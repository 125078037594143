import NumberFormat from 'react-number-format';
import { OnOfCapitalTooltip } from '../../../../../../../common/components/Tooltips/OnOfCapitalTooltip/OnOfCapitalTooltip';
import { translations } from "../../../../../../../common/translations/translations.en";
import { displayMoneyValue } from "../../../../../../../common/utils/number-display.utils";
import { CapitalMetricsValue, CapitalMetricsWrapper } from '../OverviewTab.styles';

const { real_estate } = translations.item_overview;
const { IRR_DESCRIPTION } = translations.common.item_overview;

export const rentalSoleOverviewData = {
    value: {title:'Current property value', subTitle: 'This will be reflected in your net worth'},
    showConnectedLiability:true,
    stats : [
        {title:'Total cash invested', subTitle : 'Including all contributions' , dataKey: 'contributions', multipleValues: true },
        {title:'IRR', subTitle : 'Expected annual increase in value' , dataKey: 'xirr', description: IRR_DESCRIPTION, type: 'perc', multipleValues:true, hasMetricBars: true },
        {title:'Total Income', subTitle : 'Total rent income' , dataKey: 'distributions' , multipleValues: true, showTargetActual: true, isActualAboveTargetPositive: true },
        {title:'Total Expenses', subTitle : 'Ongoing expenses' , dataKey: 'totalExpenses' }
    ],
    visuals : []
}

export const rentalPartnersOverviewData = {
    value: {title:'Current asset value', subTitle: 'This will be reflected in your net worth'},
    showConnectedLiability:true,
    stats : [
        {title: real_estate.rental_property.syndication.rate_of_return.TITLE_LABEL, subTitle: 'Total', description: real_estate.rental_property.syndication.rate_of_return.TITLE_DESCRIPTION, dataKey:'coc_capital', type:'perc', multipleValues: true , hasMetricBars: true }, //showTargetActual: true, isActualAboveTargetPositive: true
        {title:'IRR', subTitle : 'Expected annual increase in value' , dataKey: 'xirr', description: IRR_DESCRIPTION, type: 'perc', multipleValues:true, hasMetricBars: true },
        {isArray: true , 
            isDepended: true,
            getStatFn: (itemData) => {
                const stats = [];
                if (itemData.totalCommittedCapital) {
                    stats.push(
                        {
                            title: 'Uncalled capital',
                            menuDescription: 'Unpaid portion of fund commitment.',
                            dataKey: 'uncalled_capital',
                            multipleValues: true,
                            additionalDataFn: totalCommittedDataFunc
                        },
                        {
                            title: 'Called capital',
                            menuDescription: 'The total of committed capital that has been paid.',
                            dataKey: 'called_capital',
                            multipleValues: true,
                            additionalDataFn: totalCommittedDataFunc
                        }
                    );
                } else {
                    stats.push({ title: 'Total contributions', menuDescription: 'The initial invested capital and any additional investments', dataKey: 'contributions', multipleValues: true });
                }
                stats.push({
                    title: 'Invested capital', menuDescription: 'Total contributions minus return of capital', dataKey: 'invested_capital', multipleValues: true,
                    defaultOpenIndex: 1,
                    isMultipleValuesHiddenFn: (itemData, selectedTimeFrameIndex) => !(itemData.of_capital?.[selectedTimeFrameIndex]?.actual > 0),
                    multipleValuesLabels: { 'Total': 'Initial' }
                });

                return { isArray: true, stats };
            }
        },

        {title:'Total distributions', subTitle : 'Total cash received to date' , dataKey: 'distributions' , description: <OnOfCapitalTooltip />, multipleValues: true, showInlineTargetActual: false, isActualAboveTargetPositive: true,
        additionalDataFn: (itemData, selectedTimeFrameIndex , isHoldingView) => {
            return (<CapitalMetricsWrapper>
                <div>Of capital <CapitalMetricsValue>{displayMoneyValue(itemData.of_capital && itemData.of_capital[selectedTimeFrameIndex] ? itemData.of_capital[selectedTimeFrameIndex].actual : 0 , itemData.currency,false, isHoldingView ? itemData.holdings : 100)}</CapitalMetricsValue></div>
                <div>On capital <CapitalMetricsValue>{displayMoneyValue(itemData.on_capital && itemData.on_capital[selectedTimeFrameIndex] ? itemData.on_capital[selectedTimeFrameIndex].actual : (
                    itemData.distributions && itemData.distributions[selectedTimeFrameIndex] ? itemData.distributions[selectedTimeFrameIndex].actual : 0
                ), itemData.currency, false, isHoldingView ? itemData.holdings : 100)}</CapitalMetricsValue></div>
            </CapitalMetricsWrapper>)
        }
    },
        
    ],
    visuals : []
}

export const developmentSoleOverviewData = {
    value: {title:'Current asset worth', subTitle: 'Most updated property value (will be reflected in your net worth)h'},
    showConnectedLiability:true,
    stats : [
        {title:'Expected ROI ', subTitle : 'Total expected return' , type: 'perc', dataKey:'roi', hasMetricBars: true,
            description: `This metric calculates the expected return on your investment based on the sale price you anticipate. The expected sale price can be updated in the Asset Info under the Projection section.`,
        valueFn : (itemData,isIncognito,isHoldingView) => {
            return(<div>
                <NumberFormat value={itemData.expectedROICalc || 0} displayType={'text'} suffix={'%'} decimalScale={2} />
            </div>)
        }
        },
        {title:'IRR', subTitle : 'Expected annual increase in value' , dataKey: 'xirr', description: IRR_DESCRIPTION, type: 'perc', multipleValues:true, hasMetricBars: true },
        {title:'Total cash invested', subTitle : 'Including all contributions' , dataKey: 'contributions', multipleValues: true },
        {title:'Total income',  dataKey: 'distributions' , multipleValues: true, showTargetActual: true, isActualAboveTargetPositive: true,}

    ],
    visuals : []
}

const totalCommittedDataFunc = (itemData, selectedTimeFrameIndex , isHoldingView) => {
    return (<CapitalMetricsWrapper>
        <div>Total commitment <CapitalMetricsValue>{displayMoneyValue((itemData.totalCommittedCapital ?? 0), itemData.currency, false, (isHoldingView ? itemData.holdings : 100))}</CapitalMetricsValue></div>
    </CapitalMetricsWrapper>);
};

export const developmentPartnersOverviewData = {
    value: {title:'Current asset value', subTitle: 'This will be reflected in your net worth'},
    showConnectedLiability:true,
    stats : [
        // {title:'Expected ROI ', subTitle : 'Total expected return' , type: 'perc', dataKey:'roi', multipleValues:true, hasMetricBars: true,
        // description: `This metric calculates the expected return on your investment based on the sale price you anticipate. The expected sale price can be updated in the Asset Info under the Projection section.`,
        // valueFn : (itemData,isIncognito,isHoldingView) => {
        //     return(<div>
        //         A<NumberFormat value={itemData.expectedROICalc || 0} displayType={'text'} suffix={'%'} decimalScale={2} />
        //     </div>)
        // }
        {title:'Expected ROI', subTitle : 'Total expected return' , type: 'perc', dataKey:'roi',
            description: `This metric calculates the expected return on your investment based on the sale price you anticipate. The expected sale price can be updated in the Asset Info under the Projection section.`,
            valueFn : (itemData,isIncognito,isHoldingView) => {
                return(<div>
                    <NumberFormat value={itemData.expectedROICalc || 0} displayType={'text'} suffix={'%'} decimalScale={2} />
                </div>)
            }
        },
        {title:'IRR', subTitle : 'Expected annual increase in value' , dataKey: 'xirr', description: IRR_DESCRIPTION, type: 'perc', multipleValues:true, hasMetricBars: true },
        {isArray: true , 
            isDepended: true,
            getStatFn: (itemData) => {
                const stats = [];
                if (itemData.totalCommittedCapital) {
                    stats.push(
                        {
                            title: 'Uncalled capital',
                            menuDescription: 'Unpaid portion of fund commitment.',
                            dataKey: 'uncalled_capital',
                            multipleValues: true,
                            additionalDataFn: totalCommittedDataFunc
                        },
                        {
                            title: 'Called capital',
                            menuDescription: 'The total of committed capital that has been paid.',
                            dataKey: 'called_capital',
                            multipleValues: true,
                            additionalDataFn: totalCommittedDataFunc
                        }
                    );
                } else {
                    stats.push({ title: 'Total contributions', menuDescription: 'The initial invested capital and any additional investments', dataKey: 'contributions', multipleValues: true });
                }
                stats.push({
                    title: 'Invested capital', menuDescription: 'Total contributions minus return of capital', dataKey: 'invested_capital', multipleValues: true,
                    defaultOpenIndex: 1,
                    isMultipleValuesHiddenFn: (itemData, selectedTimeFrameIndex) => !(itemData.of_capital?.[selectedTimeFrameIndex]?.actual > 0),
                    multipleValuesLabels: { 'Total': 'Initial' }
                });

                return { isArray: true, stats };
            }
        },
        {title:'Total distributions', dataKey: 'distributions', description: <OnOfCapitalTooltip />, multipleValues: true, showInlineTargetActual: false, isActualAboveTargetPositive: true,
        additionalDataFn: (itemData, selectedTimeFrameIndex , isHoldingView) => {
            return (<CapitalMetricsWrapper>
                <div>Of capital <CapitalMetricsValue>{displayMoneyValue(itemData.of_capital && itemData.of_capital[selectedTimeFrameIndex] ? itemData.of_capital[selectedTimeFrameIndex].actual : 0 , itemData.currency,false, isHoldingView ? itemData.holdings : 100)}</CapitalMetricsValue></div>
                <div>On capital <CapitalMetricsValue>{displayMoneyValue(itemData.on_capital && itemData.on_capital[selectedTimeFrameIndex] ? itemData.on_capital[selectedTimeFrameIndex].actual : (
                    itemData.distributions && itemData.distributions[selectedTimeFrameIndex] ? itemData.distributions[selectedTimeFrameIndex].actual : 0
                ), itemData.currency, false, isHoldingView ? itemData.holdings : 100)}</CapitalMetricsValue></div>
            </CapitalMetricsWrapper>)
        }
        }
    ],
    visuals : []
}

export const residanceOverviewData = {
    value: {title:'Current property value', subTitle: 'This will be reflected in your net worth'},
    showConnectedLiability:true,
    stats : [
        {title:'IRR', subTitle : 'Expected annual increase in value' , dataKey: 'xirr', description: IRR_DESCRIPTION, type: 'perc', multipleValues:true, hasMetricBars: true,},
        {},
        {title:'Total cash invested', subTitle : 'Including all contributions' , dataKey: 'contributions', multipleValues: true },
        {title:'Total income', dataKey: 'distributionsTimeFrames' , multipleValues: true}
    ],
    visuals : []
}