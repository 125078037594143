import { LoadingOutlined } from '@ant-design/icons';
import { Observer } from 'mobx-react';
import React, { useState } from 'react';
import Lottie from 'react-lottie-player';
import { useHistory } from "react-router-dom";
import completeAnimData from '../../../../assets/onboarding/fireworks.json';
import { CategorySelector } from '../../../../common/components/CategorySelector/CategorySelector';
import { navigateToUrl } from '../../../../common/utils/navigation.utils';
import { displayMoneyValue } from '../../../../common/utils/number-display.utils';
import { getUrlDisplayName } from '../../../../common/utils/string.utils';
import { useStore } from '../../../app/data/root.store';
import { isCreditCard, isLiability } from '../../../connected-institutions/utils/akoya.utils';
import { AccountBalance, AccountBalanceAndActions, AccountCategorySelector, AccountLogoWrapper, AccountRowWrapper, AccountTitleAndCategory, AccountsListHeaderColumn, AccountsListHeaderRow, AccountsSelectionMissingErrorMessage, AddedAccountName, PlaidAccountsFooter, PlaidAccountsListWrapper, PlaidAccountsStepTitle, PlaidAccountsStepWrapper, ResultsAndBannerWrapper, RoundedWizardButton } from '../AddItem.styles';
import { InstitutionLogoWrapper } from './addItemInstitutionStep.styles';
import { getInstitutionLinkedProps } from '../../data/add-item.mixpanel.events';
import { useTrackEvent, useTrackGTMEvent } from '../../../../hooks/useTrackEvent/useTrackEvent';
import { ROUTES } from '../../../../common/constants/routes.const';

export function AddItemAkoyaAccountsStep(props) {
  const { isActivationFlow, onFinish } = props;
  const { createItemStore, metadataStore, wealthStore } = useStore();
  const [isAddSuccess, setIsAddSuccess] = useState(false);
  const [firstAddedItem, setFirstAddedItem] = useState(null);
  const history = useHistory();

  const handleUpdateMetadata = () => {
    metadataStore.fetchData();
  }

  useTrackEvent('Institution Linked', getInstitutionLinkedProps(createItemStore.akoyaInstitution, "Akoya"));
  useTrackGTMEvent('Institution Linked', getInstitutionLinkedProps(createItemStore.akoyaInstitution, "Akoya"));

  const handleContinue = () => {
    createItemStore.akoyaAccountsContinue((data) => {
      wealthStore.getData();
      setIsAddSuccess(true);
      const firstItem = data.sort((a,b) => b.value - a.value)?.[0];
      setFirstAddedItem(firstItem);
    })
  }

  const handleGoToItem = () => {
    navigateToUrl(history, ROUTES.wealthAssets.url + '/' + getUrlDisplayName(firstAddedItem.class.title) + '/' + firstAddedItem.id);
    onFinish && onFinish(metadataStore);
  }

  const handleNewSync = () => {
    handleUpdateMetadata();
    createItemStore.setInstitutionStep(createItemStore.syncMode);
  }

  return (<Observer>{() => (

    <PlaidAccountsStepWrapper>
      {!isActivationFlow && isAddSuccess ? <PlaidAccountsStepTitle>Woohoo! {createItemStore.akoyaAccounts.filter(ca => ca.isSelected).length > 1 ? createItemStore.akoyaAccounts.filter(ca => ca.isSelected).length + ' new items were added' : 'a new item was added'}.</PlaidAccountsStepTitle> : null}
      <ResultsAndBannerWrapper hasWidthLimit={!isActivationFlow}>
        <AccountsListHeaderRow>
          <AccountsListHeaderColumn>Account name</AccountsListHeaderColumn>
          <AccountsListHeaderColumn width={'120px'}>Current value</AccountsListHeaderColumn>
          <AccountsListHeaderColumn width={'300px'}>Choose category</AccountsListHeaderColumn>
        </AccountsListHeaderRow>
        <PlaidAccountsListWrapper>
          {createItemStore.akoyaAccounts.filter(ac => isAddSuccess ? ac.isSelected : true).map((ac) => (
            <AccountRow key={ac.accountId || ac.account_id} ac={ac} createItemStore={createItemStore} metadataStore={metadataStore} isAddSuccess={isAddSuccess} showCategorySelectorError={createItemStore.isShowAccountSelectionMissing} />
          ))}
        </PlaidAccountsListWrapper>
        {createItemStore.hasAnyInvalidAccountSelection && createItemStore.isShowAccountSelectionMissing && <AccountsSelectionMissingErrorMessage>Please select a category for each account or select ‘Ignore this account’ to continue</AccountsSelectionMissingErrorMessage>}
      </ResultsAndBannerWrapper>
      {!isActivationFlow ? <PlaidAccountsFooter>
        {isAddSuccess ? <>
          <RoundedWizardButton inline onClick={handleNewSync} margin={'0 16px 0 0'}>New sync</RoundedWizardButton>
          <RoundedWizardButton black inline onClick={handleGoToItem}>Go to item →</RoundedWizardButton>
        </> : <>
          <RoundedWizardButton black inline onClick={handleContinue}>{createItemStore.isLoading ? <LoadingOutlined /> : createItemStore.akoyaSelectedAccountsCount === 0 ? 'Please select at least one account' : `Create ${createItemStore.akoyaSelectedAccountsCount} Assets / Liabilities`}</RoundedWizardButton>
        </>}

        {/* <WizardButton onClick={()=>{createItemStore.selectType(ADD_ITEM_TYPE.LIABILITY)}}>Continue</WizardButton> */}
      </PlaidAccountsFooter> : null}
      {isAddSuccess && <>  <Lottie
        loop={true}
        animationData={completeAnimData}
        //play={showLetsGoAnim}
        speed={2}
        play
        style={{  position: 'fixed', pointerEvents:'none', top: '20px', left: '60%', zIndex: '2', width: '300px' }}
      />
        <Lottie
          loop={true}
          animationData={completeAnimData}
          //play={showLetsGoAnim}
          speed={1}
          play
          style={{  position: 'fixed', pointerEvents:'none', top: '-20px', left: '65%', zIndex: '2', width: '200px' }}
        />
        <Lottie
          loop={true}
          animationData={completeAnimData}
          //play={showLetsGoAnim}
          speed={1.5}
          play
          style={{  position: 'fixed', pointerEvents:'none', top: '-10px', left: '72%', zIndex: '2', width: '200px' }}
        />
      </>}

    </PlaidAccountsStepWrapper>
  )}</Observer>)
}

const AccountRow = (props) => {
  const { ac, createItemStore, metadataStore, isAddSuccess, showCategorySelectorError = false } = props;

  const isLiabilityClass = isLiability(ac);
  const isCreditCardClass = isCreditCard(ac);
  const relevantClasses = isLiabilityClass || isCreditCardClass ? metadataStore.liabilityClassesWithCategoriesForSync : metadataStore.availableClassesForSync;

  const handleCategoryChange = (classId, categoryId) => {
    createItemStore.changeAkoyaAccountSelected(ac.accountId|| ac.account_id, categoryId !== -1);
    createItemStore.changeAkoyaAccountClassAndCategory(ac.accountId || ac.account_id, classId, categoryId);
  }

  return (
    <AccountRowWrapper disabled={!ac.supported}>
      {(createItemStore.akoyaInstitution.logoBase64 || createItemStore.akoyaInstitution.logoURL) &&
        <AccountLogoWrapper isIgnored={!ac.isSelected}><InstitutionLogoWrapper>
          {createItemStore.akoyaInstitution.logoBase64 ? <img alt={createItemStore.akoyaInstitution.name} src={'data:image/png;base64,' + createItemStore.akoyaInstitution.logoBase64} /> :
            createItemStore.akoyaInstitution.logoURL ? <img alt={createItemStore.akoyaInstitution.name} src={createItemStore.akoyaInstitution.logoURL} /> : null}
        </InstitutionLogoWrapper></AccountLogoWrapper>}


      <AccountTitleAndCategory isIgnored={!ac.isSelected}>
        <AddedAccountName>{ac.accountName}</AddedAccountName>
      </AccountTitleAndCategory>

      {!ac.supported ? <></>:<>
      <AccountBalanceAndActions style={{ width: '120px' }} isIgnored={!ac.isSelected}>
        <AccountBalance isPositive={ac.balance > 0}>
          {displayMoneyValue(ac.balance, ac.currency)}
        </AccountBalance>
      </AccountBalanceAndActions>


      <AccountCategorySelector style={{ width: '300px' }}>
        <div style={{ width: '280px', marginRight: '20px' }}>
          <CategorySelector value={ac.categoryId} options={relevantClasses} onCategoryChange={handleCategoryChange} disabled={isAddSuccess} withIgnore withError={showCategorySelectorError} />
        </div>
      </AccountCategorySelector>
      </>}

    </AccountRowWrapper>
  )
}