import { Select } from 'antd';
import React from 'react';
import { ArrowDownIcon } from '../../../../../assets/icons/common/common-icons';
import { isNullOrUndefinedOrEmptyString } from '../../../../utils/object.utils';
import { CheckMarkSvgWrapper, SelectWithDescriptionInOptionItemSubTitle, SelectWithDescriptionInOptionItemTitle, SelectWithDescriptionInOptionItemTitlesWrapper } from './SelectWithDescriptionInOption.styles';
import { ModifiedSelectClearIcon } from '../../../ModifiedSelectClearIcon/ModifiedSelectClearIcon';

export const SelectWithDescriptionInOption = ({ value, inputKey, field, onChange, scrollContainerId, disabled }) => {
  const { Option } = Select;

  const selectedOption = field.options.find(option => option.value === value);

  return (
    <div style={{position:'relative' }}>
    <Select
      value={value}
      name={inputKey}
      disabled={disabled}
      allowClear={field.allowClear && !isNullOrUndefinedOrEmptyString(value)}
      clearIcon={field.allowClear ? <ModifiedSelectClearIcon /> : null}
      optionLabelProp='label'
      defaultValue={field.defaultValue}
      onChange={(val) => onChange(val)}
      dropdownClassName='formDisplayerDefaultSelect'
      getPopupContainer={() => scrollContainerId ? (document.getElementById(scrollContainerId) || document.body) : document.body}
      suffixIcon={<ArrowDownIcon />
      
      }
    >
      {field.options.map(fieldOption => (
        <Option key={fieldOption.value} value={fieldOption.value} className='formDisplayerSelectWithDescriptionOption' label={typeof fieldOption.label === 'function' ? fieldOption.label() : null}>
          <div style={{ display: 'flex', flex: 1, }}>
            <SelectWithDescriptionInOptionItemTitlesWrapper>
              <SelectWithDescriptionInOptionItemTitle>{typeof fieldOption.label === 'function' ? fieldOption.label() : fieldOption.label}</SelectWithDescriptionInOptionItemTitle>
              {fieldOption.description ? <SelectWithDescriptionInOptionItemSubTitle>{fieldOption.description}</SelectWithDescriptionInOptionItemSubTitle> : null}
            </SelectWithDescriptionInOptionItemTitlesWrapper>
            {field.withSuffixIcon ? <CheckMarkSvgWrapper>{value === fieldOption.value ? <CheckMarkSvg /> : null}</CheckMarkSvgWrapper> : null}
          </div>
        </Option>
      ))}
    </Select>
    {selectedOption?.selectedDescription && <span style={{pointerEvents:'none', position:'absolute' , right:'32px' , lineHeight:'38px' , color:'#9097A3'}}>{selectedOption?.selectedDescription}</span> } 
    </div>
  )
}

const CheckMarkSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5106 8.604L10.5061 15.6125L7.00008 12.1075" stroke="black" strokeWidth="2" />
    </svg>
  )
}