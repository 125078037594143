import { Dropdown , Tooltip } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import currency from 'currency.js';
import React, { useEffect, useRef, useState } from 'react';
import { ArrowDownIcon, ArrowRightIcon, ConnectedAccountsIcon } from '../../../../../assets/icons/common/common-icons';
import { MenuItemWrapper, MenuWrapper } from '../../../../../common/components/DropDownMenu/DropDownMenu.styles';
import { countDecimals, displayMoneyValue, numberWithCommas, quantityFormatWithCommas } from '../../../../../common/utils/number-display.utils';
import { useStore } from '../../../../app/data/root.store';
import { ItemPieChart } from '../../../components/charts/ItemPieChart/ItemPieChart';
import {
  AssetCardContent, AssetCardGraphPane, AssetCardMetricsPane, ChartDataboxWrapper, HeroConnectedAssetBtn, HeroConnectedAssetTitle, HeroConnectedAssetTitleBtnWrapper, HeroConnectedAssetWrapper,
  HeroDescriptionBig, HeroSyncDescription, HeroTotalCryptoCoinCount, HeroTotalLastStockUpdateDateWrapper, HeroTotalStockValueWrapper, HeroWorthHoldingsBadge, HeroWorthHoldingsBadgeText, HeroWorthHoldingsBadgeTriangle, HeroWorthHoldingsValue, HeroWorthValue,
  HoldingValueTextColor,
  HoldingsQuantityBadgeText,
  HoldingsQuantityBadgeTriangle,
  HoldingsQuantityBadgeWrapper,
  ItemDoughnutChartWrapper, MobileCarouselBoxWrapper, MobileStatBoxWrapper, MobileStatsWrapper, StatsColumn, StatsRow, SyncIconWrapper
} from './AssetOverviewHeroContent.styles';
import { LiabilityConnectedAsset } from './LiabilityConnectedAsset';
import { StatInfoWrapper } from './Overview/StatInfoBox';
import { instSyncTimeAgo } from '../../../../connected-institutions/components/institutions-panel/institutionPanel.commons';
import { getTimeAgo } from '../../../../../common/utils/dates.utils';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ItemClosedMainBox } from './AssetClosedViewMainBox';
import { getValueByPath } from '../../../../../common/utils/object.utils';


export const AssetOverviewHeroContent = observer((props) => {
  const { wealthStore, itemStore, uiStore } = useStore();
  const { item, itemOverviewDisplayData, isShowingTickers, hasConnectedLiability, isHoldingsView, assetOverviewHeroHoverData, isSyncingInstitution } = itemStore;
  const [valueDisplay, setValueDisplay] = useState(1);
  const containerRef = useRef();
  const textRef = useRef();
  const [textSize, setTextSize] = useState(32);

  useEffect(() => {
    const containerElement = containerRef.current;
    const textElement = textRef.current;

    if (containerElement && textElement) {
      const availableWidth = containerElement.clientWidth - 80;
      const textWidth = textElement.scrollWidth;
      setTextSize(textWidth > availableWidth ? (availableWidth / textWidth) * 32 : 32);
    }
  }, [item.usdValue, item.value]);

  return (
      <AssetCardContent noStats={itemOverviewDisplayData?.stats?.length === 0}>

        <AssetCardGraphPane isItemClosed={item.closedAt !== null}>
        { item.closedAt ? <ItemClosedMainBox /> :
        <>
          <ItemDoughnutChartWrapper isLoginRequired={item.isLoginRequired} ref={containerRef}>
             <ItemPieChart /> 

            <ChartDataboxWrapper isMultipartPie={isShowingTickers}>
              {hasConnectedLiability && (item.holdings === 100 || (item.holdings !== 100 && isHoldingsView)) && !assetOverviewHeroHoverData.show ?
                <Dropdown
                  overlay={
                    NetGrossDropDown({
                      selectOption: (val) => { setValueDisplay(val) }
                    })
                  }
                  trigger={['click']} placement="bottomCenter" >
                  <HeroDescriptionBig clickable>{valueDisplay === 1 ? 'Gross asset value' : 'Net asset value'}<ArrowDownIcon /></HeroDescriptionBig>
                </Dropdown> : <HeroDescriptionBig>{assetOverviewHeroHoverData.show ? assetOverviewHeroHoverData.title : itemOverviewDisplayData?.value?.title}</HeroDescriptionBig>}

                {itemOverviewDisplayData?.value?.showTickerLastUpdateDate && !item.isConnected && <HeroTotalLastStockUpdateDateWrapper>{getTimeAgo(moment(item.stockLastStockUpdateDate).toDate())}</HeroTotalLastStockUpdateDateWrapper>}
                {itemOverviewDisplayData?.value?.showCryptoLastUpdateDate && !item.isConnected && <HeroTotalLastStockUpdateDateWrapper>{getTimeAgo(moment(item.cryptoLastUpdateDate).toDate())}</HeroTotalLastStockUpdateDateWrapper>}
              <HeroWorthHoldingsValue style={{fontSize: `${textSize}px`}} ref={textRef}>
                {hasConnectedLiability && valueDisplay === 2 && isHoldingsView ?
                  displayMoneyValue(item.netValue, item.currency, uiStore.isIncognitoMode, 100)
                  : 
                  displayMoneyValue(assetOverviewHeroHoverData.show ? assetOverviewHeroHoverData.value : itemOverviewDisplayData?.value?.showPropValue ? item[itemOverviewDisplayData?.value?.showPropValue] : item.value, assetOverviewHeroHoverData.show ? assetOverviewHeroHoverData.currency : item.currency, uiStore.isIncognitoMode, item.holdings)}
              </HeroWorthHoldingsValue>
              {item.holdings !== 100 &&
                <HeroWorthValue isSelected={!isHoldingsView} style={{fontSize: `${textSize}px`}} ref={textRef}>
                  { displayMoneyValue(
                      itemOverviewDisplayData?.value?.showPropValue ? item[itemOverviewDisplayData?.value?.showPropValue] : item.value,
                      item.currency, uiStore.isIncognitoMode, 100)}
                  <HeroWorthHoldingsBadge><HeroWorthHoldingsBadgeText>100%</HeroWorthHoldingsBadgeText><HeroWorthHoldingsBadgeTriangle /></HeroWorthHoldingsBadge>
                </HeroWorthValue>}

              {item.connectedAssets?.length > 0 &&
                <HeroConnectedAssetWrapper withHoldings={item.holdings !== 100}>
                  <HeroConnectedAssetTitleBtnWrapper>
                    <HeroConnectedAssetTitle> Linked Asset </HeroConnectedAssetTitle>
                    <HeroConnectedAssetBtn><ArrowRightIcon /></HeroConnectedAssetBtn>
                  </HeroConnectedAssetTitleBtnWrapper>
                  <LiabilityConnectedAsset item={item} />
                </HeroConnectedAssetWrapper>}

              {itemOverviewDisplayData?.value?.showCryptoBalance && <HeroTotalCryptoCoinCount>≈ {displayMoneyValue(item.value, item.currency, uiStore.isIncognitoMode, item.holdings)}</HeroTotalCryptoCoinCount>}
              {itemOverviewDisplayData?.value?.showCoreBalance && 
                <HeroTotalStockValueWrapper isBigNumber={(''+quantityFormatWithCommas(item.value * item.holdings / 100)).length>6}>
                  {item.holdings !== 100 ? <>
                    <Tooltip placement='top' title={countDecimals(currency(item.value,{precision:8}).multiply(item.holdings).divide(100).value) > 2 ? numberWithCommas(currency(item.value,{precision:8}).multiply(item.holdings).divide(100).value) : ''}><div style={{display:'flex',whiteSpace:'nowrap'}}>{quantityFormatWithCommas(currency(item.value,{precision:8}).multiply(item.holdings).divide(100).value)} {getValueByPath(item,itemOverviewDisplayData?.value?.showCoreBalance)}</div></Tooltip>
                    <Tooltip placement='top' title={countDecimals(item.value) > 2 ? numberWithCommas(item.value) : ''}><div style={{display:'flex',alignItems:'center'}}><HoldingsQuantityBadgeWrapper style={{margin:'0 4px'}}><HoldingsQuantityBadgeText/><HoldingsQuantityBadgeTriangle /></HoldingsQuantityBadgeWrapper><HoldingValueTextColor>{quantityFormatWithCommas(item.value)}</HoldingValueTextColor></div></Tooltip>
                  </> : 
                  <><Tooltip placement='top' title={countDecimals(item.value) > 2 ? numberWithCommas(item.value) : ''}>{quantityFormatWithCommas(item.value)} {getValueByPath(item,itemOverviewDisplayData?.value?.showCoreBalance)}</Tooltip></>}</HeroTotalStockValueWrapper>
              }

            </ChartDataboxWrapper>
          </ItemDoughnutChartWrapper>
          
          {(( wealthStore.isCheckingUpdates || isSyncingInstitution || item.lastSync === null  ) && item.isConnected) && <HeroSyncDescription><SyncIconWrapper><ConnectedAccountsIcon /></SyncIconWrapper>Syncing values...</HeroSyncDescription>}
          {(!wealthStore.isCheckingUpdates && !isSyncingInstitution && item.lastSync !== null && item.isConnected) &&
            <HeroSyncDescription>Last sync: {instSyncTimeAgo(moment(item.lastSync || item.lastValueUpdateDate).toDate())  }</HeroSyncDescription>}
        </>}
        </AssetCardGraphPane>

        {itemOverviewDisplayData?.stats?.length > 0 &&
          <>
            {uiStore.isDesktopView ?
            <AssetCardMetricsPane>
              <StatsRow>
                {itemOverviewDisplayData.stats.slice(0, 2).map((stat, ind) => (
                  <StatsColumn isHidden={!stat.title && !stat.isArray && !stat.isDepended} key={ind}>
                    <StatInfoWrapper stat={stat} itemData={item} isIncognito={uiStore.isIncognitoMode} isHoldingView={isHoldingsView} />
                  </StatsColumn>
                ))}
              </StatsRow>
              <StatsRow>
                {itemOverviewDisplayData.stats.slice(2, 4).map((stat, ind) => (
                  <StatsColumn isHidden={!stat.title && !stat.isArray && !stat.isDepended} key={ind}>
                    <StatInfoWrapper stat={stat} itemData={item} isIncognito={uiStore.isIncognitoMode} isHoldingView={isHoldingsView} />
                  </StatsColumn>
                ))}
              </StatsRow>
            </AssetCardMetricsPane> : 
              <MobileStatsWrapper>
                <Slider dots={false} infinite={true} centerMode={true} arrows={false}>
                {itemOverviewDisplayData.stats.filter(i=>i.title || i.isArray).map((stat, ind) => (
                    <MobileCarouselBoxWrapper key={ind}>
                        <MobileStatBoxWrapper  isItemView>
                          <StatInfoWrapper stat={stat} itemData={item} isIncognito={uiStore.isIncognitoMode} isHoldingView={isHoldingsView} />
                        </MobileStatBoxWrapper>
                    </MobileCarouselBoxWrapper>
                ))}
                </Slider>
              </MobileStatsWrapper>
            }
          </>}

      </AssetCardContent>
  )
})

function NetGrossDropDown(props) {
  return (
    <MenuWrapper>
      <MenuItemWrapper onClick={() => { props.selectOption(1) }}>Gross asset value</MenuItemWrapper>
      <MenuItemWrapper onClick={() => { props.selectOption(2) }}>Net asset value</MenuItemWrapper>
    </MenuWrapper>
  )
}