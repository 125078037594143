import { Observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { GoToIcon } from '../../../../../assets/icons/common/common-icons';
import { ChartComponent } from '../../../../../common/components/charts/ChartComponent';
import { ROUTES } from '../../../../../common/constants/routes.const';
import { navigateToUrl } from '../../../../../common/utils/navigation.utils';
import { displayMoneyValue } from '../../../../../common/utils/number-display.utils';
import { getUrlDisplayName } from '../../../../../common/utils/string.utils';
import { useStore } from '../../../../app/data/root.store';
import { MagicBoxAnimatedIconGrey } from '../../../../magic-box/components/MagicBoxAnimatedIconGrey';
import { PieTextHoverWrapper, PieTrendsLink, PieValueContainer } from '../WealthPieChart/WealthPieChart.styles';
import { ClassTitleText, PieTextBigValue, PieTextContent, PieWrapper } from './FiltersPieChart.styles';

// const changesDataPoints = [
//   'previous month',
//   'previous quarter',
//   'previous year'
// ];

export function FiltersPieChart(props) {

  const { wealthStore, uiStore, metadataStore } = useStore();
  let history = useHistory();
  const { bigTextSize } = props;
  const [hoverData, setHoverData] = useState({ index: null });
  // const [showChangesSince, setShowChangesSince] = useState(0);
  const isPieWithLiabilities = wealthStore?.pieFiltersData.datasets.length > 1;
  const [chartOptions, setChartOptions] = useState({ cutout: '90%' });
  const containerRef = useRef();
  const textRef = useRef();
  const [textSize, setTextSize] = useState(bigTextSize ?? 32);

  const getChartConfig = useCallback(() => {
    return {
      maintainAspectRatio: true,
      cutout: (isPieWithLiabilities ? 80 : 90) + '%',
      onHover: (event, chartElement) => {
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        if (chartElement.length) {
          const { datasetIndex: dataSetIndex, index: dataIndex } = chartElement[0];

          const relevantItem = (dataSetIndex === 1 ?
            wealthStore.liabilitiesForPieCharts[dataIndex]
            : wealthStore.itemsForFiltersPieChart.filter(item => item.isAsset && item.usdValue > 0)[dataIndex]);
            
          setHoverData(relevantItem ? {
            show: true,
            title: relevantItem.title,
            value: relevantItem.itemCurrencyValue,
            color: relevantItem.color,
            currency: relevantItem.currency,
            isHoveredOnce: true
          } : { show: false, isHoveredOnce: true })
          if (!relevantItem) { event.native.target.style.cursor = 'default' }
        } else {
          setHoverData({
            show: false,
            isHoveredOnce: true
          })
        }
      },
      onClick: (event, chartElement) => {

        if (chartElement.length && (chartElement[0].datasetIndex === 1 || wealthStore.isFilterOnlyLiabilities)) {
          const liaId = wealthStore.liabilitiesForPieCharts[chartElement[0].index].liabilityId;
          const itemClassId = metadataStore.itemsMeta[liaId].classId;
          const itemClass = metadataStore.classesObj[itemClassId];
          navigateToUrl(history, ROUTES.wealthAssets.url + '/' + getUrlDisplayName(itemClass.title) + '/' + liaId);
        } else if (chartElement.length) {
          const relevantItem = wealthStore.itemsForFiltersPieChart.filter(item => item.isAsset)[chartElement[0].index];
          navigateToUrl(history, ROUTES.wealthAssets.url + '/' + getUrlDisplayName(relevantItem.categoryClass.title) + '/' + relevantItem.id);
        }



      },
      plugins: {
        tooltip: { enabled: false },
        legend: {enabled: false }
      }
    }
  }, [metadataStore, history, wealthStore, isPieWithLiabilities])


  useEffect(() => {
    setChartOptions(getChartConfig());
    return () => {
    }
  }, [getChartConfig])


  const getBigPieTitle = () => {
    //if the selected class is Loans show the following title
    if (wealthStore.selectedClass?.id === 10) {
      return 'Total balance'
    }
    //Otherwise show the following default title
    return 'Total items worth'
  }

  useEffect(() => {
    const containerElement = containerRef.current;
    const textElement = textRef.current;

    if (containerElement && textElement) {
      const availableWidth = containerElement.clientWidth - 80;
      const textWidth = textElement.scrollWidth;
      
      setTextSize(textWidth > availableWidth ? (availableWidth / textWidth) * 32 : 32);
    }
  }, []);

  return (<Observer>{() => (
    <PieWrapper>
      <ChartComponent type='doughnut' data={wealthStore.pieFiltersData} options={chartOptions} />
      <PieTextContent contentTop={props.contentTop} ref={containerRef}>
        <PieTextHoverWrapper onClick={() => { wealthStore.setViewType('trends'); wealthStore.setTrendsView('total_net_worth') }}>
        <ClassTitleText color={hoverData.show ? hoverData.color : null}>
          {hoverData.show ? hoverData.title : getBigPieTitle()}
        </ClassTitleText>
          <PieTextBigValue isIncognito={uiStore.isIncognitoMode} fontSize={textSize} ref={textRef}>
            <PieValueContainer>
            {displayMoneyValue(hoverData.show ? hoverData.value : wealthStore.heroMetricsData.totalItemsWorthUsdValue, hoverData.show ? hoverData.currency : 'USD', uiStore.isIncognitoMode)}
            {((wealthStore.hasLLMInProcessItem) && (!uiStore.isIncognitoMode)) ? <MagicBoxAnimatedIconGrey width='16px' display='flex' fill='rgb(255,255,255)' />: undefined}
            </PieValueContainer>
          </PieTextBigValue>
          <PieTrendsLink >See trend <GoToIcon /></PieTrendsLink>
          </PieTextHoverWrapper>
      </PieTextContent>
    </PieWrapper>
  )}</Observer>)
}

// const valueChangeOptions = [
//   {value:0,text:'Previous month'},
//   {value:1,text:'Previous quarter'},
//   {value:2,text:'Previous year'},
// ]

// function ValueChangeDropDown(props) {
//   const { showChangesSince, selectChangesSince } = props
//   return (
//     <MenuWrapperWealthPie>
//       {valueChangeOptions.map(item => (
//         <MenuItemWrapperWealthPie key={item.value} onClick={() => { selectChangesSince(item.value) }}>{item.text} {showChangesSince === item.value && <CheckmarkIcon />}</MenuItemWrapperWealthPie>
//       ))}
//     </MenuWrapperWealthPie>
//   )
// }