import { makeAutoObservable } from "mobx";
import moment from 'moment';
import { navigateToUrl } from "../../../common/utils/navigation.utils";
import { isNullOrUndefinedOrZeroOrEmptyString } from "../../../common/utils/object.utils";
import { getUrlDisplayName } from "../../../common/utils/string.utils";
import { akoyaTypeToVyzerClassMapping, akoyaTypeToVyzerTypeMapping, plaidSubTypeToVyzerCategoryMapping, plaidTypeAndSubTypeToVyzerClassMapping, plaidTypeToVyzerClassMapping, plaidTypeToVyzerTypeMapping, saltedgeCheckingAccountsNatures, saltedgeTypeToVyzerClassMapping, saltedgeTypeToVyzerTypeMapping } from "../../connected-institutions/data/connected-institutions.consts";
import { errorNotification } from "../../layout/components/Notifications/notifications";
import { connectAccounts, createVipSync, saveCryptoCoins, saveItem, saveStocks, searchInstitutions } from './add-item.service';
import { banksDefaultInstitutions, investorsDefaultInstitutions } from "./create-item-institutions.const";
import { unSupportedConnectedAccountCategories } from "../../metadata/data/metadata.store";
import { ROUTES } from "../../../common/constants/routes.const";

export const ADD_ITEM_TYPE = {
    ASSET : 'asset',
    LIABILITY : 'liability'
}

export const ADD_ITEM_STEP = {
    // CLASS_CATEGORY: ''
    TYPE : 'type',
    CLASS : 'class',
    INSTITUTION: 'institution',
    AUTO_OR_MANUALLY : 'auto_manually',
    CATEGORY : 'category',
    PLAID_ACCOUNTS: 'plaid_accounts',
    SALTEDGE_ACCOUNTS: 'saltedge_accounts',
    AKOYA_ACCOUNTS: 'akoya_accounts',
    VIP_SYNC: 'vip_sync',
    ADD_VIP_REQUEST: 'add_vip_sync_request',
    MANUALLY_FORM: 'manually_form',
    CHOOSE_METHOD: 'choose_method',
}

export const SYNC_MODES = {
    BANK: 'bank',
    INVESTOR : 'investor'
}



const predefineItemData = {
    holdings: 100,
    currency: 'USD',
    // title: 'asdasd',
    // value: 12000,
    // startDate: new Date()
};
// const saltedgeSubTypeToVyzerCategoryMapping = {
//     'checking' : 1,
//     'savings' : 2,
//     'cd': 4,
//     'money market': 3
// }




export class CreateItemStore{
    showModal = false; //true;
    headerTitle = '';
    showHeaderBackButton = false;
    showHeaderClassIcon = false;
    isLoading = false;
    isSearchingInstitutions = false;
    isLoadingMoreInstitutions = false;
    isSavingItem = false;
    searchedInstitutions = [];
    searchMeta = null;
    searchStr = '';
    syncMode = 'bank';    
    classId = 0; // 1;
    categoryId = 0; 
    step = ADD_ITEM_STEP.CHOOSE_METHOD; // ADD_ITEM_STEP.CLASS; // ADD_ITEM_STEP.CATEGORY;
    institution = {};
    plaidAccounts = [];
    saltedgeInstitution = {};
    saltedgeAccounts = [];
    akoyaAccounts = [];
    akoyaInstitution = null;
    newItemDefaultValues = predefineItemData;
    isSaltedgeInProcess = false;
    isAkoyaInProcess = false;
    isPlaidInProcess = false;
    isInstitutionConnectFail = false;
    selectedVipSyncInstitution= null;
    vipSyncStepIndex = 0;
    isShowAddManualItemLimitModal = false;
    isShowAddSyncLimitModal = false;
    isShowAddVipSyncLimitModal = false;
    isFromVipVipSyncingTab = false;
    hideBackButton = false;
    isShowAccountSelectionMissing = false;
    manualSearchStr = null;

    constructor(metadataStore){
        makeAutoObservable(this);
        this.metadataStore = metadataStore;
    }

    setManualSearchStr(str){
        this.manualSearchStr = str;
    }

    setShowModal(show) {
        this.showModal = show;
    }

    setChooseMethodStep() {
        this.headerTitle = 'How would you like to add your asset / liability?'
        this.showHeaderBackButton = false;
        this.classId = 0;
        this.categoryId = 0;
        this.step = ADD_ITEM_STEP.CHOOSE_METHOD;
    }

    setInstitutionStep(syncMode){
        this.syncMode = syncMode;
        this.headerTitle = syncMode === SYNC_MODES.INVESTOR ? 'Sync an investor portal' : 'Sync bank and brokerage account';
        this.showHeaderBackButton = false;
        this.classId = 0;
        this.categoryId = 0;
        this.searchedInstitutions = syncMode === SYNC_MODES.INVESTOR ? investorsDefaultInstitutions : banksDefaultInstitutions;
        this.step = ADD_ITEM_STEP.INSTITUTION;    
    }

    setVipSyncStep(){
        this.setVipSyncStepIndex(0);
        this.headerTitle = this.syncMode === SYNC_MODES.INVESTOR ? 'Sync an investor portal' : 'Sync bank and brokerage account';
        this.showHeaderBackButton = false;
        this.classId = 0;
        this.categoryId = 0;
        this.step = ADD_ITEM_STEP.VIP_SYNC;    
    }

    setVipRequestSyncStep(){
        // this.setVipSyncStepIndex(0);
        this.headerTitle = 'Request VIP Syncing Service';
        this.showHeaderBackButton = false;
        this.classId = 0;
        this.categoryId = 0;
        this.step = ADD_ITEM_STEP.ADD_VIP_REQUEST;    
    }

    setCategoryStep(predefineValues = null){
        this.headerTitle = 'Add Asset or Liability'
        this.showHeaderBackButton = false;
        this.classId = 0;
        this.categoryId = 0;   
        this.step = ADD_ITEM_STEP.CATEGORY; 
        this.newItemDefaultValues = {
            ...predefineItemData,
            ...(predefineValues ? predefineValues : {})
        }
    }

    selectClass(classId){
        this.headerTitle = 'Add Asset or Liability'
        this.showHeaderBackButton = false;
        this.classId = classId;
        this.step = ADD_ITEM_STEP.CATEGORY; 
    }

    selectCategory(categoryId){
        this.categoryId = categoryId;
        this.showHeaderBackButton = true;
        this.showHeaderClassIcon = true;
        this.step = ADD_ITEM_STEP.MANUALLY_FORM;
        const selectedCategory = this.metadataStore.categoriesObj[categoryId];
        this.headerTitle = this.newItemDefaultValues.isMagicBoxAi ? `Complete your ${selectedCategory.title} Details` : `Add new ${selectedCategory.title}`;
    }

    openModalForSpecificCategory(classId,CategoryId,predefineValues = null){
        this.classId = classId;
        this.newItemDefaultValues = {
            ...predefineItemData,
            ...(predefineValues ? predefineValues : {})
        }
        this.selectCategory(CategoryId);
        this.setShowModal(true);
    }

    searchForInst = async(str) => {
        // const isBankSyncWithEmptySearch = str === '' && this.syncMode === SYNC_MODES.BANK;
        // if (isBankSyncWithEmptySearch) {
        if (str === '') {
            this.setSearchResults('',null,this.syncMode === SYNC_MODES.INVESTOR ? investorsDefaultInstitutions : banksDefaultInstitutions);
            this.setIsSearchingInstitutions(false);
        } else {
            this.setIsSearchingInstitutions(true);
            try {
                const data = await searchInstitutions(str);
                this.setSearchResults(str,data.meta,data.items);
            } 
            //catch (e) {} 
            finally {
                this.setIsSearchingInstitutions(false);
            }
        }
    }

    checkForLoadingMore = async() => {
        if (this.searchMeta !== null && this.searchMeta.currentPage < this.searchMeta.totalPages){
            this.setIsLoadingMoreInstitutions(true);   
            try {
                const data = await searchInstitutions(this.searchStr,this.searchMeta.currentPage+1,this.searchMeta.itemsPerPage);
                this.setSearchResults(this.searchStr,data.meta,[...this.searchedInstitutions,...data.items]);
            } 
            //catch (e) {} 
            finally {
                this.setIsLoadingMoreInstitutions(false);
            }        
        } else if (this.searchMeta === null && this.syncMode === SYNC_MODES.INVESTOR) {
            this.setIsLoadingMoreInstitutions(true);   
            try {
                const data = await searchInstitutions(this.searchStr,0,50);
                this.setSearchResults(this.searchStr,data.meta,[...this.searchedInstitutions,...data.items]);
            } 
            //catch (e) {} 
            finally {
                this.setIsLoadingMoreInstitutions(false);
            }        
        }
    }

    setSearchResults(str,meta,items){
        this.searchStr = str;
        this.searchMeta = meta;
        this.searchedInstitutions = items;
    }

    setIsSearchingInstitutions(isSearching){
        this.isSearchingInstitutions = isSearching;
    }

    setIsLoadingMoreInstitutions(isLoading){
        this.isLoadingMoreInstitutions = isLoading;
    }
   
    
    backToClassStep(resetManuallySetting){
        this.step = ADD_ITEM_STEP.CLASS;
    }


    setPlaidAccounts(accounts,institution,metadataStore) {
        this.institution = institution;
        this.plaidAccounts = accounts.map(ac=>{
            const vyzerType = plaidTypeToVyzerTypeMapping[ac.type] || ADD_ITEM_TYPE.ASSET;
            const classId = plaidTypeAndSubTypeToVyzerClassMapping[ac.type + '_' + ac.subtype] ||  plaidTypeToVyzerClassMapping[ac.type] || null;
            
            const firstCategoryInClass = metadataStore.categories.find(category=>category.classId === classId && !category.isHidden && !unSupportedConnectedAccountCategories.includes(category.id));
            const categoryId = plaidSubTypeToVyzerCategoryMapping[ac.subtype] || ( firstCategoryInClass ? firstCategoryInClass.id : null);             
            return {
                ...ac,
                vyzerType,
                // vyzerName: ac.name,
                holdings:100,
                isCheckingClass: classId === 1,
                isSelected:true,
                classId,
                categoryId
            }
        });
        this.showHeaderBackButton = false;
        this.step = ADD_ITEM_STEP.PLAID_ACCOUNTS;
    }

    setSaltedgeAccounts(accounts,institution,metadataStore) {
        this.saltedgeInstitution = institution;
        this.saltedgeAccounts = accounts.map(ac=>{
            const vyzerType = saltedgeTypeToVyzerTypeMapping[ac.nature] || ADD_ITEM_TYPE.ASSET;
            const classId = saltedgeTypeToVyzerClassMapping[ac.nature] || ( this.classId === 0 ? null : this.classId );
            const firstCategoryInClass = metadataStore.categories.find(category=>category.classId === classId && !category.isHidden && !unSupportedConnectedAccountCategories.includes(category.id));
            const categoryId = firstCategoryInClass?.id || null;         // plaidSubTypeToVyzerCategoryMapping[ac.subtype] || firstCategoryInClass.id;
            return {
                ...ac,
                vyzerType,
                // vyzerName: ac.name,
                isCheckingClass: classId === 1,
                holdings:100,
                isSelected:true,
                classId,
                categoryId
            }
        });
        this.showHeaderBackButton = false;
        this.step = ADD_ITEM_STEP.SALTEDGE_ACCOUNTS;
    }

    setAkoyaAccounts(accounts ,metadataStore, preventAccountStep = false) {
        if (accounts.length === 0) {
            //TODO do something
        }
        const { institutionName, institutionUrl } = accounts?.[0];
        this.akoyaInstitution = {name: institutionName || '', url: institutionUrl || '', }
        this.akoyaAccounts = accounts.map(ac=>{
            const vyzerType = akoyaTypeToVyzerTypeMapping[ac.accountType] || ADD_ITEM_TYPE.ASSET;
            const classId = akoyaTypeToVyzerClassMapping[ac.accountType] || ( this.classId === 0 ? null : this.classId );
            const firstCategoryInClass = metadataStore.categories.find(category => category.classId === classId && !category.isHidden && !unSupportedConnectedAccountCategories.includes(category.id));
            const categoryId = firstCategoryInClass?.id || null;         // plaidSubTypeToVyzerCategoryMapping[ac.subtype] || firstCategoryInClass.id;
            return {
                ...ac,
                vyzerType,
                isCheckingClass: classId === 1,
                holdings:100,
                isSelected:true,
                classId,
                categoryId
            }
        });
        if (!preventAccountStep){
            this.showHeaderBackButton = false;
            this.step = ADD_ITEM_STEP.AKOYA_ACCOUNTS;
        }
    }

    moveToAkoyaSelectAccount(){
        this.showHeaderBackButton = false;
        this.step = ADD_ITEM_STEP.AKOYA_ACCOUNTS;
    }

    setIsSaltedgeInProcess = (inProcess) => {
        this.isSaltedgeInProcess = inProcess;
    }

    setIsAkoyaInProcess = (inProcess) => {
        this.isAkoyaInProcess = inProcess;
    }

    setIsPlaidInProcess = (inProcess) => {
        this.isPlaidInProcess = inProcess;
    }

    setIsInstitutionConnectFail = (isFail) => {
        this.isInstitutionConnectFail = isFail;
    }

    setVipSyncStepIndex = (index) => {
        this.vipSyncStepIndex = index;
    }
    
    setShowAddManualItemLimitModal = (show) => {
        this.isShowAddManualItemLimitModal = show
    }

    setShowAddSyncLimitModal = (show) => {
        this.isShowAddSyncLimitModal = show
    }

    setShowAddVipSyncLimitModal = (show) => {
        this.isShowAddVipSyncLimitModal = show
    }

    setSelectedVipSyncInstitution = (institution) => {
        this.selectedVipSyncInstitution = institution;
    }

    setIsFromVipVipSyncingTab = (isFromTab) => {
        this.isFromVipVipSyncingTab = isFromTab;
    }

    setHideBackButton = (hide) => {
        this.hideBackButton = hide;
    }

    handlePlaidAccountNameChange(accountId,name){
        const updatedAccounts = this.plaidAccounts.map(ac=>{
            ac.accountId = ac?.account_id;
            return (
            ac.account_id === accountId ? {
              ...ac,
              name
            } : ac)}
        )
        this.plaidAccounts = updatedAccounts;
    }

    changePlaidAccountSelected(accountId,isChecked){
        const updatedAccounts = this.plaidAccounts.map(ac=>{
            ac.accountId = ac?.account_id;
            return (
            ac.account_id === accountId ? {
              ...ac,
              isSelected: isChecked
            } : ac)}
        )
        this.plaidAccounts = updatedAccounts;
    }

    changePlaidAccountClassAndCategory(accountId,classId,categoryId){
        const updatedAccounts = this.plaidAccounts.map(ac=>{
            ac.accountId = ac?.account_id;
            return (
            ac.account_id === accountId ? {
              ...ac,
              classId,
              categoryId
            } : ac)}
        )
        this.plaidAccounts = updatedAccounts;
    }
    
    changePlaidAccountHoldings(accountId, holdings){
        const updatedAccounts = this.plaidAccounts.map(ac=>{
            ac.accountId = ac?.account_id;
            return (
            ac.account_id === accountId ? {
              ...ac,
              holdings: isNullOrUndefinedOrZeroOrEmptyString(holdings) ? 100 : holdings
            } : ac)}
        )
        this.plaidAccounts = updatedAccounts;
    }

    plaidAccountsContinue = async (onConnectItems) => {
        const invalidItem = this.plaidAccounts.filter(pa=>pa.isSelected).find(pa=>{
            return pa.classId === null || pa.categoryId === null;
        })
        if (invalidItem){
            this.setIsShowAccountSelectionMissing(true);
            errorNotification('Please select Category for all selected items');
        } else if (this.plaidAccounts.filter(pa=>pa.isSelected).length === 0 ) {
            errorNotification('Please select at least one account');
        } else {

            if (!this.isLoading){
                this.isLoading = true;
    
                const requestData = this.plaidAccounts.filter(pa=>pa.isSelected).map(pa=>{
                    return {
                        ...pa,
                        source:'plaid',
                        action:{
                            createNew: true,
                            categoryId: pa.categoryId
                        },
                        connectedInstitution:{
                            institutionName: this.institution.name,
                            id: this.institution.id
                        } 
                    }
                })
                const data = await connectAccounts(requestData,this.institution);
                if (data.statusCode){
                    this.setIsInstitutionConnectFail(true);
                    errorNotification('Something went wrong');
                }
                else{
                    this.setIsInstitutionConnectFail(false);
                    onConnectItems && onConnectItems(data);
                }
                this.setIsLoading(false);
            }


        }
    }

    handleSaltedgeAccountNameChange(accountId,name){
        const updatedAccounts = this.saltedgeAccounts.map(ac=>(
            ac.id === accountId ? {
              ...ac,
              name
            } : ac)
        )
        this.saltedgeAccounts = updatedAccounts;
    }

    changeSaltedgeAccountSelected(accountId,isChecked){
        const updatedAccounts = this.saltedgeAccounts.map(ac=>(
            ac.id === accountId ? {
              ...ac,
              isSelected: isChecked
            } : ac)
        )
        this.saltedgeAccounts = updatedAccounts;
    }

    changeSaltedgeAccountClassAndCategory(accountId,classId,categoryId){
        const updatedAccounts = this.saltedgeAccounts.map(ac=>(
            ac.id === accountId ? {
              ...ac,
              classId,
              categoryId
            } : ac)
        )
        this.saltedgeAccounts = updatedAccounts;
    }

    changeSaltedgeAccountHoldings(accountId, holdings){
        const updatedAccounts = this.saltedgeAccounts.map(ac=>(
            ac.id === accountId ? {
              ...ac,
              holdings: isNullOrUndefinedOrZeroOrEmptyString(holdings) ? 100 : holdings
            } : ac)
        )
        this.saltedgeAccounts = updatedAccounts;
    }

    saltedgeAccountsContinue = async (onConnectItems) => {

        const invalidItem = this.saltedgeAccounts.filter(sa=>sa.isSelected).find(sa=>{
            return sa.classId === null || sa.categoryId === null;
        })
        if (invalidItem){
            this.setIsShowAccountSelectionMissing(true);
            errorNotification('Please select Category for all selected items');
        } else if (this.saltedgeAccounts.filter(sa=>sa.isSelected).length === 0 ) {
            errorNotification('Please select at least one account');
        } else {

            if (!this.isLoading){
                this.isLoading = true;  
                const requestData = this.saltedgeAccounts.filter(sa=>sa.isSelected).map(sa=>{
                    return {
                        ...sa,
                        source:'saltEdge',
                        action:{
                            createNew: true,
                            categoryId: sa.categoryId
                        },
                        connectedInstitution:{
                            institutionName: this.saltedgeInstitution.name,
                            id: this.saltedgeInstitution.id
                        } 
                    }
                })
                const data = await connectAccounts(requestData,this.saltedgeInstitution);
                if (data.statusCode){
                    this.setIsInstitutionConnectFail(true);
                    errorNotification('Something went wrong');
                }
                else{
                    this.setIsInstitutionConnectFail(false);
                    onConnectItems && onConnectItems(data);
                }
                this.setIsLoading(false);
            }

        }

       
    }

    handleAkoyaAccountNameChange(accountId,name){
        const updatedAccounts = this.akoyaAccounts.map(ac=>(
            ac.accountId === accountId ? {
              ...ac,
              name
            } : ac)
        )
        this.akoyaAccounts = updatedAccounts;
    }

    changeAkoyaAccountSelected(accountId,isChecked){
        const updatedAccounts = this.akoyaAccounts.map(ac=>(
            ac.accountId === accountId ? {
              ...ac,
              isSelected: isChecked
            } : ac)
        )
        this.akoyaAccounts = updatedAccounts;
    }

    changeAkoyaAccountClassAndCategory(accountId,classId,categoryId){
        const updatedAccounts = this.akoyaAccounts.map(ac=>(
            ac.accountId === accountId ? {
              ...ac,
              classId,
              categoryId
            } : ac)
        )
        this.akoyaAccounts = updatedAccounts;
    }

    changeAkoyaAccountHoldings(accountId, holdings){
        const updatedAccounts = this.akoyaAccounts.map(ac=>(
            ac.accountId === accountId ? {
              ...ac,
              holdings: isNullOrUndefinedOrZeroOrEmptyString(holdings) ? 100 : holdings
            } : ac)
        )
        this.akoyaAccounts = updatedAccounts;
    }


    akoyaAccountsContinue = async (onConnectItems) => {
        const invalidItem = this.akoyaAccounts.filter(sa=>sa.isSelected && sa.supported).find(sa => sa.classId === null || sa.categoryId === null)

        if (invalidItem){
            this.setIsShowAccountSelectionMissing(true);
            errorNotification('Please select Category for all selected items');
        } else if (this.akoyaAccounts.filter(sa=>sa.isSelected && sa.supported).length === 0 ) {
            errorNotification('Please select at least one account');
        } else {

            if (!this.isLoading){
                this.isLoading = true;  
                const requestData = this.akoyaAccounts.filter(sa=>sa.isSelected && sa.supported).map(sa=>{
                    return {
                        ...sa,
                        source:'akoya',
                        action:{
                            createNew: true,
                            categoryId: sa.categoryId
                        },
                        connectedInstitution:{
                            institutionName: this.akoyaAccounts[0].institutionName,
                            id: this.akoyaAccounts[0].connectedInstitutionId
                        } 
                    }
                })
                const data = await connectAccounts(requestData,this.akoyaInstitution);
                if (data.statusCode){
                    this.setIsInstitutionConnectFail(true);
                    errorNotification('Something went wrong');
                }
                else{
                    this.setIsInstitutionConnectFail(false);
                    onConnectItems && onConnectItems(data);
                }
                this.setIsLoading(false);
            }

        }

       
    }

    onBoardingPlaidContinue = async(onBoardingStore) => {
        onBoardingStore.setConnectAccountLoading(true);
        const requestData = this.plaidAccounts.filter(pa=>pa.type==='depository').map(pa=>{
            return {
                ...pa,
                source:'plaid',
                action:{
                    createNew: true,
                    categoryId: pa.categoryId
                },
                connectedInstitution:{
                    institutionName: this.institution.name,
                    id: this.institution.id
                } 
            }
        })
        const data = await connectAccounts(requestData,this.institution);
        if (data.statusCode){
            errorNotification('Something went wrong');
        }
        else{
            onBoardingStore.createPlaidSaltedgeAccountsDone(data);
        }
        onBoardingStore.setConnectAccountLoading(false);
    }

    onBoardingSaltedgeContinue = async(onBoardingStore) => {
        onBoardingStore.setConnectAccountLoading(true);
        const requestData = this.saltedgeAccounts.filter(sa=>saltedgeCheckingAccountsNatures.includes(sa.nature)).map(sa=>{
            return {
                ...sa,
                source:'saltEdge',
                action:{
                    createNew: true,
                    categoryId: sa.categoryId
                },
                connectedInstitution:{
                    institutionName: this.institution.name,
                    id: this.institution.id
                } 
            }
        });
        const data = await connectAccounts(requestData,this.institution);
        if (data.statusCode){
            errorNotification('Something went wrong');
        }
        else{
            onBoardingStore.createPlaidSaltedgeAccountsDone(data);
        }
        onBoardingStore.setConnectAccountLoading(false);
    }




    saveItem = async (newItemData,wealthStore,history,isOnBoarding,onAddItem,filtersStore) => {
        const isStocksCategory = this.categoryId === 39;
        const isCryptoCoinsCategory = this.categoryId === 36;
        let isMoreThanOneStock = false;
        let isMoreThanOneCrypto = false;
        if (!this.isSavingItem){
            this.setIsSavingItem(true);
            const itemData = {
                ...newItemData,
                startDate: moment(newItemData.startDate).utc().set('D',15).startOf('D'),
                amortizationMonths: newItemData.amortization?.amount ? ( newItemData.amortization?.amount * (newItemData.amortization?.period === 'Annually' ? 12 : 1 ))  : 0,
                categoryId: this.categoryId
            }
            let createdItem
            if (isStocksCategory) {
                const createdItems = await saveStocks(itemData);
                createdItem = createdItems[0];
                isMoreThanOneStock = createdItems.length > 1;
            } else if (isCryptoCoinsCategory) {
                const createdItems = await saveCryptoCoins(itemData);
                createdItem = createdItems[0];
                isMoreThanOneCrypto = createdItems.length > 1;
            } else {
                createdItem = await saveItem(itemData);
            }
            if (createdItem.statusCode){
                errorNotification('Something went wrong');
            }
            else{
                this.metadataStore.fetchData();
                if (!isOnBoarding){
                    await wealthStore.getData(false,true);
                    if (isMoreThanOneStock || isMoreThanOneCrypto) {
                        filtersStore.handleClearFilterSelections();
                        filtersStore.handleSelectClass(isMoreThanOneStock ? 3 : 9);
                        // filtersStore.handleSelectContainer(createdItem.container);
                        filtersStore.setAppliedFiltersFromSelection();
                        wealthStore.refreshWealthData(false, history);
                        navigateToUrl(history, ROUTES.wealthOverview.url);
                    } else {
                        navigateToUrl(history, ROUTES.wealthAssets.url + '/' +  getUrlDisplayName(createdItem.categoryClass.title) + '/' +createdItem.id);
                    }
                } 
                onAddItem && onAddItem(createdItem);
            }
            this.setIsSavingItem(false);
            return createdItem;
        }
    }

    setIsSavingItem(isSaving) {
        this.isSavingItem = isSaving;
    }
    setIsLoading(isLoading){
        this.isLoading = isLoading;
    }

    createVipAsset = async (onDone) => {
        this.setIsSavingItem(true);
        try {
            await createVipSync(this.selectedVipSyncInstitution.institutionId);
            this.metadataStore.fetchData();
            // successNotification('VIP Institution created');
            onDone?.();
        } catch (error) {
            errorNotification('Something went wrong');
        } finally {
            this.setIsSavingItem(false);
        }
    }

    setIsShowAccountSelectionMissing = (isMissing) => {
        this.isShowAccountSelectionMissing = isMissing;
    }

    get selectedClass(){
        return this.metadataStore.classesObj[this.classId];
    }

    get selectedClassCategories(){
        return this.metadataStore.categories.filter(category=>category.classId === this.classId && !category.isHidden);        
    }

    get selectedCategory(){
        return this.metadataStore.categoriesObj[this.categoryId];
    }

    get plaidSelectedAccountsCount(){
        return this.plaidAccounts.length ? this.plaidAccounts.filter(pa=>pa.isSelected).length : 0;
    }
    
    get saltEdgeSelectedAccountsCount(){
        return this.saltedgeAccounts.length ? this.saltedgeAccounts.filter(sea=>sea.isSelected).length : 0;
    }

    get akoyaSelectedAccountsCount(){
        return this.akoyaAccounts.length ? this.akoyaAccounts.filter(sea=>sea.isSelected).length : 0;
    }

    get plaidCheckingAccounts(){
        return this.plaidAccounts.length ? this.plaidAccounts.filter(pa=>pa.type==='depository') : [];
    }

    get saltedgeCheckingAccounts(){
        return this.saltedgeAccounts.length ? this.saltedgeAccounts.filter(sa=>saltedgeCheckingAccountsNatures.includes(sa.nature)) : [];
    }

    get isAnyProviderInProcess() {
        return this.isSaltedgeInProcess || this.isPlaidInProcess || this.isAkoyaInProcess;
    }

    get hasAnyProviderAccounts() {
        return this.plaidAccounts.length > 0 || this.saltedgeAccounts.length > 0 || this.akoyaAccounts.length > 0;
    }

    get hasAnyInvalidAccountSelection() {
        return this.plaidAccounts.filter(pa=>pa.isSelected).find(pa=> pa.classId === null || pa.categoryId === null) ||
        this.saltedgeAccounts.filter(sa=>sa.isSelected).find(sa=>sa.classId === null || sa.categoryId === null) ||
        this.akoyaAccounts.filter(sa=>sa.isSelected && sa.supported).find(sa => sa.classId === null || sa.categoryId === null);
    }

    get isManuallyStep(){
        return [ADD_ITEM_STEP.CLASS,ADD_ITEM_STEP.CATEGORY,ADD_ITEM_STEP.MANUALLY_FORM].includes(this.step)
    }

    get isSyncingStep(){
        return [ADD_ITEM_STEP.INSTITUTION, ADD_ITEM_STEP.VIP_SYNC,ADD_ITEM_STEP.PLAID_ACCOUNTS,ADD_ITEM_STEP.SALTEDGE_ACCOUNTS].includes(this.step)
    }
    
}