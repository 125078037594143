import { wealthGroupToColor } from "../../../../../../../common/styles/theme.styles";
import { displayMoneyValue, floatFixed, percentageDisplay } from "../../../../../../../common/utils/number-display.utils";
import { liquidityNamesColors } from "../../../../../../add-item/data/common-fields/liquidity-field.const";
import { riskLevelColors } from "../../../../../../add-item/data/common-fields/risk-level-field.const";
import { customClassColorsObj } from "../../../../../../user/components/CustomClassList/icons/custom-colors";
import { weightedAverage } from "../../../../../data/wealth.utils";

export const getFormattedData = (data, metadataStore, allocateBy, trendType, assetsObj) => {
    
    if (trendType === 'current_cash_position'){
        return breakdownByAccounts(data, metadataStore, trendType, assetsObj);
    }

    switch (allocateBy) {
        case 'classes':
            return breakDownByClasses(data, metadataStore, trendType);
        case 'categories':
            return breakDownByCategories(data, metadataStore, trendType);
        case 'customClasses':
            return breakDownByCustomClasses(data, metadataStore, trendType);
        case 'customSubClasses':
            return breakDownBySubCustomClasses(data, metadataStore, trendType);
        case 'entities':
            return breakDownByEntities(data, metadataStore, trendType);
        case 'risk':
            return breakDownByRisk(data, trendType);
        case 'liquidity':
            return breakdownByLiquidity(data, trendType);
        case 'institution':
            return breakdownByInstitution(data, metadataStore, trendType);
        case 'currencies':
            return breakdownByCurrencies(data, trendType);
        case 'accounts':
            return breakdownByAccounts(data, metadataStore, trendType, assetsObj);
        default:
            return breakDownByCategories(data, metadataStore, trendType);
    }
}

const breakdownByCurrencies = (data, trendType = 'total_net_worth') => {
    const formattedData = data.map((item) => {
        const breakdown = item.breakdown;
        const newBreakdown = {};
        for (let key in breakdown) {
            const name = breakdown[key].currency ?? 'USD';
            if (!newBreakdown[name]) {
                const color = wealthGroupToColor(name);
                newBreakdown[name] = { 
                    value: 0, 
                    color, 
                    name,
                    ...(trendType === 'xirr' && { itemsToCalculate: [] })
                };
            }

            if (trendType === 'xirr') {
                const {value, weight} = breakdown[key];
                if (weight) {
                    newBreakdown[name].itemsToCalculate.push({value, weight});
                }
            } else {
                const value = breakdown[key].value;
                newBreakdown[name].value += value;
            }
        }

        if (trendType === 'xirr') {
            for (let key in newBreakdown) {
                const {itemsToCalculate} = newBreakdown[key];
                const weightedAverageValue = weightedAverage(itemsToCalculate);
                newBreakdown[key].value = weightedAverageValue || 0;
                delete newBreakdown[key].itemsToCalculate;
            }
        }

        const ret = {
            date: item.date,
            value: item.value,
            breakdown: newBreakdown
        };

        return ret;
    });

    return formattedData;
};

const breakdownByAccounts = (data, metadataStore, trendType = 'total_net_worth', assetsObj) => {
    const categoriesObj = metadataStore.categoriesObj;
    const classesObj = metadataStore.classesObj;
    const formattedData = data.map((item) => {
        const breakdown = item.breakdown;
        const newBreakdown = {};
        let tempInd = 0;
        for (let key in breakdown) {
            const itemDetails = assetsObj[key];
            const breakdownKey =  itemDetails?.container && !itemDetails.isConnected ? itemDetails.container : itemDetails.title + tempInd++;
            const name = itemDetails?.container ? itemDetails.container : itemDetails.title;
            if (!breakdownKey) {
                continue;
            }

            if (!newBreakdown[breakdownKey]) {
                const categoryId = itemDetails?.categoryId;
                const classId = categoriesObj[categoryId]?.classId;
                const relevantClass = classesObj[classId];
                const color = relevantClass?.color ?? '#FFFFFF';
                newBreakdown[breakdownKey] = { 
                    value: 0, 
                    color, 
                    name,
                    ...(trendType === 'xirr' && { itemsToCalculate: [] })
                };
            }

            if (trendType === 'xirr') {
                const {value, weight} = breakdown[key];
                if (weight) {
                    newBreakdown[breakdownKey].itemsToCalculate.push({value, weight});
                }
            } else {
                const value = breakdown[key].value;
                newBreakdown[breakdownKey].value += value;
            }
    
        }

        if (trendType === 'xirr') {
            for (let key in newBreakdown) {
                const {itemsToCalculate} = newBreakdown[key];
                const weightedAverageValue = weightedAverage(itemsToCalculate);
                newBreakdown[key].value = weightedAverageValue || 0;
                delete newBreakdown[key].itemsToCalculate;
            }
        }

        const ret = {
            date: item.date,
            value: item.value,
            breakdown: newBreakdown
        };

        return ret;
    });

    return formattedData;
};

const breakDownByClasses = (data, metadataStore, trendType = 'total_net_worth') => {
    const categoriesObj = metadataStore.categoriesObj;
    const formattedData = data.map((item) => {
        const ret = {};
        ret.date = item.date;
        ret.breakdown = {};
        const breakdown = item.breakdown;
        for (let key in breakdown) {
            const categoryId = breakdown[key].categoryId;
            const classId = categoriesObj[categoryId]?.classId;
            if (!classId) {
                continue;
            }

            if (!ret.breakdown[classId]) {
                const classesObj = metadataStore.classesObj;
                const relevantClass = classesObj[classId];

                const color = relevantClass?.color ?? '#FFFFFF';
                const name = relevantClass?.title ?? 'Unspecified';
                ret.breakdown[classId] = { 
                    value: 0, 
                    color, 
                    name,
                    ...(trendType === 'xirr' && { itemsToCalculate: [] })
                };
            }

            if (trendType === 'xirr') {
                const {value, weight} = breakdown[key];
                if (weight) {
                    ret.breakdown[classId].itemsToCalculate.push({value, weight});
                }
            } else {
                const value = breakdown[key].value;
                ret.breakdown[classId].value += value;
            }
        }

        if (trendType === 'xirr') {
            for (let key in ret.breakdown) {
                const {itemsToCalculate} = ret.breakdown[key];
                const weightedAverageValue = weightedAverage(itemsToCalculate);
                ret.breakdown[key].value = weightedAverageValue || 0;
                delete ret.breakdown[key].itemsToCalculate; // Clean up temporary data
            }
        }

        ret.value = item.value;
        return ret;
    });

    return formattedData;
};

const breakDownByCategories = (data, metadataStore, trendType = 'total_net_worth') => {
    const categoriesObj = metadataStore.categoriesObj;
    const formattedData = data.map((item) => {
        const ret = {};
        ret.date = item.date;
        ret.breakdown = {};
        const breakdown = item.breakdown;
        for (let key in breakdown) {
            const categoryId = breakdown[key].categoryId;
            if (!ret.breakdown[categoryId]) {
                const classId = categoriesObj[categoryId]?.classId || null;
                const relevantClass = classId ? metadataStore.classes.find(cls => cls.id === classId) : null;
                const color = relevantClass?.color ?? '#FFFFFF';
                const name = categoriesObj[categoryId]?.title || '';
                ret.breakdown[categoryId] = { 
                    value: 0, 
                    color, 
                    name,
                    ...(trendType === 'xirr' && { itemsToCalculate: [] })
                };
            }

            if (trendType === 'xirr') {
                const {value, weight} = breakdown[key];
                if (weight) {
                    ret.breakdown[categoryId].itemsToCalculate.push({value, weight});
                }
            } else {
                const value = breakdown[key].value;
                ret.breakdown[categoryId].value += value;
            }
        }

        if (trendType === 'xirr') {
            for (let key in ret.breakdown) {
                const {itemsToCalculate} = ret.breakdown[key];
                const weightedAverageValue = weightedAverage(itemsToCalculate);
                ret.breakdown[key].value = weightedAverageValue || 0;
                delete ret.breakdown[key].itemsToCalculate; // Clean up temporary data
            }
        }

        ret.value = item.value;
        return ret;
    });

    return formattedData;
};

const breakDownByCustomClasses = (data, metadataStore, trendType = 'total_net_worth') => {
    const customClasses = metadataStore.customClasses;
    const formattedData = data.map((item) => {
        const breakdown = item.breakdown;
        const newBreakdown = {};
        for (let key in breakdown) {
            const customClassId = breakdown[key].customClassId ?? 'unspecified';
            if (!newBreakdown[customClassId]) {
                const customClass = customClasses.find(cls => cls.id === customClassId);
                if (customClass) {
                    const name = customClass.name;
                    const color = customClassColorsObj[customClass.colorCode]?.fallbackColor ?? '#FFFFFF';
                    newBreakdown[customClassId] = { 
                        name, 
                        color, 
                        value: 0,
                        ...(trendType === 'xirr' && { itemsToCalculate: [] })
                    };
                } else {
                    newBreakdown[customClassId] = { 
                        name: 'Unspecified', 
                        color: '#FFFFFF', 
                        value: 0,
                        ...(trendType === 'xirr' && { itemsToCalculate: [] })
                    };
                }
            }

            if (trendType === 'xirr') {
                const {value, weight} = breakdown[key];
                if (weight) {
                    newBreakdown[customClassId].itemsToCalculate.push({value, weight});
                }
            } else {
                const value = breakdown[key].value;
                newBreakdown[customClassId].value += value;
            }
        }

        if (trendType === 'xirr') {
            for (let key in newBreakdown) {
                const {itemsToCalculate} = newBreakdown[key];
                const weightedAverageValue = weightedAverage(itemsToCalculate);
                newBreakdown[key].value = weightedAverageValue || 0;
                delete newBreakdown[key].itemsToCalculate;
            }
        }

        const ret = {
            date: item.date,
            value: item.value,
            breakdown: newBreakdown
        };

        return ret;
    });

    return formattedData;
};

const breakDownBySubCustomClasses = (data, metadataStore, trendType = 'total_net_worth') => {
    const customClasses = metadataStore.customClasses;
    const formattedData = data.map((item) => {
        const breakdown = item.breakdown;
        const newBreakdown = {};
        for (let key in breakdown) {
            const customClassId = breakdown[key].customClassId;
            const customSubClassId = breakdown[key].customSubClassId;
            const breakdownKey = (customClassId && customSubClassId) ? `${customClassId}-${customSubClassId}` : 'unspecified';
            if (!newBreakdown[breakdownKey]) {
                newBreakdown[breakdownKey] = { 
                    name: 'Unspecified', 
                    color: '#FFFFFF', 
                    value: 0,
                    ...(trendType === 'xirr' && { itemsToCalculate: [] })
                };

                const customClass = customClasses.find(cls => cls.id === customClassId);
                if (customClass) {
                    const customSubClass = customClass.customSubClasses.find(subCls => subCls.id === customSubClassId);
                    const color = customClassColorsObj[customClass.colorCode]?.fallbackColor ?? '#FFFFFF';
                    newBreakdown[breakdownKey].color = color;
                    if (customSubClass) {
                        newBreakdown[breakdownKey].name = customSubClass.name;
                    }
                }
            }

            if (trendType === 'xirr') {
                const {value, weight} = breakdown[key];
                if (weight) {
                    newBreakdown[breakdownKey].itemsToCalculate.push({value, weight});
                }
            } else {
                const value = breakdown[key].value;
                newBreakdown[breakdownKey].value += value;
            }
        }

        if (trendType === 'xirr') {
            for (let key in newBreakdown) {
                const {itemsToCalculate} = newBreakdown[key];
                const weightedAverageValue = weightedAverage(itemsToCalculate);
                newBreakdown[key].value = weightedAverageValue || 0;
                delete newBreakdown[key].itemsToCalculate;
            }
        }

        const ret = {
            date: item.date,
            value: item.value,
            breakdown: newBreakdown
        };

        return ret;
    });

    return formattedData;
};

const breakDownByEntities = (data, metadataStore, trendType = 'total_net_worth') => {
    const beneficiaries = metadataStore.beneficiaries;
    const formattedData = data.map((item) => {
        const breakdown = item.breakdown;
        const newBreakdown = {};
        for (let key in breakdown) {
            const beneficiary = breakdown[key].beneficiary;
            const beneficiaryKey = beneficiary?.trim()?.toLowerCase()?.replace(/\s+/g, '_')?.replace(/[^a-z0-9_]/g, '');
            if (!newBreakdown[beneficiaryKey]) {
                newBreakdown[beneficiaryKey] = { 
                    name: beneficiary, 
                    color: '#FFFFFF', 
                    value: 0,
                    ...(trendType === 'xirr' && { itemsToCalculate: [] })
                };
            }

            const entity = beneficiaries.find(ben => ben.name === beneficiary);
            if (entity) {
                newBreakdown[beneficiaryKey].color = entity.colorCode;
            }

            if (trendType === 'xirr') {
                const {value, weight} = breakdown[key];
                if (weight) {
                    newBreakdown[beneficiaryKey].itemsToCalculate.push({value, weight});
                }
            } else {
                const value = breakdown[key].value;
                newBreakdown[beneficiaryKey].value += value;
            }
        }

        if (trendType === 'xirr') {
            for (let key in newBreakdown) {
                const {itemsToCalculate} = newBreakdown[key];
                const weightedAverageValue = weightedAverage(itemsToCalculate);
                newBreakdown[key].value = weightedAverageValue || 0;
                delete newBreakdown[key].itemsToCalculate;
            }
        }

        const ret = {
            date: item.date,
            value: item.value,
            breakdown: newBreakdown
        };

        return ret;
    });

    return formattedData;
};

const breakDownByRisk = (data, trendType = 'total_net_worth') => {
    const formattedData = data.map((item) => {
        const breakdown = item.breakdown;
        const newBreakdown = {};
        for (let key in breakdown) {
            const riskLevel = breakdown[key].riskLevel ?? 'Unspecified';
            if (!newBreakdown[riskLevel]) {
                const color = riskLevelColors[riskLevel] ?? '#FFFFFF';
                newBreakdown[riskLevel] = { 
                    name: riskLevel, 
                    color, 
                    value: 0,
                    ...(trendType === 'xirr' && { itemsToCalculate: [] })
                };
            }

            if (trendType === 'xirr') {
                const {value, weight} = breakdown[key];
                if (weight) {
                    newBreakdown[riskLevel].itemsToCalculate.push({value, weight});
                }
            } else {
                const value = breakdown[key].value;
                newBreakdown[riskLevel].value += value;
            }
        }

        if (trendType === 'xirr') {
            for (let key in newBreakdown) {
                const {itemsToCalculate} = newBreakdown[key];
                const weightedAverageValue = weightedAverage(itemsToCalculate);
                newBreakdown[key].value = weightedAverageValue || 0;
                delete newBreakdown[key].itemsToCalculate;
            }
        }

        const ret = {
            date: item.date,
            value: item.value,
            breakdown: newBreakdown
        };

        return ret;
    });

    return formattedData;
};

const breakdownByLiquidity = (data, trendType = 'total_net_worth') => {
    const formattedData = data.map((item) => {
        const breakdown = item.breakdown;
        const newBreakdown = {};
        for (let key in breakdown) {
            const liquidity = breakdown[key].liquidity ?? 'Unspecified';
            if (!newBreakdown[liquidity]) {
                const liquidityNameColor = liquidityNamesColors[liquidity];
                const color = liquidityNameColor?.color ?? '#FFFFFF';
                const name = (liquidityNameColor?.name ?? 'Unspecified');
                newBreakdown[liquidity] = { 
                    name, 
                    color, 
                    value: 0,
                    ...(trendType === 'xirr' && { itemsToCalculate: [] })
                };
            }

            if (trendType === 'xirr') {
                const {value, weight} = breakdown[key];
                if (weight) {
                    newBreakdown[liquidity].itemsToCalculate.push({value, weight});
                }
            } else {
                const value = breakdown[key].value;
                newBreakdown[liquidity].value += value;
            }
        }

        if (trendType === 'xirr') {
            for (let key in newBreakdown) {
                const {itemsToCalculate} = newBreakdown[key];
                const weightedAverageValue = weightedAverage(itemsToCalculate);
                newBreakdown[key].value = weightedAverageValue || 0;
                delete newBreakdown[key].itemsToCalculate;
            }
        }

        const ret = {
            date: item.date,
            value: item.value,
            breakdown: newBreakdown
        };

        return ret;
    });

    return formattedData;
};

const breakdownByInstitution = (data, metadataStore, trendType = 'total_net_worth') => {
    const institutions = metadataStore.institutions;
    const institutionDictionary = {};
    institutions.forEach((institution, index) => {
        institutionDictionary[institution] = institutionColors[(index % institutionColors.length)];
    });
    institutionDictionary['Unspecified'] = '#FFFFFF';

    const formattedData = data.map((item) => {
        const breakdown = item.breakdown;
        const newBreakdown = {};
        for (let key in breakdown) {
            const institution = (breakdown[key].name && ('' !== breakdown[key].name)) ? breakdown[key].name : 'Unspecified';
            if (!newBreakdown[institution]) {
                const color = institutionDictionary[institution];
                newBreakdown[institution] = { 
                    name: institution, 
                    color, 
                    value: 0,
                    ...(trendType === 'xirr' && { itemsToCalculate: [] })
                };
            }

            if (trendType === 'xirr') {
                const {value, weight} = breakdown[key];
                if (weight) {
                    newBreakdown[institution].itemsToCalculate.push({value, weight});
                }
            } else {
                const value = breakdown[key].value;
                newBreakdown[institution].value += value;
            }
        }

        if (trendType === 'xirr') {
            for (let key in newBreakdown) {
                const {itemsToCalculate} = newBreakdown[key];
                const weightedAverageValue = weightedAverage(itemsToCalculate);
                newBreakdown[key].value = weightedAverageValue || 0;
                delete newBreakdown[key].itemsToCalculate;
            }
        }

        const ret = {
            date: item.date,
            value: item.value,
            breakdown: newBreakdown
        };

        return ret;
    });

    return formattedData;
};

const institutionColors = ['#B3A1E7', '#A0ADB8', '#AAC8D4', '#C3B4B6', '#C2918B', '#EAB6D0', '#EB9583', '#E9AE7A', '#F7D595', '#CECAAC', '#B4C1B2', '#78A6B4', '#65AE9F', '#BCAEA4', '#AC9C77', '#D5C972'];

export const tooltipFunc = (context, wealthStore) => {
    const { chart, tooltip } = context;

    if (!tooltip || tooltip.opacity === 0 || !tooltip.dataPoints || !tooltip.dataPoints[0]) {
        setTimeout(() => wealthStore.setTrendsTooltipData(null), 500);
        return;
    }

    const position = chart.canvas.getBoundingClientRect();
    //const tooltipWidth = tooltip.getBoundingClientRect();
    const dataPoint = tooltip.dataPoints[0];
    wealthStore.setTrendsTooltipData({
        left: (position.left + window.scrollX + tooltip.caretX) - (317 * (tooltip.caretX / chart.width)),
        top: (position.top + window.scrollY + tooltip.caretY),
        index: dataPoint.dataIndex
    })
};

export const displayNumberByTrendsType = (value, trendType, currency) => {
    switch (trendType) {
        case 'total_net_worth':
        case 'current_cash_position':
            return displayMoneyValue(value.toFixed(0), currency);
        case 'xirr':
            return percentageDisplay(value, 1);
        default:
            return '';
    }
};

export const getDiffByTrendsType = (firstValue, lastValue, trendType) => {

    switch (trendType) {
        case 'total_net_worth':
        case 'current_cash_position':
            return floatFixed(((lastValue - firstValue) / (firstValue || 1)) * 100);
        case 'xirr':
            return floatFixed((lastValue - firstValue) * 100);
        default:
            return '';
    }
}