import { DatePicker, Input } from 'antd';
import moment from 'moment';
import React, { useState } from 'react'
import NumberFormat from 'react-number-format';
import { DeleteIcon, PlusIcon } from '../../../../../assets/icons/common/common-icons';
import { colors } from '../../../../styles/theme.styles';
import { disabledMoreThanToday } from '../../../../utils/dates.utils';
import { displayMoneyValue, floatFixed, numberWithCommas } from '../../../../utils/number-display.utils';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../../../utils/object.utils';
import { SimpleSelect } from '../../../DropDownMenu/SimpleSelect';
import { ActionButtonWrapper, CreateButton, HeaderCell, HoldingsBadgeText, HoldingsBadgeTriangle, HoldingsBadgeWrapper, InputWithBadgeWrapper,
  PlusIconWrapper, RowActionsCell, RowCell, RowWrapper, sharesTradingTableSelectButtonCustomStyles, TableBottomActions, TableHeaderRow, TableScrollContent,
  TotalInvestmentAmount, TotalInvestmentHeader, TotalNumberOfSharesAmount, TotalNumberOfSharesHeader, TotalsRow, Wrapper, ClearIcon } from './SharesTradingTable.styles';
import { CloseCircleFilled } from '@ant-design/icons';

const tableColumns = ['Date', 'Event', 'PPS', 'Shares +/-', 'Shares type', 'Amount', 'Notes'];
const eventTypeOptions = [
  { value: 'Buy', label: 'Buy' },
  { value: 'Sell', label: 'Sell' },
  { value: 'Grant', label: 'Grant' },
];

export const sharesTypeOptionsDefault = ['Ordinary','Preferred'];
// const sharesTypeOptionsDefault = [
//   { value: 'Ordinary', label: 'Ordinary'  },
//   { value: 'Preffered', label: 'Preffered' },
// ];

export const SharesTradingTable = (props) => {
  const { onChange,
    // onBlur,
    value, rowValuePreFix, withError,
    // startDate,
    holdings } = props;
  const [editedRow, setEditedRow] = useState(value.length ? value[value.length - 1].id || ('t' + value[value.length - 1].tempId) : 0);
  const [sharesTypeOptions, setSharesTypeOptions] = useState(sharesTypeOptionsDefault);

  const handleAddRowClick = (rows) => {
    // const mostRecentDate = value.length > 0 ? value[value.length - 1].date : startDate ? moment(startDate).subtract(1, 'y') : moment().subtract(1, 'y');
    // console.log(value.map(u=>u.tempId));
    const maxValueOfTempId = Math.max(...value.map(u => u.tempId || 0), 0);
    let lastPricePerShare = '';
    if (rows.length) {
      lastPricePerShare = rows.filter(row => row.pricePerShare !== '').sort((a, b) => new Date(b.date) - new Date(a.date))[0]?.pricePerShare || '';
    }

    const newRow = {
      date: moment().utc().set("D",15).startOf("D"), //'',
      // date: moment(mostRecentDate).add(1, 'y') || new Date(),
      eventType: eventTypeOptions[0].value,
      sharesEventValue: '',
      numberOfShares: '',
      pricePerShare: lastPricePerShare,
      remarks: '',
      tempId: maxValueOfTempId + 1,
      // isTouched: false,
    }
    setEditedRow('t' + (maxValueOfTempId + 1));
    onChange && onChange([...value, newRow]);
    // onChange && onChange(updatedSortedSchedule);
    // setScheduleHistory([...scheduleHistory, newRow])
  }

  const handleAttributeRowUpdated = (updatedIndex, data, key) => {
    let updatedSortedSchedule = value.map((item, index) => (index === updatedIndex ? data : item));
    if (key === 'date') {
      updatedSortedSchedule = updatedSortedSchedule.sort((a, b) => new Date(a.date) - new Date(b.date));
    }
    onChange && onChange(updatedSortedSchedule);
  }

  const customSharesTypeInputBlurred = () => {
      // const uniqueUserIds = [...new Set(returnedIds)]
      const uniqueCustomValues = [...new Set(value.map(row=>row.sharesType).filter(typeVal=>!sharesTypeOptionsDefault.includes(typeVal) && !isNullOrUndefinedOrEmptyString(typeVal)))];
      setSharesTypeOptions([...sharesTypeOptionsDefault,...uniqueCustomValues]);
  }

  const handleAttributeRowDeleted = (deletedIndex) => {
    const updatedSchedule = value.filter((item, index) => index !== deletedIndex);
    onChange && onChange(updatedSchedule);
  }

  const getTotalInvestmentValue = () => {
    return displayMoneyValue(
      value?.reduce((acc, row) => acc + (isNullOrUndefinedOrEmptyString(row.sharesEventValue) ? 0 : row.sharesEventValue * (row.eventType === 'Sell' ? -1 : 1)), 0),
      rowValuePreFix
    )
  }
  

  return (
    <Wrapper>
      <TableHeaderRow onClick={(e) => { e.stopPropagation() }} isWithHoldings={holdings !== 100}>
        {tableColumns.map((columnLabel, ind) => (
          <HeaderCell key={'hc1' + ind} >
            <span>{columnLabel}</span>
          </HeaderCell>
        ))}
      </TableHeaderRow>
      <TableScrollContent>
        {value?.map((row, index) => (
          <HistoryRow key={row.id || ('t' + row.tempId)}
            rowValuePreFix={rowValuePreFix} index={index}
            item={row} atrKey={row.atrKey}
            withError={withError}
            isEditMode={row.id ? (editedRow === row.id) : (editedRow === 't' + row.tempId)}
            setEditMode={() => setEditedRow(row.id ? row.id : 't' + row.tempId)}
            holdings={holdings}
            rowUpdated={(data, key) => { handleAttributeRowUpdated(index, data, key) }}
            sharesTypeOptions={sharesTypeOptions}
            customSharesTypeInputBlurred={customSharesTypeInputBlurred}
            setSharesTypeOptions={setSharesTypeOptions}
            // rowBlurred={() => { onBlur && onBlur(); }}
            rowDeleted={() => handleAttributeRowDeleted(index)} />
        ))}
      </TableScrollContent>
      <TableBottomActions>
        <CreateButton onClick={() => { handleAddRowClick(value) }}>
          {/* <AddIcon /> */}
          <PlusIcon />
          <span className='button-text'>Add</span>
        </CreateButton>
        <TotalsRow>
          <RowCell></RowCell>
          <RowCell></RowCell>
          <RowCell style={{ flexDirection: 'column' }}>
            <TotalNumberOfSharesHeader>Number of shares</TotalNumberOfSharesHeader>
            <TotalNumberOfSharesAmount>{numberWithCommas(floatFixed(value?.reduce((acc, row) => acc + (isNullOrUndefinedOrEmptyString(row.numberOfShares) ? 0 : row.numberOfShares  * (row.eventType === 'Sell' ? -1 : 1)), 0), 2))}</TotalNumberOfSharesAmount>
          </RowCell>
          <RowCell></RowCell>
          <RowCell style={{ flexDirection: 'column' }}>
            <TotalInvestmentHeader>Total investment</TotalInvestmentHeader>
            <TotalInvestmentAmount>{getTotalInvestmentValue()} </TotalInvestmentAmount>
          </RowCell>
          <RowCell></RowCell>
        </TotalsRow>
        {/* <button style={{width:'80%', alignSelf:'center'}} onClick={handleAddRowClick}>Add row</button> */}
      </TableBottomActions>
    </Wrapper>
  )
}

const HistoryRow = (props) => {
  const { TextArea } = Input;
  const { item, rowDeleted, isEditMode, setEditMode, rowValuePreFix, holdings, withError, sharesTypeOptions, customSharesTypeInputBlurred } = props;
  const editModeStyle = { borderColor: 'transparent', backgroundColor: 'transparent' };
  const [isSharesTypeEditMode, setIsSharesTypeEditMode] = useState(false);
  const [isCellHover, setIsCellHover] = useState(false);

  const handleFieldChange = (field, value) => {
    props.rowUpdated({
      ...item,
      [field]: value
    }, field)
  }

  const handleFieldBlur = (field) => {
    // if (item.isTouched) {
    //   return;
    // }
    const watchedFieldsArray = [item.sharesEventValue, item.numberOfShares, item.pricePerShare];
    
    if (watchedFieldsArray.filter(i => i).length > 1) {
      
      let change = {} //{isTouched: true};
      if (field === 'numberOfShares' && item.numberOfShares) {
        if (item.pricePerShare) {
          change.sharesEventValue = floatFixed(Math.abs(item.numberOfShares) * item.pricePerShare, 2);
        } 
        else {
          change.pricePerShare = floatFixed(item.sharesEventValue / Math.abs(item.numberOfShares), 4);
        }
      }
      else if (field === 'pricePerShare' && item.pricePerShare) {
        if (item.numberOfShares) {
          change.sharesEventValue = floatFixed(Math.abs(item.numberOfShares) * item.pricePerShare, 2);
        } 
        else {
          change.numberOfShares = floatFixed(item.sharesEventValue / item.pricePerShare, 2);
        }
      } else if (field === 'sharesEventValue' && item.sharesEventValue) {
        if (item.numberOfShares) {
          change.pricePerShare = floatFixed(item.sharesEventValue / Math.abs(item.numberOfShares), 4);
        } 
        else {
          change.numberOfShares = floatFixed(item.sharesEventValue / item.pricePerShare, 2);
        }
      }

      props.rowUpdated({
        ...item,
        ...change
      }, '');
    }
  }

  // const cancelChanges = () => {
  //     setRowData(defaultValues);
  //     setIsEdit(false);
  // }

  // const saveChanges = () => {
  //     if (rowData.value || rowData.value === 0){
  //         setIsEdit(false);
  //         // props.rowUpdated && props.rowUpdated({...item,isEdit:false});
  //     } else {
  //         errorNotification(`Can't save empty value.\nPlease set value or delete the update`,{
  //             style:{whiteSpace:'pre-line'}
  //         });
  //     }
  // }

  // const approveDelete = () => {
  //     // setIsDelete(false);
  //     props.rowDeleted && props.rowDeleted();
  // }

  const handleShareTypeChange = (val) => {
    if (val == null) {
      handleFieldChange('sharesType', val);
      setIsSharesTypeEditMode(false);
    }
    if (val === 'custom') {
      handleFieldChange('sharesType', '');
      setIsSharesTypeEditMode(true);  
    }
    else {
      handleFieldChange('sharesType', val);
    }
  }

  const handleCustomSharesTypeInputBlur = (e) => {
    customSharesTypeInputBlurred()
    // const newOption = e.target.value;
    // if(newOption === '') {
    //   setIsSharesTypeEditMode(false)
    // }
    // else if(!sharesTypeOptions.includes({value: newOption, label: newOption})) {
    //   setSharesTypeOptions([...sharesTypeOptions, {value: newOption, label: newOption}]);
    // }
  }

  return (
    <RowWrapper onClick={(e) => { e.stopPropagation(); setEditMode() }} isWithHoldings={holdings !== 100}>
      <RowCell>
        {/* { isEditMode ?  */}
        <DatePicker picker="month" disabled={item.eventId}
          disabledDate={disabledMoreThanToday}
          // onFocus={() => { setEditMode() }}
          className={`innerTable`}
          allowClear={false}
          format={'MM-YYYY'}
          placeholder=''
          inputReadOnly
          style={isEditMode ? { height: '32px' } : { borderColor: 'transparent', backgroundColor: 'transparent', height: '32px' }}
          {...(item.date ? { suffixIcon: null } : {})}
          value={item.date ? moment(item?.date) : undefined} onChange={(val) => { handleFieldChange('date', (val ? val.format() : item?.date)) }} />
        {/* :getDisplayDate(item.date) } */}
      </RowCell>
      <RowCell>
        <SimpleSelect value={item.eventType}
          className={`innerTable`}
          buttonStyle={sharesTradingTableSelectButtonCustomStyles(isEditMode)}
          placement={"bottomLeft"}
          options={
            [...eventTypeOptions.map(opt => ({ value: opt.value, label: opt.label }))]}
          onChange={(val) => { handleFieldChange('eventType', val) }} />
      </RowCell>
      <RowCell>
        <NumberFormat
          thousandSeparator={true}
          customInput={Input}
          prefix={rowValuePreFix}
          className={`innerTable`}
          allowNegative={false}
          placeholder={isEditMode ? rowValuePreFix : ''}
          style={{ width: '100%', ...(isEditMode ? {} : editModeStyle), ...(holdings !== 100 ? { paddingLeft: '30px', color: colors.holdingsValueColor } : {}) }}
          decimalScale={4}
          value={(isNullOrUndefinedOrEmptyString(item?.pricePerShare)) ? '' : parseFloat(Math.abs((+item?.pricePerShare).toFixed(4)))}
          autoComplete="off"
          onBlur={()=>handleFieldBlur('pricePerShare')}
          onValueChange={(values) => { handleFieldChange('pricePerShare', ( values.floatValue || values.floatValue === 0 ) ? values.floatValue : '') }}
        />
      </RowCell>
      <RowCell>
        <NumberFormat
          thousandSeparator={true}
          customInput={Input}
          className={`innerTable${withError && isNullOrUndefinedOrEmptyString(item?.numberOfShares)  ? ' inputEntryError' : ''}`}
          allowNegative={false}
          style={{ ...(isEditMode ? {} : editModeStyle) }}
          value={(isNullOrUndefinedOrEmptyString(item.numberOfShares)) ? '' : parseFloat((+item?.numberOfShares).toFixed(2))}
          onBlur={() => { handleFieldBlur('numberOfShares')}}
          onValueChange={(values) => { handleFieldChange('numberOfShares', values.floatValue) }}
        />
      </RowCell>
      <RowCell isEditMode={isEditMode} 
            onMouseEnter={() => setIsCellHover(true)}
            onMouseLeave={() => setIsCellHover(false)}>
        {isSharesTypeEditMode ?
          <Input 
            autoFocus 
            type='text' 
            className='innerTable' 
            value={item.sharesType} 
            style={{ ...(isEditMode ? {} : editModeStyle) }} 
            suffix={<ClearIcon className={isCellHover && item.sharesType ? 'clear-input-hover' : null} onClick={() => handleShareTypeChange(null)}><CloseCircleFilled /></ClearIcon>} 
            onChange={(e) => { handleFieldChange('sharesType', e.target.value) }} 
            onBlur={(e) => {handleCustomSharesTypeInputBlur(e)}}
            />
          :
          <SimpleSelect value={item.sharesType}
            buttonStyle={sharesTradingTableSelectButtonCustomStyles(isEditMode)}
            allowClear={true}
            placement={"bottomLeft"}
            options={
              [...sharesTypeOptions.map(opt => ({ value: opt, label: opt })),
              {
                value: 'custom', label: <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}><PlusIconWrapper><PlusIcon /></PlusIconWrapper>Add</div>
              }]}
            onChange={(val) => { handleShareTypeChange(val) }  } />
        }
      </RowCell>
      <RowCell>
        {!isNullOrUndefined(holdings) && holdings !== 100 && <NumberFormat
          thousandSeparator={true}
          customInput={Input}
          prefix={rowValuePreFix}
          placeholder={isEditMode ? rowValuePreFix : ''}
          style={{ width: '80%', ...(isEditMode ? {} : editModeStyle) }}
          value={(isNullOrUndefinedOrEmptyString(item?.sharesEventValue)) ? '' : parseFloat((item?.sharesEventValue * (holdings / 100)).toFixed(2))}
          autoComplete="off"
          allowNegative={false}
          onBlur={()=>handleFieldBlur('sharesEventValue')}
          onValueChange={(values) => {
            if ('' + item?.sharesEventValue !== values.value)
              handleFieldChange('sharesEventValue', (values.floatValue || values.floatValue === 0) ? values.floatValue * (100 / holdings) : null)
          }}
        />}
        <InputWithBadgeWrapper>
          <NumberFormat
            thousandSeparator={true}
            customInput={Input}
            className={`innerTable${withError && isNullOrUndefinedOrEmptyString(item?.sharesEventValue) && item?.eventType !== 'Grant' ? ' inputEntryError' : ''}`}
            prefix={rowValuePreFix}
            allowNegative={false}
            placeholder={isEditMode ? rowValuePreFix : ''}
            style={{ width: '100%', ...(isEditMode ? {} : editModeStyle), ...(holdings !== 100 ? { paddingLeft: '30px', color: colors.holdingsValueColor } : {}) }}
            //  style={isIncognito && field.shouldBeHiddenIncognito ? {WebkitTextSecurity:'disc'}:{}} 
            value={(isNullOrUndefinedOrEmptyString(item?.sharesEventValue)) ? '' : parseFloat((+item?.sharesEventValue).toFixed(2))}
            autoComplete="off"
            onBlur={()=>handleFieldBlur('sharesEventValue')}
            onValueChange={(values) => { handleFieldChange('sharesEventValue', values.floatValue) }}
          />
          {holdings !== 100 && <HoldingsBadgeWrapper><HoldingsBadgeText></HoldingsBadgeText><HoldingsBadgeTriangle /></HoldingsBadgeWrapper>}
        </InputWithBadgeWrapper>
      </RowCell>
      <RowCell>
        <TextArea rows={1}
          // onFocus={() => { setEditMode() }}
          className={'innerTable'}
          style={{ resize: 'none', ...(isEditMode ? { borderRadius: '0 4px 4px 0', } : editModeStyle) }}
          value={item?.remarks} onChange={(e) => { handleFieldChange('remarks', e.target.value) }} />
      </RowCell>
      <RowActionsCell>
        {/* { isEditMode &&      */}
        <ActionButtonWrapper
          //onClick={()=>{showDeleteUpdateConfirm()}}>
          // onClick={()=>{setIsDelete(true)}} >
          onClick={() => { rowDeleted && rowDeleted() }} >
          <DeleteIcon />
        </ActionButtonWrapper>
        {/* } */}
      </RowActionsCell>
      {/* <RowActionsCell show={isEdit || isDelete}>
              {isEdit ? <>
                  <SaveButton onClick={saveChanges}>Save</SaveButton>
                  <ActionButtonWrapper onClick={cancelChanges}>
                      <CloseXIcon />
                  </ActionButtonWrapper>
              </> : (
              isDelete ? <>
                  <SaveButton onClick={approveDelete}>Delete</SaveButton>
                  <ActionButtonWrapper onClick={()=>{setIsDelete(false)}}>
                      <CloseXIcon />
                  </ActionButtonWrapper>
              </>:
              <>
                  <ActionButtonWrapper style={{marginRight:'16px'}} onClick={()=>{setIsEdit(!isEdit)}} >
                      <EditIcon />
                  </ActionButtonWrapper>
                  <ActionButtonWrapper 
                      //onClick={()=>{showDeleteUpdateConfirm()}}>
                      onClick={()=>{setIsDelete(true)}} >
                      <DeleteIcon />
                  </ActionButtonWrapper>
              </>)}
          </RowActionsCell> */}
    </RowWrapper>
  )
}