export const isNullOrUndefined = (obj) => {
    return obj === null || obj === undefined;
}

export const isNullOrUndefinedOrEmptyString = (obj) => {
    return obj === null || obj === undefined || obj === '';
}

export const isNullOrUndefinedOrZeroOrEmptyString = (obj) => {
    return obj === null || obj === undefined || obj === 0 || obj === '';
}

export const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
}

export const getValueByPath = (obj, path) => {
    path = path.replace(/\[(\w+)\]/g, '.$1')
    path = path.replace(/^\./, '')
    var a = path.split('.')
    var o = obj
    while (a.length) {
      var n = a.shift()
      if (o === null) return null;
      if (!(n in o)) return
      o = o[n]
    }
    return o
  }

  export const isNullOrUndefinedOrEmptyStringOrNan = (obj) => {
    return obj === null || obj === undefined || obj === '' || isNaN(obj);
  }

export const clearUrl = (url) => {
  return url.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/\/$/, '');
}