import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../../../../common/styles/combos.styles";
import { colors, shadows, views } from "../../../../../../../common/styles/theme.styles";
import { MenuWrapper, MenuItemWrapper, MenuItemIconWrapper, MenuItemText } from "../../../../../../../common/components/DropDownMenu/DropDownMenu.styles";
import { ReactComponent as StatusHideCanceledEvents} from './assets/status-hide-event-icon.svg';
import { PrimaryButtonWithIcon } from "../../../../../../../common/components/buttons/NewButtons.styles";

export const EventsMenuWrapper = styled(MenuWrapper)`
  margin-top: 12px;
  width: auto;
`;

export const EventsMenuItemWrapper = styled(MenuItemWrapper)`
  gap: 10px;
`;

export const EventMenuItemIconWrapper = styled(MenuItemIconWrapper)`
  margin: 0;
`;

export const EventMenuItemText = styled(MenuItemText)`
  font-size: 14px;
`;

export const CenterContentWrapper = styled.div`
  width: 100%;
`;

export const CenterContent = styled.div`
    width: 100%;
    max-width: 960px;
    padding: 0;
    margin: 0 auto;
    min-height:100%;
`;

export const StickyWrapper = styled.div`
    position: sticky;
    top: 96px;
    width: 100%;
    /* padding-top: 16px; */
    background-color: #fff;
    border-bottom: 1px solid ${colors.gray3};
    ${props => props.isSticky ? `
        
        box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.05);
        z-index: 5;
    ` : null}
`;

export const TableRowSizes = styled.div`
  display: grid;
  //grid-template-columns: 100px 120px ${props=>props.isWithHoldings ? '340' : '240'}px auto;
  grid-template-columns:
  104px
  ${props=> 160 - (props.categoryAttributeCount || 0) * 16}px
  ${props=>props.categoryAttributeCount ? `repeat(${props.categoryAttributeCount}, 80px)` : ``}
  ${props=> 128 - (props.categoryAttributeCount || 0) * 8 }px
  ${props=> 128 - (props.categoryAttributeCount || 0) * 8 }px
  auto
  100px; 
  // auto ${props=>props.matricesColumns ? `repeat(${props.matricesColumns}, 95px)` : ``} 120px;
  // categoryAttributeCount
  // gap:8px;
`;

export const HeaderWrapper = styled.div`
    display:flex;
    flex-direction:column;
`;

export const HeaderTop = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 16px 16px 16px 24px;
`

export const HeaderTableColumns = styled(TableRowSizes)`

`;

export const HeaderTableCell = styled.div`
    font-size:12px;
    line-height: 14px;
    margin:8px 0px;
    padding:0 8px;
    color:${colors.darkGray2};
    border-left:1px solid ${colors.gray3};
    display:flex;
    justify-content: space-between;
    align-items: center;
    :not(:first-of-type){
      
    }
    :first-of-type{
      border-left:none;
      padding-left:24px
    }
`;


export const HeroWorthHoldingsBadge = styled.div`
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 10px;
    line-height: 12px;
    
`;

export const HeroWorthHoldingsBadgeText = styled.div`
    background-color: ${colors.holdingsBadgeColor};
    padding: 1px 2px 1px 4px;
    border-radius: 4px 0px 0px 4px;
`;

export const HeroWorthHoldingsBadgeTriangle = styled.div`
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 6px solid ${colors.holdingsBadgeColor};
`;



export const HeaderTitle = styled.div`
    flex:1;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding-left:24px;
    color: ${colors.darkGray1};
`;

export const ActionButton = styled(PrimaryButtonWithIcon)`
    // background: #2A2D33;
    // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    // border-radius: 4px;
    // padding: 8px;
    margin-left: auto;
    /* margin: 24px; */
    width: 125px;
    justify-self: center;
    // font-size:14px;
    // color:#fff;
    // cursor: pointer;
    // display:flex;
    // align-items:center;
    // .anticon{
    //     padding:0 8px 0 0;
    // }
    ${props=>props.disabled ? `opacity:0.6;cursor:default` : ``}
    ${views.tablet}{
      font-size: 14px;
    }
`;

export const EventsMenu = styled.div`
margin-left: 10px;
`

export const EventsMenuIconWrapper = styled.div`
  ${flexCenter};
  border-radius: 50%;
  /* margin-left: 10px; */
  width:32px;
  height:32px;
  cursor: pointer;
  :hover{
      background-color: ${colors.lightGray1};
  }

  svg {
    transform: rotate(90deg);
    padding: 2px;
    path{
        stroke:${colors.redNotification};
      }
  }
`

export const LoadFutureEventsButtonWrapper = styled.div`
  /* ${flexCenter}; */
  display: inline-block;
  box-shadow: ${shadows.toggleShadow};
  border-radius: 20px;
  height: 22px;
  line-height: 22px;
  font-size: 12px;
  cursor: pointer;
  ${props=>props.isShowingFutureEvents ? `
  svg {
    transform: rotate(180deg);
  }
  ` : null}
  ${props=>props.isDisabled ? `
    color: ${colors.gray2};
    cursor: default;
    svg{
      path{
        stroke:${colors.gray2};
      }
    };
  ` : null}

  ${views.tablet}{
      height: 38px;
      border-radius: 6px;
      ${flexCenter}
      margin-right: 24px;
      font-size: 14px;
  }
`;

export const LoadFutureEventsButton = styled.div`
  ${flexCenter};
  padding: 0 10px 0 6px;
  gap: 4px;
`;

export const HistoricActualEventsPromptWrapper = styled.div`
  ${flexCenter};
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  border-radius: 8px;
  padding: 8px 12px;
`;

export const StatusHideCanceledEventsIcon = styled(StatusHideCanceledEvents)`
  position: absolute;
  transform: translateY(-3px);
  margin-left: 5px;
`;

export const ArrowRightSvgWrapper = styled.div`
  opacity: 0;
  ${flexCenter};
`;

export const EventTypeActionMenuWrapper = styled.div`
    position: relative;
`;

export const EventTypeActionMenuContent = styled.div`
    // position: absolute;
    white-space: nowrap;
    border: 1px solid #DDE3EB;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
`;

export const EventTypeActionMenuItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    height: 44px;
    padding: 8px 4px 8px 16px;
    cursor: pointer;
    background-color: #FFFFFF;
    animation: all 0.2s;

    :first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    :last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    :hover {
        background: linear-gradient( 90deg, #000  2px, #F7F8F9 2px);
        ${ArrowRightSvgWrapper} {
          opacity: 1;
        }
    }

    ${props=>props.disabled ? `
      cursor: not-allowed;
      > * {opacity: 0.5;}
    ` : ``}
`;

export const EventTypeActionMenuItemContentWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 6px;
`;

export const EventTypeActionMenuItemTitleWrapper = styled.div`
  flex: 1;
`;