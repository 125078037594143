import { useEffect, useState } from "react";

export const useTypewriter = ({ text, speed = 25, typingEffect = true, setIsTyping, eventTrackingText }) => {
    const [typedText, setTypedText] = useState('');

    useEffect(() => {
        let index = 0;
        let timeout;
        setTypedText('');

        const type = () => {
            timeout = setTimeout(() => {
                if (index < text?.length) {
                    setTypedText((prev) => (prev + text?.charAt(index)));
                    index++;
                    type();
                } else {
                    setIsTyping?.(false);
                }
            }, speed);

        }

        if (typingEffect) {
            setIsTyping?.(true);
            type();
        } else {
            setTypedText(text);
        }

        return () => {
            setIsTyping?.(false);
            clearTimeout(timeout);
            index = text.length;
        };

    }, [text, speed, typingEffect, setIsTyping]);

    return typedText;
}