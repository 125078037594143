import styled from '@emotion/styled/macro';
import { colors, shadows, views } from '../../../../common/styles/theme.styles';
import { Input } from 'antd';
import { flexCenter, textOverflow } from '../../../../common/styles/combos.styles';
import { animateSpin } from '../../../../common/styles/animations.styles';

export const InstitutionStepWrapper = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    padding:16px;
    margin-top: 24px;
    flex:1;
    justify-content:space-between;
    ${views.tablet} {
        margin-top:0;
    }
`;

export const InstitutionStepTitle = styled.div`
    color:${colors.darkGray1};
    font-size:16px;
    font-weight:500;
`;

export const InstitutionStepSubTitle = styled.div`
    color:${colors.darkGray3};
    font-size:12px;
`;

export const SearchInputAndResultsWrapper = styled.div`
    width:100%;
    margin-top:16px;
    ${props=>props.hasMaxWidth ? `max-width:530px;` : ``};
    ${props=>props.isHidden ? `display: none` : ``};
`;

export const SearchInput = styled(Input)`
    height:40px;
    border: 1px solid ${colors.darkGray2};
    border-radius: 34px;
    /* border-bottom: 1px solid #DDE3EB;
    border-width:0 0 1px 0;
    border-radius:4px 4px 0px 0px; */
    &:hover{
        border-color:#7d7d7d!important;
        box-shadow: none;
        // border-right:none!important;
    }
    &:focus{
        border-color:#7d7d7d!important;
        box-shadow: none;
        // border-right:none!important;
    }
    &.ant-input-affix-wrapper-focused{
        border-color:#7d7d7d;
        box-shadow: none;
        // border-right:none!important;
    }
`

export const ListAndLoaderWrapper = styled.div`
    position: relative;
`;

export const InstitutionsResultsListWrapper = styled.div`
    margin-top:16px;
    box-shadow:${shadows.menuPopup};
    border: 1px solid ${colors.gray3};
    background-color:#fff;
    border-radius:8px;
    // min-height:300px;
    max-height:300px;
    overflow-y:auto;  
`;

export const InstitutionItemSwitcherWrapper = styled.div``;
export const InstitutionItemWrapper = styled.div`
    padding:16px;
    position:relative;
    // border-left:2px solid ${colors.lightGray1};
    ::before{
        content:' ';
        position:absolute;
        left:0;
        top:0%;
        height:100%;
        width:0px;
        background-color:${colors.darkGray1};
        // transition:all 0.2s;
    }
    :hover{
        background-color:${colors.lightGray1};
        //border-left:2px solid ${colors.darkGray1};
        ::before{
            width:2px;
            /* top:0%;
            height:100%; */
        }
    }
    ${flexCenter};
    justify-content:flex-start;
    cursor: pointer;
`

export const InstitutionLogoWrapper = styled.div`
    ${flexCenter};
    min-width:32px;
    max-width:32px;
    // padding-right:16px;
    img{
        height:32px;
        width:32px;
    }
`

export const LogoPlaceHolder = styled.div`
    height:32px;
    width:32px;
    background-color:${colors.gray3};
    border-radius:50%;
    ${flexCenter};
    svg {
        width:24px;
        height:24px;
    }
`;

export const InstitutionTextWrapper = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    margin-left:16px;
    max-width: calc(100% - 64px);
    ${textOverflow};
`;

export const InstitutionName = styled.div`
    font-size:14px;
    line-height:16px;
    font-weight:450;
    color:${colors.darkGray1};
`;
export const InstitutionUrl = styled.div`
    font-size:12px;
    line-height:14px;
    color:${colors.gray1};
    ${textOverflow};
`;

export const SearchingLoaderWrapper = styled.div`
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color:${colors.lightGray2};
    border-radius:8px;
    opacity:0.7;
    ${flexCenter};
`;

export const LoadMoreLoaderWrapper = styled.div`
    padding:24px;
    ${flexCenter};
`;

export const CleanSearchAddManuallyMessage = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    /* cursor: pointer; */
    gap: 12px;
    margin-top:64px;
    .visible-text{    
        color:${colors.darkGray3};
        font-size:12px;
    }
    /* .add-manually-text{
        color:${colors.darkGray1};
        font-size:12px;
        visibility:hidden;
    } */
    /* :hover{
        .add-manually-text{
            visibility:visible;
        }
    } */
`;

export const AddManuallyButton = styled.div`
    background: #fff;
    color: ${colors.darkGray1};
    padding: 8px 32px;
    font-weight: 450;
    font-size: 15px;
    line-height: 19px;
    border: 1px solid #DDE3EB;
    box-shadow: ${shadows.selectMenuShadow};
    border-radius: 6px;
    cursor: pointer;
    :hover {
        background: ${colors.darkGray1};
        color: #fff;
    }
`;

export const AddManuallyButtonWrapper = styled.div`
    margin-top:16px;
    box-shadow:${shadows.menuPopup};
    border: 1px solid ${colors.gray3};
    background-color:#fff;
    border-radius:8px;
    min-height:64px;
    max-height:64px;
    padding:16px;
    position:relative;
    ${flexCenter};
    justify-content:flex-start;
    cursor: pointer;
    overflow:hidden;
    // border-left:2px solid ${colors.lightGray1};
    ::before{
        content:' ';
        position:absolute;
        left:0;
        top:50%;
        height:0%;
        width:2px;
        background-color:${colors.darkGray1};
        // transition:all 0.25s ease-in-out;
    }
    :hover{
        background-color:${colors.lightGray1};
        //border-left:2px solid ${colors.darkGray1};
        ::before{
            top:0%;
            height:100%;
        }
    } 
`;

export const AddIconWrapper = styled.div`
    width:32px;
    min-width:32px;
    height:32px;
    ${flexCenter};
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
    border-radius:50%;
    margin-left:2px;
`;

export const RequestVipSyncButtonWrapper = styled(AddManuallyButtonWrapper)`
    margin-top: 8px;
`;

export const AddVipIconWrapper = styled(AddIconWrapper)`
    box-shadow: none;
    border: 1px solid #DEB482;
`;

export const AddButtonText = styled.div`
    margin-left:16px;
    max-width: calc(100% - 64px);
    font-size:14px;
    line-height:16px;
    font-weight:500;
    color:${colors.darkGray1};
    ${textOverflow};
`;


export const ItemRowLoader = styled.div`
    padding:16px;
    width:100%;
    ${flexCenter};
`;

export const SaltEdgeLoadingWrapper = styled.div`
    ${flexCenter};
    position: absolute;
    width: 100%;
    left: 0;
    top: ${props=>props.hideTopPart ? 0 : 65}px;
    bottom: 0;
    flex-direction: column;
    padding: 0 60px;
    background: ${colors.lightGray2};
`;

export const SaltEdgeLoadingRowWrapper = styled.div`
    display: flex;
    width: ${props=>props.isFullWidth ? '100%' : '530px'};
    border-radius: 42px;
    background: #fff;
    ${props=>props.withoutPadding ? `` : `padding: 12px 16px;`};
    ${props=>props.resetWhiteSpace ? `white-space: normal` : ``};
    ${views.tablet}{
        max-width:530px;
        width:100%;
        border-radius: 0px;
        padding-top: 24px;
    }
`;

export const VipSyncRowWrapper = styled.div`
    position:relative;
    // border-left:2px solid ${colors.lightGray1};
    ${props => props.isHeader ? `` : `
    padding:16px;
        ::before{
            content:' ';
            position:absolute;
            left:0;
            top:0%;
            height:100%;
            width:0px;
            background-color:${colors.darkGray1};
            // transition:all 0.2s;
        }
        :hover{
            background-color:${colors.lightGray1};
            //border-left:2px solid ${colors.darkGray1};
            ::before{
                width:2px;
                /* top:0%;
                height:100%; */
            }
        }
        cursor: pointer;
    `}
    ${flexCenter};
    justify-content:flex-start;
`;

export const InstitutionExistsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width:100%;
    flex: 1;
    margin-top:16px;
    justify-content: space-between;
    ${props=>props.hasMaxWidth ? `max-width:530px;` : ``};
`;

export const TextPromptWrapper = styled.div`
    padding-top: 40px;
    text-align: center;
    color: ${colors.darkGray1};
`;

export const SaltEdgeLoadingFooterWrapper = styled.div`
    ${flexCenter};
    flex-direction: column;
    gap: 24px;
    padding-bottom: 16px;
`;

export const InstitutionExistsFooterWrapper = styled.div`
    ${flexCenter};
    position: absolute;
    gap: 10px;
    bottom: 28px;
    left:30px;
    right: 30px;
    ${views.tablet} {
        position: static;
        display: flex;
        flex-direction: column-reverse;
        padding-top: 16px;
    }
`;

export const ActionButton = styled.div`
    ${flexCenter};
    cursor: pointer;
    background: #000;
    color: #fff;
    font-weight: 500;
    width: 156px;
    padding: 10px 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
`;

export const SyncTabPrompt = styled.div`
    ${flexCenter};
    margin: 0 8px;
    color: ${colors.darkGray1};
    gap: 8px;
`;

export const SyncWhiteIconWrapper = styled.div`
    svg{
        path{
            /* fill:white; */
        }
    }
    margin-right: 8px;
    animation-name: ${animateSpin};
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    ${flexCenter};
`;

export const ActivationFlowInstitutionEmptyStateWrapper = styled.div`
    ${flexCenter}
    background-color: #fff;
    border: 1px solid ${colors.gray3};
    box-shadow: ${shadows.menuPopup};
    border-radius: 8px;
    flex-direction: column;
    min-height: 140px;
    gap: 4px;
`;
export const ActivationFlowInstitutionEmptyStateMessage = styled.div`
    font-weight: 450;
    font-size: 13px;
    line-height: 16px;
    color:${colors.gray3};
`

export const HighlightedSearchText = styled.span`
    font-weight: 600;
    color: #000;
`;