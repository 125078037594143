import { Observer } from 'mobx-react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  ArrowDownIcon,
  //  ArrowDownIcon, HomepageIcon
} from '../../../../assets/icons/common/common-icons'
import { navigateToUrl } from '../../../../common/utils/navigation.utils'
import { getBreadcrumbsDisplayName } from '../../../../common/utils/string.utils'
import { useStore } from '../../../app/data/root.store'
import { CenterContent } from '../../../wealth/components/WealthLayout.styles'
import { FilterTagsList } from '../FilterParameterTags/FilterTagsList'
// import { AssetClassIcon } from '../../../assets/components/AssetClassIcon'
// import { LiabilityClassIcon } from '../../../liabilities/components/LiabilityClassIcon'
import {
  BreadCrumbsClassText, BreadCrumbsHomeIconWrapper,
  // BreadCrumbsIconWrapper,
  BreadCrumbsItemText,
  BreadCrumbsOverflowText,
  // BreadcrumbsPlaceholder,
  BreadcrumbsWrapper, DiagonalSeparatorWrapper, HoldingsBadgeSquare, HoldingsBadgeTriangle, HoldingsBadgeWrapper, StickyWrapper
} from './Breadcrumbs.styles'
import { ROUTES } from '../../../../common/constants/routes.const'

export const Breadcrumbs = (props) => {
  // const {} = props;
  const { wealthStore, itemStore, uiStore, filtersStore } = useStore();
  let history = useHistory();

  const handleBackToAssetClick = () => {
    navigateToUrl(history, `/wealth/assets/${wealthStore.selectedClassUrlName}/${itemStore.item.id}` )
  }

  const handleClassClick = () => {
    // this is happened only if we are lending on item page directly without filters ( via url )
    filtersStore.handleSelectClass(wealthStore.selectedClass.id);
    filtersStore.setAppliedFiltersFromSelection();
    wealthStore.refreshWealthData(false,history);
    navigateToUrl(history, ROUTES.wealthOverview.url)
  }

  const handleCashAccountsClick = () => { // FROM cash flow page
    filtersStore.handleClearFilterSelections();
    filtersStore.handleSelectClass(1);
    filtersStore.setAppliedFiltersFromSelection();
    wealthStore.refreshWealthData(false,history);
    navigateToUrl(history, ROUTES.wealthOverview.url)
}


  const handleFiltersClick = () => {
    navigateToUrl(history, ROUTES.wealth.url);
  }

  const handleHomeClick = () => {
      if (filtersStore.countActiveFilters) {
        filtersStore.handleClearFilterSelections();
        wealthStore.refreshWealthData(false,history);
      }
      wealthStore.setViewType('overview');
      navigateToUrl(history, ROUTES.wealth.url);
  }

  return (
    <Observer>{() => (
      <> {filtersStore.countActiveFilters > 0 ?
      <StickyWrapper isSticky={uiStore.isBreadcrumbsWrapperSticky}>
        <CenterContent isCashFlowView={wealthStore.isCashFlowView}>
          <BreadcrumbsWrapper>
            
            <BreadCrumbsHomeIconWrapper onClick={handleHomeClick}>
              {/* <HomepageIcon /> */}
              Home
            </BreadCrumbsHomeIconWrapper>
            {wealthStore.viewOf !== 'all' && <DiagonalSeparator />}

            {filtersStore.countActiveFilters && wealthStore.viewOf !== 'item' && !wealthStore.isCashFlowView  ? <><DiagonalSeparator /><FilterTagsList /></> : null}

            {wealthStore.isCashFlowView  && getBreadcrumbsDisplayName(wealthStore.selectedClassUrlName) &&
              <>
                <BreadCrumbsClassText isClassView={false} onClick={handleCashAccountsClick} >Cash accounts</BreadCrumbsClassText>
                <DiagonalSeparator /><BreadCrumbsItemText>Cash flow</BreadCrumbsItemText>
              </>              
            }

            {
              !wealthStore.isCashFlowView && wealthStore.viewOf === 'class' && <BreadCrumbsClassText isClassView={true} onClick={handleClassClick} >{getBreadcrumbsDisplayName(wealthStore.selectedClassUrlName)}</BreadCrumbsClassText>
            }

            {itemStore.item?.id && wealthStore.viewOf === 'item' &&
              <>
              {filtersStore.countActiveFilters ? 
                <BreadCrumbsClassText onClick={handleFiltersClick} >{wealthStore.isSingleClassFilterResults && filtersStore.singleClassFilter && wealthStore.selectedClass.id === itemStore.item.categoryClass.id ? wealthStore.singleClass?.title : 'Filters'}</BreadCrumbsClassText>
                :
                <BreadCrumbsClassText isClassView={wealthStore.viewOf === 'class'} onClick={handleClassClick} >{getBreadcrumbsDisplayName(wealthStore.selectedClassUrlName)}</BreadCrumbsClassText>
              }
                <DiagonalSeparator />
                {itemStore.item.isConnectedSingleStock && <>
                  <BreadCrumbsClassText onClick={handleBackToAssetClick}>{itemStore.item.assetTitle}</BreadCrumbsClassText>
                  <DiagonalSeparator />
                </> }
                <BreadCrumbsItemText> <BreadCrumbsOverflowText> {itemStore.item.title}</BreadCrumbsOverflowText></BreadCrumbsItemText>
              </>
            }
            {uiStore.isBreadcrumbsWrapperSticky && itemStore.item.holdings !== 100 && wealthStore.viewOf === 'item' && <HoldingsBadgeWrapper>
              <HoldingsBadgeSquare>{itemStore.item.holdings}% Ownership</HoldingsBadgeSquare><HoldingsBadgeTriangle />
            </HoldingsBadgeWrapper>}
          </BreadcrumbsWrapper>
        </CenterContent>
      </StickyWrapper> : <div style={{height:'16px',minHeight:'16px'}}>&nbsp;</div>}
      </>
    )}</Observer>
  )
}

export const DiagonalSeparator = () => {
  return (
    <DiagonalSeparatorWrapper><ArrowDownIcon /></DiagonalSeparatorWrapper>
    // <DiagonalSeparatorWrapper><ArrowDownIcon /></DiagonalSeparatorWrapper>
    // <DiagonalSeparatorWrapper>/</DiagonalSeparatorWrapper>
  )
}