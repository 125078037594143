import styled from "@emotion/styled/macro";
import { colors } from "../../../../../../../common/styles/theme.styles";
import { views } from "../../../../../../../common/styles/theme.styles";


export const TrendsHeroContainer = styled.div`
    display: flex;
    padding: 16px 24px 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    width: 100%;
    padding-top: 24px;
    position: relative;
    ${views.tablet} {
        padding-top: 54px;
    }
`;

export const TrendsHeroControlContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    position: relative;
`;

export const TrendsHeroControlOverview = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const TrendsHeroControlCube = styled.div`
    display: flex;
    // height: 88px;
    min-width: 210px;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;

    border-left: 2px dashed ${props => props.whiteTheme ? '#000' : '#FFF'};
    &:first-of-type {
        border-left: 2px solid ${props => props.whiteTheme ? '#000' : '#FFF'};
    }
`;

export const TrendsTypeMenuButton = styled.div`
    display: flex;
    align-items: center;

    color: ${props => props.whiteTheme ? '#000' : '#FFF'};
    font-size: 12px;
    font-weight: 500;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    gap: 8px;

    svg {
        path {
            stroke: ${props => props.whiteTheme ? '#000' : '#FFF'};
        }
    }
`;

export const TrendsCurrentValueContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    opacity: ${props=>props.isLoading ? 0 : 1};
`;

export const TrendsPercentageChange = styled.div`
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    ${props => (-1 === props.sign) ? `
            background: var(--Label-2-Label-bg--, rgba(255, 148, 124, 0.30));
            color: var(--Label-2-Label-text--, #FF947C);

        ` : `
            background: var(--label-2-label-bg, rgba(109, 184, 126, 0.30));
            color: var(--label-2-label-text, #6EBA7F);
            :before {
                content: '+';
            }
        `
    }

    :after {
        content: '%';
    }

    font-size: 12px;
    font-weight: 500;
    line-height: normal;
`;

export const TrendsHeroSegmented = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right:0;
    top:0;
    ${views.tablet} {
        position: relative;
        width: 100%;
    }
`;

export const TrendsHeroSegmentedButton = styled.div`
    display: flex;
    width: 40px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    line-height: normal;
 ${views.tablet} {
    width: 25%;
 }
    ${props => props.whiteTheme ? `
            background: var(--BG-BG, #FFF);
            border: 1px solid var(--Border-Border-02, #DDE3EB);
            color: #000;

            ${props.isSelected ? `
                background: var(--BG-BG-Selected, #585D66);
                font-weight: 700;
                color: #FFF;
            ` : ''}
        `: `
            background: var(--BG-BG, #111213);
            border: 1px solid var(--Border-Border-02, #585D66);
            color: #FFF;

            ${props.isSelected ? `
                background: var(--BG-BG-Selected, #4F4F4F);
                font-weight: 700;
            ` : ''}
        `}

    font-size: 12px;
    font-weight: 450;
    cursor: pointer;
    overflow: hidden;
    user-select: none;

    &:hover {
        font-weight: 700;
    }

    &:first-of-type {
        border-radius: 6px 0 0 6px;
    }

    &:last-child {
        border-radius: 0 6px 6px 0;
    }

    &:not(:last-child) {
        border-right: none;
    }
`;

export const TrendsHeroPeriod = styled.div`
        color: var(--text-icon-active-2, #585D66);
        font-size: var(--font-size-xs, 12px);
        font-weight: 500;
        line-height: var(--font-line-height-xs, 16px); /* 133.333% */
`;


export const TrendsGraphTooltip = styled.div`
    display: flex;
    width: 317px;
    padding-bottom: 16px;
    padding-right: 4px;
    flex-direction: column;
    align-items: flex-start;

    position: fixed;

    border-radius: 4px;
    ${(props) => props.whiteTheme ? `
            border: 1px solid var(--Border-Border-02, #DDE3EB);
            background: var(--BG-BG, #FFF);
            color: #000;
        `: `
            border: 1px solid var(--Border-Border-02, #585D66);
            background: var(--BG-BG, #111213);
            color: #FFF;
        `}

    /* Popup Shadow */
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.30), 0px 4px 15px 0px rgba(0, 0, 0, 0.15);

    transform: translate(4px, 24px);
    left: ${(props) => props.left}px;
    top: ${(props) => props.top}px;

    z-index: 999999;
    overflow: hidden;
`;

export const TrendsGraphTitle = styled.div`
    display: flex;
    padding: 16px 12px ${props => props.noPadding ? 0 : 12}px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    font-size: var(--font-size-sm, 14px);
    font-weight: 500;
    line-height: var(--font-line-height-sm, 24px); /* 171.429% */
    width: 100%;

`;

export const TrendsGraphOverviewItems = styled.div`
    display: flex;
    padding: 0px 12px 0 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    max-height: 336px;
    overflow-y: auto; 
    
    /* Custom scrollbar styles */
    &::-webkit-scrollbar {
        width: 8px; /* Scrollbar width */
    }

    &::-webkit-scrollbar-track {
        background: transparent; /* Background of the track */
    }

    &::-webkit-scrollbar-thumb {
        background: #979797; /* Color of the scrollbar */
        border-radius: 13px; /* Optional: Rounded corners */
        border: none; /* Optional: no border */
    }

    /* For Firefox */
    scrollbar-width: thin; /* Forces thin scrollbar */
    scrollbar-color: #979797 transparent; /* Thumb and track colors */
`;



export const TrendsGraphOverviewItem = styled.div`
        display: flex;
        height: 24px;
        padding-left: 8px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        border-left: 2px solid ${props => props.borderLeftColor ?? '#FFF'};

        font-size: var(--font-size-xs, 12px);
        font-style: normal;
        font-weight: 450;
        line-height: var(--font-line-height-xs, 16px); /* 133.333% */
`;

export const TrendsGraphDrawer = styled.div`
    display: flex;
    width: 100%;
    padding-bottom: 16px;
    padding-right: 4px;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 4px;
    border: 1px solid var(--Border-Border-02, #DDE3EB);
    background: var(--BG-BG, #FFF);
    color: #000;
    z-index: 999999;
    overflow: hidden;
`;

export const TrendsPicker = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.darkGray1};
    colors: 'white';
    border-radius: 8px;
    position: relative;
    width: 100%;
`;

export const TrendsPickerTitle = styled.div`
    display: flex;
    align-items: center;
    padding: 0 8px;
    width: 100%;
    justify-content: space-between;
    color: ${colors.lightGray1};
`;