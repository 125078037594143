import { DatePicker, Input, Tooltip } from "antd";
import moment from 'moment';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { DeleteIcon, PlusIcon, TooltipIcon } from '../../../../../assets/icons/common/common-icons';
import { colors } from '../../../../styles/theme.styles';
import { disabledLessThanDate } from '../../../../utils/dates.utils';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../../../utils/object.utils';
import { SecondaryButton } from "../../../buttons/NewButtons.styles";
import { AmountWrapper, HoldingsBadgeText, HoldingsBadgeTriangle, HoldingsBadgeWrapper, InputWithBudgeWrapper } from '../ScheduleTable/ScheduleTable.styles';
import { ActionButtonWrapper, AmountPercentageWrapper, HeaderCell, PrevComponentButton, PrevComponentButtons, PrevComponentDescription, PrevComponentWrapper, RowActionsCell, RowCell, RowWrapper, TableBottomActions, TableHeaderRow, TableScrollContent, TooltipWrapper, Wrapper } from './SingleLoanPaymentEventScheduler.styles';
import { updateFieldsOnBlur } from "./SingleLoanPaymentEventScheduler.helpers";



export function SingleLoanPaymentEventScheduler({ onChange, disabled, value, showEscrow, rowValuePreFix, startDate, headerTooltip, holdings }) {
    const [editedRow, setEditedRow] = useState(value.length ? value[value.length - 1].id : 0);

    const handleAddRowClick = () => {
        if (disabled){return}
        const mostRecentDate = value.length > 0 ? value[value.length - 1].date : startDate ? moment(startDate).subtract(1, 'M') : moment().subtract(1, 'M');
        const maxValueOfTempId = Math.max(...value.map(u => u.tempId || 0), 0);
        const newRow = {
            value: '',
            principal: '',
            interest: '',
            date: moment(mostRecentDate).add(1, 'M').set("D", 15).startOf("D") || moment().set("D", 15).startOf("D"),
            remarks: "",
            tempId: maxValueOfTempId + 1,
        }
        setEditedRow('t' + (maxValueOfTempId + 1));
        onChange?.([...value, newRow]);
    }

    const handleAttributeRowUpdated = (updatedIndex, data, key) => {
        let updatedSortedSchedule = value.map((item, index) => (index === updatedIndex ? data : item));
        if (key === 'date') {
            updatedSortedSchedule = updatedSortedSchedule.sort((a, b) => new Date(a.date) - new Date(b.date));
            updatedSortedSchedule = updatedSortedSchedule.map((item, index) => {
                if (index === 0) return item;
                return { ...item, endDate: updatedSortedSchedule[index - 1].date };
            });
        }
        onChange?.(updatedSortedSchedule);
    }

    const handleAttributeRowDeleted = (deletedIndex) => {
        let updatedSchedule = value.filter((item, index) => index !== deletedIndex);
        updatedSchedule = updatedSchedule.map((item, index) => {
            if (index === 0) return item;
            return { ...item, endDate: updatedSchedule[index - 1].date };
        });
        onChange?.(updatedSchedule);
    }
    
    const tableColumns = ['Date', 'Expected payment', 'Principal', 'Interest', ...(showEscrow ? ['Escrow'] : []) , 'Notes'];

    return (
        <Wrapper>
            <TableHeaderRow onClick={(e) => { e.stopPropagation() }} isWithHoldings={holdings !== 100} isWithEscrow={showEscrow}>
                {tableColumns.map((columnLabel, ind) => (
                    columnLabel === 'Notes' && holdings !== 100 ? null :
                    <HeaderCell key={'hc1' + ind} isAmount={columnLabel === 'Amount'}>
                        <span>{columnLabel}</span>
                        {columnLabel === 'Expected payment' ? <Tooltip title={headerTooltip} placement="topLeft">
                            <AmountPercentageWrapper>{headerTooltip && <TooltipWrapper><TooltipIcon /></TooltipWrapper>}</AmountPercentageWrapper>
                        </Tooltip> : null}
                    </HeaderCell>
                ))}
            </TableHeaderRow>
            <TableScrollContent>
                {value?.map((row, index) => (
                    <EventRow key={row.id || ('t' + row.tempId)}
                        rowValuePreFix={rowValuePreFix} index={index} isLastRow={index === value.length - 1} nextEndDate={index === value.length - 1 ? 'End date' : value[index + 1].date}
                        item={row} atrKey={row.atrKey} rowUpdated={(data, key) => { handleAttributeRowUpdated(index, data, key) }}
                        isEditMode={value?.length === 1 ? true : row.id ? (editedRow === row.id) : (editedRow === 't' + row.tempId)}
                        setEditMode={() => setEditedRow(row.id ? row.id : 't' + row.tempId)}
                        holdings={holdings}
                        disabled={disabled}
                        showEscrow={showEscrow}
                        startDate={startDate}
                        selectedDates={value.filter(eventRow => eventRow.id ? (editedRow !== eventRow.id) : (editedRow !== 't' + eventRow.tempId)).map(eventRow => eventRow.date)}
                        rowDeleted={() => handleAttributeRowDeleted(index)} />
                ))}
            </TableScrollContent>
            <TableBottomActions>
                <SecondaryButton disabled={disabled} style={{ padding: '8px 16px' }} onClick={handleAddRowClick}>
                    <PlusIcon />
                    <span className='button-text'>Add expected payment</span>
                </SecondaryButton>
            </TableBottomActions>
        </Wrapper>
    )
}

const EventRow = ({ item, disabled, rowDeleted, rowUpdated, isEditMode, showEscrow, setEditMode, rowValuePreFix, holdings, startDate, selectedDates }) => {
    const { TextArea } = Input;
    const [focusedInput, setFocusedInput] = useState(0);

    const handleFieldChange = (field, value) => {
        if (item[field] !== value) {
            rowUpdated?.({
                ...item,
                [field]: value
            }, field)
        }
    }

    const handleFieldBlur = (field) => {
        const change = updateFieldsOnBlur(field, item, showEscrow);
 
        rowUpdated?.({
            ...item,
            ...change
        }, '');
    }

    const handleDisabledDates = (current) => {
        const isEarlierThanStartDate = disabledLessThanDate(current, startDate);
        const isPreselectedDate = selectedDates.reduce((acc, sd) => acc || current.isSame(sd, 'month'), false);
        return isEarlierThanStartDate || isPreselectedDate;
    }

    return (
        <RowWrapper onClick={(e) => { e.stopPropagation(); setEditMode() }} isWithHoldings={holdings !== 100} isEdited={isEditMode} isWithEscrow={showEscrow}>

            {/* date */}
            <RowCell>
                <DatePicker picker="month" disabled={disabled || item.eventId}
                    onFocus={() => { setEditMode() }}
                    allowClear={false}
                    inputReadOnly
                    
                    format={'MMM YYYY'}
                    style={{ ...(disabled || isEditMode ? { borderRadius: '6px', } : { borderColor: 'transparent', backgroundColor: 'transparent' }) }}
                    {...(disabled || isEditMode ? {} : { suffixIcon: null })}
                    disabledDate={handleDisabledDates}
                    value={moment(item?.date)} onChange={(val) => { handleFieldChange('date', (val ? val.format() : item?.date)) }} />
            </RowCell>

            {/* expected payment */}
            <RowCell>
                {!isNullOrUndefined(holdings) && holdings !== 100 && <AmountWrapper>
                    <NumberFormat
                        thousandSeparator={true}
                        customInput={Input}
                        prefix={rowValuePreFix}
                        placeholder={isEditMode ? rowValuePreFix : ''}
                        style={{ flex: 1, borderRadius: '6px 0 0 6px', ...(disabled || isEditMode ? {} : { borderColor: 'transparent', backgroundColor: 'transparent' }) }}
                        value={(isNullOrUndefinedOrEmptyString(item?.value)) ? '' : parseFloat((item?.value * (holdings / 100)).toFixed(2))}
                        autoComplete="off"
                        onFocus={() => { setFocusedInput(1) }}
                        allowNegative={false}
                        disabled={disabled || (item.principal && item.interest && (!showEscrow || item.escrow))}
                        onValueChange={(values) => {
                            if ('' + item?.value !== values.value && focusedInput === 1 &&
                                (!isNullOrUndefined(item?.value) || !isNullOrUndefined(values.floatValue))) {
                                handleFieldChange('value', (values.floatValue || values.floatValue === 0) ? values.floatValue * (100 / holdings) : null)
                            }
                        }}
                    />
                </AmountWrapper>}
                <InputWithBudgeWrapper isWithHoldings={holdings !== 100}>
                    <NumberFormat
                        thousandSeparator={true}
                        customInput={Input}
                        prefix={rowValuePreFix}
                        placeholder={isEditMode ? rowValuePreFix : ''}
                        style={{
                            width: '100%', borderRadius: '6px', ...(disabled || isEditMode ? {} : { borderColor: 'transparent', backgroundColor: 'transparent' }),
                            ...(holdings !== 100 ? { paddingLeft: '30px', color: colors.holdingsValueColor, borderRadius: '0 6px 6px 0' } : {})
                        }}
                        value={(isNullOrUndefinedOrEmptyString(item?.value)) ? '' : parseFloat((+item?.value).toFixed(2))}
                        autoComplete="off"
                        onFocus={() => { setFocusedInput(2) }}
                        allowNegative={false}
                        disabled={disabled || (item.principal && item.interest && (!showEscrow || item.escrow))}
                        onValueChange={(values) => { handleFieldChange('value', values.floatValue) }}
                        onBlur={() => { handleFieldBlur('value') }}
                    />
                    {holdings !== 100 && <HoldingsBadgeWrapper><HoldingsBadgeText></HoldingsBadgeText><HoldingsBadgeTriangle /></HoldingsBadgeWrapper>}
                </InputWithBudgeWrapper>
            </RowCell>

            {/* principal */}
            <RowCell>
                <AmountWrapper>
                    <NumberFormat
                        thousandSeparator={true}
                        customInput={Input}
                        prefix={rowValuePreFix}
                        placeholder={isEditMode ? rowValuePreFix : ''}
                        style={{ flex: 1, borderRadius: '6px 0 0 6px', ...(disabled || isEditMode ? {} : { borderColor: 'transparent', backgroundColor: 'transparent' }) }}
                        value={(isNullOrUndefinedOrEmptyString(item?.principal)) ? '' : parseFloat((item?.principal * (holdings / 100)).toFixed(2))}
                        autoComplete="off"
                        onFocus={() => { setFocusedInput(1) }}
                        onBlur={() => { handleFieldBlur('principal') }}
                        disabled={disabled}
                        allowNegative={false}
                        onValueChange={(values) => {
                            if ('' + item?.principal !== values.value && focusedInput === 1 &&
                                (!isNullOrUndefined(item?.principal) || !isNullOrUndefined(values.floatValue))) {
                                handleFieldChange('principal', (values.floatValue || values.floatValue === 0) ? values.floatValue * (100 / holdings) : null)
                            }
                        }}
                    />
                </AmountWrapper>
            </RowCell>

            {/* interest */}
            <RowCell style={{ marginLeft: '-4px' }}>
                <AmountWrapper>
                    <NumberFormat
                        thousandSeparator={true}
                        customInput={Input}
                        prefix={rowValuePreFix}
                        placeholder={isEditMode ? rowValuePreFix : ''}
                        style={{ flex: 1, borderRadius: showEscrow ? '0' : '0 6px 6px 0', ...(disabled || isEditMode ? {} : { borderColor: 'transparent', backgroundColor: 'transparent' }) }}
                        value={(isNullOrUndefinedOrEmptyString(item?.interest)) ? '' : parseFloat((item?.interest * (holdings / 100)).toFixed(2))}
                        autoComplete="off"
                        disabled={disabled}
                        onFocus={() => { setFocusedInput(1) }}
                        onBlur={() => { handleFieldBlur('interest') }}
                        allowNegative={false}
                        onValueChange={(values) => {
                            if ('' + item?.interest !== values.value && focusedInput === 1 &&
                                (!isNullOrUndefined(item?.interest) || !isNullOrUndefined(values.floatValue))) {
                                handleFieldChange('interest', (values.floatValue || values.floatValue === 0) ? values.floatValue * (100 / holdings) : null)
                            }
                        }}
                    />
                </AmountWrapper>
            </RowCell>

            {/* escrow */}
            { showEscrow && 
            <RowCell style={{ marginLeft: '-4px' }}>
                <AmountWrapper>
                    <NumberFormat
                        thousandSeparator={true}
                        customInput={Input}
                        prefix={rowValuePreFix}
                        placeholder={isEditMode ? rowValuePreFix : ''}
                        style={{ flex: 1, borderRadius: '0 6px 6px 0', ...(disabled || isEditMode ? {} : { borderColor: 'transparent', backgroundColor: 'transparent' }) }}
                        value={(isNullOrUndefinedOrEmptyString(item?.escrow)) ? '' : parseFloat((item?.escrow * (holdings / 100)).toFixed(2))}
                        autoComplete="off"
                        disabled={disabled}
                        onFocus={() => { setFocusedInput(1) }}
                        onBlur={() => { handleFieldBlur('escrow') }}
                        allowNegative={false}
                        onValueChange={(values) => {
                            if ('' + item?.escrow !== values.value && focusedInput === 1 &&
                                (!isNullOrUndefined(item?.escrow) || !isNullOrUndefined(values.floatValue))) {
                                handleFieldChange('escrow', (values.floatValue || values.floatValue === 0) ? values.floatValue * (100 / holdings) : null)
                            }
                        }}
                    />
                </AmountWrapper>
            </RowCell> }

            {/* remarks */}
            {holdings === 100 ? <RowCell>
                <TextArea rows={1}
                    onFocus={() => { setEditMode() }}
                    disabled={disabled}
                    style={{ resize: 'none', ...(disabled || isEditMode ? { borderRadius: '6px' } : { borderColor: 'transparent', backgroundColor: 'transparent' }) }}
                    value={item?.remarks} onChange={(e) => { handleFieldChange('remarks', e.target.value) }} />
            </RowCell> : null}

            { !disabled && <RowActionsCell>
                <ActionButtonWrapper
                    onClick={() => { rowDeleted && rowDeleted() }} >
                    <DeleteIcon />
                </ActionButtonWrapper>
            </RowActionsCell> }
        </RowWrapper>
    )
}

export const SingleEventSchedulerPrevComponent = ({ show }) => {
    return (
        <PrevComponentWrapper>
            <PrevComponentDescription>Do you have any additional commitment?</PrevComponentDescription>
            <PrevComponentButtons>
                <PrevComponentButton isSelected >No</PrevComponentButton>
                <PrevComponentButton onClick={show}>Yes, I expect future capital calls</PrevComponentButton>
            </PrevComponentButtons>
        </PrevComponentWrapper>
    )
}