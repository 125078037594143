
export const updateFieldsOnBlur = (field, item, withEscrow = false) => {
  let change = {};
  let { value, principal, interest , escrow } = item;
  value = value || 0;
  principal = principal || 0;
  interest = interest || 0;
  escrow = escrow || 0;
  const sumOfPrincipalInterest = (principal !== 0 || interest !== 0) ? principal + interest : 0;
  const sumOfPrincipalEscrow = (principal !== 0 || escrow !== 0) ? principal + escrow : 0;
  const sumOfInterestEscrow = (interest !== 0 || escrow !== 0) ? interest + escrow : 0;

  if (field === 'value') {
   
    if (!withEscrow){
      // handle value change without escrow
      if (principal && !interest && value >= principal ) {
        // we have principal without interest
        change.interest = (value - principal) || '';
      } else if (interest && value >= interest && !principal) {
        // we have interest without principal
        change.principal = (value - interest) || '';
      } else if (principal && interest && (value <= sumOfPrincipalInterest)) {
        // we have principal & interest and value is smaller than sum of principal & interest
        // actually is not relevant since we disable the field when both are with values.. but it's good to keep it
        change.principal = '';
        change.interest = '';
      } else if (!value && (!interest || !principal)) {
        // value is removed and we have one of the fields with value - removing the values.
        change.principal = '';
        change.interest = '';
      }
    } else {
      // handle value change with escrow
      if (principal && interest && !escrow && value >= sumOfPrincipalInterest) {
        // we have principal & interest without escrow
        change.escrow = (value - sumOfPrincipalInterest) || '';
      } else if (principal && escrow && !interest && value >= sumOfPrincipalEscrow) {
        // we have principal & escrow without interest
        change.interest = (value - sumOfPrincipalEscrow) || '';
      } else if (interest && escrow && !principal && value >= sumOfInterestEscrow) {
        // we have interest & escrow without principal
        change.principal = (value - sumOfInterestEscrow) || '';
      } else if ( value < principal + interest + escrow) {
        // value is smaller than sum of principal & interest & escrow
        change.principal = '';
        change.interest = '';
        change.escrow = '';
      }
    }
  } else if (field === 'principal' && value) {
    // handle principal change when we have value, 
    if (!withEscrow){
      //without escrow
      if (interest) {
        change.value = sumOfPrincipalInterest;
      } else if (principal) {
        if (principal >= value) {
          change.value = principal;
        } else {
          change.interest = value - principal;
        }
      }
    } else {
      // with escrow
      if (principal && !interest && escrow) {
        change.interest = value - sumOfPrincipalEscrow;
      } else if (principal && interest && !escrow) {
        change.escrow = value - sumOfPrincipalInterest;
      } else if  (interest || escrow) {
        change.value = principal + escrow + interest;
      }
    }
  } else if (field === 'interest' && value) {
    // handle interest change when we have value, 
    if (!withEscrow){
      // without escrow
      if (principal) {
        change.value = sumOfPrincipalInterest;
      } else if (interest) {
        if (interest >= value) {
          change.value = interest;
        } else {
          change.principal = value - interest;
        }
      }
    } else {
      // with escrow
      if (interest && !principal && escrow && value >= sumOfInterestEscrow) {
        change.principal = value - sumOfInterestEscrow;
      } else if (interest && principal && !escrow && value >= sumOfPrincipalInterest) {
        change.escrow = value - sumOfPrincipalInterest;
      } else if (principal || escrow) {
        change.value = principal + escrow + interest;
      }
    }
  } else if (field === 'escrow' && value) {
    // handle escrow change when we have value,
    if (escrow && !principal && interest) {
      change.principal = value - sumOfInterestEscrow;
    } else if (escrow && principal && !interest) {
      change.interest = value - sumOfPrincipalEscrow;
    } else if (principal || interest) {
      change.value = principal + escrow + interest;
    }
  } else {
    // one of the fields ( principal , interest , escrow ) changed but we don't have value yet
    change.value = principal + interest + escrow; //  sumOfPrincipalInterest;
  }
  for (const [key, value] of Object.entries(change)) {
    if (value < 0) {
      change[key] = 0;
    }
  }
  return change;
}