import { Observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { GoToIcon } from '../../../../../assets/icons/common/common-icons';
import { ChartComponent } from '../../../../../common/components/charts/ChartComponent';
import { CountAnimation } from '../../../../../common/components/count-animation/CountAnimation';
import { displayMoneyValue } from '../../../../../common/utils/number-display.utils';
import { useStore } from '../../../../app/data/root.store';
import { MagicBoxAnimatedIconGrey } from '../../../../magic-box/components/MagicBoxAnimatedIconGrey';
import { PieTextBigValue, PieTextContent, PieTextHoverWrapper, PieTextLabel, PieTrendsLink, PieValueContainer, PieWrapper } from './WealthPieChart.styles';

// const changesDataPoints = [
//   'previous month',
//   'previous quarter',
//   'previous year'
// ];

export function WealthPieChart(props) {

  const { wealthStore, uiStore, filtersStore } = useStore();
  let history = useHistory();
  const { bigTextSize } = props;
  const [hoverData, setHoverData] = useState({ datasetIndex: null, index: null, isHoveredOnce: false });
  // const [showChangesSince, setShowChangesSince] = useState(0);
  const containerRef = useRef();
  const textRef = useRef();
  const [textSize, setTextSize] = useState(32);
  // const optionsRef = useRef(null);
  const [chartOptions, setChartOptions] = useState({ cutout: '90%' });
  // const getTooltipItem = (dataSetIndex, dataIndex) => {
  //     // console.log("mm",wealthStore.isLibabilityClassView)
  //     // const assetGroup = wealthStore.assetsGroups[dataIndex];
  //     const relevantGroup = (dataSetIndex === 1 ? ( dataIndex < 2 ? wealthStore.liabilitiesGroups[dataIndex] : null ) : wealthStore.assetsGroups[dataIndex]) ;
  //     return relevantGroup ? {
  //       label: relevantGroup.title,
  //       value: relevantGroup.totalUsdValue,
  //       isIncognito: uiStore.isIncognitoMode,
  //       show: true
  //     } : {
  //       show: false
  //     };
  //   }

  // const someCallBack = useCallback(() => {
  //   console.log("Here");
  //   setHoveredClass(null);
  // },[setHoveredClass]);

  const getChartOptions = useCallback(() => {
    return {
      maintainAspectRatio: true,
      // animation:false,
      // tooltips: { enabled: false },
      // tooltips: customTooltip(getTooltipItem),
      cutout: '80%',
      onHover: (event, chartElement) => {
        // console.log(event,chartElement);
        // const canvasPosition = getRelativePosition(e, chart);
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';

        // if (chartElement[0] && chartElement[0]._index === 4){
        //   someCallBack();
        // }
        if (chartElement.length) {
          const { datasetIndex, index: dataIndex } = chartElement[0];
          let relevantGroup = null;
          const relevantValueAttribute = wealthStore.allocateBy === 'classes' ? 'totalUsdValue' : 'usdValue';
          /// liabilities 
          if (datasetIndex === 1) {
            const liabilities = wealthStore.chartAssetsDataForTooltips.filter(g => g.isLiability || g[relevantValueAttribute] < 0);
            if (dataIndex < liabilities.length) {
              relevantGroup = liabilities[dataIndex];
            }
          } else {

            relevantGroup = wealthStore.chartAssetsDataForTooltips.filter(g => (wealthStore.isOnlyLoansItems ? g.isLiability : !g.isLiability) && g[relevantValueAttribute] > 0)[dataIndex];


          }
          setHoverData(relevantGroup ? {
            // datasetIndex,
            // dataIndex,
            show: true,
            title: relevantGroup.title,
            value: relevantGroup[relevantValueAttribute],
            isHoveredOnce: true
          } : { show: false, isHoveredOnce: true })
        } else {
          setHoverData({
            show: false,
            isHoveredOnce: true
            // datasetIndex:null,
            // dataIndex:null
          })
        }
        // setSomeNumber(someNumber+1);
      },

      onClick: (event, chartElement) => {
        const relevantAttribute = wealthStore.allocateBy === 'classes' ? 'id' : 'classId';
        const relevantValueAttribute = wealthStore.allocateBy === 'classes' ? 'totalUsdValue' : 'usdValue';

        if (chartElement.length && chartElement[0].datasetIndex === 0) {
          const relevantClassOrCategory = wealthStore.chartAssetsDataForTooltips.filter(g => (wealthStore.isOnlyLoansItems ? g.isLiability : !g.isLiability) && g[relevantValueAttribute] > 0)[chartElement[0].index];
          if (wealthStore.allocateBy === 'classes') {
            filtersStore.handleSelectClass(relevantClassOrCategory.id);
          } else {
            filtersStore.handleSelectCategoryFromAllocation(relevantClassOrCategory.classId, relevantClassOrCategory.id);
          }

          filtersStore.setAppliedFiltersFromSelection();
          wealthStore.refreshWealthData(false, history);
        } else if (chartElement.length && (chartElement[0].datasetIndex === 1) && chartElement[0].index < 2) {
          filtersStore.handleSelectClass(wealthStore.chartAssetsDataForTooltips.filter(g => g.isLiability || g[relevantValueAttribute] < 0)[chartElement[0].index][relevantAttribute]);
          filtersStore.setAppliedFiltersFromSelection();
          wealthStore.refreshWealthData(false, history);
        }
      },
      plugins: {
        tooltip: { enabled: false },
      }
    }
  }, [filtersStore, history, wealthStore])

  useEffect(() => {
    setChartOptions(getChartOptions());
    return () => {
    }
  }, [getChartOptions, wealthStore.allocateBy])

  useEffect(() => {
    const containerElement = containerRef.current;
    const textElement = textRef.current;

    if (containerElement && textElement) {
      const availableWidth = containerElement.clientWidth - 80;
      const textWidth = textElement.scrollWidth;

      setTextSize(textWidth > availableWidth ? (availableWidth / textWidth) * 32 : 32);
    }
  }, []);

  return (<Observer>{() => (
    <PieWrapper>
      <ChartComponent key="catCharts" type='doughnut' data={wealthStore.chartAssetsData} options={chartOptions} height={props.height} />
      <PieTextContent contentTop={props.contentTop} ref={containerRef}>
        <PieTextHoverWrapper onClick={() => { wealthStore.setViewType('trends'); wealthStore.setTrendsView('total_net_worth') }}>
        <PieTextLabel>{hoverData.show ? hoverData.title : `Total ${wealthStore.isMainResults ? 'net' : 'items'} worth`}</PieTextLabel>

        <PieTextBigValue isIncognito={uiStore.isIncognitoMode} fontSize={bigTextSize} style={{ fontSize: `${textSize}px` }} ref={textRef}>
          {uiStore.isIncognitoMode ? displayMoneyValue(hoverData.show ? hoverData.value : wealthStore.totalNetWorthUsdValue, 'USD', true) :
            <PieValueContainer>
              <CountAnimation style={{ fontSize: `${textSize}px` }} currency={'USD'} value={hoverData.show ? hoverData.value : wealthStore.totalNetWorthUsdValue} noAnim={hoverData.isHoveredOnce} />
              {wealthStore.hasLLMInProcessItem && <MagicBoxAnimatedIconGrey width='16px' display='flex' fill='rgb(255,255,255)'/>}
            </PieValueContainer>
          }
          <span style={{ position: 'absolute', color: 'transparent', left: 0, top: 0 }}>{displayMoneyValue(hoverData.show ? hoverData.value : wealthStore.totalNetWorthUsdValue, 'USD', false)}</span>
        </PieTextBigValue>
        <PieTrendsLink >See trend <GoToIcon /></PieTrendsLink>
        </PieTextHoverWrapper>
      </PieTextContent>
    </PieWrapper>
  )}</Observer>)
}


// const valueChangeOptions = [
//   { value: 0, text: 'Previous month' },
//   { value: 1, text: 'Previous quarter' },
//   { value: 2, text: 'Previous year' },
// ]


// function ValueChangeDropDown(props) {
//   const { showChangesSince, selectChangesSince } = props
//   return (
//     <MenuWrapperWealthPie>
//       {valueChangeOptions.map(item => (
//         <MenuItemWrapperWealthPie key={item.value} onClick={() => { selectChangesSince(item.value) }}>{item.text} {showChangesSince === item.value && <CheckmarkIcon />}</MenuItemWrapperWealthPie>
//       ))}
//     </MenuWrapperWealthPie>
//   )
// }