import React from 'react';
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AppLoader } from '../../../common/components/Loaders/AppLoader';
import { CashFlowPage } from '../../cash-flow/components/CashFlowPage';
import { CommunityPage } from '../../community/components/CommunityPage';
import { LimitedCommunityPage } from '../../community/components/LimitedCommunityPage';
import { AkoyaRedirectComponent } from '../../connected-institutions/components/akoya-link/AkoyaRedirect';
import { InstitutionsPanel } from '../../connected-institutions/components/institutions-panel/InstitutionsPanel';
import { SaltEdgeRedirectComponent } from '../../connected-institutions/components/saltedge-link/SaltedgeRedirect';
import { LoginPage } from '../../login/components/LoginPage';
import { MagicBoxPage } from '../../magic-box/components/MagicBoxPage';
import { OnBoardingLayout } from '../../onboarding/components/OnBoardingLayout';
import { PricingPage } from '../../pricing-page/PricingPage';
import { ReportsPage } from '../../reports/components/Reports.page';
import { UserActivationFlowPage } from '../../user-activation-flow/components/UserActivationFlowPage';
import { BillingPage } from '../../user/components/Billing/Billing.page';
import { Preferences } from '../../user/components/Preferences.page';
import PortfoliosPage from '../../user/components/Portfolios/portfolios.Page';
import { ProfilePage } from '../../user/components/Profile.page';
import { RolesPage } from '../../user/components/Roles/Roles.page';
import { WealthLayout } from '../../wealth/components/WealthLayout';
import { ROUTES } from '../../../common/constants/routes.const';
import { FinancialSettingsMobilePage } from '../../user/components/FinancialSettings/FinancialSettingsContent';
import { CustomClassListMobilePage } from '../../user/components/CustomClassList/CustomClassListContent';
import { HoldingEntityListMobilePage } from '../../user/components/HoldingEntityList/HoldingEntityListContent';
import { MagicBoxAIPage } from '../../magic-box/components/MagicBoxAIPage';
import { DealFlowPage } from '../../deal-flow/components/DealFlowPage';

const {
  cashflow,
  register,
  invitation,
  welcome,
  onboarding,
  community,
  dealFlow,
  magicBox,
  saltEdgeDone,
  akoyaDone,
  wealth,
  reports,
  portfolios,
  profile,
  login,
  preferences,
  financialSettings,
  entitiesManagement,
  customClassList,
  apis,
  accounts,
  billing,
  roles,
} = ROUTES;

export function PageRouter({
  isLoggedIn,
  isOnBoarding,
  isWelcome,
  isDemoMode,
  isCommunityLimited,
  isDemoOrReadOnlyMode,
  isGranteeHavePortfolio,
  isViewingOwnPortfolio,
  isViewRolesPageBlocked,
  isPricingModalPage,
  isMobileGetStarted,
  isMobileView,
  isMobileViewMessageSeen,
  isPricingTableOpen,
  isMobileScreen,
  skipStartFreeDesktopScreen,
  skipGetStartedGuid,
  isDesktopView,
  metaDataLoaded,
  showManualMagicBox}) {

  const location = useLocation();

  return (
    <>
      {isLoggedIn ? (
        <>
          {metaDataLoaded ? (
            <Switch>
              <Route path={cashflow.url}>
                {isOnBoarding || (!isGranteeHavePortfolio && isViewingOwnPortfolio) ? (
                  <Redirect to={welcome.url} />
                ) : isWelcome ? (
                  <Redirect to={onboarding.url} />
                ) : (
                  <CashFlowPage />
                )}
              </Route>

              <Route path={onboarding.url}>
                {isOnBoarding || (!isGranteeHavePortfolio && isViewingOwnPortfolio) || !isWelcome || isDemoOrReadOnlyMode ? (
                  <Redirect to={welcome.url} />
                ) : (
                  <UserActivationFlowPage />
                )}
              </Route>

              <Route path={community.url}>
                {isOnBoarding || (!isGranteeHavePortfolio && isViewingOwnPortfolio) ? (
                  <Redirect to={welcome.url} />
                ) : isWelcome ? (
                  <Redirect to={onboarding.url} />
                ) : isCommunityLimited ? (
                  <LimitedCommunityPage />
                ) : (
                  <CommunityPage />
                )}
              </Route>
                

              <Route path={dealFlow.url}>
                {isOnBoarding || (!isGranteeHavePortfolio && isViewingOwnPortfolio) ? (
                  <Redirect to={welcome.url} />
                ) : isWelcome ? (
                  <Redirect to={onboarding.url} />
                ) : (
                  <DealFlowPage />
                )}
              </Route>          

              <Route path={magicBox.url}>
                {isOnBoarding || (!isGranteeHavePortfolio && isViewingOwnPortfolio) ? (
                  <Redirect to={welcome.url} />
                ) : isWelcome ? (
                  <Redirect to={onboarding.url} />
                ) : (
                  showManualMagicBox ? <MagicBoxPage /> : <MagicBoxAIPage />
                )}
              </Route>

              <Route exact path={saltEdgeDone.url}>
                <SaltEdgeRedirectComponent />
              </Route>

              <Route exact path={akoyaDone.url}>
                <AkoyaRedirectComponent />
              </Route>

              <Route path={welcome.url}>
                {!isOnBoarding ? (
                  isPricingModalPage ? (
                    <PricingPage />
                  ) : (
                    <Redirect to={isGranteeHavePortfolio ? wealth.url : portfolios.url} />
                  )
                ) : (
                  <OnBoardingLayout />
                )}
              </Route>

              <Route path={wealth.url}>
                {isOnBoarding || (!isGranteeHavePortfolio && isViewingOwnPortfolio) ? (
                  <Redirect to={welcome.url} />
                ) : isWelcome ? (
                  <Redirect to={{ pathname: onboarding.url, search: location.search }} />
                ) : (
                  <WealthLayout />
                )}
              </Route>

              <Route path={reports.url}>
                {isOnBoarding || (!isGranteeHavePortfolio && isViewingOwnPortfolio) ? (
                  <Redirect to={welcome.url} />
                ) : isWelcome ? (
                  <Redirect to={onboarding.url} />
                ) : (
                  <ReportsPage />
                )}
              </Route>

              <Route path={portfolios.url}>
                {isOnBoarding ? <Redirect to={welcome.url} /> : <PortfoliosPage />}
              </Route>

              <Route exact path={profile.url}>
                {isOnBoarding ? <Redirect to={welcome.url} /> : <ProfilePage isWelcome={isWelcome} />}
              </Route>

              <Route exact path={preferences.url}>
                <Preferences isWelcome={isWelcome} />
              </Route>

              <Route exact path={financialSettings.url}>
                {isOnBoarding ? <Redirect to={welcome.url} /> : isDesktopView ? <Redirect to={preferences.url} /> : <FinancialSettingsMobilePage isWelcome={isWelcome} />}
              </Route>

              <Route exact path={entitiesManagement.url}>
                {isOnBoarding ? <Redirect to={welcome.url} /> : isDesktopView ? <Redirect to={preferences.url} /> : <HoldingEntityListMobilePage isWelcome={isWelcome} />}
              </Route>

              <Route exact path={customClassList.url}>
                {isOnBoarding ? <Redirect to={welcome.url} /> : isDesktopView ? <Redirect to={preferences.url} /> : <CustomClassListMobilePage isWelcome={isWelcome} />}
              </Route>

              <Route exact path={apis.url}>
                <Redirect to={accounts.url} />
              </Route>

              <Route exact path={accounts.url}>
                {isDemoMode ? <Redirect to="/" /> : <InstitutionsPanel />}
              </Route>

              <Route exact path={billing.url}>
                {isDemoMode ? <Redirect to="/" /> : <BillingPage isWelcome={isWelcome} />}
              </Route>

              <Route exact path={roles.url}>
                {isOnBoarding ? <Redirect to={welcome.url} /> : isViewRolesPageBlocked ? <Redirect to="/" /> : <RolesPage />}
              </Route>

              <Route exact path={login.url}>
                <LoginPage />
              </Route>

              <Route path="/">
                {isOnBoarding ? <Redirect to={welcome.url} /> : <Redirect to="/wealth/overview" />}
              </Route>
            </Switch>
          ) : (
            <AppLoader backdrop absolute />
          )}
        </>
      ) : (
        <Switch>
          <Route path={register.url}>
            <LoginPage />
          </Route>

          <Route path={invitation.url}>
            <LoginPage />
          </Route>

          <Route exact path="/">
            <LoginPage />
          </Route>

          <Route path="/*">
            <Redirect to="/" />
          </Route>
        </Switch>
      )}
    </>
  );
}
