import { CategoryScale, Chart as ChartJS, LinearScale, LineElement, PointElement, Tooltip, } from 'chart.js';
import moment from 'moment';
import numeral from 'numeral';
import React, { useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { displayMoneyFormatter } from '../../../../../../../common/utils/number-display.utils';
import { useStore } from '../../../../../../app/data/root.store';
import { TrendsTooltip } from './TrendsTooltip';
import { tooltipFunc } from './TrendsUtils';
import { TrendsDrawer } from './TrendsDrawer';
import { colors } from '../../../../../../../common/styles/theme.styles';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip
);

const trendsStyle = {
    ticksColor: {
        whiteTheme: '#ACB1BB',
        blackTheme: colors.darkGray3,
    },
    gridColor: {
        whiteTheme: 'rgba(172, 177, 187, 0.10)',
        blackTheme: 'rgba(67, 69, 73, 0.2)',
    }
}

export const TrendsHeroGraph = (props) => {
    const { wealthStore, itemStore, uiStore: { isDesktopView } } = useStore();
    const [drawerIndex, setDrawerIndex] = useState(null);
    const { data, whiteTheme, lineColor = 'rgba(255, 255, 255, 1)', lineBgColor = 'rgba(255, 255, 255, 0.2)', trendType , showAbsoluteValues } = props;
    const chartRef = useRef(null);
    const dataValues = data.length === 1 ? [data[0].value, data[0].value] : data.map(item => showAbsoluteValues ? Math.abs(item.value) : item.value);
    const labels = data.length === 1 ? [moment(data[0].date).format('MMM YYYY'), 'Today'] : data.map((item, index) =>
        index === data.length - 1 ? 'Today' : moment(item.date).format('MMM YY')
    );

    //const heighestValue = dataValues.reduce((acc, curr) => Math.max(acc, curr), Number.MIN_SAFE_INTEGER);
    // const roundedHighestValue = CeilTo10(heighestValue);

    // const minValue = dataValues.reduce((acc, curr) => Math.min(acc, curr), Number.MAX_SAFE_INTEGER);
    // const roundedMinValue = FloorTo10(minValue);

    // const stepSize = ((roundedHighestValue - roundedMinValue) / 5);
    // const max = (roundedHighestValue * 1.2);//((roundedHighestValue === heighestValue) ? (roundedHighestValue + stepSize) : roundedHighestValue);
    // const min = (roundedMinValue * 0.8);//(((roundedMinValue === minValue) && (minValue !== 0)) ? (roundedMinValue - stepSize) : roundedMinValue);

    const theme = whiteTheme ? 'whiteTheme' : 'blackTheme';


    const handleGraphClick = (event) => {
        const chart = chartRef.current;
        if (!chart) return;
        const points = chart.getElementsAtEventForMode(
            event.nativeEvent, // Use native event
            'nearest',         // Find the nearest point
            { intersect: false },
            false
        );

        if (points.length) {
            const pointIndex = points[0].index;
            setDrawerIndex(pointIndex);
        }
    };

    const chartData = {
        labels,
        datasets: [
            {
                data: dataValues,
                borderColor: lineColor,
                backgroundColor: lineBgColor,
                tension: 0.1, // Added tension for curve
                borderWidth: 1.5,
                pointRadius: 0,
                cubicInterpolationMode: 'monotone'
            }
        ]
    };

    const verticalLinePlugin = {
        id: 'verticalLine',
        afterDraw: (chart) => {
            const { ctx, tooltip } = chart;
            if (tooltip.opacity === 0) {
                return;
            }

            const x = chart.tooltip.dataPoints[0].element.x;

            ctx.save();
            ctx.beginPath();
            ctx.setLineDash([5, 5]);
            ctx.moveTo(x, chart.chartArea.top);
            ctx.lineTo(x, chart.chartArea.bottom);
            ctx.lineWidth = 1;
            ctx.strokeStyle = trendsStyle.ticksColor[theme];
            ctx.stroke();
            ctx.restore();
        },
    };

    const options = {
        interaction: {
            mode: 'index',
            intersect: false,
        },
        responsive: true,
        maintainAspectRatio: false, // Allow chart to fit the container's height
        plugins: {
            legend: {
                display: false, // Hide legend
            },
            title: {
                display: false, // Hide title
            },
            tooltip: {
                enabled: false,
                position: 'nearest',
                external: (context) => tooltipFunc(context, wealthStore),
            }
        },
        scales: {
            y: {
                // min,
                // max ,
                offset:1,
                // stacked:true,
                ticks: {
                    // stepSize,
                    color: trendsStyle.ticksColor[theme],
                    font: {
                        size: 12,
                        family: 'Circular',
                    },
                    beginAtZero: true,
                    maxTicksLimit : 5,
                    callback: (value) => {
                        switch (trendType) {
                            case 'total_net_worth':
                                return displayMoneyFormatter(value, wealthStore.viewOf === 'item' ? itemStore.item.currency : 'USD');
                            // return numeral(value).format('$0.[0]a').toUpperCase();
                            case 'xirr':
                                return numeral(value).format('0%').toUpperCase();
                            default:
                                return displayMoneyFormatter(value, 'USD');
                            //return numeral(value).format('($0.00a)');
                        }
                    }
                },
                grid: {
                    drawBorder: false,
                    color: trendsStyle.gridColor[theme],
                },
                border:{
                    display:false,
                },
            },
            x: {
                // stacked: true,
                // offset:1,
                ticks: {
                    color: trendsStyle.ticksColor[theme],
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0,
                    maxTicksLimit: 12,
                    font: {
                        size: 12,
                        family: 'Circular',
                    },
                    // callback: function (value, index, values) {
                    //     return moment().add(value,'M').format('MMM YY');
                    //     // console.log(value);
                    //     // return displayMoneyValue(value, 'USD', isIncognito);
                    // },
                },
                grid: {
                    display: false
                },
                border:{
                    display:false,
                },
            },
        }
    };

    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }} >
            {(isDesktopView) && <TrendsTooltip data={data} labels={labels} whiteTheme={whiteTheme} trendType={trendType} showAbsoluteValues={showAbsoluteValues} />}
            {!isDesktopView && <TrendsDrawer data={data} labels={labels} whiteTheme={whiteTheme} trendType={trendType} index={drawerIndex} onChangeIndex={(drawerIndex) => setDrawerIndex(drawerIndex)} showAbsoluteValues={showAbsoluteValues} />}
            <Line
                ref={chartRef}
                data={chartData}
                options={options}
                plugins={[verticalLinePlugin]}
                onClick={!isDesktopView ? handleGraphClick : undefined}
            />
        </div>
    );
};