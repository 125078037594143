import { Observer } from 'mobx-react';
import React from 'react';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
// import { CashflowIcon, InstitutionIcon, OwnershipIcon, WelcomeModalArrowIcon } from '../../icons/community-page-icons';
import { ActionButton, ModalBgWrapper, ModalContent, ModalFooter, ModalHeader, ModalHeaderIconWrapper, ModalPromptItem, ModalPromptItemDescription, ModalPromptItemTitle, ModalPromptWrapper, ModalSubTitle, ModalTitle } from './DealFlowWelcomeModal.styles';
import { WelcomeModalArrowIcon } from '../../../community/icons/community-page-icons';

export const DealFlowWelcomeModal = ({ onClose, isMobile }) => {

  return (<Observer>{() => (
    <BaseModal width={'580px'} height={'auto'} borderRadius={'8'}>
      <ModalBgWrapper>
        <ModalHeader>
          <ModalHeaderIconWrapper><HeaderIcon /></ModalHeaderIconWrapper>
          <ModalTitle>Welcome to Vyzer’s Top Performers Deal Flow</ModalTitle>
          <ModalSubTitle>Your exclusive access to data-backed investment intelligence.</ModalSubTitle>
        </ModalHeader>
      </ModalBgWrapper>
      <ModalContent>
        <ModalPromptWrapper>
          <ModalPromptItem>
            <ModalPromptItemTitle>Real Data. Real Performance. Real Value.</ModalPromptItemTitle>
            <ModalPromptItemDescription>Evaluate private market deal flow with verified performance metrics from experienced sponsors, GPs, and operators.</ModalPromptItemDescription>
          </ModalPromptItem>
          <ModalPromptItem>
            <ModalPromptItemTitle>Exclusive Deal Flow</ModalPromptItemTitle>
            <ModalPromptItemDescription>Get early access to upcoming opportunities through private webinars and detailed investment materials.</ModalPromptItemDescription>
          </ModalPromptItem>
          <ModalPromptItem>
            <ModalPromptItemTitle>Why Our Insights Stand Out</ModalPromptItemTitle>
            <ModalPromptItemDescription>Vyzer tracks billions in private market transactions, offering exclusive access to performance data and insights you won’t find anywhere else.</ModalPromptItemDescription>
          </ModalPromptItem>
        </ModalPromptWrapper>
      </ModalContent>
      <ModalFooter>
        <ActionButton onClick={onClose} black >Let’s go<WelcomeModalArrowIcon /></ActionButton>
      </ModalFooter>
    </BaseModal>
  )}</Observer>)
}

const HeaderIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
  <path d="M7.2627 4.70354C7.26271 3.91233 8.84512 2.59366 10.6913 3.12113C10.4275 4.17606 8.31764 5.23101 7.2627 4.70354Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M4.88147 7.24133C4.14143 6.96141 3.46783 5.01482 4.61429 3.47466C5.50771 4.09454 5.74802 6.4412 4.88147 7.24133Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M3.33848 10.5087C2.54832 10.5495 1.14966 9.03736 1.58105 7.16643C2.6482 7.37532 3.81073 9.42789 3.33848 10.5087Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M3.59513 14.3366C2.90994 14.7322 0.976675 14.0213 0.510276 12.1588C1.55572 11.8597 3.52437 13.1593 3.59513 14.3366Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M5.816 17.1938C5.45786 17.8994 3.44995 18.359 2.04249 17.053C2.75516 16.2317 5.11405 16.246 5.816 17.1938Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M8.4532 19.8306C8.09506 20.5361 6.08716 20.9957 4.6797 19.6897C5.39237 18.8684 7.75125 18.8827 8.4532 19.8306Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M4.66722 7.61295C4.90615 6.87425 6.50806 6.03241 7.75309 6.97908C7.23391 7.89912 5.31041 8.36496 4.66722 7.61295Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M3.73772 11.1251C3.65987 10.3526 4.78881 8.93832 6.30922 9.30527C6.20325 10.3564 4.62877 11.5555 3.73772 11.1251Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M3.73782 13.7613C3.65958 12.985 4.74682 11.5684 6.21958 11.9428C6.12097 12.9983 4.60176 14.197 3.73782 13.7613Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M5.26335 17.3525C5.05872 16.9352 5.60017 15.807 6.76617 15.5802C6.89193 16.2087 5.9881 17.3421 5.26335 17.3525Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M8.14795 19.4412C7.59687 19.1869 7.06075 17.9346 7.87419 17.1378C8.54523 17.615 8.77022 19.0586 8.14795 19.4412Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M10.4272 20.2639C8.51087 19.9193 6.77302 18.8862 5.51897 17.3459C4.26491 15.8056 3.57488 13.8568 3.57009 11.8418C3.56531 9.82673 4.24607 7.87439 5.49279 6.32775C6.73951 4.7811 8.47243 3.73909 10.3871 3.38477" stroke="#F0D2BD" strokeWidth="0.527473" strokeLinecap="round"/>
  <path d="M17.5479 4.70354C17.5478 3.91233 15.9654 2.59366 14.1193 3.12113C14.383 4.17606 16.4929 5.23101 17.5479 4.70354Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M19.9291 7.24133C20.6691 6.96141 21.3427 5.01482 20.1963 3.47466C19.3028 4.09454 19.0625 6.4412 19.9291 7.24133Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M21.4721 10.5087C22.2622 10.5495 23.6609 9.03736 23.2295 7.16643C22.1623 7.37532 20.9998 9.42789 21.4721 10.5087Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M21.2154 14.3366C21.9006 14.7322 23.8339 14.0213 24.3003 12.1588C23.2548 11.8597 21.2862 13.1593 21.2154 14.3366Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M18.9946 17.1938C19.3527 17.8994 21.3606 18.359 22.7681 17.053C22.0554 16.2317 19.6965 16.246 18.9946 17.1938Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M16.3573 19.8306C16.7155 20.5361 18.7234 20.9957 20.1308 19.6897C19.4182 18.8684 17.0593 18.8827 16.3573 19.8306Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M20.1433 7.61295C19.9044 6.87425 18.3025 6.03241 17.0575 6.97908C17.5766 7.89912 19.5001 8.36496 20.1433 7.61295Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M21.0728 11.1251C21.1507 10.3526 20.0217 8.93832 18.5013 9.30527C18.6073 10.3564 20.1818 11.5555 21.0728 11.1251Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M21.0727 13.7613C21.151 12.985 20.0637 11.5684 18.591 11.9428C18.6896 12.9983 20.2088 14.197 21.0727 13.7613Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M19.5472 17.3525C19.7518 16.9352 19.2104 15.807 18.0444 15.5802C17.9186 16.2087 18.8224 17.3421 19.5472 17.3525Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M16.6626 19.4412C17.2137 19.1869 17.7498 17.9346 16.9364 17.1378C16.2653 17.615 16.0403 19.0586 16.6626 19.4412Z" fill="#F0D2BD" stroke="#F0D2BD" strokeWidth="0.527473"/>
  <path d="M14.3833 20.2639C16.2997 19.9193 18.0375 18.8862 19.2916 17.3459C20.5456 15.8056 21.2357 13.8568 21.2405 11.8418C21.2452 9.82673 20.5645 7.87439 19.3178 6.32775C18.071 4.7811 16.3381 3.73909 14.4234 3.38477" stroke="#F0D2BD" strokeWidth="0.527473" strokeLinecap="round"/>
</svg>
)
