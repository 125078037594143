import { CloseIconWrapper, ConformationBody, ConformationButtonText, ConformationFooter, ConformationHeader, ConformationText, ConformationTextTitle } from "../../../modules/wealth/pages/asset/components/conformation/Conformation.style";
import { CloseIcon } from "../../../modules/wealth/pages/asset/components/conformation/icons/icons";
import { BaseModal } from "../BaseModal/BaseModal";
import { PrimaryButton, SecondaryButton } from "../buttons/NewButtons.styles";

export const Popup = ({onClose, buttons, title, messageLines})=> {

    return (
        <BaseModal borderRadius={8} width={'520px'} height={'auto'}>
        <ConformationHeader>
            <CloseIconWrapper onClick={onClose}>
                <CloseIcon style={{ stroke: '#9097A3' }} />
            </CloseIconWrapper>
        </ConformationHeader>
        <ConformationBody padding={'0 24px'}>
            <ConformationTextTitle>{title}</ConformationTextTitle>
            {messageLines.map((line, index) => <ConformationText key={index}>{line}</ConformationText>)}
        </ConformationBody>
        <ConformationFooter>
            {
                buttons.map((button, index) => {
                    switch (button.type) {
                        case 'primary':
                            return (
                                <PrimaryButton key={index} padding='12px 32px' onClick={button.onClick}>
                                    <ConformationButtonText isPrimary>{button.text}</ConformationButtonText>
                                </PrimaryButton>
                            );
                        case 'secondary':
                            return (
                                <SecondaryButton key={index} padding='12px 32px' onClick={button.onClick}>
                                    <ConformationButtonText>{button.text}</ConformationButtonText>
                                </SecondaryButton>
                            );
                        default:
                            return null;
                    }
                })
            }
        </ConformationFooter>
    </BaseModal>
    );
}