import { observer } from 'mobx-react'
import { Drawer } from "antd";
import { TrendsGraphDrawer, TrendsGraphOverviewItem, TrendsGraphOverviewItems, TrendsGraphTitle, TrendsPicker, TrendsPickerTitle } from "./TrendsHero.style";
import { displayNumberByTrendsType, getFormattedData } from "./TrendsUtils";
import { useStore } from "../../../../../../app/data/root.store";
import { NextMonthButtonWrapper, PreviousMonthButtonWrapper } from '../../../../../../cash-flow/components/TransactionsPanel/TransactionsPanel.styles';
import { ArrowDownSmallTriangleIcon } from '../../../../../../../assets/icons/common/common-icons';

export const TrendsDrawer = observer((props) => {
    const { metadataStore, itemStore, wealthStore } = useStore();
    const { labels, index, data, trendType, onChangeIndex , showAbsoluteValues } = props;

    const formattedData = getFormattedData(data, metadataStore, wealthStore.allocateBy, trendType, wealthStore.assetsAsObj);
    if (index === null || index === undefined || !formattedData[index] || !labels[index]) {
        return null;
    }

    const currData = formattedData?.[index];
    if (!currData || !labels[index]) {
        return null;
    }

    const onClose = () => onChangeIndex(null);
    const sortedBreakdown = Object.values(currData.breakdown).sort((a, b) => b.value - a.value);
    const displayCurrency = wealthStore.viewOf === 'item' ? itemStore.item.currency : 'USD';

    return (
        <Drawer
            className={'overviewInflowOutflowDrawer overviewInflowOutflowDrawer_mobile'}
            placement={'bottom'}
            closable={false}
            onClose={onClose}
            visible={(index !== null)}
            contentWrapperStyle={{
                height: 'auto',
                overflow: 'hidden'
            }}
        >
            <TrendsGraphDrawer>
                <TrendsGraphTitle>
                    <TrendsMonthPicker
                        data={formattedData}
                        currIndex={index}
                        onChangeIndex={onChangeIndex}
                        labels={labels}
                        value={showAbsoluteValues ? Math.abs(currData.value) : currData.value}
                        trendType={trendType}
                        displayCurrency={displayCurrency}
                    />
                </TrendsGraphTitle>
                {wealthStore.viewOf !== 'item' &&
                <TrendsGraphOverviewItems>
                    {sortedBreakdown.map((item, index) => (
                        <TrendsGraphOverviewItem borderLeftColor={item.color} key={index}>
                            <div>{item.name}</div>
                            <div>{displayNumberByTrendsType(showAbsoluteValues ? Math.abs(item.value) : item.value, trendType, displayCurrency)}</div>
                        </TrendsGraphOverviewItem>
                    ))}
                </TrendsGraphOverviewItems> }
            </TrendsGraphDrawer>
        </Drawer>
    );
});



export const TrendsMonthPicker = ({ data, currIndex, onChangeIndex, labels, value, trendType, displayCurrency }) => {
    return (
        <TrendsPicker>
            <PreviousMonthButtonWrapper disabled={(0 === currIndex)} onClick={() => onChangeIndex(currIndex - 1)}><ArrowDownSmallTriangleIcon /></PreviousMonthButtonWrapper>
            <TrendsPickerTitle>
                <div>{labels[currIndex]}</div>
                <div>{displayNumberByTrendsType(value, trendType, displayCurrency)}</div>
            </TrendsPickerTitle>
            <NextMonthButtonWrapper disabled={((data.length - 1) === currIndex)} onClick={() => onChangeIndex(currIndex + 1)}><ArrowDownSmallTriangleIcon /></NextMonthButtonWrapper>
        </TrendsPicker>
    )
}