import { Observer } from "mobx-react";
import { ModalCloseIcon } from "../../../../../../../assets/icons/common/common-icons";
import { BaseModal } from "../../../../../../../common/components/BaseModal/BaseModal"
import { ModalBody, ModalFooter } from "../../../../../../../common/components/BaseModal/BaseModal.styles"
import { CURRENCY_SYMBOL } from "../../../../../../../common/constants/currency-symbol.const";
import { getTranslations } from "../../../../../../../common/translations/translations";
import { disabledMoreThanToday } from "../../../../../../../common/utils/dates.utils";
import { ClassIconWrapper } from "../../../../../../add-item/components/CreateItemModal.styles";
import { isNotNegative, isRequired } from "../../../../../../add-item/data/input-validations";
import { AssetClassIcon } from "../../../../../../assets/components/AssetClassIcon";
import { FormWithStore } from "../../../../../../form-displayer-connector/components/FormWithStore";
import { useFormHook } from "../../../../../../form-displayer-connector/data/useFormHook";
import { ActionButton } from "../custom-form-components/HoldingsUnlockModal.styles";
import { AmortizationUpdateModalHeader, TitleWrapper } from "./LoansAdditionalDataModals.styles";
import { useStore } from "../../../../../../app/data/root.store";

export const NonAmortizationLoansInfoModal = ({ item, onClose , onSave }) => {

  const { title, name } = item;
  const { userStore } = useStore();
  const formName = 'updateAmortizationForm';    
  const amortizationForm = useFormHook(formName);

  const handleBack = () => {
    amortizationForm.setStep(amortizationForm.currentSectionIndex - 1);
  }

  const handleNext = async () => {
    if (userStore.isActionBlockedByRole('editItemInfo')) {
      return;
    }
    const sectionValidations = amortizationForm.currentSectionErrors;
    // if (!sectionValidations.isValid) return amortizationForm.setErrors(sectionValidations.errors);
    if (!sectionValidations.isValid){
      amortizationForm.setErrors(sectionValidations.errors);
      amortizationForm.setBottomErrors(sectionValidations.bottomErrors);
      return false;
    } 

    if (amortizationForm.currentSectionIndex !== amortizationForm.sections.length - 1) {
      amortizationForm.setStep(amortizationForm.currentSectionIndex + 1);
    } else {
      try {
        const { startDate, value, loanType , interest } = amortizationForm.values;
        const payload = {
          startDate,
          value : +value,
          loanType,
          interest: parseFloat(interest / 100)
        }
        onSave && onSave(payload);
        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  }

  const formSections = ({updateValue,updateField}) => {
    const { loans } = getTranslations().new_item_modal;
    return [
      {
        rows: [
          {
            fields: [
              {
                key: 'value',
                label: loans.VALUE_LABEL,
                description: 'Current loan amount, taking into account repayments already made',
                validations:[isRequired],
                type:'number',
                generatePropsFn : (values)=>{
                  return {
                    prefix: CURRENCY_SYMBOL[values?.currency] || '',
                    placeholder: CURRENCY_SYMBOL[values?.currency] || '',
                  }
                }
              }
            ]
          },
          {
            fields: [
              {
                key:'startDate', 
                type:'date',
                label: loans.START_DATE_LABEL,
                description: 'The month you received the cash', 
                validations:[isRequired],
                disabledDate: disabledMoreThanToday,
                // onChange:({value})=>{
                //   start_date = value
                // }
              }
            ]
          },
          {fields:[
            {
              key:'interest', 
              label: loans.AMORTIZATION_INTEREST_LABEL,
              // description: ' ',
              specificDecimalScale: 3,
              validations:[isRequired, isNotNegative],
              type:'percentage',
            }
          ]},
          {fields:[
            {
              key:'loanType', 
              type:'select', label:'Loan type', allowClear:true,
              validations:[isRequired],
              options: [
                  {value:'Mortgage',label:'Mortgage'},
                  {value:'Home equity',label:'Home equity'},
                  {value:'Line of credit',label:'Line of credit'},
                  {value:'Auto mobile',label:'Auto mobile'},
                  {value:'Business loan',label:'Business loan'},
                  {value:'Personal loans',label:'Personal loans'},
                  {value:'Student loan',label:'Student loan'},
                  {value:'CRE loan',label:'CRE loan'},
                  {value:'Other',label:'Other'}
              ]
            }
          ]},    
        ]
      },
    ]
  }

  return(<Observer>{() => (
    <BaseModal width={'580px'} height={'585px'} borderRadius={12}>
      <AmortizationUpdateModalHeader>
        
        <h3>Edit liability info</h3>

        <TitleWrapper>
          <ClassIconWrapper className='icon' color={"#EB7E7E"} size={38} iconSize={24}>
            <AssetClassIcon name='lia-loans' />
          </ClassIconWrapper>
          <div>
            <h4>{title}, {name}</h4>
            Loan
          </div>
        </TitleWrapper>
        <ModalCloseIcon onClick={onClose} />
      </AmortizationUpdateModalHeader>
      <ModalBody id="amortization-loan-modal">

      <FormWithStore 
        fullWidth 
        isStepsDisplay
        defaultValues={item}
        isDisabled={userStore.isActionBlockedByRole('editItemInfo')}
        formName={formName} 
        sections={formSections} 
        scrollContainerId={'amortization-loan-modal'} />

      </ModalBody>
      <ModalFooter justifyContent='space-between'>
        {amortizationForm?.currentSectionIndex === 0 && <ActionButton inline nowrap flexGrow='0' onClick={onClose}>Remind me next time</ActionButton>}
        {amortizationForm?.currentSectionIndex > 0 && <ActionButton inline nowrap flexGrow='0' onClick={handleBack}>Back</ActionButton>}
        {/* <FormFooterStepsWrapper>
          {amortizationForm?.sections.map((section, index) => (
            <FormFooterStepPoint key={index} isSelected={index === amortizationForm.currentSectionIndex} />
          ))}
        </FormFooterStepsWrapper> */}
        <ActionButton disabled={userStore.isActionBlockedByRole('editItemInfo')} inline nowrap black flexGrow='0' onClick={handleNext}>Continue →</ActionButton>
      </ModalFooter>
    </BaseModal>
  )}</Observer>)
}
