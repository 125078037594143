export const translations = {
  common: {
    asset_fields: {
      ANNUAL_INCREASE_IN_VALUE_LABEL: 'Expected annual increase in value %',
      START_DATE_LABEL: 'Account opening date',
      START_DATE_DESCRIPTION: 'This is the date you opened and funded this account',
      CASH_INVESTED_LABEL: 'Total investment',
      TITLE_LABEL: 'Account name',
      TITLE_DESCRIPTION: 'The name you want this account to appear under.',
      NAME_DESCRIPTION: 'The bank where you opened this account',
      HOLDINGS_LABEL: 'Ownership (%)',
      HOLDINGS_DESCRIPTION: 'The percentage of this account that belongs to you and should be reflected in your net worth',
      BENEFICIARY_DESCRIPTION: 'The person or company listed as the owner of this account',
      REMARKS_LABEL: 'Notes',
      REMARKS_DESCRIPTION: 'Save useful links, contact info, etc',
      ONLINE_PLATFORM_URL_LABEL: 'Online platform url',
      ONLINE_PLATFORM_URL_DESCRIPTION: 'If you have a platform or online portal for you to view additional information add it here',
      DISTRIBUTION_REINVESTED_LABEL: 'Distribution reinvested',
      DISTRIBUTION_REINVESTED_DESCRIPTION: 'Toggle ON if these distributions are reinvested into the fund.',
      PRINCIPAL_FIRST_REPAYMENT_LABEL:'Principal’s first payment date',
      PRINCIPAL_FIRST_REPAYMENT_DESCRIPTION:'For balloon payment set the last month of the loan term as the first principal repayment date.',
      INTEREST_FIRST_REPAYMENT_LABEL: 'Interest first payment date',
      INTEREST_FIRST_REPAYMENT_DESCRIPTION: 'Select the beginning of the interest payment',
      MONTHLY_REPAYMENT_LABEL: 'Monthly payment',
      MONTHLY_REPAYMENT_DESCRIPTION: 'The amount you pay every month, including principal and interest.',
      AMORTIZATION_INTEREST_LABEL: 'Initial interest rate',
      RISK_LEVEL_LABEL: 'Risk level',
      LIQUIDITY_LABEL: 'Liquidity',
      CUSTOM_CLASS_LABEL: 'Custom class',
      CUSTOM_CLASS_DESCRIPTION: 'The custom class you want this asset to be associated with',
      CUSTOM_SUB_CLASS_LABEL: 'Custom sub-class',
      CUSTOM_SUB_CLASS_DESCRIPTION : 'The custom sub-class you want this asset to be associated with',
    },
    address_search_input: {
      LOCATION_SEARCH_INPUT_PLACEHOLDER: 'Start typing for exact address'
    },
    item_overview: {
      IRR_DESCRIPTION: <>
      The internal rate of return (IRR) is an annualized performance metric that represents the asset's annual increase/decrease in value PLUS any cash flow from/to this asset.
      <br />
      <br />
      For this calculation, Vyzer uses the XIRR formula, which also takes into account the dates when the cash flow actually occurs.
      </>
    }
  },
  new_item_modal: {
    cash_accounts: {
      NAME_LABEL: 'Institution',
      NAME_DESCRIPTION: 'The bank where you opened this account',
      VALUE_LABEL: 'Current balance',
      VALUE_DESCRIPTION: 'The account’s current value. Which will be added to your net worth.',
      physical_cash: {
        TITLE_LABEL: 'Asset title',
        TITLE_DESCRIPTION: 'Name of the cash you\'ve set aside. This is how it will appear in vyzer.',
        VALUE_DESCRIPTION: 'The account’s current value, which will be added to your net worth.',
        REMARKS_DESCRIPTION: 'Purpose, location, etc',
      }
    },
    real_estate: {
      common: {
        TITLE_DESCRIPTION: 'The name you want this property to appear under.',
        CONNECTED_LIABILITY_LABEL: 'Do you still have an outstanding loan against this property?',
        ADDRESS_DESCRIPTION: 'Where is this property located?',
        BENEFICIARY_LABEL: 'Holding entity',
        BENEFICIARY_DESCRIPTION: 'The person or company listed as the owner of this property',
      },
      more_info: {
        ADDRESS_LABEL: 'Property address',
      },
      rental_property: {
        sole_ownership: {
          VALUE_DESCRIPTION: 'The property’s current value, which will be added to your net worth.',
          MONTHLY_RENT_DESCRIPTION: 'The monthly rent you expect to receive from this property',
          EXPECTED_MONTHLY_EXPENSES_DESCRIPTION: 'Expenses related to maintenance, general operations, etc. Deducted monthly from your cash flow projections.',
          EXPECTED_ANNUAL_INCREASE_IN_VALUE: 'If you’re not sure, the benchmark for real estate increase is 2%',
          EXPECTED_SALE_PRICE_LABEL: 'Expected sale price',
          EXPECTED_SALE_PRICE_DESCRIPTION: 'The expected sale price of this property',
          EXPECTED_END_DATE_LABEL: 'Expected sale date',
          EXPECTED_END_DATE_DESCRIPTION: 'The expected sale date of this property',
          START_DATE_DESCRIPTION: 'The month you bought this property',
          CASH_INVESTED_DESCRIPTION: 'All funds put towards this property so far (renovation, maintenance, etc)'
        },
        owned_with_partners: {
          NAME_DESCRIPTION: 'The person or company who initiated and is managing this investment',
          HOLDINGS_LABEL: 'Ownership %',
          HOLDINGS_DESCRIPTION: `If you know how much this property is worth, give its current value and then indicate the percentage you hold.
                    If you don’t know the property value, give the amount you invested or any other estimate that reflects how much this property is worth to you, and then indicate 100% holdings of this amount. 
                    The calculated value will be added to your net worth.`,
          VALUE_LABEL: 'Initial Investment',
          VALUE_DESCRIPTION: `The amount you invested or any other estimate that reflects how much this property is worth to you.`,
          EXPECTED_ANNUAL_REVENUE_LABEL: 'Expected annual cash return',
          EXPECTED_ANNUAL_REVENUE_DESCRIPTION: 'The total amount you expect to get back on this investment, each year.', // You can define this as a percentage (%) of the investment or an absolute cash amount ($)',
          EXPECTED_DISTRIBUTION_PERIOD_LABEL: 'Expected distribution period',
          EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION: 'Expected returns will be added to your projected cash flow according to these distribution periods',
          EXPECTED_END_DATE_DESCRIPTION: 'Leave blank if you don’t have future plans to sell the property or cash out',
          EXPECTED_IRR_DESCRIPTION: 'Usually provided by the property’s sponsor/operator',
          EXPECTED_MULTIPLIER_DESCRIPTION: 'Usually provided by the property’s sponsor/operator',
          START_DATE_DESCRIPTION: 'The month you made your first investment in this property',
          CASH_INVESTED_LABEL: 'Total investment',
          CASH_INVESTED_DESCRIPTION: 'Overall investment amount and all contributions you’ve made so far',
          BENEFICIARY_DESCRIPTION: 'The person or company listed as the beneficiary of this investment',
        }
      },
      development: {
        sole_ownership: {
          VALUE_LABEL: 'Current property value',
          VALUE_DESCRIPTION: 'The property’s current value, which will be added to your net worth.',
          EXPECTED_END_DATE_DESCRIPTION: 'The expected end date of this investment',
          EXPECTED_SALE_PRICE_DESCRIPTION: 'This estimated price will be added to your projected cash flow after the expected sale date',
          START_DATE_DESCRIPTION: 'The month you bought this property',
          CASH_INVESTED_DESCRIPTION: 'Overall investment amount and all contributions made so far (including renovation, maintenance, etc)',
          BENEFICIARY_DESCRIPTION: 'The person or company listed as the owner of this property'
        },
        owned_with_partners: {
          VALUE_LABEL: 'Current value of your investment',
          VALUE_LABEL_SUBTEXT: 'A capital call schedule can be added later',
          VALUE_DESCRIPTION: `If you know how much this property is worth, give its current value and then indicate the percentage you hold.
                    If you don’t know the property value, give the amount you invested or any other estimate that reflects how much this property is worth to you, and then indicate 100% holdings of this amount.
                    The calculated value will be added to your net worth.`,
          EXPECTED_ANNUAL_REVENUE_LABEL: 'Expected annual cash return',
          EXPECTED_ANNUAL_REVENUE_DESCRIPTION: 'The total amount you expect to get back on this investment, each year.', // You can define this as a percentage (%) of the investment or an absolute cash amount ($)',
          EXPECTED_DISTRIBUTION_PERIOD_LABEL: 'Expected distribution period',
          EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION: 'Expected returns will be added to your projected cash flow according to these distribution periods',
          EXPECTED_END_DATE_LABEL: 'Expected sale date',
          EXPECTED_SALE_PRICE_LABEL: 'Expected sale price',
          EXPECTED_END_DATE_DESCRIPTION: 'The expected end date of this investment',
          EXPECTED_IRR_DESCRIPTION: 'Usually provided by the property’s sponsor/operator',
          EXPECTED_SALE_PRICE_DESCRIPTION: 'The return on your investment will be added to your projected cash flow according to this estimated price, on the expected sale date',
          START_DATE_DESCRIPTION: 'The month you made your first investment in this property',
          CASH_INVESTED_DESCRIPTION: 'Overall investment amount and all contributions you’ve made so far',
          BENEFICIARY_DESCRIPTION: 'The person or company listed as the beneficiary of this investment',
          EXPECTED_MULTIPLIER_DESCRIPTION: 'Usually provided by the property’s sponsor/operator',
        }
      },
      residence: {
        VALUE_LABEL: 'Current property value',
        VALUE_DESCRIPTION: 'The property’s current value, which will be added to your net worth.',
        START_DATE_LABEL: 'Purchase date',
        ANNUAL_INCREASE_IN_VALUE_LABEL: 'Expected annual increase in value %',
        ANNUAL_INCREASE_IN_VALUE_DESCRIPTION: 'If you’re not sure, the benchmark for real estate increase is 2%',
        EXPECTED_MONTHLY_EXPENSES_LABEL: 'Expected monthly expenses',
        EXPECTED_MONTHLY_EXPENSES_DESCRIPTION: 'Estimated expenses will be deducted monthly from your projected cash flow',
        START_DATE_DESCRIPTION: 'The month you bought this property',
        CASH_INVESTED_DESCRIPTION: 'Excluding loan payments, all funds put towards this property so far (mortgage payments, renovation, etc)',
      }
    },
    long_term_savings: {
      NAME_LABEL: 'Institution',
      NAME_DESCRIPTION: 'The company that manages this retirement account',
      RETIREMENT_TITLE_DESCRIPTION: 'The name you want this account to appear under.',
      CHILDRENS_SAVINGS_TITLE_DESCRIPTION: 'The name you want this account to appear under.',
      OTHER_TITLE_DESCRIPTION: 'The name you want this account to appear under.',
      VALUE_LABEL: 'Current balance',
      VALUE_DESCRIPTION: 'The account’s current value. Which will be added to your net worth.',
      EXPECTED_ANNUAL_PERFORMANCE_DESCRIPTION: 'If you’re not sure, the benchmark for this type of account is 4%',
      EXPECTED_MONTHLY_CONTRIBUTION_DESCRIPTION: 'The amount you plan to add to this account each month',
      START_DATE_DESCRIPTION: 'The month you opened this account and made your first deposit',
      CASH_INVESTED_DESCRIPTION: 'All deposits that were made to this retirement plan so far',
    },
    brokerage_accounts: {
      NAME_LABEL: 'Institution',
      NAME_DESCRIPTION: 'The company that manages this brokerage account',
      VALUE_LABEL: 'Current balance',
      EXPECTED_ANNUAL_PERFORMANCE_LABEL: 'Expected annual performance %',
      BENEFICIARY_LABEL: 'Holding entity',
      REMARKS_DESCRIPTION: 'Save useful links, contact info, etc',
      DEFAULT_CASH_ACCOUNT_DESCRIPTION: 'Pick the bank account you use for this account’s transactions (distributions/contributions)'
    },
    private_loans: {
      TITLE_DESCRIPTION: 'The name you want this asset to appear under.',
      TITLE_LABEL: 'Loan title',
      VALUE_LABEL: 'Current balance',
      INTEREST_LABEL: 'Annual interest rate %',
      INTEREST_DESCRIPTION: 'The agreed upon interest rate for this loan',
      START_DATE_LABEL: 'Initial loan date',
      START_DATE_DESCRIPTION: 'The month you gave the borrower this loan',
      CASH_INVESTED_LABEL: 'Total amount borrowed',
      CASH_INVESTED_DESCRIPTION: 'The initial loan amount, plus any additional amounts added later on',
    },
    p2p_lending_platform: {
      VALUE_LABEL: 'Current balance',
      NAME_DESCRIPTION: 'The online platform that manages your P2P loans',
      EXPECTED_IRR_LABEL: 'Expected annual return %',
      EXPECTED_IRR_DESCRIPTION: 'How much you expect your account’s value to increase, regardless of future contributions',
      EXPECTED_MONTHLY_CONTRIBUTION_DESCRIPTION: 'The amount you plan to add to this account each month',
      START_DATE_LABEL: 'Account opening date',
      START_DATE_DESCRIPTION: 'The month you opened this account and made your first deposit',
      CASH_INVESTED_DESCRIPTION: 'All deposits that were made to this lending account so far',
    },
    private_businesses: {
      equity: {
        SHARES_PURCHASED_DESCRIPTION: 'The amount of shares you own in this company/business',
        PRICE_PER_SHARE_DESCRIPTION: 'The price per share as of today',
        VALUE_LABEL: 'Current value',
        VALUE_DESCRIPTION: 'The current value of your shares, will be added to your net worth',
        START_DATE_LABEL: 'Equity start date',
        START_DATE_DESCRIPTION: 'The month you bought or received these shares',
        CASH_INVESTED_DESCRIPTION: 'The amount invested in the business/company ownership so far',
      },
      options: {
        NUMBER_OF_OPTIONS_DESCRIPTION: 'The number of options or RSUs you have in this company/business',
        EXERCISE_PRICE_PER_SHARE_LABEL: 'Strike price per share',
        EXERCISE_PRICE_PER_SHARE_DESCRIPTION: 'The price you expect to pay when exercising your options to buy company shares',
        PRICE_PER_SHARE_LABEL: 'Current share price',
        PRICE_PER_SHARE_DESCRIPTION: 'The latest known price for this company’s shares',
        VALUE_LABEL: 'Current asset value',
        VALUE_DESCRIPTION: 'The amount that will be reflected in your net worth, based on your current accumulation and share price, minus your strike price',
        OPTION_EXPIRATION_PERIOD_LABEL: 'Options expiration period (months)',
        OPTION_EXPIRATION_PERIOD_DESCRIPTION: 'The time limit you have for exercising these options',
      },
      traditional_business: {
        SHARES_PURCHASED_DESCRIPTION: 'The amount of shares you own in this company/business',
        PRICE_PER_SHARE_DESCRIPTION: 'The price per share as of today',
        VALUE_LABEL: 'Total business value',
        VALUE_DESCRIPTION: 'This will be reflected in your net worth',
        START_DATE_LABEL: 'Start date',
        CASH_INVESTED_LABEL: 'Initial Money Invested',
        CASH_INVESTED_DESCRIPTION: 'The amount invested in the business/company ownership so far',
        HOLDINGS_LABEL: 'Ownership %',
        HOLDINGS_DESCRIPTION: 'The percentage you own of this business',
        ANNUAL_INCREASE_IN_VALUE_LABEL: 'Expected annual increase in value',
        BENEFICIARY_LABEL: 'Holding entity / Beneficiary',
        BENEFICIARY_DESCRIPTION: 'The person or company listed as the owner of these shares',
        EXPECTED_MULTIPLIER_DESCRIPTION: 'Usually provided by the property’s sponsor/operator',
      },
    },
    managed_funds: {
      hedge_fund: {
        NAME_LABEL: 'Firm name',
        NAME_DESCRIPTION: 'The name of the Fund or Manager',
        VALUE_LABEL: 'Initial investment',
        VALUE_DESCRIPTION: 'The amount you invested or any other estimate that reflects how much this property is worth to you.',
        EXPECTED_IRR_LABEL: 'Expected IRR',
        EXPECTED_IRR_DESCRIPTION: 'The expected internal rate of return (IRR) of this account, set by your fund manager',
        EXPECTED_MULTIPLIER_DESCRIPTION: 'Usually provided by the property’s sponsor/operator',
        EXPECTED_ANNUAL_DIVIDEND_LABEL: 'Expected annual distribution %',
        EXPECTED_ANNUAL_DIVIDEND_DESCRIPTION: 'The cash distribution you expect from this fund every year',
        EXPECTED_DISTRIBUTION_PERIOD_LABEL: 'Expected distribution period',
        EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION: 'How often you expect to receive the cash distribution',
        EXPECTED_END_DATE_LABEL: 'Expected maturity date',
        EXPECTED_END_DATE_DESCRIPTION: 'The date when you expect to liquidate this asset/account',
        START_DATE_LABEL: 'Initial investment date',
        START_DATE_DESCRIPTION: 'The month you opened this account and made your first deposit',
        CASH_INVESTED_DESCRIPTION: 'Overall amount added to this account since it was opened',
        CASH_RECEIVED_DESCRIPTION: 'Any withdrawals made from this account, will be deducted from its value',
        BENEFICIARY_LABEL: 'Holding entity',
        BENEFICIARY_DESCRIPTION: 'If this position is held through an LLC, 3rd party, other family member, this is the place to note it',
        DEFAULT_CASH_ACCOUNT_DESCRIPTION: 'Pick the bank account you use for this account’s transactions (distributions/contributions)',
        EXPECTED_ANNUAL_REVENUE_LABEL: 'Expected annual cash return',
        EXPECTED_ANNUAL_REVENUE_DESCRIPTION: 'The total amount you expect to get back on this investment, each year.',
      },
      real_estate_fund: {
        NAME_LABEL: 'Fund name',
        NAME_DESCRIPTION: 'The name of the Firm or Fund',
        VALUE_LABEL: 'Initial investment',
        VALUE_DESCRIPTION: 'The amount you invested or any other estimate that reflects how much this property is worth to you.',
        EXPECTED_IRR_LABEL: 'Expected IRR',
        EXPECTED_IRR_DESCRIPTION: 'The expected internal rate of return (IRR) of this account, set by your fund manager',
        EXPECTED_MULTIPLIER_DESCRIPTION: 'Usually provided by the property’s sponsor/operator',
        EXPECTED_ANNUAL_DIVIDEND_LABEL: 'Expected annual distribution %',
        EXPECTED_ANNUAL_DIVIDEND_DESCRIPTION: 'The cash distribution you expect from this fund every year',
        EXPECTED_DISTRIBUTION_PERIOD_LABEL: 'Expected distribution period',
        EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION: 'How often you expect to receive the cash distribution',
        EXPECTED_END_DATE_LABEL: 'Expected maturity date',
        EXPECTED_END_DATE_DESCRIPTION: 'The date when you expect to liquidate this asset/account',
        START_DATE_LABEL: 'Initial investment date',
        START_DATE_DESCRIPTION: 'The month you opened this account and made your first deposit',
        CASH_INVESTED_DESCRIPTION: 'Overall amount added to this account since it was opened',
        CASH_RECEIVED_DESCRIPTION: 'Any withdrawals made from this account, will be deducted from its value',
        BENEFICIARY_LABEL: 'Holding entity',
        BENEFICIARY_DESCRIPTION: 'If this position is held through an LLC, 3rd party, other family member, this is the place to note it',
        EXPECTED_ANNUAL_REVENUE_LABEL: 'Expected annual cash return',
        EXPECTED_ANNUAL_REVENUE_DESCRIPTION: 'The total amount you expect to get back on this investment, each year.',
      },
      private_equity: {
        NAME_LABEL: 'Firm name',
        NAME_DESCRIPTION: 'The name of the Firm or Fund',
        VALUE_LABEL: 'Initial investment',
        VALUE_DESCRIPTION: 'The amount you invested or any other estimate that reflects how much this property is worth to you.',
        EXPECTED_IRR_LABEL: 'Expected IRR',
        EXPECTED_IRR_DESCRIPTION: 'The expected internal rate of return (IRR) of this account, set by your fund manager',
        EXPECTED_MULTIPLIER_DESCRIPTION: 'Usually provided by the property’s sponsor/operator',
        EXPECTED_ANNUAL_DIVIDEND_LABEL: 'Expected annual distribution %',
        EXPECTED_ANNUAL_DIVIDEND_DESCRIPTION: 'The cash distribution you expect from this fund every year',
        EXPECTED_DISTRIBUTION_PERIOD_LABEL: 'Expected distribution period',
        EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION: 'How often you expect to receive the cash distribution',
        EXPECTED_END_DATE_LABEL: 'Expected maturity date',
        EXPECTED_END_DATE_DESCRIPTION: 'The date when you expect to liquidate this asset/account',
        START_DATE_LABEL: 'Initial investment date',
        START_DATE_DESCRIPTION: 'The month you opened this account and made your first deposit',
        CASH_INVESTED_DESCRIPTION: 'Overall amount added to this account since it was opened',
        CASH_RECEIVED_DESCRIPTION: 'Any withdrawals made from this account, will be deducted from its value',
        BENEFICIARY_LABEL: 'Holding entity',
        BENEFICIARY_DESCRIPTION: 'If this position is held through an LLC, 3rd party, other family member, this is the place to note it',
        EXPECTED_ANNUAL_REVENUE_LABEL: 'Expected annual cash return',
        EXPECTED_ANNUAL_REVENUE_DESCRIPTION: 'The total amount you expect to get back on this investment, each year.',
      },
      venture_capital: {
        NAME_LABEL: 'Firm name',
        NAME_DESCRIPTION: 'The name of the Firm or Fund',
        VALUE_LABEL: 'Initial investment',
        VALUE_DESCRIPTION: 'The amount you invested or any other estimate that reflects how much this property is worth to you.',
        EXPECTED_IRR_LABEL: 'Expected IRR',
        EXPECTED_IRR_DESCRIPTION: 'The expected internal rate of return (IRR) of this account, set by your fund manager',
        EXPECTED_MULTIPLIER_DESCRIPTION: 'Usually provided by the property’s sponsor/operator',
        EXPECTED_ANNUAL_DIVIDEND_LABEL: 'Expected annual distribution %',
        EXPECTED_ANNUAL_DIVIDEND_DESCRIPTION: 'The cash distribution you expect from this fund every year',
        EXPECTED_DISTRIBUTION_PERIOD_LABEL: 'Expected distribution period',
        EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION: 'How often you expect to receive the cash distribution',
        EXPECTED_END_DATE_LABEL: 'Expected maturity date',
        EXPECTED_END_DATE_DESCRIPTION: 'The date when you expect to liquidate this asset/account',
        START_DATE_LABEL: 'Account opening date',
        START_DATE_DESCRIPTION: 'The month you opened this account and made your first deposit',
        CASH_INVESTED_DESCRIPTION: 'Overall amount added to this account since it was opened',
        CASH_RECEIVED_DESCRIPTION: 'Any withdrawals made from this account, will be deducted from its value',
        BENEFICIARY_LABEL: 'Holding entity',
        BENEFICIARY_DESCRIPTION: 'If this position is held through an LLC, 3rd party, other family member, this is the place to note it',
        EXPECTED_ANNUAL_REVENUE_LABEL: 'Expected annual cash return',
        EXPECTED_ANNUAL_REVENUE_DESCRIPTION: 'The total amount you expect to get back on this investment, each year.',
      },
      debt_fund: {
        NAME_LABEL: 'Firm name',
        NAME_DESCRIPTION: 'The name of the Firm or Fund',
        VALUE_LABEL: 'Initial investment',
        VALUE_DESCRIPTION: 'The amount you invested or any other estimate that reflects how much this property is worth to you.',
        EXPECTED_IRR_LABEL: 'Expected IRR',
        EXPECTED_IRR_DESCRIPTION: 'The expected internal rate of return (IRR) of this account, set by your fund manager',
        EXPECTED_MULTIPLIER_DESCRIPTION: 'Usually provided by the property’s sponsor/operator',
        EXPECTED_ANNUAL_DIVIDEND_LABEL: 'Expected annual distribution %',
        EXPECTED_ANNUAL_DIVIDEND_DESCRIPTION: 'The cash distribution you expect from this fund every year',
        EXPECTED_DISTRIBUTION_PERIOD_LABEL: 'Expected distribution period',
        EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION: 'How often you expect to receive the cash distribution',
        EXPECTED_END_DATE_LABEL: 'Expected maturity date',
        EXPECTED_END_DATE_DESCRIPTION: 'The date when you expect to liquidate this asset/account',
        START_DATE_LABEL: 'Account opening date',
        START_DATE_DESCRIPTION: 'The month you opened this account and made your first deposit',
        CASH_INVESTED_DESCRIPTION: 'Overall amount added to this account since it was opened',
        CASH_RECEIVED_DESCRIPTION: 'Any withdrawals made from this account, will be deducted from its value',
        BENEFICIARY_LABEL: 'Holding entity',
        BENEFICIARY_DESCRIPTION: 'If this position is held through an LLC, 3rd party, other family member, this is the place to note it',
        EXPECTED_ANNUAL_REVENUE_LABEL: 'Expected annual cash return',
        EXPECTED_ANNUAL_REVENUE_DESCRIPTION: 'The total amount you expect to get back on this investment, each year.',
      },
    },
    crypto: {
      NAME_LABEL: 'Exchange or wallet name',
      NAME_DESCRIPTION: 'The platform where you opened this account',
      COIN_LABEL: 'Coin',
      COIN_DESCRIPTION: 'The type of crypto coin owned',
      VALUE_LABEL: 'Currency balance',
      VALUE_DESCRIPTION: 'The cryptocurrency account’s value, which will be added to your net worth.',
      ANNUAL_INCREASE_IN_VALUE_LABEL: 'Expected annual increase in value %',
      ANNUAL_INCREASE_IN_VALUE_DESCRIPTION: 'How much you expect your cryptocurrency’s value to increase, regardless of future contributions',
      START_DATE_LABEL: 'Account opening date',
      START_DATE_DESCRIPTION: 'The month you opened your account and bought this cryptocurrency',
      CASH_INVESTED_DESCRIPTION: 'Overall amount invested in this cryptocurrency so far',
    },
    other: {
      TITLE_DESCRIPTION: 'The name you want this asset to appear under.',
      VALUE_LABEL: 'Current asset value',
      VALUE_DESCRIPTION: 'The asset’s current value, which will be added to your net worth.',
      PURCHASE_PRICE_DESCRIPTION: 'The amount you initially paid for these collectibles',
      ANNUAL_INCREASE_IN_VALUE_LABEL: 'Expected annual change in value %',
      ANNUAL_INCREASE_IN_VALUE_DESCRIPTION: 'How much you expect these collectibles’ value to increase or decrease every year',
      START_DATE_DESCRIPTION: 'The month you first bought these collectibles',
      CASH_INVESTED_DESCRIPTION: 'Overall amount invested in buying these collectibles so far',
    },
    loans: {
      TITLE_DESCRIPTION: 'The name you want this loan to appear under.',
      NAME_LABEL: 'Lender',
      NAME_DESCRIPTION: 'The bank where you opened this account',
      VALUE_LABEL: 'Initial borrowed amount',
      VALUE_DESCRIPTION: '',
      INTEREST_LABEL: 'Annual interest rate',
      AMORTIZATION_INTEREST_LABEL: 'Interest rate',
      MONTHLY_REPAYMENT_LABEL: 'Monthly payment',
      START_DATE_LABEL: 'Date you received the loan',
      START_DATE_DESCRIPTION: 'The month you took out this loan',
      CASH_RECEIVED_LABEL: 'Total amount borrowed',
      CASH_RECEIVED_DESCRIPTION: 'The initial loan amount, plus any additional amounts added later on',
      CASH_INVESTED_LABEL: 'Total amount paid back',
      CASH_INVESTED_DESCRIPTION: 'The amount you’ve already paid back so far',
      EXPECTED_END_DATE_DESCRIPTION: 'The month and year expected to pay the loan in full.',
      AMORTIZATION_DESCRIPTION: 'The length of time it takes to pay back principal and interest.',
      PRINCIPAL_FIRST_REPAYMENT_LABEL:'Principal’s first payment date'
    },
    credit_cards: {
      TITLE_LABEL: 'Liability title',
      TITLE_DESCRIPTION: 'The name you want this liability to appear under.',
      NAME_LABEL: 'Credit card provider',
      NAME_DESCRIPTION: 'The bank where you opened this account',
      VALUE_LABEL: 'Current balance',
      VALUE_DESCRIPTION: 'The amount you currently owe on this card',
    }
  },
  item_overview: {
    managed_funds: {
      contributions: {
        TITLE_LABEL: 'Total contributions',
      },
      distributions: {
        TITLE_LABEL: 'Total distributions',
      },
    },
    real_estate: {
      rental_property: {
        syndication: {
          rate_of_return: {
            TITLE_LABEL: 'CoC',
            TITLE_DESCRIPTION: `Cash on Cash Return measures the income your invested capital generates as a percentage of your total cash investment. It reflects the return on capital and excludes the return of capital (based on the total contributions made).`,
          }
        }
      },
      development: {
        syndication: {
          rate_of_return: {
            TITLE_LABEL: 'CoC',
          }
        }
      }
    },
  }
}
