import { Dropdown } from 'antd'
import { Observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useStore } from '../../app/data/root.store'
// import { CommunityListHeader } from './CommunityListHeader/CommunityListHeader'
// import { CommunityOverviewHero } from './CommunityOverviewHero/CommunityOverviewHero'
import { CommunityPageHeader, CommunityPageHeaderTitle, CommunityPageWrapper, 
  CountDownActionButton, 
  CountDownLeftText, 
  CountDownUnitNumber, 
  CountDownUnitsWrapper, 
  CountDownUnitText, 
  CountDownUnitWrapper, 
  // CountDownActionButton, CountDownLeftText, HeroCountDownSectionWrapper,
  // CountDownUnitNumber, CountDownUnitsWrapper, CountDownUnitText, CountDownUnitWrapper, 
  DealActionButton, DealActionButtonsWrapper, DealBadge, DealBadgesWrapper, DealDetailsTextRowWithIcon, DealDetailsTextWrapper, DealItemBoxWrapper, DealItemLeftPanel, DealItemLeftPanelDetails, DealItemLeftPanelDetailsBottomText, DealItemLeftPanelDetailsTopText, DealItemLeftPanelTitle, DealItemLeftPanelTop, DealItemRightPanel, DealItemRightPanelTop, DealsListWrapper, DealStatsRowWrapper, DealStatUnitNumber, DealStatUnitText, DealStatUnitWrapper, HeaderActions,  HeaderTitle,  HeaderWrapper, HeroBgWrapper, HeroCountDownSectionWrapper, HeroSubTitle, HeroTitle, MenuCloseButtonWrapper, MenuContentWrapper, MenuHeader, MenuItemContentWrapper, MenuItemIconWrapper, MenuItemSubTitle, MenuItemTitle, MenuItemWrapper, MenuTitle, MenuWrapper, MobileHeader, PageWrapper, StickyWrapper, WhatYouGetButton } from './DealFlowPage.styles'
// import { CommunityWelcomeModal } from './CommunityWelcomeModal/CommunityWelcomeModal'
// import { CommunityItemsListViewSwitcher } from './items-list/CommunityItemsListViewSwitcher'
import KkrLogo from '../assets/kkr-logo.png'
import LongAngleLogo from '../assets/long-angle.png'
import MagCapitalLogo from '../assets/mag.png'
import MlgLogo from '../assets/mlg.gif'
import { DealFlowWelcomeModal } from './DealFlowWelcomeModal/DealFlowWelcomeModal'
// import Countdown from 'react-countdown';

import NewVestLogo from '../assets/new-vest.png'
import { useAnalytics } from '../../app/data/AnalyticsProvider'

import Countdown from 'react-countdown';
import { ls } from '../../../common/utils/localstorage.util'
import { MobileBackButtonWrapper } from '../../layout/components/MobileLayout.styles'
import { MobileBackButton } from '../../../assets/icons/common/common-icons'

export const DealFlowPage = () => {
  const { uiStore, userStore } = useStore();
  const [isWhatYouGetMenuOpen, setIsWhatYouGetMenuOpen] = useState(false);

  const [showModal, setShowModal] =  useState(ls.get('topPerformerShown_' + userStore.data.id) === '1' ? false : true);
  // const [showModal, setShowModal] =  useState(true);
  // const communityPageContentRef = useRef();
  const { trackEvent } = useAnalytics();

  

  // const onScroll = () => {
  //   if (communityPageContentRef.current) {
  //     const { scrollTop } = communityPageContentRef.current;
  //     if (scrollTop === 0) {
  //       uiStore.setIsCommunityPageHeaderSticky(false);
  //     } else {
  //       uiStore.setIsCommunityPageHeaderSticky(true);
  //     }
  //   }
  // };

  useEffect(() => {
    uiStore.setIsRightMenuOpen(false);
    // return () => {}
  }, [uiStore])

  // useEffect(() => {
  //   communityStore.getData();
  //   // return () => {}
  // }, [communityStore, dataLayersStore])

  const sendCtaClick = (sponsorName,text,dest) => {
    trackEvent('CTA Clicked', {
      cta_text: text,
      destination: dest,
      location: 'Deal flow page - ' + sponsorName,
      type: 'Button',
      // institution: item.name,
    })
  }

  const handleCloseWelcomeModal = () => {
    setShowModal(false);
    ls.set('topPerformerShown_' + userStore.data.id, 1);
    sendCtaClick('Welcome Modal',`Let's go`,'')
  }

  // Renderer callback with condition
const countDownRenderer = ({ days, hours, minutes, seconds }) => {

    // Render a countdown
    return (
      <CountDownUnitsWrapper>
      <CountDownUnitWrapper>
        <CountDownUnitNumber>{days<10?'0':''}{days}</CountDownUnitNumber>
        <CountDownUnitText>Days</CountDownUnitText>
      </CountDownUnitWrapper>
      <CountDownUnitWrapper>
        <CountDownUnitNumber>{hours<10?'0':''}{hours}</CountDownUnitNumber>
        <CountDownUnitText>Hours</CountDownUnitText>
      </CountDownUnitWrapper>
      <CountDownUnitWrapper>
        <CountDownUnitNumber>{minutes<10?'0':''}{minutes}</CountDownUnitNumber>
        <CountDownUnitText>Minutes</CountDownUnitText>
      </CountDownUnitWrapper>
      <CountDownUnitWrapper style={{alignItems:'flex-start'}}>
        <CountDownUnitNumber >{seconds<10?'0':''}{seconds}</CountDownUnitNumber>
        <CountDownUnitText>Seconds</CountDownUnitText>
      </CountDownUnitWrapper>
      </CountDownUnitsWrapper>
    )  
  
};

  return (<Observer>{() => (
    <CommunityPageWrapper>

      {uiStore.isDesktopView ?
      <StickyWrapper>
        <CommunityPageHeader>

           <HeaderWrapper>
            <CommunityPageHeaderTitle>Welcome to Vyzer’s Top Performer Deal Flow</CommunityPageHeaderTitle>
            {/* <HeaderAltText>Learn from the investment experience of other Vyzers. Honest, Secure, Anonymous Insights</HeaderAltText> */}
          </HeaderWrapper>

          <HeaderActions>
            <Dropdown
              overlay={CreateMenu({ setMenuVisibility: setIsWhatYouGetMenuOpen })}
              trigger={['click']}
              placement='bottomRight'
              visible={isWhatYouGetMenuOpen}
              onVisibleChange={(visible) => { setIsWhatYouGetMenuOpen(visible) }}>
              <WhatYouGetButton isOpen={isWhatYouGetMenuOpen}><QuestionMarkSvg /> How We Select Top Performers</WhatYouGetButton>
            </Dropdown>

  
          </HeaderActions>

        </CommunityPageHeader>
      </StickyWrapper> 
:
         <MobileHeader isHeaderFixed={uiStore.isMobileHeaderFixed}>
            <MobileBackButtonWrapper onClick={() => { uiStore.setIsAppMobileMenuOpen(true) }}><MobileBackButton /></MobileBackButtonWrapper>
            <HeaderTitle>Deal flow</HeaderTitle>
          </MobileHeader>}
      
        <PageWrapper withHeader >
          <div>

            <HeroBgWrapper withoutTimer={false}>
              <HeroIcon />
              <HeroTitle>Top Performer Deal Flow</HeroTitle>
              <HeroSubTitle>With thousands of private market sponsors and GPs out there,
                <br/>who’s actually delivering? Let data tell the story.</HeroSubTitle>
                <HeroCountDownSectionWrapper>
                  <CountDownLeftText>Next webinar in<ArrowNextWebinarIcon/></CountDownLeftText>
                  <Countdown
                      zeroPadTime={2} 
                      
                      date={new Date(Date.UTC(2025, 0, 30, 17, 0))}
                      renderer={countDownRenderer}
                    />
                  <CountDownActionButton
                    onClick={()=>{sendCtaClick('Hero','Save your spot','https://us06web.zoom.us/webinar/register/WN_tQSm5a7eSCGPc4ZI2CrWSQ')}}
                    href='https://us06web.zoom.us/webinar/register/WN_tQSm5a7eSCGPc4ZI2CrWSQ' target='_blank'
                  >Save your spot</CountDownActionButton>
                </HeroCountDownSectionWrapper>
            </HeroBgWrapper>
            <DealsListWrapper>


            <DealItemBoxWrapper>
                  <DealItemLeftPanel>
                    {/* <DealBadgesWrapper>
                      <DealBadge>
                        <LockIcon />
                        CLOSED
                      </DealBadge>
                    </DealBadgesWrapper> */}
                    <DealItemLeftPanelTop>
                        <DealItemLeftPanelTop>
                            <img src={NewVestLogo} alt="New Vest" />
                            <DealItemLeftPanelTitle>PE50 2024</DealItemLeftPanelTitle>
                            <DealItemLeftPanelTitle>Private Equity Index Fund</DealItemLeftPanelTitle>
                        </DealItemLeftPanelTop>
                       
                    </DealItemLeftPanelTop>
                    <DealItemLeftPanelDetails>
                      <DealItemLeftPanelDetailsTopText><CalendarIcon/>Join Live Webinar</DealItemLeftPanelDetailsTopText>
                      <DealItemLeftPanelDetailsBottomText>January 30th, 2025 | 12:00 PM ET</DealItemLeftPanelDetailsBottomText>
                        </DealItemLeftPanelDetails>
                  </DealItemLeftPanel>
                  <DealItemRightPanel>
                    <DealItemRightPanelTop>
                    <DealStatsRowWrapper>
                      <DealStatUnitWrapper>
                        <DealStatUnitNumber>$100<span style={{fontSize:'28px'}}>K</span></DealStatUnitNumber>
                        <DealStatUnitText>Minimum Commitment</DealStatUnitText>
                      </DealStatUnitWrapper>
                      <DealStatUnitWrapper>
                        <DealStatUnitNumber>13-17%</DealStatUnitNumber>
                        <DealStatUnitText>Net IRR (Historical pooled returns)</DealStatUnitText>
                      </DealStatUnitWrapper>
                      {/* <DealStatUnitWrapper>
                        <DealStatUnitNumber>6-8%</DealStatUnitNumber>
                        <DealStatUnitText>Annual cash yield</DealStatUnitText>
                      </DealStatUnitWrapper>
                      <DealStatUnitWrapper>
                        <DealStatUnitNumber>1.95x</DealStatUnitNumber>
                        <DealStatUnitText>Target MOIC</DealStatUnitText>
                      </DealStatUnitWrapper> */}
                    </DealStatsRowWrapper>
                    <DealDetailsTextWrapper>
                    Why This Opportunity Stands Out:
                    <DealDetailsTextRowWithIcon>
                      <TextRowVIcon green={true} />Access to 50 premier PE funds through a single investment
                    </DealDetailsTextRowWithIcon>
                    <DealDetailsTextRowWithIcon>
                      <TextRowVIcon green={true} />Institutional-grade diversification across strategies, sectors, and regions
                    </DealDetailsTextRowWithIcon>
                    <DealDetailsTextRowWithIcon>
                      <TextRowVIcon green={true} />$100K minimum vs. typical $10M direct investment minimum
                    </DealDetailsTextRowWithIcon>
                    <DealDetailsTextRowWithIcon>
                      <TextRowVIcon green={true} />Index-style investing approach adapted for private equity
                    </DealDetailsTextRowWithIcon>
                    <div style={{paddingRight:'24px' , marginTop:'16px'}}>
                    Note: Investment available to <b>U.S. Qualified Purchasers</b> and <b>international investors</b> meeting their local accredited investor requirements.
                    </div>
                    </DealDetailsTextWrapper>
                    </DealItemRightPanelTop>
                    <DealActionButtonsWrapper>
                    {/* <DealActionButton
                    onClick={()=>{sendCtaClick('NewVest','Download Deck','https://drive.google.com/file/d/1ea2ca6LK4cR-Oig5PdIFpCn-WcCpjJMr/view?usp=sharing')}}
                    href="https://drive.google.com/file/d/1ea2ca6LK4cR-Oig5PdIFpCn-WcCpjJMr/view?usp=sharing" target='_blank'
                    ><DownloadIcon/>Download Deck</DealActionButton> */}
                    {/* <DealActionButton
                    onClick={()=>{sendCtaClick('MAG','Join SPV','https://form.typeform.com/to/oYyxjFZc')}}
                    href='https://form.typeform.com/to/oYyxjFZc' target='_blank'
                    >Join SPV</DealActionButton> */}
                    {/* <DealActionButton primary 
                    onClick={()=>{sendCtaClick('MAG','Watch Webinar','https://www.youtube.com/watch?v=5QxjDRz2SEc')}}
                    href='https://www.youtube.com/watch?v=5QxjDRz2SEc' target='_blank'>Watch Webinar</DealActionButton> */}
                    <DealActionButton primary 
                    onClick={()=>{sendCtaClick('NewVest','Join Webinar','https://us06web.zoom.us/webinar/register/WN_tQSm5a7eSCGPc4ZI2CrWSQ')}}
                    href='https://us06web.zoom.us/webinar/register/WN_tQSm5a7eSCGPc4ZI2CrWSQ' target='_blank'>Join Webinar</DealActionButton>
                    {/* <DealActionButton disabled>Closed</DealActionButton> */}
                  </DealActionButtonsWrapper>
                  </DealItemRightPanel>
                 
            </DealItemBoxWrapper>


            <DealItemBoxWrapper>
                  <DealItemLeftPanel>
                    <DealBadgesWrapper>
                      <DealBadge>
                        <LockIcon />
                        CLOSED
                      </DealBadge>
                    </DealBadgesWrapper>
                    <DealItemLeftPanelTop>
                        <DealItemLeftPanelTop>
                            <img src={MagCapitalLogo} alt="Mag Capital" />
                            <DealItemLeftPanelTitle>MAGCP Industrial</DealItemLeftPanelTitle>
                            <DealItemLeftPanelTitle>Fund III</DealItemLeftPanelTitle>
                        </DealItemLeftPanelTop>
                       
                    </DealItemLeftPanelTop>
                    <DealItemLeftPanelDetails>
                      <DealItemLeftPanelDetailsTopText><CalendarIcon/>Last Chance: Fund Closes</DealItemLeftPanelDetailsTopText>
                      <DealItemLeftPanelDetailsBottomText>January 15th, 2025</DealItemLeftPanelDetailsBottomText>
                        </DealItemLeftPanelDetails>
                  </DealItemLeftPanel>
                  <DealItemRightPanel>
                    <DealItemRightPanelTop>
                    <DealStatsRowWrapper>
                      <DealStatUnitWrapper>
                        <DealStatUnitNumber>$250<span style={{fontSize:'28px'}}>M</span></DealStatUnitNumber>
                        <DealStatUnitText>Target fund size</DealStatUnitText>
                      </DealStatUnitWrapper>
                      <DealStatUnitWrapper>
                        <DealStatUnitNumber>14-16%</DealStatUnitNumber>
                        <DealStatUnitText>Target net IRR</DealStatUnitText>
                      </DealStatUnitWrapper>
                      <DealStatUnitWrapper>
                        <DealStatUnitNumber>6-8%</DealStatUnitNumber>
                        <DealStatUnitText>Annual cash yield</DealStatUnitText>
                      </DealStatUnitWrapper>
                      <DealStatUnitWrapper>
                        <DealStatUnitNumber>1.95x</DealStatUnitNumber>
                        <DealStatUnitText>Target MOIC</DealStatUnitText>
                      </DealStatUnitWrapper>
                    </DealStatsRowWrapper>
                    <DealDetailsTextWrapper>
                    Why MAG Capital Stands Out in Our Analysis:
                    <DealDetailsTextRowWithIcon>
                      <TextRowVIcon green={true} />52 Active Vyzer Investors
                    </DealDetailsTextRowWithIcon>
                    <DealDetailsTextRowWithIcon>
                      <TextRowVIcon green={true} />57% Repeat Investors
                    </DealDetailsTextRowWithIcon>
                    <DealDetailsTextRowWithIcon>
                      <TextRowVIcon green={true} />7.03% Average ongoing Distributions
                    </DealDetailsTextRowWithIcon>
                    <DealDetailsTextRowWithIcon>
                      <TextRowVIcon green={true} />100% Target Performance Met
                    </DealDetailsTextRowWithIcon>
                    </DealDetailsTextWrapper>
                    </DealItemRightPanelTop>
                    <DealActionButtonsWrapper>
                    <DealActionButton
                    onClick={()=>{sendCtaClick('MAG','Download Deck','https://drive.google.com/file/d/1ea2ca6LK4cR-Oig5PdIFpCn-WcCpjJMr/view?usp=sharing')}}
                    href="https://drive.google.com/file/d/1ea2ca6LK4cR-Oig5PdIFpCn-WcCpjJMr/view?usp=sharing" target='_blank'
                    ><DownloadIcon/>Download Deck</DealActionButton>
                    {/* <DealActionButton
                    onClick={()=>{sendCtaClick('MAG','Join SPV','https://form.typeform.com/to/oYyxjFZc')}}
                    href='https://form.typeform.com/to/oYyxjFZc' target='_blank'
                    >Join SPV</DealActionButton> */}
                    <DealActionButton primary 
                    onClick={()=>{sendCtaClick('MAG','Watch Webinar','https://www.youtube.com/watch?v=5QxjDRz2SEc')}}
                    href='https://www.youtube.com/watch?v=5QxjDRz2SEc' target='_blank'>Watch Webinar</DealActionButton>
                    <DealActionButton disabled>Closed</DealActionButton>
                  </DealActionButtonsWrapper>
                  </DealItemRightPanel>
                 
            </DealItemBoxWrapper>

            
            <DealItemBoxWrapper>
                  <DealItemLeftPanel>
                    <DealBadgesWrapper>
                      <DealBadge>
                        <LockIcon />
                        CLOSED
                      </DealBadge>
                    </DealBadgesWrapper>
                    <DealItemLeftPanelTop>
                        <DealItemLeftPanelTop>
                            <img src={MlgLogo} alt="MLG Capital" style={{width:'120px'}} />
                            <DealItemLeftPanelTitle>Private Real Estate</DealItemLeftPanelTitle>
                            <DealItemLeftPanelTitle>Fund VI</DealItemLeftPanelTitle>
                        </DealItemLeftPanelTop>
                       
                    </DealItemLeftPanelTop>
                    <DealItemLeftPanelDetails>
                      <DealItemLeftPanelDetailsTopText><CalendarIcon/>Last Chance: Fund Closes</DealItemLeftPanelDetailsTopText>
                      <DealItemLeftPanelDetailsBottomText>December 31st, 2024</DealItemLeftPanelDetailsBottomText>
                        </DealItemLeftPanelDetails>
                  </DealItemLeftPanel>
                  <DealItemRightPanel>
                    <DealItemRightPanelTop>
                    <DealStatsRowWrapper>
                      <DealStatUnitWrapper>
                        <DealStatUnitNumber>$400<span style={{fontSize:'28px'}}>M</span></DealStatUnitNumber>
                        <DealStatUnitText>Target fund size</DealStatUnitText>
                      </DealStatUnitWrapper>
                      <DealStatUnitWrapper>
                        <DealStatUnitNumber>11-15%</DealStatUnitNumber>
                        <DealStatUnitText>Target net IRR</DealStatUnitText>
                      </DealStatUnitWrapper>
                      <DealStatUnitWrapper>
                        <DealStatUnitNumber>8%</DealStatUnitNumber>
                        <DealStatUnitText>Preferred Return</DealStatUnitText>
                      </DealStatUnitWrapper>
                      <DealStatUnitWrapper>
                        <DealStatUnitNumber>3-5%</DealStatUnitNumber>
                        <DealStatUnitText>Target quarterly distributions</DealStatUnitText>
                      </DealStatUnitWrapper>
                    </DealStatsRowWrapper>
                    <DealDetailsTextWrapper>
                    Why MLG Capital Stands Out in Our Analysis:
                    <DealDetailsTextRowWithIcon>
                      <TextRowVIcon green={true} />100% success in quarterly distributions
                    </DealDetailsTextRowWithIcon>
                    <DealDetailsTextRowWithIcon>
                    <TextRowVIcon green={true} />Top 5% in achieving target returns
                    </DealDetailsTextRowWithIcon>
                    <DealDetailsTextRowWithIcon>
                    <TextRowVIcon green={true} />42% of investors reinvest
                    </DealDetailsTextRowWithIcon>
                    </DealDetailsTextWrapper>
                    </DealItemRightPanelTop>
                    <DealActionButtonsWrapper>
                    <DealActionButton
                    onClick={()=>{sendCtaClick('MLG','Download Deck','https://drive.google.com/file/d/1_km2LPyLsuC4oy4Odi7TzPxVMwIKZADQ/view?usp=sharing')}}
                    href="https://drive.google.com/file/d/1_km2LPyLsuC4oy4Odi7TzPxVMwIKZADQ/view?usp=sharing" target='_blank'
                    ><DownloadIcon/>Download Deck</DealActionButton>
                    
                    {/* <DealActionButton disabled><DownloadIcon/>Download Deck</DealActionButton> */}
                    {/* <DealActionButton>Join SPV</DealActionButton> */}
                     <DealActionButton primary 
                    onClick={()=>{sendCtaClick('MLG','Watch Webinar','https://www.youtube.com/watch?v=8F1IeM2PaJ4')}}
                    href='https://www.youtube.com/watch?v=8F1IeM2PaJ4' target='_blank'>Watch Webinar</DealActionButton>
                         <DealActionButton disabled>Closed</DealActionButton>
                  </DealActionButtonsWrapper>
                  </DealItemRightPanel>
                 
            </DealItemBoxWrapper>

            
            <DealItemBoxWrapper>
                  <DealItemLeftPanel>
                    <DealBadgesWrapper>
                      <DealBadge>
                        <LockIcon />
                        CLOSED
                      </DealBadge>
                    </DealBadgesWrapper>
                    <DealItemLeftPanelTop>
                        <DealItemLeftPanelTop>
                            <img src={LongAngleLogo} alt="Long angle" style={{width:'120px', marginBottom:'8px'}} />
                            <img src={KkrLogo} alt="KKR Evergreen" style={{width:'120px'}} />
                            <DealItemLeftPanelTitle>KKR Evergreen</DealItemLeftPanelTitle>
                            <DealItemLeftPanelTitle>Private Equity Fund</DealItemLeftPanelTitle>
                        </DealItemLeftPanelTop>
                       
                    </DealItemLeftPanelTop>
                    <DealItemLeftPanelDetails>
                      <DealItemLeftPanelDetailsTopText><CalendarIcon/>Last Chance to Secure Fee Discount</DealItemLeftPanelDetailsTopText>
                      <DealItemLeftPanelDetailsBottomText>December 19th, 2024</DealItemLeftPanelDetailsBottomText>
                        </DealItemLeftPanelDetails>
                  </DealItemLeftPanel>
                  <DealItemRightPanel>
                    <DealItemRightPanelTop>
                    <DealStatsRowWrapper>
                      <DealStatUnitWrapper>
                        <DealStatUnitNumber>$50<span style={{fontSize:'28px'}}>K</span></DealStatUnitNumber>
                        <DealStatUnitText>Minimum Commitment</DealStatUnitText>
                      </DealStatUnitWrapper>
                      <DealStatUnitWrapper>
                        <DealStatUnitNumber>11-15%</DealStatUnitNumber>
                        <DealStatUnitText>Target net IRR</DealStatUnitText>
                      </DealStatUnitWrapper>
                      {/* <DealStatUnitWrapper>
                        <DealStatUnitNumber>8%</DealStatUnitNumber>
                        <DealStatUnitText>Preferred Return</DealStatUnitText>
                      </DealStatUnitWrapper>
                      <DealStatUnitWrapper>
                        <DealStatUnitNumber>3-5%</DealStatUnitNumber>
                        <DealStatUnitText>Target quarterly distributions</DealStatUnitText>
                      </DealStatUnitWrapper> */}
                    </DealStatsRowWrapper>
                    <DealDetailsTextWrapper>
                      Why This Opportunity Stands Out:
                    <DealDetailsTextRowWithIcon>
                      <TextRowVIcon green={true} />48-year track record with no principal losses across 25 funds, 22 beating S&P 500
                    </DealDetailsTextRowWithIcon>
                    <DealDetailsTextRowWithIcon>
                    <TextRowVIcon green={true} />Quarterly redemptions available after 2-year soft lock
                    </DealDetailsTextRowWithIcon>
                    <DealDetailsTextRowWithIcon>
                    <TextRowVIcon green={true} />0.25% fee discount for commitments made by December 19th, 2024
                    </DealDetailsTextRowWithIcon>
                    <DealDetailsTextRowWithIcon>
                    <TextRowVIcon green={true} />Full upfront deployment with ~50% of portfolio still at cost (due December 18th)
                    </DealDetailsTextRowWithIcon>

                    
                    </DealDetailsTextWrapper>
                    </DealItemRightPanelTop>
                    <DealActionButtonsWrapper>
                    {/* <DealActionButton
                    onClick={()=>{sendCtaClick('KKR','Investment Memo','https://longangle.journey.io/p/KKR-KPEC/investment-memo-99d06c7f')}}
                    href="https://longangle.journey.io/p/KKR-KPEC/investment-memo-99d06c7f" target='_blank'
                    >Investment Memo</DealActionButton>
                    <DealActionButton
                    onClick={()=>{sendCtaClick('KKR','Indicate Interest','https://airtable.com/appoqtBP3yRcl7R0U/shrXkttla1k2bjRVi')}}
                    href="https://airtable.com/appoqtBP3yRcl7R0U/shrXkttla1k2bjRVi" target='_blank'
                    >Indicate Interest</DealActionButton> */}
                    <DealActionButton disabled>Closed</DealActionButton>
                    {/* <DealActionButton>Join SPV</DealActionButton> */}
                     {/* <DealActionButton primary 
                    onClick={()=>{sendCtaClick('MLG','Watch Webinar','https://www.youtube.com/watch?v=8F1IeM2PaJ4')}}
                    href='https://www.youtube.com/watch?v=8F1IeM2PaJ4' target='_blank'>Watch Webinar</DealActionButton> */}
                  </DealActionButtonsWrapper>
                  </DealItemRightPanel>
                 
            </DealItemBoxWrapper>    

            </DealsListWrapper>
            {/* <CommunityOverviewHero /> */}
{/* 
            <CardContentBoxWrapper borderlessTop>
              <CommunityListHeader />

              <ItemsListWrapper withoutPadding={!(communityStore.isOverviewView && communityStore.selectedTab !== 'institutions')} >
                <CommunityItemsListViewSwitcher isIncognito={uiStore.isIncognitoMode} 
                // ab={()=>{}}
                ab={()=> setShowModal(true)}
                />
              </ItemsListWrapper>
            </CardContentBoxWrapper> */}

          </div>

        </PageWrapper>
      
      { showModal  && <DealFlowWelcomeModal onClose={() => { handleCloseWelcomeModal() }} isMobile={!uiStore.isDesktopView} />}
      
         {/* <Drawer
         closable={false}
         visible={showModal }
     width={ '100%' }
     height={'auto'}
     className={'addItemDrawerContainer_mobile'}
     placement={'bottom'}
     onClose={()=>{setShowModal(false)}}>
      <div>
        <h2>Commme on</h2>
        <h2>Commme on</h2>
        <h2>Commme on</h2>
        <h2>Commme on</h2>
        <h2>Commme on</h2>
        <h2>Commme on</h2>
      </div>
      
       </Drawer> */}
      {/* {userStore.data?.profile?.showCommunityWelcomePopup && <CommunityWelcomeModal onClose={() => { userStore.closeCommunityWelcomeModal() }} isMobile={!uiStore.isDesktopView} />} */}



    </CommunityPageWrapper>
  )}</Observer>)
}

function CreateMenu({setMenuVisibility}) {
  return (
    <MenuWrapper width={'540px'}>
      <MenuHeader>
      <MenuTitle>Excellence has a pattern. Here's ours:</MenuTitle>
      <MenuCloseButtonWrapper onClick={() => {setMenuVisibility(false)}}>
        <MenuCloseButton  />
      </MenuCloseButtonWrapper>
      
   
      </MenuHeader>
      <MenuContentWrapper>
      <MenuItemWrapper>
        <MenuItemIconWrapper>
          <MeasurableIcon />
        </MenuItemIconWrapper>
        <MenuItemContentWrapper>
        <MenuItemTitle>Measurable Consistency</MenuItemTitle>
        <MenuItemSubTitle>It’s not just about returns - it’s about repeatable results when it matters most.</MenuItemSubTitle>
        <DealDetailsTextRowWithIcon><TextRowVIcon />Consistent returns across market cycles</DealDetailsTextRowWithIcon>
        <DealDetailsTextRowWithIcon><TextRowVIcon />Proven ability to achieve or exceed projected returns</DealDetailsTextRowWithIcon>
        <DealDetailsTextRowWithIcon><TextRowVIcon />Successful exit track record</DealDetailsTextRowWithIcon>
        </MenuItemContentWrapper>
      </MenuItemWrapper>

      <MenuItemWrapper>
        <MenuItemIconWrapper>
          <OperationalIcon />
        </MenuItemIconWrapper>
        <MenuItemContentWrapper>
        <MenuItemTitle>Operational Edge</MenuItemTitle>
        <MenuItemSubTitle>Top performers don’t just find deals - they create lasting value.</MenuItemSubTitle>
        <DealDetailsTextRowWithIcon><TextRowVIcon />Superior deal sourcing</DealDetailsTextRowWithIcon>
        <DealDetailsTextRowWithIcon><TextRowVIcon />Rigorous due diligence</DealDetailsTextRowWithIcon>
        <DealDetailsTextRowWithIcon><TextRowVIcon />Effective value creation</DealDetailsTextRowWithIcon>
        </MenuItemContentWrapper>
      </MenuItemWrapper>


      <MenuItemWrapper>
        <MenuItemIconWrapper>
          <TransparentIcon />
        </MenuItemIconWrapper>
        <MenuItemContentWrapper>  
        <MenuItemTitle>Transparent Communication</MenuItemTitle>
        <MenuItemSubTitle>Because informed investors make better decisions and deserve peace of mind.</MenuItemSubTitle>
        <DealDetailsTextRowWithIcon><TextRowVIcon />Clear, timely reporting</DealDetailsTextRowWithIcon>
        <DealDetailsTextRowWithIcon><TextRowVIcon />Transparent communication</DealDetailsTextRowWithIcon>
        <DealDetailsTextRowWithIcon><TextRowVIcon />Responsive team</DealDetailsTextRowWithIcon>
        </MenuItemContentWrapper>
      </MenuItemWrapper>
      </MenuContentWrapper>

    </MenuWrapper>
  )
}

const HeroIcon = () => (
  <svg width="76" height="77" viewBox="0 0 76 77" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.3071 6.20253C22.3072 3.78803 27.1361 -0.236111 32.7699 1.37354C31.9651 4.59283 25.5265 7.81216 22.3071 6.20253Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M15.0402 13.9485C12.7818 13.0943 10.7262 7.15395 14.2249 2.45392C16.9512 4.34558 17.6846 11.5068 15.0402 13.9485Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M10.3313 23.916C7.92006 24.0407 3.65182 19.4261 4.96828 13.7166C8.22485 14.3541 11.7725 20.6178 10.3313 23.916Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M11.1143 35.5967C9.02331 36.804 3.12366 34.6345 1.70037 28.9508C4.89071 28.0379 10.8983 32.0038 11.1143 35.5967Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M17.8921 44.3185C16.7992 46.4715 10.6718 47.8741 6.3767 43.8887C8.55151 41.3824 15.75 41.426 17.8921 44.3185Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M25.94 52.3663C24.847 54.5193 18.7196 55.9219 14.4245 51.9366C16.5994 49.4302 23.7979 49.4739 25.94 52.3663Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M14.3862 15.0812C15.1154 12.8269 20.0038 10.2579 23.8032 13.1468C22.2189 15.9544 16.349 17.376 14.3862 15.0812Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M11.5497 25.7981C11.3121 23.4408 14.7573 19.1248 19.397 20.2446C19.0736 23.4522 14.2689 27.1115 11.5497 25.7981Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M11.5503 33.8438C11.3115 31.4748 14.6294 27.1519 19.1238 28.2943C18.8228 31.5153 14.1867 35.1734 11.5503 33.8438Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M16.2054 44.8031C15.5809 43.5297 17.2332 40.0868 20.7915 39.3948C21.1752 41.3126 18.4171 44.7714 16.2054 44.8031Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M25.0083 51.1782C23.3266 50.4019 21.6905 46.5804 24.1729 44.1489C26.2207 45.6052 26.9073 50.0105 25.0083 51.1782Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M31.9637 53.687C26.1157 52.6355 20.8124 49.4826 16.9854 44.7823C13.1585 40.0819 11.0528 34.1347 11.0382 27.9855C11.0236 21.8364 13.101 15.8785 16.9056 11.1587C20.7101 6.43887 25.9984 3.259 31.8413 2.17773" stroke="white" strokeWidth="1.60966" strokeLinecap="round"/>
<path d="M53.6941 6.19863C53.694 3.78413 48.8651 -0.240017 43.2313 1.36964C44.0361 4.58893 50.4748 7.80825 53.6941 6.19863Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M60.9605 13.9446C63.2189 13.0904 65.2745 7.15005 61.7759 2.45001C59.0495 4.34168 58.3161 11.5029 60.9605 13.9446Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M65.6689 23.9141C68.0802 24.0387 72.3484 19.4241 71.032 13.7147C67.7754 14.3521 64.2277 20.6159 65.6689 23.9141Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M64.8857 35.5928C66.9767 36.8001 72.8763 34.6306 74.2996 28.9468C71.1093 28.034 65.1017 31.9999 64.8857 35.5928Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M58.1089 44.3156C59.2018 46.4685 65.3292 47.8711 69.6243 43.8858C67.4495 41.3794 60.251 41.4231 58.1089 44.3156Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M50.061 52.3624C51.1539 54.5154 57.2814 55.918 61.5764 51.9327C59.4016 49.4263 52.2031 49.47 50.061 52.3624Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M61.6148 15.0773C60.8856 12.823 55.9972 10.254 52.1978 13.1429C53.7821 15.9505 59.652 17.3721 61.6148 15.0773Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M64.4506 25.7962C64.6881 23.4389 61.243 19.1229 56.6032 20.2427C56.9266 23.4502 61.7314 27.1095 64.4506 25.7962Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M64.4502 33.8409C64.689 31.4719 61.3711 27.149 56.8767 28.2914C57.1777 31.5124 61.8138 35.1705 64.4502 33.8409Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M59.7956 44.8001C60.42 43.5268 58.7677 40.0839 55.2095 39.3919C54.8257 41.3097 57.5839 44.7685 59.7956 44.8001Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M50.9927 51.1762C52.6744 50.3999 54.3104 46.5785 51.8281 44.1469C49.7803 45.6032 49.0937 50.0085 50.9927 51.1762Z" fill="white" stroke="white" strokeWidth="1.60966"/>
<path d="M44.0365 53.683C49.8845 52.6316 55.1879 49.4787 59.0148 44.7784C62.8417 40.078 64.9475 34.1308 64.9621 27.9816C64.9767 21.8325 62.8992 15.8746 59.0947 11.1548C55.2901 6.43497 50.0019 3.2551 44.1589 2.17383" stroke="white" strokeWidth="1.60966" strokeLinecap="round"/>
</svg>

)

const MeasurableIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5 3.47363C3.89543 3.47363 3 4.36906 3 5.47363V18.4736C3 19.5782 3.89543 20.4736 5 20.4736H19C20.1046 20.4736 21 19.5782 21 18.4736V5.47363C21 4.36906 20.1046 3.47363 19 3.47363H5ZM15.75 16.2C15.75 16.6418 16.1082 17 16.55 17H17.45C17.8918 17 18.25 16.6418 18.25 16.2V10.8C18.25 10.3582 17.8918 10 17.45 10H16.55C16.1082 10 15.75 10.3582 15.75 10.8V16.2ZM11.55 17C11.1082 17 10.75 16.6418 10.75 16.2L10.75 8.8C10.75 8.35817 11.1082 8 11.55 8H12.45C12.8918 8 13.25 8.35817 13.25 8.8V16.2C13.25 16.6418 12.8918 17 12.45 17H11.55ZM5.75 16.2C5.75 16.6418 6.10817 17 6.55 17H7.45C7.89183 17 8.25 16.6418 8.25 16.2L8.25 12.8C8.25 12.3582 7.89183 12 7.45 12H6.55C6.10817 12 5.75 12.3582 5.75 12.8L5.75 16.2Z" fill="white"/>
</svg>
)

const OperationalIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2276_56614)">
<path d="M12 8.00024C9.79 8.00024 8 9.79024 8 12.0002C8 14.2102 9.79 16.0002 12 16.0002C14.21 16.0002 16 14.2102 16 12.0002C16 9.79024 14.21 8.00024 12 8.00024ZM20.94 11.0002C20.48 6.83024 17.17 3.52024 13 3.06024V2.00024C13 1.45024 12.55 1.00024 12 1.00024C11.45 1.00024 11 1.45024 11 2.00024V3.06024C6.83 3.52024 3.52 6.83024 3.06 11.0002H2C1.45 11.0002 1 11.4502 1 12.0002C1 12.5502 1.45 13.0002 2 13.0002H3.06C3.52 17.1702 6.83 20.4802 11 20.9402V22.0002C11 22.5502 11.45 23.0002 12 23.0002C12.55 23.0002 13 22.5502 13 22.0002V20.9402C17.17 20.4802 20.48 17.1702 20.94 13.0002H22C22.55 13.0002 23 12.5502 23 12.0002C23 11.4502 22.55 11.0002 22 11.0002H20.94ZM12 19.0002C8.13 19.0002 5 15.8702 5 12.0002C5 8.13024 8.13 5.00024 12 5.00024C15.87 5.00024 19 8.13024 19 12.0002C19 15.8702 15.87 19.0002 12 19.0002Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_2276_56614">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

)

const TransparentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.883 3.3606C9.43772 3.3606 7.47427 5.32372 7.47427 7.7689C7.47427 10.2149 9.43763 12.1781 11.883 12.1781C14.3284 12.1781 16.2918 10.2149 16.2918 7.7689C16.2918 5.32372 14.3283 3.3606 11.883 3.3606ZM9.82226 13.7493C7.44917 13.7493 5.52539 15.6731 5.52539 18.0462C5.52539 19.2121 6.47055 20.1572 7.63647 20.1572H15.889C17.0549 20.1572 18 19.2121 18 18.0462C18 15.6731 16.0763 13.7493 13.7032 13.7493H9.82226Z" fill="white"/>
  </svg>
)

const QuestionMarkSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.4243 11.648V11.5827C10.4243 11.1362 10.6312 10.7987 11.0123 10.5264L11.4588 10.2107C12.1012 9.75333 12.5368 9.14356 12.5368 8.28333C12.5368 7.18355 11.6548 6.11644 9.967 6.11644C8.35544 6.11644 7.47344 7.24889 7.47344 8.42489C7.47344 8.58822 7.48433 8.76244 7.52789 8.91489L8.89989 8.96933C8.86722 8.87133 8.85633 8.75156 8.85633 8.62089C8.85633 8.01111 9.19389 7.39044 9.967 7.39044C10.6966 7.39044 11.0559 7.86956 11.0559 8.37044C11.0559 8.69711 10.9143 8.99111 10.5877 9.23067L10.065 9.62267C9.44433 10.0909 9.20478 10.6244 9.20478 11.2016C9.20478 11.3649 9.22656 11.5064 9.24833 11.648H10.4243ZM8.99789 13.2269C8.99789 13.706 9.379 14.0871 9.85811 14.0871C10.3372 14.0871 10.7292 13.706 10.7292 13.2269C10.7292 12.7478 10.3372 12.3558 9.85811 12.3558C9.379 12.3558 8.99789 12.7478 8.99789 13.2269Z" fill="#1A1B1D" />
      <rect x="3" y="3" width="14" height="14" rx="7" stroke="#1A1B1D" strokeWidth="1.5" />
    </svg>
  )
}

const ArrowNextWebinarIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 8L4 8M12 8L8.66667 4M12 8L8.66667 12" stroke="#DDE3EB" strokeWidth="1.5"/>
</svg>
)

const TextRowVIcon = ({green}) => (
<svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
  <path d="M12.5103 1.604L5.50585 8.6125L1.99983 5.10754" stroke={green ? "#6EBA7F" : "white"} stroke-width="3.42857"/>
</svg>
)

const CalendarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.0781 0.740234V2.79753H11.8889C11.9503 2.79753 12 2.84727 12 2.90863V13.4864C12 13.5478 11.9503 13.5975 11.8889 13.5975H0.111107C0.0497444 13.5975 0 13.5478 0 13.4864V2.90863C0 2.84727 0.0497445 2.79753 0.111107 2.79753H2.00075L2.00075 0.740234H3.60075L3.60075 2.79753H8.47812V0.740234H10.0781ZM1.52287 6.16667C1.46151 6.16667 1.41176 6.21641 1.41176 6.27777V11.9511C1.41176 12.0125 1.46151 12.0622 1.52287 12.0622H10.4771C10.5385 12.0622 10.5882 12.0125 10.5882 11.9511V6.27777C10.5882 6.21641 10.5385 6.16667 10.4771 6.16667H1.52287Z" fill="#585D66"/>
  </svg>
)

const DownloadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M2.66675 11.332V12.6654C2.66675 13.019 2.80722 13.3581 3.05727 13.6082C3.30732 13.8582 3.64646 13.9987 4.00008 13.9987H12.0001C12.3537 13.9987 12.6928 13.8582 12.9429 13.6082C13.1929 13.3581 13.3334 13.019 13.3334 12.6654V11.332" stroke="black" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.66675 7.33203L8.00008 10.6654L11.3334 7.33203" stroke="black" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8 2.66602V10.666" stroke="black" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

const LockIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.99999 1.7749C6.28791 1.7749 4.89999 3.16282 4.89999 4.8749V6.1249H3V13.6249H13V6.1249H11.1V4.8749C11.1 3.16282 9.71208 1.7749 7.99999 1.7749ZM9.89999 6.1249V4.8749C9.89999 3.82556 9.04933 2.9749 7.99999 2.9749C6.95065 2.9749 6.09999 3.82556 6.09999 4.8749V6.1249H9.89999ZM7.99999 7.3749C7.39248 7.3749 6.89999 7.86739 6.89999 8.4749C6.89999 8.90237 7.14383 9.27289 7.5 9.45496V11.1249C7.5 11.401 7.72386 11.6249 8 11.6249C8.27614 11.6249 8.5 11.401 8.5 11.1249V9.45496C8.85616 9.27288 9.09999 8.90237 9.09999 8.4749C9.09999 7.86739 8.60751 7.3749 7.99999 7.3749Z" fill="#585D66"/>
</svg>

)

const MenuCloseButton = () => (
<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.45483 20.0899L20.0614 9.4833" stroke="#9097A3" stroke-width="2" stroke-linejoin="round"/>
  <path d="M20.0606 20.09L9.45401 9.48337" stroke="#9097A3" stroke-width="2" stroke-linejoin="round"/>
</svg>
)