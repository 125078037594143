import { translations } from "../../../../../../../common/translations/translations.en"
import { displayMoneyValue } from "../../../../../../../common/utils/number-display.utils"
import { CapitalMetricsValue, CapitalMetricsWrapper } from "../OverviewTab.styles"
import { businessEquityOverviewData, businessOptionsOverviewData, businessTraditionalOverviewData, newBusinessEquityOverviewData } from "./business.overview.stats.const"
import { cryptoWalletOverviewData, syncedCryptoOverviewData } from "./crypto.overview.stats.const"
import { loansAmortizationOverviewData, loansNonAmortizationOverviewData } from "./liability.loans.amortization.overview.stats.const"
import { loansOverviewData } from "./liability.loans.overview.stats.const"
import { lifeInsuranceOverviewData, longTermSavingOverviewData } from "./longTermSavings.overview.stats.const"
import { privateLoansAmortizedOverviewData } from "./privateLoan.amortized.overview.stats.const"
import { privateLoansNonAmortizedOverviewData } from "./privateLoan.non.amortized.overview.stats.const"
import { p2pOverviewData, privateLoanOverviewData } from "./privateLoans.overview.stats.const"
import { brokerageOverviewData, singleTickerOverviewData } from "./publicMarkets.overview.stats.const"
import { developmentPartnersOverviewData, developmentSoleOverviewData, rentalPartnersOverviewData, rentalSoleOverviewData, residanceOverviewData } from "./realEstate.overview.stats.const"

const { item_overview } = translations;
const { IRR_DESCRIPTION } = translations.common.item_overview;

const cashAccountOverviewData = {
    hideConnectedLiability: true,
    value: { title: 'Current balance', lastUpdate: true },
    hideTitle: true,
    stats: [],
    visuals: []
}

const creditCardOverviewData = {
    hideConnectedLiability: true,
    value: { title: 'Current balance', lastUpdate: true },
    hideTitle: true,
    stats: [],
    visuals: []
}



const totalCommittedDataFunc = (itemData, selectedTimeFrameIndex, isHoldingView) => {
    return (<CapitalMetricsWrapper>
        <div>Total commitment <CapitalMetricsValue>{displayMoneyValue((itemData.totalCommittedCapital ?? 0), itemData.currency, false, (isHoldingView ? itemData.holdings : 100))}</CapitalMetricsValue></div>
    </CapitalMetricsWrapper>);
};

const fundsOverviewData = {
    value: { title: 'Current asset value', lastUpdate: true },
    stats: [
        {
            isArray: true, stats: [
                { title: 'ROI', subTitle: 'ROI', dataKey: 'roi', menuDescription: 'Return on Investment', description: 'Return on Investment (ROI) represents the percentage return based on asset value and distributions relative to total contributions.', type: 'perc', multipleValues: true, showOnlyActual: true },
                { title: 'DPI', subTitle: 'DPI', dataKey: 'dpi', menuDescription: 'Distributed to Paid-In Capital', description: 'Distribution to Paid-In Capital (DPI) measures the return of capital distributions to investors as a percentage of their invested capital.', type: 'perc', multipleValues: true },
            ]
        },
        { title: 'IRR', subTitle: 'Expected annual increase in value', dataKey: 'xirr', description: IRR_DESCRIPTION, type: 'perc', multipleValues: true, hasMetricBars: true, },
        {
            isArray: true,
            isDepended: true,
            getStatFn: (itemData) => {
                const stats = [];
                if (itemData.totalCommittedCapital) {
                    stats.push(
                        {
                            title: 'Uncalled capital',
                            menuDescription: 'Unpaid portion of fund commitment.',
                            dataKey: 'uncalled_capital',
                            multipleValues: true,
                            defaultOpenIndex: 0,
                            additionalDataFn: totalCommittedDataFunc
                        },
                        {
                            title: 'Called capital',
                            menuDescription: 'The total of committed capital that has been paid.',
                            dataKey: 'called_capital',
                            multipleValues: true,
                            defaultOpenIndex: 0,
                            additionalDataFn: totalCommittedDataFunc
                        }
                    );
                } else {
                    stats.push({ title: 'Total contributions', menuDescription: 'The initial invested capital and any additional investments', dataKey: 'contributions', multipleValues: true });
                }
                stats.push({
                    title: 'Invested capital', menuDescription: 'Total contributions minus return of capital', dataKey: 'invested_capital', multipleValues: true,
                    defaultOpenIndex: 1,
                    isMultipleValuesHiddenFn: (itemData, selectedTimeFrameIndex) => !(itemData.of_capital?.[selectedTimeFrameIndex]?.actual > 0),
                    multipleValuesLabels: { 'Total': 'Initial' }
                });

                return { isArray: true, stats };
            }
        },
        {
            title: item_overview.managed_funds.distributions.TITLE_LABEL, subTitle: 'Including all distributions', dataKey: 'distributions', multipleValues: true, isActualAboveTargetPositive: true,
            additionalDataFn: (itemData, selectedTimeFrameIndex, isHoldingView) => {
                return (<CapitalMetricsWrapper>
                    <div>Of capital <CapitalMetricsValue>{displayMoneyValue(itemData.of_capital && itemData.of_capital[selectedTimeFrameIndex] ? itemData.of_capital[selectedTimeFrameIndex].actual : 0, itemData.currency, false, isHoldingView ? itemData.holdings : 100)}</CapitalMetricsValue></div>
                    <div>On capital <CapitalMetricsValue>{displayMoneyValue(itemData.on_capital && itemData.on_capital[selectedTimeFrameIndex] ? itemData.on_capital[selectedTimeFrameIndex].actual : (
                        itemData.distributions && itemData.distributions[selectedTimeFrameIndex] ? itemData.distributions[selectedTimeFrameIndex].actual : 0
                    ), itemData.currency, false, isHoldingView ? itemData.holdings : 100)}</CapitalMetricsValue></div>
                </CapitalMetricsWrapper>)
            }
        }
    ],
    visuals: []
}

const otherCatOverviewData = {
    value: { title: 'Current asset worth', subTitle: 'Most updated asset value (will be reflected in your net worth)' },
    stats: [],
    visuals: [],
}


export const ASSET_CATEGORIES_OVERVIEW_DATA = {
    1: cashAccountOverviewData,
    2: cashAccountOverviewData,
    3: cashAccountOverviewData,
    4: cashAccountOverviewData,
    5: cashAccountOverviewData,
    6: longTermSavingOverviewData,
    // 7: longTermSavingOverviewData, - irrelevant category id
    // 8: longTermSavingOverviewData, - irrelevant category id
    // 9: longTermSavingOverviewData, - irrelevant category id
    10: longTermSavingOverviewData,
    11: longTermSavingOverviewData,
    40: lifeInsuranceOverviewData,
    12: cryptoWalletOverviewData,
    36: syncedCryptoOverviewData,
    13: brokerageOverviewData,
    15: rentalSoleOverviewData,
    16: rentalPartnersOverviewData,
    17: developmentSoleOverviewData,
    18: developmentPartnersOverviewData,
    21: residanceOverviewData,
    22: privateLoanOverviewData,
    23: p2pOverviewData,
    24: businessEquityOverviewData,
    25: businessOptionsOverviewData,
    31: businessTraditionalOverviewData,
    14: fundsOverviewData,
    19: fundsOverviewData,
    26: fundsOverviewData,
    27: fundsOverviewData,
    32: fundsOverviewData,
    28: otherCatOverviewData,
    29: otherCatOverviewData,
    30: otherCatOverviewData,
    33: loansOverviewData,
    34: creditCardOverviewData,
    35: newBusinessEquityOverviewData,
    37: loansAmortizationOverviewData,
    38: privateLoansAmortizedOverviewData,
    41: privateLoansNonAmortizedOverviewData,
    39: singleTickerOverviewData,
    42: loansNonAmortizationOverviewData,
}



