import { Observer } from "mobx-react";
import * as moment from "moment";
import { ModalCloseIcon } from "../../../../../../../assets/icons/common/common-icons";
import { BaseModal } from "../../../../../../../common/components/BaseModal/BaseModal"
import { ModalBody, ModalFooter } from "../../../../../../../common/components/BaseModal/BaseModal.styles"
import { CURRENCY_SYMBOL } from "../../../../../../../common/constants/currency-symbol.const";
import { getTranslations } from "../../../../../../../common/translations/translations";
import { disabledMonthBefore, disabledMoreThanToday } from "../../../../../../../common/utils/dates.utils";
import { calcMonthLeft } from "../../../../../../../common/utils/liabilities.utils";
import { FormFooterStepPoint, FormFooterStepsWrapper } from "../../../../../../add-item/components/AddItem.styles";
import { ClassIconWrapper } from "../../../../../../add-item/components/CreateItemModal.styles";
import { isNotNegative, isRequired } from "../../../../../../add-item/data/input-validations";
import { AssetClassIcon } from "../../../../../../assets/components/AssetClassIcon";
import { FormWithStore } from "../../../../../../form-displayer-connector/components/FormWithStore";
import { useFormHook } from "../../../../../../form-displayer-connector/data/useFormHook";
import { ActionButton } from "../custom-form-components/HoldingsUnlockModal.styles";
import { AmortizationUpdateModalHeader, TitleWrapper } from "./LoansAdditionalDataModals.styles";
import { useStore } from "../../../../../../app/data/root.store";

export const AmortizationLoansInfoModal = ({ item, onClose , onSave }) => {

  const { title, name } = item;
  const { userStore } = useStore();
  const formName = 'updateAmortizationForm';    
  const amortizationForm = useFormHook(formName);

  const handleBack = () => {
    amortizationForm.setStep(amortizationForm.currentSectionIndex - 1);
  }

  const handleNext = async () => {
    if (userStore.isActionBlockedByRole('editItemInfo')) {
      return;
    }
    const sectionValidations = amortizationForm.currentSectionErrors;
    // if (!sectionValidations.isValid) return amortizationForm.setErrors(sectionValidations.errors);
    if (!sectionValidations.isValid){
      amortizationForm.setErrors(sectionValidations.errors);
      amortizationForm.setBottomErrors(sectionValidations.bottomErrors);
      return false;
    } 

    if (amortizationForm.currentSectionIndex !== amortizationForm.sections.length - 1) {
      amortizationForm.setStep(amortizationForm.currentSectionIndex + 1);
    } else {
      try {
        const { startDate, principalFirstRepayment, isInterestSameDate, interestFirstPayment } = amortizationForm.values;
        const beneficiary = userStore.defaultBeneficiary;
        const payload = {
          startDate,
          beneficiary,
          principalFirstRepayment,
          isInterestSameDate,
          interestFirstPayment,
          value: parseFloat(amortizationForm.values.value),
          fixedMonthlyPayment: parseFloat(amortizationForm.values.fixedMonthlyPayment),
          interest: parseFloat(amortizationForm.values.interest / 100)
        }
        onSave && onSave(payload);
        onClose();
      } catch (e) {
        console.error(e);
      }
    }
  }

  const formSections = ({updateValue,updateField}) => {
    const { loans } = getTranslations().new_item_modal;
    const { asset_fields } = getTranslations().common;
    // const {asset_fields} = getTranslations().common;
  
    let start_date;

    return [
      {
        rows: [
          {
            fields: [
              {
                key: 'value',
                label: loans.VALUE_LABEL,
                description: 'Current loan amount, taking into account repayments already made',
                validations:[isRequired],
                type:'number',
                generatePropsFn : (values)=>{
                  return {
                    prefix: CURRENCY_SYMBOL[values?.currency] || '',
                    placeholder: CURRENCY_SYMBOL[values?.currency] || '',
                  }
                }
              }
            ]
          },
          {
            fields: [
              {
                key:'startDate', 
                type:'date',
                label: loans.START_DATE_LABEL,
                description: 'The month you received the cash', 
                validations:[isRequired],
                disabledDate: disabledMoreThanToday,
                onChange:({value})=>{
                  start_date = value;
                  updateValue('principalFirstRepayment', moment(value).add(1, 'M'));
                  updateValue('interestFirstPayment', moment(value).add(1, 'M'));
                }
              }
            ]
          },
        ]
      },
      {
        rows: [
          {fields:[
            {
              key:'interest', 
              label: loans.AMORTIZATION_INTEREST_LABEL,
              // description: ' ',
              specificDecimalScale: 3,
              validations:[isRequired, isNotNegative,
                {
                    fn: (value, formValues) => {
                        const isValid = calcMonthLeft(formValues.value, value / 100 , formValues.fixedMonthlyPayment) > 0;
                        return isValid;
                    },
                    message: `Required`,
                }],
              type:'percentage',
            }
          ]},
          {fields:[
            {
              key: 'fixedMonthlyPayment',
              validations:[ isRequired,
                {
                    fn: (value, formValues) => {
                        const isValid = calcMonthLeft(formValues.value, formValues.interest / 100 , value) > 0;
                        return isValid;
                    },
                    message: `Required`,
                    bottomErrorMessage: (value, formValues) => {
                      return `Invalid loan terms conditions.\nThe monthly interest payment (as calculated) cannot be above the fix monthly payment (of both principal + interest). Please change the ‘Annual interest rate’ or the ‘Fixed monthly payment’.`
                    },
                }
                ],
              decimalScale: 2,
              description: 'The amount you pay every month including both principal and interest',
              type:'number',
              label: loans.MONTHLY_REPAYMENT_LABEL,
              generatePropsFn : (values)=>{
                return {
                  prefix: CURRENCY_SYMBOL[values?.currency] || '',
                  placeholder: CURRENCY_SYMBOL[values?.currency] || '',
                }
              }
            }
          ]},
          {
            fields: [
              {
                key: 'principalFirstRepayment',
                type: 'date',
                label: loans.PRINCIPAL_FIRST_REPAYMENT_LABEL,
                description: 'The amount you pay every month including both principal and interest',
                validations:[isRequired],
                disabledDate: (current) => disabledMonthBefore(current, moment(start_date).add(1, 'month')),
                onChange: ({value}) => {
                  updateValue('interestFirstPayment', moment(value));
                }
              }
            ]
          },
          {
            fields: [
              {
                key: 'isInterestSameDate',
                type: 'checkbox',
                description: 'To select another date for the interest first payment, uncheck the box',
                checkboxLabel: 'This is the same date interest will be paid the first time',
                defaultValue: true,
                onChange: ({value})=>{
                  updateField('interestFirstPayment',{isHidden:value});
                }
              }
            ]
          },
          {
            fields: [
              {
                key: 'interestFirstPayment',
                type:'date',
                isHidden: true,
                label: asset_fields.INTEREST_FIRST_REPAYMENT_LABEL,
                description: asset_fields.INTEREST_FIRST_REPAYMENT_DESCRIPTION,
                clearable:true,
                disabledDate: (current) => disabledMonthBefore(current, moment(start_date).add(1, 'month')),
              }
            ]
          }
        ]
      }
    ]
  }

  return(<Observer>{() => (
    <BaseModal width={'580px'} height={'585px'} borderRadius={12}>
      <AmortizationUpdateModalHeader>
        
        <h3>Edit liability info</h3>

        <TitleWrapper>
          <ClassIconWrapper className='icon' color={"#EB7E7E"} size={38} iconSize={24}>
            <AssetClassIcon name='lia-loans' />
          </ClassIconWrapper>
          <div>
            <h4>{title}, {name}</h4>
            Loan
          </div>
        </TitleWrapper>
        <ModalCloseIcon onClick={onClose} />
      </AmortizationUpdateModalHeader>
      <ModalBody id="amortization-loan-modal">

      <FormWithStore 
        fullWidth 
        isStepsDisplay
        defaultValues={item}
        isDisabled={userStore.isActionBlockedByRole('editItemInfo')}
        formName={formName} 
        sections={formSections} 
        scrollContainerId={'amortization-loan-modal'} />

      </ModalBody>
      <ModalFooter justifyContent='space-between'>
        {amortizationForm?.currentSectionIndex === 0 && <ActionButton inline nowrap flexGrow='0' onClick={onClose}>Remind me next time</ActionButton>}
        {amortizationForm?.currentSectionIndex > 0 && <ActionButton inline nowrap flexGrow='0' onClick={handleBack}>Back</ActionButton>}
        <FormFooterStepsWrapper>
          {amortizationForm?.sections.map((section, index) => (
            <FormFooterStepPoint key={index} isSelected={index === amortizationForm.currentSectionIndex} />
          ))}
        </FormFooterStepsWrapper>
        <ActionButton disabled={userStore.isActionBlockedByRole('editItemInfo')} inline nowrap black flexGrow='0' onClick={handleNext}>Continue →</ActionButton>
      </ModalFooter>
    </BaseModal>
  )}</Observer>)
}
