import { Observer } from 'mobx-react';
import React from 'react';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
// import { CashflowIcon, InstitutionIcon, OwnershipIcon, WelcomeModalArrowIcon } from '../../icons/community-page-icons';
import { ActionButton, ModalBgWrapper, ModalContent, ModalFooter, ModalHeader, ModalHeaderIconWrapper, ModalPromptItem, ModalPromptItemDescription, ModalPromptItemTitle, ModalPromptWrapper, ModalTitle } from './DealFlowWelcomeModal.styles';
import { WelcomeModalArrowIcon } from '../../../community/icons/community-page-icons';

export const DealFlowWelcomeModal = ({ onClose, isMobile }) => {

  return (<Observer>{() => (
    <BaseModal width={'580px'} height={'auto'} borderRadius={'8'}>
      <ModalBgWrapper>
        <ModalHeader>
          <ModalHeaderIconWrapper><HeaderIcon /></ModalHeaderIconWrapper>
          <ModalTitle>Welcome to Vyzer’s Top Performers!</ModalTitle>
          {/* <ModalSubTitle>Your exclusive access to data-backed investment intelligence.</ModalSubTitle> */}
        </ModalHeader>
      </ModalBgWrapper>
      <ModalContent>
        <ModalPromptWrapper>
          <ModalPromptItem>
            <ModalPromptItemTitle>Real Data. Real Performance. Real Value.</ModalPromptItemTitle>
            <ModalPromptItemDescription>Evaluate private market deal flow with verified performance metrics from experienced sponsors, GPs, and operators.</ModalPromptItemDescription>
          </ModalPromptItem>
          <ModalPromptItem>
            <ModalPromptItemTitle>Exclusive Deal Flow</ModalPromptItemTitle>
            <ModalPromptItemDescription>Get early access to upcoming opportunities through private webinars and detailed investment materials.</ModalPromptItemDescription>
          </ModalPromptItem>
          <ModalPromptItem>
            <ModalPromptItemTitle>Why Our Insights Stand Out</ModalPromptItemTitle>
            <ModalPromptItemDescription>Vyzer tracks billions in private market transactions, offering exclusive access to performance data and insights you won’t find anywhere else.</ModalPromptItemDescription>
          </ModalPromptItem>
        </ModalPromptWrapper>
      </ModalContent>
      <ModalFooter>
        <ActionButton onClick={onClose} black >Let’s go<WelcomeModalArrowIcon /></ActionButton>
      </ModalFooter>
    </BaseModal>
  )}</Observer>)
}

const HeaderIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="76" height="77" viewBox="0 0 76 77" fill="none">
  <path d="M22.3071 6.20253C22.3072 3.78803 27.1361 -0.236111 32.7699 1.37354C31.9651 4.59283 25.5265 7.81216 22.3071 6.20253Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M15.0402 13.9485C12.7818 13.0943 10.7262 7.15395 14.2249 2.45392C16.9512 4.34558 17.6846 11.5068 15.0402 13.9485Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M10.3313 23.916C7.92006 24.0407 3.65182 19.4261 4.96828 13.7166C8.22485 14.3541 11.7725 20.6178 10.3313 23.916Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M11.1143 35.5964C9.02331 36.8038 3.12366 34.6342 1.70037 28.9505C4.89071 28.0376 10.8983 32.0036 11.1143 35.5964Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M17.8921 44.3187C16.7992 46.4717 10.6718 47.8743 6.3767 43.889C8.55151 41.3826 15.75 41.4263 17.8921 44.3187Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M25.94 52.3663C24.847 54.5193 18.7196 55.9219 14.4245 51.9366C16.5994 49.4302 23.7979 49.4739 25.94 52.3663Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M14.3862 15.0809C15.1154 12.8267 20.0038 10.2577 23.8032 13.1466C22.2189 15.9542 16.349 17.3758 14.3862 15.0809Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M11.5497 25.7979C11.3121 23.4406 14.7573 19.1246 19.397 20.2444C19.0736 23.4519 14.2689 27.1112 11.5497 25.7979Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M11.5503 33.8443C11.3115 31.4753 14.6294 27.1524 19.1238 28.2948C18.8228 31.5158 14.1867 35.1739 11.5503 33.8443Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M16.2054 44.8036C15.5809 43.5302 17.2332 40.0873 20.7915 39.3953C21.1752 41.3131 18.4171 44.7719 16.2054 44.8036Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M25.0085 51.1782C23.3269 50.4019 21.6908 46.5804 24.1731 44.1489C26.2209 45.6052 26.9075 50.0105 25.0085 51.1782Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M31.9637 53.6872C26.1157 52.6357 20.8124 49.4829 16.9854 44.7825C13.1585 40.0822 11.0528 34.135 11.0382 27.9858C11.0236 21.8366 13.101 15.8788 16.9056 11.1589C20.7101 6.43912 25.9984 3.25925 31.8413 2.17798" stroke="white" stroke-width="1.60966" stroke-linecap="round"/>
  <path d="M53.6938 6.19863C53.6938 3.78413 48.8648 -0.240017 43.231 1.36964C44.0359 4.58893 50.4745 7.80825 53.6938 6.19863Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M60.9603 13.9448C63.2186 13.0906 65.2742 7.15029 61.7756 2.45026C59.0492 4.34192 58.3159 11.5031 60.9603 13.9448Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M65.6687 23.9141C68.0799 24.0387 72.3482 19.4241 71.0317 13.7147C67.7751 14.3521 64.2275 20.6159 65.6687 23.9141Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M64.8857 35.593C66.9767 36.8004 72.8763 34.6308 74.2996 28.9471C71.1093 28.0342 65.1017 32.0002 64.8857 35.593Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M58.1089 44.3151C59.2018 46.4681 65.3292 47.8706 69.6243 43.8853C67.4495 41.379 60.251 41.4226 58.1089 44.3151Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M50.061 52.3624C51.1539 54.5154 57.2814 55.918 61.5764 51.9327C59.4016 49.4263 52.2031 49.47 50.061 52.3624Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M61.6148 15.0773C60.8856 12.823 55.9972 10.254 52.1978 13.1429C53.7821 15.9505 59.652 17.3721 61.6148 15.0773Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M64.4503 25.7962C64.6879 23.4389 61.2427 19.1229 56.603 20.2427C56.9264 23.4502 61.7311 27.1095 64.4503 25.7962Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M64.4502 33.8407C64.689 31.4717 61.3711 27.1488 56.8767 28.2911C57.1777 31.5122 61.8138 35.1703 64.4502 33.8407Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M59.7956 44.8001C60.42 43.5268 58.7677 40.0839 55.2095 39.3919C54.8257 41.3097 57.5839 44.7685 59.7956 44.8001Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M50.9924 51.1762C52.6741 50.3999 54.3102 46.5785 51.8278 44.1469C49.7801 45.6032 49.0935 50.0085 50.9924 51.1762Z" fill="white" stroke="white" stroke-width="1.60966"/>
  <path d="M44.0363 53.6835C49.8843 52.6321 55.1876 49.4792 59.0146 44.7789C62.8415 40.0785 64.9472 34.1313 64.9618 27.9821C64.9764 21.8329 62.899 15.8751 59.0944 11.1553C55.2899 6.43546 50.0016 3.25559 44.1587 2.17432" stroke="white" stroke-width="1.60966" stroke-linecap="round"/>
</svg>
)


