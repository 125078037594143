import { useState } from "react";
import { useStore } from "../../../../../../app/data/root.store";
import { DownloadIcon, ModalCloseIcon } from "../../../../../../../assets/icons/common/common-icons";
import { BaseModal } from "../../../../../../../common/components/BaseModal/BaseModal"
import { ModalBody, ModalFooter, ModalReportTitleInput } from "../../../../../../../common/components/BaseModal/BaseModal.styles";
import { ActionButton, ExportViewModalHeader, FiltersCheckbox, HeaderSubtitle, HeaderTitle, ModalHeaderTitles } from "./ExportViewModal.styled";
import { handleUrlFileDownload } from "../../../../../../../common/utils/network.utils";

export const ExportViewModal = (props) => {
  
  const { onClose } = props;
  const { filtersStore, dataLayersStore, wealthStore, userStore } = useStore();
  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
  const defaultReportTitle = () => {
    if (filtersStore.isOnlyOneClassFilter) {
      const {title} = filtersStore.singleClassFilter;
      return title;
    }
    if (filtersStore.countActiveFilters === 1) {
      const {titlePrefix, title} = filtersStore.filterTags[0];
      return titlePrefix + title;
  }
    return userStore.isViewingOwnPortfolio ? 'My Portfolio' : `${userStore.firstNameCapitalize}'s Portfolio`;
  }
  const [reportTitle, setReportTitle] = useState(`${defaultReportTitle()}`);
  const [showFilters, setShowFilters] = useState(true)
  const [inProcess, setInProcess] = useState(false);

  const listViewModeActionMapping = {
    customClasses: 'custom-class',
    customSubClasses: 'custom-sub-class',
    risk: 'riskLevel',
  }
  
  const handleViewExport = async (e) => {
    e.preventDefault();
    setInProcess(true);
    try {
      const request = {
        url: `${BASE_API_URL}reports/view`,
        body: {
          clientDate: new Date().toISOString(),
          reportTitle,
          listViewMode: wealthStore.listViewMode === 'items' ? 'items' : listViewModeActionMapping[wealthStore.allocateBy] || wealthStore.allocateBy,
          sortBy: dataLayersStore.sortBy,
          filters: filtersStore.appliedFilters,
          dataLayers: dataLayersStore.appliedDataLayers,
          itemsSortDirection: dataLayersStore.sortByColumn.direction || 'desc',
          ...filtersStore.filterTags.length ? {showFilters} : null,
        },
        fileName: `Export view - ${reportTitle}.pdf`
      }
      await handleUrlFileDownload(request);
      onClose();
    } catch (e) {
      console.error(e);
    } finally {
      setInProcess(false)
    }
  }

  return (
    <BaseModal onBackDropClick={onClose} width={'500px'} height={'auto'} borderRadius={'4'}>
        <ExportViewModalHeader>
          <ModalHeaderTitles>
            <HeaderTitle>Export current view</HeaderTitle>
            <HeaderSubtitle>You are about to export a PDF file of your current view.</HeaderSubtitle>
          </ModalHeaderTitles>
          <button onClick={onClose}><ModalCloseIcon /></button>
        </ExportViewModalHeader>
        <ModalBody padding={'18px 24px'}>
          <label htmlFor="ReportTitle">Report title</label>
          <ModalReportTitleInput id="ReportTitle" value={reportTitle} onChange={(e) => setReportTitle(e.target.value)} maxLength={30} />
          { filtersStore.filterTags.length ? <FiltersCheckbox defaultChecked={true} checked={showFilters} onChange={() => setShowFilters(!showFilters)}>Show selected filters</FiltersCheckbox> : null }
        </ModalBody>
        <ModalFooter padding={'16px 24px'}>
          <ActionButton type="primary" onClick={handleViewExport} disabled={inProcess}><DownloadIcon /> Create & download</ActionButton>
        </ModalFooter>
    </BaseModal>
  )


}