import { DatePicker, Input, Tooltip } from "antd";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { DeleteIcon, PlusIcon, TooltipIcon, WarningIcon } from '../../../../../assets/icons/common/common-icons';
import { colors } from '../../../../styles/theme.styles';
import { disabledLessThanDate } from '../../../../utils/dates.utils';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString, isNullOrUndefinedOrEmptyStringOrNan } from '../../../../utils/object.utils';
import { AmountWrapper, HoldingsBadgeText, HoldingsBadgeTriangle, HoldingsBadgeWrapper, InputWithBudgeWrapper } from '../ScheduleTable/ScheduleTable.styles';
import { ActionButtonWrapper, AmountPercentageWrapper, HeaderCell, PrevComponentButton, PrevComponentButtons, PrevComponentDescription, PrevComponentWrapper, RowActionsCell, RowCell, RowWrapper, TableBottomActions, TableBottomWrapper, TableHeaderRow, TableScrollContent, TooltipWrapper, TotalAmountErrorRow, TotalAmountErrorText, TotalAmountText, TotalAmountWrapper, Wrapper } from './SingleEventScheduler.styles';
import { SecondaryButton } from "../../../buttons/NewButtons.styles";
import { floatFixed } from "../../../../utils/number-display.utils";

const tableColumns = ['Date', 'Amount', 'Notes'];

export function SingleEventScheduler(props) {
    const { onChange, disabled, value, rowValuePreFix, relativeAmount, showPercentage, startDate, percentageTooltip, holdings, showTotals, showTotalsWarnings } = props;
    const [editedRow, setEditedRow] = useState(value.length ? value[value.length - 1].id : 0);

    const handleAddRowClick = () => {
        if (disabled) { return }
        const mostRecentDate = value.length > 0 ? value[value.length - 1].date : startDate ? moment(startDate).subtract(3, 'M') : moment().subtract(3, 'M');
        const maxValueOfTempId = Math.max(...value.map(u => u.tempId || 0), 0);
        const newRow = {
            value: '',
            date: moment(mostRecentDate).add(3, 'M').set("D", 15).startOf("D") || moment().set("D", 15).startOf("D"),
            remarks: "",
            tempId: maxValueOfTempId + 1,
        }
        setEditedRow('t' + (maxValueOfTempId + 1));
        onChange && onChange([...value, newRow]);
    }

    const handleAttributeRowUpdated = (updatedIndex, data, key) => {
        let updatedSortedSchedule = value.map((item, index) => (index === updatedIndex ? data : item));
        if (key === 'date') {
            updatedSortedSchedule = updatedSortedSchedule.sort((a, b) => new Date(a.date) - new Date(b.date));
            updatedSortedSchedule = updatedSortedSchedule.map((item, index) => {
                if (index === 0) return item;
                return { ...item, endDate: updatedSortedSchedule[index - 1].date };
            });
        }
        onChange && onChange(updatedSortedSchedule);
    }

    const handleAttributeRowDeleted = (deletedIndex) => {
        let updatedSchedule = value.filter((item, index) => index !== deletedIndex);
        updatedSchedule = updatedSchedule.map((item, index) => {
            if (index === 0) return item;
            return { ...item, endDate: updatedSchedule[index - 1].date };
        });
        onChange && onChange(updatedSchedule);
    }

    const sum = value?.reduce((sum, curr) => (sum += (isNullOrUndefinedOrEmptyStringOrNan(curr.value) ? 0 : floatFixed(curr.value * (holdings / 100), 8))), 0);
    const relativeAmountWithHoldings = floatFixed(relativeAmount * (holdings / 100));

    return (
        <Wrapper>
            <TableHeaderRow onClick={(e) => { e.stopPropagation() }} isWithHoldings={holdings !== 100}>
                {tableColumns.map((columnLabel, ind) => (
                    <HeaderCell key={'hc1' + ind} isAmount={columnLabel === 'Amount'}>
                        <span>{columnLabel}</span>
                        {(columnLabel === 'Amount' && showPercentage && relativeAmount) ? <Tooltip title={percentageTooltip} placement="topLeft">
                            <AmountPercentageWrapper><span>%</span>{percentageTooltip && <TooltipWrapper><TooltipIcon /></TooltipWrapper>}</AmountPercentageWrapper>
                        </Tooltip> : null}
                    </HeaderCell>
                ))}
            </TableHeaderRow>
            <TableScrollContent>
                {value?.map((row, index) => (
                    <EventRow key={row.id || ('t' + row.tempId)}
                        rowValuePreFix={rowValuePreFix} index={index} isLastRow={index === value.length - 1} nextEndDate={index === value.length - 1 ? 'End date' : value[index + 1].date}
                        item={row} atrKey={row.atrKey} rowUpdated={(data, key) => { handleAttributeRowUpdated(index, data, key) }}
                        isEditMode={value?.length === 1 ? true : row.id ? (editedRow === row.id) : (editedRow === 't' + row.tempId)}
                        setEditMode={() => setEditedRow(row.id ? row.id : 't' + row.tempId)}
                        relativeAmount={relativeAmount}
                        holdings={holdings}
                        disabled={disabled}
                        startDate={startDate}
                        selectedDates={value.filter(eventRow => eventRow.id ? (editedRow !== eventRow.id) : (editedRow !== 't' + eventRow.tempId)).map(eventRow => eventRow.date)}
                        showPercentage={showPercentage}
                        rowDeleted={() => handleAttributeRowDeleted(index)} />
                ))}

            </TableScrollContent>
            <TableBottomWrapper>
                {
                    (showTotals && (value?.length > 0)) &&
                    <TotalAmountWrapper>
                        <RowWrapper style={{ borderBottom: 'none', padding: 0, minHeight: 0 }}>
                            <RowCell>
                                <TotalAmountText>Total events</TotalAmountText>
                            </RowCell>
                            <RowCell>
                                <NumberFormat
                                    thousandSeparator={true}
                                    customInput={Input}
                                    prefix={rowValuePreFix}
                                    style={{ borderColor: 'transparent', backgroundColor: 'transparent', color: 'var(--Dark-Gray-2, #585D66)', fontSize: '14px', fontWeight: '700', cursor: 'default', padding: 0 }}
                                    value={sum}
                                    autoComplete="off"
                                    disabled={true}
                                    decimalScale={2}
                                />
                            </RowCell>
                        </RowWrapper>
                        {
                                (showTotalsWarnings && (sum !== relativeAmountWithHoldings)) &&
                            <TotalAmountErrorRow>
                                <WarningIcon />
                                <TotalAmountErrorText>
                                    {`The total amount of capital called ${(sum > relativeAmountWithHoldings) ? 'exceeds' : 'is less than'} the total committed capital`}
                                </TotalAmountErrorText>
                            </TotalAmountErrorRow>
                        }
                    </TotalAmountWrapper>
                }
                <TableBottomActions>
                    <SecondaryButton style={{ padding: '8px 16px' }} onClick={handleAddRowClick} disabled={disabled}>
                        <PlusIcon />
                        <span className='button-text'>Add call</span>
                    </SecondaryButton>
                    {/* <CreateButton onClick={handleAddRowClick}>
                    <PlusIcon />
                    <span className='button-text'>Add</span>
                </CreateButton> */}
                </TableBottomActions>
            </TableBottomWrapper>
        </Wrapper>
    )
}

const EventRow = (props) => {
    const { TextArea } = Input;
    const { item, disabled, rowDeleted, isEditMode, setEditMode, rowValuePreFix, relativeAmount, holdings, startDate, selectedDates } = props;
    const [amountPercentage, setAmountPercentage] = useState(relativeAmount && item.value ? parseFloat((item.value / relativeAmount * 100).toFixed(2)) : null);
    const [lastChangedPercentage, setLastChangedPercentage] = useState(amountPercentage);
    const [focusedInput, setFocusedInput] = useState(0);

    const handleFieldChange = (field, value) => {
        if (item[field] !== value) {
            props.rowUpdated({
                ...item,
                [field]: value
            }, field)
        }
    }

    const handlePercentageBlur = (updatedPercValue) => {
        if (relativeAmount) {
            if (updatedPercValue !== lastChangedPercentage) {
                const newValue = relativeAmount / 100 * updatedPercValue;
                handleFieldChange('value', newValue);
                setLastChangedPercentage(updatedPercValue);
            }
        } else {
            setAmountPercentage('');
        }
    }

    const handleDisabledDates = (current) => {
        const isEarlierThanStartDate = disabledLessThanDate(current, startDate);
        const isPreselectedDate = selectedDates.reduce((acc, sd) => acc || current.isSame(sd, 'month'), false);
        return isEarlierThanStartDate || isPreselectedDate;
    }

    useEffect(() => {
        if (relativeAmount && item.value) {
            const percValue = parseFloat((item.value / relativeAmount * 100).toFixed(2));
            setAmountPercentage(percValue);
            setLastChangedPercentage(percValue);
        } else {
            setAmountPercentage('');
            setLastChangedPercentage(0);
        }
        return () => { }
    }, [relativeAmount, item.value])

    return (
        <RowWrapper onClick={(e) => { e.stopPropagation(); setEditMode() }} isWithHoldings={holdings !== 100} >
            <RowCell>
                <DatePicker picker="month" disabled={item.eventId || disabled}
                    onFocus={() => { setEditMode() }}
                    allowClear={false}
                    format={'MMM YYYY'}
                    inputReadOnly
                    style={{ ...(isEditMode || disabled ? { borderRadius: '8px 0 0 8px', } : { borderColor: 'transparent', backgroundColor: 'transparent' }) }}
                    {...(isEditMode ? {} : { suffixIcon: null })}
                    disabledDate={handleDisabledDates}
                    value={moment(item?.date)} onChange={(val) => { handleFieldChange('date', (val ? val.format() : item?.date)) }} />
            </RowCell>
            <RowCell>
                {!isNullOrUndefined(holdings) && holdings !== 100 && <AmountWrapper>
                    <NumberFormat
                        thousandSeparator={true}
                        customInput={Input}
                        prefix={rowValuePreFix}
                        placeholder={isEditMode ? rowValuePreFix : ''}
                        style={{ flex: 1, borderRadius: '0', ...(isEditMode || disabled ? {} : { borderColor: 'transparent', backgroundColor: 'transparent' }) }}
                        value={(isNullOrUndefinedOrEmptyString(item?.value)) ? '' : parseFloat((item?.value * (holdings / 100)).toFixed(2))}
                        autoComplete="off"
                        disabled={disabled}
                        onFocus={() => { setFocusedInput(1) }}
                        onValueChange={(values) => {
                            if ('' + item?.value !== values.value && focusedInput === 1 &&
                                (!isNullOrUndefined(item?.value) || !isNullOrUndefined(values.floatValue))) {
                                handleFieldChange('value', (values.floatValue || values.floatValue === 0) ? values.floatValue * (100 / holdings) : null)
                            }
                        }}
                    />
                </AmountWrapper>}
                <InputWithBudgeWrapper isWithHoldings={holdings !== 100}>
                    <NumberFormat
                        thousandSeparator={true}
                        customInput={Input}
                        prefix={rowValuePreFix}
                        placeholder={isEditMode ? rowValuePreFix : ''}
                        style={{
                            width: '100%', borderRadius: '0', ...(isEditMode || disabled ? {} : { borderColor: 'transparent', backgroundColor: 'transparent' }),
                            ...(holdings !== 100 ? { paddingLeft: '30px', color: colors.holdingsValueColor, borderRadius: '0px' } : {})
                        }}
                        disabled={disabled}
                        value={(isNullOrUndefinedOrEmptyString(item?.value)) ? '' : parseFloat((+item?.value).toFixed(2))}
                        autoComplete="off"
                        onFocus={() => { setFocusedInput(2) }}
                        onValueChange={(values) => { handleFieldChange('value', values.floatValue) }}
                    />
                    {holdings !== 100 && <HoldingsBadgeWrapper><HoldingsBadgeText></HoldingsBadgeText><HoldingsBadgeTriangle /></HoldingsBadgeWrapper>}
                </InputWithBudgeWrapper>
                {(props.showPercentage && relativeAmount) ?
                    <NumberFormat
                        thousandSeparator={true}
                        customInput={Input}
                        suffix={'%'} decimalScale={2}
                        placeholder={isEditMode ? '%' : ''}
                        style={{ flex: 0.5, borderRadius: '0', minWidth: '84px', ...(isEditMode || disabled ? {} : { borderColor: 'transparent', backgroundColor: 'transparent' }) }}
                        value={amountPercentage}
                        disabled={disabled}
                        autoComplete="off"
                        onValueChange={(values) => { setAmountPercentage(values.floatValue) }}
                        onBlur={() => { handlePercentageBlur(amountPercentage) }}
                    /> : null
                }
            </RowCell>
            <RowCell>
                <TextArea rows={1}
                    onFocus={() => { setEditMode() }}
                    disabled={disabled}
                    style={{ resize: 'none', height: 38, ...(isEditMode || disabled ? { borderRadius: '0 8px 8px 0' } : { borderColor: 'transparent', backgroundColor: 'transparent' }) }}
                    value={item?.remarks} onChange={(e) => { handleFieldChange('remarks', e.target.value) }} />
            </RowCell>
            {!disabled && <RowActionsCell>
                <ActionButtonWrapper
                    onClick={() => { rowDeleted && rowDeleted() }} >
                    <DeleteIcon />
                </ActionButtonWrapper>
            </RowActionsCell>}
        </RowWrapper>
    )
}

export const SingleEventSchedulerPrevComponent = ({ show, disabled }) => {

    const handleYesButtonOnClick = () => {
        if (!disabled) {
            show?.()
        }
    }

    return (
        <PrevComponentWrapper>
            <PrevComponentDescription>Do you have any additional commitment?</PrevComponentDescription>
            <PrevComponentButtons>
                <PrevComponentButton disabled={disabled} isSelected >No</PrevComponentButton>
                <PrevComponentButton disabled={disabled} onClick={handleYesButtonOnClick}>Yes, I expect future capital calls</PrevComponentButton>
            </PrevComponentButtons>
        </PrevComponentWrapper>
    )
}