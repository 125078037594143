import moment from "moment";
import { StatAdditionalDataWrapper, LoanAdditionalDataBox, StatRemainingTimeWrapper } from "../OverviewTab.styles";
// import NumberFormat from 'react-number-format';
import { displayMoneyValue } from "../../../../../../../common/utils/number-display.utils";


export const loansAmortizationOverviewData = {
    value: { title: 'Balance', subTitle: 'Remaining loan amount', lastUpdate: true },
    stats: [
        { title: 'Interest rate', subTitle: 'Annual interest rate', dataKey: 'mostRecentInterest', type: 'perc', specificDecimalScale: 3 },
        {
            title: 'Remaining time', dataKey: 'remainingMonths', type: 'date',
            valueFn: (itemData) => {
                return itemData.remainingMonths ?
                    <StatRemainingTimeWrapper>
                        {itemData.remainingMonths} Month{itemData.remainingMonths > 1 ? "s" : ''}
                    </StatRemainingTimeWrapper>
                    : '--'
            },
            additionalDataFn: (itemData, selectedTimeFrameIndex ) => (
                itemData.remainingMonths ? <StatAdditionalDataWrapper>
                    <LoanAdditionalDataBox> {moment().add(itemData.remainingMonths,'M').format('MMM, yyyy')}</LoanAdditionalDataBox>
                </StatAdditionalDataWrapper> : '--'
            )
            
            // additionalDataFn: (itemData, selectedTimeFrameIndex) => { return <StatAdditionalDataWrapper><LoanAdditionalDataBox>Remaining balance {displayMoneyValue(itemData.amount_borrowed[0].actual - itemData.loanData[0].actual, itemData.currency)}</LoanAdditionalDataBox></StatAdditionalDataWrapper> }
        },
        {
            title: 'Paid amount', subTitle: '', dataKey: 'paidAmounts', multipleValues: true,
            additionalDataFn: (itemData, selectedTimeFrameIndex, isHoldingView,isIncognito) => {
                
                
                return (itemData.paidAmounts && itemData.paidAmounts.length) ? 
                <StatAdditionalDataWrapper>
                    <LoanAdditionalDataBox >Principal <span>{displayMoneyValue(itemData.paidAmounts[selectedTimeFrameIndex]?.principal, itemData.currency,isIncognito,isHoldingView ? itemData.holdings : 100)}</span></LoanAdditionalDataBox>
                    <LoanAdditionalDataBox >Interest <span>{displayMoneyValue(itemData.paidAmounts[selectedTimeFrameIndex]?.interest, itemData.currency,isIncognito,isHoldingView ? itemData.holdings : 100)}</span></LoanAdditionalDataBox>
                </StatAdditionalDataWrapper> : null
            }
        },
        { title: 'Borrowed amount', subTitle: '', dataKey: 'amount_borrowed' , multipleValues: true },
    ],
    visuals: []

}


export const loansNonAmortizationOverviewData = {
    value: { title: 'Balance', subTitle: 'Remaining loan amount', lastUpdate: true },
    stats: [
        { title: 'Interest rate', subTitle: 'Annual interest rate', dataKey: 'mostRecentInterest', type: 'perc', specificDecimalScale: 3 },
        {
            title: 'Remaining time', dataKey: 'remainingMonths', type: 'date',
            valueFn: (itemData) => {
                return itemData.remainingMonths ?
                    <StatRemainingTimeWrapper>
                        {itemData.remainingMonths} Month{itemData.remainingMonths > 1 ? "s" : ''}
                    </StatRemainingTimeWrapper>
                    : '--'
            },
            additionalDataFn: (itemData, selectedTimeFrameIndex ) => (
                itemData.remainingMonths ? <StatAdditionalDataWrapper>
                    <LoanAdditionalDataBox> {moment().add(itemData.remainingMonths,'M').format('MMM, yyyy')}</LoanAdditionalDataBox>
                </StatAdditionalDataWrapper> : '--'
            )
            
            // additionalDataFn: (itemData, selectedTimeFrameIndex) => { return <StatAdditionalDataWrapper><LoanAdditionalDataBox>Remaining balance {displayMoneyValue(itemData.amount_borrowed[0].actual - itemData.loanData[0].actual, itemData.currency)}</LoanAdditionalDataBox></StatAdditionalDataWrapper> }
        },
        {isArray: true , stats: [
            {
                title: 'Paid amount', 
                // menuDescription: 'Contributions made for the loan',
                menuDescription: (itemData) => { return itemData.loanType === 'Mortgage' ? 'Includes your periodical loan payments towards principal, interest, and escrow.' : 'Includes your periodical loan payments towards principal, and interest.' },
                subTitle: '', dataKey: 'paidAmounts', multipleValues: true,
                timeFrameSelectorWrapperMarginBottomFn: (itemData, selectedTimeFrameIndex) =>  itemData.loanType === 'Mortgage' ? 4 : null,
                additionalDataFn: (itemData, selectedTimeFrameIndex, isHoldingView,isIncognito) => {
                    
                    const hasEscrowPayment = itemData.loanType === 'Mortgage'; // itemData.paidAmounts[selectedTimeFrameIndex]?.hasOwnProperty('escrow') //In this case we have to change the UI to show escrow payment.

                    return (itemData.paidAmounts && itemData.paidAmounts.length) ? 
                    <StatAdditionalDataWrapper>
                        <LoanAdditionalDataBox fontShrink={hasEscrowPayment}>Principal <span>{displayMoneyValue(itemData.paidAmounts[selectedTimeFrameIndex]?.principal, itemData.currency,isIncognito,isHoldingView ? itemData.holdings : 100)}</span></LoanAdditionalDataBox>
                        <LoanAdditionalDataBox fontShrink={hasEscrowPayment}>Interest <span>{displayMoneyValue(itemData.paidAmounts[selectedTimeFrameIndex]?.interest, itemData.currency,isIncognito,isHoldingView ? itemData.holdings : 100)}</span></LoanAdditionalDataBox>
                        {hasEscrowPayment ? <LoanAdditionalDataBox fontShrink={hasEscrowPayment}>Escrow <span>{displayMoneyValue(itemData.paidAmounts[selectedTimeFrameIndex]?.escrow, itemData.currency,isIncognito,isHoldingView ? itemData.holdings : 100)}</span></LoanAdditionalDataBox> : null}
                    </StatAdditionalDataWrapper> : null
                }
            },
            {
                title:'Total contributions', 
                //menuDescription: (itemData) => { return itemData.loanType === 'Mortgage' ? 'Includes all payments such as service fees, origination fees, and any other costs associated with the loan.' : 'Includes your periodical loan payments towards principal, and interest.' },
                menuDescription : 'Includes all payments such as service fees, origination fees, and any other costs associated with the loan.',
                dataKey: 'contributions', 
                multipleValues:true,
            }
        ]},
        { title: 'Borrowed amount', subTitle: '', dataKey: 'amount_borrowed' , multipleValues: true },
    ],
    visuals: []

}