// import moment from "moment"

import { CURRENCY_SYMBOL } from "../../../../../../../common/constants/currency-symbol.const"
import { translations } from "../../../../../../../common/translations/translations.en"

const { IRR_DESCRIPTION } = translations.common.item_overview;

export const brokerageOverviewData = ({isConnected}) => {
    return {
        value: {title:'Current balance', lastUpdate: true},
        stats: isConnected ? [
            {title:'Performance', subTitle : 'roi' , dataKey: 'roi' , type: 'perc', multipleValues:true, showTotalOnly:true  },//hasMetricBars: true},
            {title: (item) => `Gain (${CURRENCY_SYMBOL[item.currency] || '$' })`, subTitle : '' , dataKey: 'gain', multipleValues: true},
            {title:'Total cost', subTitle : '', dataKey: 'contributions', multipleValues: true, showTotalOnly:true},
            {},
            // {title:'Total cash invested', subTitle : 'Including all contributions', dataKey: 'contributions', multipleValues: true},
            // {title:'Total cash return', subTitle : 'Including all contributions', dataKey: 'distributions' , multipleValues: true}
            ] : [
            {title:'Performance', subTitle : 'ROI' , dataKey: 'roi' , type: 'perc', multipleValues:true,  hasMetricBars: true},//hasMetricBars: true},
            {},
            {title:'Total Contribution', subTitle : 'Including all contributions' , dataKey: 'contributions', multipleValues: true},
            {title:'Total Distribution', subTitle : 'Including all contributions' , dataKey: 'distributions' , multipleValues: true},
            
        ],
        visuals : []
    }
}

export const singleTickerOverviewData = {
    value: {title:'Current Value', showPropValue : 'itemCurrencyTotalValue' , showCoreBalance: 'stock.symbol', showTickerLastUpdateDate:  true},
    stats : [
        {title:'Current PPS',  dataKey: 'stockLastPrice'  , digits:4, ignoreHoldings: true },
        {
            isArray: true , stats: [
                {title:'ROI', dataKey: 'roi' , type: 'perc', menuDescription: 'Return on Investment', description: `Return on Investment (ROI) represents the percentage return based on asset value and distributions relative to total contributions.` },
                {title:'IRR', dataKey: 'xirr', type: 'perc', menuDescription: 'Internal Rate of Return', description: IRR_DESCRIPTION, multipleValues:true},
            ]
        },
        {
            isArray: true , stats: [
                {title: (item) =>  `Total cost (${CURRENCY_SYMBOL[item.currency] || '$' })`, menuDescription: 'The price you paid for these shares', dataKey: 'cost_basis'  },
                {title:'Cost per share', menuDescription: "The average PPS of the stocks you currently own", dataKey: 'cost_per_share' , ignoreHoldings: true },
            ]
        },
        {title: (item) =>  `Unrealized gain/loss (${CURRENCY_SYMBOL[item.currency] || '$' })`, dataKey: 'gain'},
    ],
    visuals : []
}