import React from 'react';
import { Player } from "@lottiefiles/react-lottie-player";
// import './LogoLoader.css';
import loaderFile from './app-loader-lottie.json';

const Loader = ({logoMargin = 0, color}) => {
	return (<Player
    // ref={lottieRef}
    autoplay={true}
    loop={true}
    keepLastFrame={false}
    src={loaderFile}
    speed={1}
    style={{
      width: '72px', 
      margin: logoMargin,
      filter: color ? `hue-rotate(${color}deg)` : 'none'
    }}
    ></Player>)
}

export function AppLoader({ 
  logoWidth = 100, 
  wbg, 
  backdrop = false, 
  absolute = false, 
  mobileFixed = false, 
  logoMargin = 0,
  logoColor,
  backgroundColor
}) {
	return (
		<>
			{backdrop === true ? (
				<div 
					className={`loader-back-drop${absolute ? ' absolute-pos' : ''}${mobileFixed ? ' mobileFixed-pos' : ''}`}
					style={{ backgroundColor }}
				>
					<Loader logoMargin={logoMargin} color={logoColor} />
				</div>
			) : (
				<Loader logoMargin={logoMargin} color={logoColor} />
			)}
		</>
	)

}



