import { translations } from "../../../../../../../common/translations/translations.en";
import { displayMoneyValue } from "../../../../../../../common/utils/number-display.utils";
import { LoanAdditionalDataBox, StatAdditionalDataWrapper } from "../OverviewTab.styles";

const { IRR_DESCRIPTION } = translations.common.item_overview;

export const privateLoansAmortizedOverviewData = {
    value: { title: 'Balance', subTitle: 'Remaining loan amount', lastUpdate: true },
    stats: [
        { title: 'Annual interest rate', subTitle: 'Annual interest rate', dataKey: 'mostRecentInterest', type: 'perc', specificDecimalScale: 3 },
        
        {title:'IRR', dataKey: 'xirr', type: 'perc', description: IRR_DESCRIPTION, multipleValues:true, hasMetricBars: true},
        // {
        //     title: 'Remaining time', dataKey: 'remainingMonths', type: 'date',
        //     valueFn: (itemData) => {
        //         return itemData.remainingMonths ?
        //             <StatRemainingTimeWrapper>
        //                 {itemData.remainingMonths} Month{itemData.remainingMonths > 1 ? "s" : ''}
        //             </StatRemainingTimeWrapper>
        //             : '--'
        //     },
        //     additionalDataFn: (itemData, selectedTimeFrameIndex) => (
        //         itemData.remainingMonths ? <StatAdditionalDataWrapper>
        //             <LoanAdditionalDataBox> {moment().add(itemData.remainingMonths,'M').format('MMM, yyyy')}</LoanAdditionalDataBox>
        //         </StatAdditionalDataWrapper> : '--'
        //     )
            
        //     // additionalDataFn: (itemData, selectedTimeFrameIndex) => { return <StatAdditionalDataWrapper><LoanAdditionalDataBox>Remaining balance {displayMoneyValue(itemData.amount_borrowed[0].actual - itemData.loanData[0].actual, itemData.currency)}</LoanAdditionalDataBox></StatAdditionalDataWrapper> }
        // },
        
        {isArray: true , stats: [
            {title: 'Loan payments', menuDescription: 'Interest payments and principal repayments', dataKey:'paidAmounts',  multipleValues: true,
            additionalDataFn: (itemData, selectedTimeFrameIndex, isHoldingView,isIncognito) => {
                return (itemData.paidAmounts && itemData.paidAmounts.length) ? <StatAdditionalDataWrapper>
                    <LoanAdditionalDataBox>Interest <span>{displayMoneyValue(itemData.paidAmounts[selectedTimeFrameIndex].interest, itemData.currency,isIncognito,isHoldingView ? itemData.holdings : 100)}</span></LoanAdditionalDataBox>
                    <LoanAdditionalDataBox>Principal <span>{displayMoneyValue(itemData.paidAmounts[selectedTimeFrameIndex].principal, itemData.currency,isIncognito,isHoldingView ? itemData.holdings : 100)}</span></LoanAdditionalDataBox>
                </StatAdditionalDataWrapper> : null
            }   },
            {
                title:'Total inflow', menuDescription : 'Include any inflow (late fees etc.) as well as interest and principal repayments.' ,dataKey: 'distributions', multipleValues:true,
                // defaultOpenIndex: 1,
                // isMultipleValuesHiddenFn: (itemData, selectedTimeFrameIndex) => !(itemData.of_capital?.[selectedTimeFrameIndex]?.actual > 0) ,
                // multipleValuesLabels:{'Total' : 'Initial'}
            }
        ]},
        {isArray: true , stats: [
            {
                title: 'Lent amount', 
                menuDescription: 'Contributions made for the loan', 
                dataKey:'amount_lent',
                multipleValues: true
            },
            {
                title:'Total contributions', 
                menuDescription : 'Include any outflow (Service fee / Origination fee etc.) as well as the lent amount',
                dataKey: 'contributions', 
                multipleValues:true,
                // defaultOpenIndex: 1,
                // isMultipleValuesHiddenFn: (itemData, selectedTimeFrameIndex) => !(itemData.of_capital?.[selectedTimeFrameIndex]?.actual > 0) ,
                // multipleValuesLabels:{'Total' : 'Initial'}
            }
        ]},
        // {
        //     title: 'Paid amount', subTitle: '', dataKey: 'paidAmounts', multipleValues: true, 
        //     additionalDataFn: (itemData, selectedTimeFrameIndex) => {
        //         return <StatAdditionalDataWrapper>
        //             <LoanAdditionalDataBox>Interest <span>{displayMoneyValue(itemData.paidAmounts[selectedTimeFrameIndex].interest, itemData.currency)}</span></LoanAdditionalDataBox>
        //             <LoanAdditionalDataBox>Principal <span>{displayMoneyValue(itemData.paidAmounts[selectedTimeFrameIndex].principal, itemData.currency)}</span></LoanAdditionalDataBox>
        //         </StatAdditionalDataWrapper>
        //     }
        // },
        // { title: 'Borrowed amount', subTitle: '', dataKey: 'amount_borrowed' , multipleValues: true },
    ],
    visuals: []

}