import moment from "moment";
import { SingleEventSchedulerPrevComponent } from "../../../../../common/components/form-displayer/input-types/SingleEventsScheduler/SingleEventScheduler";
import { CURRENCY_SYMBOL } from "../../../../../common/constants/currency-symbol.const";
import { getTranslations } from "../../../../../common/translations/translations";
import { disabledMoreThanToday, monthDiff } from "../../../../../common/utils/dates.utils";
import { isNullOrUndefinedOrEmptyString, isNullOrUndefinedOrEmptyStringOrNan, isNullOrUndefinedOrZeroOrEmptyString } from "../../../../../common/utils/object.utils";
import { HoldingsUnlockModal } from "../../../../wealth/pages/asset/components/Info/custom-form-components/HoldingsUnlockModal";
import { masterInstitutionInputRows } from "../../common-fields/master-institution.const";
import { isBetweenZeroAndHundred, isRequired } from "../../input-validations";
import { containerRow } from "../../common-fields/container-field.const";
import { exitEventFieldRow } from "../../common-fields/exit-event-field.const";
import { floatFixed } from "../../../../../common/utils/number-display.utils";
import { riskLevelRow } from "../../common-fields/risk-level-field.const";
import { liquidityRow } from "../../common-fields/liquidity-field.const";
import { customClassAndSubClassRows } from "../../common-fields/custom-class-and-sub-class-field.const";
import { beneficiaryRow } from "../../common-fields/beneficiary-field.const";

export const createHedgeFundAddItemSections = ( isEditForm ) => {
    const { hedge_fund } = getTranslations().new_item_modal.managed_funds;
    const {NAME_LABEL,NAME_DESCRIPTION,VALUE_LABEL,VALUE_DESCRIPTION,START_DATE_LABEL,START_DATE_DESCRIPTION,
        EXPECTED_END_DATE_LABEL, EXPECTED_END_DATE_DESCRIPTION, EXPECTED_IRR_LABEL, EXPECTED_IRR_DESCRIPTION, EXPECTED_MULTIPLIER_DESCRIPTION,
        EXPECTED_ANNUAL_DIVIDEND_LABEL , EXPECTED_ANNUAL_DIVIDEND_DESCRIPTION , EXPECTED_DISTRIBUTION_PERIOD_LABEL, EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION,
        DEFAULT_CASH_ACCOUNT_DESCRIPTION, BENEFICIARY_LABEL, BENEFICIARY_DESCRIPTION,EXPECTED_ANNUAL_REVENUE_LABEL, EXPECTED_ANNUAL_REVENUE_DESCRIPTION} = hedge_fund;
    // const {CASH_INVESTED_LABEL} = getTranslations().common.asset_fields;
    return ({updateValue,updateField, updateSection}) => ([
        generalInfoSection(updateValue,updateField,isEditForm,NAME_LABEL,NAME_DESCRIPTION,VALUE_LABEL,VALUE_DESCRIPTION,START_DATE_LABEL,START_DATE_DESCRIPTION),
        capitalCallsSection(updateValue,updateField,isEditForm),
        projectionSection(updateValue,updateField,updateSection,isEditForm,EXPECTED_END_DATE_LABEL, EXPECTED_END_DATE_DESCRIPTION, EXPECTED_IRR_LABEL, EXPECTED_IRR_DESCRIPTION, EXPECTED_MULTIPLIER_DESCRIPTION, EXPECTED_ANNUAL_DIVIDEND_LABEL,EXPECTED_ANNUAL_DIVIDEND_DESCRIPTION,EXPECTED_DISTRIBUTION_PERIOD_LABEL, EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION),
        distributionScheduleSection(updateValue,updateField,isEditForm,EXPECTED_ANNUAL_REVENUE_LABEL, EXPECTED_ANNUAL_REVENUE_DESCRIPTION, EXPECTED_DISTRIBUTION_PERIOD_LABEL, EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION),
        distributionScheduleTableSection(updateValue,updateField,isEditForm,EXPECTED_ANNUAL_REVENUE_LABEL, EXPECTED_ANNUAL_REVENUE_DESCRIPTION),
        exitEventRowSection(updateValue,updateField,isEditForm),
        moreInfoSection(updateField,isEditForm,DEFAULT_CASH_ACCOUNT_DESCRIPTION, BENEFICIARY_LABEL, BENEFICIARY_DESCRIPTION)
    ])
}

export const createRealEstateFundAddItemSections = ( isEditForm ) => {
    const { real_estate_fund } = getTranslations().new_item_modal.managed_funds;
    const {NAME_LABEL,NAME_DESCRIPTION,VALUE_LABEL,VALUE_DESCRIPTION,START_DATE_LABEL,START_DATE_DESCRIPTION,
        EXPECTED_END_DATE_LABEL, EXPECTED_END_DATE_DESCRIPTION, EXPECTED_IRR_LABEL, EXPECTED_IRR_DESCRIPTION, EXPECTED_MULTIPLIER_DESCRIPTION,
        EXPECTED_ANNUAL_DIVIDEND_LABEL , EXPECTED_ANNUAL_DIVIDEND_DESCRIPTION , EXPECTED_DISTRIBUTION_PERIOD_LABEL, EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION,
        DEFAULT_CASH_ACCOUNT_DESCRIPTION, BENEFICIARY_LABEL, BENEFICIARY_DESCRIPTION, EXPECTED_ANNUAL_REVENUE_LABEL, EXPECTED_ANNUAL_REVENUE_DESCRIPTION} = real_estate_fund;
    return ({updateValue,updateField, updateSection}) => ([
        generalInfoSection(updateValue,updateField,isEditForm,NAME_LABEL,NAME_DESCRIPTION,VALUE_LABEL,VALUE_DESCRIPTION,START_DATE_LABEL,START_DATE_DESCRIPTION),
        capitalCallsSection(updateValue,updateField,isEditForm),
        projectionSection(updateValue,updateField,updateSection, isEditForm, EXPECTED_END_DATE_LABEL, EXPECTED_END_DATE_DESCRIPTION, EXPECTED_IRR_LABEL, EXPECTED_IRR_DESCRIPTION, EXPECTED_MULTIPLIER_DESCRIPTION, EXPECTED_ANNUAL_DIVIDEND_LABEL,EXPECTED_ANNUAL_DIVIDEND_DESCRIPTION,EXPECTED_DISTRIBUTION_PERIOD_LABEL, EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION),
        distributionScheduleSection(updateValue,updateField, isEditForm,  EXPECTED_ANNUAL_REVENUE_LABEL, EXPECTED_ANNUAL_REVENUE_DESCRIPTION, EXPECTED_DISTRIBUTION_PERIOD_LABEL, EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION),
        distributionScheduleTableSection(updateValue,updateField,isEditForm, EXPECTED_ANNUAL_REVENUE_LABEL, EXPECTED_ANNUAL_REVENUE_DESCRIPTION),
        exitEventRowSection(updateValue,updateField,isEditForm),
        moreInfoSection(updateField,isEditForm,DEFAULT_CASH_ACCOUNT_DESCRIPTION, BENEFICIARY_LABEL, BENEFICIARY_DESCRIPTION),
    ])
}

export const createPrivateEquityAddItemSections = ( isEditForm ) => {
    const { private_equity } = getTranslations().new_item_modal.managed_funds;
    const {NAME_LABEL,NAME_DESCRIPTION,VALUE_LABEL,VALUE_DESCRIPTION,START_DATE_LABEL,START_DATE_DESCRIPTION,
        EXPECTED_END_DATE_LABEL, EXPECTED_END_DATE_DESCRIPTION, EXPECTED_IRR_LABEL, EXPECTED_IRR_DESCRIPTION, EXPECTED_MULTIPLIER_DESCRIPTION,
        EXPECTED_ANNUAL_DIVIDEND_LABEL , EXPECTED_ANNUAL_DIVIDEND_DESCRIPTION , EXPECTED_DISTRIBUTION_PERIOD_LABEL, EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION,
        DEFAULT_CASH_ACCOUNT_DESCRIPTION, BENEFICIARY_LABEL, BENEFICIARY_DESCRIPTION, EXPECTED_ANNUAL_REVENUE_LABEL, EXPECTED_ANNUAL_REVENUE_DESCRIPTION} = private_equity;
    return ({updateValue,updateField,updateSection}) => ([
        generalInfoSection(updateValue,updateField,isEditForm,NAME_LABEL,NAME_DESCRIPTION,VALUE_LABEL,VALUE_DESCRIPTION,START_DATE_LABEL,START_DATE_DESCRIPTION),
        capitalCallsSection(updateValue,updateField,isEditForm),
        projectionSection(updateValue,updateField,updateSection,isEditForm,EXPECTED_END_DATE_LABEL, EXPECTED_END_DATE_DESCRIPTION, EXPECTED_IRR_LABEL, EXPECTED_IRR_DESCRIPTION, EXPECTED_MULTIPLIER_DESCRIPTION, EXPECTED_ANNUAL_DIVIDEND_LABEL,EXPECTED_ANNUAL_DIVIDEND_DESCRIPTION,EXPECTED_DISTRIBUTION_PERIOD_LABEL, EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION),
        distributionScheduleSection(updateValue,updateField,isEditForm,EXPECTED_ANNUAL_REVENUE_LABEL, EXPECTED_ANNUAL_REVENUE_DESCRIPTION, EXPECTED_DISTRIBUTION_PERIOD_LABEL, EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION),
        distributionScheduleTableSection(updateValue,updateField,isEditForm,EXPECTED_ANNUAL_REVENUE_LABEL, EXPECTED_ANNUAL_REVENUE_DESCRIPTION),
        exitEventRowSection(updateValue,updateField,isEditForm),
        moreInfoSection(updateField,isEditForm,DEFAULT_CASH_ACCOUNT_DESCRIPTION, BENEFICIARY_LABEL, BENEFICIARY_DESCRIPTION),
    ])
}

export const createVentureCapitalAddItemSections = ( isEditForm ) => {
    const { venture_capital } = getTranslations().new_item_modal.managed_funds;
    const {NAME_LABEL,NAME_DESCRIPTION,VALUE_LABEL,VALUE_DESCRIPTION,START_DATE_LABEL,START_DATE_DESCRIPTION,
        EXPECTED_END_DATE_LABEL, EXPECTED_END_DATE_DESCRIPTION, EXPECTED_IRR_LABEL, EXPECTED_IRR_DESCRIPTION, EXPECTED_MULTIPLIER_DESCRIPTION,
        EXPECTED_ANNUAL_DIVIDEND_LABEL , EXPECTED_ANNUAL_DIVIDEND_DESCRIPTION , EXPECTED_DISTRIBUTION_PERIOD_LABEL, EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION,
        DEFAULT_CASH_ACCOUNT_DESCRIPTION, BENEFICIARY_LABEL, BENEFICIARY_DESCRIPTION, EXPECTED_ANNUAL_REVENUE_LABEL, EXPECTED_ANNUAL_REVENUE_DESCRIPTION} = venture_capital;
    
    return ({updateValue,updateField,updateSection}) => ([
        generalInfoSection(updateValue,updateField,isEditForm,NAME_LABEL,NAME_DESCRIPTION,VALUE_LABEL,VALUE_DESCRIPTION,START_DATE_LABEL,START_DATE_DESCRIPTION),
        capitalCallsSection(updateValue,updateField,isEditForm),
        projectionSection(updateValue,updateField,updateSection,isEditForm,EXPECTED_END_DATE_LABEL, EXPECTED_END_DATE_DESCRIPTION, EXPECTED_IRR_LABEL, EXPECTED_IRR_DESCRIPTION, EXPECTED_MULTIPLIER_DESCRIPTION, EXPECTED_ANNUAL_DIVIDEND_LABEL,EXPECTED_ANNUAL_DIVIDEND_DESCRIPTION,EXPECTED_DISTRIBUTION_PERIOD_LABEL, EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION),
        distributionScheduleSection(updateValue,updateField,isEditForm,EXPECTED_ANNUAL_REVENUE_LABEL, EXPECTED_ANNUAL_REVENUE_DESCRIPTION, EXPECTED_DISTRIBUTION_PERIOD_LABEL, EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION),
        distributionScheduleTableSection(updateValue,updateField,isEditForm,EXPECTED_ANNUAL_REVENUE_LABEL, EXPECTED_ANNUAL_REVENUE_DESCRIPTION),
        exitEventRowSection(updateValue,updateField,isEditForm),
        moreInfoSection(updateField,isEditForm,DEFAULT_CASH_ACCOUNT_DESCRIPTION, BENEFICIARY_LABEL, BENEFICIARY_DESCRIPTION),
    ])
}

export const createDebtFundAddItemSections = ( isEditForm ) => {
    const { debt_fund } = getTranslations().new_item_modal.managed_funds;
    const {NAME_LABEL,NAME_DESCRIPTION,VALUE_LABEL,VALUE_DESCRIPTION,START_DATE_LABEL,START_DATE_DESCRIPTION,
        EXPECTED_END_DATE_LABEL, EXPECTED_END_DATE_DESCRIPTION, EXPECTED_IRR_LABEL, EXPECTED_IRR_DESCRIPTION, EXPECTED_MULTIPLIER_DESCRIPTION,
        EXPECTED_ANNUAL_DIVIDEND_LABEL , EXPECTED_ANNUAL_DIVIDEND_DESCRIPTION , EXPECTED_DISTRIBUTION_PERIOD_LABEL, EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION,
        DEFAULT_CASH_ACCOUNT_DESCRIPTION, BENEFICIARY_LABEL, BENEFICIARY_DESCRIPTION, EXPECTED_ANNUAL_REVENUE_LABEL, EXPECTED_ANNUAL_REVENUE_DESCRIPTION} = debt_fund;
    return ({updateValue,updateField,updateSection}) => ([
        generalInfoSection(updateValue,updateField,isEditForm,NAME_LABEL,NAME_DESCRIPTION,VALUE_LABEL,VALUE_DESCRIPTION,START_DATE_LABEL,START_DATE_DESCRIPTION),
        capitalCallsSection(updateValue,updateField,isEditForm),
        projectionSection(updateValue,updateField,updateSection,isEditForm,EXPECTED_END_DATE_LABEL, EXPECTED_END_DATE_DESCRIPTION, EXPECTED_IRR_LABEL, EXPECTED_IRR_DESCRIPTION, EXPECTED_MULTIPLIER_DESCRIPTION, EXPECTED_ANNUAL_DIVIDEND_LABEL,EXPECTED_ANNUAL_DIVIDEND_DESCRIPTION,EXPECTED_DISTRIBUTION_PERIOD_LABEL, EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION),
        distributionScheduleSection(updateValue,updateField,isEditForm,EXPECTED_ANNUAL_REVENUE_LABEL, EXPECTED_ANNUAL_REVENUE_DESCRIPTION, EXPECTED_DISTRIBUTION_PERIOD_LABEL, EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION),
        distributionScheduleTableSection(updateValue,updateField,isEditForm,EXPECTED_ANNUAL_REVENUE_LABEL, EXPECTED_ANNUAL_REVENUE_DESCRIPTION),
        exitEventRowSection(updateValue,updateField,isEditForm),
        moreInfoSection(updateField,isEditForm,DEFAULT_CASH_ACCOUNT_DESCRIPTION, BENEFICIARY_LABEL, BENEFICIARY_DESCRIPTION),
    ])
}

const generalInfoSection = (updateValue,updateField,isEditForm,firmLabel,firmDescription,valueLabel,valueDescription,startDateLabel,startDateDescription)  => {
    return {
        title:'General info',
        rows:[
            ...masterInstitutionInputRows(firmLabel,firmDescription, 8, false, updateValue , !isEditForm),
            {
                fields:[
                    {key:'title', validations:[isRequired]}
                ]
            },
            {
                fields:[
                    {key:'startDate', label: startDateLabel,
                    description: startDateDescription, 
                    validations:[isRequired],
                    // defaultValueFn: ()=>{return new Date()},
                    disabledDate: disabledMoreThanToday,
                    onChange:({value,formValues})=> {
                        updateField('capitalCalls', { startDate: moment(value) });
                        updateField('expectedAnnualRevenue', { startDate: moment(value).add(1, 'month') });
                        updateValue('expectedEndDate', moment(value).add(5, 'year'));
                        updateValue('expectedDistributionStartDate', moment(value).add(1, 'month'));

                        if (!formValues.isExpectedAnnualRevenueTouched){
                            // const numberOfYears = formValues.expectedEndDate ? moment(formValues.expectedEndDate).diff(moment(value),"y") : 5;
                            const years = [...Array(6).keys()];
                            const valueForYears = formValues.expectedAnnualCashReturn ? (
                                formValues.distributionReinvested ? formValues.expectedAnnualCashReturn : (formValues.cashInvested / 100 * formValues.expectedAnnualCashReturn )
                            ) : '';
                            
                            const defaultScheduleValues = years.map(year=>({
                                value: year === 5 ? 0 : valueForYears,
                                date: moment(value).add(1,"M").add(year,'y').utc().set("D",15).startOf("D"),
                                remarks: year === 5  ? '' : "Year " + (+year+1),
                                tempId: +year+1
                            }));
                            updateValue('expectedAnnualRevenue', defaultScheduleValues);
                        }
                    }}
                ]
            },  
            {
                fields:[
                    {key:'value', type: 'numberWithHoldings', label:valueLabel, validations:[isRequired],
                    description: valueDescription,
                    labelSubtext: ()=> <span>A <strong>capital call</strong> schedule can be added later</span>,
                    onBlur:({value, formValues})=>{
                        const currencySymbol = CURRENCY_SYMBOL[formValues.currency] || 'USD';
                        updateValue('cashInvested',value);
                        updateField('expectedAnnualCashReturn', {amount: value, 
                            description: `The total amount you expect to receive on this investment annually. The percentage rate is based on your asset's initial investment ${currencySymbol}${value}`})
                        updateField('expectedAnnualRevenue',{relativeAmount: value});
                    },generatePropsFn : (values)=>{
                        return {prefix: CURRENCY_SYMBOL[values.currency] || ''}
                    }},
                    {key:'currency',size:0.3,onChange:({value, formValues})=> {
                        const currencySymbol = CURRENCY_SYMBOL[formValues.currency] || 'USD';
                        updateValue('cashInvestedCurrency', value);
                        updateValue('cashReceivedCurrency', value);
                        updateField('value', { prefix: currencySymbol });
                        updateField('cashInvested', { prefix: currencySymbol });
                        updateField('cashReceived', { prefix: currencySymbol });
                        updateField('expectedAnnualRevenue', { prefix: currencySymbol });
                        updateField('capitalCalls', { prefix: currencySymbol });
                        updateField('totalCommittedCapital', { prefix: currencySymbol });
                        updateField('expectedAnnualCashReturn', { prefix: currencySymbol, 
                            placeholder: currencySymbol, 
                            description: `The total amount you expect to receive on this investment annually. The percentage rate is based on your asset's initial investment ${currencySymbol}${formValues.cashInvested}`});
                    }
                    },
                    {
                        key:'cashInvested', 
                        isHidden:true,
                        defaultValueFn:(values)=>values.value,
                    },
                ]
            },
            ...( isEditForm ? [
                ...customClassAndSubClassRows({updateValue,updateField}),
            ] : [
                {
                    fields:[
                        {key:'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isHidden:true,
                        validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                        validations:[isBetweenZeroAndHundred],
                            onBlur:({value})=>{
                                updateField('value',{holdings:value || ''});
                                updateField('capitalCalls',{holdings:value || ''});
                                updateField('expectedAnnualRevenue',{holdings:value || ''});
                                updateField('totalCommittedCapital',{holdings:value || ''});
                            },
                            generatePropsFn : (values)=>{
                                return {isHidden: values.holdings === 100}
                            }
                        }
                    ]
                },
                {
                    fields:[
                        {type:'checkbox', key:'isPartialHolding',checkboxLabel: 'This asset is 100% owned by me', description: 'If you are a co-owner of this account, uncheck this box.' ,
                        fieldCustomStyle: {marginTop:'-24px'},
                        defaultValue: true,
                        onChange: ({value})=> {
                            const newHoldingValue = value ? 100 : 50;
                            updateValue('holdings', newHoldingValue);
                            updateField('holdings', { isHidden: value });
                            updateField('value', { holdings: newHoldingValue });
                            updateField('capitalCalls', { holdings: newHoldingValue });
                            updateField('totalCommittedCapital', { holdings: newHoldingValue });
                            updateField('expectedAnnualRevenue',{holdings:newHoldingValue});
                        }},
                    ]
                },
                ])
        ]
    }
}


const projectionSection = (
    updateValue,
    updateField,
    updateSection,
    isEditForm,
    endDateLabel,endDateDescription,
    expectedIrrLabel,expectedIrrDescription,expectedMultiplierDescription,
    ) => {
    return {
        title: 'Projection', 
        rows:[
            {fields:[
                {key:'expectedEndDate', label: endDateLabel, description: endDateDescription,
                    onChange:({value,formValues})=> {
                        if (!formValues.isExpectedAnnualRevenueTouched && value){
                            const yearsFromStartToEndDate = moment(value).diff(moment(formValues.startDate),"y") + ( moment(value).month() === moment(formValues.startDate).month() ? 0 : 1 );
                            const years = [...Array(yearsFromStartToEndDate+1).keys()];
                            const valueForYears = formValues.expectedAnnualCashReturn ? (
                                formValues.distributionReinvested ? formValues.expectedAnnualCashReturn : (formValues.cashInvested / 100 * formValues.expectedAnnualCashReturn )
                            ) : '';

                            const scheduleValues = years.map(year=>({
                                value: year === yearsFromStartToEndDate ? 0 : valueForYears,
                                date: year === yearsFromStartToEndDate ? moment(value).utc().add(1,"M").set("D",15).startOf("D") : moment(formValues.startDate).utc().add(1,"M").add(year,'y').set("D",15).startOf("D"),
                                remarks: year === yearsFromStartToEndDate ? '' : "Year " + (+year+1),
                                tempId: +year+1
                            }));
                            updateValue('expectedAnnualRevenue', scheduleValues);
                        }
                    },
                    isHidden: isEditForm,
                    setFnForSave: (isDistribution, value, formValues) => {
                        return isEditForm ? ( formValues?.exitEvent?.date || null ) : value;
                    },
                },{ isHidden:true, key:'cashflowEventsAfterExit' ,  setFnForSave: (isDistribution, value, formValues) => {
                    return isEditForm ? !formValues?.exitEvent?.stopCreatingFutureEventsAfterExitEvent : false
                },}
            ]},
            {fields:[
                {key:'expectedIRR', label:expectedIrrLabel, description: expectedIrrDescription, defaultValue: 0,
                onBlur:({value,formValues}) => {
                    const { startDate, expectedEndDate } = formValues;
                    if (!isNullOrUndefinedOrZeroOrEmptyString(value)) {
                        const numberOfYears = (monthDiff(new Date(startDate), new Date(expectedEndDate)) / 12)
                        const updatedExpectedMultiplier = (1 + (value / 100)) ** numberOfYears;
                        updateValue('expectedMultiplier', floatFixed(updatedExpectedMultiplier, 2));
                    }
                }
                }
            ]},
            {fields:[
                {key:'expectedMultiplier', description: expectedMultiplierDescription}
            ]},
            {fields:[
                {key:'expectedDistributions', type:'radio', label: 'Do you have expected distributions?', 
                defaultValueFn: (values) => { return !isEditForm ? true : (values.expectedAnnualRevenue ? values.expectedAnnualRevenue.length > 0 : false )}, 
                // defaultValue:  true,
                onChange: ({value}) => {
                    updateValue('distributionReinvested', false);
                    updateField('distributionReinvested', { isHidden: !value, value: false });
                    updateSection('distributionScheduleProps', { isHidden: !value });
                    updateSection('distributionScheduleTable', { isHidden: !value });
                    if (!value) {
                        updateValue('expectedAnnualRevenue', []);
                    }
                },
                optionType: 'button',
                options:[
                    {label: 'Yes', value: true},
                    {label: 'No', value: false}
                ],
            }
            ]},
            {fields:[
                {key:'distributionReinvested', label: '', checkboxLabel: 'My distributions are reinvested' , type:'checkbox' , 
                description: 'Check this box if your distributions are reinvested into the fund',
                onChange:({value, formValues})=> {
                    updateValue('isDistributionsByPercentage', value);
                    updateField('expectedAnnualRevenue', { isPercentageDisplay: value });
                    updateField('expectedAnnualCashReturn', { withNumber: !value, label: `Expected annual${value ? '' : ' cash'} return` });
                    updateSection('distributionScheduleProps' , { title : value ? 'TARGET REINVESTED DISTRIBUTION' : 'Target cash distribution'});
                    updateSection('distributionScheduleTable' , { title : value ? 'TARGET REINVESTED DISTRIBUTION schedule' : 'Target cash distribution schedule'});
                    // update distributions schedule values to percentages / fixed number;
                    const updatedScheduleValues = formValues.expectedAnnualRevenue?.map(row => {
                    
                    const newValue = row.value === '' ? (
                        formValues.cashInvested && formValues.expectedAnnualCashReturn ? (
                            value ?    
                            parseFloat((formValues.expectedAnnualCashReturn / formValues.cashInvested * 100).toFixed(2)) : 
                            formValues.cashInvested / 100 * formValues.expectedAnnualCashReturn
                        ) : ''  
                      ) :( value ?
                        parseFloat((row.value / formValues.cashInvested * 100).toFixed(2)) :
                        (formValues.cashInvested / 100 * row.value) );
                      return { ...row, value: newValue }
                    })
                    updateValue('expectedAnnualRevenue', updatedScheduleValues);
                }},
                {
                    key:'isDistributionsByPercentage', 
                    isHidden:true,
                    defaultValue : false
                },
            ]},
            
            // {fields:[
            //     {key:'expectedAnnualDividend', label: expectedAnnualDividendLabel, description: expectedAnnualDividendDescription},
            // ]},
            // {fields:[
            //     {key:'expectedDistributionPeriod', label: expectedDistributionPeriodLabel,  description: expectedDistributionPeriodDescription}
            // ]},
        ]
    }
}

const capitalCallsSection = (updateValue,updateField,isEditForm) => {
    return {
        title:'Capital calls',
        titleId: 'capital_calls_table_label',
        fullWidth:true,
        onLoad: (values) => {
            if (!values.totalCommittedCapital && (!values.capitalCalls || values.capitalCalls.length === 0)){
                updateField('totalCommittedCapital',{isHidden: true});
                updateField('capitalCalls',{showPrevComp: true});
            }
        },
        rows:[
                {fields:[
                    {key:'totalCommittedCapital', type: 'numberWithHoldings', label: "Total committed capital" , 
                        description: 'The full amount you committed to invest',
                        onBlur:({value,formValues})=>{
                            updateField('capitalCalls',{relativeAmount: value});
                        },generatePropsFn : (values)=>{
                            console.log(values)
                            return {
                                prefix: CURRENCY_SYMBOL[values.currency] || '',
                                isHidden: !values.totalCommittedCapital && (!values.capitalCalls || values.capitalCalls.length === 0) , 
                                holdings: values.holdings 
                            }
                        },
                        setFnForSave: (isDistribution, value, formValues) => {
                            return !value ? 0 : value;
                        },
                    },
                ]},
                {fields:[
                    {key:'capitalCalls', label: 'Capital calls',
                    description: 'The amount you committed, divided into several "call events".',
                    type:'singleEventScheduler',
                    showTotals: true,
                    showTotalsWarnings: true,
                    // defaultValue:[],
                    generatePropsFn : (values)=> {                        
                        return {
                            prefix: CURRENCY_SYMBOL[values.currency] || 'USD', 
                            placeholder: CURRENCY_SYMBOL[values.currency] , 
                            relativeAmount: values.totalCommittedCapital || 0 ,
                            startDate: values.startDate , 
                            showPrevComp: !values.totalCommittedCapital &&  (!values.capitalCalls || values.capitalCalls.length === 0 ), 
                            holdings: values.holdings
                        }
                    },
            
                    showPercentage: true,
                    percentageTooltip: `Percentage of the 'Total committed capital'`,
                    onShow:({values})=>{
                        updateField('totalCommittedCapital',{isHidden: false});
                        if (isEditForm){
                            updateValue('capitalCalls', [{date: moment(),tempId: 1}]);
                        } else {
                            updateValue('totalCommittedCapital', values.value);
                            updateField('capitalCalls',{relativeAmount: values.value});
                            updateValue('capitalCalls', [{value:values.value,date: moment(values.startDate),remarks: "Initial investment",tempId: 1}]);
                            updateValue('value', 0);
                            updateValue('cashInvested', 0);
                        }
                        
                    },
                    validations: [
                        {
                          fn: (value) => {
                            for(let i = 0, l= value?.length; i < l; i++){
                                if(isNullOrUndefinedOrEmptyStringOrNan(Date.parse(value[i].date)) || isNullOrUndefinedOrEmptyStringOrNan(value[i].value)){
                                    return false;
                                }
                            }
                            return true; 
                          },
                          message: ' ',
                          bottomErrorMessage: () => {
                            return `Empty value for a row is not valid`;
                          },
                        },
                    ],
                    ClickToShowComponent:SingleEventSchedulerPrevComponent
                    },
                    
                ]},
        ]
    }
}

const monthsPerPeriodMap = {
    'Monthly' : 1,
    'Quarterly' : 3,
    'Semi-annually' : 6,
    'Annually' : 12
}

const distributionScheduleSection = (updateValue,updateField,isEditForm,expectedAnnualRevenueLabel, expectedAnnualRevenueDescription, expectedDistributionPeriodLabel, expectedDistributionPeriodDescription) => {
    return {
        key: 'distributionScheduleProps',
        title:'Target cash distribution',
        fullWidth:true,
        generatePropsFn : (values)=>{
            return {
                isHidden: !isEditForm ? false : !(values.expectedAnnualRevenue ? values.expectedAnnualRevenue.length > 0 : false ),
                title: values.distributionReinvested ? 'Target reinvested distribution' : 'Target cash distribution'
            }
        },
        rows: [
            {fields:[
                {key:'expectedAnnualCashReturn', label: expectedAnnualRevenueLabel, description: expectedAnnualRevenueDescription,
                    type:'percentage',
                    withNumber: true,
                    generatePropsFn : (values)=> {
                        const currencySymbol = CURRENCY_SYMBOL[values.currency] || 'USD'
                        return {
                            prefix: currencySymbol, placeholder: CURRENCY_SYMBOL[values.currency],holdings:values.holdings,
                            amount: values.cashInvested || values.value,
                            label: `Expected annual${values.distributionReinvested ? '' : ' cash'} return`,
                            description: `The total amount you expect to receive on this investment annually. The percentage rate is based on your asset's initial investment ${currencySymbol}${values.cashInvested}`
                        }
                    },
                    onBlur: ({value, formValues}) => {
                        
                        updateField('expectedAnnualRevenue', { expectedAnnualPercentage: value });
                        if (!formValues.isExpectedAnnualRevenueTouched){
                            const updatedScheduleValues = formValues.expectedAnnualRevenue?.map(row => {
                                const newValue = (
                                    formValues.cashInvested && value ? (
                                        formValues.distributionReinvested ? 
                                        value : 
                                        (formValues.cashInvested / 100 * value )
                                    ) : ''  
                                  );
                                  return { ...row, value: newValue }
                            })
                            updateValue('expectedAnnualRevenue', updatedScheduleValues);
                        }
                    }
                },
            ]},
            {fields:[
                {key:'expectedDistributionPeriod', label: expectedDistributionPeriodLabel, description: expectedDistributionPeriodDescription,
                type:'select',
                withDescriptionInOption: true,
                defaultValue: 'Monthly',
                withSuffixIcon: true,
                options: [
                    {value:'Monthly',label:'Monthly'},
                    {value:'Quarterly',label:'Quarterly', description:'Transactions date: Apr, Jul, Oct, Jan (next year).' , selectedDescription:'Jan, Apr, Jul, Oct each year'},
                    {value:'Semi-annually',label:'Semi-annually', description:'Transactions date: Jul and Jan (next year).' , selectedDescription:'Apr, Jul each year'},
                    {value:'Annually',label:'Annually', description:'Transactions date: Jan (next year).', selectedDescription:'January each year'},
                    {value:'Custom',label:'Custom...', },
                ],
                onChange: ({value, formValues}) => {
                    updateField('expectedDistributionStartDate', { isHidden: value !== 'Custom' });
                    updateField('expectedDistributionCustomInterval', { isHidden: value !== 'Custom' });
                    updateField('expectedDistributionCustomPeriod', { isHidden: value !== 'Custom' });
                    updateField('expectedAnnualRevenue', { 
                        monthsPerPeriod: monthsPerPeriodMap[value] ? monthsPerPeriodMap[value] : formValues.expectedDistributionCustomInterval || 1 ,
                        period: value === 'Custom' ? 'Periodic' : value
                    });
                },
            }
            ]},
            {fields:[
                {key:'expectedDistributionStartDate', clearable: true ,  label:'Transactions start date', description:`Enter the frequency's first payment date.`, type:'date',
                    generatePropsFn: (formValues) => {
                        return { isHidden: formValues.expectedDistributionPeriod !== 'Custom' }
                    },
                    onChange:({value , formValues})=>{
                        updateField('expectedAnnualRevenue',{startDate: value ? value : formValues.startDate});
                        
                        if (!formValues.isExpectedAnnualRevenueTouched){
                            const updatedScheduleValues = formValues.expectedAnnualRevenue.map((item,index)=>{
                                return {...item,date :  moment(value).utc().add(index,'y').set("D",15).startOf("D")}
                            });
                            updateValue('expectedAnnualRevenue', updatedScheduleValues);
                        }
                    }
                }
            ]},
            {fields:[
                {key:'expectedDistributionCustomInterval', label:'Repeat every:', type:'number', withArrows:true,
                maxValue: 15, minValue: 0, defaultValue:2, decimalScale: 0,
  
                generatePropsFn: (formValues) => {
                    return { isHidden: formValues.expectedDistributionPeriod !== 'Custom' }
                },
                onChange: ({value, formValues}) => {
                    updateField('expectedAnnualRevenue', {monthsPerPeriod: value * ( formValues.expectedDistributionCustomPeriod === 'Years' ? 12 : 1 ) })  
                },
                validations:[
                    {
                        fn: (value) => {
                            return value < 16;
                        },
                        message:'Maximum period allowed: 15'
                    }
                ]},
                {key:'expectedDistributionCustomPeriod', type:'select', defaultValue:'Months',
                withDescriptionInOption: true,
                withSuffixIcon: true,
                generatePropsFn: (formValues) => {
                    return { isHidden: formValues.expectedDistributionPeriod !== 'Custom' }
                },
                onChange: ({value, formValues}) => {
                    updateField('expectedAnnualRevenue', {monthsPerPeriod: formValues.expectedDistributionCustomInterval * ( value === 'Years' ? 12 : 1 ) })  
                },
                options:[
                    // {value: 'Weeks', label:'Weeks'},
                    {value: 'Months', label:'Months'},
                    {value: 'Years', label:'Years'},
                ]},
            ]}
        ]
    }
}

const distributionScheduleTableSection = (updateValue,updateField,isEditForm,expectedAnnualRevenueLabel, expectedAnnualRevenueDescription ) => {
    return {
        key: 'distributionScheduleTable',
        fullWidth:true,
        // title:'Target cash distribution schedule',
        generatePropsFn : (values)=>{
            return {
                isHidden: !isEditForm ? false : !(values.expectedAnnualRevenue ? values.expectedAnnualRevenue.length > 0 : false ) ,
                title: values.distributionReinvested ? 'Target reinvested distribution schedule' : 'Target cash distribution schedule'
            }
        },
        rows: [
            {fields:[
                {
                    key:'expectedAnnualRevenue', 
                    label: '',
                    // label: expectedAnnualRevenueLabel, 
                    // description: expectedAnnualRevenueDescription,
                    type:'scheduleTable',
                    defaultValue: [],
                    onChange: ({value, formValues}) => {
                        if (!formValues.isExpectedAnnualRevenueTouched){
                            updateValue('isExpectedAnnualRevenueTouched' , true);
                        }
                    },
                    showHistory: false,
                    showPercentage: true,
                    generatePropsFn : (values)=> {
                        const exPer = values.expectedDistributionPeriod;
                        return {
                            prefix: CURRENCY_SYMBOL[values.currency] || 'USD', 
                            placeholder: CURRENCY_SYMBOL[values.currency],
                            holdings:values.holdings , 
                            startDate: values.startDate,
                            relativeAmount: values.cashInvested || values.value,
                            isPercentageDisplay: values.distributionReinvested,
                            monthsPerPeriod: monthsPerPeriodMap[values.expectedDistributionPeriod] ? monthsPerPeriodMap[values.expectedDistributionPeriod] : (values.expectedDistributionCustomInterval || 1 ),
                            period: !exPer ? 'Monthly' : exPer === 'Custom' ? 'Periodic' : exPer
                        }
                    },
                    validations: [
                        {
                          fn: (value, formValues) => {
                            return Array.isArray(value) ? (value.length === 0 || !value.some(item=> isNullOrUndefinedOrEmptyString(item.value)) ) : true ; 
                          },
                          // message: 'Empty value for a row is not valid',
                          message: ' ',
                          bottomErrorMessage: (value, formValues) => {
                            return `Empty value for a row is not valid`; // `must be equal to ${formValues.transactionValue}.`
                          },
                        },
                    ]
                },
                {
                    key:'isExpectedAnnualRevenueTouched', 
                    isHidden:true,
                    defaultValue : isEditForm
                },
            ]},
        ]
    }
}

const moreInfoSection = (updateField,isEditForm, defaultCashAccountDescription, beneficiaryLabel, beneficiaryDescription ) => {
    return {
        title:`More info`,
        rows:[
            // {fields:[
            //     {key:'startDate', label: hedge_fund.START_DATE_LABEL, description: hedge_fund.START_DATE_DESCRIPTION, defaultValueFn: ()=>{return new Date()},
            //     disabledDate: disabledMoreThanToday }
            // ]},
            ...(isEditForm ? [
                containerRow({isEditForm, updateField}),
                {
                    fields:[
                        {key:'holdings',  label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth',   isLocked: true,  UnlockComponent:HoldingsUnlockModal,
                        validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                        validations:[isBetweenZeroAndHundred],
                        onBlur:({value})=>{
                            updateField('capitalCalls',{holdings:value || ''});
                            updateField('totalCommittedCapital',{holdings:value || ''});
                        },
                        }
                    ]
                },
                {
                    fields:[
                        {key:'defaultCashAccountId', description: defaultCashAccountDescription}
                    ]
                }
            ] : []),
            beneficiaryRow({label: beneficiaryLabel, description: beneficiaryDescription}),
            {fields:[
                {key:'onlinePlatformUrl'}
            ]},
            ...(isEditForm ? [
                riskLevelRow({updateField}),
                liquidityRow({updateField}),
            ] : []),
            {fields:[
                {key:'remarks'}
            ]}
        ]
    }
}


const exitEventRowSection = (updateValue,updateField,isEditForm) => {
    return {
        title:'',
        key: 'exitEventSection',
        fullWidth:true,
        generatePropsFn : (values)=>{
            return {
                isHidden: !isEditForm,
            }
        },
        rows: [
            exitEventFieldRow({
                isEditForm, 
                autoCalculateTooltip: <>Uncheck this box to edit the exit event amount manually.<br/><br/>Vyzer automatically calculates the expected exit event based on your target IRR, target distributions, capital calls, and all actual events during the asset's lifetime.</>
            })
        ]
    }
}