import { BaseModal } from "../../../../../../../common/components/BaseModal/BaseModal";
import { ActionButton, ActionsWrapper, HoldingsUnlockModalAltText, HoldingsUnlockModalMainText, HoldingsUnlockModalWrapper } from "./HoldingsUnlockModal.styles";

export const ChangeLoanTermsModal = ({ unlock, cancel , title , subTitle }) => {
  return (
    <BaseModal width={'500px'} height={'auto'} borderRadius={8} onBackDropClick={cancel}>
      <HoldingsUnlockModalWrapper style={{ textAlign: 'center', padding: '32px 64px' }}>
        {title ? <HoldingsUnlockModalMainText margin={'0 0 16px'}>{title}</HoldingsUnlockModalMainText> :
          <HoldingsUnlockModalMainText margin={'16px 0'}> Changing loan terms is not available.<br />Please open a new loan with the new terms.</HoldingsUnlockModalMainText>
        }
        <HoldingsUnlockModalAltText center>{subTitle || `Note: Interest rate can be changed from the ‘Events’ tab.`}</HoldingsUnlockModalAltText>
        {/* <HoldingsUnlockModalSmallText center>TIP: If you refinanced, close this loan and add a new one instead.</HoldingsUnlockModalSmallText> */}
        <ActionsWrapper padding="40px 0 0">
          {/* <ActionButton onClick={cancel}>Cancel</ActionButton> */}
          <ActionButton black onClick={cancel} style={{ maxWidth: '140px' }}>Got it</ActionButton>
        </ActionsWrapper>
      </HoldingsUnlockModalWrapper>

    </BaseModal>
  )
}