import moment from "moment";
import { CURRENCY_SYMBOL } from "../../../../../common/constants/currency-symbol.const";
import { masterInstitutionInputRows } from "../../common-fields/master-institution.const";
import { isBetweenZeroAndHundred, isNotNegative, isRequired } from "../../input-validations";
import { ChangeLoanTermsModal } from "../../../../wealth/pages/asset/components/Info/custom-form-components/ChangeLoanTermsModal";
import { calcMonthLeft, calcMonthlyPayment } from "../../../../../common/utils/liabilities.utils";
import { disabledMonthBefore } from "../../../../../common/utils/dates.utils";
// import { FixedPaymentUnlockModal } from "../../../../wealth/pages/asset/components/Info/custom-form-components/FixedPaymentUnlockModal";
import { Tooltip } from "antd";
import { FieldLabelToolTipTriggerWrapper } from "../../../../../common/components/form-displayer/FormDisplayer.styles";
import { TooltipIcon } from "../../../../../assets/icons/common/common-icons";
import { containerRow } from "../../common-fields/container-field.const";
import { riskLevelRow } from "../../common-fields/risk-level-field.const";
import { liquidityRow } from "../../common-fields/liquidity-field.const";
import { customClassAndSubClassRows } from "../../common-fields/custom-class-and-sub-class-field.const";
import { beneficiaryRow } from "../../common-fields/beneficiary-field.const";

const periodsToMonthsMapper = {
  'Annually': 12,
  'Quarterly': 4,
  'Semi-annually': 2,
  'Monthly': 1
}

const periodsValueMapper = {
  'Annually': 1,
  'Quarterly': 4,
  'Semi-annually': 2,
  'Monthly': 12
}

const customPeriodsValueMapper = {
  'Months': 1,
  'Years': 12,
}

export const createPrivateLoanAmortizedAddItemSections = (isEditForm) => {
  return ({ updateValue, updateField }) => {
    return [
      {
        title: 'General info',
        rows: [
          {
            fields: [
              {
                key: 'borrower', type: 'text', label: 'Borrower', description: 'The person or entity responsible for repaying the loan', validations: [isRequired],
                onBlur: ({ value , formValues }) => {
                  if (!formValues.title){
                    updateValue('title', value);
                  }
                }
              },
            ]
          },
          {
            fields: [
              { key: 'title',
              label: 'Loan name', 
            
            
              description: `This is the name you'll use to search for this private loan in Vyzer`, 
               validations: [isRequired] },
            ]
          },
          ...masterInstitutionInputRows('Agent / Platform', 'The platform or agent servicing this loan', 1, false, updateValue),
          beneficiaryRow({ description:`The person or company listed as the asset's owner`}),
          ...(isEditForm ? [
            ...customClassAndSubClassRows({updateValue,updateField}),
          ] : [

          ]),
        ]
      },
      {
        title: 'Loan info',
        rows: [
          {
            fields: [
              {
                key: 'startDate', label: 'Initial loan date',
                validations: [isRequired],
                // defaultValueFn: () => { return new Date() },
                onChange: ({ value , formValues }) => {
                  const oneMonthFromStartDate = moment(value).add(1, 'month');
                  updateField('expectedDistributionStartDate',{disabledDate : (current) => disabledMonthBefore(current,oneMonthFromStartDate )} )
                  updateField('expectedEndDate',{disabledDate : (current) => disabledMonthBefore(current,oneMonthFromStartDate)} )
                  updateValue('principalFirstRepayment',oneMonthFromStartDate)
                  updateField('principalFirstRepayment',{disabledDate : (current) => disabledMonthBefore(current,oneMonthFromStartDate )} )
                  updateValue('interestFirstPayment', oneMonthFromStartDate)
                  updateField('interestFirstPayment',{disabledDate : (current) => disabledMonthBefore(current,oneMonthFromStartDate )} )
                  updateField('amortization', {startDate: moment(value)})
                  handleMonthlyPaymentCalculation({formValues , updateValue, updateField});
                },
                description: 'Enter the date this loan was first issued'
              }
            ]
          },
          {
            fields: [
              {
                key: 'value', type: 'numberWithHoldings', label: 'Initial loan amount', description: 'The amount you lent to the borrower on the initial loan date.', validations: [isRequired],
                onBlur: ({ value , formValues }) => {
                  updateValue('cashInvested', value);
                  handleMonthlyPaymentCalculation({formValues , updateValue, updateField});
                }, generatePropsFn: (values) => {
                  return { prefix: CURRENCY_SYMBOL[values.currency] || '' }
                }
              },
              {
                key: 'currency', size: 0.3, onChange: ({ value }) => {
                  updateValue('cashInvestedCurrency', value);
                  updateField('value', { prefix: CURRENCY_SYMBOL[value] || '' });
                  updateField('cashInvested', { prefix: CURRENCY_SYMBOL[value] || '' });
                  updateField('fixedMonthlyPayment', { prefix: CURRENCY_SYMBOL[value] || '' });
                  
                }
              },
            ]
          },
          ...(isEditForm ? [] : [
            {
              fields: [
                {
                  key: 'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isHidden: true,
                  validValues: ({ floatValue }) => { return !floatValue || (floatValue >= 0 && floatValue <= 100) },
                  validations:[isBetweenZeroAndHundred],
                  onBlur: ({ value }) => {
                    updateField('value', { holdings: value || '' });
                    updateField('cashInvested', { holdings: value || '' });
                  },
                  generatePropsFn: (values) => {
                    return { isHidden: values.holdings === 100 }
                  }
                }
              ]
            },
            {
              fields: [
                {
                  type: 'checkbox', key: 'isPartialHolding', checkboxLabel: 'This loan is 100% lent by me', description: 'Uncheck this box if you co-own this asset',
                  fieldCustomStyle: { marginTop: '-24px' },
                  defaultValue: true,
                  onChange: ({ value }) => {
                    const newHoldingValue = value ? 100 : 50;
                    updateValue('holdings', newHoldingValue);
                    updateField('holdings', { isHidden: value });
                    updateField('value', { holdings: newHoldingValue });
                    updateField('cashInvested', { holdings: newHoldingValue });
                  }
                },
              ]
            },
          ]),
          {
            fields: [
              {
                key: 'principalFirstRepayment',
                label: () => <div><span style={{ fontWeight: 700 }}>Principal’s</span> first repayment date</div>,
                description: `For a balloon payment, select “At the end of the loan”. For repayments starting after a grace period, specify the month`,
                withCustomOption: true,
                clearable: false,
                customOptions: [
                  { text: 'At the end of the loan', value: 'end' },
                ],
                onChange: ({ value, formValues }) => {
                  const currentAmortizationType = amortizationType({ formValues });
                  updateField('fixedMonthlyPayment', { label: updateFixedMonthlyPaymentLabel({ formValues }) })
                  updateField('expectedDistributionPeriod', { label: currentAmortizationType === 'balloon' ? 'Interest payment period' : 'Payment period', isHidden: currentAmortizationType === 'bullet' })
                  handleMonthlyPaymentCalculation({formValues , updateValue, updateField});
                  updateField('expectedDistributionCustomInterval', { isHidden: formValues.expectedDistributionPeriod !== 'Custom' || currentAmortizationType === 'bullet' });
                  updateField('expectedDistributionCustomPeriod', { isHidden: formValues.expectedDistributionPeriod !== 'Custom' || currentAmortizationType === 'bullet' });
                },
                overrideValueFn: (formValues) => moment(formValues.principalFirstRepayment).format('YYYY-MM-DD') === moment(formValues.expectedEndDate).format('YYYY-MM-DD') ? 'end' : formValues.principalFirstRepayment,
                setFnForSave: (isDistribution, value, formValues) => {
                  return value === 'end' ? formValues.expectedEndDate : value;
                },
                isLocked: isEditForm,
                UnlockComponent: ChangeLoanTermsModal,
                hideEditLockIcon: true,
              }
            ]
          },
          {
            fields: [
              {
                key: 'interestFirstPayment',
                label: () => <div><span style={{ fontWeight: 700 }}>Interest’s</span> first payment date</div>,
                description: `Select the beginning of the interest payment`,
                withCustomOption: true,
                clearable: false,
                customOptions: [
                  { text: 'At the end of the loan', value: 'end' },
                ],
                onChange: ({ value, formValues }) => {
                  const currentAmortizationType = amortizationType({ formValues });
                  updateField('amortization', { isHidden: currentAmortizationType === 'bullet', skipValidation: currentAmortizationType === 'bullet' });
                  updateField('isPayoffSameDate', { isHidden: currentAmortizationType === 'bullet', skipValidation: currentAmortizationType === 'bullet' });
                  updateField('expectedDistributionPeriod', { isHidden: currentAmortizationType === 'bullet', skipValidation: currentAmortizationType === 'bullet' });
                  updateField('expectedEndDate', { isHidden: currentAmortizationType !== 'bullet', skipValidation: currentAmortizationType !== 'bullet' });
                  handleMonthlyPaymentCalculation({formValues , updateValue, updateField});
                  updateField('expectedDistributionCustomInterval', { isHidden: formValues.expectedDistributionPeriod !== 'Custom' || currentAmortizationType === 'bullet' });
                  updateField('expectedDistributionCustomPeriod', { isHidden: formValues.expectedDistributionPeriod !== 'Custom' || currentAmortizationType === 'bullet' });
                },
                overrideValueFn: (formValues) => moment(formValues.interestFirstPayment).format('YYYY-MM-DD') === moment(formValues.expectedEndDate).format('YYYY-MM-DD') ? 'end' : formValues.interestFirstPayment,
                setFnForSave: (isDistribution, value, formValues) => {
                  return value === 'end' ? formValues.expectedEndDate : value;
                },
                isLocked: isEditForm,
                UnlockComponent: ChangeLoanTermsModal,
                hideEditLockIcon: true,
              }
            ]
          },

        ]
      },
      {
        title: 'Amortization terms',
        rows: [
          {
            fields: [
              {
                key: 'interest',
                // label: 'Annual interest rate',
                label: () => (<div><span >Annual interest rate</span><br/>
                <div style={{display:'flex' , alignItems:'center'}}>
                  <span style={{fontWeight: 450, fontSize: '12px' , color:'#585D66'}}>You can add variables later</span>
                  <Tooltip title={'Enter the initial interest rate of the loan'} ><FieldLabelToolTipTriggerWrapper ><TooltipIcon /></FieldLabelToolTipTriggerWrapper></Tooltip>
                  </div>
                  </div>),
                // description: 'Enter the initial interest rate of the loan',
                validations: [isRequired, isNotNegative],
                specificDecimalScale: 3,
                isLocked: isEditForm,
                showHistory: false,
                UnlockComponent: ChangeLoanTermsModal,
                hideEditLockIcon: true,
                onBlur: ({ value, formValues }) => {
                  handleMonthlyPaymentCalculation({formValues , updateValue, updateField});
                }
              }
            ]
          },
          {
            fields: [
              {
                key: 'amortization',
                label: 'Amortization period',
                description: 'The agreed period for the borrower to repay both the principal and the interest',
                type: 'amortizationPeriod',
                UnlockComponent: ChangeLoanTermsModal,
                isLocked: isEditForm,
                hideEditLockIcon: true,
                onChange: ({ value, formValues }) => {
                  updateValue('expectedEndDate', moment(formValues.startDate).add(value.amount, value.period === 'Annually' ? 'Y' : 'M').toDate(), true)
                  updateValue('amortizationMonths', value.period === "Annually" ? value.amount * 12 : value.amount);
                  handleMonthlyPaymentCalculation({formValues , updateValue, updateField});
                },
                defaultValueFn: (values) => {
                  const months = values?.amortizationMonths;
                  if (!months) return { amount: null, period: 'Annually' };
                  const isYears = months % 12 === 0 ? months / 12 : false;

                  return {
                    amount: isYears ? isYears : months,
                    period: isYears ? 'Annually' : 'Monthly',
                  };
                },
                generatePropsFn: (formValues) => {
                  const currentAmortizationType = amortizationType({ formValues });
                  const isHidden = currentAmortizationType === 'bullet'
                  return {
                    calculatedEndDate: moment(formValues.startDate),
                    isHidden
                  }
                },
                validations: [
                  {
                    fn: (value) => {
                      return isEditForm || (Object.values(value).length && value.amount > 0);
                    },                    
                    message: `Required`
                  },
                ]
              },
            ]
          },
          {
            fields: [
              {
                type: 'checkbox',
                key: 'isPayoffSameDate',
                checkboxLabel: 'Expected payoff date is the same as above',
                description: 'Uncheck this box if the amortization period is different from the full repayment date',
                fieldCustomStyle: { marginTop: '-24px' },
                // defaultValue: true,
                defaultValueFn: (formValues) => {
                  const currentAmortizationType = amortizationType({ formValues });
                  return currentAmortizationType !== 'bullet'
                },
                onChange: ({ value, formValues }) => {
                  updateField('expectedEndDate', { isHidden: value });
                },
                generatePropsFn: (formValues) => {
                  const currentAmortizationType = amortizationType({ formValues });
                  const isHidden = currentAmortizationType === 'bullet'
                  return {
                    isHidden,
                  }
                }
                // isLocked: isEditForm,
                // UnlockComponent: ChangeLoanTermsModal,
                // hideEditLockIcon: true,
              },
            ]
          },
          {
            fields: [
              {
                key: 'expectedEndDate',
                label: 'Expected payoff date',
                // isHidden: true,
                description: '',
                validations: [isRequired],
                generatePropsFn: (formValues) => {
                  const currentAmortizationType = amortizationType({ formValues });
                  const isHidden = currentAmortizationType !== 'bullet'
                  return {
                    disabledDate: (current) => disabledMonthBefore(current, moment(formValues.startDate).add(1, 'month')),
                    isHidden
                  }
                },
                onChange: ({value, formValues}) => {
                  const { principalFirstRepayment, interestFirstPayment } = formValues;
                  if (principalFirstRepayment === 'end' || (moment(value).format('YYYY-MM-DD') === moment(principalFirstRepayment).format('YYYY-MM-DD'))) {
                    updateValue('principalFirstRepayment', value)
                  }
                  if (interestFirstPayment === 'end' || (moment(value).format('YYYY-MM-DD') === moment(interestFirstPayment).format('YYYY-MM-DD'))) {
                    updateValue('interestFirstPayment', value)
                  }
                }
              }
            ]
          },
          {
            fields: [
              {
                key: 'expectedDistributionPeriod',
                label: 'Payment period',
                type: 'select',
                withDescriptionInOption: true,
                defaultValue: 'Monthly',
                withSuffixIcon: true,
                options: [
                  { value: 'Monthly', label: 'Monthly' },
                  {value:'Quarterly',label:'Quarterly', description:'Transactions date: Apr, Jul, Oct, Jan (next year).' , selectedDescription:'Jan, Apr, Jul, Oct each year'},
                  {value:'Semi-annually',label:'Semi-annually', description:'Transactions date: Jul and Jan (next year).' , selectedDescription:'Apr, Jul each year'},
                  {value:'Annually',label:'Annually', description:'Transactions date: Jan (next year).', selectedDescription:'January each year'},
                  { value: 'Custom', label: 'Custom...', },
                ],
                onChange: ({ value, formValues }) => {
                  updateField('fixedMonthlyPayment', { label: updateFixedMonthlyPaymentLabel({ formValues }) })
                  updateField('fixedMonthlyPayment', { description: `The amount due ${value.toLowerCase()}, including principal and interest. Vyzer calculates this total based on the data you entered` })
                  updateField('expectedDistributionStartDate', { isHidden: value !== 'Custom' });
                  updateField('expectedDistributionCustomInterval', { isHidden: value !== 'Custom' });
                  updateField('expectedDistributionCustomPeriod', { isHidden: value !== 'Custom' });
                  handleMonthlyPaymentCalculation({formValues , updateValue, updateField});
                },
                generatePropsFn: (formValues) => {
                  const currentAmortizationType = amortizationType({ formValues });
                  const isHidden = currentAmortizationType === 'bullet';
                  const skipValidation = currentAmortizationType === 'bullet';
                  const isLocked = isEditForm && currentAmortizationType !== 'balloon';
                  const label = currentAmortizationType === 'balloon' ? 'Interest payment period' : 'Payment period';
                  return { 
                    isHidden,
                    skipValidation,
                    isLocked,
                    label
                  };
                },
                // isLocked: isEditForm,
                UnlockComponent: ChangeLoanTermsModal,
                hideEditLockIcon: true,
              }
            ]
          },
          // {
          //   fields: [
          //     {
          //       key: 'expectedDistributionStartDate', clearable: true, label: 'Transactions start date', description: `Enter the frequency's first payment date.`, type: 'date',
          //       // disabledDate: (current) => disabledMonthBefore(current, moment(start_date).add(1, 'month')),
          //       generatePropsFn: (formValues) => {
          //         return { 
          //           isHidden: formValues.expectedDistributionPeriod !== 'Custom',
          //           disabledDate: (current) => disabledMonthBefore(current, moment(formValues.startDate).add(1, 'month')),
          //         }
          //       },
          //     }
          //   ]
          // },
          {
            fields: [
              {
                key: 'expectedDistributionCustomInterval', label: 'Repeat every:', type: 'number', defaultValue: 2, decimalScale: 0, withArrows: true,
                maxValue: 15, minValue: 0,
                generatePropsFn: (formValues) => {
                  const { expectedDistributionPeriod } = formValues;
                  const currentAmortizationType = amortizationType({ formValues });
                  const isHidden = expectedDistributionPeriod !== 'Custom' || currentAmortizationType === 'bullet';
                  return { isHidden };
                },
                onChange: ({ value, formValues }) => {
                  updateField('expectedAnnualRevenue', { monthsPerPeriod: value * (formValues.expectedDistributionCustomPeriod === 'Years' ? 12 : 1) })
                  handleMonthlyPaymentCalculation({formValues , updateValue, updateField});
                },
                isLocked: isEditForm,
                UnlockComponent: ChangeLoanTermsModal,
                hideEditLockIcon: true,
                validations: [
                  {
                    fn: (value) => {
                      return value < 16;
                    },
                    message: 'Maximum period allowed: 15'
                  }
                ]
              },
              {
                key: 'expectedDistributionCustomPeriod', type: 'select', defaultValue: 'Months',
                withDescriptionInOption: true,
                withSuffixIcon: true,
                generatePropsFn: (formValues) => {
                  const { expectedDistributionPeriod } = formValues;
                  const currentAmortizationType = amortizationType({ formValues });
                  const isHidden = expectedDistributionPeriod !== 'Custom' || currentAmortizationType === 'bullet';
                  return { isHidden };
                },
                isLocked: isEditForm,
                UnlockComponent: ChangeLoanTermsModal,
                hideEditLockIcon: true,
                onChange: ({ value, formValues }) => {
                  updateField('expectedAnnualRevenue', { monthsPerPeriod: formValues.expectedDistributionCustomInterval * (value === 'Years' ? 12 : 1) })
                  handleMonthlyPaymentCalculation({formValues , updateValue, updateField});
                },
                options: [
                  // {value: 'Weeks', label:'Weeks'},
                  { value: 'Months', label: 'Months' },
                  { value: 'Years', label: 'Years' },
                ]
              },
            ]
          },
          {
            fields: [{
              key: 'fixedMonthlyPayment',
              description: 'The amount due monthly, including principal and interest. Vyzer calculates this total based on the data you entered',
              isLocked: true,
              UnlockComponent: ChangeLoanTermsModal,
              hideEditLockIcon: true,
              validations: [isRequired,
                {
                  fn: (value, formValues) => {
                    const currentAmortizationType = amortizationType({ formValues });
                    const isValidFixedPayment = value <= formValues.value // Fixed payment cant be higher than the total loan value
                    const isValid = currentAmortizationType !== 'simple' || calcMonthLeft(formValues.value, formValues.interest / 100, value) > 0 || formValues.interest === 0;
                    return isValid && isValidFixedPayment
                  },
                  message: `Required`,
                  bottomErrorMessage: (value, formValues) => {
                    return `Invalid loan terms conditions.\nThe monthly interest payment (as calculated) cannot be above the fixed monthly payment (of both principal + interest).\nPlease change the ‘Annual interest rate’ or the ‘Fixed monthly payment’`
                  },
                }
              ],
              decimalScale: 2,
              onBlur: ({ value, formValues }) => {
                if (value && formValues.interest) {
                  let numberOfMonthsToReturn = Math.ceil(calcMonthLeft(formValues.value, formValues.interest / 100, value));
                  // let period = 'Monthly';
                  // if (numberOfPeriodToReturn % 12 === 0) {
                  //   period = 'Annually';
                  //   numberOfPeriodToReturn = numberOfPeriodToReturn / 12;
                  // }
                  // const monthsDiff = formValues.amortization.amount numberOfMonthsToReturn
                  // calc the diff , update expected end date.
                  updateValue('isPayoffSameDate', false);
                  updateField('expectedEndDate', { isHidden: false });
                  updateValue('expectedEndDate', moment(formValues.startDate).add(numberOfMonthsToReturn, 'M').toDate(), true)
                  // updateValue('amortization', { period, amount: numberOfPeriodToReturn }, true);

                }
              },
              generatePropsFn: (formValues) => {
                const currentAmortizationType = amortizationType({ formValues });
                const isHidden = currentAmortizationType === 'bullet';
                const skipValidation = isEditForm && (currentAmortizationType === 'bullet' || currentAmortizationType === 'balloon');
                return {
                  prefix: CURRENCY_SYMBOL[formValues.currency] || '',
                  placeholder: CURRENCY_SYMBOL[formValues.currency] || '',
                  label: updateFixedMonthlyPaymentLabel({ formValues }),
                  isHidden,
                  skipValidation
                }
              }
            },
            {
              key:'fixedMonthlyPaymentEditedManually', 
              isHidden:true,
              defaultValue: false
            },
            ]
          },
        ]
      },
      {
        title: 'More info',
        rows: [
          ...(isEditForm ? [
            containerRow({isEditForm, updateField}),
            riskLevelRow({updateField}),
            liquidityRow({updateField}),
          ] : []),
          {
            fields: [
              { key: 'remarks' }
            ]
          }
        ]
      }
    ]
  }

}

const updateFixedMonthlyPaymentLabel = ({ formValues }) => {
  const { expectedDistributionPeriod } = formValues;
  const currentAmortizationType = amortizationType({ formValues });
  if (currentAmortizationType === 'balloon') {
    return `Interest ${expectedDistributionPeriod === 'Custom' ? 'periodic' : expectedDistributionPeriod.toLowerCase()} payment`;
  }
  return expectedDistributionPeriod ? `${expectedDistributionPeriod === 'Custom' ? 'Periodic' : expectedDistributionPeriod} payment` : 'Fixed Monthly payment';
}

const amortizationType = ({ formValues }) => { // [simple, balloon, grace, bullet]
  const { principalFirstRepayment, interestFirstPayment, startDate, expectedEndDate } = formValues;

  const isPrincipalFirstRepaymentSameAsExpectedEndDate = principalFirstRepayment === 'end' || moment(principalFirstRepayment).format('YYYY-MM-DD') === moment(expectedEndDate).format('YYYY-MM-DD');
  const isInterestFirstPaymentSameAsExpectedEndDate = interestFirstPayment === 'end' || moment(interestFirstPayment).format('YYYY-MM-DD') === moment(expectedEndDate).format('YYYY-MM-DD');
  
  const isPrincipalAndInterestSameDate = moment(principalFirstRepayment).isSame(interestFirstPayment,'M');
  const isPrincipalSameAsStartDateOrOneMonthLater = moment(principalFirstRepayment).isSame(startDate,'M') || moment(principalFirstRepayment).subtract(1,'M').isSame(startDate,'M');
  const isInterestSameAsStartDateOrOneMonthLater = moment(interestFirstPayment).isSame(startDate,'M') || moment(interestFirstPayment).subtract(1,'M').isSame(startDate,'M')
  if (isPrincipalAndInterestSameDate && isPrincipalSameAsStartDateOrOneMonthLater) {
    return 'simple';
  } else if (isPrincipalFirstRepaymentSameAsExpectedEndDate && !isInterestFirstPaymentSameAsExpectedEndDate) {
    return 'balloon';
  } else if ((!isPrincipalFirstRepaymentSameAsExpectedEndDate && !isPrincipalSameAsStartDateOrOneMonthLater) || (!isInterestFirstPaymentSameAsExpectedEndDate && !isInterestSameAsStartDateOrOneMonthLater)) {
    return 'grace';
  } else if (isPrincipalFirstRepaymentSameAsExpectedEndDate && isInterestFirstPaymentSameAsExpectedEndDate) {
    return 'bullet';
  }
}


const handleMonthlyPaymentCalculation = ({ formValues, updateValue, updateField }) => {
  const currentAmortizationType = amortizationType({ formValues });
  const { value, amortization } = formValues;

  const isAmortizationTypeHidden = ['grace', 'bullet'].includes(currentAmortizationType);
  updateField('fixedMonthlyPayment', { isHidden: isAmortizationTypeHidden, skipValidation: isAmortizationTypeHidden });

  let monthlyPayment = 0;

  if (currentAmortizationType === 'balloon') {
    monthlyPayment = calculateBalloonPayment(formValues);
  } else if (value && amortization?.amount) {
    monthlyPayment = calculateOtherPayment(formValues);
  }

  updateValue('fixedMonthlyPayment', monthlyPayment);
}

function calculateBalloonPayment(formValues) {
  const { value, interest, expectedDistributionPeriod, expectedDistributionCustomInterval, expectedDistributionCustomPeriod } = formValues;
  if (expectedDistributionPeriod === 'Custom') {
    if (expectedDistributionCustomPeriod === 'Months') {
      return value * interest / 100 / (12 / expectedDistributionCustomInterval * customPeriodsValueMapper[expectedDistributionCustomPeriod]);
    } else {
      return value * interest / 100 * expectedDistributionCustomInterval;
    }
  } else {
    return value * interest / 100 / periodsValueMapper[expectedDistributionPeriod];
  }
}

function calculateOtherPayment(formValues) {
  const { value, interest, amortization } = formValues;
  if (formValues.expectedDistributionPeriod === 'Custom') {
    return calculateCustomPayment(formValues);
  } else {
    const amortizationDurationInMonths = periodsToMonthsMapper[amortization.period] * amortization.amount;
    const duration = Math.ceil(amortizationDurationInMonths / (12 / periodsValueMapper[formValues.expectedDistributionPeriod]));
    const updatedInterest = interest / 100 * (12 / periodsValueMapper[formValues.expectedDistributionPeriod]);
    return calcMonthlyPayment(value, updatedInterest, duration);
  }
}

function calculateCustomPayment(formValues) {
  const { value, interest, amortization, expectedDistributionCustomInterval, expectedDistributionCustomPeriod } = formValues;
  const updatedInterest = interest / 100 * expectedDistributionCustomInterval;
  const customDuration = expectedDistributionCustomInterval * customPeriodsValueMapper[expectedDistributionCustomPeriod];
  const duration = Math.ceil(amortization.amount * periodsToMonthsMapper[amortization.period] / customDuration);
  return calcMonthlyPayment(value, updatedInterest, duration);
}


// const handleMonthlyPaymentCalculation = ({formValues , updateValue , updateField}) => {
//   const currentAmortizationType = amortizationType({ formValues });
//   const {value , interest , amortization} = formValues;

//   updateField('fixedMonthlyPayment', { isHidden: ['grace', 'bullet'].includes(currentAmortizationType), skipValidation: ['grace', 'bullet'].includes(currentAmortizationType) });
  
//   if (!interest){return}

//   if (currentAmortizationType === 'balloon'){
//     const monthlyPayment = value * interest / 100 / periodsToMonthsMapper[formValues.expectedDistributionPeriod];
//     updateValue('fixedMonthlyPayment', monthlyPayment);
//   } else if (value && amortization.amount) {
//     let monthlyPayment = 0
//     if (formValues.expectedDistributionPeriod === 'Custom') {
//       const updatedInterest = interest / 100 * formValues.expectedDistributionCustomInterval
//       const customDuration = formValues.expectedDistributionCustomInterval * customPeriodsValueMapper[formValues.expectedDistributionCustomPeriod]
//       const duration = Math.ceil(amortization.amount * periodsToMonthsMapper[amortization.period] / customDuration)
//       monthlyPayment = calcMonthlyPayment(value, updatedInterest, duration)
//     }else{
//       const amortizationDurationInMonths = periodsToMonthsMapper[amortization.period] * amortization.amount
//       const duration =  Math.ceil(amortizationDurationInMonths  / (12 / periodsValueMapper[formValues.expectedDistributionPeriod]))
//       const updatedInterest = interest / 100 * (12 / periodsValueMapper[formValues.expectedDistributionPeriod])
      
//       monthlyPayment = calcMonthlyPayment(value,  updatedInterest, duration)
//     }
//     updateValue('fixedMonthlyPayment', monthlyPayment);
//   }
// }