import styled from '@emotion/styled/macro';
import { NavLink } from 'react-router-dom';
import { flexCenter } from '../../styles/combos.styles';
import { colors, shadows, views } from '../../styles/theme.styles';

export const MenuWrapper = styled.div`
    width:${props => props.width || '200px'};
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 8px 0px;
`;

export const MenuItemWrapper = styled.div`
    padding: 8px 16px 8px 8px;
    ${flexCenter};
    color:${colors.darkGray1};
    ${props => !props.disabled ? `
    :hover{
        background-color: ${colors.lightGray1};
    }
    cursor: pointer;
    ` : null}
    ${views.tablet}{
        justify-content:start;
    }
`;

export const MenuItemWrapperBlackBorder = styled.div`
    padding: 8px 16px;
    position:relative;
    color:${colors.darkGray1};
    // border-left:2px solid ${colors.lightGray1};
    ::before{
        content:' ';
        position:absolute;
        left:0;
        top:0%;
        height:100%;
        width:0px;
        background-color:${colors.darkGray1};
        // transition:all 0.2s;
    }
    :hover{
        background-color:${colors.lightGray1};
        //border-left:2px solid ${colors.darkGray1};
        ::before{
            width:2px;
            /* top:0%;
            height:100%; */
        }
    }
    ${flexCenter};
    justify-content:space-between;
    cursor: pointer;

    ${props=>props.withCheckMark && `
        height: 44px;
    `}
    
    ${props=>props.withBorders && `
        :not(:last-of-type){
            border-bottom: 1px solid ${colors.gray3};
        }
    `}

`

export const BlackMenuWrapper = styled.div`
    width: 160px;
    background-color: #000000;
    border-radius: 8px;
    box-shadow: ${shadows.selectMenuShadow};
    border: 1px solid #303236;
`;

export const BlackMenuItemWrapper = styled.div`
    height: 44px;
    padding: 0 12px 0 16px ;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    :hover{
        background-image: linear-gradient(90deg, #FFFFFF 2px , #303236 2px); 
    }

    :first-of-type{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    :last-of-type{
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }


    .ant-checkbox-input, .ant-checkbox-inner {
        /* width: 16px;
        height: 16px; */
        :focus{
            border-color:${colors.darkGray3};
        }
        background: ${colors.darkGray1};
        border-radius: 4px;
    }
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color:${colors.darkGray3};
    }
    .ant-checkbox-checked{
        .ant-checkbox-inner {
            background-color:${colors.darkGray3};
            border-color:${colors.darkGray3};
            /* ::after{
                transform: rotate(45deg) scale(1) translate(-56%, -58%);
            } */
        }
        ::after {
            border-color:${colors.darkGray3};
        }
    }
    :hover{
        .ant-checkbox-inner {
            border-color:${colors.darkGray3};
        }
    }
    &.ant-checkbox-wrapper-checked:hover{
        .ant-checkbox-inner {
            border-color:${colors.darkGray3};
        }
    }
    .ant-checkbox-wrapper, .ant-checkbox-wrapper-checked {
        :hover {
            .ant-checkbox, .ant-checkbox-checked::after {
                border-radius: 8px;
            }
        }
    }
`;

export const MenuWrapperWealthPie = styled.div`
    width: 160px;
    background-color: #000000;
    border-radius: 8px;
    box-shadow: ${shadows.selectMenuShadow};
    border: 1px solid #303236;
`;

export const MenuWrapperWealthPieDropdown = styled.div`
    display: flex;
    width: 248px;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 8px;
    background: #000;

    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
`;

export const MenuWrapperWealthPieDropdownItem = styled.div`
    display: flex;
    padding: ${({checked}) => `8px ${checked ? '8px' : '24px'} 8px 24px`};
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-left: 4px solid transparent;

    &:hover {
        border-left: 4px solid var(--White, #FFF);
        background: #303236;
        cursor: pointer;
    }
`;

export const MenuWrapperWealthPieDropdownItemTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    flex: 1 0 0;
`;

export const MenuWrapperWealthPieDropdownItemTitle = styled.div`
    display: flex;
    align-items: center;

    color: var(--White, #FFF);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

export const MenuWrapperWealthPieDropdownItemSubTitle = styled.div`
    color: var(--Gray-3, #DDE3EB);
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: 130%; /* 15.6px */
`;


export const MenuItemWrapperWealthPie = styled.div`
    height: 44px;
    padding: 0 ${props=> props.isSelected ? '12' : '16'}px 0 16px ;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    :hover{
        background-image: linear-gradient(90deg, #FFFFFF 2px , #303236 2px); 
    }

    :first-of-type{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    :last-of-type{
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
`;

export const MenuNavLinkItemWrapper = styled(NavLink)`
    padding:8px 16px;
    ${flexCenter};
    color:${colors.darkGray1};
    :hover{
        background-color: ${colors.lightGray1};
        color:${colors.darkGray1};
    }
`;

export const MenuItemIconWrapper = styled.div`
    height:24px;
    width:24px;
    ${flexCenter};
    ${props=>props.color && 
        `svg{
            path{
                stroke:${props.color};
            };
        };`
    };

    svg{
        height:24px;
        width:24px;
    }
    margin-right:12px;
`

export const MenuItemText = styled.div`
    flex:1;
    font-size:16px;
    line-height:19px;
`

export const MenuSeparators = styled.div`
    width:100%;
    height:1px;
    background-color:${colors.gray3};
    margin:4px 0px;
`;