import moment from "moment";
import { SingleEventSchedulerPrevComponent } from "../../../../../common/components/form-displayer/input-types/SingleEventsScheduler/SingleEventScheduler";
import { CURRENCY_SYMBOL } from "../../../../../common/constants/currency-symbol.const";
import { getTranslations } from "../../../../../common/translations/translations";
import { disabledMoreThanToday, monthDiff } from "../../../../../common/utils/dates.utils";
import { floatFixed } from "../../../../../common/utils/number-display.utils";
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString, isNullOrUndefinedOrEmptyStringOrNan, isNullOrUndefinedOrZeroOrEmptyString } from "../../../../../common/utils/object.utils";
import { HoldingsUnlockModal } from "../../../../wealth/pages/asset/components/Info/custom-form-components/HoldingsUnlockModal";
import { masterInstitutionInputRows } from "../../common-fields/master-institution.const";
import { isBetweenZeroAndHundred, isRequired } from "../../input-validations";
import { containerRow } from "../../common-fields/container-field.const";
import { exitEventFieldRow } from "../../common-fields/exit-event-field.const";
import { riskLevelRow } from "../../common-fields/risk-level-field.const";
import { liquidityRow } from "../../common-fields/liquidity-field.const";
import { customClassAndSubClassRows } from "../../common-fields/custom-class-and-sub-class-field.const";
import { beneficiaryRow } from "../../common-fields/beneficiary-field.const";

const sectionGeneralSole = (withSponsor, valueLabel, valueDescription, isRentalSole, updateValue,updateField,isEditForm, labelSubtext) => {
    const { TITLE_DESCRIPTION} = getTranslations().new_item_modal.real_estate.common;
    const {NAME_DESCRIPTION} = getTranslations().new_item_modal.real_estate.rental_property.owned_with_partners;
    const { sole_ownership } = getTranslations().new_item_modal.real_estate.rental_property; 
    return {
        title:'General info',
        rows:[
            ...(withSponsor ? masterInstitutionInputRows('Sponsor / Operator',NAME_DESCRIPTION, 4, false, updateValue , !isEditForm) : []),
            {fields:[
                {key:'title',label:'Property name', description: TITLE_DESCRIPTION, validations:[isRequired]}
            ]},
            {fields:[
                {key:'startDate', label: 'Purchase date',
                description: sole_ownership.START_DATE_DESCRIPTION, 
                validations:[isRequired],
                // defaultValueFn: ()=>{return new Date()},
                disabledDate: disabledMoreThanToday,
                onChange:({value,formValues})=> {
                    updateField('capitalCalls', { startDate: value });
                    updateField('expectedAnnualRevenue', { startDate: value });
                    updateField('expectedAnnualExpense',{startDate: value });
                    updateValue('expectedEndDate', moment(value).add(5, 'year'));
                    updateValue('expectedDistributionStartDate', moment(value).add(1, 'month'));
                    // if (!formValues.isExpectedAnnualRevenueTouched && Array.isArray(formValues.expectedAnnualRevenue)){
                    //     const years = [...Array(6).keys()];
                    //     const valueForYears = formValues.expectedAnnualCashReturn ? (
                    //         formValues.distributionReinvested ? formValues.expectedAnnualCashReturn : (formValues.cashInvested / 100 * formValues.expectedAnnualCashReturn )
                    //     ) : '';
                            
                    //     const defaultScheduleValues = years.map(year=>({
                    //         value: year === 5 ? 0 : valueForYears,
                    //         date: moment(value).add(1,"M").add(year,'y').set("D",15).startOf("D"),
                    //         remarks: year === 5  ? '' : "Year " + (+year+1),
                    //         tempId: +year+1
                    //     }));
                    //     updateValue('expectedAnnualRevenue', defaultScheduleValues);
                    // }
                }
             }
            ]},
            {fields:[
                {key:'value' , type: 'numberWithHoldings' ,  validations:[isRequired],
                label: valueLabel, // withSponsor ? 'Position value' : 'Current Property Value' ,
                labelSubtext: labelSubtext,
                description: valueDescription ,
                onBlur:({value,formValues})=>{
                    const currencySymbol = CURRENCY_SYMBOL[formValues.currency] || 'USD';
                    updateValue('cashInvested',value);
                    updateField('expectedAnnualCashReturn', {amount: value, 
                        description: `The total amount you expect to receive on this investment annually. The percentage rate is based on your asset's initial investment ${currencySymbol}${value}`});
                    updateField('expectedAnnualRevenue',{relativeAmount: value});
                    updateField('expectedAnnualExpense',{relativeAmount: value});
                    updateValue('expectedSalePrice', value);
                },generatePropsFn : (values)=>{
                    return {prefix: CURRENCY_SYMBOL[values.currency] || ''}
                }},
                {
                    key:'cashInvested', 
                    isHidden:true,
                    defaultValueFn:(values)=>values.value
                },
                {key:'currency',size:0.3,onChange:({value,formValues})=>{
                    const currencySymbol = CURRENCY_SYMBOL[formValues.currency] || 'USD';
                    updateValue('cashInvestedCurrency',value);
                    updateField('value',{prefix:CURRENCY_SYMBOL[value] || ''});
                    updateField('cashInvested',{prefix:CURRENCY_SYMBOL[value] || ''});
                    updateField('expectedMonthlyExpenses',{prefix:CURRENCY_SYMBOL[value] || ''});
                    updateField('monthlyRent',{prefix:CURRENCY_SYMBOL[value] || ''});
                    updateField('expectedAnnualRevenue',{prefix:CURRENCY_SYMBOL[value] || ''});
                    updateField('expectedAnnualExpense',{prefix:CURRENCY_SYMBOL[value] || ''});
                    updateField('totalCommittedCapital',{prefix:CURRENCY_SYMBOL[value] || ''});
                    updateField('capitalCalls',{prefix:CURRENCY_SYMBOL[value] || ''});
                    updateField('expectedSalePrice',{prefix:CURRENCY_SYMBOL[value] || ''});
                    updateField('expectedAnnualCashReturn', { prefix: CURRENCY_SYMBOL[value] || '', 
                            placeholder: CURRENCY_SYMBOL[value] || '', 
                            description: `The total amount you expect to receive on this investment annually. The percentage rate is based on your asset's initial investment ${currencySymbol}${formValues.cashInvested}`});
                    
                }}
            ]},
            ...( isEditForm ? [
                ...customClassAndSubClassRows({updateValue,updateField}),
            ] : [
                {
                    fields:[
                        {key:'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isHidden:true,
                        validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                        validations:[isBetweenZeroAndHundred],
                            onBlur:({value})=>{
                                updateField('value',{holdings:value || ''});
                                updateField('monthlyRent',{holdings:value || ''});
                                updateField('expectedMonthlyExpenses',{holdings:value || ''});
                                updateField('expectedSalePrice',{holdings:value || ''});
                                updateField('expectedAnnualRevenue',{holdings:value || ''});
                                updateField('expectedAnnualExpense',{holdings:value || ''});
                                updateField('capitalCalls',{holdings:value || ''});
                                updateField('totalCommittedCapital',{holdings:value || ''});
                            },
                            generatePropsFn : (values)=>{
                                return {isHidden: values.holdings === 100}
                            }
                        }
                    ]
                },
            {
                fields:[
                    {type:'checkbox', key:'isPartialHolding',checkboxLabel: 'This asset is 100% owned by me', description: 'If you are a co-owner of this property, uncheck this box.' ,
                    fieldCustomStyle: {marginTop:'-24px'},
                    defaultValue: true,
                        onChange: ({value})=>{
                            const newHoldingValue = value ? 100 : 50;
                            updateValue('holdings', newHoldingValue);
                            updateField('holdings', { isHidden: value });
                            updateField('value', { holdings: newHoldingValue });
                            updateField('capitalCalls', { holdings: newHoldingValue });
                            updateField('totalCommittedCapital', { holdings: newHoldingValue });
                            updateField('monthlyRent',{holdings:newHoldingValue});
                            updateField('expectedMonthlyExpenses',{holdings:newHoldingValue});
                            updateField('expectedSalePrice',{holdings:newHoldingValue});
                            updateField('expectedAnnualRevenue',{holdings:newHoldingValue});
                            updateField('expectedAnnualExpense',{holdings:newHoldingValue});
                        }
                    },
                ]
            },
            ]),
            // {fields:[
            //     {key:'startDate', label: 'Purchase date',
            //     description: sole_ownership.START_DATE_DESCRIPTION, 
            //     validations:[isRequired],
            //     // defaultValueFn: ()=>{return new Date()},
            //     disabledDate: disabledMoreThanToday,
            //     onChange:({value,formValues})=> {
            //         updateField('capitalCalls', { startDate: value });
            //         updateField('expectedAnnualRevenue', { startDate: value });
            //         updateField('expectedAnnualExpense',{startDate: value });
            //         updateValue('expectedEndDate', moment(value).add(5, 'year'));
            //         updateValue('expectedDistributionStartDate', moment(value).add(1, 'month'));
            //         // isExpectedAnnualRevenueTouched,isExpectedAnnualExpenseTouched
            //         // if (!formValues.isExpectedAnnualRevenueTouched && Array.isArray(formValues.expectedAnnualRevenue)){}
            //         if (isRentalSole){
            //             if (!formValues.isExpectedAnnualRevenueTouched && !isEditForm){
            //                 const defaultScheduleValues = [{ value: "", date: moment(value).add(1,"M").set("D",15).utc(), remarks: '', tempId: 1}];
            //                 updateValue('expectedAnnualRevenue', defaultScheduleValues);
            //             }
            //             if (!formValues.isExpectedAnnualExpenseTouched && !isEditForm){
            //                 const defaultScheduleValues = [{ value: "", date: moment(value).add(1,"M").set("D",15).utc(), remarks: '', tempId: 1}];
            //                 updateValue('expectedAnnualExpense', defaultScheduleValues);
            //             }
            //         } else {
            //             if (!formValues.isExpectedAnnualRevenueTouched && Array.isArray(formValues.expectedAnnualRevenue)){
            //                 const years = [...Array(6).keys()];
            //                 const valueForYears = formValues.expectedAnnualCashReturn ? (
            //                     formValues.distributionReinvested ? formValues.expectedAnnualCashReturn : (formValues.cashInvested / 100 * formValues.expectedAnnualCashReturn )
            //                 ) : '';
                                
            //                 const defaultScheduleValues = years.map(year=>({
            //                     value: year === 5 ? 0 : valueForYears,
            //                     date: moment(value).add(1,"M").add(year,'y').set("D",15).startOf("D"),
            //                     remarks: year === 5  ? '' : "Year " + (+year+1),
            //                     tempId: +year+1
            //                 }));
            //                 updateValue('expectedAnnualRevenue', defaultScheduleValues);
            //             }
            //         }
                    
            //     }
            //  }
            // ]},
        ]
    }
}

const distributionScheduleSection = (updateValue,updateField,isEditForm,expectedAnnualRevenueLabel,expectedAnnualRevenueDescription,expectedDistributionPeriodLabel,expectedDistributionPeriodDescription) => {

    return {
        key: 'distributionScheduleProps',
        title:'Target cash distribution',
        fullWidth:true,
        generatePropsFn : (values)=>{
            return {
                isHidden: !isEditForm ? false : !(values.expectedAnnualRevenue ? values.expectedAnnualRevenue.length > 0 : false ),
                title: values.distributionReinvested ? 'Target reinvested distribution' : 'Target cash distribution' 
            }
        },
        rows: [
            {fields:[
                {key:'expectedAnnualCashReturn', 
                    label: expectedAnnualRevenueLabel, description: expectedAnnualRevenueDescription,
                    type:'percentage',
                    withNumber: true,
                    generatePropsFn : (values)=> {
                        const currencySymbol = CURRENCY_SYMBOL[values.currency] || 'USD'
                        return {
                            prefix: currencySymbol, placeholder: CURRENCY_SYMBOL[values.currency],holdings:values.holdings,
                            amount: values.cashInvested || values.value,
                            description: `The total amount you expect to receive on this investment, each year.\nThe percentage rate is based on ${currencySymbol}${values.cashInvested} - the asset initial investment as entered`
                        }
                    },
                    onBlur: ({value, formValues}) => {
                        updateField('expectedAnnualRevenue', { expectedAnnualPercentage: value });
                        if (!formValues.isExpectedAnnualRevenueTouched){
                            const updatedScheduleValues = formValues.expectedAnnualRevenue?.map(row => {
                                const newValue = ( formValues.cashInvested && value ? (
                                        formValues.distributionReinvested ? 
                                        value : 
                                        (formValues.cashInvested / 100 * value )    
                                    ) : ''  );
                                return { ...row, value: newValue }
                            })
                            updateValue('expectedAnnualRevenue', updatedScheduleValues);
                        }
                    }
                },
            ]},
            {fields:[
                {key:'expectedDistributionPeriod', label: expectedDistributionPeriodLabel, description: expectedDistributionPeriodDescription,
                type:'select',
                withDescriptionInOption: true,
                defaultValue: 'Monthly',
                withSuffixIcon: true,
                options: [
                    {value:'Monthly',label:'Monthly'},
                    {value:'Quarterly',label:'Quarterly', description:'Transactions date: Apr, Jul, Oct, Jan (next year).' , selectedDescription:'Jan, Apr, Jul, Oct each year'},
                    {value:'Semi-annually',label:'Semi-annually', description:'Transactions date: Jul and Jan (next year).' , selectedDescription:'Apr, Jul each year'},
                    {value:'Annually',label:'Annually', description:'Transactions date: Jan (next year).', selectedDescription:'January each year'},
                    {value:'Custom',label:'Custom...', },
                ],
                onChange: ({value, formValues}) => {
                    updateField('expectedDistributionStartDate', { isHidden: value !== 'Custom' });
                    updateField('expectedDistributionCustomInterval', { isHidden: value !== 'Custom' });
                    updateField('expectedDistributionCustomPeriod', { isHidden: value !== 'Custom' });
                    updateField('expectedAnnualRevenue', { 
                        monthsPerPeriod: monthsPerPeriodMap[value] ? monthsPerPeriodMap[value] : formValues.expectedDistributionCustomInterval || 1,
                        period: value === 'Custom' ? 'Periodic' : value
                    });
                },
            }
            ]},
            {fields:[
                {key:'expectedDistributionStartDate', clearable: true ,  label:'Transactions start date', description:`Enter the frequency's first payment date.`, type:'date',
                    generatePropsFn: (formValues) => {
                        return { isHidden: formValues.expectedDistributionPeriod !== 'Custom' }
                    },
                    onChange:({value , formValues})=>{
                        updateField('expectedAnnualRevenue',{startDate: value ? value : formValues.startDate});
                        if (!formValues.isExpectedAnnualRevenueTouched){
                            const updatedScheduleValues = formValues.expectedAnnualRevenue.map((item,index)=>{
                                return {...item,date :  moment(value).add(index,'y').set("D",15).startOf("D")}
                            });
                            updateValue('expectedAnnualRevenue', updatedScheduleValues);
                        }
                    }
                }
            ]},
            {fields:[
                {key:'expectedDistributionCustomInterval', label:'Repeat every:', type:'number', defaultValue:2, decimalScale: 0, withArrows:true,
                maxValue: 15, minValue: 0,
  
                generatePropsFn: (formValues) => {
                    return { isHidden: formValues.expectedDistributionPeriod !== 'Custom' }
                },
                onChange: ({value, formValues}) => {
                    updateField('expectedAnnualRevenue', {monthsPerPeriod: value * ( formValues.expectedDistributionCustomPeriod === 'Years' ? 12 : 1 ) })  
                },
                validations:[
                    {
                        fn: (value) => {
                            return value < 16;
                        },
                        message:'Maximum period allowed: 15'
                    }
                ]},
                {key:'expectedDistributionCustomPeriod', type:'select', defaultValue:'Months',
                withDescriptionInOption: true,
                withSuffixIcon: true,
                generatePropsFn: (formValues) => {
                    return { isHidden: formValues.expectedDistributionPeriod !== 'Custom' }
                },
                onChange: ({value, formValues}) => {
                    updateField('expectedAnnualRevenue', {monthsPerPeriod: formValues.expectedDistributionCustomInterval * ( value === 'Years' ? 12 : 1 ) })  
                },
                options:[
                    // {value: 'Weeks', label:'Weeks'},
                    {value: 'Months', label:'Months'},
                    {value: 'Years', label:'Years'},
                ]},
            ]}
        ]
    }
}

const monthsPerPeriodMap = {
    'Monthly' : 1,
    'Quarterly' : 3,
    'Semi-annually' : 6,
    'Annually' : 12
}

const distributionScheduleTableSection = (updateValue,updateField,isEditForm,expectedAnnualRevenueLabel,expectedAnnualRevenueDescription) => {

    return {
        key: 'distributionScheduleTable',
        fullWidth:true,
        
        generatePropsFn : (values)=>{
            return {
                isHidden: !isEditForm ? false : !(values.expectedAnnualRevenue ? values.expectedAnnualRevenue.length > 0 : false ),
                title: values.distributionReinvested ? 'Target reinvested distribution schedule' : 'Target cash distribution schedule'
            }
        },
        rows: [
            {fields:[
                {
                    key:'expectedAnnualRevenue', 
                    label: '',  
                    // label: expectedAnnualRevenueLabel, 
                    // description: expectedAnnualRevenueDescription,
                    type:'scheduleTable',
                    defaultValue: [],
                    onChange: ({value, formValues}) => {
                        if (!formValues.isExpectedAnnualRevenueTouched){
                            updateValue('isExpectedAnnualRevenueTouched' , true);
                        }
                    },
                    showHistory: false,
                    showPercentage: true,
                    generatePropsFn : (values)=> {
                        const exPer = values.expectedDistributionPeriod;
                        return {
                            prefix: CURRENCY_SYMBOL[values.currency] || 'USD', 
                            placeholder: CURRENCY_SYMBOL[values.currency],
                            holdings:values.holdings , 
                            startDate: values.startDate,
                            relativeAmount: values.cashInvested || values.value,
                            // isPercentageDisplay: values.distributionReinvested,
                            monthsPerPeriod: monthsPerPeriodMap[values.expectedDistributionPeriod] ? monthsPerPeriodMap[values.expectedDistributionPeriod] : (values.expectedDistributionCustomInterval || 1 ),
                            period: !exPer ? 'Monthly' : exPer === 'Custom' ? 'Periodic' : exPer
                        }
                    },
                    validations: [
                        {
                          fn: (value, formValues) => {
                            return Array.isArray(value) ? (value.length === 0 || !value.some(item=> isNullOrUndefinedOrEmptyString(item.value)) ) : true ; 
                          },
                          // message: 'Empty value for a row is not valid',
                          message: ' ',
                          bottomErrorMessage: (value, formValues) => {
                            return `Empty value for a row is not valid`; // `must be equal to ${formValues.transactionValue}.`
                          },
                        },
                    ]
                },
                {
                    key:'isExpectedAnnualRevenueTouched', 
                    isHidden:true,
                    defaultValue : isEditForm
                },
            ]},
        ]
    }
}

const capitalCallsSection = (updateValue,updateField, isSyndication) => {
    return {
        title:'Capital calls',
        titleId: 'capital_calls_table_label',
        fullWidth:true,
        onLoad: (values) => {
            if (!values.totalCommittedCapital && (!values.capitalCalls || values.capitalCalls.length === 0)){
                updateField('totalCommittedCapital',{isHidden: true});
                updateField('capitalCalls',{showPrevComp: true});
            }
        },
        rows:[
                {fields:[
                    {key:'totalCommittedCapital', type: 'numberWithHoldings', label: "Total committed capital" , 
                        description: 'The full amount you committed to invest',
                        onBlur:({value,formValues})=>{
                            updateField('capitalCalls',{relativeAmount: value});
                        },generatePropsFn : (values)=>{
                            return {
                                prefix: CURRENCY_SYMBOL[values.currency] || '',
                                isHidden: !values.totalCommittedCapital && (!values.capitalCalls || values.capitalCalls.length === 0), 
                                holdings: values.holdings }
                        },
                        setFnForSave: (isDistribution, value, formValues) => {
                            return !value ? 0 : value;
                        },
                    },
                ]},
                {fields:[
                    {key:'capitalCalls', label: 'Capital calls', 
                    description: 'The amount you committed, divided into several "call events".',
                    type:'singleEventScheduler',
                    showTotals: isSyndication,
                    showTotalsWarnings: isSyndication,
                    defaultValue:[],
                    generatePropsFn : (values)=> {
                        return {
                            prefix: CURRENCY_SYMBOL[values.currency] || 'USD',
                            placeholder: CURRENCY_SYMBOL[values.currency] ,
                            relativeAmount: values.totalCommittedCapital || 0,
                            startDate: values.startDate,
                            showPrevComp: !values.totalCommittedCapital && (!values.capitalCalls || values.capitalCalls.length === 0),
                            holdings: values.holdings}
                    },
                    showPercentage: true,
                    percentageTooltip: `Percentage of the 'Total committed capital'`,
                    onShow: ({ values }) => {
                        updateField('totalCommittedCapital', { isHidden: false });

                        if (isSyndication) {
                            updateValue('totalCommittedCapital', values.value);
                            updateValue('value', 0);
                            updateValue('cashInvested', 0);
                            updateValue('capitalCalls', [{ value: values.value, date: moment(values.startDate), remarks: "Initial investment", tempId: 1 }]);
                            updateField('capitalCalls',{relativeAmount: values.value});
                        } else {
                            updateValue('capitalCalls', [{ value: "", date: moment(values.startDate).add(1, 'month').set("D", 15), remarks: "", tempId: 1 }]);
                        }
                    },
                    validations: [
                        {
                          fn: (value) => {
                            for(let i = 0, l= value?.length; i < l; i++){
                                if(isNullOrUndefinedOrEmptyStringOrNan(Date.parse(value[i].date)) || isNullOrUndefinedOrEmptyStringOrNan(value[i].value)){
                                    return false;
                                }
                            }
                            return true; 
                          },
                          message: ' ',
                          bottomErrorMessage: () => {
                            return `Empty value for a row is not valid`;
                          },
                        },
                    ],
                    ClickToShowComponent:SingleEventSchedulerPrevComponent
                    },
                ]},

        ]
    }
}

const moreInfo = (updateValue,updateField,isEditForm,withType,withHoldings,beneficiaryLabel,beneficiaryDescription) => {
    const {ADDRESS_DESCRIPTION, BENEFICIARY_LABEL, BENEFICIARY_DESCRIPTION} = getTranslations().new_item_modal.real_estate.common;
    return {    
        title: 'More info', 
        rows:[
            {fields:[
                {key:'address', label: 'Property address', description: ADDRESS_DESCRIPTION}
            ]},
            beneficiaryRow({
                label: beneficiaryLabel || BENEFICIARY_LABEL,
                description: beneficiaryDescription || BENEFICIARY_DESCRIPTION,
            }),
            ...(isEditForm ? [
                containerRow({isEditForm, updateField}),
                ...(withHoldings ? [
                    {
                        fields:[
                            {key:'holdings',  label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', 
                            isLocked: true,validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                            validations:[isBetweenZeroAndHundred],
                            UnlockComponent:HoldingsUnlockModal,
                            onBlur:({value})=>{
                                updateField('monthlyRent',{holdings:value || ''});
                                updateField('expectedMonthlyExpenses',{holdings:value || ''});
                                updateField('expectedSalePrice',{holdings:value || ''});
                                updateField('expectedAnnualRevenue',{holdings:value || ''});
                                updateField('capitalCalls',{holdings:value || ''});
                                updateField('totalCommittedCapital',{holdings:value || ''});
                            },}
                        ]
                    },
                ] : []),
                {
                    fields:[
                        {key:'defaultCashAccountId'}
                    ]
                },
            ] : []),
            ...(withType ? [
                {
                    fields:[
                        {key: 'propertyType'},
                    ]
                }
            ] : []),
            ...(isEditForm ? [
                riskLevelRow({updateField}),
                liquidityRow({updateField}),
            ] : []),
            {fields:[
                {key:'remarks'}
            ]}
        ]
    }
}

export const createRealEstateRentalPropertySoleOwnershipAddItemSections = (isEditForm) => {
    return ({updateValue,updateField,updateSection}) => {
        const { sole_ownership } = getTranslations().new_item_modal.real_estate.rental_property;
        return ([
            sectionGeneralSole(false, 'Purchase value',sole_ownership.VALUE_DESCRIPTION, true, updateValue,updateField,isEditForm),
            {
                title:'Projection',
                generatePropsFn : (values)=>{
                    return {
                        isHidden: !(values.monthlyRent ||  values.expectedMonthlyExpenses),
                    }
                },
                rows:[
                    {fields:[
                        {key:'monthlyRent', description: sole_ownership.MONTHLY_RENT_DESCRIPTION, type:'numberWithHoldings',
                        generatePropsFn : (values)=>{
                            return {prefix: CURRENCY_SYMBOL[values.currency] || 'USD',holdings:values.holdings}
                        }},
                    ]},
                    {fields:[
                        {key:'expectedMonthlyExpenses', description: sole_ownership.EXPECTED_MONTHLY_EXPENSES_DESCRIPTION, type:'numberWithHoldings',
                        generatePropsFn : (values)=>{
                            return {prefix: CURRENCY_SYMBOL[values.currency] || 'USD',holdings:values.holdings}
                        }},
                    ]},
                    {fields:[
                        {key:'expectedSalePrice',
                        label: sole_ownership.EXPECTED_SALE_PRICE_LABEL, description: sole_ownership.EXPECTED_SALE_PRICE_DESCRIPTION,type:'numberWithHoldings',
                        onBlur: ({value, formValues}) => {
                            if (!isNullOrUndefinedOrZeroOrEmptyString(value) && value > formValues.value && !isNullOrUndefined(formValues.expectedEndDate) && !isNullOrUndefined(formValues.startDate)) {
                                const holdingYears = moment(formValues.expectedEndDate).diff(moment(formValues.startDate),'M') / 12;
                                const increaseInValue = Math.pow(value / formValues.value, 1/holdingYears) - 1;
                                updateValue('annualIncreaseInValue', floatFixed(increaseInValue * 100, 2));
                            }
                        },
                        generatePropsFn : (values)=>{
                            return {prefix: CURRENCY_SYMBOL[values.currency] || 'USD',holdings:values.holdings}
                        }}
                    ]},
                    {fields:[
                        {key:'annualIncreaseInValue', description: sole_ownership.EXPECTED_ANNUAL_INCREASE_IN_VALUE  }
                    ]},
                    {fields:[
                        {key:'expectedEndDate', label: sole_ownership.EXPECTED_END_DATE_LABEL, description: sole_ownership.EXPECTED_END_DATE_DESCRIPTION,
                        onChange: ({value, formValues}) => {
                            if (!isNullOrUndefinedOrZeroOrEmptyString(formValues.expectedSalePrice) && formValues.expectedSalePrice > formValues.value && !isNullOrUndefined(value) && !isNullOrUndefined(formValues.startDate)) {
                                const holdingYears = moment(value).diff(moment(formValues.startDate),'M') / 12;
                                const increaseInValue = Math.pow(formValues.expectedSalePrice / formValues.value, 1/holdingYears) - 1;
                                updateValue('annualIncreaseInValue', floatFixed(increaseInValue * 100, 2));
                            }
                        }}
                    ]},
                ]
            },
            {
                title:'Cash flow projection',
                generatePropsFn : (values)=>{
                    return {
                        isHidden: (values.monthlyRent ||  values.expectedMonthlyExpenses),
                    }
                },
                rows:[
                    {fields:[
                        {key:'expectedDistributions', type:'radio', label: () => <div>Do you have any expected <span style={{fontWeight:700}}>rental income</span>?</div>, 
                        defaultValueFn: (values) => { return !isEditForm ? false : (values.expectedAnnualRevenue ? values.expectedAnnualRevenue.length > 0 : false )}, 
                        // defaultValue:  true,
                        onChange: ({value}) => {
                            updateSection('distributionScheduleTable', { isHidden: !value });
                        },
                        optionType: 'button',
                        options:[
                            {label: 'Yes', value: true},
                            {label: 'No', value: false}
                        ],
                    }
                    ]},
                    {fields:[
                        {key:'expectedContributions', type:'radio', label: () => <div>Do you have any expected <span style={{fontWeight:700}}>expenses</span>?</div>, 
                        defaultValueFn: (values) => { return !isEditForm ? false : (values.expectedAnnualExpense ? values.expectedAnnualExpense.length > 0 : false )},
                        onChange: ({value}) => {
                            updateSection('contributionsScheduleTable', { isHidden: !value });
                        },
                        // onChange: ({value, formValues}) => {
                        //     resetExpectedDistributionPeriod({updateValue, updateField});
                        //     if (value) { // yes
                        //         updateValue('expectedAnnualRevenue', [{ value: '', date: moment(formValues.startDate).utc(), remarks: '', tempId: 1}])
                        //         updateField('expectedAnnualRevenue', {startDate: moment(value)})
                        //     } else { // no
                        //         updateValue('expectedAnnualRevenue', []);
                        //         updateField('expectedAnnualRevenue', {
                        //             customAnnualReturnToolTipText: `The total amount you expect to receive on this investment annually. 
                        //             The percentage rate is based on your asset's initial investment ${(CURRENCY_SYMBOL[formValues.currency] + formValues.cashInvested) || ''}`
                        //         });
                        //     }
                        //     updateField('expectedDistributionPeriod', {isHidden: !value})
                        //     updateField('expectedAnnualRevenue', {isHidden: !value})
                        // },
                        optionType: 'button',
                        options:[
                            {label: 'Yes', value: true},
                            {label: 'No', value: false}
                        ],
                    }
                    ]},
                ]
            },
            rentScheduleSection(updateValue,updateField,isEditForm),
            expensesScheduleSection(updateValue,updateField,isEditForm),
            {
                title:'Projection',
                generatePropsFn : (values)=>{
                    return {
                        isHidden: (values.monthlyRent ||  values.expectedMonthlyExpenses),
                    }
                },
                rows:[
                    {fields:[
                        {key:'expectedSalePrice',
                        label: sole_ownership.EXPECTED_SALE_PRICE_LABEL, description: sole_ownership.EXPECTED_SALE_PRICE_DESCRIPTION,type:'numberWithHoldings',
                        onBlur: ({value, formValues}) => {
                            if (!isNullOrUndefinedOrZeroOrEmptyString(value) && value > formValues.value && !isNullOrUndefined(formValues.expectedEndDate) && !isNullOrUndefined(formValues.startDate)) {
                                const holdingYears = moment(formValues.expectedEndDate).diff(moment(formValues.startDate),'M') / 12;
                                const increaseInValue = Math.pow(value / formValues.value, 1/holdingYears) - 1;
                                updateValue('annualIncreaseInValue', floatFixed(increaseInValue * 100, 2));
                            }
                        },
                        generatePropsFn : (values)=>{
                            return {prefix: CURRENCY_SYMBOL[values.currency] || 'USD',holdings:values.holdings}
                        }}
                    ]},
                    {fields:[
                        {key:'annualIncreaseInValue', description: sole_ownership.EXPECTED_ANNUAL_INCREASE_IN_VALUE  }
                    ]},
                    {fields:[
                        {key:'expectedEndDate', label: sole_ownership.EXPECTED_END_DATE_LABEL, description: sole_ownership.EXPECTED_END_DATE_DESCRIPTION,
                        onChange: ({value, formValues}) => {
                            if (!isNullOrUndefinedOrZeroOrEmptyString(formValues.expectedSalePrice) && formValues.expectedSalePrice > formValues.value && !isNullOrUndefined(value) && !isNullOrUndefined(formValues.startDate)) {
                                const holdingYears = moment(value).diff(moment(formValues.startDate),'M') / 12;
                                const increaseInValue = Math.pow(formValues.expectedSalePrice / formValues.value, 1/holdingYears) - 1;
                                updateValue('annualIncreaseInValue', floatFixed(increaseInValue * 100, 2));
                            }
                        }}
                    ]},
                ]
            },
            moreInfo(updateValue,updateField,isEditForm,true,true)
        ])
    }
}

export const createRealEstateRentalPropertyWithPartnersAddItemSections = (isEditForm) => {
    return ({updateValue,updateField,updateSection}) => {
        const { owned_with_partners } = getTranslations().new_item_modal.real_estate.rental_property;
        const labelSubtext = ()=> <span>A <strong>capital call</strong> schedule can be added later</span>
        return ([
        sectionGeneralSole(true,owned_with_partners.VALUE_LABEL,owned_with_partners.VALUE_DESCRIPTION,false,updateValue,updateField,isEditForm, labelSubtext),
        capitalCallsSection(updateValue,updateField,true), 
        {
            title: 'Projection',
            rows: [
                {fields:[
                    {key:'expectedEndDate', description: owned_with_partners.EXPECTED_END_DATE_DESCRIPTION,
                        onChange:({value,formValues})=> {
                            if (!formValues.isExpectedAnnualRevenueTouched && value){
                                const yearsFromStartToEndDate = moment(value).diff(moment(formValues.startDate),"y") + ( moment(value).month() === moment(formValues.startDate).month() ? 0 : 1 );
                                const years = [...Array(yearsFromStartToEndDate+1).keys()];
                                const valueForYears = formValues.expectedAnnualCashReturn ? (
                                    formValues.distributionReinvested ? formValues.expectedAnnualCashReturn : (formValues.cashInvested / 100 * formValues.expectedAnnualCashReturn )
                                ) : '';
        
                                const scheduleValues = years.map(year=>({
                                    value: year === yearsFromStartToEndDate ? 0 : valueForYears,
                                    date: year === yearsFromStartToEndDate ? moment(value).add(1,"M").set("D",15).startOf("D") : moment(formValues.startDate).add(1,"M").add(year,'y').set("D",15).startOf("D"),
                                    remarks: year === yearsFromStartToEndDate ? '' : "Year " + (+year+1),
                                    tempId: +year+1
                                }));
                                updateValue('expectedAnnualRevenue', scheduleValues);
                            }
                        },
                        isHidden: isEditForm,
                        setFnForSave: (isDistribution, value, formValues) => {
                            return isEditForm ? ( formValues?.exitEvent?.date || null ) : value
                        },
                    },{ isHidden:true, key:'cashflowEventsAfterExit' ,  setFnForSave: (isDistribution, value, formValues) => {
                        return isEditForm ? !formValues?.exitEvent?.stopCreatingFutureEventsAfterExitEvent : false
                    },}
                ]},
                {fields:[
                    {key:'expectedIRR', label: 'Expected IRR', description: owned_with_partners.EXPECTED_IRR_DESCRIPTION, defaultValue:0,
                    onBlur:({value,formValues}) => {
                        const { startDate, expectedEndDate } = formValues;
                        if (!isNullOrUndefinedOrZeroOrEmptyString(value)) {
                            const numberOfYears = (monthDiff(new Date(startDate), new Date(expectedEndDate)) / 12)
                            const updatedExpectedMultiplier = (1 + (value / 100)) ** numberOfYears;
                            updateValue('expectedMultiplier', floatFixed(updatedExpectedMultiplier, 2));
                        }
                    }
                }
                ]},
                {fields:[
                    {key:'expectedMultiplier', description: owned_with_partners.EXPECTED_MULTIPLIER_DESCRIPTION}
                ]},
                {fields:[
                    {key:'expectedDistributions', type:'radio', label: 'Do you have expected distributions?', 
                    defaultValueFn: (values) => { return !isEditForm ? true : (values.expectedAnnualRevenue ? values.expectedAnnualRevenue.length > 0 : false )}, 
                    // defaultValue:  true,
                    onChange: ({value}) => {
                        // updateValue('distributionReinvested', false);
                        // updateField('distributionReinvested', { isHidden: !value, value: false });
                        updateSection('distributionScheduleProps', { isHidden: !value });
                        updateSection('distributionScheduleTable', { isHidden: !value });
                        if (!value) {
                            updateValue('expectedAnnualRevenue', []);
                        }
                    },
                    optionType: 'button',
                    options:[
                        {label: 'Yes', value: true},
                        {label: 'No', value: false}
                    ],
                }
                ]},
                // ...(!isEditForm ? [{fields:[
                //     {isHidden: true , key:'exitEvent' ,  defaultValue: {isAutoCalculated:true}}
                // ]}] : []),
                // {fields:[
                //     {key:'distributionReinvested', label: '', checkboxLabel: 'My distributions are reinvested' , type:'checkbox' , 
                //     onChange:({value, formValues})=> {
                //         updateValue('isDistributionsByPercentage', value);
                //         updateField('expectedAnnualRevenue', { isPercentageDisplay: value });
                //         updateField('expectedAnnualCashReturn', { withNumber: !value });
                //         updateSection('distributionScheduleTable' , { title : value ? 'TARGET REINVESTED DISTRIBUTION' : 'Target cash distribution schedule'});
                      
                //         // update distributions schedule values to percentages / fixed number;
                //         const updatedScheduleValues = formValues.expectedAnnualRevenue?.map(row => {
                //         const newValue = row.value === '' ? (
                //                 formValues.cashInvested && formValues.expectedAnnualCashReturn ? (
                //                     value ?    
                //                     parseFloat((formValues.expectedAnnualCashReturn / formValues.cashInvested * 100).toFixed(2)) : 
                //                     formValues.cashInvested / 100 * formValues.expectedAnnualCashReturn
                //                 ) : ''  
                //             ) :( value ?
                //                 parseFloat((row.value / formValues.cashInvested * 100).toFixed(2)) :
                //                 (formValues.cashInvested / 100 * row.value) );
                //             return { ...row, value: newValue }
                //             })
                //         updateValue('expectedAnnualRevenue', updatedScheduleValues);
                //     }},
                //     {
                //         key:'isDistributionsByPercentage', 
                //         isHidden:true,
                //         defaultValue : false
                //     },
                // ]},
            ]
        },
        // {
        //     title:'Projected cashflow - distributions',
        //     fullWidth:true,
        //     // padding:'24px 96px',
        //     rows: [
        //         {fields:[
        //             {
        //                 key:'expectedAnnualRevenue', label: owned_with_partners.EXPECTED_ANNUAL_REVENUE_LABEL, description: owned_with_partners.EXPECTED_ANNUAL_REVENUE_DESCRIPTION,
        //                 type:'scheduleTable',
        //                 defaultValue:[],
        //                 showHistory: false,
        //                 generatePropsFn : (values)=> {
        //                     return {prefix: CURRENCY_SYMBOL[values.currency] || 'USD', placeholder: CURRENCY_SYMBOL[values.currency],holdings:values.holdings}
        //                 },
        //             },
        //         ]},
        //         {fields:[
        //             {key:'expectedDistributionPeriod', label: owned_with_partners.EXPECTED_DISTRIBUTION_PERIOD_LABEL, description: owned_with_partners.EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION}
        //         ]},
        //     ]
        // },
        distributionScheduleSection(updateValue,updateField,isEditForm,owned_with_partners.EXPECTED_ANNUAL_REVENUE_LABEL,owned_with_partners.EXPECTED_ANNUAL_REVENUE_DESCRIPTION,owned_with_partners.EXPECTED_DISTRIBUTION_PERIOD_LABEL,owned_with_partners.EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION),
        distributionScheduleTableSection(updateValue,updateField,isEditForm,owned_with_partners.EXPECTED_ANNUAL_REVENUE_LABEL,owned_with_partners.EXPECTED_ANNUAL_REVENUE_DESCRIPTION),
        exitEventRowSection(updateValue,updateField,isEditForm),
        
        // {
        //     title:'Projected Performance',
        //     rows:[
                
        //         {fields:[
        //             {key:'expectedMultiplier', description: owned_with_partners.EXPECTED_MULTIPLIER_DESCRIPTION}
        //         ]},
        //     ]
        // // },{
        // //     title: 'Performance', 
        // //     rows:[
        // //         {fields:[
        // //             {key:'startDate', label: 'Initial investment date ',
        // //             description: owned_with_partners.START_DATE_DESCRIPTION,
        // //             defaultValueFn: ()=>{return new Date()},
        // //             disabledDate: disabledMoreThanToday }
        // //         ]},
        // //         {fields:[
        // //                 {key:'cashInvested', label: owned_with_partners.CASH_INVESTED_LABEL ,prefix:CURRENCY_SYMBOL['USD'],
        // //                 description: owned_with_partners.CASH_INVESTED_DESCRIPTION},
        // //                 {key:'cashInvestedCurrency',size:0.3 , label: ' ', defaultValue: 'USD'}
        // //         ]},
        // //     ]
        // },
        moreInfo(updateValue,updateField,isEditForm,true,true,null, owned_with_partners.BENEFICIARY_DESCRIPTION),
    ])};
} 

export const createRealEstateDevelopmentSoleOwnershipAddItemSections = (isEditForm) => {
    return ({updateValue,updateField})  => {
        const { sole_ownership } = getTranslations().new_item_modal.real_estate.development;
        return ([
        sectionGeneralSole(false, sole_ownership.VALUE_LABEL, sole_ownership.VALUE_DESCRIPTION, false, updateValue, updateField,isEditForm),
        {
            title:'Projection',
            rows:[
                {fields:[
                    {key:'expectedEndDate',label: 'Expected sale date', description: sole_ownership.EXPECTED_END_DATE_DESCRIPTION}
                ]},
                // {fields:[
                //     {key:'', label: 'Expected Additional Investment'}
                // ]},
                {fields:[
                    {key:'expectedSalePrice',
                    generatePropsFn : (values)=>{
                        return {prefix: CURRENCY_SYMBOL[values.currency] || 'USD'}
                    },
                    description: sole_ownership.EXPECTED_SALE_PRICE_DESCRIPTION}
                ]},
                // {fields:[
                //     {key:'expectedROI'}
                // ]},
            ]
        // },{
        //     title: 'Performance', 
        //     rows:[
        //         {fields:[
        //             {key:'startDate', label: 'Initial investment date ',
        //             description: sole_ownership.START_DATE_DESCRIPTION, 
        //             defaultValueFn: ()=>{return new Date()},
        //             disabledDate: disabledMoreThanToday }
        //         ]},
        //         {fields:[
        //                 {key:'cashInvested', label:'Total Investment',prefix:CURRENCY_SYMBOL['USD'] ,
        //                 description: sole_ownership.CASH_INVESTED_DESCRIPTION},
        //                 {key:'cashInvestedCurrency',size:0.3 , label: ' ', defaultValue: 'USD'}
        //         ]},
        //     ]
        },
        moreInfo(updateValue,updateField,isEditForm,true,true),
    ])};
}

export const createRealEstateDevelopmentWithPartnersAddItemSections = (isEditForm) => {
    return ({updateValue,updateField,updateSection}) => {
        const { owned_with_partners } = getTranslations().new_item_modal.real_estate.development;
        // const {CASH_INVESTED_LABEL} = getTranslations().common.asset_fields;
        const labelSubtext = ()=> <span>A <strong>capital call</strong> schedule can be added later</span>
        return ([
            sectionGeneralSole(true,owned_with_partners.VALUE_LABEL,owned_with_partners.VALUE_DESCRIPTION, false, updateValue,updateField,isEditForm, labelSubtext),
            capitalCallsSection(updateValue,updateField, true), 
            {
                title: 'Projection',
                rows: [
                    {fields:[
                        {key:'expectedEndDate', description: owned_with_partners.EXPECTED_END_DATE_DESCRIPTION,
                            onChange:({value,formValues})=> {
                                if (!formValues.isExpectedAnnualRevenueTouched && value){
                                    const yearsFromStartToEndDate = moment(value).diff(moment(formValues.startDate),"y") + ( moment(value).month() === moment(formValues.startDate).month() ? 0 : 1 );
                                    const years = [...Array(yearsFromStartToEndDate+1).keys()];
                                    const valueForYears = formValues.expectedAnnualCashReturn ? (
                                        formValues.distributionReinvested ? formValues.expectedAnnualCashReturn : (formValues.cashInvested / 100 * formValues.expectedAnnualCashReturn )
                                    ) : '';
            
                                    const scheduleValues = years.map(year=>({
                                        value: year === yearsFromStartToEndDate ? 0 : valueForYears,
                                        date: year === yearsFromStartToEndDate ? moment(value).add(1,"M").set("D",15).startOf("D") : moment(formValues.startDate).add(1,"M").add(year,'y').set("D",15).startOf("D"),
                                        remarks: year === yearsFromStartToEndDate ? '' : "Year " + (+year+1),
                                        tempId: +year+1
                                    }));
                                    updateValue('expectedAnnualRevenue', scheduleValues);
                                }
                            },
                            isHidden: isEditForm,
                            setFnForSave: (isDistribution, value, formValues) => {
                                return formValues?.exitEvent?.date || value
                            },
                        },{ isHidden:true, key:'cashflowEventsAfterExit' ,  setFnForSave: (isDistribution, value, formValues) => {
                            return isEditForm ? !formValues?.exitEvent?.stopCreatingFutureEventsAfterExitEvent : false
                        },}
                    ]},
                    {fields:[
                        {key:'expectedIRR', label: 'Expected IRR', description: owned_with_partners.EXPECTED_IRR_DESCRIPTION, defaultValue:0,
                        onBlur:({value,formValues}) => {
                            const { startDate, expectedEndDate } = formValues;
                            if (!isNullOrUndefinedOrZeroOrEmptyString(value)) {
                                const numberOfYears = (monthDiff(new Date(startDate), new Date(expectedEndDate)) / 12)
                                const updatedExpectedMultiplier = (1 + (value / 100)) ** numberOfYears;
                                updateValue('expectedMultiplier', floatFixed(updatedExpectedMultiplier, 2));
                            }
                        }
                        }
                    ]},
                    {fields:[
                        {key:'expectedMultiplier', description: owned_with_partners.EXPECTED_MULTIPLIER_DESCRIPTION}
                    ]},
                    {fields:[
                        {key:'expectedDistributions', type:'radio', label: 'Do you have expected distributions?', 
                        defaultValueFn: (values) => { return !isEditForm ? true : (values.expectedAnnualRevenue ? values.expectedAnnualRevenue.length > 0 : false )}, 
                        // defaultValue:  true,
                        onChange: ({value}) => {
                            // updateValue('distributionReinvested', false);
                            // updateField('distributionReinvested', { isHidden: !value, value: false });
                            updateSection('distributionScheduleProps', { isHidden: !value });
                            updateSection('distributionScheduleTable', { isHidden: !value });
                            if (!value) {
                                updateValue('expectedAnnualRevenue', []);
                            }
                        },
                        optionType: 'button',
                        options:[
                            {label: 'Yes', value: true},
                            {label: 'No', value: false}
                        ],
                    }
                    ]},
                    // {fields:[
                    //     {key:'distributionReinvested', label: '', checkboxLabel: 'My distributions are reinvested' , type:'checkbox' , 
                    //     onChange:({value, formValues})=> {
                    //         updateValue('isDistributionsByPercentage', value);
                    //         updateField('expectedAnnualRevenue', { isPercentageDisplay: value });
                    //         updateField('expectedAnnualCashReturn', { withNumber: !value });
                          
                    //         // update distributions schedule values to percentages / fixed number;
                    //         const updatedScheduleValues = formValues.expectedAnnualRevenue?.map(row => {
                    //            const newValue = row.value === '' ? (
                    //             formValues.cashInvested && formValues.expectedAnnualCashReturn ? (
                    //                 value ?    
                    //                 parseFloat((formValues.expectedAnnualCashReturn / formValues.cashInvested * 100).toFixed(2)) : 
                    //                 formValues.cashInvested / 100 * formValues.expectedAnnualCashReturn
                    //             ) : ''  
                    //             ) :( value ?
                    //                 parseFloat((row.value / formValues.cashInvested * 100).toFixed(2)) :
                    //                 (formValues.cashInvested / 100 * row.value) );
                    //             return { ...row, value: newValue }
                    //             })
                    //         updateValue('expectedAnnualRevenue', updatedScheduleValues);
                    //     }},
                    //     {
                    //         key:'isDistributionsByPercentage', 
                    //         isHidden:true,
                    //         defaultValue : false
                    //     },
                    // ]},
                ]
            },
            // {
            //     title:'Projected cashflow - distributions',
            //     fullWidth:true,
            //     // padding:'24px 96px',
            //     rows: [
            //         {fields:[
            //             {
            //                 key:'expectedAnnualRevenue', label: owned_with_partners.EXPECTED_ANNUAL_REVENUE_LABEL, description: owned_with_partners.EXPECTED_ANNUAL_REVENUE_DESCRIPTION,
            //                 type:'scheduleTable',
            //                 defaultValue:[],
            //                 showHistory: false,
            //                 generatePropsFn : (values)=> {
            //                     return {prefix: CURRENCY_SYMBOL[values.currency] || 'USD', placeholder: CURRENCY_SYMBOL[values.currency]}
            //                 },
            //             },
            //         ]},
            //         {fields:[
            //             {key:'expectedDistributionPeriod', label: owned_with_partners.EXPECTED_DISTRIBUTION_PERIOD_LABEL, description: owned_with_partners.EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION}
            //         ]},
            //     ]
            // },
            distributionScheduleSection(updateValue,updateField,isEditForm,owned_with_partners.EXPECTED_ANNUAL_REVENUE_LABEL,owned_with_partners.EXPECTED_ANNUAL_REVENUE_DESCRIPTION,owned_with_partners.EXPECTED_DISTRIBUTION_PERIOD_LABEL,owned_with_partners.EXPECTED_DISTRIBUTION_PERIOD_DESCRIPTION),
            distributionScheduleTableSection(updateValue,updateField,isEditForm,owned_with_partners.EXPECTED_ANNUAL_REVENUE_LABEL,owned_with_partners.EXPECTED_ANNUAL_REVENUE_DESCRIPTION),
            exitEventRowSection(updateValue,updateField,isEditForm),
            // {
            //     title:'Projection',
            //     rows:[
            //         {fields:[
            //             {key:'expectedSalePrice', label: owned_with_partners.EXPECTED_SALE_PRICE_LABEL,
            //             generatePropsFn : (values)=>{
            //                 return {prefix: CURRENCY_SYMBOL[values.currency] || 'USD'}
            //             },
            //             description: owned_with_partners.EXPECTED_SALE_PRICE_DESCRIPTION}
            //         ]},
            //         // {fields:[
            //         //     {key:'expectedROI'}
            //         // ]},
            //     ]
            // // },{
            // //     title: 'Performance', 
            // //     rows:[
            // //         {fields:[
            // //             {key:'startDate', label: 'Initial investment date ',
            // //             description: owned_with_partners.START_DATE_DESCRIPTION, 
            // //             defaultValueFn: ()=>{return new Date()},
            // //             disabledDate: disabledMoreThanToday }
            // //         ]},
            // //         {fields:[
            // //                 {key:'cashInvested', label: CASH_INVESTED_LABEL,prefix:CURRENCY_SYMBOL['USD'] ,
            // //                 description: owned_with_partners.CASH_INVESTED_DESCRIPTION},
            // //                 {key:'cashInvestedCurrency',size:0.3 , label: ' ', defaultValue: 'USD'}
            // //         ]},
            // //     ]
            // },
            moreInfo(updateValue,updateField,isEditForm,true,true,null, owned_with_partners.BENEFICIARY_DESCRIPTION),
            // moreInfo(withDefaultCashAccountField),
        ])
    };
}

export const createRealEstateResidenceAddItemSections = (isEditForm) => {
    return ({updateValue,updateField}) => {
        const { residence } = getTranslations().new_item_modal.real_estate;
        // const {CASH_INVESTED_LABEL} = getTranslations().common.asset_fields;
        return ([
            sectionGeneralSole(false,residence.VALUE_LABEL,residence.VALUE_DESCRIPTION,false,updateValue,updateField,isEditForm),
            {
                title:'Projection',
                rows:[
                    {fields:[
                        {key:'expectedMonthlyExpenses',label: residence.EXPECTED_MONTHLY_EXPENSES_LABEL,
                        generatePropsFn : (values)=>{
                            return {prefix: CURRENCY_SYMBOL[values.currency] || 'USD'}
                        },
                        description: residence.EXPECTED_MONTHLY_EXPENSES_DESCRIPTION}
                    ]},
                    {fields:[
                        {key:'annualIncreaseInValue',label: residence.ANNUAL_INCREASE_IN_VALUE_LABEL, description: residence.ANNUAL_INCREASE_IN_VALUE_DESCRIPTION, defaultValue: 0}
                    ]},
                    // {fields:[
                    //     {key:'expectedIRR', }
                    // ]},
                ]
            // },{
            //     title: 'Performance', 
            //     rows:[
            //         {fields:[
            //             {key:'startDate', label: residence.START_DATE_LABEL,
            //             description: residence.START_DATE_DESCRIPTION, 
            //             defaultValueFn: ()=>{return new Date()},
            //             disabledDate: disabledMoreThanToday }
            //         ]},
            //         {fields:[
            //                 {key:'cashInvested', label: CASH_INVESTED_LABEL,
            //                 prefix:CURRENCY_SYMBOL['USD'],
            //                 description: residence.CASH_INVESTED_DESCRIPTION},
            //                 {key:'cashInvestedCurrency',size:0.3 , label: ' ', defaultValue: 'USD'}
            //         ]},
            //     ]
            },
            moreInfo(updateValue,updateField,isEditForm,false,false),
        ])
    }
}

const exitEventRowSection = (updateValue,updateField,isEditForm) => {
    return {
        title:'',
        key: 'exitEventSection',
        fullWidth:true,
        generatePropsFn : (values)=>{
            return {
                isHidden: !isEditForm,
            }
        },
        rows: [
            exitEventFieldRow({isEditForm, autoCalculateTooltip: <>Uncheck this box to edit the exit event amount manually.<br/><br/>Vyzer automatically calculates the expected exit event based on your projections (IRR/Multiplier), target distributions, and all actual events during the asset's lifetime.</>})
        ]
    }
}

const expensesScheduleSection = (updateValue,updateField,isEditForm) => {
    return {
        key: 'contributionsScheduleTable',
        fullWidth:true,
        title: 'Target expenses schedule',
        generatePropsFn : (values)=>{
            return {
                isHidden: !isEditForm ? true : (values.monthlyRent ||  values.expectedMonthlyExpenses) || !(values.expectedAnnualExpense ? values.expectedAnnualExpense.length > 0 : false )
            }
        },
        rows: [
            {fields:[
                {key:'expectedContributionPeriod', label: 'Expenses frequency', description: '',
                type:'select',
                withDescriptionInOption: true,
                defaultValue: 'Monthly',
                overrideToDefaultIfNull: true,
                withSuffixIcon: true,
                options: [
                    {value:'Monthly',label:'Monthly'},
                    {value:'Quarterly',label:'Quarterly', description:'Transactions date: Apr, Jul, Oct, Jan (next year).' , selectedDescription:'Jan, Apr, Jul, Oct each year'},
                    {value:'Semi-annually',label:'Semi-annually', description:'Transactions date: Jul and Jan (next year).' , selectedDescription:'Apr, Jul each year'},
                    {value:'Annually',label:'Annually', description:'Transactions date: Jan (next year).', selectedDescription:'January each year'},
                    {value:'Custom',label:'Custom...', },
                ],
                onChange: ({value, formValues}) => {
                    updateField('expectedContributionStartDate', { isHidden: value !== 'Custom' });
                    updateField('expectedContributionCustomInterval', { isHidden: value !== 'Custom' });
                    updateField('expectedContributionCustomPeriod', { isHidden: value !== 'Custom' });
                    updateField('expectedAnnualExpense', { 
                        monthsPerPeriod: monthsPerPeriodMap[value] ? monthsPerPeriodMap[value] : formValues.expectedContributionCustomInterval || 1 ,
                        period: value === 'Custom' ? 'Periodic' : value
                    });
                },
            }
            ]},
            // {fields:[
            //     {key:'expectedDistributionStartDate', clearable: true ,  label:'Transactions start date', description:`Enter the frequency's first payment date.`, type:'date',
            //         generatePropsFn: (formValues) => {
            //             return { isHidden: formValues.expectedDistributionPeriod !== 'Custom' }
            //         },
            //         onChange:({value , formValues})=>{
            //             updateField('expectedAnnualRevenue',{startDate: value ? value : formValues.startDate});
                        
            //             if (!formValues.isExpectedAnnualRevenueTouched){
            //                 const updatedScheduleValues = formValues.expectedAnnualRevenue.map((item,index)=>{
            //                     return {...item,date :  moment(value).utc().add(index,'y').set("D",15).startOf("D")}
            //                 });
            //                 updateValue('expectedAnnualRevenue', updatedScheduleValues);
            //             }
            //         }
            //     }
            // ]},
            {fields:[
                {key:'expectedContributionCustomInterval', label:'Repeat every:', type:'number', withArrows:true,
                maxValue: 15, minValue: 0, defaultValue:2, decimalScale: 0,
  
                generatePropsFn: (formValues) => {
                    return { isHidden: formValues.expectedContributionPeriod !== 'Custom' }
                },
                onChange: ({value, formValues}) => {
                    updateField('expectedAnnualExpense', {monthsPerPeriod: value * ( formValues.expectedContributionCustomPeriod === 'Years' ? 12 : 1 ) })  
                },
                validations:[
                    {
                        fn: (value) => {
                            return value < 16;
                        },
                        message:'Maximum period allowed: 15'
                    }
                ]},
                {key:'expectedContributionCustomPeriod', type:'select', defaultValue:'Months',
                withDescriptionInOption: true,
                withSuffixIcon: true,
                generatePropsFn: (formValues) => {
                    return { isHidden: formValues.expectedContributionPeriod !== 'Custom' }
                },
                onChange: ({value, formValues}) => {
                    updateField('expectedAnnualExpense', {monthsPerPeriod: formValues.expectedContributionCustomInterval * ( value === 'Years' ? 12 : 1 ) })  
                },
                options:[
                    // {value: 'Weeks', label:'Weeks'},
                    {value: 'Months', label:'Months'},
                    {value: 'Years', label:'Years'},
                ]},
            ]},
            {fields:[
                {
                    key:'expectedAnnualExpense', 
                    label: '',
                    type:'scheduleTable',
                    defaultValueFn: (values) => {
                        if (isEditForm) { // asset info
                            if (values.expectedAnnualExpense) return values.expectedAnnualExpense;
                        } 
                        return null;
                    },
                    onChange: ({value, formValues}) => {
                        if (!formValues.isExpectedAnnualExpenseTouched){
                            updateValue('isExpectedAnnualExpenseTouched' , true);
                        }
                    },
                    showHistory: false,
                    showPercentage: true,
                    customAnnualReturnLabel: 'Annual contribution %',
                    paymentLabel: 'expenses',
                    customColumnsSize: ['105px','105px','105px','116px','auto'],
                    generatePropsFn : (values)=> {
                        const exPer = values.expectedContributionPeriod;
                        return {
                            prefix: CURRENCY_SYMBOL[values.currency] || 'USD', 
                            placeholder: CURRENCY_SYMBOL[values.currency],
                            holdings:values.holdings , 
                            startDate: values.startDate,
                            relativeAmount: values.cashInvested || values.value,
                            // isPercentageDisplay: values.distributionReinvested,
                            monthsPerPeriod: monthsPerPeriodMap[values.expectedContributionPeriod] ? monthsPerPeriodMap[values.expectedContributionPeriod] : (values.expectedContributionCustomInterval || 1 ),
                            period: !exPer ? 'Monthly' : exPer === 'Custom' ? 'Periodic' : exPer,
                            customAnnualReturnToolTipText: `The total amount you expect to contribute to this investment annually.`
                        }
                    },
                    validations: [
                        {
                          fn: (value, formValues) => {
                            return Array.isArray(value) ? (value.length === 0 || !value.some(item=> isNullOrUndefinedOrEmptyString(item.value)) ) : true ; 
                          },
                          // message: 'Empty value for a row is not valid',
                          bottomErrorMessage: (value, formValues) => {
                            return `Empty value for a row is not valid`; // `must be equal to ${formValues.transactionValue}.`
                          },
                        },
                    ]
                },
                {
                    key:'isExpectedAnnualExpenseTouched', 
                    isHidden:true,
                    defaultValue : isEditForm
                },
            ]},
        ]
    }
}

const rentScheduleSection = (updateValue,updateField,isEditForm) => {
    return {
        key: 'distributionScheduleTable',
        fullWidth:true,
        title: 'Target rent schedule',
        generatePropsFn : (values)=>{
            return {
                isHidden: !isEditForm ? true : (values.monthlyExpenses ||  values.expectedMonthlyExpenses) || !(values.expectedAnnualRevenue ? values.expectedAnnualRevenue.length > 0 : false )
            }
        },
        onLoad:(values)=>{
            updateField('expectedAnnualRevenue', { 
                customAnnualReturnToolTipText: `The total amount you expect to receive on this investment annually. 
                The percentage rate is based on your asset's initial investment ${(CURRENCY_SYMBOL[values.currency] + values.cashInvested) || ''}`,
            });
        },
        rows: [
            {fields:[
                {key:'expectedDistributionPeriod', label: 'Rent frequency', description: '',
                type:'select',
                withDescriptionInOption: true,
                defaultValue: 'Monthly',
                overrideToDefaultIfNull: true,
                withSuffixIcon: true,
                options: [
                    {value:'Monthly',label:'Monthly'},
                    {value:'Quarterly',label:'Quarterly', description:'Transactions date: Apr, Jul, Oct, Jan (next year).' , selectedDescription:'Jan, Apr, Jul, Oct each year'},
                    {value:'Semi-annually',label:'Semi-annually', description:'Transactions date: Jul and Jan (next year).' , selectedDescription:'Apr, Jul each year'},
                    {value:'Annually',label:'Annually', description:'Transactions date: Jan (next year).', selectedDescription:'January each year'},
                    {value:'Custom',label:'Custom...', },
                ],
                onChange: ({value, formValues}) => {
                    updateField('expectedDistributionStartDate', { isHidden: value !== 'Custom' });
                    updateField('expectedDistributionCustomInterval', { isHidden: value !== 'Custom' });
                    updateField('expectedDistributionCustomPeriod', { isHidden: value !== 'Custom' });
                    updateField('expectedAnnualRevenue', { 
                        monthsPerPeriod: monthsPerPeriodMap[value] ? monthsPerPeriodMap[value] : formValues.expectedDistributionCustomInterval || 1 ,
                        period: value === 'Custom' ? 'Periodic' : value
                    });
                },
                // generatePropsFn: (formValues) => {
                //     return {
                //         isHidden: !isEditForm ? true : !formValues.expectedAnnualRevenue?.length
                //     }
                // }
            }
            ]},
            {fields:[
                {key:'expectedDistributionCustomInterval', label:'Repeat every:', type:'number', withArrows:true,
                maxValue: 15, minValue: 0, defaultValue:2, decimalScale: 0,

                generatePropsFn: (formValues) => {
                    return { isHidden: formValues.expectedDistributionPeriod !== 'Custom' || !formValues.expectedAnnualRevenue || formValues.expectedAnnualRevenue?.length === 0 }
                },
                onChange: ({value, formValues}) => {
                    updateField('expectedAnnualRevenue', {monthsPerPeriod: value * ( formValues.expectedDistributionCustomPeriod === 'Years' ? 12 : 1 ) })  
                },
                validations:[
                    {
                        fn: (value) => {
                            return value < 16;
                        },
                        message:'Maximum period allowed: 15'
                    }
                ]},
                {key:'expectedDistributionCustomPeriod', type:'select', defaultValue:'Months',
                withDescriptionInOption: true,
                withSuffixIcon: true,
                generatePropsFn: (formValues) => {
                    return { isHidden: formValues.expectedDistributionPeriod !== 'Custom' || !formValues.expectedAnnualRevenue || formValues.expectedAnnualRevenue?.length === 0 }
                },
                onChange: ({value, formValues}) => {
                    updateField('expectedAnnualRevenue', {monthsPerPeriod: formValues.expectedDistributionCustomInterval * ( value === 'Years' ? 12 : 1 ) })  
                },
                options:[
                    {value: 'Months', label:'Months'},
                    {value: 'Years', label:'Years'},
                ]},
            ]},
            {fields:[
                {
                    key:'expectedAnnualRevenue', 
                    label: '',
                    type:'scheduleTable',
                    defaultValueFn: (values) => {
                        if (isEditForm) { // asset info
                            if (values.expectedAnnualRevenue) return values.expectedAnnualRevenue;
                        } 
                        return null;
                    },
                    onChange: ({value, formValues}) => {
                        if (!formValues.isExpectedAnnualRevenueTouched){
                            updateValue('isExpectedAnnualRevenueTouched' , true);
                        }
                    },
                    showHistory: false,
                    showPercentage: true,
                    generatePropsFn : (values)=> {
                        const exPer = values.expectedDistributionPeriod;
                        return {
                            prefix: CURRENCY_SYMBOL[values.currency] || 'USD', 
                            placeholder: CURRENCY_SYMBOL[values.currency],
                            holdings:values.holdings , 
                            startDate: values.startDate,
                            relativeAmount: values.cashInvested || values.value,
                            isPercentageDisplay: false,
                            paymentLabel: 'rent',
                            monthsPerPeriod: monthsPerPeriodMap[values.expectedDistributionPeriod] ? monthsPerPeriodMap[values.expectedDistributionPeriod] : (values.expectedDistributionCustomInterval || 1 ),
                            period: !exPer ? 'Monthly' : exPer === 'Custom' ? 'Periodic' : exPer,
                            customAnnualReturnToolTipText: `The total amount you expect to receive on this investment annually. 
                            The percentage rate is based on your asset's initial investment ${(CURRENCY_SYMBOL[values.currency] + values.cashInvested) || ''}`,
                            defaultEditedRow: !isEditForm,
                            // isHidden: !isEditForm ? true : !values.expectedAnnualRevenue?.length
                        }
                    },
                    validations: [
                        {
                        fn: (value, formValues) => {
                            return Array.isArray(value) ? (value.length === 0 || value.every(item=> isNullOrUndefinedOrEmptyString(item.value)) || value.every(item=> !isNullOrUndefinedOrEmptyString(item.value)) ) : true ; 
                        },
                        message: ' ',
                        bottomErrorMessage: (value, formValues) => {
                            return `Empty value for a row is not valid`;
                        },
                        },
                    ],
                    setFnForSave: (isDistribution, value, formValues) => {
                        if (!Array.isArray(value) || value.every(item => isNullOrUndefinedOrEmptyString(item.value))) {
                            updateValue('expectedDistributions', false);
                            updateValue('expectedAnnualRevenue', []);
                            return [];
                        }
                        return value;
                    }
                },
                {
                    key:'isExpectedAnnualRevenueTouched', 
                    isHidden:true,
                    defaultValue : isEditForm
                },
            ]}
        ]
    }
}


