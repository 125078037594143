import { action, computed, makeAutoObservable, makeObservable, observable } from "mobx";

export class FormsStore{
    forms = {};
    regFns = {}
    unRegFns = {}

    constructor(){
        makeAutoObservable(this);
    }

    register(formName){
        
        if (!this.forms.hasOwnProperty(formName)){
            const formStore = new FormStore();
            this.forms[formName] = formStore;
            if(this.regFns[formName]){
                this.regFns[formName].forEach(cb => {
                    cb(formStore);
                });
            }
        } 
    }

    unRegister(formName){
        if(this.unRegFns[formName]){
            this.unRegFns[formName].forEach(cb => {
                cb();
            });
        }
        delete this.forms[formName];
    }

    setRegFn(formName,cb){
        if (!this.regFns.hasOwnProperty(formName)){
            this.regFns[formName] = [cb];    
        } else {
            this.regFns[formName].push(cb);
        } 
    }

    setUnRegFn(formName,cb){
        if (!this.unRegFns.hasOwnProperty(formName)){
            this.unRegFns[formName] = [cb];    
        } else {
            this.unRegFns[formName].push(cb);
        } 
    }
    
}


export class FormStore{
    sections = [];
    fields = [];
    values = {};
    errors = {};
    bottomErrors = {};
    currentSectionIndex = 0;
    initialValues = {};
    maxValue={};
    // fieldsObj = {};
    
    constructor(){
        // makeAutoObservable(this);
        makeObservable(this,{
            sections: observable,
            values: observable,
            initialValues: observable,
            errors: observable,
            bottomErrors: observable,
            maxValue: observable,
            currentSectionIndex: observable,
            fields: observable,
            // fieldsObj: observable,
            setSections: action,
            setValues:action,
            setStep:action,
            setErrors:action,
            setBottomErrors:action,
            updateValue:action,
            updateField:action,
            handleFieldBlur: action,
            showComponentAfterPrev:action,
            handleUnlockComponent:action,
            valuesForSave: computed,
            currentSectionErrors: computed,
        })
    }

    setSections(sections,values, firstStep ){
        // console.log("MM?", sections , values);
        
        // this.fieldsObj = fields.reduce(
        //     (acc, curr) => ((acc[curr.key] = curr.hasOwnProperty('generatePropsFn') ? {...curr,...curr.generatePropsFn(values)} : curr ), acc),{} /* eslint-disable-line no-sequences*/
        // );

        const normalizedSections = [];
        const defaultValues = {};
        const overrideValues = {};

        sections.forEach(section=>{
            const normalizedRows = [];
            section.rows.forEach(row=>{
                const normalizedFields = [];
                row.fields.forEach(field=>{
                    if (field.hasOwnProperty('key')){
                        normalizedFields.push(field.key);
                        if (field.hasOwnProperty('defaultValue')){
                            defaultValues[field.key] = field.defaultValue;
                        } else if (field.hasOwnProperty('defaultValueFn')){
                            defaultValues[field.key] = field.defaultValueFn(values);
                        } else {
                            // defaultValues[field.key] = null;
                        }
                        if(field.hasOwnProperty('overrideValueFn') && values.hasOwnProperty(field.key)){
                            overrideValues[field.key] = field.overrideValueFn(values);
                        } else if (field.hasOwnProperty('overrideToDefaultIfNull') && values.hasOwnProperty(field.key) && values[field.key] === null){
                            if (field.hasOwnProperty('defaultValue')){
                                overrideValues[field.key] = field.defaultValue;
                            } else if (field.hasOwnProperty('defaultValueFn')){
                                overrideValues[field.key] = field.defaultValueFn(values);
                            }
                        }
                    }
                })
                normalizedRows.push({...row,fields:normalizedFields});
            });
            normalizedSections.push({
                ...section,
                ...(section.hasOwnProperty('generatePropsFn') ? section.generatePropsFn(values) : {}),
                rows:normalizedRows
            });
        });
        
        const fieldsFlat = sections.map(section=>section.rows.map(row=>row.fields.flat().map(field=>(field.hasOwnProperty('generatePropsFn') ? {...field,...field.generatePropsFn(values)} : field))).flat()).flat();
        this.fields = fieldsFlat;
        this.values = { ...defaultValues , ...values , ...overrideValues };
        this.initialValues = { ...defaultValues , ...values , ...overrideValues };
        this.sections = normalizedSections;

        // this.sections = sections.map(section=>({
        //     ...section,
        //     rows: section.rows.map(row=>({
        //         ...row,
        //         fields: row.fields.map(field=>(field.key))
        //     }))
        // }));
        // console.log(fields);
        if (firstStep !== -1){
            this.setStep(0);
        } else {
            this.sections.forEach(section=>{
                section.onLoad?.(this.values)
            });
        }

    }

    setValues(values){
        this.values = values;
    }

    setStep(stepIndex){
        if (this.currentSectionIndex !== stepIndex){
            this.sections[stepIndex].onLoad && this.sections[stepIndex].onLoad(this.values);
        }
        this.currentSectionIndex = stepIndex;
    }

    setErrors(errors){
        this.errors = errors;
    }

    setBottomErrors(errors){
        this.bottomErrors = errors;
    }

    updateValue(fieldKey,value,isProgrammaticallyUpdate){
        const previousValue = this.values[fieldKey];
        // console.log(fieldKey,value,isProgrammaticallyUpdate)
        this.values = { ...this.values,[fieldKey] : value};
        // this.values[fieldKey] = value; 
        const currentField= this.fields.find(field=>field.key === fieldKey);
        if (currentField) {
            if (!isProgrammaticallyUpdate) {
                // console.log("Apply on change for field : " + fieldKey);
                // const currentField= this.fields.find(field=>field.key === fieldKey);
                // console.log("On chnange for " + currentField.key);
                currentField.onChange && currentField.onChange({value,formValues : this.values, previousValue});
                // this.fieldsObj[fieldKey].onChange && this.fieldsObj[fieldKey].onChange({value,formValues : this.values});
            }
            
            let updatedErrors = {...this.errors};
            let updatedBottomErrors = {...this.bottomErrors};
            let hasValidationsChanges = false;

            // const sectionErrors = {};  
            // const sectionBottomErrors = {};

            // on change - only remove validations if exist , ( do not add )
            [fieldKey,...(currentField.validateFieldsOnChange || [])].forEach(validationFieldKey=>{
                const currentValidationField= this.fields.find(field=>field.key === validationFieldKey);
                let isFieldValid = true;

                // sectionErrors[validationFieldKey] = [];
                // sectionBottomErrors[validationFieldKey] = [];
                const newErrors = []; // updatedErrors[validationFieldKey] = []
                const newBottomErrors = [];
                // updatedBottomErrors[validationFieldKey] = []

                currentValidationField.validations?.forEach(validation=>{
                    if (!validation.fn(this.values[validationFieldKey], this.values)){
                        isFieldValid = false;
                        if (currentValidationField.showValidationErrorOnChange){
                            if (validation.message){
                                // if (!updatedErrors.hasOwnProperty(validationFieldKey)){ updatedErrors[validationFieldKey] = []}
                                if(typeof validation.message  === 'function'){
                                    newErrors.push(validation.message(this.values[validationFieldKey], this.values))
                                } else {
                                    newErrors.push(validation.message)
                                }   
                            }
                            
                            
                            if(typeof validation.bottomErrorMessage  === 'function'){
                                // if (!updatedBottomErrors[validationFieldKey]){ updatedBottomErrors[validationFieldKey] = []}
                                newBottomErrors.push(validation.bottomErrorMessage(this.values[validationFieldKey], this.values))
                            }
                        }
                    }
                });

                if (isFieldValid){
                    if (updatedErrors.hasOwnProperty(validationFieldKey)){
                        // const newErrors = {...this.errors};
                        hasValidationsChanges = true;
                        delete updatedErrors[validationFieldKey];
                        // this.setErrors(newErrors);
                    }
                    if (updatedBottomErrors.hasOwnProperty(validationFieldKey)){
                        // const newErrors = {...this.bottomErrors};
                        hasValidationsChanges = true;
                        delete updatedBottomErrors[validationFieldKey];
                        // this.setBottomErrors(newErrors);
                    }
                } else  {
                    if (newErrors.length){
                        hasValidationsChanges = true;
                        updatedErrors[validationFieldKey] = newErrors;
                    }
                    if (newBottomErrors.length){
                        hasValidationsChanges = true;
                        updatedBottomErrors[validationFieldKey] = newBottomErrors;
                    }
                }
               
            });

            if (hasValidationsChanges){
                this.setErrors(updatedErrors);
                this.setBottomErrors(updatedBottomErrors);
            }
        }
        
    }

    updateField(fieldKey,update){
        // const currentFieldIndex = this.fields.findIndex(field=>field.key === fieldKey);
        // if (currentFieldIndex > -1){
        // This fix is to work with all fields if we have more than one field with same key ( for old assets when we need to hide and change positions for sections )
        const fieldsIndexes = this.fields.map((field,index)=>(field.key === fieldKey ? index : -1)).filter(index=>index !== -1);
        fieldsIndexes.forEach(currentFieldIndex=>{
            this.fields[currentFieldIndex] = {...this.fields[currentFieldIndex] , ...update};
        });
    }

    updateSection(sectionKey,update){
        const updatedSectionIndex = this.sections.findIndex(section=>section.key === sectionKey);
        if (updatedSectionIndex > -1){
            this.sections[updatedSectionIndex] = {...this.sections[updatedSectionIndex] , ...update};
            // enforce rerender of the field ( the fields obj is not observable )
            // this.values = { ...this.values};
            
            // currentField = {...currentField, ...update}
            // this.fieldsObj[fieldKey] = {
            //     ...this.fieldsObj[fieldKey],
            //     ...update
            // }
        }
    }

    handleFieldBlur(fieldKey){
        // console.log("Field Blur")
        
        const currentField = this.fields.find(field=>field.key === fieldKey);
        
        currentField && currentField.onBlur && currentField.onBlur({value:this.values[fieldKey],formValues : this.values});

        if (currentField && (currentField.showValidationErrorOnBlur || currentField.validateFieldsOnBlur)){

            if (currentField.skipBlurValidationFn && currentField.skipBlurValidationFn({value:this.values[fieldKey],formValues : this.values})){
                return;
            }
   
            

            let updatedErrors = {...this.errors};
            let updatedBottomErrors = {...this.bottomErrors};
            let hasValidationsChanges = false;

            
            // on change - only remove validations if exist , ( do not add )
            [...( currentField.showValidationErrorOnBlur ? [fieldKey] : [] ),...(currentField.validateFieldsOnBlur || [])].forEach(validationFieldKey=>{
                const currentValidationField= this.fields.find(field=>field.key === validationFieldKey);
                let isFieldValid = true;

                // sectionErrors[validationFieldKey] = [];
                // sectionBottomErrors[validationFieldKey] = [];
                const newErrors = []; // updatedErrors[validationFieldKey] = []
                const newBottomErrors = [];
                // updatedBottomErrors[validationFieldKey] = []

                currentValidationField.validations?.forEach(validation=>{
                    if (!validation.fn(this.values[validationFieldKey], this.values)){
                        isFieldValid = false;
                        //if (currentValidationField.showValidationErrorOnChange){
                            if (validation.message){
                                // if (!updatedErrors.hasOwnProperty(validationFieldKey)){ updatedErrors[validationFieldKey] = []}
                                if(typeof validation.message  === 'function'){
                                    newErrors.push(validation.message(this.values[validationFieldKey], this.values))
                                } else {
                                    newErrors.push(validation.message)
                                }   
                            }
                            
                            
                            if(typeof validation.bottomErrorMessage  === 'function'){
                                // if (!updatedBottomErrors[validationFieldKey]){ updatedBottomErrors[validationFieldKey] = []}
                                newBottomErrors.push(validation.bottomErrorMessage(this.values[validationFieldKey], this.values))
                            }
                        //}
                    }
                });

                if (isFieldValid){
                    if (updatedErrors.hasOwnProperty(validationFieldKey)){
                        // const newErrors = {...this.errors};
                        hasValidationsChanges = true;
                        delete updatedErrors[validationFieldKey];
                        // this.setErrors(newErrors);
                    }
                    if (updatedBottomErrors.hasOwnProperty(validationFieldKey)){
                        // const newErrors = {...this.bottomErrors};
                        hasValidationsChanges = true;
                        delete updatedBottomErrors[validationFieldKey];
                        // this.setBottomErrors(newErrors);
                    }
                } else  {
                    if (newErrors.length){
                        hasValidationsChanges = true;
                        updatedErrors[validationFieldKey] = newErrors;
                    }
                    if (newBottomErrors.length){
                        hasValidationsChanges = true;
                        updatedBottomErrors[validationFieldKey] = newBottomErrors;
                    }
                }
               
            });
            
            if (hasValidationsChanges){
                this.setErrors(updatedErrors);
                this.setBottomErrors(updatedBottomErrors);
            }
        }
    }

    showComponentAfterPrev(fieldKey){
        this.updateField(fieldKey,{showPrevComp:false});
        const currentField = this.fields.find(field=>field.key === fieldKey);
        currentField && currentField.onShow && currentField.onShow({values: this.values});
    }

    handleChangeShowUnlockComponent(fieldKey,show){
        this.updateField(fieldKey,{showUnlockComponent:show});
    }

    handleUnlockComponent(fieldKey){
        this.updateField(fieldKey,{isLocked:false});
        const currentField = this.fields.find(field=>field.key === fieldKey);
        currentField && currentField.onUnlock && currentField.onUnlock();
    }

    resetValues(){
        this.setValues(this.initialValues);
    }

    get valuesForSave(){
        let updatedValues = {};
        this.fields.forEach( field => {
            // const field = this.fields[fieldKey];
            let fieldValue = field.hasOwnProperty('setFnForSave') ? field.setFnForSave(false, this.values[field.key] , this.values) : this.values[field.key];
            updatedValues[field.key] = fieldValue;
        });
        return {...this.values, ...updatedValues};
    }

    get currentSectionErrors(){
        const { sectionErrors , sectionBottomErrors } = checkSectionValidation((  this.sections[this.currentSectionIndex]?.rows || []), this.fields, this.values);
        return {
            isValid: Object.keys(sectionErrors).length === 0 && Object.keys(sectionBottomErrors).length === 0, 
            errors: sectionErrors,
            bottomErrors: sectionBottomErrors,
        } 
    }

    get allSectionErrors(){
        let allSectionErrors = {};  
        let allSectionBottomErrors = {};
        this.sections.forEach(section=>{
            const { sectionErrors , sectionBottomErrors } = checkSectionValidation(section.rows, this.fields, this.values);
            allSectionErrors = {...allSectionErrors , ...sectionErrors}
            allSectionBottomErrors = {...allSectionBottomErrors , ...sectionBottomErrors}
        });
        return {
            isValid: Object.keys(allSectionErrors).length === 0 && Object.keys(allSectionBottomErrors).length === 0, 
            errors: allSectionErrors,
            bottomErrors: allSectionBottomErrors,
        } 
    }

    get fieldsObj(){
        return this.fields.reduce(
            (acc, curr) => ((acc[curr.key] = curr ), acc),{} /* eslint-disable-line no-sequences*/
        );
    }
    
}


const checkSectionValidation = (sectionRows, fields, values) => {
    const sectionErrors = {};  
    const sectionBottomErrors = {};
    sectionRows.forEach(row=>{
        row.fields.forEach(fieldKey=>{
            const currentField= fields.find(field=>field.key === fieldKey);
            if (currentField.hasOwnProperty('validations') && !currentField.skipValidation){
                sectionErrors[fieldKey] = [];
                sectionBottomErrors[fieldKey] = [];
                currentField.validations.forEach(validation=>{
                    if (!validation.fn(values[fieldKey], values)){
                        if (validation.message){
                            if(typeof validation.message  === 'function'){
                                sectionErrors[fieldKey].push(validation.message(values[fieldKey], values))
                            } else {
                                sectionErrors[fieldKey].push(validation.message)
                            }   
                        }
                        
                        if(typeof validation.bottomErrorMessage  === 'function'){
                            sectionBottomErrors[fieldKey].push(validation.bottomErrorMessage(values[fieldKey], values))
                        }
                    }
                });
                if (sectionErrors[fieldKey].length === 0){
                    delete sectionErrors[fieldKey];
                }
                if (sectionBottomErrors[fieldKey].length === 0){
                    delete sectionBottomErrors[fieldKey];
                }
            }
        })
    });
    return { sectionErrors , sectionBottomErrors };

}