import { Observer } from 'mobx-react';
import React, { useEffect } from 'react'
// import { useHistory } from 'react-router-dom';
import { LogoLoader } from '../../../../../common/components/LogoLoader/LogoLoader';
// import { navigateToUrl } from '../../../../../common/utils/navigation.utils';
import { useStore } from '../../../../app/data/root.store';
import { TickersList } from '../../asset/components/Tickers/TickersList';
import { ClassPageTickersListViewWrapper, LogoLoaderWrapper } from './ClassPage.styles';
import { ClassPageTickersListViewEmptyState } from './ClassPageTickersListViewEmptyState';

export const ClassPageTickersListView = ({relevantClass}) => {
  const { tickersStore } = useStore();
  // const history = useHistory();
  
  useEffect(() => {
    tickersStore.getTickersData([],relevantClass);
    // return () => {
    //   tickersStore.setData([]);
    // }
  }, [tickersStore,relevantClass])

  return (<Observer>{() => (
    <ClassPageTickersListViewWrapper>
      {tickersStore.isLoadingTickersData ?
        <LogoLoaderWrapper>
          <LogoLoader wbg />
        </LogoLoaderWrapper>
        :
        !tickersStore.tickers?.length ?
          <ClassPageTickersListViewEmptyState />
          :
          <TickersList tickers={tickersStore.sortedTickersList} relevantClass={relevantClass} isClassView={true} closedHoldingsInDisplayCount={tickersStore.closedHoldingsInDisplayCount} />}
    </ClassPageTickersListViewWrapper>
  )}</Observer>)
}