import styled from "@emotion/styled/macro";
import { views } from "../../../../../../common/styles/theme.styles";

export const ConformationHeader = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  padding: 10px 12px 6px 12px;
`;

export const CloseIconWrapper = styled.div`
    display: flex;
    cursor: pointer;
`;

export const ConformationBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    padding: ${props => props.padding || ''};
    padding:0 16px;
`;

export const ConformationFooter = styled.div`
    display: flex;
    justify-content: center;
    gap: 8px;
    padding: 38px 24px 20px 24px;
    ${views.tablet} {
        flex-direction: column;
    }
`;

export const ConformationButtonText = styled.div`
    color: ${props => props.isPrimary ? 'var(--White, var(--System-Background-Base-Primary, #FFF))' : 'var(--Dark-Gray-1, #1A1B1D)'};,
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    white-space: nowrap;
    `;

export const ConformationTextTitle = styled.div`
    color: var(--Dark-Gray-1, #1A1B1D);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

export const ConformationText = styled.div`
    color: #26282C;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 18px; /* 128.571% */
`;