import { Chart } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { clamp } from "../../../common/utils/number-display.utils";

const base64Images = [
    'data:image/svg+xml;base64,' + btoa(`<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect y="0.5" width="12" height="12" rx="2" fill="#9097A3"/> </svg>`),
    'data:image/svg+xml;base64,' + btoa(`<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect y="0.5" width="12" height="12" rx="2" fill="#DDE3EB"/> </svg>`),
    'data:image/svg+xml;base64,' + btoa(`<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect y="0.5" width="12" height="12" rx="2" fill="#DEB482"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M2.67078 6.3335L5.66667 6.3335C5.94282 6.3335 6.16667 6.55735 6.16667 6.8335L6.16667 9.82957C7.93019 9.74273 9.33334 8.28539 9.33334 6.50033C9.33334 4.65938 7.84096 3.16699 6.00001 3.16699C4.215 3.16699 2.7577 4.57005 2.67078 6.3335Z" fill="white"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M5.49994 9.79621C4.05925 9.57949 2.92052 8.44071 2.70386 7L5.49994 7L5.49994 9.79621Z" fill="white"/> </svg>`),
    'data:image/svg+xml;base64,' + btoa(`<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect y="0.5" width="12" height="12" rx="2" fill="#E173B5"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M6.33341 3.16699H4.16675V4.66699H3.00008C2.81599 4.66699 2.66675 4.81623 2.66675 5.00033V9.83366H4.83341V8.33366H5.66675V9.83366H9.00008C9.18418 9.83366 9.33342 9.68442 9.33342 9.50033V7.66699H7.83342V6.16699H6.33341V3.16699ZM4.90073 4.59968V3.90097H5.59943V4.59968H4.90073ZM3.39948 6.10093V5.39973H4.10068V6.10093H3.39948ZM4.89948 7.60093V6.89973H5.60068V7.60093H4.89948ZM3.39846 8.39871V9.10196H4.1017V8.39871H3.39846ZM3.39948 6.89973V7.60042H4.10017V6.89973H3.39948ZM4.89948 5.39973V6.10093H5.60068V5.39973H4.89948ZM6.39846 8.3987V9.10195H7.1017V8.3987H6.39846ZM7.89846 9.10195V8.39871H8.6017V9.10195H7.89846ZM6.39974 6.9V7.60068H7.10042V6.9H6.39974Z" fill="white"/> </svg>`)
];

const truncateText = (ctx, text, maxWidth) => {
    const ellipsis = '...';
    let truncated = text;

    // Measure the text width
    while (ctx.measureText(truncated).width > maxWidth) {
        truncated = truncated.slice(0, -1); // Remove one character at a time
    }

    // Append ellipsis if text was truncated
    if (truncated !== text) {
        truncated = truncated.slice(0, -ellipsis.length) + ellipsis;
    }

    return truncated;
}

export const commitedReportGraphImage = async (graphData) => {

    const svgImages = base64Images.map((path) => {
        if (!path) return null;

        const img = new Image();
        img.src = path;
        return img;
    });

    const chartData = graphData.reduce((acc, item) => {
        const { itemName, classId, commitedCapital, totalCommitment } = item;
        acc.labels.push(itemName);
        acc.totalCommitmentData.push(totalCommitment);
        acc.calledCapitalData.push(commitedCapital);
        acc.images.push(svgImages[(classId === 8) ? 2 : 3]);

        return acc;

    }, { labels: [], totalCommitmentData: [], calledCapitalData: [], images: [] });

    const yScaleImage = {
        id: 'yScaleImage',
        afterDatasetsDraw: (chart) => {
            const { ctx, scales: { y } } = chart;
            ctx.save();

            chartData.labels.forEach((value, index) => {
                const label = chartData.labels[index];
                const img = chartData.images[index];

                const xPos = 10;
                const yPos = y.getPixelForValue(index) - 6;
                const w = 12;
                const h = 12;

                ctx.drawImage(img, xPos, yPos, w, h);

                const textX = xPos + w + 8;
                const textY = yPos + 6;
                ctx.font = '12px Circular';
                ctx.fillStyle = '#585D66'; // Text color
                ctx.textAlign = 'left'; // Align text to the left

                const text = truncateText(ctx, label, 120); // Truncate text if needed
                ctx.fillText(text, textX, textY); // Draw text after the image
            });

            ctx.restore();
        }
    }

    const stepSize = Math.floor(Math.max(...chartData.totalCommitmentData) / 10);
    const max = (12 * stepSize);

    const config = {
        type: 'bar',
        data: {
            labels: chartData.labels,
            datasets: [
                {
                    label: 'Managed funds',
                    backgroundColor: '#DEB482',
                    data: [],
                    pointStyle: svgImages[2]
                },
                {
                    label: 'Real estate',
                    backgroundColor: '#E173B5',
                    data: [],
                    pointStyle: svgImages[3]
                },
                {
                    label: 'Total funded',
                    borderRadius: 100,
                    backgroundColor: '#9097A3',
                    data: chartData.calledCapitalData,
                    barThickness: 19,
                    datalabels: {
                        align: 'end',
                        anchor: 'start',
                        color: '#DDE3EB'
                    },
                    borderWidth: 0,
                    pointStyle: svgImages[0]
                },
                {
                    label: 'Total commitment',
                    backgroundColor: '#DDE3EB',
                    borderRadius: 100,
                    data: chartData.totalCommitmentData,
                    barThickness: 19,
                    datalabels: {
                        align: 'start',
                        anchor: 'end',
                        color: '#1A1B1D'
                    },
                    borderWidth: 0,
                    pointStyle: svgImages[1]
                }
            ]
        },
        options: {
            layout: {
                padding: {
                    left: 150, // Adjust as needed to fit images and text
                },
            },
            indexAxis: 'y',
            animation: {
                duration: 0 // Disable all animations
            },
            responsive: true,
            plugins: {
                datalabels: {
                    color: '#1A1B1D',
                    font: {
                        weight: 'bold',
                        size: 10
                    },
                    formatter: (num, context) => {
                        if (num < stepSize) {
                            return '';
                        }

                        const index = context.dataIndex;
                        if (context.dataset.label === 'Total funded') {
                            const totalCommitmentData = chartData.totalCommitmentData[index];

                            if (totalCommitmentData < (stepSize * 2)) {
                                return '';
                            }
                        }

                        return `$${new Intl.NumberFormat().format(Math.round(num))}`;
                    }
                },
                legend: {
                    position: 'bottom',
                    labels: {
                        usePointStyle: true,
                        padding: 20
                    }
                }
            },
            scales: {
                y: {
                    stacked: true,
                    ticks: {
                        callback: () => ''
                    }
                },
                x: {
                    type: 'linear', // Ensure the X-axis is linear for numerical data
                    position: 'bottom',
                    stacked: false,
                    min: 0,
                    max,
                    ticks: {
                        stepSize,
                        autoSkip: false, // Ensure no skipping of ticks
                        callback: (value) => {
                            const roundThousend = Math.round(value / 1000);
                            if (roundThousend === 0) return '';
                            if (roundThousend > 1000) return `$${roundThousend / 1000}M`;
                            return `${value > 0 ? '$' : ''}${Math.round(value / 1000)}K`
                        }
                    }
                }
            }
        },
        plugins: [ChartDataLabels, yScaleImage]
    };

    const canvas = document.createElement('canvas');
    const height = clamp((50 + (graphData.length * 10)), 130, 380); // Adjust as needed
    canvas.height = height;

    const ctx = canvas.getContext('2d');

    const div = document.createElement('div');
    document.body.appendChild(div);
    div.style.width = '800px';
    div.appendChild(canvas);
    new Chart(ctx, config);

    const base64 = await new Promise(resolve => setTimeout(() => {
        const base64 = canvas.toDataURL('image/png');
        resolve(base64);
    }, 150));

    div.parentElement.removeChild(div);

    return base64;
}