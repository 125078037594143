 import moment from 'moment';
import {countryListAlpha3} from '../../../../../common/constants/countries.const';
 import {CURRENCY_SYMBOL} from '../../../../../common/constants/currency-symbol.const';
import { getTranslations } from '../../../../../common/translations/translations';
import { disabledMonthBefore, disabledMoreThanToday } from '../../../../../common/utils/dates.utils';
const {asset_fields} = getTranslations().common;
export const assetFields = [
    {type:'text',key:'title', label: asset_fields.TITLE_LABEL, description: asset_fields.TITLE_DESCRIPTION},
    {type:'text',key:'name',label:'Institution', description: asset_fields.NAME_DESCRIPTION, disableIfConnected:true},
    {type:'text',key:'description',label:`Description`},
    {type:'address-autocomplete', key: 'address', label:'Address' },
    {type:'select', key:'country',label:'Country', showSearch:true, allowClear:true, options:
        Object.entries(countryListAlpha3).map(([key, value]) => ({value:key,label:value}))},
    {type:'number',key:'value',label:'Balance', description:'Most updated asset worth', disableIfConnected:true, shouldBeHiddenIncognito : true} ,
    {type:'select', key:'currency',label:' ', showSearch:true, disableIfConnected:true, options:
        Object.entries(CURRENCY_SYMBOL).map(([key, value]) => ({value:key,label:key}))},
        {type:'date', key:'startDate',label: asset_fields.START_DATE_LABEL, description: asset_fields.START_DATE_DESCRIPTION,
     disabledDate: disabledMoreThanToday , clearable:false },
    {type:'date', key:'expectedEndDate',label:'Expected end date', clearable:true,
        generatePropsFn: (formValues) => {
            return {
                disabledDate: (current) => disabledMonthBefore(current, moment(formValues.startDate).add(1, 'month')),
            }
        },
    },
    {type:'date', key:'principalFirstRepayment',label: asset_fields.PRINCIPAL_FIRST_REPAYMENT_LABEL, description: asset_fields.PRINCIPAL_FIRST_REPAYMENT_DESCRIPTION, clearable:true  },
    {type:'date', key:'interestFirstPayment',label: asset_fields.INTEREST_FIRST_REPAYMENT_LABEL, description: asset_fields.INTEREST_FIRST_REPAYMENT_DESCRIPTION, clearable:true  },
    {type:'number', key:'optionExpirationPeriod',label:'Options Expiration Period (Months)' , description: 'The time limit for exercising these options.'},
    {type:'percentage', key:'apy',label:'APY %', description: 'The annual interest rate you’re earning on this account', showHistory: true  },
    {type:'percentage', key:'expectedIRR',label:'Expected IRR %', showHistory: true},
    {type:'number', key:'expectedMultiplier',label:'Expected multiplier', showHistory: true},
    {type:'number', key:'maturityFromGrant', showHistory: true,label:'Time to maturity from grant (years)' , description: 'The number of years until you can exercise these options'},
    {type:'beneficiary-autocomplete', key:'beneficiary',label:'Holding entity', description: asset_fields.BENEFICIARY_DESCRIPTION, defaultValue: 'Me' },
    // {type:'slider', key:'holdings',label:'Holdings' },
    {type:'percentage', key:'holdings',label: asset_fields.HOLDINGS_LABEL, description: asset_fields.HOLDINGS_DESCRIPTION },
    {type:'select', key: 'propertyType', label:'Type', allowClear:true,
        options: [
            {value:'Multi family',label:'Multi family'},
            {value:'Single family',label:'Single family'},
            {value:'RV parks',label:'RV parks'},
            {value:'Mobile home parks',label:'Mobile home parks'},
            {value:'Self storage',label:'Self storage'},
            {value:'Commercial',label:'Commercial'},
            {value:'Industrial',label:'Industrial'},
            {value:'Assisted Living',label:'Assisted Living'},
            {value:'ATM',label:'ATM'},
            {value:'Hospitality',label:'Hospitality'},
            {value:'Other',label:'Other'}
        ]},
    {type:'select', key: 'vestingPeriod', label:'Vesting period',
        description: 'How often these options are released from their vesting to you',
      options: [{value:'Monthly',label:'Monthly'},{value:'Quarterly',label:'Quarterly'},{value:'Annually',label:'Annually'},{value:'At the end', label:'Fully vested'}]
    },
    {type:'number', key: 'purchasePrice', showHistory: true, label:'Purchase price', shouldBeHiddenIncognito : true},
    {type:'select', key:'purchasePriceCurrency',label:' ', showSearch:true, disableIfConnected:true, options:
    Object.entries(CURRENCY_SYMBOL).map(([key, value]) => ({value:key,label:key}))},
    {type:'number', key: 'cashInvested', showHistory: true, shouldBeHiddenIncognito : true,
    label: asset_fields.CASH_INVESTED_LABEL, description:'Including all contributions since initial date'},
    {type:'select', key:'cashInvestedCurrency',label:' ', showSearch:true, disableIfConnected:true, disabled:true, options:
    Object.entries(CURRENCY_SYMBOL).map(([key, value]) => ({value:key,label:key}))},
    {type:'number', key: 'cashReceived', showHistory: true, shouldBeHiddenIncognito : true,
    label: 'Total cash received', description:'Total withdrawals since initial date'},
    {type:'select', key:'cashReceivedCurrency',label:' ', showSearch:true, disableIfConnected:true , disabled:true,  options:
    Object.entries(CURRENCY_SYMBOL).map(([key, value]) => ({value:key,label:key}))},
    {type:'text', key: 'agent', label:'Agent' },
    {type:'number', key: 'monthlyRent', label: 'Monthly rent', showHistory: true, shouldBeHiddenIncognito : true},
    {type:'number', key: 'expectedAnnualRevenue', label: 'Expected annual revenue', showHistory: true,  shouldBeHiddenIncognito : true},
    {type:'number', key: 'expectedMonthlyExpenses', label: 'Expected monthly expenses', showHistory: true ,  shouldBeHiddenIncognito : true},
    {type:'percentage', key: 'annualIncreaseInValue', label: asset_fields.ANNUAL_INCREASE_IN_VALUE_LABEL, showHistory: false},
    {type:'number', key: 'expectedMonthlyContribution', label: 'Expected monthly contribution', showHistory: true},
    {type:'number', key: 'expectedAnnualDistribution', label: 'Expected annual distribution', showHistory: true},
    {type:'select', key: 'expectedDistributionPeriod', label:'Expected distribution period', options: [{value:'Monthly',label:'Monthly'},{value:'Quarterly',label:'Quarterly'},{value:'Annually',label:'Annually'}]},
    {type:'percentage', key: 'expectedROI', label: 'Expected ROI %', showHistory: true},
    {type:'percentage', key: 'expectedROICalc', label: 'Expected ROI %', },
    {type:'percentage', key: 'expectedAnnualDividend', label: 'Expected annual dividend %', showHistory: true},
    {type:'number', key: 'pricePerShare', label: 'Price per share', showHistory: true, shouldBeHiddenIncognito : true},
    {type:'select', key:'pricePerShareCurrency',label:' ', showSearch:true, disableIfConnected:true, options:
    Object.entries(CURRENCY_SYMBOL).map(([key, value]) => ({value:key,label:key}))},
    {type:'number', key: 'sharesPurchased', label: 'Shares purchased', showHistory: true, shouldBeHiddenIncognito : true},
    {type:'number', key: 'numberOfShares', label: 'Number of options (Shares)', showHistory: true, shouldBeHiddenIncognito : true},
    {type:'number', key: 'exercisePricePerShare', label: 'Exercise / Strike Price Per Share', showHistory: true, shouldBeHiddenIncognito : true},
    {type:'number', key: 'loanAmount', label:'Loan amount', showHistory: true , shouldBeHiddenIncognito : true},
    {type:'number', key: 'fixedMonthlyPayment', label: asset_fields.MONTHLY_REPAYMENT_LABEL, description: asset_fields.MONTHLY_REPAYMENT_DESCRIPTION, shouldBeHiddenIncognito : true},
    {type:'select', key: 'interestRepaymentPeriod', label:'Interest repayment period',  options: [{value:'Monthly',label:'Monthly'},{value:'Quarterly',label:'Quarterly'},{value:'Annually',label:'Annually'},{value:'At the end', label:'At the end'}]},
    {type:'select', key: 'principalRepaymentPeriod', label:'Principal repayment period', options: [{value:'Monthly',label:'Monthly'},{value:'Quarterly',label:'Quarterly'},{value:'Annually',label:'Annually'},{value:'At the end', label:'At the end'}]},
    {type:'select', key: 'loanType', label:'Loan type', allowClear:true,
    options: [
        {value:'Mortgage',label:'Mortgage'},
        {value:'Home equity',label:'Home equity'},
        {value:'Line of credit',label:'Line of credit'},
        {value:'Auto mobile',label:'Auto mobile'},
        {value:'Business loan',label:'Business loan'},
        {value:'Personal loans',label:'Personal loans'},
        {value:'Student loan',label:'Student loan'},
        {value:'CRE loan',label:'CRE loan'},
        {value:'Other',label:'Other'}
    ]},
    {type:'percentage', key: 'interest', label: 'Interest %', showHistory: true},
    {type:'select', key: 'interestType', label:'Interest type', options: [{value:'Annual interest',label:'Annual interest'},{value:'Total interest',label:'Total interest'}]},
    {type:'percentage', key: 'brokerageEquities', label:'Equities %', showHistory: true},
    {type:'percentage', key: 'brokerageCash', label:'Cash %', showHistory: true},
    {type:'percentage', key: 'brokerageFixedIncome', label:'Fixed Income %', showHistory: true},
    {type:'percentage', key: 'brokerageCrypto', label:'Crypto %', showHistory: true},
    {type:'percentage', key: 'brokerageReits', label:'REITs %', showHistory: true},
    {type:'number', key: 'expectedSalePrice', label:'Expected sale price ', showHistory: false , shouldBeHiddenIncognito : true},
    {type:'textarea',key:'remarks',label: asset_fields.REMARKS_LABEL, description: asset_fields.REMARKS_DESCRIPTION},
    {type:'default-cashAccount', key:'defaultCashAccountId',label:'Default cash account' },
    {type:'connectedLiability', key:'connectedLiability',label:'Does this property have a loan connected to it?' },
    {type:'number', key:'originalLoanAmount',label:'Original Loan Amount' , shouldBeHiddenIncognito : true},
    {type:'number', key:'totalInves',label:'Original Loan Amount' , shouldBeHiddenIncognito : true},
    {type:'text', key:'onlinePlatformUrl',label: asset_fields.ONLINE_PLATFORM_URL_LABEL, description: asset_fields.ONLINE_PLATFORM_URL_DESCRIPTION},
    {type:'checkbox', key:'distributionReinvested',label: asset_fields.DISTRIBUTION_REINVESTED_LABEL, description: asset_fields.DISTRIBUTION_REINVESTED_DESCRIPTION},
];

export const assetFieldsObj = assetFields.reduce(
    (acc, curr) => ((acc[curr.name] = curr), acc), {}, /* eslint-disable-line no-sequences*/ 
);

export const assetCommonFields = assetFields.reduce(
    (acc, curr) => ((acc[curr.name||curr.key] = curr), acc), {}, /* eslint-disable-line no-sequences*/ 
);

// const percentageDisplay = (value) => { return value * 100 + ' %'};
const percentageDisplay = (value) => { return value + ' %'};
export const attributeDisplayFormats = {
    apy: percentageDisplay,
    holdings: percentageDisplay,
    expectedIRR: percentageDisplay,

    brokerageEquities: percentageDisplay,
    brokerageCash: percentageDisplay,
    brokerageFixedIncome: percentageDisplay,
    brokerageCrypto: percentageDisplay,
    brokerageReits: percentageDisplay,

    annualIncreaseInValue: percentageDisplay,
    expectedROI: percentageDisplay,
    interest: percentageDisplay,
    expectedAnnualDividend: percentageDisplay
}

export const attributeLabels = {
    apy: "APY",
    expectedMonthlyContribution: "Expected monthly contribution",
    expectedIRR: "Expected IRR %",
    brokerageEquities: "Equities %",
    brokerageCash: "Cash %",
    brokerageFixedIncome: "Fixed Income %",
    brokerageCrypto: "Crypto %",
    brokerageReits: "REITs %",
    cashInvested: "Cash Invested",
    monthlyRent: "Monthly rent",
    expectedAnnualRevenue: "Expected annual revenue",
    expectedMonthlyExpenses: "Expected monthly expenses",
    annualIncreaseInValue: "Annual increase in value",
    expectedAnnualDistribution: "Expected annual distribution",
    expectedROI: "Expected ROI",
    pricePerShare: "Price per share",
    sharesPurchased: "Shares purchased",
    expectedAnnualDividend: "Expected annual dividend %",
    numberOfShares: "Number of options (Shares)",
    exercisePricePerShare: "Exercise price per share",
    expectedSalePrice: "Expected sale price",
    principalRepaymentPeriod: "Principal repayment period",
    interestRepaymentPeriod: "Interest repayment period",
    expectedDistributionPeriod: "Expected distribution period"
}