import { Drawer, Tabs } from "antd";
import { Observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowDownIcon, CloseXIcon, MobileSearchIcon } from "../../../../../../assets/icons/common/common-icons";
import { ROUTES } from "../../../../../../common/constants/routes.const";
import { navigateToUrl } from "../../../../../../common/utils/navigation.utils";
import { useAnalytics } from "../../../../../app/data/AnalyticsProvider";
import { useStore } from '../../../../../app/data/root.store';
import { FiltersButton, SearchButton } from "../../../../../layout/components/Header/MobileHeader.styles";
import { HeaderSearchBox } from "../../../../../layout/components/HeaderSearchBox/HeaderSearchBox";
import { HeroHeader, HeroMobileBackButtonWrapper, HeroTabTitle, HeroTabTitleWrapper, HeroTabsWrapper, HeroTitle, HeroWrapper, MobileHeaderActionsWrapper, MobileIconTabWrapper, MobileOverViewInflowOutflowSelectorButton, MobileOverViewInflowOutflowSelectorWrapper, OverviewInflowOutflowDrawerCloseIconWrapper } from '../../../../components/WealthLayout.styles';
import { DrawerHeader, DrawerHeaderTitle } from "../items-list/ItemsListHeaderTabs.styles";
import { InflowOutflowHeroContent } from './InflowOutflowContent/InflowOutflowHeroContent';
import { OverviewHeroContent } from './OverviewHeroContent/OverviewHeroContent';
import { OverviewInflowOutflowMenuItem, OverviewInflowOutflowMenuItemDescription, OverviewInflowOutflowMenuItemList, OverviewInflowOutflowMenuItemText, OverviewInflowOutflowMenuItemTitle } from "./WealthHero.styles";
import { WealthHeroTitle } from './WealthHeroTitle';
import { WealthMoreActions } from './WealthMoreActions';
import { EmptyState } from "./EmptyState/EmptyState";
import { TrendsHeroContent } from "./TrendsHeroContent/TrendsHeroContent";

const { TabPane } = Tabs;

export function WealthHero(props) {
  const { isOnlyHeader, headerNotFixed, withoutIcon } = props;
  const { wealthStore, filtersStore, uiStore, userStore } = useStore();
  const [showSearchBox, setShowMobileSearchBox] = useState(false)
  const [showOverviewInflowOutflowDrawer, setShowOverviewInflowOutflowDrawer] = useState(false);
  let history = useHistory();
  const { mixpanelTrack } = useAnalytics();

  const overviewInflowOutflowMenuItems = [
    { title: 'Overview', description: 'Display class allocation and summery of\nthe list below', key: 'overview', icon: <OverviewMobileIcon color={'#000'} /> },
    { title: 'Trends', description: 'Display a visual trend representation of portfolio data over a selected timeframe', key: 'trends', icon: <TrendsMobileIcon color={'#000'} /> },
    { title: 'Inflow / Outflow', description: `The total per timeframe of the actual\nand target inflow / out flow events `, key: 'cashflow', icon: <CashflowMobileIcon color={'#000'} /> }
  ]

  const heroContentComponents = {
    overview: <OverviewHeroContent />,
    trends: <TrendsHeroContent />,
    cashflow: <InflowOutflowHeroContent />
  };

  useEffect(() => {
    wealthStore.setUnSelectedClass();
    // console.log("Here?");
    // wealthStore.setViewOf('all');
    // return () => { }
  }, [wealthStore]);

  const handleTabChange = (key) => {
    wealthStore.setViewType(key);
    const title = overviewInflowOutflowMenuItems.find(item => item.key === key)?.title;
    mixpanelTrack('Tab Clicked', {
      tab_text: title,
      type: 'tab',
      group: `Wealth/${filtersStore?.filterTags.map(ft => ft.title).join('|')}`
    });
  }

  const handleFilterBoxOnClick = () => {
    // filtersStore.fetchData();
    filtersStore.setShowDrawer(true);
  }

  const handleMobileBackClick = () => {
    if (filtersStore.countActiveFilters) {
      filtersStore.handleClearFilterSelections();
      wealthStore.refreshWealthData(false, history);
    }
    navigateToUrl(history, ROUTES.wealth.url);
  }

  const handleViewTypeChange = (key) => {
    wealthStore.setViewType(key);
    setShowOverviewInflowOutflowDrawer(false);
  }
  
  return (<Observer>{() => (
    <>
      <HeroWrapper borderlessBottom={!wealthStore.isEmptyFilterResults}>
        <HeroHeader isFixed={uiStore.isMobileHeaderFixed} headerNotFixed={headerNotFixed} isSearchOpen={showSearchBox} withFilters={!!filtersStore.countActiveFilters}>
          {!showSearchBox && <HeroTitle>
            {!filtersStore.countActiveFilters ?
              <WealthHeroTitle withoutIcon={withoutIcon} title={`${userStore.isViewingOwnPortfolio ? 'My' : `${userStore.firstNameCapitalize}'s`} portfolio`} iconName='home' /> :
              <>
                {!uiStore.isDesktopView && <HeroMobileBackButtonWrapper onClick={handleMobileBackClick}><ArrowDownIcon /></HeroMobileBackButtonWrapper>}
                <WealthHeroTitle tag={filtersStore.filterTags} withoutIcon={withoutIcon} />
              </>
            }
          </HeroTitle>}
          {uiStore.isDesktopView && !wealthStore.isEmptyFilterResults &&
            <HeroTabsWrapper>
              <Tabs activeKey={wealthStore.viewType} onChange={handleTabChange} >
                <TabPane tab={<HeroTabTitleWrapper><HeroTabTitle>{uiStore.isDesktopView ? 'Overview' : <MobileIconTabWrapper><OverviewMobileIcon /></MobileIconTabWrapper>}</HeroTabTitle></HeroTabTitleWrapper>} key="overview" />
                <TabPane tab={<HeroTabTitleWrapper><HeroTabTitle>{uiStore.isDesktopView ? 'Trends' : <MobileIconTabWrapper><OverviewMobileIcon /></MobileIconTabWrapper>}</HeroTabTitle></HeroTabTitleWrapper>} key="trends" />
                <TabPane tab={<HeroTabTitleWrapper><HeroTabTitle id={'inflowOutflowTabButton'}>{uiStore.isDesktopView ? 'Inflow/Outflow' : <MobileIconTabWrapper><CashflowMobileIcon /></MobileIconTabWrapper>}</HeroTabTitle></HeroTabTitleWrapper>
                } key="cashflow" />
              </Tabs>
            </HeroTabsWrapper>
          }


          {!uiStore.isDesktopView &&
            <MobileHeaderActionsWrapper isSearchOpen={showSearchBox}>
              {showSearchBox ? <HeaderSearchBox mobile /> :
                <FiltersButton onClick={handleFilterBoxOnClick} style={{ mrginLeft: 0 }}>
                  {/* <LottieHoverIcon hoverParentRef={filterButtonRef} lottieFile={lottieFilterIcon} style={{ width: '24px', height: '24px' }} speed={2} /> */}
                  Filters
                </FiltersButton>}
              <SearchButton onClick={() => { setShowMobileSearchBox(!showSearchBox) }}>
                {showSearchBox ? <CloseXIcon /> : <MobileSearchIcon />}
              </SearchButton>
            </MobileHeaderActionsWrapper>}



          {uiStore.isDesktopView && <WealthMoreActions
            listViewMode={wealthStore.listViewMode}
            viewType={wealthStore.viewType}
            allocation={wealthStore.allocateBy}
            isBulkEditMode={wealthStore.isBulkEditMode}
            setEditBulkItemsMode={(isEdit) => wealthStore.setIsBulkEditMode(isEdit, true)}
          />}

        </HeroHeader>

        {!isOnlyHeader && <>

          {!uiStore.isDesktopView && !wealthStore.isEmptyFilterResults &&
            <MobileOverViewInflowOutflowSelectorWrapper isHeaderFixed={uiStore.isMobileHeaderFixed}>

              <MobileOverViewInflowOutflowSelectorButton onClick={() => { setShowOverviewInflowOutflowDrawer(true) }}>
                {overviewInflowOutflowMenuItems.find(item => item.key === wealthStore.viewType)?.title}<ArrowDownIcon />
              </MobileOverViewInflowOutflowSelectorButton>

              <Drawer
                closable={false}
                visible={showOverviewInflowOutflowDrawer}
                width={340}
                height={'auto'}
                className={'overviewInflowOutflowDrawer overviewInflowOutflowDrawer_mobile'}
                placement={'bottom'}
                onClose={() => { setShowOverviewInflowOutflowDrawer(false) }}
              >
                <DrawerHeader>
                  <DrawerHeaderTitle>Select view</DrawerHeaderTitle>
                  <OverviewInflowOutflowDrawerCloseIconWrapper onClick={() => { setShowOverviewInflowOutflowDrawer(false) }}><DrawerCloseIcon /></OverviewInflowOutflowDrawerCloseIconWrapper>
                </DrawerHeader>

                <OverviewInflowOutflowMenuItemList>
                  {overviewInflowOutflowMenuItems.map((item) => (
                    <OverviewInflowOutflowMenuItem key={item.key} isSelected={item.key === wealthStore.viewType}>
                      {item.icon}
                      <OverviewInflowOutflowMenuItemText onClick={() => handleViewTypeChange(item.key)}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <OverviewInflowOutflowMenuItemTitle>{item.title}</OverviewInflowOutflowMenuItemTitle>
                          <OverviewInflowOutflowMenuItemDescription>{item.description}</OverviewInflowOutflowMenuItemDescription>
                        </div>
                      </OverviewInflowOutflowMenuItemText>
                      {item.key === wealthStore.viewType && <SelectedMenuItemIcon />}
                    </OverviewInflowOutflowMenuItem>
                  ))}
                </OverviewInflowOutflowMenuItemList>
              </Drawer>
            </MobileOverViewInflowOutflowSelectorWrapper>
          }
          {wealthStore.isEmptyFilterResults ? <OverviewHeroContent /> : heroContentComponents[wealthStore.viewType]}
        </>}
      </HeroWrapper>
      {
        wealthStore.isEmptyFilterResults && filtersStore.singleClassFilter &&
        <EmptyState filter={filtersStore.singleClassFilter.id} />
      }

    </>

  )}</Observer>)
}

const OverviewMobileIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 12L22.75 12C22.75 11.8011 22.671 11.6103 22.5303 11.4697C22.3897 11.329 22.1989 11.25 22 11.25L22 12ZM11.5 12L10.75 12C10.75 12.4142 11.0858 12.75 11.5 12.75L11.5 12ZM11.5 2.01228L12.25 2.01228C12.25 1.80697 12.1658 1.61063 12.0171 1.46907C11.8684 1.3275 11.6682 1.25309 11.4631 1.26319L11.5 2.01228ZM10.75 21.9877C10.75 22.4019 11.0858 22.7377 11.5 22.7377C11.9142 22.7377 12.25 22.4019 12.25 21.9877L10.75 21.9877ZM12.25 10.5C12.25 10.0858 11.9142 9.75 11.5 9.75C11.0858 9.75 10.75 10.0858 10.75 10.5L12.25 10.5ZM21.25 12C21.25 17.1086 17.1086 21.25 12 21.25L12 22.75C17.9371 22.75 22.75 17.9371 22.75 12L21.25 12ZM22 11.25L11.5 11.25L11.5 12.75L22 12.75L22 11.25ZM12.25 12L12.25 2.01228L10.75 2.01228L10.75 12L12.25 12ZM2.75 12C2.75 7.04668 6.64373 3.00233 11.5369 2.76137L11.4631 1.26319C5.77514 1.54329 1.25 6.24296 1.25 12L2.75 12ZM12 21.25C6.89137 21.25 2.75 17.1086 2.75 12L1.25 12C1.25 17.9371 6.06294 22.75 12 22.75L12 21.25ZM12.25 21.9877L12.25 10.5L10.75 10.5L10.75 21.9877L12.25 21.9877Z" fill={color || "white"} />
      <path d="M12 2.75C17.1086 2.75 21.25 6.89137 21.25 12L22.75 12C22.75 6.06294 17.9371 1.25 12 1.25L12 2.75ZM2.75 12C2.75 6.89136 6.89137 2.75 12 2.75L12 1.25C6.06294 1.25 1.25 6.06294 1.25 12L2.75 12ZM12 21.25C6.89137 21.25 2.75 17.1086 2.75 12L1.25 12C1.25 17.9371 6.06294 22.75 12 22.75L12 21.25ZM12 22.75C17.9371 22.75 22.75 17.9371 22.75 12L21.25 12C21.25 17.1086 17.1086 21.25 12 21.25L12 22.75Z" fill={color || "white"} />
    </svg>
  )
}

const TrendsMobileIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M3 17.0014L9 11.0014L13 15.0014L20.625 7.375" stroke="#585D66" stroke-width="1.5" stroke-linecap="square" />
      <path d="M14 7L21 7L21 14" stroke="#585D66" stroke-width="1.5" stroke-linecap="square" />
    </svg>
  )
}

const CashflowMobileIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.75 4C1.75 3.0335 2.5335 2.25 3.5 2.25H20.5C21.4665 2.25 22.25 3.0335 22.25 4V20C22.25 20.9665 21.4665 21.75 20.5 21.75H3.5C2.5335 21.75 1.75 20.9665 1.75 20V4ZM3.5 3.75C3.36193 3.75 3.25 3.86193 3.25 4V20C3.25 20.1381 3.36193 20.25 3.5 20.25H20.5C20.6381 20.25 20.75 20.1381 20.75 20V4C20.75 3.86193 20.6381 3.75 20.5 3.75H3.5Z" fill={color || "white"} />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.75 17L6.75 12L8.25 12L8.25 17L6.75 17Z" fill={color || "white"} />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.25 17V8H12.75V17H11.25Z" fill={color || "white"} />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.75 17L15.75 10H17.25L17.25 17H15.75Z" fill={color || "white"} />
    </svg>
  )
}

const DrawerCloseIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="8" y="8" width="28" height="28" rx="14" fill="#E8E9ED" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.4194 27.7301L16.5199 17.8306L17.5806 16.7699L27.4801 26.6694L26.4194 27.7301Z" fill="#585D66" />
      <path fillRule="evenodd" clipRule="evenodd" d="M27.4802 17.8306L17.5807 27.7301L16.52 26.6694L26.4195 16.7699L27.4802 17.8306Z" fill="#585D66" />
    </svg>
  )
}

const SelectedMenuItemIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5103 8.60401L10.5059 15.6125L6.99983 12.1076" stroke="black" strokeWidth="2" />
    </svg>
  )
}