import moment from "moment";
import { CURRENCY_SYMBOL } from "../../../../../common/constants/currency-symbol.const";
import { getTranslations } from "../../../../../common/translations/translations";
import { disabledMoreThanToday, monthDiff } from "../../../../../common/utils/dates.utils";
import { isNullOrUndefinedOrEmptyString, isNullOrUndefinedOrZeroOrEmptyString } from "../../../../../common/utils/object.utils";
import { HoldingsUnlockModal } from "../../../../wealth/pages/asset/components/Info/custom-form-components/HoldingsUnlockModal";
import { containerRow } from "../../common-fields/container-field.const";
import { masterInstitutionInputRows } from "../../common-fields/master-institution.const";
import { isBetweenZeroAndHundred, isRequired } from "../../input-validations";
import { simpleExitEventFieldRow } from "../../common-fields/exit-event-field.const";
import { floatFixed } from "../../../../../common/utils/number-display.utils";
import { riskLevelRow } from "../../common-fields/risk-level-field.const";
import { liquidityRow } from "../../common-fields/liquidity-field.const";
import { customClassAndSubClassRows } from "../../common-fields/custom-class-and-sub-class-field.const";
import { beneficiaryRow } from "../../common-fields/beneficiary-field.const";

const COMPANY_SECTOR_OPTIONS = [
    { value: 'Information Technology', label: 'Information Technology' },
    { value: 'Health Care', label: 'Health Care' },
    { value: 'Financials', label: 'Financials' },
    { value: 'Consumer Discretionary', label: 'Consumer Discretionary' },
    { value: 'Communication Services', label: 'Communication Services' },
    { value: 'Industrials', label: 'Industrials' },
    { value: 'Consumer Staples', label: 'Consumer Staples' },
    { value: 'Energy', label: 'Energy' },
    { value: 'Utilities', label: 'Utilities' },
    { value: 'Real Estate', label: 'Real Estate' },
    { value: 'Materials ', label: 'Materials' },
]

const monthsPerPeriodMap = {
    'Monthly' : 1,
    'Quarterly' : 3,
    'Semi-annually' : 6,
    'Annually' : 12
}

export const createEquityAddItemSections = (isEditForm) => {
    return ({ updateValue, updateField }) => {
        const { equity } = getTranslations().new_item_modal.private_businesses;
        return ([
            {
                title: 'General info',
                rows: [
                    {
                        fields: [
                            { key: 'title', validations: [isRequired], label: 'Company name', description: '', } // defaultValue: 'asdasd' }
                        ]
                    },
                    {
                        fields: [
                            { key: 'companySector', type: 'select', label: 'Company sector', description: '', options: COMPANY_SECTOR_OPTIONS, allowClear: true }
                        ]
                    },
                    ...(masterInstitutionInputRows('Fund / Platform used to invest', '', 4, false, updateValue)),
                    beneficiaryRow({label: 'Holding entity / Beneficiary', description:'The person or company listed as the owner of these shares'}),
                    {
                        fields: [
                            {
                                key: 'pricePerShare', label: 'Current PPS',
                                description: equity.PRICE_PER_SHARE_DESCRIPTION, generatePropsFn: (values) => {
                                    return { prefix: CURRENCY_SYMBOL[values.currency] || '' }
                                },
                                onBlur: ({ value, formValues }) => {
                                    if (formValues.sharesDistributions.length === 1) {
                                        updateValue('sharesDistributions', [{ ...formValues.sharesDistributions[0], pricePerShare: value }])
                                    }
                                }
                            },
                            {
                                key: 'currency', size: 0.3, defaultValue: 'USD', onChange: ({ value }) => {
                                    updateField('pricePerShare', { prefix: CURRENCY_SYMBOL[value] || '' });
                                    updateField('sharesDistributions', { prefix: CURRENCY_SYMBOL[value] || '' });
                                }
                            },
                            {
                                key: 'value', isHidden:true,defaultValue:0
                            },

                        ]
                    },
                    ...( isEditForm ? [
                        ...customClassAndSubClassRows({updateValue,updateField}),
                    ] : []),
                ]
            }, {
                title: 'Shares purchased / sold',
                fullWidth: true,
                margin: '0 -24px',
                rows: [
                    {
                        fields: [
                            {
                                key: 'sharesDistributions', label: 'Shares distributions', description: '', type: 'sharesTradingTable',
                                defaultValue: [{ date: moment().utc().set("D",15).startOf("D"), tempId: 0, eventType: 'Buy', sharesEventValue: '', numberOfShares: '', }], //isTouched: false }],


                                showHistory: false,
                                generatePropsFn: (values) => {
                                    return { prefix: CURRENCY_SYMBOL[values.currency] || 'USD', placeholder: CURRENCY_SYMBOL[values.currency], holdings: values.holdings }
                                },
                                validations: [
                                    {
                                        fn: (value) => {
                                            if (!value.length) {
                                                return false;
                                            }

                                            const isValid = value.map(row=>{
                                                if(!row.date || (row.eventType === 'Grant' && !row.numberOfShares) || (!row.sharesEventValue && row.eventType !== 'Grant')) {
                                                    return false;
                                                }
                                                return true;
                                            })
                                            if(isValid.includes(false)){
                                                return false;
                                            }
                                            return true
                                        },
                                        message: `Required`
                                    },
                                    {
                                        fn:(value)=>{
                                            if(value.length && value[0].eventType === 'Sell'){
                                                return false;
                                            }
                                            return true;
                                        },
                                        message:`First event has to be Buy or Grant`
                                    }
                                ],
                            },
                        ]
                    },
                ]
            }, {
                title: 'Info + Performance',
                rows: [
                    {
                        fields: [
                            {
                                key: 'expectedAnnualDividend', label: 'Expected Annual Distributions / Dividends %',
                                description: 'If you expect any personal annual income (other than w2) from this business, please note it here.'
                            }
                        ]
                    },
                    {
                        fields: [
                            {
                                key: 'expectedDistributionPeriod', label: 'Distribution Period', allowClear: true,
                                description: 'How often you Expect to receive the distributions listed above'
                            }
                        ]
                    },
                    {
                        fields: [
                            { key: 'expectedIRR', description: 'This refers to your expected annualized return from this business  (including cash distributions/dividends and potential exit amounts but on an annualized, IRR basis)' }
                        ]
                    },
                    ...(isEditForm ? [
                        containerRow({isEditForm, updateField}),
                        {
                            fields: [
                                { key: 'defaultCashAccountId' }
                            ]
                        },
                        riskLevelRow({updateField}),
                        liquidityRow({updateField}),
                    ] : []),
                    {
                        fields: [
                            { key: 'remarks', description: 'Total business value, your ownership percentage, relevant links etc.' }
                        ]
                    }
                ]
            }
        ])
    }
}

export const createOptionsAddItemSections = (isEditForm) => {
    return ({ updateValue, updateField }) => {
        const { options } = getTranslations().new_item_modal.private_businesses;
        return ([
            {
                title: 'General info',
                rows: [
                    {
                        fields: [
                            { key: 'title', label: 'Company name', description: '', validations: [isRequired] }
                        ]
                    },
                    {
                        fields: [
                            {
                                key: 'numberOfShares', label: 'Number of options',
                                description: options.NUMBER_OF_OPTIONS_DESCRIPTION,
                                onBlur: ({ value, formValues }) => {
                                    if (formValues.pricePerShare) {
                                        updateValue('value', value * formValues.pricePerShare);
                                    }
                                }
                            }
                        ]
                    },
                    {
                        fields: [
                            {
                                key: 'exercisePricePerShare', label: options.EXERCISE_PRICE_PER_SHARE_LABEL, description: options.EXERCISE_PRICE_PER_SHARE_DESCRIPTION,
                                generatePropsFn: (values) => {
                                    return { prefix: CURRENCY_SYMBOL[values.currency] || '' }
                                }
                            }
                        ]
                    },
                    {
                        fields: [
                            {
                                key: 'pricePerShare', label: options.PRICE_PER_SHARE_LABEL,
                                description: options.PRICE_PER_SHARE_DESCRIPTION,
                                onBlur: ({ value, formValues }) => {
                                    if (formValues.numberOfShares) {
                                        updateValue('value', value * formValues.numberOfShares);
                                    }

                                }, generatePropsFn: (values) => {
                                    return { prefix: CURRENCY_SYMBOL[values.currency] || '' }
                                }
                            },
                            {
                                key: 'pricePerShareCurrency', size: 0.3, defaultValue: 'USD', onChange: ({ value }) => {
                                    updateField('pricePerShare', { prefix: CURRENCY_SYMBOL[value] || '' });
                                    updateField('exercisePricePerShare', { prefix: CURRENCY_SYMBOL[value] || '' });
                                    updateField('value', { prefix: CURRENCY_SYMBOL[value] || '' });
                                    updateValue('currency', value);
                                }
                            },
                        ]
                    },
                    {
                        fields: [
                            {
                                key: 'value', label: options.VALUE_LABEL, validations: [isRequired],
                                description: options.VALUE_DESCRIPTION, generatePropsFn: (values) => {
                                    return { prefix: CURRENCY_SYMBOL[values.currency] || '' }
                                }
                            },
                            {
                                key: 'currency', size: 0.3, onChange: ({ value }) => {
                                    updateField('pricePerShare', { prefix: CURRENCY_SYMBOL[value] || '' });
                                    updateField('exercisePricePerShare', { prefix: CURRENCY_SYMBOL[value] || '' });
                                    updateField('value', { prefix: CURRENCY_SYMBOL[value] || '' });
                                    updateValue('pricePerShareCurrency', value);
                                }
                            }
                        ]
                    },
                    ...( isEditForm ? [
                        ...customClassAndSubClassRows({updateValue,updateField}),
                    ] : []),
                ]
            }, {
                title: 'Projection',
                rows: [
                    {
                        fields: [
                            {
                                key: 'startDate', label: 'Grant date',
                                description: 'The initial date you received these options'
                            }
                        ]
                    },
                    {
                        fields: [
                            { key: 'maturityFromGrant' }
                        ]
                    },
                    {
                        fields: [
                            { key: 'vestingPeriod' }
                        ]
                    },
                ]
            }, {
                title: `More info`,
                rows: [
                    {
                        fields: [
                            { key: 'optionExpirationPeriod', label: options.OPTION_EXPIRATION_PERIOD_LABEL, description: options.OPTION_EXPIRATION_PERIOD_DESCRIPTION }
                        ]
                    },
                    ...(isEditForm ? [
                        containerRow({isEditForm, updateField}),
                        {
                            fields: [
                                { key: 'defaultCashAccountId' }
                            ]
                        },
                    ] : []),
                    beneficiaryRow({}),
                    ...(isEditForm ? [
                        riskLevelRow({updateField}),
                        liquidityRow({updateField}),
                    ] : []),
                    {
                        fields: [
                            { key: 'remarks' }
                        ]
                    }
                ]
            }
        ])
    };
}

export const createTraditionalBusinessAddItemSections = (isEditForm) => {
    return ({ updateValue, updateField, updateSection }) => {
        const { traditional_business } = getTranslations().new_item_modal.private_businesses;
        return ([
            {
                title: 'General info',
                rows: [
                    {
                        fields: [
                            { key: 'title', validations: [isRequired], label: 'Business name', description: '' }
                        ]
                    },
                    {
                        fields: [
                            { key: 'companySector', type: 'select', label: 'Company sector', description: '', options: COMPANY_SECTOR_OPTIONS, allowClear: true }
                        ]
                    },
                    {
                        fields: [
                            {
                                key: 'value', type: 'numberWithHoldings', label: traditional_business.VALUE_LABEL, validations: [isRequired],
                                description: traditional_business.VALUE_DESCRIPTION,
                                onBlur:({value})=>{
                                    updateValue('cashInvested',value);
                                    updateField('expectedAnnualRevenue',{relativeAmount: value});
                                    updateField('expectedAnnualExpense',{relativeAmount: value});
                                },
                                generatePropsFn: (values) => {
                                    return { prefix: CURRENCY_SYMBOL[values.currency] || '' }
                                }
                            },
                            {
                                key: 'currency', size: 0.3, onChange: ({ value }) => {
                                    updateValue('cashInvestedCurrency', value);
                                    updateField('value', { prefix: CURRENCY_SYMBOL[value] || '' });
                                    updateField('cashInvested', { prefix: CURRENCY_SYMBOL[value] || '' });
                                    updateField('expectedAnnualRevenue', { prefix: CURRENCY_SYMBOL[value] || '' });
                                    updateField('expectedAnnualExpense', { prefix: CURRENCY_SYMBOL[value] || '' });
                                }
                            },
                            {
                                key: 'cashInvested',
                                isHidden: true,
                                defaultValueFn: (values) => values.value
                            },
                        ]
                    },
                    ...(isEditForm ? [
                        ...customClassAndSubClassRows({updateValue,updateField}),
                    ] : [
                        {
                            fields: [
                                {
                                    key: 'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isHidden: true,
                                    validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                    validations:[isBetweenZeroAndHundred],
                                    onBlur: ({ value }) => {
                                        updateField('value', { holdings: value || '' });
                                        updateField('expectedAnnualRevenue',{holdings:value || ''});
                                        updateField('expectedAnnualExpense',{holdings:value || ''});
                                    },
                                    generatePropsFn: (values) => {
                                        return { isHidden: values.holdings === 100 }
                                    }
                                }
                            ]
                        },
                        {
                            fields: [
                                {
                                    type: 'checkbox', key: 'isPartialHolding', checkboxLabel: 'This asset is 100% owned by me', description: 'If you are a co-owner of this account, uncheck this box.',
                                    fieldCustomStyle: { marginTop: '-24px' },
                                    defaultValue: true,
                                    onChange: ({ value }) => {
                                        const newHoldingValue = value ? 100 : 50;
                                        updateValue('holdings', newHoldingValue);
                                        updateField('holdings', { isHidden: value });
                                        updateField('value', { holdings: newHoldingValue });
                                        updateField('expectedAnnualRevenue',{holdings:newHoldingValue || ''});
                                        updateField('expectedAnnualExpense',{holdings:newHoldingValue || ''});
                                    }
                                },
                            ]
                        },
                    ]),
                    {
                        fields: [
                            {
                                key: 'startDate', label: traditional_business.START_DATE_LABEL, description: `This is the date when you officially start owning the business`, defaultValueFn: () => { return new Date() }, validations: [isRequired],
                                disabledDate: disabledMoreThanToday
                            }
                        ]
                    },
                ]
            },
            {
                fullWidth:true,
                title: 'Target distribution schedule',
                rows: [
                    {fields:[
                        {key:'expectedDistributions', type:'radio', label: () => <div>Do you have expected <span style={{fontWeight:700}}>distributions/dividends</span>?</div>, 
                        defaultValueFn: (values) => { return !isEditForm ? false : (values.expectedAnnualRevenue ? values.expectedAnnualRevenue.length > 0 : false )},
                        onChange: ({value, formValues}) => {
                            resetExpectedDistributionPeriod({updateValue, updateField});
                            if (value) { // yes
                                updateValue('expectedAnnualRevenue', [{ value: '', date: moment(formValues.startDate).utc(), remarks: '', tempId: 1}])
                                updateField('expectedAnnualRevenue', {startDate: moment(value)})
                            } else { // no
                                updateValue('expectedAnnualRevenue', []);
                                updateField('expectedAnnualRevenue', {
                                    customAnnualReturnToolTipText: `The total amount you expect to receive on this investment annually. 
                                    The percentage rate is based on your asset's initial investment ${(CURRENCY_SYMBOL[formValues.currency] + formValues.cashInvested) || ''}`
                                });
                            }
                            updateField('expectedDistributionPeriod', {isHidden: !value})
                            updateField('expectedAnnualRevenue', {isHidden: !value})
                        },
                        optionType: 'button',
                        options:[
                            {label: 'Yes', value: true},
                            {label: 'No', value: false}
                        ],
                    }
                    ]},
                    {fields:[
                        {key:'expectedDistributionPeriod', label: 'Distribution frequency', description: '',
                        type:'select',
                        withDescriptionInOption: true,
                        defaultValue: 'Monthly',
                        withSuffixIcon: true,
                        options: [
                            {value:'Monthly',label:'Monthly'},
                            {value:'Quarterly',label:'Quarterly', description:'Transactions date: Apr, Jul, Oct, Jan (next year).' , selectedDescription:'Jan, Apr, Jul, Oct each year'},
                            {value:'Semi-annually',label:'Semi-annually', description:'Transactions date: Jul and Jan (next year).' , selectedDescription:'Apr, Jul each year'},
                            {value:'Annually',label:'Annually', description:'Transactions date: Jan (next year).', selectedDescription:'January each year'},
                            {value:'Custom',label:'Custom...', },
                        ],
                        onChange: ({value, formValues}) => {
                            updateField('expectedDistributionStartDate', { isHidden: value !== 'Custom' });
                            updateField('expectedDistributionCustomInterval', { isHidden: value !== 'Custom' });
                            updateField('expectedDistributionCustomPeriod', { isHidden: value !== 'Custom' });
                            updateField('expectedAnnualRevenue', { 
                                monthsPerPeriod: monthsPerPeriodMap[value] ? monthsPerPeriodMap[value] : formValues.expectedDistributionCustomInterval || 1 ,
                                period: value === 'Custom' ? 'Periodic' : value
                            });
                        },
                        generatePropsFn: (formValues) => {
                            return {
                                isHidden: !isEditForm ? true : !formValues.expectedAnnualRevenue?.length
                            }
                        }
                    }
                    ]},
                    {fields:[
                        {key:'expectedDistributionCustomInterval', label:'Repeat every:', type:'number', withArrows:true,
                        maxValue: 15, minValue: 0, defaultValue:2, decimalScale: 0,
          
                        generatePropsFn: (formValues) => {
                            return { isHidden: formValues.expectedDistributionPeriod !== 'Custom' || !formValues.expectedAnnualRevenue || formValues.expectedAnnualRevenue?.length === 0 }
                        },
                        onChange: ({value, formValues}) => {
                            updateField('expectedAnnualRevenue', {monthsPerPeriod: value * ( formValues.expectedDistributionCustomPeriod === 'Years' ? 12 : 1 ) })  
                        },
                        validations:[
                            {
                                fn: (value) => {
                                    return value < 16;
                                },
                                message:'Maximum period allowed: 15'
                            }
                        ]},
                        {key:'expectedDistributionCustomPeriod', type:'select', defaultValue:'Months',
                        withDescriptionInOption: true,
                        withSuffixIcon: true,
                        generatePropsFn: (formValues) => {
                            return { isHidden: formValues.expectedDistributionPeriod !== 'Custom' || !formValues.expectedAnnualRevenue || formValues.expectedAnnualRevenue?.length === 0 }
                        },
                        onChange: ({value, formValues}) => {
                            updateField('expectedAnnualRevenue', {monthsPerPeriod: formValues.expectedDistributionCustomInterval * ( value === 'Years' ? 12 : 1 ) })  
                        },
                        options:[
                            {value: 'Months', label:'Months'},
                            {value: 'Years', label:'Years'},
                        ]},
                    ]},
                    {fields:[
                        {
                            key:'expectedAnnualRevenue', 
                            label: '',
                            type:'scheduleTable',
                            defaultValueFn: (values) => {
                                if (isEditForm) { // asset info
                                    if (values.expectedAnnualRevenue) return values.expectedAnnualRevenue;
                                } else { // add item flow
                                    if (!values.expectedAnnualRevenue || values.expectedAnnualRevenue?.length === 0) {
                                        return [{ value: "", date: moment(values.startDate).utc(), remarks: '', tempId: 1}]
                                    }
                                }
                                return null;
                            },
                            onChange: ({value, formValues}) => {
                                if (!formValues.isExpectedAnnualRevenueTouched){
                                    updateValue('isExpectedAnnualRevenueTouched' , true);
                                }
                            },
                            showHistory: false,
                            showPercentage: true,
                            generatePropsFn : (values)=> {
                                const exPer = values.expectedDistributionPeriod;
                                return {
                                    prefix: CURRENCY_SYMBOL[values.currency] || 'USD', 
                                    placeholder: CURRENCY_SYMBOL[values.currency],
                                    holdings:values.holdings , 
                                    startDate: values.startDate,
                                    relativeAmount: values.cashInvested || values.value,
                                    isPercentageDisplay: false,
                                    monthsPerPeriod: monthsPerPeriodMap[values.expectedDistributionPeriod] ? monthsPerPeriodMap[values.expectedDistributionPeriod] : (values.expectedDistributionCustomInterval || 1 ),
                                    period: !exPer ? 'Monthly' : exPer === 'Custom' ? 'Periodic' : exPer,
                                    customAnnualReturnToolTipText: `The total amount you expect to receive on this investment annually. 
                                    The percentage rate is based on your asset's initial investment ${(CURRENCY_SYMBOL[values.currency] + values.cashInvested) || ''}`,
                                    defaultEditedRow: !isEditForm,
                                    isHidden: !isEditForm ? true : !values.expectedAnnualRevenue?.length
                                }
                            },
                            validations: [
                                {
                                  fn: (value, formValues) => {
                                    return Array.isArray(value) ? (value.length === 0 || value.every(item=> isNullOrUndefinedOrEmptyString(item.value)) || value.every(item=> !isNullOrUndefinedOrEmptyString(item.value)) ) : true ; 
                                  },
                                  bottomErrorMessage: (value, formValues) => {
                                    return `Empty value for a row is not valid`;
                                  },
                                },
                            ],
                            setFnForSave: (isDistribution, value, formValues) => {
                                if (!Array.isArray(value) || value.every(item => isNullOrUndefinedOrEmptyString(item.value))) {
                                    updateValue('expectedDistributions', false);
                                    updateValue('expectedAnnualRevenue', []);
                                    return [];
                                }
                                return value;
                            }
                        },
                        {
                            key:'isExpectedAnnualRevenueTouched', 
                            isHidden:true,
                            defaultValue : isEditForm
                        },
                    ]},
                ]
            },
            exitEventRowSection(updateValue,updateField,isEditForm),
            {
                fullWidth:true,
                title: 'Target contribution schedule',
                rows: [
                    {fields:[
                        {key:'expectedContributions', type:'radio', label: () => <div>Do you have expected <span style={{fontWeight:700}}>contributions</span>?</div>, 
                        defaultValueFn: (values) => { return !isEditForm ? false : (values.expectedAnnualExpense ? values.expectedAnnualExpense.length > 0 : false )},
                        onChange: ({value, formValues}) => {
                            resetExpectedContributionPeriod({updateValue, updateField});
                            if (value) { // yes
                                updateValue('expectedAnnualExpense', [{ value: '', date: moment(formValues.startDate).utc(), remarks: '', tempId: 1}])
                                updateField('expectedAnnualExpense', {startDate: moment(value)})
                            } else { // no
                                updateValue('expectedAnnualExpense', []);
                                updateField('expectedAnnualExpense', {
                                    customAnnualReturnToolTipText: `The total amount you expect to receive on this investment annually. 
                                    The percentage rate is based on your asset's initial investment ${(CURRENCY_SYMBOL[formValues.currency] + formValues.cashInvested) || ''}`
                                });
                            }
                            updateField('expectedContributionPeriod', {isHidden: !value})
                            updateField('expectedAnnualExpense', {isHidden: !value})
                        },
                        optionType: 'button',
                        options:[
                            {label: 'Yes', value: true},
                            {label: 'No', value: false}
                        ],
                    }
                    ]},
                    {fields:[
                        {key:'expectedContributionPeriod', label: 'Contribution frequency', description: '',
                        type:'select',
                        withDescriptionInOption: true,
                        defaultValue: 'Monthly',
                        withSuffixIcon: true,
                        options: [
                            {value:'Monthly',label:'Monthly'},
                            {value:'Quarterly',label:'Quarterly', description:'Transactions date: Apr, Jul, Oct, Jan (next year).' , selectedDescription:'Jan, Apr, Jul, Oct each year'},
                            {value:'Semi-annually',label:'Semi-annually', description:'Transactions date: Jul and Jan (next year).' , selectedDescription:'Apr, Jul each year'},
                            {value:'Annually',label:'Annually', description:'Transactions date: Jan (next year).', selectedDescription:'January each year'},
                            {value:'Custom',label:'Custom...', },
                        ],
                        onChange: ({value, formValues}) => {
                            updateField('expectedContributionStartDate', { isHidden: value !== 'Custom' });
                            updateField('expectedContributionCustomInterval', { isHidden: value !== 'Custom' });
                            updateField('expectedContributionCustomPeriod', { isHidden: value !== 'Custom' });
                            updateField('expectedAnnualExpense', { 
                                monthsPerPeriod: monthsPerPeriodMap[value] ? monthsPerPeriodMap[value] : formValues.expectedDistributionCustomInterval || 1 ,
                                period: value === 'Custom' ? 'Periodic' : value
                            });
                        },
                        generatePropsFn: (formValues) => {
                            return {
                                isHidden: !isEditForm ? true : !formValues.expectedAnnualExpense?.length
                            }
                        }
                    }
                    ]},
                    {fields:[
                        {key:'expectedContributionCustomInterval', label:'Repeat every:', type:'number', withArrows:true,
                        maxValue: 15, minValue: 0, defaultValue:2, decimalScale: 0,
          
                        generatePropsFn: (formValues) => {
                            return { isHidden: formValues.expectedContributionPeriod !== 'Custom' || !formValues.expectedAnnualExpense || formValues.expectedAnnualExpense?.length === 0 }
                        },
                        onChange: ({value, formValues}) => {
                            updateField('expectedAnnualExpense', {monthsPerPeriod: value * ( formValues.expectedContributionCustomPeriod === 'Years' ? 12 : 1 ) })  
                        },
                        validations:[
                            {
                                fn: (value) => {
                                    return value < 16;
                                },
                                message:'Maximum period allowed: 15'
                            }
                        ]},
                        {key:'expectedContributionCustomPeriod', type:'select', defaultValue:'Months',
                        withDescriptionInOption: true,
                        withSuffixIcon: true,
                        generatePropsFn: (formValues) => {
                            return { isHidden: formValues.expectedContributionPeriod !== 'Custom' || !formValues.expectedAnnualExpense || formValues.expectedAnnualExpense?.length === 0 }
                        },
                        onChange: ({value, formValues}) => {
                            updateField('expectedAnnualExpense', {monthsPerPeriod: formValues.expectedContributionCustomInterval * ( value === 'Years' ? 12 : 1 ) })  
                        },
                        options:[
                            {value: 'Months', label:'Months'},
                            {value: 'Years', label:'Years'},
                        ]},
                    ]},
                    {fields:[
                        {
                            key:'expectedAnnualExpense',
                            label: '',
                            type:'scheduleTable',
                            defaultValueFn: (values) => {
                                if (isEditForm) { // asset info
                                    if (values.expectedAnnualExpense) return values.expectedAnnualExpense;
                                } else { // add item flow
                                    if (!values.expectedAnnualExpense || values.expectedAnnualExpense?.length === 0) {
                                        return [{ value: "", date: moment(values.startDate).utc(), remarks: '', tempId: 1}]
                                    }
                                }
                                return null;
                            },
                            onChange: ({value, formValues}) => {
                                if (!formValues.isExpectedAnnualExpenseTouched){
                                    updateValue('isExpectedAnnualExpenseTouched' , true);
                                }
                            },
                            showHistory: false,
                            showPercentage: true,
                            customAnnualReturnLabel: 'Annual contribution %',
                            customColumnsSize: ['105px','105px','105px','116px','auto'],
                            generatePropsFn : (values)=> {
                                const exPer = values.expectedContributionPeriod;
                                return {
                                    prefix: CURRENCY_SYMBOL[values.currency] || 'USD', 
                                    placeholder: CURRENCY_SYMBOL[values.currency],
                                    holdings:values.holdings , 
                                    startDate: values.startDate,
                                    relativeAmount: values.cashInvested || values.value,
                                    monthsPerPeriod: monthsPerPeriodMap[values.expectedContributionPeriod] ? monthsPerPeriodMap[values.expectedContributionPeriod] : (values.expectedContributionCustomInterval || 1 ),
                                    period: !exPer ? 'Monthly' : exPer === 'Custom' ? 'Periodic' : exPer,
                                    customAnnualReturnToolTipText: `The total amount you expect to contribute to this investment annually.`,
                                    defaultEditedRow: !isEditForm,
                                    isHidden: !isEditForm ? true : !values.expectedAnnualExpense?.length
                                }
                            },
                            validations: [
                                {
                                  fn: (value, formValues) => {
                                    return Array.isArray(value) ? (value.length === 0 || value.every(item=> isNullOrUndefinedOrEmptyString(item.value)) || value.every(item=> !isNullOrUndefinedOrEmptyString(item.value)) ) : true ; 
                                  },
                                  bottomErrorMessage: (value, formValues) => {
                                    return `Empty value for a row is not valid`;
                                  },
                                },
                            ],
                            setFnForSave: (isDistribution, value, formValues) => {
                                if (!Array.isArray(value) || value.every(item => isNullOrUndefinedOrEmptyString(item.value))) {
                                    updateValue('expectedContributions', false);
                                    updateValue('expectedAnnualExpense', []);
                                    return [];
                                }
                                return value;
                            }
                        },
                        {
                            key:'isExpectedAnnualExpenseTouched', 
                            isHidden:true,
                            defaultValue : isEditForm
                        },
                    ]},
                ]
            },
            {
                title: `More Info`,
                rows: [
                    {fields:[
                        {key:'expectedEndDate', label: 'Expected maturity date', description: `The date when you expect to liquidate this asset/account`,
                        // onChange:({value,formValues})=> {
                        //     if (!formValues.isExpectedAnnualRevenueTouched && value){
                        //         const yearsFromStartToEndDate = moment(value).diff(moment(formValues.startDate),"y") + ( moment(value).month() === moment(formValues.startDate).month() ? 0 : 1 );
                        //         const years = [...Array(yearsFromStartToEndDate+1).keys()];
                        //         const valueForYears = formValues.expectedAnnualCashReturn ? (formValues.cashInvested / 100 * formValues.expectedAnnualCashReturn) : '';
        
                        //         const scheduleValues = years.map(year=>({
                        //             value: year === yearsFromStartToEndDate ? 0 : valueForYears,
                        //             date: year === yearsFromStartToEndDate ? moment(value).utc().add(1,"M").set("D",15).startOf("D") : moment(formValues.startDate).utc().add(1,"M").add(year,'y').set("D",15).startOf("D"),
                        //             remarks: year === yearsFromStartToEndDate ? '' : "Year " + (+year+1),
                        //             tempId: +year+1
                        //         }));
                        //         updateValue('expectedAnnualRevenue', scheduleValues);
                        //     }
                        // }
                        isHidden: isEditForm,
                        setFnForSave: (isDistribution, value, formValues) => {
                            return isEditForm ? (formValues?.exitEvent?.date || null) : value;
                        },
                    },{ isHidden:true, key:'cashflowEventsAfterExit' ,  setFnForSave: (isDistribution, value, formValues) => {
                        return isEditForm ? !formValues?.exitEvent?.stopCreatingFutureEventsAfterExitEvent : false
                    },}
                    ]},
                    {
                        fields: [
                            { key: 'expectedIRR', description: 'This refers to your expected annualized return from this business (including cash distributions/dividends and potential exit amounts but on an annualized, IRR basis)',
                            onBlur:({value,formValues}) => {
                                const { startDate, expectedEndDate } = formValues;
                                if (!isNullOrUndefinedOrZeroOrEmptyString(value)) {
                                    const numberOfYears = (monthDiff(new Date(startDate), new Date(expectedEndDate)) / 12)
                                    const updatedExpectedMultiplier = (1 + (value / 100)) ** numberOfYears;
                                    updateValue('expectedMultiplier', floatFixed(updatedExpectedMultiplier, 2));
                                }
                            }
                             },
                            {key:'expectedMultiplier', description: traditional_business.expectedMultiplierDescription}
                        ]
                    },
                    ...(isEditForm ? [
                        containerRow({isEditForm, updateField}),
                        {
                            fields: [
                                {
                                    key: 'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isLocked: true, UnlockComponent: HoldingsUnlockModal,
                                    validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                    validations:[isBetweenZeroAndHundred],
                                }
                            ]
                        },
                        {
                            fields: [
                                { key: 'defaultCashAccountId' }
                            ]
                        }
                    ] : []),
                    beneficiaryRow({label: traditional_business.BENEFICIARY_LABEL, description: traditional_business.BENEFICIARY_DESCRIPTION}),
                    ...(isEditForm ? [
                        riskLevelRow({updateField}),
                        liquidityRow({updateField}),
                    ] : []),
                    {
                        fields: [
                            { key: 'remarks', description: 'Total business value, your ownership percentage, relevant links etc.' }
                        ]
                    }
                ]
            }
        ])
    }
}

const resetExpectedDistributionPeriod = ({updateValue, updateField}) => {
    updateValue('expectedDistributionPeriod', 'Monthly');
    updateField('expectedDistributionCustomInterval', {isHidden: true});
    updateField('expectedDistributionCustomPeriod', {isHidden: true});   
}

const resetExpectedContributionPeriod = ({updateValue, updateField}) => {
    updateValue('expectedContributionPeriod', 'Monthly');
    updateField('expectedContributionCustomInterval', {isHidden: true});
    updateField('expectedContributionCustomPeriod', {isHidden: true});   
}

const exitEventRowSection = (updateValue,updateField,isEditForm) => {
    return {
        title:'',
        key: 'exitEventSection',
        fullWidth:true,
        generatePropsFn : (values)=>{
            return {
                isHidden: !isEditForm,
            }
        },
        rows: [
            simpleExitEventFieldRow({
                isEditForm, 
                autoCalculateTooltip: <>Uncheck this box to edit the exit event amount manually.<br/><br/>Vyzer automatically calculates the expected exit event based on your target IRR, target distributions, capital calls, and all actual events during the asset's lifetime.</>
            })
        ]
    }
}