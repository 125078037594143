import styled from '@emotion/styled/macro'
import { Link } from 'react-router-dom';
import { flexCenter, textOverflow } from '../../../../../common/styles/combos.styles';
import { colors, views } from '../../../../../common/styles/theme.styles';
import { animateSpin } from '../../../../../common/styles/animations.styles';
import { css } from '@emotion/react';
// import { ArchiveAsset, Erase, LinkLiability, UnlinkLiability } from '../Icons/icons'
// import {  Erase, LinkLiability, UnlinkLiability } from '../Icons/icons'

export const ItemViewHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px 0 20px;
    
    border-bottom:1px solid #dde3eb;
    /* background-color: #fff; */
    /* border-radius: 8px 8px 0 0;
    border-top: 1px solid ${colors.gray3};
    border-right: 1px solid ${colors.gray3};
    border-left: 1px solid ${colors.gray3}; */
    ${views.tablet} {
        background-color: #fff;
        transition: 0.2s ease-in;
        gap: 10px;
        padding: 16px;
        ${props => props.isFixed ? `
            position: fixed;
            width: 100%;
            top: 0;
            z-index: 2;
            background-color: #000;
            border-bottom: none;
            padding:0 16px;
            height: 72px;
        ` : `
        flex-direction: column;
        align-items: flex-start;
        `}
    }
`;

export const ItemIconAndTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  flex:1;
  max-width: 100%;
  /* padding-bottom: 16px; */
`;

export const ItemInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    ${views.desktop} {
        max-width:calc(100% - 62px);
    }
    ${views.tablet} {
        ${props => props.isFixed ? `
            max-width:calc(100% - 48px);
        ` : ``}
    }    
`;

export const ItemTitleWrapper = styled.div`
    display: flex;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${props => props.isFixed ? '#fff' : colors.darkGray1};
    align-items: center;
    ${views.tablet} {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }
`;

export const TitleTextWrapper = styled.div`
    ${views.desktop} {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    ${props => props.isFixed ? `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    ` : ``}
`;

export const ItemTitleOverflowText = styled.div`
    display: block;
    // flex: 1;
    ${textOverflow};
`;

export const ItemClassTitleWrapper = styled.div`
    // color: ${colors.darkGray2};
    color: ${props => props.isFixed ? '#a9a9a9' : colors.darkGray2};
    font-weight: 450;
    font-size: 13px;
    font-family: Circular;
    line-height: 17px;
`;

export const ItemIconWrapper = styled.div`
    /* margin-left:24px; */
    width: ${props => props.iconWidth ? props.iconWidth : '40px'};
    height: ${props => props.iconHeight ? props.iconHeight : '40px'};
    min-width:${props => props.iconWidth ? props.iconWidth : '40px'};;
    border-radius: 23px;
    background-color:${props => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:12px;
    svg{
        width: ${props => props.iconWidth ? '18px' : '24px'};
        height: ${props => props.iconHeight ? '18px' : '24px'};
    }
    path{
        // transition:all 0.2s;
        /* stroke:#4F699D; */
        fill:#fff;
    }
`;

export const ItemLogoWrapper = styled.div`
    ${flexCenter};
    width: ${props => props.iconWidth ? props.iconWidth : '40px'};
    height: ${props => props.iconHeight ? props.iconHeight : '40px'};
    border-radius: 50%;
    margin-right:12px;
    img{
        border-radius: 50%;
        width: ${props => props.iconWidth ? '18px' : '40px'};
        height: ${props => props.iconHeight ? '18px' : '40px'};
        object-fit: contain;
    }
`;

export const LogoPlaceHolder = styled.div`
    ${flexCenter}
    height:40px;
    width:40px;
    background-color:  ${props => props.crypto ? '#FBD384' : '#B3E8CF'};
    font-weight: 500;
    color: ${colors.darkGray1};
    border-radius:50%;
    margin-right: 12px;
    svg{
        height:40px;
        width:40px;
    }
`;

export const ItemViewActionsWrapper = styled.div`
    ${flexCenter};
    height: 100%;
    gap:12px;
`;

export const ThreeDotsIconWrapper = styled.div`
    ${flexCenter};
    width:28px;
    height:28px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.32);
    border-radius: 4px;
    /* background-color: #000; */
    cursor: pointer;
    :hover{
        background-color: ${colors.lightGray1};
    }
    .ant-dropdown-trigger{
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .anticon{
        color:#fff;
        font-size:20px;
    }
`;

export const HamburgerIconWrapper = styled.div`
    ${flexCenter};
    width:32px;
    height:32px;
    border-radius: 50%;
    cursor: pointer;
    :hover{
        background-color: ${colors.lightGray1};
    }
    ${views.tablet} {
        ${props => props.isFixed ? `
            svg line{stroke:#fff;}
        ` : ``}
    }
    ${props => props.isMenuOpen ? `
        background-color: ${colors.lightGray1};
    ` : ``}
`;

export const OwnershipToggleWrapper = styled.div`
    ${flexCenter};
    padding: 3px;
    background-color: #fff;
    border: 1px solid ${colors.gray3};
    border-radius: 40px;
    cursor: pointer;
    ${views.tablet} {
        ${props => props.isFixed ? `` : `
            margin-left: 50px;
        `}
    }
`;

export const VerticalSeparator = styled.div`
    width: 1px;
    background-color: ${props => props.isClassView ? colors.darkGray2 : colors.gray3};
    margin: 0 24px;
    align-self: stretch;
`;

export const OwnershipToggleLeftSideWrapper = styled.div`
    user-select: none;
    ${flexCenter};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 4px 8px;
    white-space: nowrap;
    background-color: ${props => props.isSelected ? colors.gray3 : '#fff'};
    color: ${props => props.isSelected ? colors.darkGray1 : colors.darkGray3};
    border-radius: 12px;
    ${views.tablet} {
       padding: 8px 12px;
       border-radius:16px;
       ${props => props.isFixed ? `
            padding: 8px 8px;
            border-radius:16px;
       ` : ``}
    }
`;

export const OwnershipToggleRightSideWrapper = styled(OwnershipToggleLeftSideWrapper)`
    background-color: ${props => props.isSelected ? colors.holdingsBadgeColor : '#fff'};
    color: ${props => props.isSelected ? '#fff' : colors.darkGray3};

`;

export const ConnectedLiabilityButton = styled(Link)`
    /* border : 1px solid #fff; */
    color: #000;
    border-radius: 2px;  
    font-size: 14px;
    /* color: #fff; */
    padding: 8px 8px 8px 12px;
    &:hover{
        color: #000;
        /* color: #fff; */
        /* background-color :#333333; */
    }
    padding: 4px 8px;
    margin-top: 8px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    svg{
        path{
            fill:#fbfbfb;
        }
    }
`;

export const ProviderLinkWrapper = styled.div`
    cursor: pointer;
`;

export const ItemLoginRequiredBadge = styled.div`
    ${flexCenter}    
    padding: 2px 4px;
    /* height: 18px; */
    background-color:#FCE6E6;
    color:#912121;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    border-radius: 4px;
    margin-left: 8px;
`;

// ArchiveAssetIcon, EraseIcon, LinkLiabilityIcon, UnlinkLiabilityIcon
// export const ArchiveAssetIcon = (ArchiveAsset).div`

// `
// export const EraseIcon = (Erase).div`
// `
// export const LinkLiabilityIcon = (LinkLiability).div`
// `
// export const UnlinkLiabilityIcon = (UnlinkLiability).div`
// `

export const SingleFilterClassWrapper = styled.span`
    color: ${colors.darkGray3};
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    border-right: 1px solid ${colors.gray3};
    padding-right: 12px;
    margin-right: 12px;
`;

export const ConnectedAccountsIconWrapper = styled.div`
    ${flexCenter};
    width: 24px;
    height: 24px;
    svg {
        width: 18px;
        height: 18px;
    }
    ${props => props.isSyncing ? css`
    animation: ${animateSpin} 3000ms infinite linear;
    ` : ``}
`;

export const ContainerLinkWrapper = styled.span`
    cursor: pointer;
    position:relative;
    ::after{
        content:'';
        position:absolute;
        width:0%;
        height:1px;
        left:50%;
        bottom:0;
        background-color:#979797;
        transition:all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);
    }
    &:hover{
        ::after{
            width:100%;
            left:0%;
        }
    }
`;

export const OutstandingLoanWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const OutstandingLoanDescription = styled.div`
    display: flex;
    flex-direction: column;
    color: ${colors.darkGray2};
    font-family: Circular;
    font-size: 13px;
    font-weight: 450;
`;

export const AssetActionMenuWrapper = styled.div`
    // position: relative;
    // margin-top: 32px;
`;

export const AssetActionMenuContent = styled.div`
  // position: absolute;
  border: 1px solid #DDE3EB;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  // top: -28px;
  white-space: nowrap;
  background: var(--White, #FFF);

  ${views.tablet}{
    position: relative;
    border:none;
    box-shadow: none;
  }
`;

export const AssetActionMenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 44px;
  padding: 8px 16px;
  padding-left: ${props => props.pl || 12}px;
  padding-right: ${props => props.pr || 16}px;
  cursor: pointer;
  background-color: #FFFFFF;
  // transition: all 0.2s;

  &:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover {
    background: ${props => props.isRed ? 'linear-gradient(90deg, var(--Color-System-Error, #F00) 2px, rgba(255, 0, 0, 0.04) 2px)' : 'linear-gradient(90deg, #000 2px, #F7F8F9 2px)'};
    border-top: none !important;
  }

  .anticon {
    margin-right: 8px;
  }

  ${props => props.disabled ? `
    cursor: not-allowed;
    color:#bdbdbd;
     > * {opacity: 0.4;}
` : ``}

${props => props.isRed ? `
    color:#FF0000;
` : ``}

  ${views.tablet}{
    // justify-content: center;
    // border-bottom: 1px solid #e3e3e3;
    padding: 8px 20px;
    :not(:last-of-type){
        border-bottom: 1px solid ${colors.gray3};
    }
  }

  ${props => props.withBorderTop ? `border-top: 1px solid ${colors.lightGray2}` : ``}
  ${props => props.withBorderBottom ? `border-bottom: 1px solid ${colors.lightGray2}` : ``}
`;

export const AssetActionMenuItemChild = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  flex:1;
  
  ${props => props.withArrow ? `justify-content:space-between;` : ``}
  ${props => props.notClickable ? `cursor:default;` : ``}
  ${props => props.isStretch ? `flex:1;` : ``}
  ${props => props.isIndent ? `padding-left:24px;` : ``}
  ${props => props.isBold ? `
    font-weight:500;
  ` : ``}
`;


export const AssetPageHeaderTabsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: -1px;


    .ant-tabs-nav-list{
        gap:16px;
    }

    .ant-tabs-tab{
        margin:0px;
        padding: 14px 0
    }
    ${views.desktop}{
        .ant-tabs-tab{
            padding: 24px 0
        }
    }   
    .ant-tabs-tab-btn{
        color: var(--text-icon-placeholder, #ACB1BB);
        font-weight: 500;
        font-size: var(--font-size-md, 16px);
        line-height: var(--font-line-height-sm, 24px);
        text-shadow: none !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        color: var(--text-icon-main, #000);
    }

    .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
        background-color: var(--text-icon-main, #000);
        height: 2px;
    }

    .ant-tabs-top > .ant-tabs-nav{
        margin-bottom: 0px;
        ::before{
            border-bottom:none;
        }
    }
`;
export const AssetPageHeaderTab = styled.div`
    font-size: 14px;
    // display: flex;
    // height: 68px;
    // justify-content: center;
    // align-items: center;
    // gap: 8px;

    // font-size: var(--font-size-md, 16px);
    // font-weight: 500;
    // line-height: var(--font-line-height-sm, 24px); /* 150% */

    // ${props => props.selected ? `
    //     border-bottom: 2px solid var(--text-icon-main, #000);
    //     color: var(--text-icon-main, #000);
    //     `: `
    //         color: var(--text-icon-placeholder, #ACB1BB);
    // `}
`;