import { Dropdown } from 'antd'
import { Observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import { LogoLoader } from '../../../common/components/LogoLoader/LogoLoader'
import { useStore } from '../../app/data/root.store'
import { CommunityBreadcrumbs } from '../../layout/components/Breadcrumbs/CommunityBreadcrumbs'
import { CardContentBoxWrapper, ItemsListWrapper } from '../../wealth/components/wealth.commons.styles'
import { AccurateInfoIcon, EyeIcon, HeartIcon, LearnIcon } from '../icons/community-page-icons'
import { CommunityListHeader } from './CommunityListHeader/CommunityListHeader'
import { CommunityOverviewHero } from './CommunityOverviewHero/CommunityOverviewHero'
import { CommunityPageHeader, CommunityPageHeaderTitle, CommunityPageWrapper, HeaderActions, HeaderAltText, HeaderWrapper, LearnButton, MenuItemWrapper, MenuTitle, MenuWrapper, PageWrapper, StickyWrapper, WhatYouGetButton, WhatYouGetMenuItemIcon, WhatYouGetMenuItemSubTitle, WhatYouGetMenuItemTextContent, WhatYouGetMenuItemTitle, WhatYouGetMenuItemWrapper } from './CommunityPage.styles'
import { CommunityWelcomeModal } from './CommunityWelcomeModal/CommunityWelcomeModal'
import { CommunityItemsListViewSwitcher } from './items-list/CommunityItemsListViewSwitcher'
import { startIntercomTourById } from '../../../common/utils/intercom.utils'

export const CommunityPage = () => {
  const { uiStore, communityStore, dataLayersStore } = useStore();
  const [isWhatYouGetMenuOpen, setIsWhatYouGetMenuOpen] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const communityPageContentRef = useRef();

  const onScroll = () => {
    if (communityPageContentRef.current) {
      const { scrollTop } = communityPageContentRef.current;
      if (scrollTop === 0) {
        uiStore.setIsCommunityPageHeaderSticky(false);
      } else {
        uiStore.setIsCommunityPageHeaderSticky(true);
      }
    }
  };

  const handleLearnClick = () => {
    communityStore.setUnSelectedClass();
    communityStore.setSelectedTab('institutions');
    startIntercomTourById('416933');
  }
  
  useEffect(() => {
    uiStore.setIsRightMenuOpen(false);
    // return () => {}
  }, [uiStore])

  useEffect(() => {
    communityStore.getData();
    // return () => {}
  }, [communityStore, dataLayersStore])

  return (<Observer>{() => (
    <CommunityPageWrapper>

      {uiStore.isDesktopView &&
      <StickyWrapper isSticky={uiStore.isCommunityPageHeaderSticky}>
        <CommunityPageHeader>

          {communityStore.isClassView ? <CommunityBreadcrumbs /> : <HeaderWrapper>
            <CommunityPageHeaderTitle>Welcome to Vyzer’s Private Community</CommunityPageHeaderTitle>
            <HeaderAltText>Learn from the investment experience of other Vyzers. Honest, Secure, Anonymous Insights</HeaderAltText>
          </HeaderWrapper>}

          <HeaderActions>
            <Dropdown
              overlay={CreateMenu({ setMenuVisibility: setIsWhatYouGetMenuOpen })}
              trigger={['click']}
              placement='bottomRight'
              visible={isWhatYouGetMenuOpen}
              onVisibleChange={(visible) => { setIsWhatYouGetMenuOpen(visible) }}>
              <WhatYouGetButton isOpen={isWhatYouGetMenuOpen}>What you’ll get</WhatYouGetButton>
            </Dropdown>

            <LearnButton onClick={handleLearnClick}>
              <LearnIcon />
              Learn
            </LearnButton>
          </HeaderActions>

        </CommunityPageHeader>
      </StickyWrapper> }


      {communityStore.isLoading ?
        <LogoLoader backdrop />
        :
        <PageWrapper id={'communityPageContent'} 
        onScroll={onScroll} 
        ref={communityPageContentRef}>
          <div>

            <CommunityOverviewHero />

            <CardContentBoxWrapper borderlessTop>
              <CommunityListHeader />

              <ItemsListWrapper withoutPadding={!(communityStore.isOverviewView && communityStore.selectedTab !== 'institutions')} >
                <CommunityItemsListViewSwitcher isIncognito={uiStore.isIncognitoMode} 
                 ab={()=>{}}
                // ab={()=> setShowModal(true)}
                />
              </ItemsListWrapper>
            </CardContentBoxWrapper>

          </div>

        </PageWrapper>
      }
      { showModal  && <CommunityWelcomeModal onClose={() => { setShowModal(false) }} isMobile={!uiStore.isDesktopView} />}
      
         {/* <Drawer
         closable={false}
         visible={showModal }
     width={ '100%' }
     height={'auto'}
     className={'addItemDrawerContainer_mobile'}
     placement={'bottom'}
     onClose={()=>{setShowModal(false)}}>
      <div>
        <h2>Commme on</h2>
        <h2>Commme on</h2>
        <h2>Commme on</h2>
        <h2>Commme on</h2>
        <h2>Commme on</h2>
        <h2>Commme on</h2>
      </div>
      
       </Drawer> */}
      {/* {userStore.data?.profile?.showCommunityWelcomePopup && <CommunityWelcomeModal onClose={() => { userStore.closeCommunityWelcomeModal() }} isMobile={!uiStore.isDesktopView} />} */}



    </CommunityPageWrapper>
  )}</Observer>)
}

function CreateMenu(props) {
  return (
    <MenuWrapper width={'396px'}>
      <MenuTitle>What you’ll get from the Community:</MenuTitle>

      <MenuItemWrapper>
        <WhatYouGetMenuItemWrapper>
          <WhatYouGetMenuItemIcon>
            <AccurateInfoIcon />
          </WhatYouGetMenuItemIcon>
          <WhatYouGetMenuItemTextContent>
            <WhatYouGetMenuItemTitle>Current, accurate information</WhatYouGetMenuItemTitle>
            <WhatYouGetMenuItemSubTitle>Use the Community’s verified data to help you strategize your investments.</WhatYouGetMenuItemSubTitle>
          </WhatYouGetMenuItemTextContent>
        </WhatYouGetMenuItemWrapper>
      </MenuItemWrapper>

      <MenuItemWrapper>
        <WhatYouGetMenuItemWrapper>
          <WhatYouGetMenuItemIcon>
            <EyeIcon />
          </WhatYouGetMenuItemIcon>
          <WhatYouGetMenuItemTextContent>
            <WhatYouGetMenuItemTitle>Learnings from high net worth portfolios</WhatYouGetMenuItemTitle>
            <WhatYouGetMenuItemSubTitle>Understand how savvy investors allocate their investments.</WhatYouGetMenuItemSubTitle>
          </WhatYouGetMenuItemTextContent>
        </WhatYouGetMenuItemWrapper>
      </MenuItemWrapper>

      <MenuItemWrapper>
        <WhatYouGetMenuItemWrapper>
          <WhatYouGetMenuItemIcon>
            <HeartIcon />
          </WhatYouGetMenuItemIcon>
          <WhatYouGetMenuItemTextContent>
            <WhatYouGetMenuItemTitle>Trustworthy institutions and investments</WhatYouGetMenuItemTitle>
            <WhatYouGetMenuItemSubTitle>Get inspiration from other investors and discover new opportunities with confidence.</WhatYouGetMenuItemSubTitle>
          </WhatYouGetMenuItemTextContent>
        </WhatYouGetMenuItemWrapper>
      </MenuItemWrapper>

    </MenuWrapper>
  )
}