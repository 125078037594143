import { makeAutoObservable } from "mobx";
import { getCustomClassData, createCustomClass, updateCustomClass, deleteCustomClass, createCustomSubClass, updateCustomSubClass, deleteCustomSubClass, updateCustomClassesOrder } from "./custom-class.service";


export class CustomClassStore {

  showModal = false;
  isLoaded = false;
  data = [];

  setShowModal = (show) => {
    this.showModal = show;
  }

  constructor(metadataStore) {
    makeAutoObservable(this);
    this.metadataStore = metadataStore;
  }

  setLoading(isLoading) {
    if (isLoading) {
      this.isLoading = true;
    } else {
      this.isLoaded = true;
      this.isLoading = false;
    }
  }

  fetchData = async () => {
    this.setLoading(true);
    try {
      const data = await getCustomClassData();
      this.setData(data);
    }
    catch (e) { }
    finally {
      this.setLoading(false);
    }
  }

  setData(data) {
    this.data = data;
  }

  get customClasses() {
    return this.metadataStore.customClasses.slice().sort((a, b) => a.position - b.position);
  }

  async createCustomClass(data) {
    try {
      const result = await createCustomClass(data);
      this.setData([...this.data, result]);
      this.metadataStore.fetchData();
    }
    catch (e) { }
  }

  async updateCustomClass(data) {
    try {
      const result = await updateCustomClass(data);
      const updatedData = this.data.map(item => item.id === result.id ? result : item);
      this.setData(updatedData);
      this.metadataStore.fetchData();
    } catch (error) {
      
    }
  }

  async deleteCustomClass(id) {
    try {
      await deleteCustomClass(id);
      this.setData(this.data.filter(item => item.id !== id));
      this.metadataStore.fetchData();
    } catch (error) {}
  }

  async createCustomSubClass(data) {
    try {
      const result = await createCustomSubClass(data);
      // console.log(result);
      const updatedCustomClassData = this.data.map(item => item.id === result.id ? result : item);
      this.setData(updatedCustomClassData);
      this.metadataStore.fetchData();
      return result;
      // this.setData([...this.data, result]);
    } catch (error) {
      
    }
  }

  async updateCustomSubClass(data) {
    try {
      const result = await updateCustomSubClass(data);
      const updatedData = this.data.map(item => item.id === result.id ? result : item);
      this.setData(updatedData);
      this.metadataStore.fetchData();
    } catch (error) {
      
    }
  }

  async deleteCustomSubClass(data) {
    try {
      await deleteCustomSubClass(data);
      const updatedData = this.data.map(classItem => {
        if (classItem.id === data.customClassId) {
          // Filter out the deleted subClass
          const filteredCustomSubClasses = classItem.customSubClasses.filter(subClassItem => subClassItem.id !== data.id);
          // Return a new object for the class with the updated customSubClasses list
          return { ...classItem, customSubClasses: filteredCustomSubClasses };
        }
        return classItem;
      });
      this.setData(updatedData);
      this.metadataStore.fetchData();
    } catch (error) {
      // Handle error
    }
  }

  async updateCustomClassOrder(data) {
    try {
      this.metadataStore.updateCustomClassesOrder(data);
      updateCustomClassesOrder(data);
      // const updatedData = this.data.map(classItem => {
      //   if (classItem.id === data.customClassId) {
      //     // Filter out the deleted subClass
      //     const filteredCustomSubClasses = classItem.customSubClasses.filter(subClassItem => subClassItem.id !== data.id);
      //     // Return a new object for the class with the updated customSubClasses list
      //     return { ...classItem, customSubClasses: filteredCustomSubClasses };
      //   }
      //   return classItem;
      // });
      // this.setData(updatedData);
      // this.metadataStore.fetchData();
    } catch (error) {
      // Handle error
    }
  }

  get customClassesNames() {
    return this.metadataStore.customClasses.map(cls => cls.name.toLowerCase());
}

get customSubClassesNames() {
    return this.metadataStore.customClasses.flatMap(cls => cls.customSubClasses.map(subCls => subCls.name.toLowerCase()));
}
}