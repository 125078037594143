import { LoadingOutlined } from '@ant-design/icons';
import { AutoComplete, Drawer, Input } from "antd";
import { Observer } from 'mobx-react';
import React, { useState, } from 'react';
import ReactDOM from 'react-dom';
import { ModalCloseIcon } from '../../../../assets/icons/common/common-icons';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { DeleteButton, PrimaryButton } from '../../../../common/components/buttons/NewButtons.styles';
import { colors, emailToColor } from '../../../../common/styles/theme.styles';
import { capitalize } from '../../../../common/utils/string.utils';
import { useTrackEvent } from "../../../../hooks/useTrackEvent/useTrackEvent";
import { useStore } from '../../../app/data/root.store';
import { AutoCompleteContainer, BackButtonWrapper, ConfirmationViewContainer, ConfirmationViewInfoBulletsContainer, ConfirmationViewRow, ConfirmationViewRowEmail, ConfirmationViewRowNameAndMail, ConfirmationViewRowNameText, ConfirmationViewRowRoleInfo, ConfirmationViewText, ConfirmationViewTitle, ConfirmationViewTitleContainer, DemoRoleHeaderContainer, DemoRoleHeaderSubtitle, DemoRoleHeaderTitle, DoneViewContainer, DoneViewText, DrawerFooter, DrawerHeader, DrawerWrapper, EditRoleContentWrapper, EditRoleInputRow, EditRoleLabelText, FloatingLabeInInput, InputWithLabelOnTopWrapper, PermissionSelectButton, PermissionText, PermissionTypeBoxWrapper, PermissionsListWrapper, RevokeViewContainer, RevokeViewText, RolesNameIconWrapper, UpgradeTierToAccessRolesText } from './Roles.styles';
import { ReactComponent as EnvelopSvg } from './assets/envelop.svg';
import { ReactComponent as WhiteCloseButtonSvg } from './assets/white-modal-close-button.svg';


export const EditRole = (props) => {
  const { uiStore } = useStore();

  useTrackEvent('Popup Viewed', {
    title: 'Invite modal opened',
    tier: props.userTier,
    url: window.location.href
  })

  return (
      <Observer>{() => (
          uiStore.isDesktopView ? 
          ReactDOM.createPortal(<BaseModal width={'512px'} height={'auto'} borderRadius={8}>
              <EditRoleContent {...props} />
          </BaseModal> , document.body) : 
          <Drawer
              closable={false}
              visible={props.visible !== false}
              width={  340  }
              height={'calc(100% - 32px)'}
              className={'editRoleDrawerContainer_mobile'}
              placement={'bottom'}
              destroyOnClose={true}
              onClose={()=>{ props.onClose()}} >
           <EditRoleContent {...props} /> 
      </Drawer>
      )}</Observer>
  )
}

const permissionsTypes = [
  {title:'Guest - View only' , value: 'GUEST' , description: 'Share your portfolio with others in view-only access. '},
  {title:'Editor - View and edit' , value: 'EDITOR' , description: 'Editing permissions including updating portfolio and other settings.'},
  {title:'Admin - Full access' , value: 'ADMIN' , description: 'Full editing permissions for personal assistants.\nIncluding billing and role management.'},
];

const roleInfo = {
  'GUEST': {
    text: 'will have view-only access to your portfolio, accounts, and financial information.',
    rowInfo: 'Guest: View only access',
    doneViewRowInfo: 'view only',
    roleDescriptions: [
      { value: 'Accountant', label: 'Accountant' },
      { value: 'CPA', label: 'CPA' },
      { value: 'Colleague', label: 'Colleague' },
      { value: 'Estate planner', label: 'Estate planner' },
      { value: 'Family member', label: 'Family member' },
      { value: 'Financial advisor', label: 'Financial advisor' },
      { value: 'Friend', label: 'Friend' },
      { value: 'Tax advisor', label: 'Tax advisor' },
      { value: 'Other', label: 'Other' },
    ],
  },
  'EDITOR': {
    text: 'will be able to view and edit your portfolio, accounts and financial information.',
    rowInfo: 'Editor: View and edit access',
    doneViewRowInfo: 'editing',
    roleDescriptions: [
      { value: 'Accountant', label: 'Accountant' },
      { value: 'CPA', label: 'CPA' },
      { value: 'Colleague', label: 'Colleague' },
      { value: 'Estate planner', label: 'Estate planner' },
      { value: 'Financial advisor', label: 'Financial advisor' },
      { value: 'Friend', label: 'Friend' },
      { value: 'Tax advisor', label: 'Tax advisor' },
      { value: "Personal assistant", label: "Personal assistant" },
      { value: "Family office", label: "Family office" },
      { value: "Wealth manager", label: "Wealth manager" },
      { value: "Family member", label: "Family member" },
      { value: "Other", label: "Other" }
    ],
  },
  'ADMIN': {
    text: 'will be able to view and edit your portfolio, accounts, financial information, billing and role management.',
    rowInfo: 'Admin: Full access',
    doneViewRowInfo: 'full editing',
    roleDescriptions: [
      { value: "Personal assistant", label: "Personal assistant" },
      { value: "Family office", label: "Family office" },
      { value: "Wealth manager", label: "Wealth manager" },
      { value: "Family member", label: "Family member" },
      { value: "Other", label: "Other" }
    ],
  }
}

export const EditRoleContent = (props) => {
  const { 
    //id, eventTypeIndex, 
    item,
    isEdit,
    isDemoRole,
    isTier1or2,
    onClose,
    onSave,
    onDelete,
    onStartPortfolioClick,
   } = props;
  
  const [roleData, setRoleData] = useState({role:'GUEST' , ...item});
  const [isConfirmationView, setIsConfirmationView] = useState(false);
  const [isDoneView, setIsDoneView] = useState(false);
  const [isRevokeView, setIsRevokeView] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingCreatingRole, setIsLoadingCreatingRole] = useState(false);

  const filedChange = (field, value) => {
    resetFormInputError(field);
    if (field === 'roleDescription' && value?.length > 20) {
      setFormErrors({
        ...formErrors,
        roleDescription: 'Role description cannot exceed 20 characters'
      });
      value = value.substring(0, 20);
    }
    setRoleData({...roleData, [field]: value })
  }

  const updateFormErrors = (data) => {
    const errors = {};
    const fields = [
        { name: 'granteeName', message: 'Grantee name is required' },
        { name: 'granteeEmail', message: 'Grantee email is required', isEmail: true },
        { name: 'role', message: 'Role is required' },
        { name: 'roleDescription', message: 'Role description is required' },
    ];

    fields.forEach(field => {
        const value = data[field.name];
        if (!value || !value?.trim()) {
            errors[field.name] = field.message;
        } else if (field.isEmail && !validateEmail(value)) {
            errors[field.name] = 'Invalid email format';
        }
    });

    setFormErrors(errors);
    return errors;
  }

  const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
  }

  const resetFormInputError = (input) => {
    delete formErrors[input];
  }

  const handleSave = () => {
    if (isTier1or2) {
      handleStartPortfolioClick();
    } else if (isDoneView) {
      onClose?.();
    } else if (isEdit) {
      const trimmedRoleData = {
        ...roleData,
        granteeName: roleData.granteeName?.trim(),
        granteeEmail: roleData.granteeEmail?.trim(),
      };

      const errors = updateFormErrors(trimmedRoleData);
      const isFormValid = Object.keys(errors).length === 0;
      if (isFormValid) {
        onSave?.(trimmedRoleData);
      }
    } else if (isConfirmationView) {
      setIsLoadingCreatingRole(true);
      onSave?.(roleData, (data) => {
        if (data.error) {
          setIsLoadingCreatingRole(false);
          onClose?.();
        } else {
          setIsDoneView(true);
        }
        setIsConfirmationView(false);
      });
    } else {
      const trimmedRoleData = {
        ...roleData,
        granteeName: roleData.granteeName?.trim(),
        granteeEmail: roleData.granteeEmail?.trim(),
      };

      const errors = updateFormErrors(trimmedRoleData);
      const isFormValid = Object.keys(errors).length === 0;
      if (isFormValid) {
        setRoleData(trimmedRoleData);
        setIsConfirmationView(true);
      }
    }
  }

  const handleDelete = () => {
    if (isDemoRole) {
      return;
    } else if (isRevokeView) {
      onDelete?.(roleData.id);
    } else {
      setIsRevokeView(true);
    }
  }

  const handleStartPortfolioClick = () => {
    onClose?.();
    onStartPortfolioClick?.();
  }

  return (<DrawerWrapper>
    <DrawerHeader isDemoRole={isDemoRole}>
      {isDoneView || isRevokeView 
        ? null 
        : isDemoRole 
          ? (
            <DemoRoleHeaderContainer>
              <DemoRoleHeaderTitle>This is a demo invitation.</DemoRoleHeaderTitle>
              <DemoRoleHeaderSubtitle>Upgrade to Premium or Elite to access Roles.</DemoRoleHeaderSubtitle>
            </DemoRoleHeaderContainer>
          ) 
          : isConfirmationView 
            ? (
              <BackButtonWrapper onClick={() => {setIsConfirmationView(false)}}>
                <ArrowLeftSvg /> Back
              </BackButtonWrapper>
            ) 
            : isEdit 
              ? 'Edit permissions' 
              : 'Create a portfolio invitation' 
      }
      {isRevokeView && <div style={{flex:1}} />}
      {!isDoneView ? isDemoRole ? <WhiteCloseButtonSvg style={{cursor: 'pointer'}} onClick={onClose} /> : <ModalCloseIcon style={{cursor: 'pointer'}} onClick={onClose} /> : null}
    </DrawerHeader>
    <EditRoleContentWrapper withJustifyContentCenter={isConfirmationView} id="edit_role_content_wrapper">

        {isRevokeView &&
          <RevokeViewContainer>
            <RevokeViewText>You are about to revoke access and permissions for:</RevokeViewText>
            <ConfirmationViewRow style={{border:'none', justifyContent:'normal', marginBottom:'0'}}>
              <RolesNameIconWrapper isOwner={roleData.isOwner} color={item.color}>
                {roleData.granteeName.charAt(0).toUpperCase()}
              </RolesNameIconWrapper>
              <ConfirmationViewRowNameAndMail>
                <ConfirmationViewRowNameText>{capitalize(roleData.granteeName)}</ConfirmationViewRowNameText>
                <ConfirmationViewRowEmail>{roleData.granteeEmail}</ConfirmationViewRowEmail>
              </ConfirmationViewRowNameAndMail>
            </ConfirmationViewRow>
          </RevokeViewContainer>
        }

        {isDoneView && 
          <DoneViewContainer>
            <EnvelopSvg />
            <ConfirmationViewTitle style={{textAlign:'center', fontSize:14}}>You have given your {roleData.roleDescription} {capitalize(roleData.granteeName)}<br/>{roleInfo[roleData.role].doneViewRowInfo} permissions </ConfirmationViewTitle>
            <DoneViewText>Invitation sent to {roleData.granteeEmail}</DoneViewText>
            <ConfirmationViewRow>
              <RolesNameIconWrapper isOwner={roleData.isOwner} color={emailToColor(roleData.granteeEmail)}>
                {roleData.granteeName.charAt(0).toUpperCase()}
              </RolesNameIconWrapper>
              <ConfirmationViewRowNameAndMail>
                <ConfirmationViewRowNameText>{capitalize(roleData.granteeName)}</ConfirmationViewRowNameText>
                <ConfirmationViewRowEmail>{roleData.granteeEmail}</ConfirmationViewRowEmail>
              </ConfirmationViewRowNameAndMail>
              <ConfirmationViewRowRoleInfo>{roleInfo[roleData.role].rowInfo}</ConfirmationViewRowRoleInfo>
            </ConfirmationViewRow>
          </DoneViewContainer>}

        {isConfirmationView &&
        <ConfirmationViewContainer>
            <ConfirmationViewTitleContainer>
              <ConfirmationViewTitle>Share your portfolio with {capitalize(roleData.granteeName)}</ConfirmationViewTitle>
              <ConfirmationViewText>As {roleData.role === 'GUEST' ? 'a' : 'an'} {roleData.role.toLowerCase()}, {capitalize(roleData.granteeName)} {roleInfo[roleData.role].text}</ConfirmationViewText>
            </ConfirmationViewTitleContainer>

            <ConfirmationViewRow>
              <RolesNameIconWrapper isOwner={roleData.isOwner} color={emailToColor(roleData.granteeEmail)}>
                {roleData.granteeName.charAt(0).toUpperCase()}
              </RolesNameIconWrapper>
              <ConfirmationViewRowNameAndMail>
                <ConfirmationViewRowNameText>{capitalize(roleData.granteeName)}</ConfirmationViewRowNameText>
                <ConfirmationViewRowEmail>{roleData.granteeEmail}</ConfirmationViewRowEmail>
              </ConfirmationViewRowNameAndMail>
              <ConfirmationViewRowRoleInfo>{roleInfo[roleData.role].rowInfo}</ConfirmationViewRowRoleInfo>
            </ConfirmationViewRow>

        </ConfirmationViewContainer>}
        
        {!isConfirmationView && !isDoneView && !isRevokeView && <>
        {isDemoRole && <div style={{alignSelf:'flex-start'}}>{isEdit ? 'Edit permissions' : 'Create a portfolio invitation'}</div>}
        <EditRoleInputRow>
            <InputWithLabelOnTopWrapper withError={formErrors.granteeName}>
                <FloatingLabeInInput>Name</FloatingLabeInInput>
                <Input style={{height: '64px'}}  value={roleData.granteeName} onChange={(e)=>{filedChange('granteeName' , e.target.value)}}/>
            </InputWithLabelOnTopWrapper>
        </EditRoleInputRow>
        <EditRoleInputRow>
            <InputWithLabelOnTopWrapper withError={formErrors.granteeEmail}>
                <FloatingLabeInInput>Email</FloatingLabeInInput>
                <Input style={{height: '64px'}} value={roleData.granteeEmail} disabled={isEdit} onChange={(e)=>{filedChange('granteeEmail' , e.target.value)}}/>
            </InputWithLabelOnTopWrapper>
        </EditRoleInputRow>
        <PermissionsListWrapper>
            <EditRoleLabelText>Select permission:</EditRoleLabelText>
            {permissionsTypes.map((perType,index)=>(
              <PermissionTypeBoxWrapper key={index} isSelected={perType.value === roleData.role} onClick={()=>{filedChange('role',perType.value);}}>
                <PermissionSelectButton isSelected={perType.value === roleData.role} />
                <PermissionText >
                    <span className='title'>{perType.title}</span>
                    <span className='description'>{perType.description}</span>
                </PermissionText>
              </PermissionTypeBoxWrapper>
            ))}
            
        </PermissionsListWrapper>
        <EditRoleInputRow>
            <InputWithLabelOnTopWrapper withError={formErrors.roleDescription}>
                <FloatingLabeInInput>Role description</FloatingLabeInInput>
                {/* <SimpleSelect
            // onChange={(val) => { handleFieldChange('type', val) }} 
            onChange={(val) => {filedChange('roleDescription', val)}}
            style={{ width: '100%' }}
            value={roleData.roleDescription}
            options={
              roleInfo[roleData.role].roleDescriptions
            //   [
            //   { value: 'Accountant', label: 'Accountant' },
            //   { value: 'CPA', label: 'CPA' },
            //   { value: 'Colleague', label: 'Colleague' },
            //   { value: 'Estate planner', label: 'Estate planner' },
            //   { value: 'Family member', label: 'Family member' },
            //   { value: 'Financial advisor', label: 'Financial advisor' },
            //   { value: 'Friend', label: 'Friend' },
            //   { value: 'Tax advisor', label: 'Tax advisor' },
            //   { value: 'Other', label: 'Other' },
            // ]
          }
            buttonStyle={{ borderRadius: '6px', fontWeight: '450', color: '#3F3F45', boxShadow: 'none', border: `1px solid ${colors.gray3}`, height: '64px', }}
            buttonHoverBorderColor={colors.darkGray1}
            menuItemColor={'#3F3F45'}
          /> */}
          <AutoCompleteContainer withError={formErrors.roleDescription}>
            <AutoComplete
              placeholder='Select a role'
              value={roleData.roleDescription ? roleData.roleDescription : '' }
              options={roleInfo[roleData.role].roleDescriptions}
              // disabled={disabled}
              filterOption={true}
              // onChange={(val)=>{handleChange(inputKey,val)}} 
              onChange={(val) => {filedChange('roleDescription', val)}}
              getPopupContainer={() =>  document.getElementById('edit_role_content_wrapper') }
              allowClear
            />
          </AutoCompleteContainer>
            </InputWithLabelOnTopWrapper>
        </EditRoleInputRow>
        </>}
    </EditRoleContentWrapper>
    {isConfirmationView && 
      <ConfirmationViewInfoBulletsContainer>
      <ul>
        <li>The invitation will be sent via email (the account owner will receive a copy).</li>
        <li>The account owner can revoke permissions at any time</li>
      </ul>
    </ConfirmationViewInfoBulletsContainer>}
    <DrawerFooter>
            {isRevokeView && <div style={{flex:1}}></div>}
            { isEdit && <DeleteButton onClick={handleDelete} disabled={isDemoRole} withInvertedColors={isRevokeView}>{!isRevokeView ? <DeleteSvg /> : null}Revoke access</DeleteButton> } 
            {isTier1or2 && <UpgradeTierToAccessRolesText>Upgrade to Premium or Elite to access Roles</UpgradeTierToAccessRolesText>}
            {!isRevokeView && <>
              <div style={{flex:1}}></div>
               {!isDemoRole ?
                <PrimaryButton onClick={handleSave}>{isTier1or2 ? 'Upgrade Now' : isDoneView ? 'Done' : isConfirmationView ? (isLoadingCreatingRole ? <LoadingOutlined /> : 'Send invitation') : isEdit ? 'Save' : 'Continue'}</PrimaryButton>
                :
                <PrimaryButton style={{backgroundColor:colors.blueAzul, gap:'4px', paddingRight:'12px'}} onClick={handleStartPortfolioClick}>Start My Portfolio <DiagonalArrowSvg /></PrimaryButton>
              }
            </>}
        </DrawerFooter>
  </DrawerWrapper> )
}

const DeleteSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.25 9.5C18.25 9.5 17.7262 15.9022 17.4194 18.9881C17.369 19.4947 16.9419 19.8757 16.4328 19.876L7.65189 19.8811C7.14227 19.8814 6.7143 19.5 6.66174 18.9931C6.34059 15.8953 5.75 9.5 5.75 9.5" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
      <path d="M19.5 6.55273H4.5" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
      <path d="M16.5 6.55288C15.4225 6.55288 15.0944 5.67353 15.042 4.75719C15.004 4.09153 14.5116 3.5 13.8448 3.5H10.1552C9.48842 3.5 8.99604 4.09153 8.95799 4.75719C8.90562 5.67353 8.57753 6.55288 7.5 6.55288" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

const ArrowLeftSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6803 16.0132L10.347 11.9995L13.6803 7.98584" stroke="black" strokeWidth="1.5"/>
    </svg>
  )
}

const DiagonalArrowSvg = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6785 10.4287C12.6785 10.4287 17.5533 10.4287 19.7615 10.4287C19.7615 12.6369 19.7615 17.5118 19.7615 17.5118" stroke="white" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.7144 19.4795L19.4525 10.7414" stroke="white" strokeWidth="1.71429" strokeLinecap="round"/>
    </svg>
  )
}