import { Drawer, Input } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { BaseModal } from '../../../../common/components/BaseModal/BaseModal';
import { PrimaryButtonWithIcon, SecondaryButtonWithIcon } from '../../../../common/components/buttons/NewButtons.styles';
import { useTrackEvent } from '../../../../hooks/useTrackEvent/useTrackEvent';
import { useStore } from '../../../app/data/root.store';
import { ColorSelectionWrapper, CustomClassColorsInputTitle, CustomClassContentWrapper, DrawerFooter, DrawerHeader, DrawerWrapper, EditCustomClassInputRow, ErrorText, FloatingLabeInInput, IconSelectionWrapper, ImageTile, InputWithLabelOnTopWrapper, MobileCloseCreateCustomClassWrapper, MobileHeaderSubTitle, MobileHeaderTitle, ModalCloseButtonWrapper, SelectionContainer } from './EditCustomClass.styles';
import { customColors } from './icons/custom-colors';
import { customClassSvgs } from './new-svgs/new-custom-svgs';
import { ReactComponent as ModalCloseButtonSvg } from './svgs/modal-close-button.svg';
import { ReactComponent as MobileBackButtonSvg } from './svgs/mobile-modal-back-button.svg';
import { CustomSubClassListMobile } from './CustomSubClassListMobile';
import { DeleteCustomClass } from './DeleteCustomClass';
import { ActionLockedIconWithTooltip } from '../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';

export const EditCustomClass = observer((props) => {
  const { uiStore } = useStore();

  useTrackEvent('Popup Viewed', {
    title: 'Custom class modal opened',
    url: window.location.href
  })

  return (
    uiStore.isDesktopView ?
      ReactDOM.createPortal(<BaseModal width={'512px'} height={'auto'} borderRadius={8}>
        <EditCustomClassContent {...props} />
      </BaseModal>, document.body) :
      <Drawer
        closable={false}
        visible={props.drawerVisible !== false}
        width={'100%'}
        // height={'100%'}
        className={'editCustomClassDrawerContainer_mobile'}
        placement={'right'}
        destroyOnClose={true}
        push={false}
        onClose={() => { props.onClose() }} >
        <EditCustomClassContent {...props} isMobileView />
      </Drawer>
  )
})

export const CUSTOM_CLASS_NAME_MAX_LENGTH = 40;

export const EditCustomClassContent = observer(({
  isEdit = false,
  item = {},
  onSave,
  onDelete,
  onClose,
  isMobileView,
  customClassesNames, 
  customSubClassesNames
}) => {
  const { userStore } = useStore();
  const [customClassData, setCustomClassData] = useState({
    name: item.name || '',
    description: item.description || '',
    colorCode: customColors.find(({ colorName }) => colorName === item.colorCode) ? item.colorCode : customColors[0].colorName,
    icon: customClassSvgs.find(({ svgName }) => svgName === item.icon) ? item.icon : customClassSvgs[0].svgName
  });
  const [customSubClassesData, setCustomSubClassesData] = useState(item.customSubClasses || [])
  const [formErrors, setFormErrors] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const classNameInputRef = useRef(null);

  const isBlockedByRoleOrPaymentStatus = userStore.isReadOnlyMode || userStore.isActionBlockedByRole('addCustomClass') || userStore.isActionBlockedByRole('editCustomClass');
  useEffect(() => {
    if (classNameInputRef.current) {
      setTimeout(() => {
        classNameInputRef.current.focus();
      }, 100);
    }
  }, []);


  const fieldChange = (field, value) => {
    resetFormInputError(field);
    if (field === 'name' && value?.length > CUSTOM_CLASS_NAME_MAX_LENGTH) {
      // setFormErrors({
      //   ...formErrors,
      //   name: `Name cannot exceed ${CUSTOM_CLASS_NAME_MAX_LENGTH} characters`
      // });
      value = value.substring(0, CUSTOM_CLASS_NAME_MAX_LENGTH);
    }
    setCustomClassData({ ...customClassData, [field]: value })
  }

  const updateFormErrors = (data, itemPreviousName) => {
    const errors = {};
    const fields = [
      { name: 'name', message: 'Name is required' },
    ];

    if (customClassesNames && customClassesNames.length && customClassesNames.includes(data.name.toLowerCase()) && (!itemPreviousName || data.name.toLowerCase() !== itemPreviousName.toLowerCase() ) ) {
      errors.name = `The class name '${data.name}' is already in use. Please choose a different name.`;
    }

    fields.forEach(field => {
      const value = data[field.name];
      if (!value || !value?.trim()) {
        errors[field.name] = field.message;
      }
    });

    setFormErrors(errors);
    return errors;
  }

  const resetFormInputError = (input) => {
    delete formErrors[input];
  }

  const handleSave = (e) => {
    if (isBlockedByRoleOrPaymentStatus) {
      userStore.setShowLimitedSharedAccess(true);
    } else {
      const trimmedCustomClassData = {
        ...customClassData,
        name: customClassData.name?.trim(),
      };
  
      const errors = updateFormErrors(trimmedCustomClassData, item.name);
      const isFormValid = Object.keys(errors).length === 0;
      if (isFormValid) {
        onSave?.({
          ...trimmedCustomClassData,
          ...(customSubClassesData ? { customSubClasses: customSubClassesData } : {}),
          ...(isEdit ? { id: item.id } : {})
        });
        if (isMobileView) {
          onClose(e);
        }
      }
    }
  }

  const mobileViewDeleteCustomClass = (e) => {
    if (userStore.isReadOnlyMode || userStore.isActionBlockedByRole('deleteCustomClass')) {
      userStore.setShowLimitedSharedAccess(true);
    } else {
      setIsDelete(true);
    }
  }

  return (<DrawerWrapper>
    <DrawerHeader>
      {isMobileView ?
        <>
          <div style={{ display: 'flex', flex:1 }}>
            {isEdit && <MobileBackButtonSvg onClick={onClose} />}
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', ...(!isEdit ? {paddingLeft:'40px'} : {}) }}>
              <ImageTileComponent
                relevantImage={customColors.find(({ colorName }) => colorName === customClassData.colorCode)?.colorImage}
                relevantFallbackColor={customColors.find(({ colorName }) => colorName === customClassData.colorCode)?.fallbackColor}
                RelevantSvg={customClassSvgs.find(({ svgName }) => svgName === customClassData.icon)?.svgContent}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {isEdit ?
                  <>
                    <MobileHeaderTitle>{customClassData.name}</MobileHeaderTitle>
                    <MobileHeaderSubTitle>Edit custom class</MobileHeaderSubTitle>
                  </> : <MobileHeaderTitle>Create new custom class</MobileHeaderTitle>}
              </div>
            </div>
            {!isEdit &&
            <>
              <div style={{flex:1}} />
              <MobileCloseCreateCustomClassWrapper onClick={onClose}>
                <MobileCloseCreateCustomClass />
              </MobileCloseCreateCustomClassWrapper>
            </>
            }
          </div>
        </>
        :
        <>
          {isEdit ? 'Edit' : 'Create new'} custom class
          <ModalCloseButtonWrapper onClick={onClose}><ModalCloseButtonSvg /></ModalCloseButtonWrapper>
        </>
      }
    </DrawerHeader>

    <CustomClassContentWrapper>

      <EditCustomClassInputRow>
        <InputWithLabelOnTopWrapper withError={formErrors.name}>
          <FloatingLabeInInput>Name</FloatingLabeInInput>
          <Input style={{ height: '64px' }} ref={classNameInputRef} value={customClassData.name} onChange={(e) => { fieldChange('name', e.target.value) }} />
        </InputWithLabelOnTopWrapper>
        {formErrors.name && <ErrorText>{formErrors.name}</ErrorText>}
      </EditCustomClassInputRow>
      

      <EditCustomClassInputRow>
        <InputWithLabelOnTopWrapper withError={formErrors.description}>
          <FloatingLabeInInput>Description</FloatingLabeInInput>
          <Input.TextArea autoSize style={{ height: '64px' }} value={customClassData.description} onChange={(e) => { fieldChange('description', e.target.value) }} />
        </InputWithLabelOnTopWrapper>
      </EditCustomClassInputRow>

      {isMobileView && <EditCustomClassInputRow>
        <CustomSubClassListMobile
          item={{ ...item, customSubClasses: customSubClassesData }}
          updateSubClasses={(data) => { setCustomSubClassesData(data) }}
          isEditingClass={isEdit}
          relevantImage={customColors.find(({ colorName }) => colorName === customClassData.colorCode)?.colorImage}
          relevantFallbackColor={customColors.find(({ colorName }) => colorName === customClassData.colorCode)?.fallbackColor}
          RelevantSvg={customClassSvgs.find(({ svgName }) => svgName === customClassData.icon)?.svgContent}
          customSubClassesNames={customSubClassesNames}
        />
      </EditCustomClassInputRow>}

      <ColorSelectionWrapper>
        <CustomClassColorsInputTitle>Color</CustomClassColorsInputTitle>
        <SelectionContainer>
          {customColors.map(({ colorName, colorImage, fallbackColor }, index) => (
            <ImageTile
              key={index}
              image={colorImage}
              fallbackColor={fallbackColor}
              size={isMobileView ? 54 : 44}
              isSelected={customClassData.colorCode === colorName}
              onClick={() => { fieldChange('colorCode', colorName) }}
              isSelectable
            />
          ))}
        </SelectionContainer>
      </ColorSelectionWrapper>

      <IconSelectionWrapper>
        <CustomClassColorsInputTitle>Icon</CustomClassColorsInputTitle>
        <SelectionContainer>
          {customClassSvgs.map(({ svgName, svgContent: RelevantSvg }, index) => {
            const {
              colorImage: relevantCustomClassColorCodeImage,
              fallbackColor: relevantFallbackColor
            } = customColors.find(({ colorName }) => colorName === customClassData.colorCode) || {};
            return (
              <ImageTile
                key={index}
                image={relevantCustomClassColorCodeImage}
                fallbackColor={relevantFallbackColor}
                size={isMobileView ? 54 : 44}
                isSelected={customClassData.icon === svgName}
                onClick={() => { fieldChange('icon', svgName) }}
                isSelectable
              >
                <RelevantSvg key={index} />
              </ImageTile>
            )
          })}
        </SelectionContainer>
      </IconSelectionWrapper>

    </CustomClassContentWrapper>

    <DrawerFooter>
      <div style={{ flex: 1 }} />
      {isEdit && isMobileView && <SecondaryButtonWithIcon onClick={mobileViewDeleteCustomClass} style={{ color: '#ff0000' }} mobileHeight={56} mobileBorderRadius={12}>
        {userStore.isReadOnlyMode || userStore.isActionBlockedByRole('deleteCustomClass') ? <ActionLockedIconWithTooltip iconColor={'#ff0000'} marginLeft={'0px'} isRoleBlocked={userStore.isActionBlockedByRole('deleteCustomClass')} /> : <EraseCustomClassSvg />}
        Delete custom class</SecondaryButtonWithIcon>}
      <PrimaryButtonWithIcon onClick={handleSave} mobileHeight={56} mobileBorderRadius={12}>
        {isBlockedByRoleOrPaymentStatus && <ActionLockedIconWithTooltip iconColor={'#fff'} marginLeft={'0px'} isRoleBlocked={userStore.isActionBlockedByRole('addCustomClass') || userStore.isActionBlockedByRole('editCustomClass')} />}
        {isEdit ? 'Save' : 'Create'}
      </PrimaryButtonWithIcon>
    </DrawerFooter>

    {isDelete && <DeleteCustomClass item={item} onClose={() => { setIsDelete(false); }} onDelete={() => { onDelete(item.id); }}
      relevantImage={customColors.find(({ colorName }) => colorName === customClassData.colorCode)?.colorImage}
      relevantFallbackColor={customColors.find(({ colorName }) => colorName === customClassData.colorCode)?.fallbackColor}
      RelevantSvg={customClassSvgs.find(({ svgName }) => svgName === customClassData.icon)?.svgContent}
    />}
  </DrawerWrapper>)
})

const ImageTileComponent = ({ relevantImage, relevantFallbackColor, RelevantSvg }) => {
  return (
    <ImageTile
      // style={{ position: 'absolute', left: 8, top: 8, zIndex: 1 }}
      image={relevantImage}
      fallbackColor={relevantFallbackColor}
      size={36}
      borderRadius={6}
    >
      <RelevantSvg />
    </ImageTile>
  )
}

const EraseCustomClassSvg = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.75 9.5C18.75 9.5 18.2262 15.9022 17.9194 18.9881C17.869 19.4947 17.4419 19.8757 16.9328 19.876L8.15189 19.8811C7.64227 19.8814 7.2143 19.5 7.16174 18.9931C6.84059 15.8953 6.25 9.5 6.25 9.5" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M20 6.55469H5" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M17 6.55288C15.9225 6.55288 15.5944 5.67353 15.542 4.75719C15.504 4.09153 15.0116 3.5 14.3448 3.5H10.6552C9.98842 3.5 9.49604 4.09153 9.45799 4.75719C9.40562 5.67353 9.07753 6.55288 8 6.55288" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

const MobileCloseCreateCustomClass = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="14" fill="#E8E9ED"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.4194 19.7297L8.51991 9.83019L9.58057 8.76953L19.4801 18.669L18.4194 19.7297Z" fill="#585D66"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M19.4801 9.83109L9.58056 19.7306L8.5199 18.6699L18.4194 8.77043L19.4801 9.83109Z" fill="#585D66"/>
    </svg>
  )
}