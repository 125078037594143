import { Dropdown, Input } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { DeleteIcon } from '../../../../assets/icons/common/common-icons';
import { CommonActionMenuItem, DeleteIconContainer } from '../../../../common/styles/menus.styles';
import { useStore } from '../../../app/data/root.store';
import { ActionsOnHoverWrapper, CollapsibleContent, CustomClassActionMenuContent, CustomClassActionMenuWrapper, CustomClassRowCollapsible, CustomClassRowCollapsibleActionsWrapper, CustomClassRowCollapsibleArrow, CustomClassRowCollapsibleDetailsWrapper, CustomClassRowCollapsibleDraggableIcon, CustomClassRowCollapsibleHeader, CustomClassRowCollapsibleThreeDots, CustomClassRowCollapsibleTitle, CustomSubClassEmptyStateRowIconWrapper, CustomSubClassEmptyStateRowTextWrapper, CustomSubClassEmptyStateRowWrapper, CustomSubClassInputWrapper, CustomSubClassListTitle, CustomSubClassRowWrapper, CustomSubClassRowWrapperTextWrapper, EraseSubClassSvgWrapper, HoverActionWrapper, ThreeDotsCircleWrapper } from './CustomClassRow.styles';
import { ImageTile } from './EditCustomClass.styles';
import { customColors } from './icons/custom-colors';
import { CUSTOM_CLASS_NAME_MAX_LENGTH, EditCustomClass } from './EditCustomClass';
import { DeleteCustomClass } from './DeleteCustomClass';
import { PrimaryButtonWithIcon } from '../../../../common/components/buttons/NewButtons.styles';
import { ReactComponent as EraseSubClassSvg } from './svgs/erase-sub-class.svg';
import { ModifiedTooltip } from '../../../../common/components/ModifiedTooltip/ModifiedTooltip';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { customClassSvgs } from './new-svgs/new-custom-svgs';
import { ActionLockedIconWithTooltip } from '../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';
import { errorNotification } from '../../../layout/components/Notifications/notifications';

export const CustomClassRow = observer((props) => {
  const { uiStore, customClassStore } = useStore();

  const updateCustomClass = (data) => {
    customClassStore.updateCustomClass(data);
  }

  const deleteCustomClass = (id) => {
    customClassStore.deleteCustomClass(id)
  }

  const createCustomSubClass = (data) => {
    customClassStore.createCustomSubClass(data);
  }

  const updateCustomSubClass = (data) => {
    customClassStore.updateCustomSubClass(data);
  }

  const deleteCustomSubClass = (data) => {
    customClassStore.deleteCustomSubClass(data);
  }

  const PropsWithMethods = { ...props, updateCustomClass, deleteCustomClass, createCustomSubClass, updateCustomSubClass, deleteCustomSubClass,
    customClassesNames: customClassStore.customClassesNames,
    customSubClassesNames: customClassStore.customSubClassesNames
   };

  return (
    // uiStore.isDesktopView ?
    <CustomClassRowDesktop {...PropsWithMethods} isMobileView={!uiStore.isDesktopView} />
    // :      <CustomClassRowMobile {...PropsWithMethods} />
  )
})


export const CustomClassRowDesktop = observer(({
  item = {},
  forceClose,
  updateCustomClass,
  deleteCustomClass,
  createCustomSubClass,
  updateCustomSubClass,
  deleteCustomSubClass,
  isMobileView,
  isNewCustomClassCreated,
  customClassesNames,
  customSubClassesNames
}) => {
  const { userStore } = useStore();
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [subClassObjToDelete, setSubClassObjToDelete] = useState(null);
  const [isOpen, setIsOpen] = useState((isMobileView && item.customSubClasses?.length > 0) || (!isMobileView && isNewCustomClassCreated) || false);
  const [isEditingSubClass, setIsEditingSubClass] = useState(false);
  const [formFields, setFormFields] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const isBlockedByRoleOrPaymentStatus = userStore.isReadOnlyMode || userStore.isActionBlockedByRole('addCustomSubClass') || userStore.isActionBlockedByRole('editCustomSubClass');

  useEffect(() => {
    if (isMobileView) {
      setIsOpen(item.customSubClasses?.length > 0);
    } else if (forceClose) {
      setIsOpen(false);
    }
  }, [item.customSubClasses, isMobileView, forceClose]);


  const updateField = (field, value) => {
    resetFormInputError(field);
    if (field === 'name' && value.length > CUSTOM_CLASS_NAME_MAX_LENGTH) {
      value = value.substring(0, CUSTOM_CLASS_NAME_MAX_LENGTH);
    }
    const newFormFields = { ...formFields, [field]: value };
    setFormFields(newFormFields);
  }

  const updateFormErrors = (data) => {
    const errors = {};
    const fields = [
      { name: 'name', message: 'Name is required' },
    ];

    fields.forEach(field => {
      const value = data[field.name];
      if (!value || !value?.trim()) {
        errors[field.name] = field.message;
      }
    });

    setFormErrors(errors);
    return errors;
  }

  const handleCreateSubClass = (e,previousName) => {
    if (isBlockedByRoleOrPaymentStatus) {
      userStore.setShowLimitedSharedAccess(true);
    } else {
      e.stopPropagation();
      const trimmedCustomSubClassData = {
        ...formFields,
        name: formFields.name?.trim(),
      };
      const errors = updateFormErrors(trimmedCustomSubClassData);
      const isFormValid = Object.keys(errors).length === 0;
      if (isFormValid) {
      
        if (customSubClassesNames.includes(trimmedCustomSubClassData.name.toLowerCase()) && previousName.toLowerCase() !== trimmedCustomSubClassData.name.toLowerCase()) {
          errorNotification(`The sub-class name '${trimmedCustomSubClassData.name}' is already in use. Please choose a different name.`);
        } else {
          if (formFields.id) {
            updateCustomSubClass({ ...trimmedCustomSubClassData, customClassId: item.id });
          }
          else {
            createCustomSubClass({ ...trimmedCustomSubClassData, customClassId: item.id });
          }
          setIsEditingSubClass(false);
          setFormFields({})
        }

      
      }
    }
  }

  const resetFormInputError = (input) => {
    delete formErrors[input];
  }

  const handleCustomClassRowCollapsibleOpenToggle = () => {
    if (isMobileView) {
      setIsEdit(true);
    } else {
      setIsOpen(!isOpen);
      if (!isOpen) {
        setIsEditingSubClass(false);
        setFormFields({});
      }
    }
  }

  const { colorImage: relevantImage, fallbackColor: relevantFallbackColor } = customColors.find(({ colorName }) => colorName === item?.colorCode) || {};
  const { svgContent: RelevantSvg } = customClassSvgs.find(({ svgName }) => svgName === item?.icon) || {};

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: item.id, animateLayoutChanges: () => false });

  const dragItemStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <CustomClassRowCollapsible
      onClick={handleCustomClassRowCollapsibleOpenToggle}
      ref={setNodeRef} style={dragItemStyle} {...attributes}>

      <CustomClassRowCollapsibleHeader isOpen={isOpen}>
        {!isMobileView && !userStore.isReadOnlyMode && !userStore.isActionBlockedByRole('editCustomClassOrder') && <CustomClassRowCollapsibleDraggableIcon  {...listeners}><DraggableSvg /></CustomClassRowCollapsibleDraggableIcon>}
        <CustomClassRowCollapsibleDetailsWrapper>
          <ImageTile
            image={relevantImage}
            fallbackColor={relevantFallbackColor}
            size={32}
          >
            <RelevantSvg />
          </ImageTile>
          <CustomClassRowCollapsibleTitle>{item.name}</CustomClassRowCollapsibleTitle>
        </CustomClassRowCollapsibleDetailsWrapper>
        <CustomClassRowCollapsibleActionsWrapper>
          {!isMobileView && <Dropdown
            overlay={CustomClassActionMenu({
              handleOnCustomClassEdit: () => { setIsEdit(true) },
              handleOnCustomClassDelete: () => { setIsDelete(true) }
            })}
            trigger={['click']}
            placement="topRight"
            getPopupContainer={() => document.getElementById('preferencesPage')}
          >
            <CustomClassRowCollapsibleThreeDots onClick={(e) => { e.stopPropagation(); }}><ThreeDotsCircleWrapper><ThreeDotsSvg /></ThreeDotsCircleWrapper></CustomClassRowCollapsibleThreeDots>
          </Dropdown>}
          <CustomClassRowCollapsibleArrow isOpen={isOpen}><CollapsibleArrowSvg /></CustomClassRowCollapsibleArrow>
        </CustomClassRowCollapsibleActionsWrapper>
      </CustomClassRowCollapsibleHeader>

      {isOpen && <CollapsibleContent onClick={(e) => {
        if (!isMobileView) {
          e.stopPropagation();
        }
      }}>

        <CustomSubClassListTitle>Custom sub-class:</CustomSubClassListTitle>

        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '2px' }}>

          {item.customSubClasses?.map((subClass, index) => {
            return (
              <CustomSubClassRowWrapper isEdited={isEditingSubClass && formFields.id === subClass.id} key={index} onClick={() => {
                if (!isMobileView) {
                  resetFormInputError('name');
                  setFormFields({ id: subClass.id, name: subClass.name });
                  setIsEditingSubClass(true);
                }
              }}>
                {
                  isEditingSubClass && formFields.id === subClass.id ?
                    <>
                      <CustomSubClassInputWrapper style={{ position: 'relative' }} withError={formErrors.name}>
                        <ImageTile
                          style={{ position: 'absolute', left: 8, top: 8, zIndex: 1 }}
                          image={relevantImage}
                          fallbackColor={relevantFallbackColor}
                          size={24}
                          borderRadius={6}
                        >
                          <RelevantSvg />
                        </ImageTile>
                        <Input autoFocus value={formFields.name} onChange={(e) => { e.stopPropagation(); updateField('name', e.target.value) }} />
                      </CustomSubClassInputWrapper>
                      <PrimaryButtonWithIcon style={{ gap: '2px', padding: '0 24px 0 16px' }} onClick={(e)=>{handleCreateSubClass(e,subClass.name)}}>
                      {userStore.isReadOnlyMode || userStore.isActionBlockedByRole('editCustomSubClass') ? <ActionLockedIconWithTooltip iconColor={'#fff'} marginLeft={'0px'} isRoleBlocked={userStore.isActionBlockedByRole('editCustomSubClass')} /> : <WhiteCheckMark />}
                        Save
                      </PrimaryButtonWithIcon>
                      <ModifiedTooltip title={'Cancel changes'}>
                        <EraseSubClassSvgWrapper onClick={(e) => { e.stopPropagation(); setIsEditingSubClass(false); setFormFields({}); }}><EraseSubClassSvg /></EraseSubClassSvgWrapper>
                      </ModifiedTooltip>
                    </>
                    :
                    <CustomClassRowCollapsibleDetailsWrapper>
                      <ImageTile
                        image={relevantImage}
                        fallbackColor={relevantFallbackColor}
                        size={24}
                        borderRadius={6}
                      >
                        <RelevantSvg />
                      </ImageTile>
                      <CustomSubClassRowWrapperTextWrapper>{subClass.name}</CustomSubClassRowWrapperTextWrapper>
                      <ActionsOnHoverWrapper>
                        <ModifiedTooltip title={'Remove'}>
                          <HoverActionWrapper onClick={(e) => { e.stopPropagation(); setIsDelete(true); setSubClassObjToDelete(subClass); }}><CustomSubClassTrashSvg /></HoverActionWrapper>
                        </ModifiedTooltip>
                        <ModifiedTooltip title={'Rename'}>
                          <HoverActionWrapper><CustomSubClassEditPencilSvg /></HoverActionWrapper>
                        </ModifiedTooltip>
                      </ActionsOnHoverWrapper>
                    </CustomClassRowCollapsibleDetailsWrapper>}
              </CustomSubClassRowWrapper>
            )
          })}

          {!isMobileView && <CustomSubClassEmptyStateRowWrapper onClick={() => { resetFormInputError('name'); setFormFields({}); setIsEditingSubClass(true); }} isEditingSubClass={isEditingSubClass && !formFields.id}>
            {isEditingSubClass && !formFields.id ?
              <>
                <CustomSubClassInputWrapper style={{ position: 'relative' }} withError={formErrors.name}>
                  <ImageTile
                    style={{ position: 'absolute', left: 8, top: 8, zIndex: 1 }}
                    image={relevantImage}
                    fallbackColor={relevantFallbackColor}
                    size={24}
                    borderRadius={6}
                  >
                    <RelevantSvg />
                  </ImageTile>
                  <Input autoFocus value={formFields.name} onChange={(e) => { e.stopPropagation(); updateField('name', e.target.value) }} />
                </CustomSubClassInputWrapper>
                <PrimaryButtonWithIcon style={{ gap: '2px', padding: '0 24px 0 16px' }} onClick={(e)=>{handleCreateSubClass(e,'')}}>
                  {userStore.isReadOnlyMode || userStore.isActionBlockedByRole('addCustomSubClass') ? <ActionLockedIconWithTooltip iconColor={'#fff'} marginLeft={'0px'} isRoleBlocked={userStore.isActionBlockedByRole('addCustomSubClass')} /> : <WhiteCheckMark />}
                  Create
                </PrimaryButtonWithIcon>
                <ModifiedTooltip title={'Cancel changes'}>
                  <EraseSubClassSvgWrapper onClick={(e) => { e.stopPropagation(); setIsEditingSubClass(false); setFormFields({}); }}><EraseSubClassSvg /></EraseSubClassSvgWrapper>
                </ModifiedTooltip>
              </>
              :
              <>
                <CustomSubClassEmptyStateRowIconWrapper><EmptyStatePlusIcon /></CustomSubClassEmptyStateRowIconWrapper>
                <CustomSubClassEmptyStateRowTextWrapper>Add sub-class</CustomSubClassEmptyStateRowTextWrapper>
              </>
            }

          </CustomSubClassEmptyStateRowWrapper>}

        </div>

      </CollapsibleContent>}
      {isEdit && <EditCustomClass isEdit item={item} 
      onClose={(e) => { e.stopPropagation(); setIsEdit(false) }} 
      onSave={(data) => { updateCustomClass(data); setIsEdit(false) }} 
      onDelete={(id) => { deleteCustomClass(id); setIsEdit(false) }} 
      customClassesNames={customClassesNames}
      customSubClassesNames={customSubClassesNames}
      />}
      {isDelete ?
        subClassObjToDelete ?
          <DeleteCustomClass isCustomSubClass item={{ customClassId: item.id, colorCode: item.colorCode, icon: item.icon, name: subClassObjToDelete.name, id: subClassObjToDelete.id }} onClose={() => { setIsDelete(false); setSubClassObjToDelete(null); }} onDelete={() => { deleteCustomSubClass({ ...subClassObjToDelete, customClassId: item.id }); }}
            relevantImage={relevantImage}
            relevantFallbackColor={relevantFallbackColor}
            RelevantSvg={RelevantSvg}
          />
          :
          <DeleteCustomClass item={item} onClose={() => { setIsDelete(false) }} onDelete={() => { deleteCustomClass(item.id); }}
            relevantImage={relevantImage}
            relevantFallbackColor={relevantFallbackColor}
            RelevantSvg={RelevantSvg}
          /> : null}
    </CustomClassRowCollapsible>
  )
})

const DraggableSvg = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="2" r="1" fill="#ACB1BB" />
      <circle cx="4" cy="2" r="1" fill="#ACB1BB" />
      <circle cx="8" cy="6" r="1" fill="#ACB1BB" />
      <circle cx="4" cy="6" r="1" fill="#ACB1BB" />
      <circle cx="8" cy="10" r="1" fill="#ACB1BB" />
      <circle cx="4" cy="10" r="1" fill="#ACB1BB" />
    </svg>
  )
}

const ThreeDotsSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2.66659" cy="7.99935" r="1.33333" fill="#1A1B1D" />
      <circle cx="7.99984" cy="7.99935" r="1.33333" fill="#1A1B1D" />
      <circle cx="13.3333" cy="7.99935" r="1.33333" fill="#1A1B1D" />
    </svg>
  )
}

const CollapsibleArrowSvg = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.48658 14.1816L12.5002 10.8483L16.5139 14.1816" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}

const EmptyStatePlusIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 12.25L7 1.75" stroke="#9097A3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.25 7H1.75" stroke="#9097A3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

function CustomClassActionMenu({
  handleOnCustomClassEdit,
  handleOnCustomClassDelete
}) {

  return (
    <CustomClassActionMenuWrapper onClick={(e) => e.stopPropagation()}>
      <CustomClassActionMenuContent>
        <CommonActionMenuItem onClick={handleOnCustomClassEdit}>
          <EditPencilSvg /> Edit class
        </CommonActionMenuItem>
        <CommonActionMenuItem color={'red'} onClick={handleOnCustomClassDelete}>
          <DeleteIconContainer color={'red'}><DeleteIcon /></DeleteIconContainer>Delete
        </CommonActionMenuItem>
      </CustomClassActionMenuContent>
    </CustomClassActionMenuWrapper>
  )
}

const EditPencilSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.3979 11.7988L12.0028 6.58895" stroke="black" strokeWidth="1.5" />
      <path d="M9.90681 19.5683L9.90585 20.3183C10.1101 20.3186 10.3056 20.2355 10.4472 20.0884L9.90681 19.5683ZM4.50304 14.3674L3.96267 13.8473C3.82105 13.9945 3.74556 14.193 3.75363 14.3971L4.50304 14.3674ZM4.70849 19.5617L3.95908 19.5913L3.98753 20.3107L4.70753 20.3117L4.70849 19.5617ZM14.6892 5.22602L18.6519 9.04001L19.6921 7.95926L15.7294 4.14526L14.6892 5.22602ZM18.6587 9.3935L9.36643 19.0482L10.4472 20.0884L19.7395 10.4337L18.6587 9.3935ZM5.04342 14.8875L14.3357 5.23278L13.2549 4.1926L3.96267 13.8473L5.04342 14.8875ZM9.90776 18.8183L4.70945 18.8117L4.70753 20.3117L9.90585 20.3183L9.90776 18.8183ZM5.45791 19.532L5.25246 14.3378L3.75363 14.3971L3.95908 19.5913L5.45791 19.532ZM18.6519 9.04001C18.7514 9.13576 18.7544 9.29402 18.6587 9.3935L19.7395 10.4337C20.4097 9.73732 20.3885 8.62948 19.6921 7.95926L18.6519 9.04001ZM15.7294 4.14526C15.033 3.47504 13.9252 3.49624 13.2549 4.1926L14.3357 5.23278C14.4314 5.1333 14.5897 5.13027 14.6892 5.22602L15.7294 4.14526Z" fill="black" />
      <path d="M20 19.5996H13" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}

const WhiteCheckMark = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5106 8.60403L10.5061 15.6125L7.00008 12.1076" stroke="white" strokeWidth="2" />
    </svg>
  )
}

const CustomSubClassTrashSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.2084 7.91797C15.2084 7.91797 14.7719 13.2532 14.5162 15.8247C14.4743 16.2469 14.1183 16.5644 13.6941 16.5647L6.37666 16.5689C5.95197 16.5691 5.59533 16.2513 5.55153 15.8289C5.2839 13.2474 4.79175 7.91797 4.79175 7.91797" stroke="#1A1B1D" strokeWidth="1.25" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M16.25 5.46224H3.75" stroke="#1A1B1D" strokeWidth="1.25" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M13.75 5.46203C12.8521 5.46203 12.5787 4.72924 12.535 3.96563C12.5033 3.41091 12.093 2.91797 11.5374 2.91797H8.46263C7.90701 2.91797 7.4967 3.41091 7.46499 3.96563C7.42135 4.72924 7.14794 5.46203 6.25 5.46203" stroke="#1A1B1D" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

const CustomSubClassEditPencilSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.4983 9.83203L10.0024 5.49047" stroke="black" strokeWidth="1.25" />
      <path d="M8.25543 16.3063L8.25463 16.9313C8.42481 16.9315 8.58773 16.8623 8.70574 16.7397L8.25543 16.3063ZM3.75229 11.9722L3.30198 11.5388C3.18396 11.6614 3.12105 11.8268 3.12778 11.9969L3.75229 11.9722ZM3.9235 16.3007L3.29899 16.3254L3.3227 16.925L3.9227 16.9257L3.9235 16.3007ZM12.2407 4.35436L15.543 7.53269L16.4099 6.63206L13.1076 3.45374L12.2407 4.35436ZM15.5487 7.82727L7.80511 15.8729L8.70574 16.7397L16.4493 8.69408L15.5487 7.82727ZM4.2026 12.4056L11.9462 4.36L11.0455 3.49318L3.30198 11.5388L4.2026 12.4056ZM8.25623 15.6813L3.9243 15.6757L3.9227 16.9257L8.25463 16.9313L8.25623 15.6813ZM4.54801 16.276L4.3768 11.9475L3.12778 11.9969L3.29899 16.3254L4.54801 16.276ZM15.543 7.53269C15.6259 7.61248 15.6285 7.74437 15.5487 7.82727L16.4493 8.69408C17.0078 8.11378 16.9902 7.19058 16.4099 6.63206L15.543 7.53269ZM13.1076 3.45374C12.5273 2.89522 11.6041 2.91288 11.0455 3.49318L11.9462 4.36C12.026 4.2771 12.1578 4.27457 12.2407 4.35436L13.1076 3.45374Z" fill="black" />
      <path d="M16.6667 16.332H10.8334" stroke="black" strokeWidth="1.25" />
    </svg>
  )
}