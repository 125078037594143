import { useHistory } from "react-router-dom";
import { translations } from "../../../../../../../common/translations/translations.en";
import { navigateToUrl } from "../../../../../../../common/utils/navigation.utils";
import { displayMoneyValue } from "../../../../../../../common/utils/number-display.utils";
import { getUrlDisplayName } from "../../../../../../../common/utils/string.utils";
import { LoanAdditionalDataBox, PencilIconTextWrapper, PencilIconWrapper, StatAdditionalDataWrapper } from "../OverviewTab.styles";

const { IRR_DESCRIPTION } = translations.common.item_overview;

export const privateLoansNonAmortizedOverviewData = {
    value: { title: 'Balance', subTitle: 'Remaining loan amount', lastUpdate: true },
    stats: [
        
        { title: 'Target annual interest rate', 
        //description: `The average interest rate is calculated by actual interest payments made and the current loan balance after principal repayments.`, 
        dataKey: 'interest', type: 'perc', specificDecimalScale: 3,
        // valueFn: (itemData) => { return percentageDisplay(itemData.interest_payment.find(i=>i.label==='Average').actual) },
        additionalDataFn: (itemData, selectedTimeFrameIndex) => {
            return (itemData.paidAmounts && itemData.paidAmounts.length) ?
            <StatAdditionalDataWrapper>
                <LoanAdditionalDataBox>
                    <EditPencilComp text={'Edit'} itemData={itemData} fieldKey={'expected-interest'} />
                </LoanAdditionalDataBox>
            </StatAdditionalDataWrapper> : null
        }
        },
        
        {title:'IRR', dataKey: 'xirr', type: 'perc', description: IRR_DESCRIPTION, multipleValues:true, hasMetricBars: true},
        
        {isArray: true , stats: [
            {title: 'Loan payments', menuDescription: 'Interest payments and principal repayments', dataKey:'paidAmounts',  multipleValues: true,
            additionalDataFn: (itemData, selectedTimeFrameIndex , isHoldingView, isIncognito) => {
                return itemData.paidAmounts.length ? <StatAdditionalDataWrapper>
                    <LoanAdditionalDataBox>Interest <span>{displayMoneyValue(itemData.paidAmounts[selectedTimeFrameIndex].interest, itemData.currency, isIncognito,  isHoldingView ? itemData.holdings : 100)}</span></LoanAdditionalDataBox>
                    <LoanAdditionalDataBox>Principal <span>{displayMoneyValue(itemData.paidAmounts[selectedTimeFrameIndex].principal, itemData.currency, isIncognito,  isHoldingView ? itemData.holdings : 100)}</span></LoanAdditionalDataBox>
                </StatAdditionalDataWrapper> : null
            }  
             },
            { title:'Total inflow', menuDescription : 'Include any inflow (late fees etc.) as well as interest and principal repayments.' ,dataKey: 'distributions', multipleValues:true, }
        ]},

        {isArray: true , stats: [
            {
                title: 'Lent amount',
                menuDescription: 'Contributions made for the loan', 
                dataKey:'amount_lent',
                multipleValues: true,
                // additionalDataFn: (itemData, selectedTimeFrameIndex, isHoldingView, isIncognito) => {
                //     const { expected_return } = itemData;
                //     const expectedTotalReturn = expected_return?.find(i=>i.label === 'Total').target || 0;
                //     return expected_return.length ?
                //     <StatAdditionalDataWrapper>
                //         <LoanAdditionalDataBox>
                //             Total expected return <span>{displayMoneyValue(expectedTotalReturn, itemData.currency, isIncognito,  isHoldingView ? itemData.holdings : 100)}</span><EditPencilComp itemData={itemData} fieldKey={'private-loan-non-amortized-payments'} />
                //         </LoanAdditionalDataBox>
                //     </StatAdditionalDataWrapper>
                //     : null
                // } 
            },
            {
                title:'Total contributions', 
                menuDescription : 'Include any outflow (Service fee / Origination fee etc.) as well as the lent amount',
                dataKey: 'contributions', 
                multipleValues:true,
            }
        ]},

    ],
    visuals: []

}

const EditPencilComp = ({ itemData, fieldKey , text }) => {
    const history = useHistory();

    const handleClick = () => {
        const url = `/wealth/assets/${getUrlDisplayName(itemData.categoryClass.title)}/${itemData.id}/info?field=${fieldKey}`;
        navigateToUrl(history, url);
    };

    return (
        <PencilIconWrapper onClick={handleClick}>
            {text && <PencilIconTextWrapper>{text}</PencilIconTextWrapper>}
            <PencilEditSvg withText={text}/>
        </PencilIconWrapper>
    );
};

const PencilEditSvg = ({withText}) => {
    return (
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={withText ? {marginTop:'-1px'}:{}}>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.6051 3.22014C13.4582 3.07415 13.2205 3.07462 13.0742 3.2212L7.68505 8.61988C7.64018 8.66483 7.60749 8.72042 7.59004 8.78143L7.0553 10.651C7.02291 10.7643 7.12811 10.8688 7.24149 10.8361L9.11315 10.295C9.17423 10.2774 9.22981 10.2445 9.27468 10.1996L13.2311 6.23618L11.6481 4.65314L12.1784 4.12281L13.761 5.70538L14.6638 4.80091C14.8102 4.65433 14.8097 4.41717 14.6628 4.27118L13.6051 3.22014Z" fill="#585D66" />
        <path d="M13.5 9V13.425C13.5 13.4664 13.4664 13.5 13.425 13.5H4.575C4.53358 13.5 4.5 13.4664 4.5 13.425L4.5 4.575C4.5 4.53358 4.53358 4.5 4.575 4.5H9" stroke="#585D66" strokeWidth="1.125" />
      </svg>
    )
}