
import React, { useEffect, useCallback } from 'react';
import { Observer} from 'mobx-react';
import { useStore } from  '../../app/data/root.store';
// import { ASSETS_ADD_ITEM_FORMS } from '../../add-item/data/add-item-forms/test-forms-functions.constant';
import { FormDisplayer } from '../../../common/components/form-displayer/FormDisplayer';
// import { useFormHook } from '../data/useFormHook';

export function FormWithStore(props) {
    const { sections, defaultValues, isStepsDisplay, isDisabled, scrollContainerId, formName, attributeHistoryUpdated, attributeHistoryDeleted, fullWidth, isActionLocked, inViewTopOffset , inViewBottomOffset } = props;
    const { uiStore, formsStore} = useStore();
    // const createEventForm = useFormHook(formName);

    useEffect(() => {
        formsStore.register(formName);
        return () => {
            formsStore.unRegister(formName);
        }
    }, [formName,formsStore]);
    
    const updateFieldConnector = useCallback((key,update) => {
        // console.log(key,update);
        formsStore.forms[formName].updateField(key,update);
    },[formsStore,formName]);

    const updateFieldValueConnector = useCallback((key,value) => {
        // console.log(key,update);
        formsStore.forms[formName].updateValue(key,value,true)
    },[formsStore,formName]);

    const updateSectionConnector = useCallback((key,value) => {
        // console.log(key,update);
        formsStore.forms[formName].updateSection(key,value)
    },[formsStore,formName]);

    useEffect(() => { 
        if (sections && sections.length){
            const formSections = typeof sections === 'function' ? sections(
                {updateField:updateFieldConnector,updateValue:updateFieldValueConnector, updateSection: updateSectionConnector}) : sections;
            formsStore.forms[formName].setSections(formSections,defaultValues, isStepsDisplay ? 0 : -1);
            // if (isStepsDisplay){
            //     formsStore.forms[formName].setStep(0);
            // } else {
            //     formsStore.forms[formName].callOnLoadForAllSections();
            // }
            
            formsStore.forms[formName].setErrors({});
            formsStore.forms[formName].setBottomErrors({});
        }
        return () => {}
    }, [updateFieldConnector,updateFieldValueConnector,updateSectionConnector,sections,defaultValues,formName,formsStore, isStepsDisplay]);

    const handleFiledChange = (fieldName, value ) => {
        formsStore.forms[formName].updateValue(fieldName,value,false);
        props.valueUpdated && props.valueUpdated(fieldName, value);
    }

    const handleFieldBlur = (fieldName) => {
        formsStore.forms[formName].handleFieldBlur(fieldName);
    }

    const handleShowComponentAfterPreviewComponent = (fieldKey) => {
        formsStore.forms[formName].showComponentAfterPrev(fieldKey);
    }

    const handleChangeShowUnlockComponent = (fieldKey,show) => {
        formsStore.forms[formName].handleChangeShowUnlockComponent(fieldKey,show);
    }

    const handleUnlockComponent = (fieldKey) => {
        formsStore.forms[formName].handleUnlockComponent(fieldKey);
    }
    
    return (<Observer>{()=>(
        formsStore.forms[formName] ?
        <FormDisplayer
            sections={formsStore.forms[formName].sections}
            fields={formsStore.forms[formName].fieldsObj}
            values={formsStore.forms[formName].values}
            errors={formsStore.forms[formName].errors}
            bottomErrors={formsStore.forms[formName].bottomErrors}
            currentStep={formsStore.forms[formName].currentSectionIndex}  
            fullWidth={fullWidth}
            isDesktopView={uiStore.isDesktopView}
            isStepsDisplay={isStepsDisplay}
            isDisabled={isDisabled}
            inViewTopOffset={inViewTopOffset||0}
            inViewBottomOffset={inViewBottomOffset||0}
            handleFiledChange={handleFiledChange}
            handleFieldBlur={handleFieldBlur}
            handleShowComponentAfterPreviewComponent={handleShowComponentAfterPreviewComponent}
            handleChangeShowUnlockComponent={handleChangeShowUnlockComponent}
            handleUnlockComponent={handleUnlockComponent}
            attributeHistoryUpdated={attributeHistoryUpdated}
            attributeHistoryDeleted={attributeHistoryDeleted}
            isActionLocked={isActionLocked}
            scrollContainerId={scrollContainerId}>
        </FormDisplayer> : null
    )}</Observer>)
}