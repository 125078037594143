import { CURRENCY_SYMBOL } from "../../../../../../../common/constants/currency-symbol.const";
import { newPrivateBusinessEquityEvents, privateBusinessEquityEvents, privateBusinessOptionsEvents } from "./privateBusiness.events.const";
import { eventTypeGenerator } from "./createEventUtils";
import { fundsEvents } from "./funds.events.const";
import { cryptoEvents } from "./crypto.events.const";
import { realEstateDevelopmentSoleOwnershipEvents, realEstateDevelopmentWithPartnersEvents, realEstateRentalSoleOwnershipEvents, realEstateRentalWithPartnersEvents, realEstateResidenceEvents } from "./realEstate.events.const";
import { loanAmortizationEvents } from "./loanAmortization.events.const";
import { privateLoanAmortizedEvents } from "./private-loan-amortized.events.const";
import { publicMarketEvents, singleTickerEvents } from "./publicMarkets.events.const";
import { lifeInsuranceEvents } from "./lifeInsurance.events.const";
import { privateBusinessTraditionalEvents } from "./privateBusinessTraditionalEvents";
import { privateLoanNonAmortizedEvents } from "./private-loan-non-amortized.events.const";
import { loanNonAmortizedEvents } from "./loan-non-amortized.events.const";

const valueUpdateOnly = eventTypeGenerator(false, false, true);
const contributionAndValueUpdate = eventTypeGenerator(true, false, true);
const distributionAndValueUpdate = eventTypeGenerator(false, true, true);
const itemCreatedValueUpdate = eventTypeGenerator(true, false, true);

const cryptoWalletEvents = [
    {title:'Sale/withdrawal of coins' ,  sections: distributionAndValueUpdate, isDistribution: true},
    {title:'Purchase of more coins' , sections: contributionAndValueUpdate},
    {title: 'Value update' , sections: valueUpdateOnly},
    {title: 'Item created' , isHidden: true, sections: itemCreatedValueUpdate, deleteEnable: false},
]

const privateLoanEvents = [
    {title:'Loan fully paid' , sections: distributionAndValueUpdate, isDistribution: true},
    {title:'Additional cash lent' , description:'Cash lent', sections: contributionAndValueUpdate},
    {title:'Interest payment received' , description:'Cash Received', sections: distributionAndValueUpdate, isDistribution: true},
    {title:'Principal payment received' , description:'Cash Received', sections: distributionAndValueUpdate, isDistribution: true},
    {title:'General payment received' , description:'Cash Received', sections: distributionAndValueUpdate, isDistribution: true},
    {title:'End date' , isPredicted: true , sections: distributionAndValueUpdate , isDistribution: true},
    {title:'Interest and principal repayment' , isPredicted: true , sections: distributionAndValueUpdate , isDistribution : true },
    {title:'Interest repayment' , isPredicted: true , sections: distributionAndValueUpdate , isDistribution : true },
    {title:'Principal repayment' , isPredicted: true , sections: distributionAndValueUpdate , isDistribution : true },
    {title: 'Value update' , isHidden: true, sections: valueUpdateOnly},
    {title: 'Item created' , isHidden: true, sections: itemCreatedValueUpdate, deleteEnable: false},
]

const p2pLendingPlatformEvents = [
    {title:'Loan fully paid' , sections: distributionAndValueUpdate, isDistribution: true},
    {title:'Additional cash lent' , description:'Cash lent', sections: contributionAndValueUpdate},
    {title:'Interest payment received' , description:'Cash Received', sections: distributionAndValueUpdate, isDistribution: true},
    {title:'Principal payment received' , description:'Cash Received', sections: distributionAndValueUpdate, isDistribution: true},
    {title:'General payment received' , description:'Cash Received', sections: distributionAndValueUpdate, isDistribution: true},
    {title: 'Value update' , isHidden: true, sections: valueUpdateOnly},
    {title: 'Item created' , isHidden: true, sections: itemCreatedValueUpdate, deleteEnable: false},
]

export const DEFAULT_EVENTS_TYPES = [
    {title:'Value update', description:'Will be reflected in your net worth' , sections:valueUpdateOnly},
    {title:'Contribution', description:'Cash you added to this investment',sections:contributionAndValueUpdate },
    {title:'Distribution', description:'Cash you received from this investment', sections: distributionAndValueUpdate , isDistribution: true},
    {title: 'Item created' , isHidden: true, sections: itemCreatedValueUpdate, deleteEnable: false},
]

const cashAccountItemCreatedValueUpdate = eventTypeGenerator(false, false, true);
export const cashAccountsOnlyValueUpdate = [
    {title:'Value update', description:'Will be reflected in your net worth' , sections:valueUpdateOnly},
    {title: 'Item created' , isHidden: true, sections: cashAccountItemCreatedValueUpdate, deleteEnable: false},
];


//// LIABILITIES 

const liabilityEventTypeGenerator = (withContribution = false, withDistribution = false, withValueUpdate = false, customFields = []) => {
    return ({updateValue}) => ([
        {
            title: '',
            rows: [
                // ...commonRows,
                ...(withContribution || withDistribution ? [{fields:[
                    {key:'transactionValue', type: 'numberWithHoldings', label: withContribution ? 'Contribution value' : 'Distribution value', 
                    generatePropsFn : (values)=>{
                        return {
                            holdings: values.holdings,
                            prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                            placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                            decimalScale : 2, 
                        }
                    },
                    }
                ]}] : []),
                ...customFields,
                ...(withValueUpdate ? [{fields:[
                    {key:'updatedValue', type: 'numberWithHoldings', label: 'New value' , defaultValueFn: (values)=>{
                        return values.value ? values.value : ''
                    },
                    generatePropsFn : (values)=>{
                        return {
                            holdings: values.holdings,
                            prefix: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                            placeholder: CURRENCY_SYMBOL[values.trnCurrency] || CURRENCY_SYMBOL[values.currency] || '',
                            decimalScale : 2, 
                        }
                    },
                    }
                ]}] : []),
                {fields:[],type:'separator'},
                {fields: [{key:'eventRemarks', type: 'textarea', label: 'Remarks' , rows:2 , placeholder:'Add notes to remember'}]}
            ]
        }
    ])
}

const liabilityValueUpdateOnly = liabilityEventTypeGenerator(false, false, true);
const liabilityContributionOnly = liabilityEventTypeGenerator(true);
const liabilityContributionAndValueUpdate = liabilityEventTypeGenerator(true, false, true);
const liabilityDistributionAndValueUpdate = liabilityEventTypeGenerator(false, true, true);
const liabilityItemCreatedValueUpdate = liabilityEventTypeGenerator(false, true, true);

const liabilityLoanEvents = [
    {title:'Value update', isLiability: true, description:'Will be reflected in your net worth' , sections:liabilityValueUpdateOnly},
    { title:'Withdrawal',  isLiability: true, description:'Cash you received from this loan', sections: liabilityDistributionAndValueUpdate , isDistribution: true},
    { title:'Contribution',  isLiability: true, description:'Cash you returned to this loan',sections:liabilityContributionAndValueUpdate },
    { title:'Principal repayment',  isLiability: true, isPredicted: true , sections: liabilityContributionOnly },
    { title:'Interest repayment',  isLiability: true, isPredicted: true , sections: liabilityContributionOnly },
    { title:'Interest and principal repayment',  isLiability: true, isPredicted: true , sections: liabilityContributionOnly },
    {title: 'Item created',  isLiability: true, isHidden: true, sections: liabilityItemCreatedValueUpdate, deleteEnable: false},
]

export const ASSET_CATEGORIES_CREATE_EVENT = {
    1: cashAccountsOnlyValueUpdate,
    2: cashAccountsOnlyValueUpdate,
    3: cashAccountsOnlyValueUpdate,
    4: cashAccountsOnlyValueUpdate,
    5: cashAccountsOnlyValueUpdate,
    12: cryptoWalletEvents,
    36: cryptoEvents,
    15: realEstateRentalSoleOwnershipEvents,
    16: realEstateRentalWithPartnersEvents,
    17: realEstateDevelopmentSoleOwnershipEvents,
    18: realEstateDevelopmentWithPartnersEvents,
    21: realEstateResidenceEvents,
    22: privateLoanEvents,
    23: p2pLendingPlatformEvents,
    24: privateBusinessEquityEvents,
    35: newPrivateBusinessEquityEvents,
    31: privateBusinessTraditionalEvents,
    25: privateBusinessOptionsEvents,
    14: fundsEvents,
    19: fundsEvents,
    26: fundsEvents,
    27: fundsEvents,
    32: fundsEvents,
    33: liabilityLoanEvents,
    37: loanAmortizationEvents,
    38: privateLoanAmortizedEvents,
    41: privateLoanNonAmortizedEvents,
    13: publicMarketEvents,
    39: singleTickerEvents,
    40: lifeInsuranceEvents,
    42: loanNonAmortizedEvents,
}

export const ASSET_CATEGORIES_DISPLAY_ATTRIBUTES_VALUE_AT_DATE = {
    40: [{dataKey:  'deathBenefit', title:'Death benefit at date:' , type: 'money'}]
}


