import styled from '@emotion/styled/macro';
import { Link } from "react-router-dom";
import { animateSpin } from '../../../../../../common/styles/animations.styles';
import { flexCenter } from '../../../../../../common/styles/combos.styles';
import { colors, views } from '../../../../../../common/styles/theme.styles';
import { maxMetricGraphBarHeight } from './ItemMetricBars';

export const TabWrapper = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    padding-top:24px;
`
export const ItemWorthAndConnectedLiabilities = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    margin-bottom:24px;
`;

export const ItemWorth = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    padding-left:24px;
    padding-bottom:8px;
`;

export const WorthValue = styled.div`
    color: #0038FF;
    font-size:40px;
    font-weight:700;
`;

export const ConnectedLiabilityWrapper = styled.div`
    border-left:1px solid #C8C8C8;
    padding: 12px 24px;
    display:flex;
    flex-direction:column;
`;

export const ConnectedLiabilityValue = styled.div`
    font-size: 22px;
    line-height: 26px;
    color: #fff;
    margin-bottom:4px;
`;

export const ConnectedLiabilityText = styled.div`
    color:#fff;
    font-weight:600;
    font-size:18px;
    line-height:20px;
`;

export const ConnectedLiabilityButton = styled(Link)`
    border : 1px solid #fff;
    color: #000;
    border-radius: 2px;  
    font-size: 14px;
    color: #fff;
    padding: 8px 8px 8px 12px;
    &:hover{
        color: #fff;
        background-color :#333333;
    }
    padding: 4px 8px;
    margin-top: 8px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    svg{
        path{
            fill:#fbfbfb;
        }
    }
`;

export const DisconnectLiabilityButtonWrapper = styled.div`
    position: absolute;
    height:32px;
    left:100% ; //calc(100% - 1px);
    width:24px;
    // background-color:red;
    display:none;
    cursor: default;
    ${ConnectedLiabilityButton}:hover &{
        display:block;
    }
    text-align:right;
`;

export const DisconnectLiabilityIconWrapper = styled.div`
    cursor: pointer;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    height:100%;
`;


export const StatsRow = styled.div`
    display:flex;
    flex-direction:row;
    margin:0 -8px;
    margin-bottom: 32px;
`;

export const StatBox = styled.div`
    /* box-shadow: 0px 1px 4px #E5E9F2; */
    color: ${props => (props.isClassView || props.isWealthView) ? '#fff' : '#3F3F45'};
    border-radius: ${props => props.isOpen ? '8px 8px 0 0' : '8px'};
    /* padding:24px; */
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    /* margin:0 16px; */
    /* width:calc(33.3% - 32px);
    max-width:calc(33.3% - 32px); */
    width: 100%;
    
    cursor: ${props => props.haveChilds ? 'pointer' : 'default'};
    position:relative;
    ${props => (props.isClassView || props.isWealthView) ? `justify-content:space-between;` : ``}
    ${views.desktop} {
        height: 100px;
    }
`;

export const StatSubWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    width: 100%;
`

export const StatValue = styled.div`
    display: flex;
    font-size:22px;
    font-weight:500;
    div{
        font-size: ${props => props.showInlineTargetActual ? 12 : 22}px;
        font-weight:500;
        ${props => props.showInlineTargetActual ? `
            display: flex;
            align-items: center;
            // margin-left: 6px;
        ` : ``}
    }
`;

export const StatDescriptionBig = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    height: 16px;
    line-height: 16px;
    font-weight:400;
    font-size:14px;
    margin-bottom:8px; 
    ${props=> props.isWealthView || props.isClassView ? `color:${colors.gray2}` : ``};
    ${props=> props.width ? `width:${props.width}` : ``};
`;

export const MetricWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const StatContentAndGraph = styled.div`
    display:flex;
    align-items: flex-end;
`;

export const StatMultiContentWrapper = styled.div`
    ${props => props.fullWidth ? `
        flex:1;
    ` : ``} 
`;

export const StatContentWrapper = styled.div`
    height: 24px;
    line-height: 24px;
`;


export const StatGraphWrapper = styled.div``;

export const StatDescriptionSmall = styled.div`
    color:#9E9D9D;
    font-size:14px;
`;

export const StatTargetValue = styled.div`
    color: #E48A04;
    font-size:13px;
    line-height: 15px;
    /* margin-left:4px; */
`;

export const StatChildsArrowWrapper = styled.div`
    display:flex;
    flex:1;
    justify-content:center;
    align-items: flex-end;
    width:100%;
    padding-top:8px;
`;

export const StatTimeFrameSelectorWrapper = styled.div`
    height:14px;
    line-height:14px;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '8'}px;
    display: flex;
    align-items: center;
`;

export const ArrowIconWrapper = styled.div`
    line-height: 6px;
    svg{
        transition:transform 0.2s;
        transform:rotate(${props => props.isOpen ? '180' : '0'}deg);
    }
`;

export const ChartRow = styled.div`
    padding-top:32px;
    display:flex;
    flex-direction:row;
    margin:0 8px;
`;

export const ChartPlaceHolderWrapper = styled.div`
    position:relative;
    // width:100%;
    box-shadow: 0px 1px 4px #e5e9f2;
    border-radius: 8px;
    margin: 0 8px;
    /* border: 1px solid #e3e3e3; */
    svg{width:100%}
`;

export const ChartPlaceHolderText = styled.div`
    position:absolute;
    top:46px;
    left:50%;
    transform: translateX(-50%);
    color:#9D9D9D;
    font-size:18px;
`;


export const StatChildsMenuWrapper = styled.div`
    position:absolute;
    width:100%;
    // top: calc(100% - 12px);
    top: 100%;
    left: 0px;
    z-index: 2;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px #e5e9f2;
    border-radius: 0 0 8px 8px;
`;

export const ChildStatBoxWrapper = styled.div`
    padding: 16px 0;
    border-top: 1px solid #C8C8C8;
    margin: 0 16px;
`;

export const SyncWhiteIconWrapper = styled.div`
    svg{
        path{
            fill:white;
        }
    }
    animation-name: ${animateSpin};
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    ${flexCenter};
`;

export const ValueChangesButton = styled.div`
    color: ${props => props.color ? props.color : colors.darkGray1}; // #9097A3;
    font-size:12px;
    svg {margin-left:8px}
    /* margin-top:8px; */
    cursor: pointer;
    ${flexCenter};
    pointer-events:auto;
    svg {
        path {
            stroke: ${props => props.color ? props.color : colors.darkGray1} // #9097A3;
        }
    }
    border:${props => props.borderLess ? 'none' : '1px solid #EBF0F8'};
    border-radius: 20px;
    padding: 2px ${props => props.borderLess ? '' : '8px'};
`;



export const ItemTargetActualPerformanceWrapper = styled.div`
    color: ${props => props.isPositive ? '#6EBA7F' : '#E43A04'};
    margin-left: 2px;
    font-size: 13px;
    line-height: 15px;
`;

export const ItemMetricBarsWrapper = styled.div`
    display: flex;
    gap: 4px;
    align-items: flex-end;
    font-weight: 500;
    font-size:14px;
    line-height:14px;
    margin-left: 12px;
    margin-bottom: 2px;
    // align-self: center;
`;

export const TargetMetricBar = styled.div`
    width: 6px;
    height: ${props => props.height > maxMetricGraphBarHeight ? maxMetricGraphBarHeight : props.height}px;
    background-color: #FFCA7B;
    border-radius: 2px;
`;

export const ActualMetricBar = styled(TargetMetricBar)`
    min-height:2px;
    background-color: #607DE6;
`;

export const StatAdditionalDataWrapper = styled.div`
    margin-top: 4px;
`;

export const LoanAdditionalDataBox = styled.div`
    display: flex;
    gap: 4px;
    font-weight: 500;
    font-size: ${props => props.fontShrink ? '12px;' : '13px;'}
    line-height: ${props => props.fontShrink ? '13px;' : '16px;'}
    color: ${colors.darkGray3};
    
    span{
        color: ${colors.darkGray1};
    }
    `;

export const StatRemainingTimeWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StatsArrayActionMenuWrapper = styled.div`
    margin-left: -24px;
    margin-top: -4px;
`;

export const StatsArrayActionMenuContent = styled.div`
    width: 250px;
    border: 1px solid #DDE3EB;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
`;

export const StatsArrayActionMenuItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    padding-left: 12px;
    cursor: pointer;
    background: ${props => props.selectedItem ? 'linear-gradient( 90deg, #000  2px, #F7F8F9 2px);' : '#fff'};
    animation: all 0.2s;

    :first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    :last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    :hover {
        background: linear-gradient( 90deg, #000  2px, #F7F8F9 2px);
    }
`;

export const StatsArrayActionMenuItemTitlesWrapper = styled.div`
    display: flex;
    flex:1;
    flex-direction: column;
    gap: 2px;
`;

export const StatsArrayActionMenuItemTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.darkGray1};
`;

export const StatsArrayActionMenuItemSubTitle = styled.div`
    font-weight: 450;
    font-size: 13px;
    line-height: 16px;
    color: ${colors.darkGray3};
`;

export const CheckMarkSvgWrapper = styled.div`
    ${flexCenter};
    width: 24px;
`;

export const CapitalMetricsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    color: ${colors.darkGray3};
    font-weight: 450;
    font-size: 13px;
    line-height: 16px;
    margin-top: 4px;
`;

export const CapitalMetricsValue = styled.span`
    color: ${colors.darkGray1};
`;

export const AvailableLoanAmountEmptyStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    color: ${colors.darkGray3};
    font-family: Circular;
    font-size: 12px;
    font-weight: 450;
`;

export const LeveragedAssetsFilterButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.darkGray2};
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
`;

export const AvailableRemainingLoanAmountWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => (props.isNegative ? 'red' : '')};
`;

export const PencilIconWrapper = styled.div`
    cursor: pointer;
    line-height: 16px;
    ${flexCenter}
`;

export const PencilIconTextWrapper = styled.div`
    line-height: 16px;
`;