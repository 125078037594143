import moment from "moment";
import { CURRENCY_SYMBOL } from "../../../../../common/constants/currency-symbol.const";
import { getTranslations } from "../../../../../common/translations/translations";
import { disabledMoreThanToday , disabledMonthBefore } from "../../../../../common/utils/dates.utils";
import { HoldingsUnlockModal } from "../../../../wealth/pages/asset/components/Info/custom-form-components/HoldingsUnlockModal";
import { beneficiaryRow } from "../../common-fields/beneficiary-field.const";
import { containerRow } from "../../common-fields/container-field.const";
import { customClassAndSubClassRows } from "../../common-fields/custom-class-and-sub-class-field.const";
import { liquidityRow } from "../../common-fields/liquidity-field.const";
import { masterInstitutionInputRows } from "../../common-fields/master-institution.const";
import { riskLevelRow } from "../../common-fields/risk-level-field.const";
import { isBetweenZeroAndHundred, isNotNegative, isNotNegativeIfNotEmpty, isRequired } from "../../input-validations";
import { ChangeLoanTermsModal } from "../../../../wealth/pages/asset/components/Info/custom-form-components/ChangeLoanTermsModal";
// import { isNullOrUndefinedOrEmptyString } from "../../../../../common/utils/object.utils";

export const createLoanAddItemSections = (isEditForm) => {
    return ({updateValue,updateField}) => {
        const { loans } = getTranslations().new_item_modal;
        return [
            {
                title:'General info',
                rows:[
                    ...masterInstitutionInputRows(loans.NAME_LABEL,loans.NAME_DESCRIPTION, 1, false, updateValue),
                    {fields:[
                        {key:'title',label:'Loan name', validations:[isRequired], description:loans.TITLE_DESCRIPTION}
                    ]},
                    beneficiaryRow({}),
                    {fields:[
                        {key:'value', type: 'numberWithHoldings', validations:[isRequired], label: loans.VALUE_LABEL,
                        onBlur:({value})=>{
                            updateValue('cashReceived',value);
                        },
                        description: loans.VALUE_DESCRIPTION,generatePropsFn : (values)=>{
                            return {prefix: CURRENCY_SYMBOL[values.currency] || ''}
                        }},
                        {key:'currency',label:' ',size:0.3 ,onChange:({value})=>{
                            
                            updateField('value',{prefix:CURRENCY_SYMBOL[value] || ''});
                            updateValue('cashInvestedCurrency',value);
                            updateField('cashInvested',{prefix:CURRENCY_SYMBOL[value] || ''});
                            updateValue('cashReceivedCurrency',value);
                            updateField('cashReceived',{prefix:CURRENCY_SYMBOL[value] || ''});
                        }}
                    ]},
                    ...( isEditForm ? [
                        ...customClassAndSubClassRows({updateValue,updateField}),
                    ] : [
                        {
                            fields:[
                                {key:'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isHidden:true,
                                validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                validations:[isBetweenZeroAndHundred],
                                    onBlur:({value})=>{
                                        updateField('value',{holdings:value || ''});
                                        updateField('cashInvested',{holdings:value || ''});
                                        updateField('cashReceived',{holdings:value || ''});
                                    },
                                    generatePropsFn : (values)=>{
                                        return {isHidden: values.holdings === 100}
                                    }
                                }
                            ]
                        },
                        {
                            fields:[
                                {type:'checkbox', key:'isPartialHolding',checkboxLabel: 'This loan is 100% owned by me', description: 'If you are co-owners of this loan, uncheck this box' ,
                                fieldCustomStyle: {marginTop:'-24px'},
                                defaultValue: true,
                                    onChange: ({value})=>{
                                        const newHoldingValue = value ? 100 : 50;
                                        updateValue('holdings',newHoldingValue);
                                        updateField('holdings',{isHidden:value});
                                        updateField('value',{holdings:newHoldingValue});
                                        updateField('cashInvested',{holdings:newHoldingValue});
                                        updateField('cashReceived',{holdings:newHoldingValue});
                                    }
                                },
                            ]
                        },
                        ]),
                ]
            },{
                title: 'Projection', 
                rows:[
                    {fields:[
                        {key:'interest', label: loans.INTEREST_LABEL, validations:[isNotNegative]}
                    ]},
                    {fields:[
                        {key:'expectedEndDate'}
                    ]},
                    {fields:[
                        {key:'interestRepaymentPeriod'}
                    ]},
                    {fields:[
                        {key:'principalRepaymentPeriod'}
                    ]},
                ]
            },{
                title: 'Performance', 
                rows:[
                    {fields:[
                        {key:'startDate', label: loans.START_DATE_LABEL, description: loans.START_DATE_DESCRIPTION, defaultValueFn: ()=>{return new Date()},
                        disabledDate: disabledMoreThanToday}
                    ]},
                    {fields:[
                        {key:'cashReceived', type: 'numberWithHoldings', label: loans.CASH_RECEIVED_LABEL , description: loans.CASH_RECEIVED_DESCRIPTION,prefix:CURRENCY_SYMBOL['USD']},
                        {key:'cashReceivedCurrency',size:0.3 , label: ' ', defaultValue: 'USD'}
                    ]},
                    {fields:[
                        {key:'cashInvested', type: 'numberWithHoldings', label: loans.CASH_INVESTED_LABEL , description: loans.CASH_INVESTED_DESCRIPTION,prefix:CURRENCY_SYMBOL['USD']},
                        {key:'cashInvestedCurrency',size:0.3 , label: ' ', defaultValue: 'USD'}
                    ]},
                ]
            },{
                title:`More info`,
                rows:[
                    ...(isEditForm ? [
                        containerRow({isEditForm, updateField}),
                        {
                            fields:[
                                {key:'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth',  isLocked: true,  UnlockComponent:HoldingsUnlockModal,
                                validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                                validations:[isBetweenZeroAndHundred],
                                // onBlur:({value})=>{
                                //     updateField('monthlyRent',{holdings:value || ''});
                                //     updateField('expectedMonthlyExpenses',{holdings:value || ''});
                                //     updateField('expectedSalePrice',{holdings:value || ''});
                                // },
                                }
                            ]
                        },
                        {
                            fields:[
                                {key:'defaultCashAccountId'}
                            ]
                        },
                        riskLevelRow({updateField}),
                        liquidityRow({updateField}),
                    ] : []),
                    {fields:[
                        {key:'remarks'}
                    ]}
                ]
            }
        ]
}
}

let start_date;

export const createLoanNonAmortizedAddItemSections = (isEditForm) => {
    return ({ updateValue, updateField , updateSection }) => {
      const { loans } = getTranslations().new_item_modal;
      return [
        {
          title: 'General info',
          rows: [
            // {
            //   fields: [
            //     {
            //       key: 'borrower', type: 'text', label: 'Borrower', description: 'The person or entity responsible for repaying the loan', validations: [isRequired],
            //       onBlur: ({ value, formValues }) => {
            //         if (!formValues.title){
            //           updateValue('title', value);
            //         } 
            //       }
            //     },
            //   ]
            // },
            ...masterInstitutionInputRows('Agent / Platform', 'The platform or agent servicing this loan', 1, false, updateValue),
            {
              fields: [
                { key: 'title', label: 'Loan name', description: `This is the name you'll use to search for this private loan in Vyzer`, validations: [isRequired] },
              ]
            },
            // beneficiaryRow({}),
            {fields:[
                {key:'startDate', label: 'Date you received the loan',
                description: 'The date you received the cash', 
                validations:[isRequired],
                // defaultValueFn: ()=>{return new Date()},
                disabledDate: disabledMoreThanToday,
                onChange:({value})=>{
                    const oneMonthFromStartDate = moment(value).add(1, 'month');
                    updateField('loanNonAmortizedPayments',{startDate: oneMonthFromStartDate} )
                    updateField('expectedEndDate',{disabledDate : (current) => disabledMonthBefore(current,oneMonthFromStartDate)} )
                    // start_date = value;
                    // updateValue('principalFirstRepayment', moment(value).add(1, 'M'));
                    // updateValue('interestFirstPayment', moment(value).add(1, 'M').toDate());
                    // updateField('amortization', {startDate: moment(value)})
                }}
            ]},
            {fields:[
                {key:'value', type: 'numberWithHoldings', validations:[isRequired], label: loans.VALUE_LABEL,
                onBlur:({value})=>{
                    updateValue('cashReceived',value);
                },
                description: loans.VALUE_DESCRIPTION,generatePropsFn : (values)=>{
                    return {prefix: CURRENCY_SYMBOL[values.currency] || ''}
                }},
                {key:'currency',label:' ',size:0.3 ,onChange:({value})=>{                    
                    updateField('value',{prefix:CURRENCY_SYMBOL[value] || ''});
                    updateField('loanNonAmortizedPayments',{prefix:CURRENCY_SYMBOL[value] || ''});
                    updateValue('cashReceivedCurrency',value);
                    updateField('cashReceived',{prefix:CURRENCY_SYMBOL[value] || ''});
                }}
            ]},
            ...(isEditForm ? [
              ...customClassAndSubClassRows({updateValue,updateField}),
            ] : [
                {
                    fields:[
                        {key:'holdings', label: 'My ownership percentage', description: 'The ownership percentage of the asset you own, as it should be reflected in your net worth', isHidden:true,
                        validValues: ({floatValue}) => { return !floatValue || (floatValue >= 0 && floatValue <= 100)},
                        validations:[isBetweenZeroAndHundred],
                            onBlur:({value})=>{
                                updateField('value',{holdings:value || ''});
                                updateField('cashInvested',{holdings:value || ''});
                                updateField('cashReceived',{holdings:value || ''});
                                updateField('loanNonAmortizedPayments',{holdings:value || ''});
                            },
                            generatePropsFn : (values)=>{
                                return {isHidden: values.holdings === 100}
                            }
                        }
                    ]
                },
                {
                    fields:[
                        {type:'checkbox', key:'isPartialHolding',checkboxLabel: 'This loan is 100% owned by me', description: 'If you are co-owners of this loan, uncheck this box' ,
                        fieldCustomStyle: {marginTop:'-24px'},
                        defaultValue: true,
                            onChange: ({value})=>{
                                const newHoldingValue = value ? 100 : 50;
                                updateValue('holdings',newHoldingValue);
                                updateField('holdings',{isHidden:value});
                                updateField('value',{holdings:newHoldingValue});
                                updateField('cashInvested',{holdings:newHoldingValue});
                                updateField('cashReceived',{holdings:newHoldingValue});
                                updateField('loanNonAmortizedPayments',{holdings:newHoldingValue});
                            }
                        },
                    ]
                },
            ]),
          ]
        },
        {
          title:'Loan terms',
          rows: [
            {fields:[
                {
                    key:'interest', 
                    label: 'Interest rate',
                    validations:[isRequired,isNotNegativeIfNotEmpty],
                    specificDecimalScale: 3,
                    // isLocked: isEditForm,
                    showHistory: false,
                    disabled: isEditForm,
                    // UnlockComponent: ChangeLoanTermsModal,
                    // onUnlock: () => {
                    //     updateField('fixedMonthlyPayment', {isLocked: false})
                    //     updateField('amortization', {isLocked: false})
                    // },
                    // onBlur:({value,formValues}) => {
                    //     const { amount_borrowed, amortization } = formValues;
                    //     if (amount_borrowed && amount_borrowed[0].actual && amortization.amount) {
                    //         // const monthlyPayment = calcMonthlyPayment(amount_borrowed[0].actual , value/100 ,
                    //         // amortization.amount * (amortization.period === 'Annually' ? 12 : 1))
                    //         // updateValue('fixedMonthlyPayment',monthlyPayment);
                    //     } else if(!isNullOrUndefined(value) && formValues.amortization.amount){
                    //         // const monthlyPayment = calcMonthlyPayment(formValues.value , value/100 ,
                    //         // amortization.amount * (amortization.period === 'Annually' ? 12 : 1))
                    //         // updateValue('fixedMonthlyPayment',monthlyPayment);
                    //     }
                    // }
                }
            ]},
            {fields:[
                {
                    key:'expectedEndDate', 
                    label:'Expected payoff date', 
                    description: loans.EXPECTED_END_DATE_DESCRIPTION, 
                    // validations:[isRequired],
                    validations:[isRequired],
                    disabledDate: (current) => disabledMonthBefore(current, moment(start_date).add(1, 'month')),
                }
            ]},
            {fields:[
                {
                    key:'loanType',
                    disabled: isEditForm,
                    isLocked: isEditForm,
                    validations: isEditForm ? [] : [isRequired],
                    UnlockComponent:  ChangeLoanTermsModal,
                    unlockComponentsProps: {title: 'Changing loan type is not allowed', subTitle: `To modify the loan type, please delete this loan and create a new one with the desired type.`},
                    onChange:({value,formValues, previousValue})=>{
                        updateField('loanNonAmortizedPayments',{showEscrow : value === 'Mortgage'});
                        if (previousValue === 'Mortgage' && value !== 'Mortgage' && formValues.loanNonAmortizedPayments){
                            /// update total value of loan payments to be equal interest + principal without escrow; 
                            const updatedRows = formValues.loanNonAmortizedPayments.map(row => {
                                return {
                                    ...row,
                                    value: row.interest + row.principal,
                                }
                            })
                            updateValue('loanNonAmortizedPayments', updatedRows);
                        }
                    },
                }
            ]}
          ]
        },
        {
            title: 'Target payment events',
            key: 'targetPaymentEventsSection',
            fullWidth: true,
            onLoad: (values) => {
                updateSection('targetPaymentEventsSection',{extraLargeAddItem: values.loanType === 'Mortgage'});
                updateField('loanNonAmortizedPayments',{headerTooltip: values.loanType === 'Mortgage' ?`The sum of each single expected payment, including principal and interest, and escrow.`: `The sum of each single expected payment, including principal and interest.`});
            },
            rows: [
                {fields: [
                  {
                    key:'loanNonAmortizedPayments',
                    label: 'Expected payments',
                    labelId: 'private-loan-non-amortized-payments',
                    description: 'The amount you committed, divided into several "call events".',
                    type:'singleLoanPaymentEventScheduler',
                    // defaultValue:[],
                    generatePropsFn : (values) => {
                        console.log('values',values);
                        
                        return {
                          prefix: CURRENCY_SYMBOL[values.currency] || 'USD',
                          placeholder: CURRENCY_SYMBOL[values.currency],
                          startDate: values.startDate,
                          holdings: values.holdings,
                          showEscrow: values.loanType === 'Mortgage',
                      }
                    },
                    onShow:({values})=>{
                        updateValue('loanNonAmortizedPayments', [{value:"",date: moment(values.startDate).add(1, 'month'),remarks: "",tempId: 1}]);
                    },
                    // ClickToShowComponent:SingleEventSchedulerPrevComponent
                    setFnForSave: (isDistribution, value, formValues) => {
                      return value?.filter(i => i.principal || i.interest || i.value || i.escrow)
                          .map(({ id, principal, interest,escrow, value: rowValue, remarks, date }) => {
                              const principalValue = principal || (!interest && !escrow ? rowValue : '') || '';
                              return {
                                  id,
                                  value: {
                                      principal: principalValue,
                                      interest,
                                      escrow,
                                  },
                                  remarks,
                                  date,
                              };
                          });
                    },            
                    overrideValueFn: (formValues) => {
                        return formValues.loanNonAmortizedPayments?.map(dataRow=> ({
                            id: dataRow.id,
                            value: dataRow.value.principal + dataRow.value.interest + dataRow.value.escrow,
                            principal: dataRow.value.principal,
                            interest: dataRow.value.interest,
                            escrow: dataRow.value.escrow,
                            date: dataRow.date,
                            remarks: dataRow.remarks,
                        }));
                    },
                  }
                ]},
              ]
        },
        {
          title: 'More info',
          rows: [
            beneficiaryRow({}),
            ...(isEditForm ? [
              containerRow({isEditForm, updateField}),
              {
                fields:[
                    { key:'connectedCollateralId', label:'Collateral', description:`Select the asset that uses as collateral / security of this loan.`, type:'amortizationCollateralAutoComplete'}
                ]
                },
              riskLevelRow({updateField}),
              liquidityRow({updateField}),
            ] : []),
            {
              fields: [
                { key: 'remarks' }
              ]
            }
          ]
        }
      ]
    }
}
  
//   const lastPaymentEventCreation = ({formValues, updateValue}) => {
//     const { startDate, value, expectedEndDate, interest } = formValues;
//     if (startDate && value && interest >= 0 && expectedEndDate) {
//       const periodInMonths = moment(expectedEndDate).diff(moment(startDate), 'months');
//       const monthlyRate = interest / 100 / 12;
//       const compoundedAmount = value * Math.pow(1 + monthlyRate, periodInMonths);
//       const compoundedLastInterestPayment = compoundedAmount - value;
//       updateValue('loanNonAmortizedPayments', [
//         {
//           value: value + compoundedLastInterestPayment,
//           principal: value,
//           interest: compoundedLastInterestPayment,
//           date: moment(expectedEndDate).set("D", 15).startOf("D"),
//           remarks: "",
//           tempId: 1,
//         }
//       ]);
//     }
//   }