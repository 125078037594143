import moment from "moment";
import { errorNotification } from "../../layout/components/Notifications/notifications";
import { useEffect, useState } from "react";
import { ArrowDownIcon, DownloadIcon } from "../../../assets/icons/common/common-icons";
import { PrimaryButtonWithIcon, SecondaryButtonWithIcon } from "../../../common/components/buttons/NewButtons.styles";
import { ActionButtonsWrapper, CheckboxWrapper, DataBoxInputElement, DataBoxInputLabel, DataBoxInputWrapper, FormContentWrapper, GroupedByWrapper, StyledCheckbox } from "./Reports.styles";
import { handleUrlFileDownload } from "../../../common/utils/network.utils";
import { LoadingOutlined } from '@ant-design/icons';
import { StyledSelect } from "../../user/components/Profile.styles";
import { Radio, Select, Space } from "antd";
import { useStore } from "../../app/data/root.store";
import { observer } from "mobx-react";
import { ModifiedTooltip } from "../../../common/components/ModifiedTooltip/ModifiedTooltip";

export const PFSDataBoxContent = observer(({ isMobileAppView, isLimited }) => {

    const { metadataStore } = useStore();
    const [isPFSProccesing, setIsPFSProccesing] = useState(false);
    const [holdingEntities, setHoldingEntities] = useState([]);
    const [holdingEntitiesInputValue, setHoldingEntitiesInputValue] = useState('Select all');
    const [isAllEntitiesChecked, setIsAllEntitiesChecked] = useState(true);
    const [groupedBySelection, setGroupedBySelection] = useState('classes');

    useEffect(() => {
        if (metadataStore.isLoaded) {
            setHoldingEntities(metadataStore.beneficiaries.map(entity => ({ ...entity, checked: true })));
        }

    }, [metadataStore.isLoaded, metadataStore.beneficiaries]);

    const { Option } = Select;

    const FILE_DATE_FORMAT = 'YYYY-M-D';

    const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
    const handlePFSReport = async () => {
        if (isPFSProccesing) return;
        const holdingEntitiesNames = holdingEntitiesInputValue === 'Select all' ? [] :
            holdingEntities.filter(entity => entity.checked).map(entity => entity.name);

        setIsPFSProccesing(true);
        if (isMobileAppView) {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    action: 'download_file_api_request',
                    data: {
                        url: `${baseApiUrl}reports/pfs`,
                        body: {
                            clientDate: new Date().toISOString(),
                            holdingEntities: holdingEntitiesNames,
                            organizeByCustomClasses: (groupedBySelection === 'customClasses')
                        },
                        fileName: `personal-finance-statement-${moment().format(FILE_DATE_FORMAT)}.xlsx`
                    }
                }));

            }
        } else {
            try {
                await handleUrlFileDownload({
                    url: `${baseApiUrl}reports/pfs`,
                    body: {
                        clientDate: new Date().toISOString(),
                        holdingEntities: holdingEntitiesNames,
                        organizeByCustomClasses: (groupedBySelection === 'customClasses')
                    },
                    fileName: `personal-finance-statement-${moment(new Date()).format(FILE_DATE_FORMAT)}.xlsx`
                })
            } catch (e) {
                errorNotification(e.message);
            }
        }

        setIsPFSProccesing(false);
    }

    const holdingEntitiesNamesValue = () => {
        const checkedEntities = holdingEntities.filter(entity => entity.checked === true)
        const names = checkedEntities.map(entity => entity.name)
        setHoldingEntitiesInputValue(names.join(', '))
    }

    const handleAllEntitiesDeselected = (e) => {
        if (holdingEntities.every(entity => entity.checked === false)) {
            handleSelectAll(e);
        }
    }

    const handleSelectAll = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const checkedEntities = holdingEntities
        if (isAllEntitiesChecked) {
            setIsAllEntitiesChecked(false)
            setHoldingEntitiesInputValue('Please choose entities')
            checkedEntities.forEach((entity) => { entity.checked = false })

        } else if (!isAllEntitiesChecked) {
            setIsAllEntitiesChecked(true);
            setHoldingEntitiesInputValue('Select all')
            checkedEntities.forEach((entity) => { entity.checked = true })
        }

        setHoldingEntities([...checkedEntities])
    }

    const handleEntitiesSelection = (e, entityName, entityChecked) => {
        e.stopPropagation();
        e.preventDefault();

        const relevantEntityIndex = holdingEntities.findIndex(entity => entity.name === entityName);
        holdingEntities[relevantEntityIndex] = { ...holdingEntities[relevantEntityIndex], checked: !entityChecked };
        setHoldingEntities(holdingEntities);
        if (holdingEntities.every(entity => entity.checked === false)) {
            setHoldingEntitiesInputValue('Please choose at least one entity');
        } else if (holdingEntities.every(entity => entity.checked === true)) {
            setIsAllEntitiesChecked(true);
            setHoldingEntitiesInputValue('Select all')
        } else {
            holdingEntitiesNamesValue();
            if (isAllEntitiesChecked) setIsAllEntitiesChecked(false)
        }
    }

    const handleDownloadSamplePfsOnClick = async () => {

        if (isMobileAppView) {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    action: 'download_file_api_request',
                    data: {
                        url: `${baseApiUrl}reports/download-sample`,
                        body: { type: 'pfs_sample' },
                        fileName: `Vyzer PFS - Demo Data.xlsx`
                    }
                }));
            }
        } else {
            await handleUrlFileDownload({
                url: `${baseApiUrl}reports/download-sample`,
                body: {
                    type: 'pfs_sample'
                },
                fileName: `Vyzer PFS - Demo Data.xlsx`
            })
        }

    }

    return (
        <>
            <FormContentWrapper>
                {
                    !isLimited &&
                    <>
                        <DataBoxInputWrapper>
                            <DataBoxInputLabel fontSize='14px'>
                                Holding entity
                            </DataBoxInputLabel>
                            <DataBoxInputElement>
                                <StyledSelect name='holdingEntity' onBlur={handleAllEntitiesDeselected}
                                    value={holdingEntitiesInputValue}
                                    suffixIcon={<ArrowDownIcon />}
                                    getPopupContainer={() => document.getElementById('reportsPage')}
                                >
                                    <Option key='all-entities' value='all-entities'>
                                        <CheckboxWrapper width={'100%'} onClick={(e) => handleSelectAll(e)}>
                                            <StyledCheckbox type="checkbox" name='select all' checked={isAllEntitiesChecked}>Select all</StyledCheckbox>
                                        </CheckboxWrapper>
                                    </Option>
                                    {holdingEntities.map((entity, index) => (
                                        <Option key={entity.name} value={index}>
                                            <CheckboxWrapper width={'100%'} onClick={(e) => handleEntitiesSelection(e, entity.name, entity.checked)}>
                                                <StyledCheckbox type="checkbox" checked={entity.checked}>{entity.name}</StyledCheckbox>
                                            </CheckboxWrapper>
                                        </Option>))
                                    }
                                </StyledSelect>
                            </DataBoxInputElement>
                        </DataBoxInputWrapper>

                        <GroupedByWrapper>
                            Group assets by:
                            <Radio.Group onChange={(e) => { setGroupedBySelection(e.target.value) }} value={groupedBySelection}>
                                <Space direction='vertical'>
                                    <Radio value='classes'>Classes</Radio>
                                    <Radio value='customClasses'>Custom classes</Radio>
                                </Space>
                            </Radio.Group>
                        </GroupedByWrapper>
                    </>
                }
            </FormContentWrapper>
            <ActionButtonsWrapper>
                {
                    isLimited ?
                        <ModifiedTooltip title={'Download sample report with demo data'}>
                            <SecondaryButtonWithIcon style={{ height: '38px' }} onClick={handleDownloadSamplePfsOnClick}>
                                <BlackDownloadSvg /> Download sample
                            </SecondaryButtonWithIcon>
                        </ModifiedTooltip>
                        :
                        <PrimaryButtonWithIcon style={{ width: '202px', height: '38px' }} onClick={handlePFSReport} isProcessing={isPFSProccesing}>
                            {isPFSProccesing ? <LoadingOutlined /> : <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}><DownloadIcon />Create & download</div>}
                        </PrimaryButtonWithIcon>
                }
            </ActionButtonsWrapper>
        </>
    )
});




const BlackDownloadSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 15.25L12 4.75" stroke="#1A1B1D" strokeWidth="1.5" />
            <path d="M15.5 11.75L12 15.25L8.5 11.75" stroke="#1A1B1D" strokeWidth="1.5" />
            <path d="M5 13.3895V18.25C5 18.8023 5.44772 19.25 6 19.25L18 19.25C18.5523 19.25 19 18.8023 19 18.25L19 13.25" stroke="#1A1B1D" strokeWidth="1.5" />
        </svg>
    )
}