import styled from '@emotion/styled/macro';
import { views } from '../../../../../common/styles/theme.styles';

export const PieWrapper = styled.div`
    position:relative;
    width:100%;
    // padding:16px 0;
`

export const PieTextContent = styled.div`
    position:absolute;
    width:100%;
    height:100%;
    padding-top:16px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    top:${props=>props.contentTop ? props.contentTop : '0px'};
    pointer-events:none;
`

export const PieTextHoverWrapper = styled.span`
    cursor: pointer;
    pointer-events: auto;
    // margin:0 48px;
     display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
`

export const PieTextLabel = styled.div`
    color:#fff;
    // width:50%;
    font-size:16px;
    line-height: 20px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; 
`

export const PieTextBigValue = styled.div`
    color:#FFFFFF;
    position:relative;
    font-size:${props=>props.isIncognito ? '22px' : (props.fontSize ? props.fontSize : '32px')};
    font-weight:  32px;
`

export const PieTrendsLink = styled.div`
    marginTop:-4px;
    color: #fff;
    visibility: hidden;
    display:flex;
    align-items:center;
    gap:4px;
    ${views.tablet}{
        visibility: visible;
    }
    ${PieTextHoverWrapper}:hover & {
        visibility: visible;
    }
`;

export const PieValueContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`
export const EventDateContainer = styled.div`
    display: flex;
    align-items: center;

    color: var(--Gray-2, #ACB1BB);
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
    cursor: pointer;

    &:hover {
        filter: brightness(0.9);
    }
`;

export const EventDateArrowLink = styled.div`
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    
`;

export const ClassIconWrapper = styled.div`
    width: 25px;
    height: 25px;   


    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        width:25px;
        height:25px;
    }
    path{
        /* stroke:#4F699D; */
        fill:${props=>props.color || '#9097A3'};
    }
`

export const ClassTitleText = styled.div`
    font-size:16px;
    color: ${props=>props.color || '#fff'};
`;
export const ClassSumText = styled.div`
    color: #9097A3;
    font-size:14px;
`;