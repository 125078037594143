import { Dropdown } from "antd";
import { useState } from "react";
import { MenuItemWrapperWealthPie, MenuWrapperWealthPie } from "../../../../../../../common/components/DropDownMenu/DropDownMenu.styles";
import { ArrowDownIcon, CheckmarkIcon } from "../../../../../../../assets/icons/common/common-icons";
import { ArrowIconWrapper } from "../../../../asset/components/Overview/OverviewTab.styles";
import { TrendsTypeMenuButton } from "./TrendsHero.style";


export const TrendsDropdown = (props) => {
    const { value, options, onChangeTrendsType, whiteTheme, showAstric } = props;
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const onChange = (value) => {
        onChangeTrendsType(value);
        setIsMenuOpen(false);
    }

    return (
        <Dropdown
            overlay={TrendsDropdownMenu({ value, options, onChange })}
            visible={isMenuOpen}
            overlayStyle={{ zIndex: 4 }}
            onVisibleChange={(visible) => setIsMenuOpen(visible)}
            trigger={['click']} placement="bottomLeft"
            getPopupContainer={() => document.getElementById('wealthPageContent')}
            disabled={options.length === 1}
        >
            <TrendsTypeMenuButton whiteTheme={whiteTheme} 
                disabled={options.length === 1}>
                {options.find(item => (item.value === value)).text}
                {showAstric && '*'}
                {options.length > 1 && <ArrowIconWrapper isOpen={isMenuOpen}>
                    <ArrowDownIcon />
                </ArrowIconWrapper>}
            </TrendsTypeMenuButton>
        </Dropdown>
    );
};

function TrendsDropdownMenu(props) {
    const { value, options, onChange } = props
    return (
        <MenuWrapperWealthPie style={{minWidth: '160px' , width: 'auto'}}>
            {options.map(item => (
                <MenuItemWrapperWealthPie key={item.value} isSelected={value === item.value} onClick={() => { onChange(item.value) }}>{item.text} {(value === item.value) && <CheckmarkIcon style={{marginLeft:'16px'}} />}</MenuItemWrapperWealthPie>
            ))}
        </MenuWrapperWealthPie>
    )
}