import moment from 'moment';
import { deleteData, getData, postData, putData } from '../../../common/utils/network.utils';

export const fetchWealthData = async (filters = {}) => {
    return (await postData('wealth', { filters }));
}

export const fetchLastChangesData = async () => {
    return (await getData('wealth/last-changes')).data;
}

export const fetchProjectionData = async () => {
    return (await getData('wealth/projection')).data;
}

export const fetchIndicationsData = async () => {
    return (await getData('cash-flow/indications')).data;
}

export const getUpdatesData = async (isOnboarding = false) => {
    return (await getData(`balance/checkUpdates`)).data;
}

export const fetchAssetData = async (id) => {
    return (await getData(`assets/${id}/page`)).data;
}

export const fetchLiabilityData = async (id) => {
    return (await getData(`liabilities/${id}/page`)).data;
}

export const fetchClassData = async (id) => {
    return (await getData(`wealth/class/${id}/asset/items/matrices`)).data;
}

export const fetchTickersData = async (classId) => {
    return (await getData(`assets/holdings?cls=${classId}`)).data;
}

export const fetchTickersDataForItems = async (ids) => {
    return (await getData(`assets/tickers-by-items-id?${ids.map(id => `itemId=${id}`).join('&')}`)).data;
}

export const fetchPpsAtDate = async (symbol, exchange, symbolCurrency, date, itemCurrency) => {
    // fox date if it is saturday or sunday ( remove the days ) 
    const relevantDate = moment(date, 'YYYY-MM-DD');
    const dayAtWeek = relevantDate.day();
    const daysToRemove = dayAtWeek === 0 ? 2 : dayAtWeek === 6 ? 1 : 0;
    const dateToSend = relevantDate.subtract(daysToRemove, 'days');
    return (await getData(`currencies/price-at-date?symbol=${symbol}&symbol_exchange=${exchange}&symbol_currency=${symbolCurrency}&date=${dateToSend.format('YYYY-MM-DD')}&item_currency=${itemCurrency}`)).data;
}

export const fetchCryptoCoinValueAtDate = async (symbol, date, currency) => {
    return (await getData(`currencies/crypto-price-at-date?symbol=${symbol}&date=${date}&currencies=${currency}`)).data;
}

export const addAsset = async (data) => {
    return (await postData("dashboard/assets", data));
}

export const addLiability = async (data) => {
    return (await postData("dashboard/liabilities", data));
}

export const updateAsset = async (id, data) => {
    return (await putData(`assets/${id}`, data));
}

export const updateAssetSettings = async (assetId, section, data) => {
    return (await putData(`assets/${assetId}/settings/${section}`, data));
}

export const updateAssetCashAccountId = async (itemId, cashAccountId) => {
    return (await postData(`assets/${itemId}/default-cash-account`, { dca: cashAccountId }));
}

export const updateLiability = async (id, data) => {
    return (await putData(`liabilities/${id}`, data));
}

export const createValueUpdate = async (assetId, valueUpdate) => {
    return (await postData(`assets/${assetId}/value-update`, valueUpdate));
}

export const updateAssetValueUpdate = async (assetId, valueUpdate) => {
    return (await putData(`assets/${assetId}/value-update/${valueUpdate.id}`, valueUpdate));
}

export const deleteAssetValueUpdate = async (assetId, valueUpdateId) => {
    return (await deleteData(`assets/${assetId}/value-update/${valueUpdateId}`));
}

export const createTransaction = async (itemId, transaction) => {
    return (await postData(`assets/${itemId}/transaction`, transaction));
}

export const updateTransaction = async (itemId, transaction) => {
    return (await putData(`assets/${itemId}/transaction/${transaction.id}`, transaction));
}

export const deleteTransaction = async (itemId, transactionId) => {
    return (await deleteData(`assets/${itemId}/transaction/${transactionId}`));
}

export const createAttributeUpdate = async (itemId, attributeUpdate) => {
    return (await postData(`assets/${itemId}/attribute-update`, attributeUpdate));
}

export const updateAttributeUpdate = async (itemId, attributeUpdate) => {
    return (await putData(`assets/${itemId}/attribute-update/${attributeUpdate.id}`, attributeUpdate));
}

export const deleteAttributeUpdate = async (itemId, attributeUpdateId, attributeKey) => {
    return (await deleteData(`assets/${itemId}/attribute-update/${attributeUpdateId}/${attributeKey}`));
}

export const deleteAssetAttributeUpdate = async (assetId, attributeUpdateId) => {
    return (await deleteData(`assets/${assetId}/attribute-update/${attributeUpdateId}`));
}

export const deleteAsset = async (itemId) => {
    return (await deleteData(`assets/${itemId}`));
}

export const closeReopenAsset = async (id, shouldClose) => {
    return (await postData(`assets/${id}/${shouldClose ? 'close' : 're-open'}`));
}

export const connectCollaterals = async (assetId, liabilityData) => {
    return (await postData(`collaterals/connect/${assetId}`, liabilityData));
}

export const disconnectLiabilityFromCollateral = async (assetId, liabilityData) => {
    return (await postData(`collaterals/disconnect/${assetId}`, liabilityData));
}

export const connectLiabilityToAsset = async (assetId, liabilityData) => {
    return (await postData(`assets/${assetId}/connectLiability`, liabilityData));
}

export const disconnectLiabilityFromAsset = async (assetId, connectionId) => {
    return (await postData(`assets/${assetId}/disconnectLiability`, { connectionId }));
}

export const updateAmortizationLoan = async (itemId, payload, isAmortization) => {
    return (await postData(`assets/update/loan-${isAmortization ? '' : 'non-'}amortization/${itemId}`, payload));
}

export const bulkEditUpdate = async (data) => {
    return (await putData(`assets/bulk-edit/`, data));
}

export const bulkDelete = async (data) => {
    return (await deleteData('assets/bulk-delete/', data));
}