import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../../common/styles/combos.styles";
import { colors, shadows, views } from "../../../../common/styles/theme.styles";
import bgImg from '../../assets/welcome-modal-bg.jpg';

export const ModalBgWrapper = styled.div`
  background: url(${bgImg}) no-repeat;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  display:flex;
  flex-direction:column;
  align-items:center;
  background-size: cover;
  background-position: center;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 56px 8px;
`;

export const ModalContent = styled.div`
  ${flexCenter};
  flex-direction: column;
  padding: 0 64px;
  /* background-color: #F7F8F9; */
  ${views.tablet}{
    overflow-y: auto;
    padding:0 24px;
    
  }
  @media (max-width: 455px) and (max-height: 600px){
      padding-top: 48px; 
  }
`;

export const ModalHeaderIconWrapper = styled.div`
  display: flex;
// width: 28px;
// height: 28px;
padding: 2px;
justify-content: center;
align-items: center;
border-radius: 360px;
// background: #000;
`

export const ModalTitle = styled.div`
  color: #fff;
  font-weight: 900;
  font-size: 28px;
  line-height: 24px;
  text-align: center;
  ${views.tablet}{
    padding: 0 24px;
  }
`;

export const ModalSubTitle = styled.div`
  color: ${colors.darkGray1};
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  ${views.tablet}{
    display: none;
  }
`;

export const ModalPromptWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  // gap: 12px;
  padding-top: 16px;
  padding-bottom: 24px;
  font-size: 14px;
  // line-height: 150%;
  // text-align: center;
  white-space: pre-line;
   ${views.tablet}{
    padding-top: 32px;
  }
`;

export const ModalPromptItem= styled.div`
    padding:24px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    :not(:last-child){
      border-bottom: 1px solid #F1F3F5;
    }
`;

export const ModalPromptItemTitle = styled.div`
   font-size: 16px;
    font-weight: 500;
    color:${colors.darkGray1};
`;

export const ModalPromptItemDescription = styled.div`
     font-size: 13px;
    line-height: 18px;
    font-weight: 450;
    color:${colors.darkGray2};
`;

export const ModalPromptRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ModalFooter = styled.div`
  ${flexCenter};
  flex-direction: column;
  padding-bottom:32px;
  ${views.tablet}{
    padding-bottom:24px;
  }
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props=>props.black ? colors.darkGray1 : '#fff'} ;
  box-shadow: ${shadows.toggleShadow};
  border-radius: 8px;
  padding:8px 56px;
  font-size:14px;
  color: ${props=> props.black ? '#fff' : '#000' };
  cursor: pointer;
  text-align:center;
  transition:all 0.2s;
  flex: 1;
  ${props => props.flexGrow ? `flex-grow: ${props.flexGrow}` : null};
  ${props => props.nowrap ? `white-space: nowrap` : null};
  svg{
    margin-left:8px;
  }
`;