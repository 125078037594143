export const appliedFiltersEvents = (appliedFilters) => {
  const FILTERS_KEYS = {
    holdingEntity: 'Holding entity',
    assetsClasses: 'Asset classes',
    institutions: 'Institution',
    ownerships: 'Ownership',
    itemStatus: 'Item Status',
    assetsValue: 'Asset value',
    startEndDate: 'Start / End date',
    currencies: 'Currency',
    leveragedAssets: 'Leveraged assets',
    containers: 'Containers',
    stocks: 'Stocks',
    riskLevel: 'Risk level',
    liquidityLevel: 'Liquidity level',
    propertyType: 'Property type',
    companySector: 'Company sector',
    capitalCommitments: 'Capital commitment',
  } 
let filters = Object.keys(appliedFilters).filter(i => !['assetsCategories', 'startDate', 'endDate'].includes(i));


if (Object.keys(appliedFilters).includes('startDate') || Object.keys(appliedFilters).includes('startDate')) {
  filters.push('startEndDate');
}

  return {
    applied_filters: filters.map(filter => FILTERS_KEYS[filter]),
    url: window.location.href
  };
}