import styled from '@emotion/styled/macro';
import { flexCenter, ScrollPanelWithShadows } from '../../../common/styles/combos.styles';
import { colors, shadows, views } from '../../../common/styles/theme.styles';

export const ModalBackDrop = styled.div`
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    bottom:0;
    display: flex;
    background-color: rgba(0, 0, 0, 0.7);
    align-items: center;
    justify-content: center;
    z-index: 120;
`;

export const ModalWrapper = styled.div`
    width: 90vw;
    max-width: 880px;
    background-color: #fff;
    // border: 1px solid gray;
    // padding:16px;
    min-height: 65vh;
    max-height: 75vh;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 0px 30px rgba(0, 0, 0, 0.02);
    transform: translateY(-32px);
`;


export const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
    height:100%;
    flex:1;
`

export const HeaderWrapper = styled.div`
    display:flex;
    justify-content: space-between;
    padding:16px;
    border-bottom: 1px solid #E7E5E5;
`;

export const HeaderTitleWrapper = styled.div`
    display:flex;
    align-items: flex-start;
`;

export const HeaderTitle = styled.div`
    margin-left:16px;
    display:flex;
    flex-direction:column;
`;

export const Title = styled.div`
    font-size:18px;
    line-height: 18px;
`;

export const SubTitleWrapper = styled.div`
    display:flex;
    flex-direction :row;
`;
export const SubTitle = styled.div`
    font-size:22px;
    font-weight:600;
    cursor:${props=>props.clickable ? 'pointer' : 'default'};
`;


export const CloseBtnWrapper = styled.div`
    cursor: pointer;
    border-right: 1px solid #9D9D9D;
    padding:0px 12px 24px 0px;
`
export const HeaderSaveWrapper = styled.div`
    
`;


export const StepWrapper = styled(ScrollPanelWithShadows)`
    display:flex;
    flex-direction:column;
    flex:1;
    padding:0 ${props=>props.isExtraLarge ? 24 : 80}px;
    overflow-y:auto;
    // padding:${props=>props.widthPadding ? '16px' : '0'};
    ${views.tablet}{
        padding:0 16px;
    }
`

export const StepWrapperOnBoarding = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    overflow-y:auto;
    padding:${props=>props.widthPadding ? '16px' : '0'};
`

export const PlaidAccountsStepWrapper = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    // padding:16px;
    align-items:center;
    overflow-y:auto;
    position:relative;
    justify-content: space-between;
`;

export const VipSyncStepWrapper = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    padding-top: 68px;
    align-items:center;
    overflow-y:auto;
    position:relative;
    justify-content: space-between;
    ${views.tablet}{
        padding-top:0px;
    }
`;

export const VipSyncIntroWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
font-size: 14px;
`;

export const VipSyncIntroText = styled.div`
text-align: center;
max-width: 500px;
padding-top: 30px;
    ${views.tablet}{
        padding:24px;
    }
`;

export const VipSyncConsentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    ${props => props.isActivationFlow ? `
        max-width: 600px;
        margin-inline: auto;
        padding: 36px 0 36px 0;
    ` : `max-width: 380px`};
    ${views.tablet}{
        max-width: 100%;
        padding: 24px 36px;            
    }
`;


export const VipSyncConsentText = styled.div`
    white-space: pre-line;
    padding-bottom: 24px;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
`;

export const ConsentCheckWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    gap: 16px;
    border: 1px solid ${colors.darkGray3};
    border-radius: 8px;
    .ant-checkbox-input, .ant-checkbox-inner {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        :focus{
            border-color:${colors.darkGray1};
        }
    }
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color:${colors.darkGray1};
    }
    .ant-checkbox-checked{
        .ant-checkbox-inner {
            background-color:${colors.darkGray1};
            border-color:${colors.darkGray1};
            ::after{
                transform: rotate(45deg) scale(1) translate(-56%, -58%);
            }
        }
        ::after {
            border-color:${colors.darkGray1};
            border-radius: 4px;

        }
    }
    :hover{
        .ant-checkbox-inner {
            border-color:${colors.darkGray1};
        }
    }
    &.ant-checkbox-wrapper-checked:hover{
        .ant-checkbox-inner {
            border-color:${colors.darkGray1};
        }
    }
`;

export const VipSyncEmailSentWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
${views.tablet}{
    padding: 24px
}
`;

export const VipSyncEmailSentTitle = styled.div`
font-weight: 500;
font-size: 15px;
line-height: 19px;
color: ${colors.darkGray1};
padding-top: 16px;
`;

export const VipSyncEmailSentToText = styled.div`
font-weight: 450;
font-size: 14px;
line-height: 18px;
color: ${colors.darkGray2};
padding-top: 8px;
`;

export const VipSyncEmailSentLookForwardText = styled.div`
font-weight: 450;
font-size: 13px;
line-height: 16px;
color: ${colors.darkGray2};
padding-top: 32px;
`;

export const VipSyncStepFooter = styled.div`
    font-weight:500;
    text-align:center;
    width:100%;
    padding: 24px;
    background-color: ${colors.lightGray1};
    box-shadow: inset 0px 1px 0px #E7E8EA;
    position: sticky;
    bottom:0px;
    z-index: 1;
    ${flexCenter};
    gap: 16px;
    ${views.tablet} {
        flex-direction: column-reverse;
        align-items: stretch;
    }
`;

export const CategoryStepWrapper = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    overflow-y:auto;
    padding: ${props=>props.isOnBoarding ? '24px 16px' : '16px 30% 64px'};
    justify-content: center;
    align-items:center;
    width:100%;
`;

export const SelectCategoryClassTitle = styled.div`
    color: #2A2D33;
    font-size:32px;
    font-weight:500;
    margin-bottom:18px;
    text-align:center;
`;

export const SelectCategoryClassSubTitle = styled.div`
    color: #2A2D33;
    font-size:18px;
    line-height:21px;
    text-align:center;
    margin-bottom:32px;
    max-width: 500px;
`;

export const CategoryCreateManuallyText = styled.div`
    color: #9d9d9d;
    font-size:16px;
    padding:8px 0px;
`;

export const ButtonsWrapper = styled.div`
    width:100%;
    max-width:456px;
    ${props=>props.isOnBoarding ? `
        background: #FAFBFC;
        border: 1px solid #DDE3EB;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        padding:24px;
    ` : ``}
`;

export const SyncOptionsButtonsWrapper = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    width: 300px;
`;

export const WizardButton = styled.div`
    // border : 1px solid #222222;
    color: ${props=> props.black ? '#fff' : '#000'};
    background-color: ${props=> props.black ? '#222222' : '#fff'};
    font-size: 18px;
    padding: 8px 16px;
    text-align:center;
    cursor: pointer;
    // &:not(:last-child){
        // margin: ${props => props.margin || '0px 0px 8px 0px'};
        margin: ${props => props.margin || '0px'};
    //}
    box-shadow:${shadows.toggleShadow};
    // min-width: 144px;
    transition:all 0.2s;
    border-radius:4px;
    &:hover{
        background-color: ${props=> props.black ? '#000' : '#f1f4f8'};
        // border : 1px solid ${props=> props.black ? '#000' : '#222222'};
    }
    display: ${props=> props.inline ? 'inline-block' : props.flex ? `flex` : 'block'};
    ${props=>props.flex ? `align-items:center` : ``}
`;

export const ContinueButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    svg{
        height: 14px;
        path{
            stroke: #FFFFFF;
            fill: #FFFFFF;
        }
    }
`;

export const RoundedWizardButton = styled.div`
    // border : 1px solid #222222;
    color: ${props=> props.black ? '#fff' : colors.darkGray1};
    background-color: ${props=> props.black ? colors.darkGray1 : '#fff'};
    font-size: 14px;
    font-weight:500px;
    line-height:16px;
    padding: 8px 16px;
    text-align:center;
    cursor: pointer;
    margin: ${props => props.margin || '0px'};
    box-shadow:${shadows.buttonShadow};
    // min-width: 144px;
    transition:all 0.2s;
    border-radius:8px;
    &:hover{
        background-color: ${props=> props.black ? '#000' : '#f1f4f8'};
        // border : 1px solid ${props=> props.black ? '#000' : '#222222'};
    }
    display: ${props=> props.inline ? 'inline-block' : 'block'};
`;

export const PlaidAccountsStepTitle = styled.div`
    font-size:16px;
    font-weight:500;
    text-align:center;
    width:100%;
    padding: 24px;
    // background-color: #f7f8f9;
    position: sticky;
    top:0px;
    z-index: 1;
    pointer-events: none;
    background-color:#fff;
`;

export const AccountsListHeaderRow = styled.div`
    display: flex;
    width: 100%;
    margin-top: 16px;
`;

export const AccountsListHeaderColumn = styled.div`
    font-weight: 450;
    font-size: 13px;
    line-height: 16px;
    color: ${colors.darkGray3};
    :first-of-type {
        flex: 1;
        padding-left: 4px;
    }
    ${props=>props.width ? `width: ${props.width}` : ``};
`;

export const PlaidAccountsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width:100%;
    margin-top:8px;
    // box-shadow:${shadows.menuPopup};
    // border: 1px solid ${colors.gray3};
    ${props => props.isActivationFlow ? `` : `background-color: #fff`};
    border-radius:8px;
`;

export const ResultsAndBannerWrapper = styled.div`
    display: flex;
    width: 100%;
    ${props=>props.hasWidthLimit ? `max-width: 752px` : ``};
    align-items: center;
    flex-direction: column ;
    // gap: 40px ;
    padding-bottom:8px;
    flex:1;
    /* max-width:530px; */
    /* width:100%; */
    /* padding-top:64px; */
    /* padding-bottom:24px; */
`;

export const ConnectAllAccountsTipWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 474px;
    gap: 8px;
    padding: 12px 16px;
    background: #FEF8EF;
    box-shadow: ${shadows.buttonShadow};
    border-radius: 8px;
`;

export const ConnectAllAccountsTipText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${colors.darkGray1};
`;

// export const InstitutionsResultsListWrapper = styled.div`
//     // min-height:300px;
//     max-height:300px;
//     overflow-y:auto;  
// `;

export const AccountRowWrapper = styled.div`
    display:flex;
    flex:1;
    align-items: center;
    // padding: 16px 140px 16px 144px; // 16px 4px 16px 0px;
    padding:16px 0px;
    border-radius: 8px;
    background-color: ${props => props.isActivationFlow ? `#fff` : `#F9FAFC`};
    ${props => props.disabled ? `opacity: 0.5` : ``}
    ${views.tablet}{
        flex-wrap: wrap;
    }
`;

export const AccountTitleAndCategory = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    margin-left: 8px;
    .ant-input-affix-wrapper{
        border: none;
        max-width:360px;
        background-color: transparent;
        .ant-input{
            font-size: 14px;
            font-weight: bold;
            color:${colors.darkGray1};
            background-color: transparent;
        }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border-radius:8px;
    }
    ${props=>props.isIgnored ? `opacity: 0.6` : ``};
`;

export const AddedAccountName = styled.div`
    font-size:14px;
    font-weight:500;
    color:${colors.darkGray1};
`;

export const AccountCategorySelector = styled.div`
    display:flex;
    flex-direction:row-reverse;
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
        background:none;
    }
`;

export const AccountCheckboxWrapper = styled.div`
    margin-left:16px;
    .ant-checkbox-wrapper{
        width:16px;
        height:16px;
        border-radius:4px;
        /* transform:scale(1.5) */
    }
    .ant-checkbox .ant-checkbox-inner{
        border-color:#2A2D33;
        /* width: 26px;
        height: 26px; */
    }  
    .ant-checkbox-checked .ant-checkbox-inner{
        background-color: #2A2D33;
    }  
`;

export const AccountLogoWrapper = styled.div`
    margin-left: 20px;
    ${props=>props.isIgnored ? `opacity: 0.6` : ``};
`;
export const AccountBalanceAndActions = styled.div`
    display:flex;
    flex-direction:row;
    align-items:flex-end;
    justify-content: flex-end;
    padding-right: 40px;
    ${props=>props.isIgnored ? `opacity: 0.6` : ``};
    ${views.tablet}{
        padding-right: 24px;
    }
`;

export const AccountBalance = styled.div`
    display: flex;
    font-size: 14px;
    line-height: 18px;
    ${props=>props.isPositive ? `
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
        color: #20CD7B;
    ` : ``};
`;

export const PlaidAccountsFooter = styled.div`
    font-weight:500;
    text-align:center;
    width:100%;
    padding: 24px;
    // background-color: #f7f8f9;
    background-color: #fff;
    box-shadow: inset 0px 1px 0px #E7E8EA;
    position: sticky;
    bottom:0px;
    z-index: 1;
    ${flexCenter};
    justify-content:flex-end;
`;

export const HoldingsInputWrapper = styled.div`
    /* padding: 4px 8px 4px 12px; */
    margin-left: 8px;
    position: relative;
    /* border-radius:8px; */

    .ant-input{
        border: 1px solid ${colors.gray3};
        font-size: 14px;
        line-height: 17px;
        padding: ${props=>props.isFocused ? '3px 0 3px 8px' : '3px 0 3px 80px'};
        /* padding: 3px 0 3px 80px; */
        position: relative;
        border-radius:8px;
        width: 132px;
        :focus{
            border: 1px solid ${colors.darkGray1};
            box-shadow: none;
        }
    }
`;

export const OwnershipTextWrapper = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    color: ${props=>props.disabled ? colors.gray3 : null};
    pointer-events: none;
`;

export const FormStepFooter = styled.div`
    width:100%;
    padding:24px;
    border-top: 1px solid #e3e3e3;
    justify-content:space-between;
    display:flex;
    align-items:center;
`;

export const FormFooterButtonsWrapper = styled.div`
    width:150px;
    text-align:${props=>props.isNext? 'right': 'left'};
    ${views.tablet}{
        width:70px;
    }
`;

export const FormFooterStepsWrapper = styled.div`
    display:flex;
    align-items:center;
`;

export const FormFooterStepPoint = styled.div`
    width:10px;
    height:10px;
    display:block;
    margin:8px;
    border-radius:50%;
    background-color:${props=>props.isSelected ? '#000000' : '#C4C4C4'};
`;


export const RealEstateButton = styled.div`
    // border : 1px solid #222222;
    color: ${props=> props.black ? '#fff' : '#000'};
    background-color: ${props=> props.black ? '#222222' : '#fff'};
    font-size: 18px;
    padding: 8px 16px;
    // text-align:center;
    display:flex;
    flex-direction:column;
    cursor: pointer;
    box-shadow:${shadows.toggleShadow};
    &:not(:last-child){
        margin-bottom:8px;    
    }
    // min-width: 144px;
    transition:all 0.2s;
    border-radius:4px;
    &:hover{
        background-color: ${props=> props.black ? '#000' : '#f1f4f8'};
        // border : 1px solid ${props=> props.black ? '#000' : '#222222'};;
    }
`;

export const RealEstateButtonTitle = styled.div`
    font-size: 18px;
`;
export const CategoryButtonDescription = styled.div`
    font-size: 14px;
    color: ${colors.darkGray2};
`;
export const RealEstateInnerTitle = styled.div`
    font-size:18px;
    cursor: pointer;
    margin-right:24px;
`;

export const RealEstateInnerTitleWrapper = styled.div`
   /* display:flex;
   align-items:flex-end; */
   margin-bottom:8px;
`;

export const AddItemChooseMethodStepWrapper = styled.div`
    display:flex;
    flex-direction: column;
    gap: 8px;
    /* flex:1; */
    ${views.desktop}{
        padding: 32px 160px 72px;
    }
    ${views.tablet}{
        padding: 0px;
        gap:0px;
    }
    
`;

export const MenuItemWrapper = styled.div`
    padding:8px 0;
    ${flexCenter};
    color:${colors.darkGray1};
    border-top: 1px solid ${colors.gray3};
    /* ${props => !props.disabled ? `
    :hover{
        background-color: ${colors.lightGray1};
    }
    cursor: pointer;
    ` : null} */
`;

export const MethodWrapper = styled.div`
    box-shadow: 0px 1px 4px rgb(0 0 0 / 8%), 0px 8px 16px rgb(0 0 0 / 4%);
    padding: 8px 16px 8px 8px;
    border-radius: 8px;
    background-color: #FDFDFD;
`;

export const AccountsSelectionMissingErrorMessage = styled.div`
    color: red;
    font-size: 14px;
    margin-top: auto;
    align-self: flex-start;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    ${views.tablet} {
        margin-left: 20px;
    }
`;

export const VipAddRequeSyncStepWrapper = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    padding-top: 68px;
    align-items:center;
    overflow-y:auto;
    position:relative;
    justify-content: space-between;
    ${views.tablet}{
        padding-top:0px;
    }
`;


export const RequestVipSyncStepWrapper = styled.div`
    // display:flex;
    // flex-direction:column;
    // flex:1;
    // padding-top: 32px;
    // align-items:center;
    // overflow-y:auto;
    // max-width: 400px;
    // position:relative;
    // // justify-content: space-between;
    // // ${views.tablet}{
    // //     padding-top:0px;
    // // }

    display:flex;
    flex-direction:column;
    flex:1;
    // padding-top: 16px;
    align-items:center;
    overflow-y:auto;
    position:relative;
    justify-content: space-between;
    ${views.tablet}{
        padding-top:0px;
    }
`;

export const RequestVipSyncStepContent = styled.div`
    max-width: 464px;
    width:100%;
    padding:24px 16px;
    display:flex;
    flex-direction:column;
    // align-items: flex-start;
    .fd-sectionWrapper{
        padding:0px;
    }
    .ant-input{
        border-radius: 8px;
        height: 48px!important;
    }
    .contact-person-name-input .ant-input{
        border-radius: 8px 8px 0px 0px;
    }
    .contact-person-email-input .ant-input{
        border-radius: 0px 0px 8px 8px;
    }
`;

export const RequestVipSyncStepFooter = styled.div`
    padding:16px;
    font-weight:500;
    text-align:center;
    width:100%;
    padding: 16px 24px;
    background-color: ${colors.lightGray1};
    box-shadow: inset 0px 1px 0px #E7E8EA;
    position: sticky;
    bottom:0px;
    z-index: 1;
    ${flexCenter};
    gap: 16px;
    justify-content: flex-end;
    ${views.tablet} {
        flex-direction: column-reverse;
        align-items: stretch;
    }
`;

export const RequestVipSyncHeaderWrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    padding-top: 32px;
    max-width: 370px;
    ${props=>props.isAfterSent ? `
        flex: 1;
        justify-content: center;    
        padding-top: 0;
    ` : ``}
`;

export const RequestVipSyncHeaderIcon = styled.div`
        ${flexCenter};
        width:40px;
        height: 40px;
        border-radius: 50%;
        background-color: #DEB482;
        margin-bottom: 8px;
`;

export const RequestVipSyncHeaderTitle = styled.div`
        color:${colors.darkGray1};
        font-size: 22px;
        font-weight: 400;
        font-family: 'Domine';
`;

export const RequestVipSyncHeaderSubTitle = styled.div`
        color:#1A1B1D;
        text-align: center;
        font-size: 14px;
        font-weight: 450;
`;