import styled from '@emotion/styled/macro'
import { views } from '../../../../common/styles/theme.styles';

export const NotificationsContainerWrapper = styled.div`
    .app-notifications-wrapper{
        margin-left:130px;
        ${views.tablet} {
            margin-left:0;
        }
    }

    .app-notifications-wrapper-login-layout{
        margin-left:-20vw;
    }
    
    
    .app-notification{
        padding: 0 8px;
        min-height:auto;
    }

`;
export const MessageWrapper = styled.div`
  display:flex;
  align-items:center;
  
`

export const IconWrapper = styled.div`
    display:flex;
    // flex:1;
    align-items:center;
    svg{
      margin-right:12px;
      path{
          fill:#fff;
      }
    }
`;

export const UndoWrapper = styled.div`
    font-size:14px;
    padding-right: 8px;
    border-right: 1px solid #fff;
    margin-right: 8px;
    span{cursor: pointer; text-decoration: underline; }
`;

export const TextWrapper = styled.div`
    font-size:14px;
`;

