import moment from "moment";
import { errorNotification } from "../../layout/components/Notifications/notifications";
import { useEffect, useState } from "react";
import { ArrowDownIcon, DownloadIcon } from "../../../assets/icons/common/common-icons";
import { PrimaryButtonWithIcon } from "../../../common/components/buttons/NewButtons.styles";
import { ActionButtonsWrapper, CheckboxWrapper, DataBoxInputElement, DataBoxInputLabel, DataBoxInputWrapper, FormContentWrapper, StyledCheckbox } from "./Reports.styles";
import { handleUrlFileDownload, getData, postData } from "../../../common/utils/network.utils";
import { LoadingOutlined } from '@ant-design/icons';
import { StyledSelect } from "../../user/components/Profile.styles";
import { Select } from "antd";
import { useStore } from "../../app/data/root.store";
import { observer } from "mobx-react";
import { commitedReportGraphImage } from "./CommitmentReportGraph";
import { Popup } from "../../../common/components/Popup/Popup";

export const CommitmentReportDataBoxContent = observer(({ isMobileAppView }) => {

    const { metadataStore } = useStore();
    const [isCommitedCapitalsReportProccesing, setIsCommitedCapitalsReportProccesing] = useState(false);
    const [holdingEntities, setHoldingEntities] = useState([]);
    const [holdingEntitiesInputValue, setHoldingEntitiesInputValue] = useState('Select all');
    const [isAllEntitiesChecked, setIsAllEntitiesChecked] = useState(true);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (metadataStore.isLoaded) {
            setHoldingEntities(metadataStore.beneficiaries.map(entity => ({ ...entity, checked: true })));
        }

    }, [metadataStore.isLoaded, metadataStore.beneficiaries]);

    const { Option } = Select;

    const FILE_DATE_FORMAT = 'YYYY-M-D';

    const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
    const handleCommitmentReport = async () => {
        if (isCommitedCapitalsReportProccesing) return;
        const holdingEntitiesNames = holdingEntitiesInputValue === 'Select all' ? [] :
        holdingEntities.filter(entity => entity.checked).map(entity => entity.name);

        const query = holdingEntitiesNames.map(item => `holdingEntities=${encodeURIComponent(item)}`).join('&');
        const graphData = await getData(`reports/commitment_graph${query ? `?${query}` : ''}`);
        if(graphData.data.length === 0) {
            setShowPopup(true);
            return;
        }
        
        setIsCommitedCapitalsReportProccesing(true);
        const image = await commitedReportGraphImage(graphData.data);

        await postData(`reports/commitment-image`, { image });

        if (isMobileAppView){
            if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        action: 'download_file_api_request',
                        data: {
                            url: `${baseApiUrl}reports/commitment`,
                            body: { 
                                clientDate: new Date().toISOString(),
                                holdingEntities: holdingEntitiesNames,
                            },
                            fileName: `commitment-report-${moment().format(FILE_DATE_FORMAT)}.pdf`
                        }
                    }));

            } 
        } else {
            try {
                await handleUrlFileDownload({
                    url: `${baseApiUrl}reports/commitment`,
                    body: {
                        clientDate: new Date().toISOString(),
                        holdingEntities: holdingEntitiesNames,
                    },
                    fileName: `commitment-report-${moment(new Date()).format(FILE_DATE_FORMAT)}.pdf`
                })
            } catch (e) {
                errorNotification(e.message);
            }
        }

        setIsCommitedCapitalsReportProccesing(false);
    }

    const holdingEntitiesNamesValue = () => {
        const checkedEntities = holdingEntities.filter(entity => entity.checked === true)
        const names = checkedEntities.map(entity => entity.name)
        setHoldingEntitiesInputValue(names.join(', '))
    }

    const handleAllEntitiesDeselected = (e) => {
        if (holdingEntities.every(entity => entity.checked === false)) {
            handleSelectAll(e);
        }
    }

    const handleSelectAll = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const checkedEntities = holdingEntities
        if (isAllEntitiesChecked) {
            setIsAllEntitiesChecked(false)
            setHoldingEntitiesInputValue('Please choose entities')
            checkedEntities.forEach((entity) => { entity.checked = false })

        } else if (!isAllEntitiesChecked) {
            setIsAllEntitiesChecked(true);
            setHoldingEntitiesInputValue('Select all')
            checkedEntities.forEach((entity) => { entity.checked = true })
        }

        setHoldingEntities([...checkedEntities])
    }
    const handleEntitiesSelection = (e, entityName, entityChecked) => {
        e.stopPropagation();
        e.preventDefault();

        const relevantEntityIndex = holdingEntities.findIndex(entity => entity.name === entityName);
        holdingEntities[relevantEntityIndex] = { ...holdingEntities[relevantEntityIndex], checked: !entityChecked };
        setHoldingEntities(holdingEntities);
        if (holdingEntities.every(entity => entity.checked === false)) {
            setHoldingEntitiesInputValue('Please choose at least one entity');
        } else if (holdingEntities.every(entity => entity.checked === true)) {
            setIsAllEntitiesChecked(true);
            setHoldingEntitiesInputValue('Select all')
        } else {
            holdingEntitiesNamesValue();
            if (isAllEntitiesChecked) setIsAllEntitiesChecked(false)
        }
    }

    return (
        showPopup ? 
        <Popup 
            onClose={() => setShowPopup(false)}
            title='No Commitments Available'
            messageLines={[
                `It seems you haven't created any capital call schedules yet.`,
                ``,
                `💡 Tip: Create a capital call schedule for your funds or real estate syndication categories to track your commitments effectively.`
            ]}
            buttons={[
                { type: 'primary', text: 'Got it', onClick: () => setShowPopup(false) },
            ]}
        /> :
        <>
            <FormContentWrapper>
                <DataBoxInputWrapper>
                    <DataBoxInputLabel fontSize='14px'>
                        Holding entity
                    </DataBoxInputLabel>
                    <DataBoxInputElement>
                        <StyledSelect name='holdingEntity' onBlur={handleAllEntitiesDeselected}
                            value={holdingEntitiesInputValue}
                            suffixIcon={<ArrowDownIcon />}
                            getPopupContainer={() => document.getElementById('reportsPage')}
                        >
                            <Option key='all-entities' value='all-entities'>
                                <CheckboxWrapper width={'100%'} onClick={(e) => handleSelectAll(e)}>
                                    <StyledCheckbox type="checkbox" name='select all' checked={isAllEntitiesChecked}>Select all</StyledCheckbox>
                                </CheckboxWrapper>
                            </Option>
                            {holdingEntities.map((entity, index) => (
                                <Option key={entity.name} value={index}>
                                    <CheckboxWrapper width={'100%'} onClick={(e) => handleEntitiesSelection(e, entity.name, entity.checked)}>
                                        <StyledCheckbox type="checkbox" checked={entity.checked}>{entity.name}</StyledCheckbox>
                                    </CheckboxWrapper>
                                </Option>))
                            }
                        </StyledSelect>
                    </DataBoxInputElement>
                </DataBoxInputWrapper>
            </FormContentWrapper>
            <ActionButtonsWrapper>
                <PrimaryButtonWithIcon style={{ width: '202px', height: '38px' }} onClick={handleCommitmentReport} isProcessing={isCommitedCapitalsReportProccesing}>
                    {isCommitedCapitalsReportProccesing ? <LoadingOutlined /> : <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}><DownloadIcon />Create & download</div>}
                </PrimaryButtonWithIcon>
            </ActionButtonsWrapper>
        </>
    )
});