import { LoadingOutlined } from '@ant-design/icons';
import { Radio, Select, Space, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ArrowDownIcon, DownloadIcon, MobileLeftCornerMask, MobileRightCornerMask, TooltipIcon } from '../../../assets/icons/common/common-icons';
import { LimitationModal } from '../../../common/components/LimitationModal/LimitationModal';
import { PrimaryButtonWithIcon } from '../../../common/components/buttons/NewButtons.styles';
import { startIntercomNewMessage } from '../../../common/utils/intercom.utils';
import { handleUrlFileDownload } from '../../../common/utils/network.utils';
import { isNullOrUndefinedOrEmptyString } from '../../../common/utils/object.utils';
import { ActivationChecklistPane } from '../../activation/components/checklist-pane/ChecklistPane';
import { useStore } from '../../app/data/root.store';
import { AppHeader } from '../../layout/components/Header/Header';
import { PageWrapper, RightSidePushMenuWrapper } from '../../layout/components/Layout.styles';
import { errorNotification } from '../../layout/components/Notifications/notifications';
import { DataBoxSubTitle, DataBoxTitle, StyledDatePicker, StyledSelect } from '../../user/components/Profile.styles';
import { ReactComponent as CustomReportIcon } from '../assets/custom-report-icon.svg';
import { ReactComponent as PDFicon } from '../assets/pdf-icon.svg';
import { ReactComponent as XLSicon } from '../assets/xls-icon.svg';
import { ActionButtonsWrapper, CheckboxWrapper, DataBox, DataBoxHeader, DataBoxHeaderWrapper, DataBoxInputElement, DataBoxInputLabel, DataBoxInputWrapper, FormContentWrapper, GroupedByWrapper, HeaderTitle, MobileHeaderWrapper, ReportsContentWrapper, ReportsMobileHeader, StyledCheckbox } from './Reports.styles';
import { ReportsDataBox } from './ReportsDataBox';
import { ReportsEmptyHero } from './ReportsEmptyHero';
import { AppLoader } from '../../../common/components/Loaders/AppLoader';
import { CommitmentReportDataBoxContent } from './CommitmentReportDataBoxContent';
import { PFSDataBoxContent } from './PFSDataBoxContent';

// const fileTypesOptions = [{ label: 'PDF file', value:'pdf'  }, { label: 'CSV file', value: 'csv'  }, { label: 'Excel (xlsx)', value:'xls' }];
const FILE_DATE_FORMAT = 'YYYY-M-D';

export const ReportsPage = observer((props) => {
  const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
  const { Option } = Select;
  const { reportsStore, metadataStore, userStore, billingStore, uiStore } = useStore();
  const [holdingEntities, setHoldingEntities] = useState([]);
  const [isAllEntitiesChecked, setIsAllEntitiesChecked] = useState(true);
  const [holdingEntitiesInputValue, setHoldingEntitiesInputValue] = useState('All entities');
  const [reportOpenByDeepLink, setReportOpenByDeepLink] = useState('');
  const [processingReport, setProcessingReport] = useState(false);
  const [reportDate, setReportDate] = useState(moment());
  const [groupedBySelection, setGroupedBySelection] = useState('');
  // eslint-disable-next-line
  const [isGeneralReportProcessing, setIsGeneralReportProcessing] = useState(false);
  const selectedFileType = 'pdf';

  const reportsData = {
    'pfs': {
      title: 'Personal Financial Statement (PFS)',
      subtitle: userStore.isFreeTier || userStore.isDemoOrReadOnlyMode ? 'Upgrade to request a PFS' : 'Request a PFS document.',
      isLimited: userStore.isDemoOrReadOnlyMode || !userStore.userLimitations.reporting,
      headerSvg: <XLSicon />,
      alwaysOpen: userStore.isDemoOrReadOnlyMode || userStore.isFreeTier,
      defaultOpen: reportOpenByDeepLink === 'pfs',
    },
    'balanceSheet': {
      title: 'Balance sheet',
      subtitle: 'Total assets / liabilities and net worth report',
      isLimited: userStore.isDemoOrReadOnlyMode || !userStore.userLimitations.reporting,
      headerSvg: <PDFicon />,
      alwaysOpen: false,
      defaultOpen: false, // reportOpenByDeepLink === 'balanceSheet', prep for air-condition
    },
    'generalReport': {
      title: 'General report',
      subtitle: 'Export a general summary of all of your assets.',
      isLimited: userStore.isDemoOrReadOnlyMode || !userStore.userLimitations.reporting,
      headerSvg: <XLSicon />,
      alwaysOpen: false,
      defaultOpen: false, // reportOpenByDeepLink === 'generalReport', prep for air-condition
    },
    'commitmentCapitals': {
      title: 'Commitments report',
      subtitle: 'Export a summary for a commitment',
      isLimited: userStore.isDemoOrReadOnlyMode || !userStore.userLimitations.reporting,
      headerSvg: <PDFicon />,
      alwaysOpen: false,
      defaultOpen: false
    },
    'customReports': {
      title: 'Custom reports',
      subtitle: <>
        Request comprehensive reports to see detailed allocations, cash flow and more.
        {!userStore.isTier4 && <> <br /> This service is only available on Elite plan.</>}
      </>,
      //`Request comprehensive reports to see detailed allocations, cash flow and more. ${!userStore.isTier4 ? <br/> + 'This service is only available on Elite plan.' : ''}`,
      isLimited: userStore.isDemoOrReadOnlyMode || !userStore.userLimitations.customReporting,
      headerSvg: <CustomReportIcon />,
      alwaysOpen: false,
      defaultOpen: false, // reportOpenByDeepLink === 'customReports', prep for air-condition
    },
  }

  useEffect(() => {
    const reportNameParam = new URLSearchParams(window.location.search).get('rt');
    const reportName = !isNullOrUndefinedOrEmptyString(reportNameParam) ? reportNameParam : '';
    setReportOpenByDeepLink(reportName);
    const cleanURL = window.location.pathname;
    window.history.replaceState({}, '', cleanURL);
    return () => { }
  }, [])

  useEffect(() => {
    if (metadataStore.isLoaded) {
      setHoldingEntities(metadataStore.beneficiaries.map(entity => ({ ...entity, checked: true })));
    }
    //   return () => {}
  }, [metadataStore.isLoaded, metadataStore.beneficiaries])

  const handleEntitiesSelection = (e, entityName, entityChecked) => {
    e.stopPropagation();
    e.preventDefault();

    const relevantEntityIndex = holdingEntities.findIndex(entity => entity.name === entityName);
    holdingEntities[relevantEntityIndex] = { ...holdingEntities[relevantEntityIndex], checked: !entityChecked };
    setHoldingEntities(holdingEntities);
    if (holdingEntities.every(entity => entity.checked === false)) {
      setHoldingEntitiesInputValue('Please choose at least one entity');
    } else if (holdingEntities.every(entity => entity.checked === true)) {
      setIsAllEntitiesChecked(true);
      setHoldingEntitiesInputValue('All entities')
    } else {
      holdingEntitiesNamesValue();
      if (isAllEntitiesChecked) setIsAllEntitiesChecked(false)
    }
  }

  const handleSelectAll = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const checkedEntities = holdingEntities
    if (isAllEntitiesChecked) {
      setIsAllEntitiesChecked(false)
      setHoldingEntitiesInputValue('Please choose entities')
      checkedEntities.forEach((entity) => { entity.checked = false })

    } else if (!isAllEntitiesChecked) {
      setIsAllEntitiesChecked(true);
      setHoldingEntitiesInputValue('All entities')
      checkedEntities.forEach((entity) => { entity.checked = true })
    }

    setHoldingEntities([...checkedEntities])
  }

  const holdingEntitiesNamesValue = () => {
    const checkedEntities = holdingEntities.filter(entity => entity.checked === true)
    const names = checkedEntities.map(entity => entity.name)
    setHoldingEntitiesInputValue(names.join(', '))
  }

  const handleAllEntitiesDeselected = (e) => {
    if (holdingEntities.every(entity => entity.checked === false)) {
      handleSelectAll(e);
    }
  }

  const handleExportDataParams = async () => {
    if (uiStore.isMobileAppView) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          action: 'download_file_api_request',
          data: {
            url: `${baseApiUrl}reports/balance`,
            body: {
              reportDate: moment(reportDate).set('D', 15).toISOString(),
              holdingEntities: holdingEntitiesInputValue === 'All entities' ? [] :
                holdingEntities.filter(entity => entity.checked).map(entity => entity.name),
              groupBy: groupedBySelection
            },
            fileName: `balance-sheet-${moment(reportDate).format(FILE_DATE_FORMAT)}.pdf`
          }
        }));
      }
    } else {
      try {
        if (processingReport) return;
        if (!reportDate || !moment().isValid(reportDate)) throw Error('Please select a valid date');
        setProcessingReport(true)
        switch (selectedFileType) {
          case 'pdf':
            await handleUrlFileDownload({
              url: `${baseApiUrl}reports/balance`,
              body: {
                reportDate: moment(reportDate).set('D', 15).toISOString(),
                holdingEntities: holdingEntitiesInputValue === 'All entities' ? [] :
                  holdingEntities.filter(entity => entity.checked).map(entity => entity.name),
                groupBy: groupedBySelection
              },
              fileName: `balance-sheet-${moment(reportDate).format(FILE_DATE_FORMAT)}.pdf`
            })
            setProcessingReport(false)
            break;
          case 'xls':
            // handleDownload({
            //     url: `${baseApiUrl}reports/data-export`,
            //     body: {dataExportType: "default"},
            //     fileName: `balance_sheet_${moment().format('L')}.xlsx`
            // })
            // throw new Error('Not supported')
            break;
          case 'csv':
            // throw new Error('Not supported')
            break;
          default:
            throw new Error('Unknown file requested')
        }
      } catch (e) {
        errorNotification(e.message)
        setProcessingReport(false)
      }
    }
  }

  const handleGeneralReport = async () => {
    if (isGeneralReportProcessing) return;
    if (uiStore.isMobileAppView) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          action: 'download_file_api_request',
          data: {
            url: `${baseApiUrl}reports/data-export`,
            body: { dataExportType: "general" },
            fileName: `vyzer-general-report-${moment().format(FILE_DATE_FORMAT)}.xlsx`
          }
        }));
      }
    } else {
      setIsGeneralReportProcessing(true);
      try {
        await handleUrlFileDownload({
          url: `${baseApiUrl}reports/data-export`,
          body: { dataExportType: "general" },
          fileName: `vyzer-general-report-${moment().format(FILE_DATE_FORMAT)}.xlsx`
        })
      } catch (e) {
        errorNotification(e.message);
      } finally {
        setIsGeneralReportProcessing(false);
      }
    }
  }

  const disabledDate = (current) => {
    return current && moment(current).isAfter(Date.now());
  }

  const handleFormat = (value) => {
    return moment(value).format('MMMM YYYY')
  }

  const handleUpgradeTierClick = async () => {
    // const paymentLink = await billingStore.fetchBillingUrl()
    billingStore.setShowPricingTableModal(true);
  }

  const CustomReportOnClick = () => {
    startIntercomNewMessage();
    // startIntercomNewMessage(`Hello Vyzer Team,\n\nI am interested in obtaining a custom report tailored to my specific needs.\n\nCould you please assist me in setting this up? I would appreciate any guidance on the next steps in this process.`);
  }

  return (
    <>
      {uiStore.isDesktopView ? <AppHeader /> :
        <ReportsMobileHeader isHeaderFixed={uiStore.isMobileHeaderFixed}>
          <HeaderTitle>Reports</HeaderTitle>
        </ReportsMobileHeader>}
      <RightSidePushMenuWrapper>
        {userStore.isViewingOwnPortfolio && userStore.isGetStarted && <ActivationChecklistPane />}
      </RightSidePushMenuWrapper>
      {!metadataStore.isLoaded || reportsStore.isLoading ? <AppLoader backdrop /> :
        <><PageWrapper withHeader id='reportsPage' >
          <ReportsContentWrapper>
            <ReportsEmptyHero />

            <>
              {(userStore.isFreeTier || metadataStore.totalItemsCount > 0) &&
                <>
                  {!uiStore.isDesktopView && <MobileHeaderWrapper>
                    Select report
                    <MobileLeftCornerMask style={{ position: 'absolute', top: '0', left: '0' }} />
                    <MobileRightCornerMask style={{ position: 'absolute', top: '0', right: '0' }} />
                  </MobileHeaderWrapper>}
                  <DataBox>
                    {uiStore.isDesktopView &&
                      <DataBoxHeaderWrapper>
                        <DataBoxHeader>
                          <DataBoxTitle>Reports</DataBoxTitle>
                          <DataBoxSubTitle>Generate and export financial statements</DataBoxSubTitle>
                        </DataBoxHeader>
                      </DataBoxHeaderWrapper>}

                    <ReportsDataBox {...reportsData.pfs} >
                      <PFSDataBoxContent isMobileAppView={uiStore.isMobileAppView} isLimited={reportsData.pfs.isLimited}/>
                    </ReportsDataBox>

                    <ReportsDataBox {...reportsData.balanceSheet} >
                      <>
                        <FormContentWrapper>
                          <DataBoxInputWrapper>
                            <DataBoxInputLabel withIcon>
                              Report date
                              <Tooltip title={'Generate report from a specific date in order to get a snap-shot of your wealth at date.'} placement="top"><TooltipIcon /></Tooltip>
                            </DataBoxInputLabel>
                            <DataBoxInputElement>
                              <StyledDatePicker
                                value={reportDate}
                                name='reportDate'
                                picker='month'
                                onChange={(value) => { setReportDate(value || moment(new Date().toISOString())) }}
                                disabledDate={disabledDate}
                                format={handleFormat} />
                              {/* name='reportDate' onChange={(val) => { handleFieldChange('reportDate', val) }} /> */}
                            </DataBoxInputElement>
                          </DataBoxInputWrapper>
                          <DataBoxInputWrapper>
                            <DataBoxInputLabel>
                              Holding entity
                            </DataBoxInputLabel>
                            <DataBoxInputElement>
                              <StyledSelect name='holdingEntity' onBlur={handleAllEntitiesDeselected}
                                value={holdingEntitiesInputValue}
                                suffixIcon={<ArrowDownIcon />}
                                getPopupContainer={() => document.getElementById('reportsPage')}
                              >
                                <Option key='all-entities' value='all-entities'>
                                  <CheckboxWrapper width={'100%'} onClick={(e) => handleSelectAll(e)}>
                                    <StyledCheckbox type="checkbox" name='all entities' checked={isAllEntitiesChecked}>All entities</StyledCheckbox>
                                  </CheckboxWrapper>
                                </Option>
                                {holdingEntities.map((entity, index) => (
                                  <Option key={entity.name} value={index}>
                                    <CheckboxWrapper width={'100%'} onClick={(e) => handleEntitiesSelection(e, entity.name, entity.checked)}>
                                      <StyledCheckbox type="checkbox" checked={entity.checked}>{entity.name}</StyledCheckbox>
                                    </CheckboxWrapper>
                                  </Option>))
                                }
                              </StyledSelect>
                            </DataBoxInputElement>
                          </DataBoxInputWrapper>
                          <CheckboxWrapper>
                            {/* <StyledCheckbox type="checkbox" name='inputKey' onClick={() => setIsGroupAssetsByClasses(!isGroupAssetsByClassesChecked)} checked={isGroupAssetsByClassesChecked}> */}
                            <GroupedByWrapper>
                              Group assets by:
                              <Radio.Group onChange={(e) => { setGroupedBySelection(e.target.value) }} value={groupedBySelection}>
                                <Space direction='vertical'>
                                  <Radio value='classes'>Classes</Radio>
                                  <Radio value='customClasses'>Custom classes</Radio>
                                  <Radio defaultChecked value=''>None</Radio>
                                </Space>
                              </Radio.Group>
                            </GroupedByWrapper>
                            {/* </StyledCheckbox> */}
                          </CheckboxWrapper>
                        </FormContentWrapper>
                        <ActionButtonsWrapper>
                          <PrimaryButtonWithIcon style={{ width: '202px', height: '38px' }} onClick={handleExportDataParams} isProcessing={processingReport}>
                            {processingReport ? <LoadingOutlined /> : <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}><DownloadIcon /> Create & download</div>}
                          </PrimaryButtonWithIcon>
                          {/* <SimpleSelect value={selectedFileType}
                              buttonStyle={{borderRadius:'8px'}}
                              placement={"bottomLeft"}
                              width={'140px'}
                              options={fileTypesOptions}
                              withCheckMark
                              withBorders
                              borderRadius={'8px'}
                              onChange={(val) => { setSelectedFileType(val) }} /> */}
                        </ActionButtonsWrapper>
                      </>
                    </ReportsDataBox>

                    <ReportsDataBox {...reportsData.generalReport} >
                      <ActionButtonsWrapper>
                        <PrimaryButtonWithIcon style={{ width: '202px', height: '38px' }} onClick={handleGeneralReport} isProcessing={isGeneralReportProcessing}>
                          {isGeneralReportProcessing ? <LoadingOutlined /> : <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}><DownloadIcon />Create & download</div>}
                        </PrimaryButtonWithIcon>
                      </ActionButtonsWrapper>
                    </ReportsDataBox>

                      <ReportsDataBox {...reportsData.commitmentCapitals} >
                        <CommitmentReportDataBoxContent isMobileAppView={uiStore.isMobileAppView} />
                      </ReportsDataBox>

                    <ReportsDataBox {...reportsData.customReports} >
                      <ActionButtonsWrapper>
                        <PrimaryButtonWithIcon onClick={CustomReportOnClick}>
                          Request custom report
                        </PrimaryButtonWithIcon>
                      </ActionButtonsWrapper>
                    </ReportsDataBox>

                  </DataBox></>
              }</>

          </ReportsContentWrapper>
        </PageWrapper>
          {userStore.data?.profile?.showFreeTierReportsPopup && <LimitationModal modalType={'reports'} onUpgrade={() => { handleUpgradeTierClick() }} onClose={() => { userStore.closeFreeTierReportsModal() }} />}
        </>
      }</>


  )
})