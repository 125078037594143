import { getTranslations } from "../../../../common/translations/translations";
import { ReactComponent as LowRiskSvg } from './icons/low-risk.svg';
import { ReactComponent as MediumRiskSvg } from './icons/medium-risk.svg';
import { ReactComponent as HighRiskSvg } from './icons/high-risk.svg';
import styled from "@emotion/styled/macro";

const { asset_fields } = getTranslations().common;

const OptionContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: -1px;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 2px;
  flex-shrink: 0;
`;

export const riskLevelRow = ({ isEditForm, updateField, validations = [] }) => ({
  fields: [
    {
      key: 'riskLevel', type: 'select',
      label: asset_fields.RISK_LEVEL_LABEL,
      description: `Risk: Indicates the potential for loss in an investment. Each asset gets a risk score from 1 (Low) to 3 (High). View and manage the allocation of your portfolio by these scores to optimize your investment strategy.`,
      withDescriptionInOption: true,
      allowClear: true,
      withSuffixIcon: true,
      setFnForSave: (isDistribution, value, formValues) => (value === '') ? null : value,
      options: [
        { value: 'Low', label: () => <OptionContainer><IconContainer style={{ background: '#C0D681' }}><LowRiskSvg /></IconContainer>Low</OptionContainer>, },
        { value: 'Medium', label: () => <OptionContainer><IconContainer style={{ background: '#EFB764' }}><MediumRiskSvg /></IconContainer>Medium</OptionContainer>, },
        { value: 'High', label: () => <OptionContainer><IconContainer style={{ background: '#F17389' }}><HighRiskSvg /></IconContainer>High</OptionContainer>, },
      ],
      ...(validations.length > 0 ? {
        validations
      } : {}),
    }
  ]
})

export const riskLevelColors = {
  Low: '#C0D681',
  Medium: '#EFB764',
  High: '#F17389',
}