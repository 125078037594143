import React from 'react';
import { EmptyStateSquareLogo, EmptyStateSquareLogoTitleWrapper, EmptyStateSquareLogoWrapper, EmptyStateSquareTitleWrapper, EmptyStateSquareTitle, EmptyStateSquareURL, EmptyStateSquareWrapper, EmptyStateSVIPSyncLogoWrapper, EmptyStateVIPSyncText } from './EmptyState.styles';
import { useStore } from '../../../../../../app/data/root.store';
import { clearUrl } from '../../../../../../../common/utils/object.utils';

export const EmptyStateSquare = (props) => {
    const { institution, syncMode } = props;
    const { createItemStore, userStore } = useStore();

    const onCreateSyncClick = () => {
        if (!userStore.isActionBlockedByRole('createItemInvestor') || userStore.isActionBlockedByRole('createItemBank')) {
            createItemStore.setInstitutionStep(syncMode);

            if (-1 !== institution.id) {
                institution.id = institution.env_id[process.env.REACT_APP_ENV_KEY];
                if (institution.providerName === 'vip') {
                    createItemStore.setSelectedVipSyncInstitution(institution);
                    createItemStore.setVipSyncStep();
                }else{
                    createItemStore.setManualSearchStr(institution.name);
                    createItemStore.setSearchResults('', null, [institution]);
                }
            }

            createItemStore.setShowModal(true);
        }
    };

    const isVipSync = (institution.providerName === 'vip');
    return (
        <EmptyStateSquareWrapper onClick={onCreateSyncClick}>
            <EmptyStateSquareLogoTitleWrapper>
                <EmptyStateSquareLogoWrapper>
                    {
                        institution.logoURL ?
                            <EmptyStateSquareLogo src={institution.logoURL} />
                            :
                            (
                                institution.logoBase64 ?
                                    <EmptyStateSquareLogo src={'data:image/png;base64,' + institution.logoBase64} />
                                    :
                                    institution.logoComponent
                            )

                    }
                </EmptyStateSquareLogoWrapper>
                <EmptyStateSquareTitleWrapper>
                    <EmptyStateSquareTitle isVipSync={isVipSync}>{institution.name}</EmptyStateSquareTitle>
                    {(!isVipSync && institution.homeURL) && <EmptyStateSquareURL>{clearUrl(institution.homeURL)}</EmptyStateSquareURL>}
                </EmptyStateSquareTitleWrapper>
            </EmptyStateSquareLogoTitleWrapper>
            {
                isVipSync &&
                <EmptyStateSVIPSyncLogoWrapper>
                    <EmptyStateVIPSyncText>VIP Syncing Service</EmptyStateVIPSyncText>
                </EmptyStateSVIPSyncLogoWrapper>
                
            }
        </EmptyStateSquareWrapper>
    );
};