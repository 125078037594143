import { makeAutoObservable } from "mobx";
import moment from 'moment';

import { closeReopenAsset,
    updateAttributeUpdate, deleteAttributeUpdate, 
     deleteAssetValueUpdate, deleteTransaction, fetchAssetData, updateAsset, updateAssetValueUpdate, updateTransaction, connectLiabilityToAsset , disconnectLiabilityFromAsset, deleteAsset , updateAmortizationLoan, updateAssetSettings, connectCollaterals, disconnectLiabilityFromCollateral } from './wealth.service';
import { ASSET_CATEGORIES_ACTUALS_ATTRIBUTES } from '../pages/asset/components/Actuals/constants/actualsTab.attributes.const';
import { monthDiff } from '../../../common/utils/dates.utils';
import { errorNotification, successNotification } from '../../layout/components/Notifications/notifications';
import { ASSET_CATEGORIES_OVERVIEW_DATA } from "../pages/asset/components/Overview/constants/OverviewTab.stats.const";
import { syncInstitution } from "../../connected-institutions/data/connected-institutions.service";
import { categoriesWithTickersHoldingsWhenIsConnected } from "../../../common/constants/tickers.const";

export class WealthItemStore{
    isLoading = false;
    isLoadedOnce = false;
    item={};
    itemSettings = {};
    activeTabUrl = '';  
    isHoldingsView = true;
    isSingleTickerConnectedView = false;
    eventsSliderDefaultIndex = 0;
    eventsSliderShowFutureEvents = false;
    assetOverviewHeroHoverData = {index: null};
    hiddenCanceledEventsItems = Array.isArray(JSON.parse(window.localStorage.getItem('hiddenCanceledEventsItems'))) ? JSON.parse(window.localStorage.getItem('hiddenCanceledEventsItems')) : [];
    isSyncingInstitution = false;
    isItemUpdated = false;
    itemFormEditedChanges = {};
    itemFormHaveChanges = false;
    editTargetsModal = {show: false , field: ''};
    showCloseItemModal = false;
    showCloseAccountNotFoundItemModal = false;
    showOpenItemModal = false;
    

    constructor(wealthStore,cashFlowStore,documentsStore,metadataStore,tickersStore){
        makeAutoObservable(this);
        this.wealthStore = wealthStore;
        this.cashFlowStore = cashFlowStore;
        this.documentsStore = documentsStore;
        this.metadataStore = metadataStore;
        this.tickersStore = tickersStore;
    }

    getItemData = async (id, fetchAnyway = false) => {
        if ((''+this.item.id !== ''+id) || this.isItemUpdated || fetchAnyway) {
            this.updateItem({},false);
            this.setIsLoadedOnce(false);
            this.setIsLoading(true);
            try {
                const data = await fetchAssetData(id);
                await this.setItemData(data);
            } 
            //catch (e) {} 
            finally {
                this.setIsLoading(false);
            }
        }
        else if(categoriesWithTickersHoldingsWhenIsConnected.includes(this.item.categoryId) && this.item.isConnected ) {
            await this.tickersStore.getTickersData([this.item.id]);
        }
        this.wealthStore.setViewOf('item');
    }

    setIsLoadedOnce(isLoaded){
        this.isLoadedOnce = isLoaded;
    }

    setIsLoading(isLoading){
        this.isLoading = isLoading;
        if (isLoading === false){
            this.isLoadedOnce = true;
        }
    }

    setIsSyncingInstitution(isSyncing){
        this.isSyncingInstitution = isSyncing;
    }

    setHoldingsView = (isHoldingsView) => {
        this.isHoldingsView = isHoldingsView;
    }

    setShowEditTargetModal(show, field = ''){
        this.editTargetsModal = {show , field };
    }

    setShowCloseItemModal(show){
        this.showCloseItemModal = show;
    }

    setShowCloseAccountNotFoundItemModal = (show) => {
        this.showCloseAccountNotFoundItemModal = show;
    }

    setShowOpenItemModal(show){
        this.showOpenItemModal = show;
    }

    setHidingCanceledEvents() {
        this.hiddenCanceledEventsItems = !this.hiddenCanceledEventsItems.includes(this.item.id) ? 
        [...this.hiddenCanceledEventsItems, this.item.id] : 
        this.hiddenCanceledEventsItems.filter(i => i !== this.item.id);
        window.localStorage.setItem('hiddenCanceledEventsItems', JSON.stringify(this.hiddenCanceledEventsItems));
    }

    updateItem(item,extend=true){
        this.item = {...(extend ? this.item : {}),...item} ;
    }

    setItemFormEditChanges(changes){
        this.itemFormEditedChanges = changes;
    }

    setItemFormHaveChanges(changes){
        this.itemFormHaveChanges = changes;
    }
     async setItemData(data){
        const {documents , ...assetData } = data.asset;

        this.item = assetData;
        this.item.futureEvents = data.events || [];
        this.documentsStore.setItemDocuments(assetData.id , documents);

        this.itemSettings = data.settings;
        if(!this.item.settings) {
            this.item.settings = {};
        }
        if(categoriesWithTickersHoldingsWhenIsConnected.includes(this.item.categoryId) && this.item.isConnected) {
            this.tickersStore.setData([]);
            await this.tickersStore.getTickersData([this.item.id]);
        }
        this.isItemUpdated = false;
    }

    setStockAssetPageView(stockId){
        const found = this.tickersStore.tickers?.find(t=>t.id === +stockId);
        this.isSingleTickerConnectedView = true;
        if (found){
            this.item = {
                ...this.item,
                assetTitle: this.item.title,
                isConnectedSingleStock: true,
                value: found.quantity,
                usdValue: found.usdValue,
                itemCurrencyValue: found.value,
                itemCurrencyTotalValue: found.value,
                stockCurrencyValue: found.value,
                symbol: found.symbol,
                symbolType: found.type,
                title: found.title,
                currency: found.isoCurrencyCode,
                category: {
                    id: 39,
                    classId: 3,
                    title: "Tickers",
                    description: "Track your stocks, bonds, ETFs, mutual funds, economic indicators, foreign exchange rates and commodities."
                },
                categoryId: 39,
                stock: found,
                stockLastPrice: found.currentPrice,
                roi: found.performance,
                cost_basis: found.costBasis,
                cost_per_share: found.costPerShare,
                gain: found.gain,
            }
        }
    }

    setSingleTickerView(isInView){
        this.isSingleTickerConnectedView = isInView;
    }
    setActiveTabUrl(activeTab){
        this.activeTabUrl = activeTab;
    }

    get activeTab() {
        // return (this.activeTabUrl && eligibleTabs.includes(this.activeTabUrl)) ? this.activeTabUrl : ([1,11].includes(this.item.categoryClass?.id ) ? 'transactions' : this.item.categoryClass.id === 3 && this.item.isConnected ? 'holdings' : 'events')
        return this.activeTabUrl || ([1,11].includes(this.item.categoryClass?.id ) ? 'transactions' : ( this.isSingleTickerConnectedView ? 'info' : categoriesWithTickersHoldingsWhenIsConnected.includes(this.item.categoryId) && this.item.isConnected ? 'holdings' : 'events'));
    }

    setItemUpdated(isUpdated){
        this.isItemUpdated = isUpdated;
    }

    itemUpdated(id){
        if (this.item.id === id){
            this.isItemUpdated = true;
        }
    }

    closeReopenAsset = async (assetId,isClosed,isLiability=false,onDone) => {
        try {
            const data = await closeReopenAsset(assetId,!isClosed,isLiability);
            if (data.statusCode){
                errorNotification('Something went wrong');
            }else {
                successNotification(`${isLiability ? 'Liability' : 'Asset'} ${isClosed ? 'reopened' : 'closed'}`)
                onDone();
                this.item.closedAt = isClosed ? null : new Date();
                this.wealthStore.getData(true,true);
                this.updateItemData();
            }
        } 
        catch (e) {errorNotification('Something went wrong');} 
        finally {}
    }

    deleteAsset = async(assetId,isLiability=false,onDone) => {
        try {
            const data = await deleteAsset(assetId,isLiability);
            if (data.statusCode){
                errorNotification('Something went wrong');
            }else {
                successNotification(`${isLiability ? 'Liability' : 'Asset'} deleted`);
                // this.wealthStore.getData();
                onDone && onDone();
            }
        } 
        catch (e) {errorNotification('Something went wrong');} 
        finally {}
    }

    updateAsset = async (assetId,data) => {
        try {
            const updateData = await updateAsset(assetId,data);
            if (updateData.statusCode){
                errorNotification('Something went wrong');
            }else {
                successNotification('Asset updated');
                // this.updateItem(updateData);
                this.updateItem({...updateData.asset,futureEvents : updateData.events || []});
                this.wealthStore.getData(false,true);
                this.metadataStore.fetchData();
                return updateData;
            }
        } 
        //catch (e) {} 
        finally {
            // this.setLoadingAssetData(false);
        }
    }

    updateAssetValueUpdate = async (assetId,valueUpdate) => {
        try {
            const updateData = await updateAssetValueUpdate(assetId,valueUpdate);
            this.showMessageAndUpdateItemAndWealthData(updateData,'Something went wrong','Updated successfully')       
        } 
        //catch (e) {} 
        finally {}
    }

    deleteAssetValueUpdate = async (assetId,valueUpdateId) => {
        if ( this.item.valueUpdates.length > 1 ){
        try {            
            const deleteData = await deleteAssetValueUpdate(assetId,valueUpdateId);
            this.showMessageAndUpdateItemAndWealthData(deleteData,'Something went wrong','Deleted successfully')       
        } 
        //catch (e) {} 
        finally {}
        } else {
            errorNotification(`Can't delete the lats update`);
        }
    }

    updateItemAttribute = async (attributeUpdate) => {
        try {
            const updateData = await updateAttributeUpdate(this.item.id,attributeUpdate);
            this.showMessageAndUpdateItemAndWealthData(updateData,'Something went wrong','Updated successfully')       
        } 
        //catch (e) {} 
        finally {}
    }

    deleteItemAttribute = async (attributesRowId,atrKey) => {
        try {
            const deleteData = await deleteAttributeUpdate(this.item.id,attributesRowId,atrKey);
            this.showMessageAndUpdateItemAndWealthData(deleteData,'Something went wrong','Deleted successfully')       
        } 
        //catch (e) {} 
        finally {}
        
        
    }

    dismissVipSyncAwareness(assetId){
        if (!this.item?.settings?.promptDismissed) {
            this.item.settings.promptDismissed = {vipSyncAwareness: true};
        } else {
            this.item.settings.promptDismissed.vipSyncAwareness = true;
        }
        this.updateSettings(assetId, 'promptDismissed', { vipSyncAwareness: true });
    }

    updateTransaction = async (itemId,transaction) => {
        try {
            const updateData = await updateTransaction(itemId,transaction);
            this.showMessageAndUpdateItemAndWealthData(updateData,'Something went wrong','Updated successfully')  
        } 
        //catch (e) {} 
        finally {}
    }

    deleteTransaction = async (itemId,transactionId) => {
        try {            
            const deleteData = await deleteTransaction(itemId,transactionId);
            this.showMessageAndUpdateItemAndWealthData(deleteData,'Something went wrong','Deleted successfully')       
        } 
        //catch (e) {} 
        finally {}
    }

    connectCollaterals = async(itemId,liabilityData,onDone) => {
        try {
            const connectData = await connectCollaterals(itemId,liabilityData);
            this.showMessageAndUpdateItemAndWealthData(connectData,'Something went wrong','Linked successfully'); 
            onDone();
        } 
        catch (e) {
            errorNotification(e.message);
        } 
        finally {}
    }

    connectLiabilityToAsset = async(itemId,liabilityData,onDone) => {
        try {
            const connectData = await connectLiabilityToAsset(itemId,liabilityData);
            this.showMessageAndUpdateItemAndWealthData(connectData,'Something went wrong','Linked successfully'); 
            onDone();
        } 
        catch (e) {
            errorNotification(e.message);
        } 
        finally {}
    }

    disconnectLiabilityFromCollateral = async(itemId,liabilityData,onDone) => {
        try {
            const connectData = await disconnectLiabilityFromCollateral(itemId,liabilityData);
            this.showMessageAndUpdateItemAndWealthData(connectData,'Something went wrong','Unlinked successfully');       
            onDone();
        } 
        finally {}
    }

    disconnectLiabilityFromAsset = async(itemId,connectionId,onDone) => {
        try {
            const connectData = await disconnectLiabilityFromAsset(itemId,connectionId);
            this.showMessageAndUpdateItemAndWealthData(connectData,'Something went wrong','Unlinked successfully');       
            onDone();
        } 
        finally {}
    }

    showMessageAndUpdateItemAndWealthData(data,errorMessage,successMessage,cb=null){
        if (data.statusCode || data === false){
            errorNotification(errorMessage,{autoClose:3000});
        } else {
            successNotification(successMessage,{autoClose:3000});
            Promise.all([this.updateItemData(),this.wealthStore.getData(true,true)]).then(()=>{
                cb && cb();
            });
        }
    }
    
    updateItemData = async () => {
        this.setIsLoading(true);
        try {
            const data = await fetchAssetData(this.item.id);
            this.updateItem({...data.asset,futureEvents : data.events || []});
        }
        finally {
            this.setIsLoading(false);
        }
    }

    setEventsSliderDefaultIndex(index){
        this.eventsSliderDefaultIndex = index;
    }

    setEventsSliderShowFutureEvents(show){
        this.eventsSliderShowFutureEvents = show;
    }
    setAssetOverviewHeroHoverData(hoverData) {
        this.assetOverviewHeroHoverData = hoverData;
    }

    updateItemSettings = (updates) => {
        this.itemSettings = {...this.itemSettings, ...updates};
    }

    async updateSettings(assetId, section, updates){
        await updateAssetSettings(assetId, section, updates);
    }

    updateLoanAmortizationInitialDetails = async(itemId, data, isAmortization ) => {
        
        try {
            const updateData = await updateAmortizationLoan(itemId,data,isAmortization);
            if (updateData.statusCode){
                errorNotification('Something went wrong');
            }else {
                successNotification('Asset updated');
                // this.updateItem(updateData);
                this.updateItem({...updateData.asset,futureEvents : updateData.events || []});
                this.wealthStore.getData();
                this.metadataStore.fetchData();
                return updateData;
            }
        } 
        //catch (e) {} 
        finally {
            // this.setLoadingAssetData(false);
        }
    }

    fetchInstitutionAccounts = async(provider, instId) => {
        this.setIsSyncingInstitution(true);
        try {
            if (['plaid','saltEdge','akoya'].includes(provider) && instId) {
                await syncInstitution(provider,instId);
                this.getItemData(this.item.id, true);
            }
        } catch (error) {
            errorNotification('Something went wrong...')
            // throw new Error('something went wrong...');
        }
        finally {
            this.setIsSyncingInstitution(false);
        }
    }

    get eventsSliderStartIndex(){
        return this.startAndTodayIndex || this.eventsSliderDefaultIndex;
    }

    get isLoadFutureDisables(){
        return !this.eventsSliderShowFutureEvents && this.eventsSliderStartIndex === 0;
    }
    // setEventSliderStartIndex(index){
    //     this.eventsSliderStartIndex = index;
    // }
    get startAndTodayIndex(){
        if(this.itemEvents.length > 0) {
            let currentMonthStartIndex = this.itemEvents.findIndex(event => event.month <= 0);
            // console.log(this.itemEvents);
            currentMonthStartIndex = currentMonthStartIndex < 0 ? currentMonthStartIndex : currentMonthStartIndex - 1
            // console.log(currentMonthStartIndex);
            // if (currentMonthStartIndex > this.itemEvents.length - this.eventsSliderVisibleItems) {
            //     return this.itemEvents.length > this.eventsSliderVisibleItems ? this.itemEvents.length - this.eventsSliderVisibleItems : 0
            // }
            // else {
                return currentMonthStartIndex !== -1 ? currentMonthStartIndex : 0;
            // }
        }
        return 0;
    }
    get eventsSliderVisibleItems(){
        // if(this.itemEvents.length - this.eventsSliderStartIndex <= 5) {
        //     return 5;
        // }
        if(this.eventsSliderShowFutureEvents) {
            // console.log('YO');
            // console.log(this.itemEvents.length);
            return this.itemEvents.length;
        }
        return this.itemEvents.length - this.eventsSliderStartIndex;
    }

    get itemOverviewDisplayData() {
        return typeof ASSET_CATEGORIES_OVERVIEW_DATA[this.item?.category?.id] === 'function' ? ASSET_CATEGORIES_OVERVIEW_DATA[this.item?.category?.id]({isConnected : this.item.isConnected}) : ASSET_CATEGORIES_OVERVIEW_DATA[this.item?.category?.id] || [];
    }

    get hasConnectedLiability(){
        return this.item.connectedLiabilities?.length > 0;
    }

    get connectedCollaterals() {
        if (!this.item.collateralOf || this.item.collateralOf?.length === 0) {
            return [];
        }
        const loansForDisplay = this.item.collateralOf.map(c=>{
            const relevantLoan = this.metadataStore.loansObj[c.liabilityId];
            return {
                ...relevantLoan,
                assetId: this.item.id,
                liabilityId: relevantLoan.id,
                usdValue: relevantLoan.value,
            }
        }
        )
        return loansForDisplay
    }

    get isShowingTickers() {
        return this.tickersStore.tickers?.length > 0 && this.item.isConnected;
    }

    get itemPieChartData(){
        const { tickers } = this.tickersStore;

        const itemUsdHoldingsValue = ( this.item.usdValue ) * ((this.item.holdings || 100)/100);
        const liabilitiesUsdHoldingsValues = this.item?.connectedLiabilities.map( lia => ( lia.usdValue || lia.value ) * ((lia.holdings || 100)/100) );
        const liabilitiesUsdSumValue = liabilitiesUsdHoldingsValues.reduce((a,b)=> a+b,0);

        return {
            datasets: [
                {
                    data: this.isShowingTickers && !this.isSingleTickerConnectedView ? tickers.map(ticker=>ticker.value) : [1],
                    backgroundColor: this.item?.categoryClass.color,
                    hoverBackgroundColor: this.item?.categoryClass.color,
                    hoverBorderColor: '#fff',
                    // hoverBackgroundColor: this.assetsGroups.map(group=>group.color),
                    // borderColor:'#000',
                    // borderWidth:this.assetsGroups.map((group,groupIndex)=>{return groupIndex===this.selectedAssetClassIndex ? 0 : 6}),
                    label:'Asset',
                    borderColor:'#fff',
                    borderWidth: this.isShowingTickers ? 
                    (this.item?.connectedLiabilities?.length > 0 ? 2 : 6 ) : 0,
                    weight: 0.5
                    // labels: this.assetClass.items?.map(asset=>asset.title),
                },
                ...( this.item?.connectedLiabilities?.length > 0 ? [
                    {
                        data: [1],
                        backgroundColor:  '#fff',
                        hoverBackgroundColor:'#fff',
                        hoverBorderColor: '#fff',
                        label:'spaceX',
                        weight: this.isShowingTickers ? 0.2 : 0.3
                    },
                    {
                        data: [ ...liabilitiesUsdHoldingsValues , itemUsdHoldingsValue - liabilitiesUsdSumValue ],
                        backgroundColor:  [...liabilitiesUsdHoldingsValues.map(val=>'#EB8787') ,'#fff'],
                        hoverBackgroundColor:[...liabilitiesUsdHoldingsValues.map(val=>'#EB8787'),'#fff'],
                        label:'Liability',
                        // hoverBackgroundColor: this.assetsGroups.map(group=>group.color),
                        hoverBorderColor: '#fff',
                        borderColor:'#fff',
                        borderWidth: 2, // this.isShowingTickers ? 2 : 0,
                        weight: 0.5
                    }
                ] : [] )
            ]
        };
    }

    get valueChartData(){
        
        const sortedValueUpdate = this.item.valueUpdates ? this.item.valueUpdates.slice()
        .sort((a,b)=>{
            return new Date(b.date) - new Date(a.date);
        }) : [];
        const dataSetLabels = sortedValueUpdate.map(vu=> vu.date); // moment(vu.date).format('DD MMM YY'));
        const dataSetData = sortedValueUpdate.map(vu=>vu.value * ( 
            this.isHoldingsView && this.item.holdings ? (this.item.holdings / 100) : 1 ));
        if (this.item.valueUpdates && this.item.valueUpdates.length === 1){
            const updateDate = moment(this.item.valueUpdates[0].date);
            dataSetLabels.unshift(moment(updateDate).add(-1,'M'));
            dataSetLabels.push(moment(updateDate).add(1,'M'));
            dataSetData.unshift(null);
            dataSetData.push(null);
        }
        return {
            labels: dataSetLabels,
            datasets: [
                {
                    label: 'Value',
                    data : dataSetData,
                    fill: false,
                    backgroundColor: '#fff' , // '#545E64',
                    borderColor: '#fff' , // '#545E64',
                    lineTension:0,
                }
            ]
        };
    }

    get itemCurrency(){
        return this.item.currency || 'USD';
    }

    get itemActuals(){
        
        const valueUpdates = this.item.valueUpdates?.filter(vu=> (!vu.hasOwnProperty('deletedAt') || vu.deletedAt === null) && !vu.eventId ).map(vu=>({...vu,updatedKeys:['balance'],isValueUpdate:true}));
        const transactions = this.item.categoryClass?.id === 1 ? [] : this.item.transactions.filter(trn=> (!trn.hasOwnProperty('deletedAt') || trn.deletedAt === null) && !trn.eventId );
        const attributesAsActuals = 
            ASSET_CATEGORIES_ACTUALS_ATTRIBUTES.hasOwnProperty(this.item.categoryId) ? 
            ASSET_CATEGORIES_ACTUALS_ATTRIBUTES[this.item.categoryId]
          .map(attribute=>{
            const attributeUpdates = this.item.attributesHistory[attribute.attributeKey] || [];
            return attributeUpdates.filter(au=>au.eventId===null).map(au=>({
                ...au,
                ...attribute,
                isAttributeUpdate:true
            }))
        }).flat() : []; 
        return [
            ...valueUpdates,
            ...transactions,
            ...attributesAsActuals,
        ].map(
            item=>({...item,month:monthDiff(new Date(), new Date(item.date),true)})
        )
        
        
        // .sort((a,b)=>{
        //     return new Date(b.date) - new Date(a.date);
        // });
    }

    get itemEvents(){
        const events = this.item.futureEvents;
        // console.log(events.length);
        let prevMonth = null;
        // let currentValueUpdate = null;


        const res = [
            // ...this.itemActuals.map(ac=>({...ac,isWithoutEvent:true})),
            ...events
        ]
        // .sort((a,b)=>{
        //     if (a.month === b.month){
                
        //         //"Annual interest rate"
        //         //Interest and principal repayment
        //         if (a.eventType === "Annual interest rate" && b.eventType === "Interest and principal repayment" ) {
        //             return 1
        //         }else if (b.eventType === "Annual interest rate" && a.eventType === "Interest and principal repayment") {
        //             return -1
        //         }
        //       return (a.date && b.date ? ( a.date - b.date ) : 0);
        //     } else {
        //       return a.month - b.month
        //     }
        // })
        // .map((event)=>{
        //     currentValueUpdate = event.eventData?.valueUpdates?.length ? event.eventData?.valueUpdates[0].value : currentValueUpdate;
        //     return {...event, valueAtDate : currentValueUpdate}
        // })
        .reverse().map((event) => {
            const isSameAsPrev = prevMonth === event.month;
            prevMonth = event.month;
            
            return isSameAsPrev ? event : {...event, isFirstInMonth: true}
        });

        return this.isItemHideCancelledEvents ? res.filter(item => !item.isCanceled) : res;
    }

    get latestItemEvent() {
        return this.itemEvents.slice()[0]; 
    }

    get latestItemEventDate() {
        const latestEvent = this.latestItemEvent;
        return latestEvent ? latestEvent.date : null;
    }

    get pastEventNotificationsCount(){
        return this.itemEvents.filter(item=> !item.isCanceled && !item.hasHappened && !item.id && !item.trnId && item.month < 0 ).length;
    }

    get transactionsEventNotificationsCount(){
        return this.itemEvents.filter(item=> !item.isCanceled && !item.hasHappened && !item.id && item.trnId ).length;
    }    

    get firstContributionId(){
        const firstContribution = this.item.transactions.filter(tr=> !tr.hasOwnProperty('deletedAt') || tr.deletedAt === null).slice().reverse().find(tr=>{
            const isConnectedFrom = (''+tr.associateId) !== ''+this.item.id;
            return tr.value * (isConnectedFrom ? -1 : 1) < 0;
        })
        return firstContribution ? firstContribution.id : null;
    }

    get firstDistributionId(){
        const firstDistribution = this.item.transactions.filter(tr=> !tr.hasOwnProperty('deletedAt') || tr.deletedAt === null).slice().reverse().find(tr=>{
            const isConnectedFrom = (''+tr.associateId) !== ''+this.item.id;
            return tr.value * (isConnectedFrom ? -1 : 1) > 0;
        })
        return firstDistribution ? firstDistribution.id : null;
    }

    get canceledItemsEventsCount(){
        return this.item.futureEvents.filter(item => item.isCanceled).length;
    }

    get isItemHideCancelledEvents() {
        return this.hiddenCanceledEventsItems.includes(this.item.id)
    }

    get hasEventWithTwoTransactions() {
        return this.item.futureEvents.some(futureEvent => futureEvent?.eventData?.transactions?.length === 2);
    }

    get isShowingVipSyncAwareness() {
        return  this.item?.showVipSyncAwareness && [4,8].includes(this.item?.categoryClass.id) && !this.metadataStore.checkInstitutionExistence(this.item?.institutionsList?.institutionId);
    }

    get isExitEventExist() {
        return this.itemEvents.find(ev=>ev.eventType === 'Exit event')
    }

    get connectedAccountId() {
        if (!this.item.connectedProvider) return null;

        const accountId = {
          plaid: this.item.plaidConnectedAccountId,
          saltEdge: this.item.saltEdgeAccountId,
          akoya: this.item.akoyaAccountId
        }[this.item.connectedProvider];

        return accountId;
    }

}

