import { makeAutoObservable } from "mobx";
import { fetchTickersData, fetchTickersDataForItems } from "../../../../../data/wealth.service";
import { getValueByPath } from "../../../../../../../common/utils/object.utils";

export class TickersStore {

  tickers = [];
  isLoadingTickersData = false;
  hoveredIndex = -1;
  currentClassId = null;

  constructor(dataLayersStore) {
    makeAutoObservable(this);
    this.dataLayersStore = dataLayersStore;
  }

  setData(data) {
    this.tickers = data;
  }

  getTickersData = async (itemIds,classId) => {
    this.setIsLoadingTickersData(true);
    try {
      const tickersData = await (itemIds.length ? fetchTickersDataForItems(itemIds) : fetchTickersData(classId));
      this.currentClassId = classId;
      this.setData(tickersData);
    }
    //catch (e) {} 
    finally {
      this.setIsLoadingTickersData(false);
    }
  }

  setIsLoadingTickersData(isLoading) {
    this.isLoadingTickersData = isLoading;
  }

  setHoveredIndex(index) {
    this.hoveredIndex = index;
  }

  get sortedTickersList() {
    
    const {sortByColumn } = this.dataLayersStore;
    const items = this.tickers.map((item)=>({
      ...item, 
      sortValue: sortByColumn.path ? (getValueByPath(item,sortByColumn.path) || null) : item[sortByColumn.dataKey],
    }))
    .sort((a,b)=>{ 
      if (['date','remainingTimeFromDate'].includes(sortByColumn.type)){
         return  +(new Date(b.sortValue)) - +(new Date(a.sortValue));
      }
      // if (sortByColumn.type === 'assetName'){
      //     return (''+(b.title || '')).localeCompare((a.title || ''), 'en', {sensitivity: 'base'});
      // }
      if (sortByColumn.type === 'text'){
          return (''+(b.sortValue || '')).localeCompare((a.sortValue || ''));
      }
      if (sortByColumn.type === 'liabilityAmount'){
          return b.sortValue?.reduce((c,d)=>c+d.usdValue,0) -  a.sortValue?.reduce((c,d)=>c+d.usdValue,0);
      }
      if (['percentage', 'percentageMax1', 'multiplier'].includes(sortByColumn.type)){
          return b.sortValue - a.sortValue;
      }
      if (sortByColumn.type === 'bool'){
          return b.sortValue === a.sortValue ? 0 : b.sortValue ? 1 : -1;
      }
      if (sortByColumn.dataKey === 'usdValue') {
        if (a.isClosed && !b.isClosed) {
          return 1;
        }
        if (b.isClosed && !a.isClosed) {
          return -1;
        }
      }
      return (b.sortValue*(b.holdings||1)) - (a.sortValue*(a.holdings||1))});
    
    
    return sortByColumn.direction === "asc" ? items.reverse() : items;
    // return this.tickers;
  }

  get closedHoldingsIdsInDisplay() {
    return this.tickers.filter(item => item.isClosed).map(item => item.id);
  }

  get closedHoldingsInDisplayCount() {
    return this.tickers.filter(item => item.isClosed).length;
  }

}