import styled from "@emotion/styled/macro";
import { flexCenter } from "../../../common/styles/combos.styles";
import { colors, views } from "../../../common/styles/theme.styles";
import bgImg from '../assets/deal-flow-bg.jpg';

export const CommunityPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  grid-area:content;
  ${views.desktop}{
    height: 100vh;
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 45px;
  background-color: #f2f3f5;
  ${props=>props.withHeader ? `` : `
    grid-area:content;
  `}
  overflow:auto;
  min-height:100%;
  & > div {
    width: 100%;
    max-width: 880px;
    margin-bottom:45px;
  }
  ${views.tablet}{
    background-color: ${colors.darkHeroBackground};
    padding:0px; //64px 0 0 0;
    & > div {
      // padding-bottom:64px;
      padding-bottom: calc( env(safe-area-inset-bottom) + 64px );
      margin-bottom:0px;
    }
  }
`

export const StickyWrapper = styled.div`
  position: sticky;
  top: 0px;
  width: 100%;
  
  ${props => props.isSticky ? `
      border-bottom: 1px solid ${colors.gray3};
      z-index: 5;
  ` : null}
`;

export const CommunityPageHeader = styled.div`
  display: flex;
  align-items: center;
  padding-inline: 20px;
  min-height: 52px;
`

export const MobileHeader = styled.div`
    position: ${props => props.isHeaderFixed ? `fixed` : `relative`};
    top: 0;
    background-color: #111213;
    z-index: 2;
    width: 100%;
    min-height: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 24px 0 0;
`;

export const HeaderTitle = styled.div`
    font-weight: 450;
    font-size: 18px;
    color: #fff;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CommunityPageHeaderTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: ${colors.darkGray1};
`

export const HeaderAltText = styled.div`
  font-size: 13px;
  line-height: 138%;
  color: ${colors.darkGray3};
`;

export const HeaderActions = styled.div`
  display:flex; 
  flex:1; 
  justify-content:flex-end; 
  align-items:center;
  gap:8px;
`;

export const HeaderButton = styled.div`
  ${flexCenter};
  height:32px;
  font-size:16px;
  font-weight:450;
  outline:none;
  border:none;
  border-radius:6px;
  cursor:pointer;
  gap: 4px;
  padding: 0 8px;
`;


export const WhatYouGetButton = styled(HeaderButton)`
  background-color: ${props => props.isOpen ? colors.darkGray1 : colors.lightGray1};
  color: ${props => props.isOpen ? '#fff' : colors.darkGray1};
  :hover{
    background-color:${colors.darkGray1};
    color: #fff;
    svg {
      path {
        fill: #fff;
      }
      rect {
        stroke: #fff;
      }    
    } 
  }
  ${props => props.isOpen ? `
    svg {
      path {
        fill: #fff;
      }
      rect {
        stroke: #fff;
      }    
    } 
  ` : ``}
`;

export const MenuWrapper = styled.div`
    width:${props => props.width || '200px'};
    max-height: 100vh;
    overflow-y: auto;
    background: ${colors.darkGray1};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    padding: 0;
    border-radius: 8px;
    border: 1px solid ${colors.darkGray1};
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const MenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-between;
  padding: 24px 16px 24px 32px;
  border-bottom: 1px solid ${colors.darkGray2};
`;

export const MenuCloseButtonWrapper = styled.div`
  cursor: pointer;
  ${flexCenter};
`;

export const MenuTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
`;

export const MenuContentWrapper = styled.div`
  padding: 0px 32px 8px;
`;

export const MenuSubTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  padding: 0px 0 8px;
`;


export const MenuItemWrapper = styled.div`
    padding:8px 0;
    ${flexCenter};
    color:#fff;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap:10px;
    padding-bottom: 16px;
    :not(:last-of-type){
      border-bottom: 1px solid ${colors.darkGray2};
    }
`;


export const MenuItemIconWrapper = styled.div`
    ${flexCenter};
    color:#fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:8px;
    width: 24px;
    padding-top: 8px;
`;
export const MenuItemContentWrapper = styled.div`
    padding:8px 0;
    ${flexCenter};
    color:#fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // gap:8px;
    padding-bottom: 16px;
    :not(:last-of-type){
      border-bottom: 1px solid ${colors.darkGray2};
    }
`;

export const MenuItemTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
    color:#fff;
    line-height: 24px;
`;

export const MenuItemSubTitle = styled.div`
      font-size: 14px;
      line-height: 16px;
    font-weight: 450;
    color:${colors.darkGray3};
    padding-bottom: 8px;
`;

export const MenuItemsDescriptionListSeparator = styled.div`
    width: 380px;
    border-bottom: 1px solid #DDE3EB;
    margin:4px 0;
`;

export const WhatYouGetMenuItemWrapper = styled.div`
    ${flexCenter};
    padding:8px 0px;
    gap: 20px;
`;

export const WhatYouGetMenuItemIcon = styled.div`
    /* width: 80px; */
    display: flex;
    align-items: center;
`;

export const WhatYouGetMenuItemTextContent = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    align-items: flex-start;
`;

export const WhatYouGetMenuItemTitle = styled.div`
    color:${colors.darkGray1};
    font-size:16px;
    font-weight:500;
    line-height:19px;
    margin-bottom:4px;
`;

export const WhatYouGetMenuItemSubTitle = styled.div`
    color:${colors.darkGray3};
    font-size: 12px;
`;



export const HeroBgWrapper = styled.div`
  background: url(${bgImg}) no-repeat;
  border-radius: 8px;
  position: relative;
  display:flex;
  flex-direction:column;
  align-items:center;
  height: ${props=>props.withoutTimer ? 240 : 440}px;
  background-size: cover;
  background-position: center center;
      justify-content: flex-end;
    padding-bottom: 24px;
  ${views.tablet}{
      height: auto;
      padding-top: 24px;
  }
`;

export const HeroTitle = styled.div`
  font-size: 42px;
  font-weight: 700;
  color:#fff;
   ${views.tablet}{
      font-size: 32px;
      padding:0 24px 8px;
      line-height: 36px;
      text-align: center;
  }
`;

export const HeroSubTitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 450;
  color:#fff;
  text-align: center;
   ${views.tablet}{
      padding:0 24px;
      text-align: center;
  }
`;

export const HeroCountDownSectionWrapper = styled.div`
      display: flex;
padding: 16px 24px;
align-items: center;
gap: 24px;
border-radius: 10px;
border: 1px solid #57a8b62e;
background: rgba(255, 255, 255, 0.05);
backdrop-filter: blur(5px);
margin-top: 40px;
${views.tablet}{
    flex-direction: column;
    gap:8px;
}
    ${views.mobile}{
      padding:16px 8px;
    }
`;

export const CountDownLeftText = styled.div`
display: flex;
align-items: center;
      font-size: 12px;
      font-weight: 450;
      color:#fff;
`;

export const CountDownUnitsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap:16px;
`;

export const CountDownUnitWrapper = styled.div`
  display: flex;
  width: 64px;
  padding: 12px 4px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  ${views.tablet}{
    padding: 4px 4px;
  }
  ${views.mobile}{
    padding: 4px 4px;
  }
`;

export const CountDownUnitNumber = styled.div`
  font-size: 40px;
  line-height: 42px;
  font-weight: 700;
  color:#fff;
`;
export const CountDownUnitText = styled.div`
  font-size: 12px;
  font-weight: 450;
  color:#fff;
`;

export const CountDownActionButton = styled.a`
      display: flex;
height: 44px;
padding: 0px 12px;
align-items: center;
border-radius: 8px;
border: 1px solid #FFCA7B;
border-radius: 6px;
box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.20), 0px 4px 15px 0px rgba(0, 0, 0, 0.10);
color: #FFCA7B;
font-size: 16px;
font-weight: 450;
:hover{
  color:#FFCA7B;
  background-color: #FFCA7B20;
}
`;

export const DealsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top:24px;

`;

export const DealItemBoxWrapper = styled.div`
display: flex;
      border-radius: 8px;
border: 1px solid #DDE3EB;
background: #FFF;
box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08), 0px 8px 16px 0px rgba(0, 0, 0, 0.04);
${views.tablet}{
  flex-direction: column;
}
`;

export const DealItemLeftPanel = styled.div`
  display: flex;
  width: 280px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-right: 1px solid  #DDE3EB;
  ${views.tablet}{
    width: 100%;
  }
`;

export const DealItemLeftPanelTop = styled.div`
      ${flexCenter};
      flex-direction: column;
      flex:1;
      img{
        width:80px;
      }
      ${views.tablet}{
        padding:16px 0;
      }
`;


export const DealItemLeftPanelTitle = styled.div`
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      // ${views.tablet}{
      //   padding:16px 0;
      // }
`;

export const DealItemLeftPanelDetails = styled.div`
      width: 100%;
  border-top: 1px solid  #DDE3EB;
  background:  #F7F8F9;
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-bottom-left-radius: 8px;
  ${views.tablet}{
    border-bottom-left-radius: 0px;
        padding:16px 0;
        border-bottom: 1px solid  #DDE3EB;
  }
`;

export const DealItemLeftPanelDetailsTopText = styled.div`
        font-size: 12px;
        line-height: 12px;
  font-weight: 450;
  color:${colors.darkGray1};
  display: flex;
  align-items: center;
  gap:6px;
`;
export const DealItemLeftPanelDetailsBottomText = styled.div`
  font-size: 14px;
  font-weight: 450;
  color:${colors.darkGray1};
`;

export const DealItemRightPanel = styled.div`
      display: flex;
      flex-direction: column;
      flex:1;
`;

export const DealItemRightPanelTop = styled.div`
  display: flex;
  padding: 32px 24px 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap:8px;
  align-self: stretch;
`;

export const DealStatsRowWrapper = styled.div`
      display: flex;
      align-items: flex-start;
      gap:0;
      width:100%;
      flex-wrap: wrap;
      // gap: 24px;
       ${views.tablet}{
         flex-wrap: wrap;
         gap:0;
         width:100%;
       }
`;

export const DealStatUnitWrapper = styled.div`
  display: flex;
  flex-direction: column;
   width: 50%;
    align-items: flex-start;
    margin-bottom: 16px;
  ${views.tablet}{
    width: 50%;
    align-items: flex-start;
    margin-bottom: 16px;
  }
`;

export const DealStatUnitNumber = styled.div`
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  color:${colors.darkGray1};
  letter-spacing: -2px;
`;
export const DealStatUnitText = styled.div`
  font-size: 12px;
  font-weight: 450;
  color:${colors.darkGray1};
  // text-align: center;
`;

export const DealDetailsTextWrapper = styled.div`
  display: flex;
  padding: 0px 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 450;
  color:${colors.darkGray1};
`;

export const DealDetailsTextRowWithIcon = styled.div`
  display: flex;
  align-items: flex-start;
  gap:8px;
  svg{margin-top: 4px;min-width: 14px;}
  padding:4px 0;
`;

export const DealActionButtonsWrapper = styled.div`
      display: flex;
padding: 24px;
justify-content: flex-end;
align-items: center;
gap: 16px;
align-self: stretch;
${views.mobile}{
    flex-direction: column;
}
`;

export const DealActionButton = styled.a`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  text-align: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid  #DDE3EB;
  background:  #FFF;
  color: #000;
  font-size: 14px;
  justify-content: center;
  ${views.mobile}{
    width:100%;
    justify-content: center;
}
  :hover{
    color: #000;
    background-color:#f4f4f4;
  }
  ${props => props.primary ? `
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.20), 0px 4px 15px 0px rgba(0, 0, 0, 0.10);
      border-radius: 6px;
      background: #FFB21C;
      color: #fff;
      border:1px solid #FFB21C;
      :hover{
        color: #fff;
        background-color: #d2941a;
      }
      @media (min-width: 1132px){
        width:160px;
      }
  ` : ``}
  ${props => props.disabled ? `
      svg{
      opacity: 0.25;
      }
      :hover{
        color: rgba(0, 0, 0, 0.25);
      }
    ` : ``}
`;

export const DealBadgesWrapper = styled.div`
  display: flex;
  gap: 8px;
  // margin-bottom: 16px;
  align-self: flex-start;
  padding-left: 24px;
`;

export const DealBadge = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  width: 44px;
  flex-direction: column;
  background: ${props => props.closing ? '#FFE9E9' : colors.gray3};
  font-size: 9px;
  font-weight: 700;
  color: ${props => props.closing ? '#FF4D4D' : colors.darkGray2};
  position: relative;

  &:after, &:before {
    content: '';
    position: absolute;
    bottom: -10px;
    width: 0;
    height: 0;
    border-style: solid;
  }

  &:before {
    left: 0;
    border-width: 10px 22px 0 0;
    border-color: ${props => props.closing ? '#FFE9E9' :  colors.gray3} transparent transparent transparent;
  }

  &:after {
    right: 0;
    border-width: 10px 0 0 22px;
    border-color: ${props => props.closing ? '#FFE9E9' :  colors.gray3} transparent transparent transparent;
  }
`;