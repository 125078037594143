import { Dropdown } from "antd";
import { Observer } from 'mobx-react';
import React, { useState } from 'react';
import { ArrowDownIcon, CheckmarkIcon } from "../../../assets/icons/common/common-icons";
import { useAnalytics } from "../../app/data/AnalyticsProvider";
import { useStore } from '../../app/data/root.store';
import { allocateByTitles } from "../data/allocations.const";
import { AllocateByMenuButton, AllocatedByMenuItemText, AllocatedByMenuItemWrapper, AllocatedByMenuWrapper, ArrowIconWrapper, } from "./AllocateBy.styles";
import { CustomClassEmptyStateModal } from "../../user/components/CustomClassList/CustomClassEmptyStateModal/CustomClassEmptyStateModal";
import { classesToShowAccountsAllocation } from "../../../common/constants/tickers.const";

export function AllocateByButtonAndMenu(props) {
  const { wealthStore, filtersStore, dataLayersStore, metadataStore} = useStore();
  const { mixpanelTrack } = useAnalytics();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showCustomClassEmptyStateModal, setShowCustomClassEmptyStateModal] = useState(false);

  const handleTabChange = (key, e) => {
    setIsMenuOpen(false);
    const isCustomClassEmpty = metadataStore.customClasses?.length === 0 && ['customClasses','customSubClasses'].includes(key);
    if (isCustomClassEmpty) {
      setShowCustomClassEmptyStateModal(true);
    } else {
      wealthStore.setAllocateBy(key);
      wealthStore.setListViewMode('allocations');
      dataLayersStore.setSelectedListViewMode('allocations' , key);
    }
    mixpanelTrack('Allocation Selection', { 
      selected_attribute: e.target.textContent,
      url: window.location.href
    });
  }

  return (<Observer>{() => (
    <>
            <Dropdown
            overlay={
              AllocateBySelectorMenu({
                selectedAllocation: wealthStore.allocateBy,
                setSelectedAllocation: handleTabChange,
                showClassesAllocation: !filtersStore.hasClassFilter,
                showAccountsAllocation: wealthStore.isSingleClassFilterResults && filtersStore.isOnlyOneClassFilter && classesToShowAccountsAllocation.includes(wealthStore.filterResultSingleClassId),
                showCustomClass: !filtersStore.hasCustomClassFilter,
                showPropertyTypeAllocation : wealthStore.isSingleClassFilterResults && filtersStore.isOnlyOneClassFilter && wealthStore.filterResultSingleClassId === 4,
                showCompanySectorAllocation : wealthStore.isSingleClassFilterResults && filtersStore.isOnlyOneClassFilter && [3,6].includes(wealthStore.filterResultSingleClassId),
                activeFilters: filtersStore.activeFiltersTypes,
                // showChangesSince,
                // selectChangesSince: (index) => { setShowChangesSince(index) }
              })
            }
            visible={isMenuOpen}
            overlayStyle={{zIndex: 4}}
            onVisibleChange={(visible) => setIsMenuOpen(visible)}
            getPopupContainer={() => document.getElementById('wealthPageContent')}
            trigger={['click']} placement="bottomLeft" >
            <AllocateByMenuButton>Allocate by: {allocateByTitles[wealthStore.allocateBy]}<ArrowIconWrapper isOpen={isMenuOpen}><ArrowDownIcon /></ArrowIconWrapper></AllocateByMenuButton>

            </Dropdown>
            {showCustomClassEmptyStateModal && <CustomClassEmptyStateModal onClose={() => {setShowCustomClassEmptyStateModal(false)}} />}
    </>
  )}</Observer>)
}   

function AllocateBySelectorMenu(props) {
  const {selectedAllocation, setSelectedAllocation, showClassesAllocation, showAccountsAllocation, activeFilters, showPropertyTypeAllocation, showCompanySectorAllocation, showCustomClass} = props
  return (
    <AllocatedByMenuWrapper>
      {showClassesAllocation && <AllocatedByMenuItemWrapper isSelected={selectedAllocation === 'classes'} onClick={(e)=>{setSelectedAllocation('classes', e)}}><AllocationClassesIcon /><AllocatedByMenuItemText>Asset classes</AllocatedByMenuItemText>{selectedAllocation === 'classes' && <CheckmarkIcon/>}</AllocatedByMenuItemWrapper>}
      {showAccountsAllocation && <AllocatedByMenuItemWrapper isSelected={selectedAllocation === 'accounts'} onClick={(e)=>{setSelectedAllocation('accounts', e)}}><AllocationAccountsIcon /><AllocatedByMenuItemText>Accounts</AllocatedByMenuItemText>{selectedAllocation === 'accounts' && <CheckmarkIcon/>}</AllocatedByMenuItemWrapper> }
      <AllocatedByMenuItemWrapper isSelected={selectedAllocation === 'categories'} onClick={(e)=>{setSelectedAllocation('categories', e)}}><AllocationCategoriesIcon /><AllocatedByMenuItemText>Categories</AllocatedByMenuItemText>{selectedAllocation === 'categories' && <CheckmarkIcon/>}</AllocatedByMenuItemWrapper>
      {showCustomClass && <AllocatedByMenuItemWrapper id='customClassesMenuItem' isSelected={selectedAllocation === 'customClasses'} onClick={(e)=>{setSelectedAllocation('customClasses', e)}}><AllocationCustomClassIcon /><AllocatedByMenuItemText>Custom classes</AllocatedByMenuItemText>{selectedAllocation === 'customClasses' && <CheckmarkIcon/>}</AllocatedByMenuItemWrapper>}
      <AllocatedByMenuItemWrapper id='customSubClassesMenuItem' isSelected={selectedAllocation === 'customSubClasses'} onClick={(e)=>{setSelectedAllocation('customSubClasses', e)}}><AllocationCustomSubClassIcon /><AllocatedByMenuItemText>Custom sub-classes</AllocatedByMenuItemText>{selectedAllocation === 'customSubClasses' && <CheckmarkIcon/>}</AllocatedByMenuItemWrapper>
      { !activeFilters.includes('holdingEntity') && <AllocatedByMenuItemWrapper isSelected={selectedAllocation === 'entities'} onClick={(e)=>{setSelectedAllocation('entities', e)}}><AllocationEntitiesIcon /><AllocatedByMenuItemText>Entities</AllocatedByMenuItemText>{selectedAllocation === 'entities' && <CheckmarkIcon/>}</AllocatedByMenuItemWrapper> }
      <AllocatedByMenuItemWrapper isSelected={selectedAllocation === 'currencies'} onClick={(e)=>{setSelectedAllocation('currencies', e)}}><AllocationCurrenciesIcon /><AllocatedByMenuItemText>Currencies</AllocatedByMenuItemText>{selectedAllocation === 'currencies' && <CheckmarkIcon/>}</AllocatedByMenuItemWrapper>
      <AllocatedByMenuItemWrapper isSelected={selectedAllocation === 'risk'} onClick={(e)=>{setSelectedAllocation('risk', e)}}><AllocationRiskLevelIcon /><AllocatedByMenuItemText>Risk level</AllocatedByMenuItemText>{selectedAllocation === 'risk' && <CheckmarkIcon/>}</AllocatedByMenuItemWrapper>
      <AllocatedByMenuItemWrapper isSelected={selectedAllocation === 'liquidity'} onClick={(e)=>{setSelectedAllocation('liquidity', e)}}><AllocationLiquidityLevelIcon /><AllocatedByMenuItemText>Liquidity level</AllocatedByMenuItemText>{selectedAllocation === 'liquidity' && <CheckmarkIcon/>}</AllocatedByMenuItemWrapper>
      <AllocatedByMenuItemWrapper isSelected={selectedAllocation === 'institution'} onClick={(e)=>{setSelectedAllocation('institution', e)}}><AllocationInstSponsorIcon /><AllocatedByMenuItemText>Institution / Sponsor</AllocatedByMenuItemText>{selectedAllocation === 'institution' && <CheckmarkIcon/>}</AllocatedByMenuItemWrapper>
      {showPropertyTypeAllocation && <AllocatedByMenuItemWrapper isSelected={selectedAllocation === 'propertyType'} onClick={(e)=>{setSelectedAllocation('propertyType', e)}}><AllocationPropertyTypeIcon color={'white'}/><AllocatedByMenuItemText>Property type</AllocatedByMenuItemText>{selectedAllocation === 'propertyType' && <CheckmarkIcon/>}</AllocatedByMenuItemWrapper>}
      {showCompanySectorAllocation && <AllocatedByMenuItemWrapper isSelected={selectedAllocation === 'companySector'} onClick={(e)=>{setSelectedAllocation('companySector', e)}}><AllocationCompanySectorIcon color={'white'}/><AllocatedByMenuItemText>Company sector</AllocatedByMenuItemText>{selectedAllocation === 'companySector' && <CheckmarkIcon/>}</AllocatedByMenuItemWrapper>}
    </AllocatedByMenuWrapper>
  )
}

export const AllocationClassesIcon = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.75 5C4.75 4.86193 4.86193 4.75 5 4.75H10.3611V10.3611H4.75V5Z" stroke={color || "white"} strokeWidth="1.5"/>
    <path d="M4.75 13.6389H10.3611V19.25H5C4.86193 19.25 4.75 19.1381 4.75 19V13.6389Z" stroke={color || "white"} strokeWidth="1.5"/>
    <path d="M13.6389 4.75H19C19.1381 4.75 19.25 4.86193 19.25 5V10.3611H13.6389V4.75Z" stroke={color || "white"} strokeWidth="1.5"/>
    <path d="M13.6389 13.6389H19.25V19C19.25 19.1381 19.1381 19.25 19 19.25H13.6389V13.6389Z" stroke={color || "white"} strokeWidth="1.5"/>
    </svg>
  )
}

export const AllocationCategoriesIcon = ({color}) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.75 13.7499H10.25V19.2499H4.75V13.7499Z" stroke={color || "white"} strokeWidth="1.5"/>
    <path d="M13.25 16.5C13.25 14.8431 14.5931 13.5 16.25 13.5C17.9069 13.5 19.25 14.8431 19.25 16.5C19.25 18.1569 17.9069 19.5 16.25 19.5C14.5931 19.5 13.25 18.1569 13.25 16.5Z" stroke={color || "white"} strokeWidth="1.5"/>
    <path d="M8.37375 10.25L11.5 5.38694L14.6263 10.25H8.37375Z" stroke={color || "white"} strokeWidth="1.5"/>
  </svg>
)


export const AllocationEntitiesIcon = ({color}) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 20H7C6.44772 20 6 19.5523 6 19V5H17C17.5523 5 18 5.44772 18 6V10.5" stroke={color || "white"} strokeWidth="1.5" strokeLinecap="square"/>
    <path d="M13.8421 13C13.7004 13.7868 13.2604 14.4995 12.7592 15.091C12.2812 15.6551 11.7112 16.1735 11.0706 16.5281C10.5957 16.791 10.0925 16.9871 9.55096 16.9994C9.21844 17.0069 9.00869 16.948 9.00035 16.5562C8.98508 15.8389 9.47796 15.2598 10.0208 14.879C10.6815 14.4155 11.4735 14.1292 12.2722 14.1292C12.6084 14.1292 12.942 14.1549 13.1593 14.4498C13.343 14.699 13.497 14.9731 13.7001 15.206C13.8933 15.4274 14.1658 15.6226 14.4514 15.6773C15.0369 15.7895 15.6053 15.8268 16.1718 15.6211C16.4991 15.5023 16.8022 15.3294 17.1188 15.1843C17.2616 15.1188 17.3685 15.0737 17.525 15.1204C17.8676 15.2226 18.1322 15.5366 18.428 15.731C18.8033 15.9777 19.2242 16.1122 19.6565 15.9609C19.9999 15.8408 20.3153 15.6463 20.6549 15.5164C20.7662 15.4738 20.896 15.4685 21 15.4142" stroke={color || "white"} strokeWidth="1.3" strokeLinecap="round"/>
  </svg>
)

export const AllocationCurrenciesIcon = ({color}) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.3963 17.9287V17.9787H11.4463H12.5605H12.6105V17.9287V16.75C14.2853 16.5638 15.468 15.5288 15.468 14.0186V14.0049C15.468 13.3487 15.23 12.7989 14.767 12.3621C14.3054 11.9266 13.6227 11.6058 12.7359 11.3995L11.8337 11.1877L11.8334 11.1876C11.3144 11.0689 10.9263 10.8987 10.6689 10.6859C10.4131 10.4745 10.2863 10.2214 10.2863 9.93066V9.91699C10.2863 9.54382 10.4492 9.23739 10.7451 9.02264C11.0425 8.80679 11.4772 8.68184 12.0205 8.68184C12.5353 8.68184 12.9564 8.81304 13.2577 9.03925C13.5583 9.26494 13.7427 9.58719 13.7823 9.97674L13.7891 10.045L13.7936 10.09H13.8389H15.2676H15.321L15.3175 10.0367L15.3106 9.93433C15.3106 9.93433 15.3106 9.93433 15.3106 9.93432C15.2275 8.62533 14.1702 7.60546 12.6105 7.39835V6.19141V6.14141H12.5605H11.4463H11.3963V6.19141V7.3983C9.82347 7.60485 8.74395 8.6059 8.74395 10.0195V10.0332C8.74395 10.6824 8.98179 11.2284 9.41769 11.6573C9.85258 12.0853 10.4823 12.3945 11.264 12.577C11.264 12.577 11.264 12.577 11.264 12.577L12.2002 12.7957C12.2002 12.7957 12.2003 12.7957 12.2003 12.7957C12.8062 12.9421 13.237 13.1194 13.5157 13.3392C13.7916 13.5568 13.9187 13.8162 13.9187 14.1348V14.1484C13.9187 14.5565 13.7502 14.8796 13.4381 15.1024C13.124 15.3266 12.6604 15.452 12.0684 15.452C11.4805 15.452 11.0041 15.3218 10.6635 15.0906C10.324 14.8601 10.1167 14.5277 10.0672 14.1151L10.0672 14.1149L10.0604 14.0602L10.0549 14.0164H10.0107H8.56836H8.51437L8.51851 14.0702L8.52534 14.1591L8.52535 14.1591C8.63612 15.5854 9.77697 16.5374 11.3963 16.7359V17.9287Z" fill={color || "white"} stroke={color || "white"} strokeWidth="0.1"/>
    <circle cx="12" cy="11.999" r="8" stroke={color || "white"} strokeWidth="1.5"/>
  </svg>
)

export const AllocationRiskLevelIcon = ({color}) => (
  <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.9999 12.856C19.9999 8.43774 16.4182 4.85602 11.9999 4.85602C7.5816 4.85602 3.99988 8.43774 3.99988 12.856" stroke={color || "white"} strokeWidth="1.5"/>
<path d="M17.8085 18.357C19.1667 16.9232 19.9999 14.9869 19.9999 12.856C19.9999 8.43774 16.4182 4.85602 11.9999 4.85602C7.5816 4.85602 3.99988 8.43774 3.99988 12.856C3.99988 14.9869 4.83301 16.9232 6.1913 18.357" stroke={color || "white"} strokeWidth="1.5"/>
<path d="M13.5779 13.7306L12.6586 12.525L15.7658 11.1087L13.5779 13.7306Z" fill={color || "white"} stroke={color || "white"}/>
<rect x="12.0465" y="11.7636" width="3.5" height="3.5" rx="1.75" transform="rotate(52.67 12.0465 11.7636)" stroke={color || "white"} strokeWidth="1.5"/>
</svg>
);

  export const AllocationLiquidityLevelIcon = ({color}) => (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19 13.8179C19 17.2322 15.866 20 12 20C8.13401 20 5 17.2322 5 13.8179C5 9.45919 8 7.72222 12 3C16 7.72222 19 9.45919 19 13.8179Z" stroke={color || "white"} strokeWidth="1.5" strokeLinejoin="round"/>
  <path d="M8 13C8 14.6057 8.5 16 10 16.5" stroke={color || "white"} strokeWidth="1.5"/>
  </svg>
  );

    export const AllocationInstSponsorIcon = ({color}) => (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.5 8.5L12 4L19.5 8.5" stroke={color || "white"} strokeWidth="1.5"/>
<path d="M21 18H3" stroke={color || "white"} strokeWidth="1.3"/>
<path d="M21 8.5H3" stroke={color || "white"} strokeWidth="1.5"/>
<path d="M5.75 10.5V18" stroke={color || "white"} strokeWidth="1.5"/>
<path d="M9.75 10.5V18" stroke={color || "white"} strokeWidth="1.5"/>
<path d="M14.25 10.5V18" stroke={color || "white"} strokeWidth="1.5"/>
<path d="M18.25 10.5V18" stroke={color || "white"} strokeWidth="1.5"/>
</svg>
    );

    export const AllocationAccountsIcon = ({color}) => (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.375 8.375C9.375 6.92525 10.5503 5.75 12 5.75C13.4497 5.75 14.625 6.92525 14.625 8.375C14.625 9.82475 13.4497 11 12 11C10.5503 11 9.375 9.82475 9.375 8.375ZM12 4.25C9.72183 4.25 7.875 6.09683 7.875 8.375C7.875 9.65311 8.45629 10.7955 9.36891 11.5521L8.79882 12.5022C8.35502 12.339 7.87542 12.2499 7.375 12.2499C5.09683 12.2499 3.25 14.0968 3.25 16.3749C3.25 18.6531 5.09683 20.4999 7.375 20.4999C9.65317 20.4999 11.5 18.6531 11.5 16.3749C11.5 15.0269 10.8534 13.8298 9.85332 13.0771L10.3941 12.1757C10.8877 12.3845 11.4304 12.5 12 12.5C12.5696 12.5 13.1122 12.3846 13.6058 12.1758L14.0718 12.9524C12.9728 13.6934 12.25 14.9498 12.25 16.375C12.25 18.6532 14.0968 20.5 16.375 20.5C18.6532 20.5 20.5 18.6532 20.5 16.375C20.5 14.0968 18.6532 12.25 16.375 12.25C15.9518 12.25 15.5434 12.3137 15.159 12.4321L14.631 11.5521C15.5437 10.7955 16.125 9.65314 16.125 8.375C16.125 6.09683 14.2782 4.25 12 4.25ZM4.75 16.3749C4.75 14.9252 5.92525 13.7499 7.375 13.7499C8.82475 13.7499 10 14.9252 10 16.3749C10 17.8247 8.82475 18.9999 7.375 18.9999C5.92525 18.9999 4.75 17.8247 4.75 16.3749ZM13.75 16.375C13.75 14.9253 14.9253 13.75 16.375 13.75C17.8247 13.75 19 14.9253 19 16.375C19 17.8247 17.8247 19 16.375 19C14.9253 19 13.75 17.8247 13.75 16.375Z" fill={color || "white"}/>
    </svg>
    )


export const AllocationCustomClassIcon = ({color}) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.51479 7.49556C2.8442 7.8797 2.8442 8.84686 3.51479 9.231L10.8669 13.4425C11.1748 13.6189 11.5531 13.6189 11.861 13.4425L19.2131 9.231C19.8837 8.84686 19.8837 7.8797 19.2131 7.49556L11.861 3.28405C11.5531 3.10767 11.1748 3.10767 10.8669 3.28405L3.51479 7.49556Z" stroke={color || "white"} strokeWidth="1.5"/>
    <path d="M17.2626 10.3782L19.3395 11.5679C19.9807 11.9353 20.0157 12.8473 19.4045 13.2627L11.9261 18.3452C11.5868 18.5758 11.1411 18.5758 10.8019 18.3452L3.32343 13.2627C2.7122 12.8473 2.7472 11.9353 3.38847 11.5679L5.46533 10.3782" stroke={color || "white"} strokeWidth="1.5"/>
    <path d="M17.2626 15.015L19.3395 16.2046C19.9807 16.572 20.0157 17.484 19.4045 17.8994L11.9261 22.9819C11.5868 23.2125 11.1411 23.2125 10.8019 22.9819L3.32343 17.8994C2.7122 17.484 2.7472 16.572 3.38847 16.2046L5.46533 15.015" stroke={color || "white"} strokeWidth="1.5"/>
  </svg>
)

export const AllocationCustomSubClassIcon = ({color}) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 8.38743C3 7.70487 3.6687 7.2229 4.31623 7.43874L11.3162 9.77208C11.7246 9.90819 12 10.2903 12 10.7208V20.6126C12 21.2951 11.3313 21.7771 10.6838 21.5613L3.68377 19.2279C3.27543 19.0918 3 18.7097 3 18.2792V8.38743Z" stroke={color || "white"} strokeWidth="1.5"/>
    <path d="M12 17.6667L14.6838 18.5613C15.3313 18.7771 16 18.2951 16 17.6126V7.72076C16 7.29033 15.7246 6.90819 15.3162 6.77208L8.31623 4.43874C7.6687 4.2229 7 4.70487 7 5.38743V8" stroke={color || "white"} strokeWidth="1.5"/>
    <path d="M16 14.6667L18.6838 15.5613C19.3313 15.7771 20 15.2951 20 14.6126V4.72076C20 4.29033 19.7246 3.90819 19.3162 3.77208L12.3162 1.43874C11.6687 1.2229 11 1.70487 11 2.38743V5" stroke={color || "white"} strokeWidth="1.5"/>
</svg>
)

export const AllocationPropertyTypeIcon = ({color}) => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 10.4924V18.8C3 18.9105 3.08954 19 3.2 19H7.3C7.41046 19 7.5 18.9105 7.5 18.8V14C7.5 13.7239 7.72386 13.5 8 13.5H11C11.2761 13.5 11.5 13.7239 11.5 14V18.8C11.5 18.9105 11.5895 19 11.7 19H15.8C15.9105 19 16 18.9105 16 18.8V14.25V10.4924C16 10.1819 15.8558 9.88907 15.6097 9.69978L10.1097 5.46901C9.75026 5.19251 9.24974 5.19251 8.89029 5.46901L3.39029 9.69978C3.1442 9.88907 3 10.1819 3 10.4924Z" stroke={color || "black"}  strokeWidth="1.5"/>
<path d="M18 19H20.8C20.9105 19 21 18.9105 21 18.8V10.4924C21 10.1819 20.8558 9.88907 20.6097 9.69978L15.0787 5.44513C14.7339 5.17995 14.2573 5.168 13.8998 5.41556L11.9888 6.73855" stroke={color || "black"}  strokeWidth="1.5"/>
</svg>
)

export const AllocationCompanySectorIcon = ({color}) => (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M14.0925 3.65613C14.3611 3.34086 14.8345 3.30308 15.1498 3.57174C15.465 3.8404 15.5028 4.31377 15.2342 4.62904L14.5391 5.44461C13.3326 6.86043 11.1312 6.81451 9.98477 5.34962C9.46288 4.68276 8.48121 4.60755 7.86383 5.18713L7.17664 5.83224C6.87465 6.11575 6.40001 6.10076 6.11651 5.79877C5.83301 5.49677 5.848 5.02214 6.14999 4.73863L6.83717 4.09352C8.09725 2.91059 10.1008 3.06409 11.166 4.42515C11.7277 5.14289 12.8063 5.16538 13.3975 4.4717L14.0925 3.65613ZM9.35342 7.25H4.97322L4.00617 18.8547C3.92114 19.875 4.7263 20.75 5.75012 20.75H8.57652C8.60561 20.75 8.63451 20.7493 8.66323 20.7479V20.75L18.1095 20.75C19.112 20.75 19.9096 19.9095 19.8571 18.9083L19.3386 9.02573L15.2003 12.1295L14.7225 9.26293L9.76743 12.2181L9.35342 7.25ZM18.1095 19.25H10.3091C10.3274 19.1219 10.3317 18.9896 10.3205 18.8547L9.90608 13.8819L13.6041 11.6764L14.1263 14.8099L17.988 11.9136L18.3591 18.9869C18.3666 19.1299 18.2527 19.25 18.1095 19.25ZM6.20759 10.5L6.35342 8.75H7.97322L8.11905 10.5H6.20759ZM6.08259 12L5.50098 18.9792C5.48884 19.125 5.60386 19.25 5.75012 19.25H8.57652C8.72278 19.25 8.8378 19.125 8.82566 18.9792L8.24405 12H6.08259Z" fill={color || "black"} />
</svg>
)

