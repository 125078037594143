import styled from "@emotion/styled/macro";
import { getTranslations } from "../../../../common/translations/translations";
import { ReactComponent as Liquidity1 } from './icons/liquidity-1.svg';
import { ReactComponent as Liquidity2 } from './icons/liquidity-2.svg';
import { ReactComponent as Liquidity3 } from './icons/liquidity-3.svg';
import { ReactComponent as Liquidity4 } from './icons/liquidity-4.svg';
import { ReactComponent as Liquidity5 } from './icons/liquidity-5.svg';

const { asset_fields } = getTranslations().common;

const OptionContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: -1px;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 2px;
  flex-shrink: 0;
`;

export const liquidityRow = ({ isEditForm, updateField, validations = [] }) => ({
  fields: [
    {
      key: 'liquidity', type: 'select',
      label: asset_fields.LIQUIDITY_LABEL,
      description:`Liquidity: Indicates how quickly an investment can be converted to cash. Each asset gets a liquidity score from 1 to 5, with 5 being the most liquid. View and manage the allocation of your portfolio by these scores to optimize your financial strategy.`,
      withDescriptionInOption: true,
      allowClear: true,
      withSuffixIcon: true,
      setFnForSave: (isDistribution, value, formValues) => (value === '') ? null : value,
      options: [
        { value: 1, label: () => <OptionContainer><IconContainer style={{ background: '#373255' }}><Liquidity1 /></IconContainer>01 (Non-liquid)</OptionContainer>, },
        { value: 2, label: () => <OptionContainer><IconContainer style={{ background: '#564B97' }}><Liquidity2 /></IconContainer>02</OptionContainer>, },
        { value: 3, label: () => <OptionContainer><IconContainer style={{ background: '#5775C3' }}><Liquidity3 /></IconContainer>03</OptionContainer>, },
        { value: 4, label: () => <OptionContainer><IconContainer style={{ background: '#3CB4CF' }}><Liquidity4 /></IconContainer>04</OptionContainer>, },
        { value: 5, label: () => <OptionContainer><IconContainer style={{ background: '#84E9FF' }}><Liquidity5 /></IconContainer>05 (Fully liquid)</OptionContainer>, },
      ],
      ...(validations.length > 0 ? {
        validations
      } : {}),
    }
  ]
});

export const liquidityNamesColors = {
  1: {name: '01 (Non-liquid)', color: '#373255'},
  2: {name: '02', color: '#564B97'},
  3: {name: '03', color: '#5775C3'},
  4: {name: '04', color: '#3CB4CF'},
  5: {name: '05 (Fully liquid)', color: '#84E9FF'},
};