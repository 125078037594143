import { isNullOrUndefined } from "../../../common/utils/object.utils"

export const isRequired =    //{fn:(val)=>{console.log('a',val); return val!='yo'},message:`Can't be yo`}
{
    fn:(val) => { return ((!!val || val === 0) && (''+val).trim() !== '')},
    message: `Required`
}

export const isRequiredAndNotZero = {
    fn:(val) => { return ((!!val) && (''+val).trim() !== '')},
    message: `Required`
}


export const isBetweenZeroAndHundred = {
    fn: value => !isNullOrUndefined(value) && value >= 0 && value <= 100,
    bottomErrorMessage: () => 'Value must be between 0 and 100'
}

export const isNotNegative = {
    fn: value => !isNullOrUndefined(value) && value >= 0,
    message: ' ',
    bottomErrorMessage: () => 'The interest rate cannot be negative'
}

export const isNotNegativeIfNotEmpty = {
    fn: value => isNullOrUndefined(value) || (!isNullOrUndefined(value) && value >= 0),
    message: ' ',
    bottomErrorMessage: () => 'The interest rate cannot be negative'
}

export const isValidEmail = {
    fn: value => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
    message: ' ',
    bottomErrorMessage: () => 'Invalid email address'
}

export const isValidEmailOrEmpty = {
    fn: value => isNullOrUndefined(value) || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
    message: ' ',
    bottomErrorMessage: () => 'Invalid email address'
}